<template>
  <div class="dropdown-button-wrapper">
    <div class="dropdown-button" :class="{ active: open }" @click="toggleOptions" :data-test-id="`${dataAttr}-dropdown-button`">
      <ar-text
        class="selected-option-name"
        :class="{ placeholder: isPlaceholder }"
        size="14px"
        :text="selected.name"
        weight="normal"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ArrowHead class="dropdown-button-arrowhead" height="8px" width="10px" fill="#8492a6" />
    </div>
    <div v-if="open" class="dropdown-options-wrapper">
      <template v-for="(option, index) in options">
        <div class="dropdown-option" :key="index" @click="handleSelected(option)">
          <ar-text
            class="option-name"
            :class="{ selected: isSelected(option) }"
            size="14px"
            :text="option.name"
            weight="normal"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
export default {
  name: 'DropdownButton',
  components: {
    ArrowHead
  },
  props: {
    dataAttr: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selected: {
      type: Object,
      default: null
    }
  },
  model: {
    prop: 'selected',
    event: 'selection',
  },
  data() {
    return {
      open: false
    }
  },
  watch: {
    open() {
      this.$emit('open', this.open)
    }
  },
  computed: {
    isPlaceholder() {
      return this.selected?.key === 'placeholder' ? true : false
    }
  },
  methods: {
    handleSelected(option) {
      this.$emit('selection', option)
      this.open = false
    },
    isSelected(option) {
      if (!this.selected) return false
      
      return option.name === this.selected.name ? true : false
    },
    toggleOptions() {
      this.open = !this.open
    }
  }
}
</script>
<style lang="scss" scoped>
.dropdown-button-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;

  .dropdown-button {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px $blueGrey500;
    background-color: #fff;
    height: 48px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 16px;
    margin-bottom: 4px;;
    cursor: pointer;

    &.active {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;

      .dropdown-button-arrowhead {
        transform: rotate(180deg);
      }
    }

    .selected-option-name {
      border: none;
      outline: none;
      cursor: pointer;
      width: 258px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      color: $blueGrey800;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.placeholder {
        color: $blueGrey700;
      }
    }
  }

  .dropdown-options-wrapper {
    width: 100%;
    padding: 9px 0;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 $shadow;
    border: solid 1px #d4d8e1;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    max-height: 221px;
    overflow: scroll;
    position: absolute;
    top: 54px;
    z-index: 10;

    .dropdown-option {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      width: 100%;
      padding: 10px 16px;
      cursor: pointer;

      &:hover {
        background-color: #f2edff;

        .option-name {
          color: $purple500;          
        }
      }

      .option-name {
        color: $blueGrey800;
        max-width: 275px;

        &.selected {
          color: $purple500;
        }
      }
    }
  }
}
</style>