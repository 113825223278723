var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:("dialog-select " + (_vm.classNames.join(' ')))},[_c('div',{staticClass:"triangle",style:(_vm.arrowAlignStyle)},[_c('div',{staticClass:"triangle-outer"}),_vm._v(" "),_c('div',{staticClass:"triangle-inner"})]),_vm._v(" "),(_vm.tabs.length > 0)?_c('div',{staticClass:"tabs-section"},[_c('am2-elegant-tabs',{attrs:{"items":_vm.tabs,"tab-key":_vm.tabKey},on:{"select":_vm.handleTabSelect}})],1):_vm._e(),_vm._v(" "),(_vm.hasSearch)?_c('div',{staticClass:"filter-section"},[_c('am2-search',{ref:"search",staticClass:"filter",attrs:{"value":_vm.searchString,"placeholder":_vm.placeholder,"enable-clear":false},on:{"input":_vm.handleSearchStringChange}}),_vm._v(" "),_c('div',{staticClass:"fader"})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"items-section",staticClass:"items-section",style:({
      maxHeight: _vm.itemsSectionMaxHeight,
    }),on:{"mouseleave":_vm.handleMouseLeaveItemsSection}},[(_vm.loading)?_vm._l((3),function(n){return _c('div',{key:n,staticClass:"item",style:(_vm.itemStyle)},[_c('am2-typography-skeleton',{attrs:{"size":"xs","level":2 * n - 1}})],1)}):(_vm.items.length > 0)?_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,ref:"dropdownList",refInFor:true,class:['dropdown-item', item.disabled ? 'disabled' : ''],attrs:{"data-test-id":("dropdown-item-" + (_vm.generateSafeName(item)))},on:{"mouseenter":function($event){return _vm.handleMouseEnterItem(idx)}}},[(item.type === 'header')?_c('div',{staticClass:"item header",style:(_vm.getHeaderItemStyle(idx))},[_c('ar-text',_vm._b({attrs:{"size":"xs","text":item.name,"weight":"bold"}},'ar-text',item.typography,false)),_vm._v(" "),(item.hasCloseIcon)?_c('ar-icon',{staticClass:"dialog-select__icon-close",attrs:{"name":"cross-thick","width":"16"},on:{"click":_vm.handleClickCloseIcon}}):_vm._e()],1):(item.type === 'divider')?_c('div',{staticClass:"item divider",style:(_vm.dividerStyle || item.style)}):(item.type === 'action')?_c('div',{staticClass:"item action",style:(_vm.itemStyle),on:{"click":function () {
              _vm.handleItemClick(item);
            }}},[_c('div',{staticClass:"action-icon"},[_c('ar-icon',{attrs:{"name":"circle-plus","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-text',_vm._b({style:({
              color: _vm.$arStyle.color.purple500,
            }),attrs:{"text":item.name,"size":"xs","weight":"bold"}},'ar-text',item.typography,false))],1):(item.type === 'profile')?_c('div',{staticClass:"item profile",style:(_vm.itemStyle)},[_c('ar-avatar',{staticClass:"avatar",attrs:{"image-path":item.avatar,"name":((item.firstName) + " " + (item.lastName))}}),_vm._v(" "),_c('div',{staticClass:"info-section"},[_c('ar-text',{attrs:{"size":"xs","text":((item.firstName) + " " + (item.lastName))}}),_vm._v(" "),_c('ar-text',{staticClass:"subtitle",attrs:{"size":"xs","text":item.emailAddress}})],1)],1):(item.type === 'extended-option')?_c('div',{staticClass:"item extended-option",attrs:{"data-test-id":("option-" + (item.key))},on:{"click":function () {
              _vm.handleItemClick(item);
            }}},[_c('div',{staticClass:"item-body"},[_c('div',{staticClass:"icon-container"},[_c('div',{staticClass:"icon-wrapper",style:({
                  backgroundColor: item.iconColor,
                })},[_c('div',{staticClass:"icon",domProps:{"innerHTML":_vm._s(require(("~/assets/icons/" + (item.icon) + ".svg?raw")))}})])]),_vm._v(" "),_c('div',{staticClass:"info-section"},[_c('ar-text',{staticClass:"title",attrs:{"size":"xs","weight":"bold","text":item.title}}),_vm._v(" "),_c('ar-text',{staticClass:"subtitle",attrs:{"size":"xs","text":item.description,"multipleLines":""}})],1),_vm._v(" "),(!!item.tag)?_c('div',{staticClass:"tag-container",style:({
                backgroundColor: item.tagBackground,
              })},[(!!item.tagIcon)?_c('div',{staticClass:"icon tag-icon",style:({
                  fill: item.tagColor,
                }),domProps:{"innerHTML":_vm._s(require(("~/assets/icons/" + (item.tagIcon) + ".svg?raw")))}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"tag",style:({
                  color: item.tagColor,
                }),attrs:{"size":"10px","weight":"bold","text":item.tag}})],1):_vm._e()]),_vm._v(" "),(item.banner)?_c('div',{staticClass:"banner",style:({
              backgroundColor: item.bannerBackground,
            })},[_c('ar-text',{staticClass:"subtitle",style:({
                color: item.bannerColor,
              }),attrs:{"size":"xs","text":item.banner,"weight":"bold","multipleLines":""}})],1):_vm._e()]):_c('div',{staticClass:"item text",class:[
            _vm.arrowCounter === idx && item.type !== 'default-without-hover' && 'hover-style',
            item.type === 'default-without-hover' && 'default-without-hover' ],style:(_vm.itemStyle),on:{"click":function () {
              _vm.handleItemClick(item);
            }}},[(item.icon)?_c('div',{staticClass:"icon-section"},[_c('ar-icon',_vm._b({staticClass:"icon"},'ar-icon',item.icon,false))],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('ar-text',_vm._b({staticClass:"typography",attrs:{"text":item.name,"size":"xs"}},'ar-text',item.typography,false)),_vm._v(" "),(_vm.isSubtitled(item))?_c('ar-text',_vm._b({staticClass:"typography subtitle mt-auto",attrs:{"size":"xxxs","text":item.subtitleStartedString}},'ar-text',item.typography,false)):_vm._e()],1),_vm._v(" "),(item.controls)?_c('div',{staticClass:"controls-section"},_vm._l((item.controls),function(control,controlIdx){return _c('div',{key:controlIdx,staticClass:"control-wrapper"},[_c('ar-icon-button',{staticClass:"control",style:({
                  display: _vm.checkIsControlVisible(idx, control) ? 'inline-flex' : 'none',
                }),attrs:{"color":control.pinned ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.purple200,"icon-props":{
                  name: control.iconName,
                  width: '12px',
                }},on:{"click":function($event){return _vm.handleControlClick(item, control)}}})],1)}),0):_vm._e()])])}):(_vm.hasSearch && _vm.items.length === 0)?_c('div',{staticClass:"item",style:(_vm.itemStyle)},[_c('ar-text',{attrs:{"text":_vm.noOptionText,"size":"xs"}})],1):_vm._e()],2),_vm._v(" "),(_vm.hasFixedFooter)?_c('div',[_c('div',{staticClass:"bottom-fader"}),_vm._v(" "),_c('div',{staticClass:"custom-divider",style:(_vm.itemStyle)},[_c('ar-link-button',{attrs:{"text":"+ Add custom field","has-underline":false,"color":_vm.$arStyle.color.skyBlueGrey800},on:{"click":_vm.handleCustomFieldClickManager}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }