<template>
  <div class="settings-password">
    <div class="input-groups-section">
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="Current password"
          />
        </div>
        <div class="input-section">
          <ar-input
            v-model="resetPasswordObject.oldPassword"
            v-validate="'required'"
            data-vv-name="oldPassword"
            autocomplete="current-password"
            class="input"
            placeholder="Current password"
            text-secure
            type="password"
          />
          <ar-state-message
            v-if="veeErrors.has('oldPassword')"
            :text="veeErrors.first('oldPassword')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="New password"
          />
        </div>
        <div class="input-section">
          <ar-input
            v-model="resetPasswordObject.newPassword"
            v-validate="'required|arPasswordLength'"
            autocomplete="new-password"
            data-vv-name="newPassword"
            class="input"
            placeholder="New password"
            text-secure
            type="password"
          />
          <ar-state-message
            v-if="veeErrors.has('newPassword')"
            :text="veeErrors.first('newPassword')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="Confirm new password"
          />
        </div>
        <div class="input-section">
          <ar-input
            v-model="resetPasswordObject.confirmNewPassword"
            v-validate="{
              required: !!resetPasswordObject.newPassword,
              matchPassword: resetPasswordObject.newPassword,
            }"
            autocomplete="new-password"
            data-vv-name="confirmNewPassword"
            class="input"
            placeholder="Confirm new password"
            text-secure
            type="password"
          />
          <ar-state-message
            v-if="veeErrors.has('confirmNewPassword')"
            :text="veeErrors.first('confirmNewPassword')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
    </div>
    <ar-divider
      class="u-margin-y-17"
    />
    <div
      class="functions-section"
    >
      <ar-simple-button
        text="Change Password"
        :style="{
          width: '170px',
        }"
        :loading="isChangingPassword"
        @click="handleSaveChangeClick"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      resetPasswordObject: {
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
    };
  },

  computed: {
    ...mapState({
      isChangingPassword: state => state.auth.isChangingPassword,
    }),
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          oldPassword: {
            required: 'Current password is required'
          },
          newPassword: {
            required: 'New password is required'
          },
          confirmNewPassword: {
            required: 'Confirm new password is required'
          },
        },
      },
    });
  },

  methods: {
    ...mapActions([
      'auth/CHANGE_PASSWORD',
    ]),

    resetEditPassword() {
      this.resetPasswordObject = {
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      };
    },

    async handleSaveChangeClick() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }
      const succeed = await this['auth/CHANGE_PASSWORD']({
        oldPassword: this.resetPasswordObject.oldPassword,
        password: this.resetPasswordObject.newPassword,
      });
      if (succeed) {
        this.resetEditPassword();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/base/mixins';
.settings-password {
  .input-groups-section {
    max-width: 800px;
    margin: 59px auto 0;
    .input-group {
      display: flex;
      margin-bottom: 30px;
      .label-section {
        flex-shrink: 0;
        flex-basis: 210px;
        display: flex;
        align-items: center;
        height: 50px;
      }
      .input-section {
        flex-grow: 1;

        .input {
          width: 100%;
        }
      }

      &.sm-max {
        flex-direction: column;
        .label-section {
          flex-basis: 32px;
        }
      }
    }
  }

  .functions-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 39px;
  }
}
</style>
