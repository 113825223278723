var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"edit-template-page"},[_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displaySaveCurrentEmailTemplateModal,"loading":_vm.isCreatingEmailTemplate},on:{"confirm":_vm.handleSaveCurrentEmailTempalteConfirm,"cancel":_vm.handleSaveCurrentEmailTempalteCancel}}),_vm._v(" "),_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displayNewEmailTemplateModal,"loading":_vm.isCreatingEmailTemplate},on:{"confirm":_vm.handleNewEmailTempalteConfirm,"cancel":_vm.handleNewEmailTempalteCancel}}),_vm._v(" "),_c('am2-select-email-template-modal',{attrs:{"opened":_vm.displaySelectEmailTemplateModal},on:{"select":_vm.handleSelectEmailTemplateSelect,"cancel":_vm.handleSelectEmailTemplateCancel}}),_vm._v(" "),_c('am2-email-preview-modal',{attrs:{"opened":_vm.displayEmailPreviewModal,"email-html":_vm.templateHtml},on:{"close":_vm.handleEmailPreviewClose}}),_vm._v(" "),_c('div',{staticClass:"header-section"},[_c('div',{staticClass:"header-title-section"},[_c('ar-icon',{attrs:{"name":"email","width":"18.67px","color":_vm.$arStyle.color.email,"wrapper-style":{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: ("2px solid " + (_vm.$arStyle.color.skyBlueGrey400)),
        }}}),_vm._v(" "),_c('div',{staticClass:"header-title-right-section"},[_c('ar-text',{attrs:{"size":"sm","text":_vm.isNewEmailTemplate ? 'Create Email Template' : 'Edit Email Template'}}),_vm._v(" "),_c('ar-text',{style:({
            color: '#9FA8B5',
            marginTop: '6px',
          }),attrs:{"size":"xxs","text":_vm.scratchEmailTemplate.meta.name}})],1)],1),_vm._v(" "),_c('div',{staticClass:"header-right-section"},[_c('am2-link-button-dropdown',{style:({
          marginRight: '36px',
        }),attrs:{"placeholder":"Template","items":_vm.templateDropdownItems,"dropdown-style":{
          padding: '5px 0',
          width: '207px',
        },"item-style":{
          height: '40px',
          padding: '0 20px',
        },"align":"left"},on:{"select":_vm.handleTemplateItemSelect}}),_vm._v(" "),_c('am2-link-button-dropdown',{style:({
          marginRight: '36px',
        }),attrs:{"placeholder":"Preview","items":_vm.previewDropdownItems,"dropdown-style":{
          padding: '5px 0',
          width: '207px',
        },"item-style":{
          height: '40px',
          padding: '0 20px',
        },"align":"left"},on:{"select":_vm.handlePreviewItemSelect}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Exit"},on:{"click":_vm.handleExitClick}})],1)]),_vm._v(" "),_c('div',{staticClass:"page-content"},[_c('div',{class:[
        'email-editor-wrapper',
        _vm.scratchEmailTemplate.meta.templateType === 'rich-text' && 'with-rich-text' ]},[_c('EmailEditor',{ref:"email-editor-component",attrs:{"dynamic-tags":_vm.availableDynamicTagsInEmailTemplate,"type":_vm.scratchEmailTemplate.meta.templateType,"show-dynamic-tags-tool":false},on:{"templateUpdate":_vm.handleTemplateOutput,"emailHtmlUpdate":_vm.handleEmailHtmlOutput}})],1)]),_vm._v(" "),_c('div',{class:[
      'navigation-content',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[_c('div',{staticClass:"navigation-left-content"},[_c('ar-simple-button',{style:({
          width: '111px',
        }),attrs:{"outlined":"","text":"Cancel"},on:{"click":_vm.handleCancelClick}})],1),_vm._v(" "),_c('div',{staticClass:"navigation-right-content"},[(_vm.isAutoSaveEnabled)?_c('ar-text',{staticClass:"u-margin-right-4",style:({
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"text":_vm.autoSaveCopy,"size":"14px"}}):_vm._e(),_vm._v(" "),(!_vm.isNewEmailTemplate)?_c('ar-simple-button',{attrs:{"color":"purple","text":"Save as new template","disabled":_vm.templateIsNotComplete || _vm.isCreatingEmailTemplate || _vm.isUpdatingSelectedEmailTemplate},on:{"click":_vm.handleSaveAsNewTemplateClick}}):_vm._e(),_vm._v(" "),(_vm.isNewEmailTemplate)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
          general: ['Control', 'Enter'],
          mac: ['Meta', 'Enter'],
          action: _vm.handleCreateTemplateClick,
          disabled: _vm.templateIsNotComplete,
        }),expression:"{\n          general: ['Control', 'Enter'],\n          mac: ['Meta', 'Enter'],\n          action: handleCreateTemplateClick,\n          disabled: templateIsNotComplete,\n        }"}],style:({
          width: '144px',
        }),attrs:{"text":"Save template","loading":_vm.isCreatingEmailTemplate,"disabled":_vm.templateIsNotComplete},on:{"click":_vm.handleCreateTemplateClick}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }