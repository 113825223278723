var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.readonly)?_c('ar-input',{ref:"input",attrs:{"icon-name":"link","icon-props":{
    color: _vm.$arStyle.color.blueGrey800,
  },"value":_vm.fullLink,"label":_vm.enableCopy ? _vm.copyLinkLabel : null,"label-props":{
    weight: 'bold',
    style: { color: _vm.hasCopied ? _vm.$arStyle.color.green500 : _vm.$arStyle.color.blueGrey800, },
  },"readonly":"","data-test-id":"new-slug-input-readonly"},on:{"labelClick":_vm.handleInputLabelClick}}):_c('ar-input',{ref:"input",attrs:{"prefix":_vm.fixedPrefix,"value":_vm.realVal,"autocomplete":"slug","label":_vm.enableCopy ? _vm.copyLinkLabel : null,"label-props":{
    weight: 'bold',
    style: { color: _vm.hasCopied ? _vm.$arStyle.color.green500 : _vm.$arStyle.color.blueGrey800, },
  },"data-test-id":"new-slug-input"},on:{"input":_vm.handleSlugChange,"enter":_vm.handleInputEnter,"labelClick":_vm.handleInputLabelClick}})}
var staticRenderFns = []

export { render, staticRenderFns }