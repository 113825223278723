<template>
  <section
    :class="['ar-privacy-portal-page', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
  >
    <am2-edit-privacy-portal-modal
      :is-show="showEditPrivacyPortalModal"
      :privacy-portal="currentPrivacyPortal"
      @create="handlePrivacyPortalCreate"
      @update="showEditPrivacyPortalModal = false"
      @cancel="handlePrivacyPortalModalClose"
    />
    <div
      class="top-section"
    >
      <ar-text
        size="lg"
        text="Privacy Portal"
      />
      <am2-expand-button-dropdown
        v-if="currentPrivacyPortal"
        align="left"
        :button-props="{
          iconName: 'settings',
        }"
        :items="settingActions"
        @select="handleSettingActionSelect"
      />
    </div>
    <ar-divider
      :style="{
        marginTop: '35px',
        marginBottom: '28px',
      }"
    />
    <am2-card-container
      :class="[
        'u-position-relative',
      ]"
      :style="{
        padding: '78px 30px 62px',
      }"
    >
      <am2-signal-tag
        v-if="isPrivacyPortalPublished"
        :class="[
          'u-position-absolute',
        ]"
        text="Active"
        :style="{
          right: '34px',
          top: '26px',
        }"
      />
      <div
        :class="[
          'u-margin-bottom-10',
          'u-display-flex',
          'u-justify-content-center',
          'u-align-items-center',
        ]"
      >
        <ar-icon
          name="privacy"
          :color="$arStyle.color.purple500"
        />
        <ar-text
          class="u-margin-left-4"
          size="lg"
          text="Privacy Portal"
        />
      </div>
      <!-- Has no Privacy Portal -->
      <div
        v-if="!currentPrivacyPortal"
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-flex-flow-column',
        ]"
      >
        <ar-text
          size="xs"
          text="Set up a privacy portal to allow people to view, edit, download or delete the information you have about them."
          multiple-lines
          line-height="28px"
          align="center"
          :style="{
            color: $arStyle.color.blueGrey700,
            maxWidth: '590px',
          }"
        />
        <ar-simple-button
          class="u-margin-top-15"
          text="Setup Privacy Portal"
          :disabled="setupPrivacyPortalButtonDisabled"
          :style="{
            width: '250px',
          }"
          @click="handleSetupButtonClick"
        />
      </div>
      <!-- Privacy Portal Enabled -->
      <div
        v-if="currentPrivacyPortal && isPrivacyPortalPublished"
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-flex-flow-column',
        ]"
      >
        <ar-text
          size="xs"
          text="Publish the link to your Privacy Portal on your website, so people can view, edit, download or delete the information you have about them."
          align="center"
          allow-html
          multiple-lines
          line-height="28px"
          :style="{
            color: $arStyle.color.blueGrey700,
            maxWidth: '590px',
          }"
        />
        <am2-new-slug-input
          class="u-margin-top-14"
          :value="privacyPortalUrl"
          readonly
          :style="{
            maxWidth: '708px',
          }"
        />
        <ar-simple-button
          class="u-margin-top-15"
          text="View Privacy Portal"
          :style="{
            width: '250px',
          }"
          @click="handleViewPrivacyPortalClick"
        />
      </div>
      <!-- Privacy Portal Disabled -->
      <div
        v-if="currentPrivacyPortal && !isPrivacyPortalPublished"
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-flex-flow-column',
        ]"
      >
        <ar-text
          size="xs"
          text="Your Privacy Portal has been disabled.<br/>Enable your privacy portal so people can view, edit, download or delete the information you have about them."
          align="center"
          allow-html
          multiple-lines
          line-height="28px"
          :style="{
            color: $arStyle.color.blueGrey700,
            maxWidth: '590px',
          }"
        />
        <ar-simple-button
          class="u-margin-top-15"
          text="Enable Privacy Portal"
          :style="{
            width: '250px',
          }"
          :loading="isUpdatingPrivacyPortal"
          @click="handleEnablePrivacyPortalClick"
        />
      </div>
    </am2-card-container>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  layout: 'default',
  data() {
    return {
      showEditPrivacyPortalModal: false,
    };
  },
  computed: {
    ...mapState({
      currentPrivacyPortal: state => state.privacyPortal.currentPrivacyPortal,
      isFetchingCurrentPrivacyPortal: state => state.privacyPortal.isFetchingCurrentPrivacyPortal,
      hasFetchCurrentPrivacyPortalFailed: state => state.privacyPortal.hasFetchCurrentPrivacyPortalFailed,
      isUpdatingPrivacyPortal: state => state.privacyPortal.isUpdatingPrivacyPortal,
    }),
    ...mapGetters({
      privacyPortalUrl: 'privacyPortal/privacyPortalUrl',
    }),
    isPrivacyPortalPublished() {
      return this.currentPrivacyPortal?.published;
    },
    setupPrivacyPortalButtonDisabled() {
      return this.isFetchingCurrentPrivacyPortal || this.hasFetchCurrentPrivacyPortalFailed;
    },
    settingActions() {
      let actions = [
        {
          name: 'Edit Privacy Portal',
          key: 'edit_privacy_portal',
        },
        {
          name: 'Download log as CSV',
          key: 'export_request_csv',
        },
      ];
      if (this.isPrivacyPortalPublished) {
        actions = [
          {
            name: 'Unpublish Privacy Portal',
            key: 'unpublish_privacy_portal',
          }
        ].concat(actions);
      }
      return actions;
    },
  },
  methods: {
    ...mapActions([
      'privacyPortal/RESET_CURRENT_PRIVACY_PORTAL',
      'privacyPortal/FETCH_CURRENT_PRIVACY_PORTAL',
      'privacyPortal/PUBLISH_PRIVACY_PORTAL',
      'privacyPortal/EXPORT_REQUEST_CSV',
    ]),
    handlePrivacyPortalCreate() {
      this.showEditPrivacyPortalModal = false;
      this['privacyPortal/FETCH_CURRENT_PRIVACY_PORTAL']();
    },
    handlePrivacyPortalModalClose() {
      this.showEditPrivacyPortalModal = false;
    },
    handleSetupButtonClick() {
      this.showEditPrivacyPortalModal = true;
    },
    handleViewPrivacyPortalClick() {
      window.open(this.privacyPortalUrl, '__blank');
    },
    handleEnablePrivacyPortalClick() {
      this['privacyPortal/PUBLISH_PRIVACY_PORTAL']({
        oid: this.currentPrivacyPortal.oid,
        published: true,
      });
    },
    handleSettingActionSelect(action) {
      if (action.key === 'edit_privacy_portal') {
        this.showEditPrivacyPortalModal = true;
      } else if (action.key === 'unpublish_privacy_portal') {
        this['privacyPortal/PUBLISH_PRIVACY_PORTAL']({
          oid: this.currentPrivacyPortal.oid,
          published: false,
        });
      } else if (action.key === 'export_request_csv') {
        this['privacyPortal/EXPORT_REQUEST_CSV']();
      }
    },
  },
  created() {
    if (!this.currentPrivacyPortal) {
      this['privacyPortal/RESET_CURRENT_PRIVACY_PORTAL']();
      this['privacyPortal/FETCH_CURRENT_PRIVACY_PORTAL']();
    }
  },
};
</script>

<style lang="scss">
.ar-privacy-portal-page {
  max-width: 1200px;
  padding: 50px 30px 30px;
  margin: 0 auto;
  &.sm-max {
    padding: 30px 12px 30px;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
}
</style>
