<template>
  <AuthenticateFrame
    :totalStep="3"
    :currentStep="3"
  >
    <section class="billing-details">
      <ar-text
        text="Enter your billing details"
        size="lg"
        multiple-lines
        :style="{
          marginBottom: '20px',
        }"
      />
      <p class="description">
        A credit card is required to use some features
      </p>
      <am2-check-list
        check-state="yes"
        text="You will not be charged until you select a plan"
        :style="{
          marginTop: '30px',
        }"
      />
      <am2-check-list
        check-state="yes"
        text="No commitments, cancel at any time"
        :style="{
          marginTop: '14px',
        }"
      />
      <am2-stripe-element-form
        :style="{
          marginTop: '38px',
        }"
        :loading="isCreatingPaymentSource"
        @submit="handleBillingFormSubmite"
      />
      <ar-text
        size="xxxs"
        text="Your credit card will not be charged"
        align="center"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
        class="u-margin-top-4"
      />
    </section>
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'ImportSalesData',
  components: {
    AuthenticateFrame,
  },
  layout: 'split',

  computed: {
    ...mapState({
      isCreatingPaymentSource: state => state.payment.isCreatingPaymentSource,
    }),
  },

  methods: {
    ...mapActions([
      'payment/CREATE_PAYMENT_SOURCE',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    async handleBillingFormSubmite(source) {
      const newPaymentSource = await this['payment/CREATE_PAYMENT_SOURCE'](source);
      if (newPaymentSource) {
        this.$router.push({
          path: '/audience',
        });
      }
    },
  },

  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](3);
  },
};
</script>

<style lang="scss" scoped>
.billing-details {
  .description {
    font-size: 16px;
    color: $skyBlueGrey700;
    letter-spacing: 0;
    line-height: 20px;
  }
}
</style>
