<template>
  <article class="stats-list">
    <header>
      <div class="heading-container">
        <am2-heading
          type="h1"
          size="md"
          :title="heading"
        />
        <ar-icon
          v-if="tooltip"
          name="tooltip-question-mark"
          class="u-margin-left-2 tooltip-icon"
          :color="$arStyle.color.blueGrey500"
          height="16px"
          width="16px"
          v-tooltip.top="{
            content: tooltip
          }"
        />
      </div>
      
      <div
        v-if="!!timestamp"
        class="u-display-flex u-align-items-center"
      >
        <ar-text
          :text="`Last synced ${getOrderTimeFromNow(timestamp)}`"
          size="xxs"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
          data-test-id="click-stats-table-timestamp"
        />
        <ar-icon
          name="alert-exclamation-mark"
          class="u-margin-top-1 u-margin-left-2 tooltip-icon"
          height="16px"
          width="16px"
          v-tooltip.top="{
            content: 'The table below is updated periodically.',
          }"
          data-test-id="click-stats-table-tooltip"
        />
      </div>
    </header>
    <!-- If there is no row data supplied then show the emptyText -->
    <div :class="['stats-content', $arMediaQuery.pageContent.minWidth('sm') && 'sm-min']">
      <table :class="!hasRowData ? 'empty' : null">
        <thead>
          <tr>
            <th class="label" :style="{ paddingLeft: hasRowData ? '33px' : '9px' }">{{columnNames[0]}}</th>
            <th class="value">{{columnNames[1]}}</th>
            <th class="value">{{columnNames[2]}}</th>
          </tr>
        </thead>
        <tbody v-if="hasRowData && !loading && !error">
          <tr
            v-for="(row, index) in data"
            :class="[row.href || row.internalHref ? 'clickable' : null]"
            :key="`${index}`"
            :data-test-id="`click-stats-table-row-${index}`"
          >
            <td>
              <div class="url-info">
                <span class="number">{{index + 1}}</span>
                <a
                  class="name u-margin-left-1"
                  data-test-id="click-stats-url"
                  :href="displayData(row, 'name')"
                  @click="() => handleRowClick(index)"
                >
                  {{displayData(row, 'name')}}
                </a>
              </div>
            </td>
            <td class="right">
              <ar-text
                :class="[locked && 'blurred']"
                size="xs"
                :text="displayData(row, 'uniqueTotal')"
                align="right"
                data-test-id="click-stats-unique-total"
              />
            </td>
            <td class="right">
              <ar-text
                :class="[locked && 'blurred']"
                size="xs"
                :text="displayData(row, 'total')"
                align="right"
                data-test-id="click-stats-total"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
        </tbody>
      </table>
      <div class="current-state-section loading" v-if="loading">
        <am2-loading-bubble
          class="message"
        />
      </div>
      <div class="current-state-section error" v-else-if="error">
        <ar-state-message
          :style="{ justifyContent: 'center' }"
          class="message"
          type="error"
          text="Error loading data."
        />
      </div>
      <div class="current-state-section empty" v-else-if="!hasRowData">
        <ar-state-message
          class="message"
          has-icon
          disable-text-color
          type="general"
          :text="emptyText"
        />
      </div>
    </div>
    <div v-if="showSeeAll" class="see-all">
      <ar-link-button
        text="See more"
        @click="handleSeeAllClick"
        data-test-id="click-stats-see-more"
      />
    </div>
    <div v-if="showSeeLess" class="see-all">
      <ar-link-button
        text="See less"
        @click="handleSeeLessClick"
        data-test-id="click-stats-see-less"
      />
    </div>
  </article>
</template>

<script>
import accounting from 'accounting';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default {
  name: 'ClicksStatsTable',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    locked: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    },
    error: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: "Default heading"
    },
    tooltip: {
      type: String,
      default: null,
    },
    columnNames: {
      type: Array,
      default: () => [],
    },
    emptyText: {
      type: String,
      default: "No data is available"
    },
    isValueCurrency: {
      type: Boolean,
      default: false,
    },
    showSeeAllButton: {
      type: Boolean || null,
      default: false,
    },
    timestamp: {
      type: String || null,
      default: null,
    }
  },

  computed: {
    showSeeAll() {
      if(!this.data || this.showSeeAllButton == null) return false;
      return this.showSeeAllButton;
    },
    showSeeLess() {
      if(!this.data || this.showSeeAllButton == null) return false;
      return !this.showSeeAllButton;
    },
    hasRowData() {
      if(!this.data) return false;
      return (this.data && this.data.length > 0)
    },
  },

  methods: {
    displayData(data, key) {
      if (!isNaN(parseInt(data[key]))) {
        if (this.isValueCurrency) {
          return accounting.formatMoney(data[key] / 100);
        }
        return accounting.formatNumber(data[key]);
      }
      return data[key];
    },
    handleSeeAllClick() {
      this.$emit('handleSeeAll')
    },
    handleSeeLessClick() {
      this.$emit('handleSeeLess')
    },
    handleRowClick(index) {
      window.open(this.data[index].name)
    },

    getOrderTimeFromNow(orderDate) {
      return dayjs(orderDate).fromNow()
    },
  }
};
</script>

<style lang="scss" scoped>
.stats-list {
  width: 100%;

  .blurred {
    filter: blur(3px);
  }
  .see-all {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    .heading-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tooltip-icon {
        margin-top:2px;
      }
    }
    nav {
      cursor: pointer;
    }
  }

  .stats-content {
    height: calc(100% - 75px);
    min-height: 300px;
    overflow:scroll;

    &.sm-min {
      overflow:visible;
    }
    table {
      border-spacing: 0;
      border-top: 1px solid $skyBlueGrey400;
      thead {
        border-bottom: 1px solid $skyBlueGrey400;
        th {
          color: $blueGrey700;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 13px;
          line-height: 14px;
          font-weight: normal;
          text-align: left;
          padding: 19px 0;
          &.label {
            position: relative;
            width: 100%;
          }
          &.value {
            padding-right: 9px;
            white-space: nowrap;
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          &.clickable {
            cursor: pointer;
            &:hover {
              background: $purple100;
            }
          }
          td {
            border-top: 1px solid $skyBlueGrey400;
            font-size: 14px;
            line-height: 15px;
            color: $blueGrey800;
            padding: 16px 0;
            max-width: calc(300px);
            padding-right: 9px;
            
            .url-info {
              display: flex;
              align-items: center;
              .number {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: 27px;
                text-align: center;
                line-height: 1;
              }
              .name {
                max-width: calc(100% - 30px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .label {
              display: inline-block;
              vertical-align: middle;
              width: 100%;
              white-space: pre-wrap;
            }

            &.right {
              padding-left: 9px;
              min-width: 100px;
            }
          }
        }
      }
    }

    .current-state-section {
      display: flex;
      height: calc(100% - 53px);
      align-items: center;
      justify-content: center;
      border-top: 1px solid $skyBlueGrey400;

      // Make them look like center-aligned
      .message {
        position: relative;
        top: 120px;
      }
    }
  }
}
</style>
