var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'extended-sidebar-wrapper',
    _vm.$arMediaQuery.window.minWidth('md') && 'md-min',
    _vm.$arMediaQuery.window.maxWidth('md') && 'md-max',
    _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max',
    !_vm.layoutHasNoSidebar && 'with-sidebar'
  ],on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}},[_c('div',{class:[
      'extended-sidebar-content',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max',
      !_vm.displaySegmentDrawer && 'hide',
      'layout-has-no-sidebar' ],style:({
      transition: _vm.displaySegmentDrawer ? 'transform 0.3s' : 'transform 0.01s',
    })},[_c('SegmentSection')],1),_vm._v(" "),_c('div',{class:{ 'extended-sidebar-mask': true, hide: !_vm.displaySegmentDrawer },style:({
      width: '100vw',
      transition: '0.01s all',
    }),on:{"click":_vm.closeSidebar}})])}
var staticRenderFns = []

export { render, staticRenderFns }