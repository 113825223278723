var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"table-outer-wrapper",class:[
    'table-outer-wrapper',
    _vm.hasControlSection && 'top-edge-straight'
  ],on:{"mouseleave":_vm.handleTableMouseLeave}},[_c('div',{ref:"right-table-shadow",class:['table-shadow', 'right'],style:({
      boxShadow: _vm.hasRightTableShadow ? ("inset -15px 0 14px -16px " + (this.$arStyle.color.blueGrey700)) : null,
    })}),_vm._v(" "),_c('div',{ref:"left-table-shadow",class:['table-shadow', 'left'],style:({
      left: _vm.showTicks ? _vm.tickColumnWidth + _vm.firstColumnWidth + 'px' : 0,
      borderTopLeftRadius: _vm.showTicks ? null : '5px',
      boxShadow: _vm.hasLeftTableShadow ? ("inset 15px 0 14px -16px " + (this.$arStyle.color.blueGrey700)) : null,
    })}),_vm._v(" "),(_vm.isNoRows)?_c('div',{staticClass:"no-content"},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey600,
      }),attrs:{"size":"xs","text":_vm.emptyText,"align":"center"}})],1):_vm._e(),_vm._v(" "),(_vm.showTicks)?_c('div',{staticClass:"table-body-container checkboxes-container",style:({
          width: _vm.tickColumnWidth,
          position: 'absolute',
          left: '0',
          top: '52px',
          overflow: 'visible',
          zIndex: _vm.$arStyle.zIndex.regular,
        })},[_c('div',{class:['tbody', _vm.locked && 'locked']},[_c('div',{staticClass:"tr-container",style:({
              transform: ("translateY(" + _vm.computedTop + "px)")
            })},[_vm._l((_vm.visibleRows),function(row,rowIndex){return _c('div',{key:row[_vm.rowKeyName],staticClass:"tr-wrapper",attrs:{"data-test-id":("row-floated-" + (row[_vm.rowKeyName]))}},[_c('div',{class:['tr', row.class],style:({
                  cursor: _vm.enableRowClick ? 'pointer' : null,
                  background: _vm.checkRowActivity(row, rowIndex) ? _vm.$arStyle.color.purple100 : '#fff',
                  display: 'flex',
                }),on:{"mouseenter":function($event){return _vm.handleRowMouseEnter(rowIndex)}}},[_c('div',{staticClass:"td",style:({
                    minHeight: (_vm.rowHeight + "px"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: (_vm.tickColumnWidth + "px"),
                    cursor: 'pointer',
                    zIndex: _vm.$arStyle.zIndex.regular,
                  }),on:{"click":function($event){$event.stopPropagation();return _vm.handleRowTick(row)}}},[_c('ar-checkbox',{ref:"checkbox",refInFor:true,style:({ pointerEvents: 'none' }),attrs:{"value":_vm.tickedRowsMap[row[_vm.rowKeyName]]}})],1),_vm._v(" "),_c('div',{staticClass:"td",style:({ width: _vm.firstColumnWidth + 'px' }),on:{"click":function($event){$event.stopPropagation();return _vm.handleRowClick(row)}}},[_vm._t('name',null,{"data":row,"index":_vm.getRowIndex(row),"active":_vm.activeRowIndex === rowIndex})],2)])])}),_vm._v(" "),(_vm.loading)?_c('div',_vm._l((12),function(n){return _c('div',{key:n,staticClass:"tr-wrapper"},[_c('div',{staticClass:"tr tr--skeleton-white"},[(_vm.showTicks)?_c('div',{staticClass:"td",style:({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: (_vm.tickColumnWidth + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                  marginLeft: '1px',
                })},[_c('am2-typography-skeleton',{style:({ width: '20px' }),attrs:{"size":"md","level":n}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"td",style:({
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  width: ((_vm.headWidths[0] || _vm.minColumnWidth) + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                })},[_c('am2-typography-skeleton',{style:({ width: "45%", height: '12px', }),attrs:{"size":"md","level":n}})],1)])])}),0):_vm._e()],2)])]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
          priority: 1,
        }),expression:"{\n          priority: 1,\n        }"}],ref:"thead-real",class:[
        'table-header-outer-container',
        'thead',
        _vm.hasControlSection && 'top-edge-straight',
        _vm.showTicks && 'ticks'
      ],on:{"scroll":_vm.handleHeaderScroll}},[_c('div',{staticClass:"tr",style:({width: _vm.tableWidth + 'px'})},[(_vm.showTicks)?_c('div',{staticClass:"th th--checkbox th--checkbox-header",style:({
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            width: (_vm.tickColumnWidth + "px"),
            cursor: _vm.locked ? 'not-allowed' : 'pointer',
            position: 'sticky',
            padding: '0',
          }),attrs:{"data-test-id":"table-header-checkbox-th"},on:{"click":_vm.handleTickAllCheckboxClick}},[_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNoRows),expression:"!isNoRows"}],ref:"tick-all-checkbox",style:({
              pointerEvents: 'none',
            }),attrs:{"data-test-id":"table-header-checkbox","value":_vm.tickAllRows}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('div',{key:head.key,staticClass:"th",class:'th--' + head.sortKey,style:({
              cursor: _vm.resizingColumn !== null ? 'col-resize' : head.sortKey ? 'pointer' : null,
              width: ((_vm.headWidths[idx] || 150) + "px"),
              padding: _vm.customHeadPadding ? _vm.customHeadPadding : null,
            }),on:{"click":function (event) { return _vm.handleSortClick(head.sortKey, event); },"mouseenter":function($event){return _vm.handleCellMouseEnter(head)},"mouseleave":function($event){return _vm.handleCellMouseLeave(head)}}},[_c('div',{staticClass:"table-cell",style:({justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end'})},[((_vm.showTicks || idx > 0) && _vm.sortBy.key === head.sortKey && !!head.sortKey)?_c('ar-icon',{staticClass:"arrow",style:({
                  marginRight: "7px"
                }),attrs:{"name":"arrow","color":_vm.$arStyle.color.blueGrey700,"rotate":_vm.sortBy.order === 'asc' ? 180 : 0}}):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
                  paddingRight: head.align === 'right' && head.lock ? '10px' : null,
                  height: '18px',
                  top: '2px',
                  position: 'relative',
                }),attrs:{"size":"xs","text":head.name,"weight":"bold"}}),_vm._v(" "),(!_vm.showTicks && _vm.sortBy.key === head.sortKey && !!head.sortKey && idx == 0)?_c('ar-icon',{staticClass:"arrow",style:({
                  marginLeft: "7px",
                  marginRight: "0px"
                }),attrs:{"name":"arrow","color":_vm.$arStyle.color.blueGrey700,"rotate":_vm.sortBy.order === 'asc' ? 180 : 0}}):_vm._e(),_vm._v(" "),(head.lock)?_c('ar-icon',{staticClass:"lock",attrs:{"name":"lock","color":_vm.$arStyle.color.blueGrey600,"height":"14px"}}):_vm._e()],1),_vm._v(" "),(_vm.enableColumnResize)?_c('div',{staticClass:"table-header-edge",on:{"mousedown":function (event) { return _vm.handleTableResizeMouseDown(event, idx); },"touchstart":function (event) { return _vm.handleTableResizeMouseDown(event, idx); },"dblclick":function($event){return _vm.showContentColumnWidth(idx)}}},[_c('div',{staticClass:"table-header-edge__border"}),_vm._v(" "),_c('div',{staticClass:"table-header-edge__resizer",class:{'table-header-edge__resizer--active': _vm.resizingColumn === idx},style:(_vm.getResizeStyles(idx))})]):_vm._e()])})],2)]),_vm._v(" "),_c('div',{ref:"table-wrapper",staticClass:"table-wrapper",style:({
      height: _vm.totalHeight + 'px',
    })},[_c('table',{ref:"table-container",staticClass:"table-container",style:({
      borderBottom: _vm.loading ? 'none' : null,
      width: _vm.tableWidth + 'px',
      height: 'unset',
      transform: ("translateY(" + _vm.computedTop + "px)")
    })},[_c('thead',{style:({
        width: '100%',
        visibility: 'hidden',
      })},[_c('tr',[(_vm.showTicks)?_c('th',{style:({
                width: (_vm.tickColumnWidth + "px"),
              })}):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('th',{key:head.key,style:({
            width: ((_vm.headWidths[idx] || 150) + "px"),
          })})})],2)]),_vm._v(" "),_c('tbody',{ref:"tbody",staticClass:"tbody",on:{"mouseleave":function($event){return _vm.handleRowMouseLeave()}}},[_vm._l((_vm.visibleRows),function(row,rowIndex){return _c('tr',{key:row[_vm.rowKeyName],staticClass:"tr",style:({
          cursor: _vm.enableRowClick ? 'pointer' : null,
          background: _vm.checkRowActivity(row, rowIndex) ? _vm.$arStyle.color.purple100 : null,
        }),attrs:{"data-test-id":("row-" + (row[_vm.rowKeyName]))},on:{"mouseenter":function($event){return _vm.handleRowMouseEnter(rowIndex)},"click":function($event){$event.stopPropagation();return _vm.handleRowClick(row)}}},[(_vm.showTicks)?_c('td',{staticClass:"td",style:({ width: (_vm.tickColumnWidth + "px"), minHeight: (_vm.rowHeight + "px") })}):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head){return _c('td',{key:head.key,class:[
              'td',
              head.lock && 'blur'
            ],on:{"mouseenter":function($event){return _vm.handleCellMouseEnter(head)},"mouseleave":function($event){return _vm.handleCellMouseLeave(head)}}},[_vm._t(head.format,null,{"data":row,"index":_vm.getRowIndex(row),"head":head,"active":_vm.activeRowIndex === rowIndex})],2)})],2)}),_vm._v(" "),(_vm.loading)?_vm._l((12),function(n){return _c('tr',{key:n,staticClass:"tr"},[(_vm.showTicks)?_c('td',{staticClass:"td",style:({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: (_vm.tickColumnWidth + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                  marginLeft: '1px',
                })}):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('td',{key:head.key,staticClass:"td",style:({
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  width: ((_vm.headWidths[idx] || 150) + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                })},[_c('am2-typography-skeleton',{style:({ width: "45%", height: '12px', float: head.align === 'left' ? 'left' : 'right', }),attrs:{"size":"md","level":n}})],1)})],2)}):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }