var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"active-memebership-list"},[_c('am2-heading',{staticClass:"list-heading",style:({
      marginBottom: '24px',
    }),attrs:{"type":"h1","size":"md","title":"Active Memberships"}}),_vm._v(" "),(_vm.fanLoyaltyMemberships.length === 0 && !_vm.isFetchingLoyaltyMemberships)?_c('div',[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xs","text":"No active memberships"}})],1):_vm._e(),_vm._v(" "),(_vm.isFetchingLoyaltyMemberships)?_vm._l((1),function(n){return _c('div',{key:n,style:({
        marginBottom: '20px',
      })},[_c('ar-skeleton',{style:({
          height: '16px',
          width: '150px',
          borderRadius: '3px',
        }),attrs:{"level":3}})],1)}):_vm._e(),_vm._v(" "),_vm._l((_vm.fanLoyaltyMemberships),function(membership){return _c('div',{key:membership.oid,staticClass:"member-item-wrapper"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.getLoyaltyTierTitle(membership),
      }),expression:"{\n        content: getLoyaltyTierTitle(membership),\n      }",modifiers:{"top":true}}],staticClass:"member-item"},[_c('div',{staticClass:"icon-wrapper"},[_c('ar-icon',{attrs:{"name":membership.provider,"width":"16px","color":_vm.$arStyle.color[membership.provider]}})],1),_vm._v(" "),_c('ar-text',{attrs:{"size":"14px","text":_vm.getMembershipProviderCopy(membership)}})],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }