var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"purchases-table-wrapper"},[_c('am2-table',{ref:"purchases-table",staticClass:"purchases-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.body,"loading":_vm.loading,"empty-text":"No purchase data available","enable-row-click":"","default-sort-by":_vm.orderBy,"locked":_vm.locked},on:{"sortBy":_vm.handleSortByChange,"rowClick":_vm.handlePurchaseRowClick},scopedSlots:_vm._u([{key:"description",fn:function(ref){
var data = ref.data;
return _c('div',{staticClass:"cell cell-left"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.getDescription(data)}})],1)}},{key:"source",fn:function(ref){
var data = ref.data;
return _c('div',{staticClass:"cell cell-left"},[_c('section',{style:({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
      })},[_c('ar-icon',{staticClass:"avatar",attrs:{"type":"primary","name":data.source.icon,"color":data.source.iconColor,"width":"20px","height":"20px"}}),_vm._v(" "),_c('div',{style:({marginLeft: '12px'})},[_c('ar-text',{staticClass:"name-text",attrs:{"size":"xs","multiple-lines":"","text":data.source.platform}}),_vm._v(" "),_c('ar-text',{staticClass:"name-text",style:({
              color: _vm.$arStyle.color.skyBlueGrey700,
              paddingTop: '2px',
            }),attrs:{"size":"xxxs","multiple-lines":"","text":data.source.description}})],1)],1)])}},{key:"quantity",fn:function(ref){
            var data = ref.data;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":data.quantity}})],1)}},{key:"totalPrice",fn:function(ref){
            var data = ref.data;
return _c('div',{staticClass:"cell"},[_c('ar-text',{style:({
          textDecoration: data.status === 'Cancelled' ? 'line-through' : null,
        }),attrs:{"size":"xs","text":_vm.getTotalPrice(data)}})],1)}},{key:"orderId",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":data.orderId}})],1)}},{key:"status",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"cell status-cell"},[_c('am2-tag',{style:({
          textTransform: 'capitalize',
          border: '1px solid transparent',
        }),attrs:{"type":_vm.getTagColor(data.status),"text":data.status}})],1)}},{key:"purchaseDateTime",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.prettyTime(data.purchaseDateTime)
        }),expression:"{\n          content: prettyTime(data.purchaseDateTime)\n        }",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.timeAgo(data.purchaseDateTime)}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }