<template>
  <am2-tag
    :text="text"
    :type="type"
    shape="round"
    :icon-name="iconName"
    :icon-animation="iconAnimation"
    :icon-props="{
      height: '10px',
    }"
    :style="{
      padding: `8px 15px 8px 15px`,
    }"
  />
</template>

<script>
export default {
  name: 'GenericTag',
  props: {
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
    },
    iconAnimation: {
      type: String,
    },
    type: {
      type: String,
      default: 'gray',
    }
  },
};
</script>
