<template>
  <div
    class="compare-events-wrapper"
    :class="[!hasData || disabled ? 'disabled' : '']"
    v-click-outside="closeAndReset"
    tabindex="0"
    @keydown.esc="handleCompareEscape"
  >
    <div class="compare-events-button" @click="toggleDropdown" data-test-id="compare-events-button">
      <ar-text
        class="compare-events-text"
        size="xs"
        text="Compare events"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ar-icon
        width="10px"
        name="arrow"
        class="icon-arrow"
        :rotate="compareEventsOpen ? 180 : 0"
      />
    </div>

    <div 
      v-show="compareEventsOpen" 
      ref="compareDropdown"
      class="compare-event-items-wrapper"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keyup.enter.space="onEnter"
    >
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>
      <div class="compare-events-container">
        <am2-search
          ref="searchInput"
          class="compare-events-search"
          v-model="compareSearchText"
          placeholder="Search events..."
          data-test-id="compare-events-search"
        />
        <ar-link-button
          class="compare-deselect-button"
          text="Deselect all"
          :has-underline="true"
          :color="$arStyle.color.$purple500"
          @click="handleEventCompareDeselect"
          data-test-id="compare-events-deselect"
        />

        <div class="compare-options-wrapper" ref="compareContainer">
          <template v-if="options">
            <div class="scroll-gradient-top" />
            <a 
              v-for="(option, index) in options"
              :key="option.oid"
              class="compare-option-wrapper"
              :class="[
                arrowCounter === index ? 'hover' : '',
              ]"
              @click="selectCompareOption(option.oid)"
              :data-test-id="`compare-option-${option.oid}`"
            >
              <ar-checkbox
                :ref="`compareCheckbox${option.oid}`"
                v-model="compareIdsLocal[option.oid]"
                class="compare-event-checkbox"
                :data-test-id="`compare-checkbox-${option.oid}`"
              />
              <div class="compare-copy-wrapper">
                <ar-text
                  class="compare-option-event-name"
                  size="xs"
                  :text="option.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="bold"
                />
                <ar-text
                  class="compare-option-date-string"
                  size="xs"
                  :text="option.dateString"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                />
                <ar-text
                  class="compare-option-date-string"
                  size="xs"
                  :text="option.location"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                />
              </div>
            </a>
          </template>
        </div>
      </div>

      <div class="compare-from-controls-wrapper">
        <div class="compare-checkbox-wrapper">
          <span class="compare-text">Compare from: </span>
          <ar-checkbox
            class="start-radio"
            type="radio"
            label="Start"
            ticked-value="start"
            v-model="compareFromVal"
            data-test-id="compare-start-radio"
          />
          <ar-checkbox
            class="end-radio"
            type="radio"
            label="End"
            ticked-value="end"
            v-model="compareFromVal"
            data-test-id="compare-end"
          />
        </div>
        <ar-simple-button
          class="compare-button"
          text="Compare"
          :style="{
            padding: '13px 16px',
            height: 'unset',
          }"
          @click="handleCompare"
          data-test-id="compare-from-button"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompareEventsDropdown',
  props: {
    hasData: {
      type: Boolean,
      default: false,
    },
    compareIds: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    compareFrom: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  model: {
    prop: 'compareIds',
    event: 'selection',
  },
  computed: {
    compareIdsLocal: {
      get: function() {
        return this.compareIds
      },
      set: function(value) {
        this.$emit('selection', value)
      }
    }
  },
  data() {
    return {
      compareEventsOpen: false,
      compareSearchText: '',
      initialOptions: [],
      compareFromVal: 'start',
      compareItems: {},
      arrowCounter: -1,
    }
  },
  watch: {
    compareIds() {
      if (!Object.keys(this.compareIds).length) {
        this.compareSearchText = ''
      }
    },
    compareSearchText() {
      this.$emit('filter', this.compareSearchText)
    },

    compareFromVal() {
      this.$emit('compare-from-update', this.compareFromVal)
    },

    options(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.initialOptions = newVal
      }
      this.arrowCounter = -1
    }
  },
  methods: {
    onArrowDown() {
      if (this.options.length === 0) {
        return
      }

      if (this.arrowCounter < this.options.length - 1) {
        this.arrowCounter++
        this.scrollOptions()
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--
        this.scrollOptions()
      }
    },
    onEnter(e) {
      e.preventDefault()
      let selection = this.options[this.arrowCounter]
      if (!selection) {
        this.$refs.searchInput.$el.children[0].children[1].click()
        return
      }
      this.selectCompareOption(selection.oid)
    },
    scrollOptions() {
      let compareContainer = this.$refs.compareContainer
      let compareItemHeight = compareContainer.children[1].clientHeight
      compareContainer.scrollTop = compareItemHeight * (this.arrowCounter - 3)
    },
    handleEventCompareDeselect() {
      this.$emit('clear')
      this.arrowCounter = 0
      this.compareSearchText = ''
      this.$refs.searchInput.$el.children[0].children[1].click()
    },

    handleCompare() {
      this.compareEventsOpen = false
      this.$emit('compare')
      this.arrowCounter = -1
    },
    
    selectCompareOption(oid) {
      this.$refs[`compareCheckbox${oid}`][0].toggle()
      this.$refs.searchInput.$el.children[0].children[1].click()
    },

    closeAndReset() {
      if (this.compareEventsOpen) {
        this.compareEventsOpen = false
        this.compareSearchText = ''
        this.arrowCounter = -1
      }
    },

    handleCompareEscape() {
      if (!!this.compareSearchText) {
        this.compareSearchText = ''
      } else {
        this.closeAndReset()
        this.$emit('clear')
      }      
    },

    toggleDropdown() {
      this.compareEventsOpen = !this.compareEventsOpen
      this.compareEventsOpen && this.$refs.searchInput.$el.children[0].children[1].click()
    },
  }
}
</script>
<style lang="scss" scoped>

$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: ui-px2em(1) solid $borderColor;
  border-radius: 5px; 
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}

.compare-events-wrapper {
  position: relative;    
  outline: none;

  &.disabled {
    pointer-events: none;

    .compare-events-button {
      .compare-events-text {
        color: $disabledColor;
      }

      .icon-arrow {
        color: $disabledColor;
      }            
    }
  }

  .compare-events-button {
    @include flexRow;
    border: ui-px2em(1) solid $borderColor;
    border-radius: 4px;
    padding: ui-px2em(8) ui-px2em(14);
    background: white;
    margin-right: 7px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .compare-events-text {
      margin-right: 5px;
    }

    .icon-arrow {
      margin-top: 2px;
    }
  }

  .compare-event-items-wrapper {
    @include dropdownOptionsStyle;
    position: absolute;
    z-index: 999;
    width: 516px;
    top: 50px;
    left: -180px;
    min-height: 200px;
    padding: ui-px2em(12) 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    .triangle {
      position: absolute;
      width: 10px;
      top: -5px;
      left: 275px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid #d8dde3;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid white;
        transform: translateY(10%);
      }
    }

    .compare-events-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .compare-events-search {
        width: 100%;
        margin-bottom: 10px;
        padding: 0 ui-px2em(12);

        &:focus {
          outline: none;
        }
      }

      .compare-deselect-button {
        align-self: flex-end;
        padding: 0 ui-px2em(10);
      }

      .compare-options-wrapper {
        width: 100%;
        max-height: 336px;
        overflow: scroll;
        margin-top: 7px;

        .compare-option-wrapper {
          @include flexRow;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: ui-px2em(9) ui-px2rem(15);
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          .compare-event-checkbox {
            margin-right: 15px;
            cursor: pointer;
            pointer-events: none;
          }
        }

        .compare-copy-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          cursor: default;

          .compare-option-date-string {
            color: #c1c6cf;
          }
        }
      }
    }          

    .compare-from-controls-wrapper {
      @include flexRow;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      padding: ui-px2em(14) ui-px2em(14);
      border-top: ui-px2em(1) solid $blueGrey500;

      .compare-checkbox-wrapper {
        .compare-text {
          margin-right: 11px;
        }
        .start-radio {
          margin-right: 12px;
        }
      }
      .compare-button {
          &:focus {
            outline:lightpink;
          }
        }
    }
  }
}
</style>