<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="message-list-preferences-modal"
      data-test-id="message-list-preferences-modal"
      header="List settings"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div slot="body" class="body">
        <div
          :class="[
            'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        > 
          <am2-card-container
            class="u-display-flex u-align-items-center u-justify-content-space-between u-padding-y-6 u-padding-x-7 u-margin-top-4"
          >
            <div class="u-display-flex u-flex-flow-column">
              <ar-text
                size="xs"
                weight="bold"
                text="Disable Manage Subscription Preferences"
              />
              <ar-text
                size="xs"
                weight="normal"
                :style="{
                  color: $arStyle.color.blueGrey700,
                  paddingTop: '8px',
                }"
                text="Removes subscription preferences on unsubscribe link"
              />
            </div>
            <am2-switch
              v-model="enabled"
            />
          </am2-card-container>
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'footer',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]">
        <ar-simple-button
          text="Save"
          @click="handleSaveClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MessageListPreferencesModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      enabled: true,
    }
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),

    ...mapGetters({
      isSubscriptionPreferenceEnabled: 'messageList/isSubscriptionPreferenceEnabled',
    }),
  },

  watch: {
    isShow() {
      this.enabled = this.isSubscriptionPreferenceEnabled;
    },
    isSubscriptionPreferenceEnabled() {
      this.enabled = this.isSubscriptionPreferenceEnabled;
    }
  },

  methods: {
    ...mapActions([
      'messageList/UPDATE_SUBSCRIPTION_PREFERENCE',
    ]),

    handleClose() {
      this.$emit('close');
    },

    handleSaveClick() {
      this['messageList/UPDATE_SUBSCRIPTION_PREFERENCE']({
        promoterOid: this.promoterOid,
        enabled: this.enabled,
      });

      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.message-list-preferences-modal {
  .header {
    font-size: 20px;  
  }

  .body {
    .modal-content {
      padding: 29px 30px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>