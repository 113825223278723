<template>
  <am2-card-container
    class="plan-purchase-card"
    :style="{
      border: 'none',
    }"
  >
    <am2-loading-section
      v-if="isFetchingCurrentPaymentPlan || isFetchingPromoterAudienceCount"
      class="loading-section"
    />
    <div v-else class="content-section">
      <!-- Title -->
      <div class="title-section">
        <ar-text
          size="md"
          :text="planName"
          align="center"
          weight="bold"
        />
        <div class="title-section-subtitle-section" >
          <ar-text
            size="xs"
            :text="fromPriceCopy"
          />
          <ar-text
            size="xs"
            :text="changePlanHtml"
            allow-html
            :style="{
              marginLeft: '4px',
              color: $arStyle.color.blueGrey700,
            }"
            @anchorClick="handleChangePlanClick"
          />
        </div>
      </div>
      <ar-divider
        class="first-divider"
      />
      <!-- Number of Contacts -->
      <div class="contacts-section">
        <div
          class="contacts-question-section"
        >
          <ar-text
            size="xs"
            text="How many contacts do you have?"
          />
          <ar-icon
            name="tooltip-question-mark"
            :color="$arStyle.color.skyBlueGrey600"
            width="16px"
            v-tooltip.top="{
              content: `You will be charged based on the number of contacts you have`
            }"
            :style="{
              marginLeft: '10px',
            }"
          />
        </div>
        <am2-slider-input
          v-model="tierIndex"
          :min="0"
          :max="planTiers.length - 1"
          track-color="purple"
          :style="{
            marginTop: '10px',
          }"
        />
        <div class="contacts-number-display-section">
          <ar-text
            size="xs"
            :text="contactsNumberCopy"
          />
          <div
            :style="{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '99px',
              height: '30px',
              borderLeft: `1px solid ${$arStyle.color.blueGrey500}`,
            }"
          >
            <ar-text
              size="xs"
              text="Contacts"
            />
          </div>
        </div>
      </div>
      <ar-divider
        class="second-divider"
      />
      <!-- Select Period and Payment Method -->
      <div class="purchase-section">
        <div
          class="money-to-pay-section"
        >
          <ar-text
            size="lg"
            :text="finalPriceCopy"
            :style="{
              color: $arStyle.color.purple500,
            }"
          />
          <ar-text
            size="xs"
            :text="finalPriceUnitCopy"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
            class="u-margin-top-3"
          />
        </div>
        <div
          v-if="displayErrorMessage"
          class="u-margin-top-5"
        >
          <ar-text
            v-if="isTierOverLimitation"
            size="xs"
            :text="overLimitationCopy"
            allow-html
            multiple-lines
            align="center"
          />
          <ar-text
            v-else-if="isTierUnderLimitation"
            size="xs"
            :text="underLimitationCopy"
            allow-html
            multiple-lines
            align="center"
            class="u-margin-top-2"
          />
        </div>
        <ar-simple-button
          v-if="displayContactSalesButton"
          type="gradient-purple"
          text="Contact Sales"
          class="u-margin-top-8 u-width-100-percent"
          @click="handleContactSalesClick"
        />
        <ar-simple-button
          v-else
          type="gradient-purple"
          :text="paymentSubscription ? 'Update plan' : 'Purchase Plan'"
          :loading="isCreatingPaymentSubscription"
          :disabled="purchasePlanButtonDisabled"
          v-tooltip.top="{
            content: purchasePlanButtonDisabled ? `You must add a credit card to Purchase Plan` : null,
            hideOnTargetClick: false,
          }"
          class="u-margin-top-8 u-width-100-percent"
          @click="handlePurchasePlanClick"
        />
        <!-- Start to select card -->
        <am2-payment-source-section
          :payment-source-puid="selectedPaymentSourcePuid"
          :loading="!isPaymentSourceSectionReady"
          @select="handlePaymentMethodSelect"
          class="u-margin-top-5"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import accounting from 'accounting';
import { getMinimumRequiredPlanTierByNumberOfContacts, getBasePaymentPlanTier } from '@/utils/payment/';

export default {
  name: 'PlanPurchaseCard',
  props: {
    paymentPlanOid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      changePlanHtml: `(<a style="color: ${this.$arStyle.color.blueGrey700};">Change</a>)`,
      tierIndex: 0,
      selectedPaymentSourcePuid: null, // Payment source puid used to select item in ar-payment-source-section
      selectedPaymentSourceOid: null, // Payment source oid used to purchase or update plan
      isPaymentSourceSectionReady: false, // If it's not ready, make ar-payment-source-section loading
      paymentSubscription: null,
      isCreatingPaymentSubscription: false,
    };
  },
  computed: {
    ...mapState({
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      currentPaymentPlan: state => state.payment.currentPaymentPlan,
      isFetchingCurrentPaymentPlan: state => state.payment.isFetchingCurrentPaymentPlan,
      promoterAudienceCount: state => state.audience.promoterAudienceCount,
      isFetchingPromoterAudienceCount: state => state.audience.isFetchingPromoterAudienceCount,
    }),
    ...mapGetters({
      primaryPaymentSource: 'payment/primaryPaymentSource',
    }),
    planName() {
      if (!this.currentPaymentPlan) return null;
      return this.currentPaymentPlan.additionalInfo.invoiceName || this.currentPaymentPlan.name;
    },
    planTiers() {
      if (!this.currentPaymentPlan) {
        return [];
      }
      return this.currentPaymentPlan.additionalInfo.tiers.filter( tier => tier.startingUnit <= this.allowedPlanTierQuantity.max + 1);
    },
    minumunRequiredPlanTier() {
      if (!this.currentPaymentPlan) {
        return null;
      }
      return getMinimumRequiredPlanTierByNumberOfContacts({
        paymentPlan: this.currentPaymentPlan,
        numberOfContacts: this.promoterAudienceCount,
      });
    },
    allowedPlanTierQuantity() {
      let maxQuantity = 50000;
      let minQuantity = 1;

      // If we got limit from backend, we use it
      if (this.currentPaymentPlan && this.currentPaymentPlan.additionalInfo?.metaData?.quantity) {
        maxQuantity = this.currentPaymentPlan.additionalInfo.metaData.quantity.max;
        minQuantity = this.currentPaymentPlan.additionalInfo.metaData.quantity.min;
      }
      // We also need to update lower limit based on how many contacts you currently have
      if (this.minumunRequiredPlanTier) {
        // Pick the greatest one and make it the min quantity
        minQuantity = Math.max(minQuantity, this.minumunRequiredPlanTier.endingUnit || this.promoterAudienceCount);
      }
      return {
        max: maxQuantity,
        min: minQuantity,
      };
    },
    selectedPlanTier() {
      return this.planTiers.length === 0 ? null : this.planTiers[this.tierIndex];
    },
    isTierOverLimitation() {
      if (!this.selectedPlanTier) {
        return false;
      }
      if (!this.selectedPlanTier.endingUnit) {
        // No upper limit, assume it is over
        return true;
      } else {
        return this.selectedPlanTier.endingUnit > this.allowedPlanTierQuantity.max;
      }
    },
    isTierUnderLimitation() {
      if (!this.selectedPlanTier) {
        return false;
      }
      return this.selectedPlanTier.endingUnit < this.allowedPlanTierQuantity.min;
    },
    overLimitationCopy() {
      return `If you wish to have an audience limit of <b>more than ${accounting.formatNumber(this.allowedPlanTierQuantity.max)}</b>, please contact sales`;
    },
    underLimitationCopy() {
      return `Your ${accounting.formatNumber(this.promoterAudienceCount)} contacts exceeds the plan's maximum. Please pick a higher limit`;
    },
    minimunPrice() {
      return this.planTiers.length === 0 ? null : accounting.formatMoney(this.planTiers[0].price / 100, null, 0);
    },
    fromPriceCopy() {
      return !this.minimunPrice ? null : `from ${this.minimunPrice} /${this.billingPeriod === 'year' ? 'year' : 'mo'}`;
    },
    displayErrorMessage() {
      return this.isTierUnderLimitation || this.isTierOverLimitation;
    },
    finalPriceCopy() {
      if (this.isTierOverLimitation) {
        return 'POA';
      }
      return this.planTiers.length === 0 ? null : `${accounting.formatMoney(this.planTiers[this.tierIndex].price / 100, null, 0)}`;
    },
    finalPriceUnitCopy() {
      if (!this.currentPaymentPlan) { return null; }
      const currency = this.currentPaymentPlan.currency;
      return this.billingPeriod ==='year' ? `Per year (${currency})` : `Per month (${currency})`;
    },
    contactsNumberCopy() {
      if (this.planTiers.length === 0) {
        return null;
      }
      if (this.planTiers[this.tierIndex].endingUnit && this.planTiers[this.tierIndex].endingUnit <= this.allowedPlanTierQuantity.max) {
        return accounting.formatNumber(this.planTiers[this.tierIndex].endingUnit);
      } else {
        return `More than ${accounting.formatNumber(this.planTiers[this.tierIndex].startingUnit - 1)}`;
      }
    },
    billingPeriod() {
      return !this.currentPaymentPlan ? null : this.currentPaymentPlan.billingPeriod;
    },
    displayContactSalesButton() {
      return this.isTierOverLimitation;
    },
    purchasePlanButtonDisabled() {
      return !this.selectedPlanTier
        || !this.selectedPaymentSourcePuid
        || this.isTierUnderLimitation;
    },
  },
  watch: {
    primaryPaymentSource(paymentSource) {
      // If you already got selected payment source puid from subscription,
      // then no need to set it to default payment source
      if (this.selectedPaymentSourcePuid) { return; }
      this.selectedPaymentSourcePuid = paymentSource ? paymentSource.puid : null;
    },
    paymentSubscriptionList() {
      this.paymentSubscription = this.paymentSubscriptionList.find(({ pplanId }) => pplanId === this.currentPaymentPlan.puid);

      // Only when you have subscription
      if (this.paymentSubscription) {
        // Update tierIndex
        const defaultTier = getBasePaymentPlanTier({
          paymentPlan: this.currentPaymentPlan,
          planQuantity: this.paymentSubscription.additionalInfo.planQuantity,
        });
        this.tierIndex = this.planTiers.indexOf(defaultTier);
        this.selectedPaymentSourcePuid = this.paymentSubscription.ppaymentSourceId;
      } else if (this.minumunRequiredPlanTier) {
        this.tierIndex = this.planTiers.indexOf(this.minumunRequiredPlanTier);
      }
    },
  },
  async created() {
    await Promise.all([this['payment/FETCH_CURRENT_PAYMENT_PLAN'](this.paymentPlanOid), this['payment/FETCH_PAYMENT_CUSTOMER']()], this.fetchNumberOfContacts());
    this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']();

    this.isPaymentSourceSectionReady = true;
  },
  methods: {
    ...mapActions([
      'audience/FETCH_PROMOTER_AUDIENCE_COUNT',
      'payment/FETCH_CURRENT_PAYMENT_PLAN',
      'payment/FETCH_PAYMENT_CUSTOMER',
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'CREATE_PAYMENT_SUBSCRIPTION',
      'UPDATE_PAYMENT_SUBSCRIPTION',
    ]),
    ...mapMutations([
      'payment/SET_PAYMENT_CUSTOMER',
    ]),

    async fetchNumberOfContacts() {
      await this['audience/FETCH_PROMOTER_AUDIENCE_COUNT']({});
    },
    handleContactSalesClick() {
      window.open('https://audiencerepublic.com/contact-us?type=organizer');
    },
    handleChangePlanClick(idx) {
      if (idx !== 0) {
        return;
      }
      this.$router.push({
        path: '/plans',
      });
    },
    handlePaymentMethodSelect(paymentSource) {
      this.selectedPaymentSourcePuid = paymentSource.puid;
      this.selectedPaymentSourceOid = paymentSource.oid;
    },
    async handlePurchasePlanClick() {
      try {
        this.isCreatingPaymentSubscription = true;

        const basePlanSubscription =
          this.paymentSubscriptionList.find(item => {
            return item.paymentPlan.type === 'base';
          });

        if (basePlanSubscription) {
          // If a paymentSubscription already exists, then only do an update
          await this.UPDATE_PAYMENT_SUBSCRIPTION({
            planOid: this.paymentPlanOid,
            planQuantity: this.selectedPlanTier.endingUnit,
            paymentSourceOid: this.selectedPaymentSourceOid,
            subscriptionOid: basePlanSubscription.oid,
          });
        } else {
          // Otherwise, it means they've never made one before, so lets create it for the first time
          await this.CREATE_PAYMENT_SUBSCRIPTION({
            planOid: this.paymentPlanOid,
            planQuantity: this.selectedPlanTier.endingUnit,
            paymentSourceOid: this.selectedPaymentSourceOid,
          });
        }

        this.$arNotification.push({
          type: 'success',
          message: `Successfully subscribed ${this.planName} plan`,
        });
        this.$router.push({
          path: '/settings/subscriptions',
        });
      } catch (e) {
        console.error(e);
        if(e.response && e.response.status === 422) {
          this.$arNotification.push({
            type: 'error',
            message: 'Your selected payment method has been declined. Please update it and try again',
          });
        } else {
          this.$arNotification.push({
            type: 'error',
            message: `Failed to subscribe ${this.planName} plan`,
          });
        }
      } finally {
        this.isCreatingPaymentSubscription = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-purchase-card {
  box-shadow: 0 0 15px 0 rgba($blueGrey800, 0.1);
  overflow: hidden;

  .loading-section {
    height: 300px;
  }

  .content-section {
    padding: 25px 30px;

    .title-section {
      .title-section-subtitle-section {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .first-divider {
      margin: 32px 0;
      background: $skyBlueGrey400;
    }

    .contacts-section {
      .contacts-question-section {
        display: flex;
        align-items: center;
      }

      .contacts-number-display-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $blueGrey500;
        border-radius: 3px;
        height: 50px;
        margin-top: 10px;
        padding-left: 20px;
      }
    }

    .second-divider {
      margin: 30px 0 26px;
      background: $skyBlueGrey400;
    }

    .purchase-section {
      .money-to-pay-section {
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }
  }
}
</style>
