<template>

    <div
      v-infinite-scroll="loadMoreRecipients"
      infinite-scroll-disabled="disableLoadMoreRecipients"
      infinite-scroll-distance="10"
    >
    <!-- When you scroll down to the bottom of the page loadMoreRecipients will be triggered -->
      <section
        class="message-center-recipients"
      >

        <div v-if="clickTrackingDisabled" class="link-tracking-wrapper">
          <div class="link-tracking-container">
            <ar-snackbar
              class="u-margin-top-5"
              type="warning"
              message="Click tracking is disabled on this message"
            />
          </div>
        </div>

        <div class="sent-numbers-wrapper">
          <div class="sent-numbers-container">
            <am2-tag type="purple" :text="messageStatus" />
            <ar-text size="xs" :text="messageStatsCount" :style="{ color: $arStyle.color.skyBlueGrey700, marginLeft: '15px' }" />
          </div>
        </div>

        <MessageOverviewStats
          v-if="currentSelectedMessage"
          :selected-message="currentSelectedMessage"
          :status-details="statusDetails"
          @tab-click="handleOverviewTabClick"
        />

        <div
          v-ar-sticky-top="{
            priority: 1,
          }"
        >
          <TableControl
            :recipient-count="totalRecipientsCount"
            :dropdown-items="dropdownItems"
            @search="handleSearch"
            @clear="handleSearchClear"
          />
        </div>

        <MessageTable
          :table-headers="recipientTableHead"
          :table-data="tableData"
          :loading="isFetchingRecipients"
          :enable-row-click="customerProfilesEnabled"
          @sort="handleSortByChange"
          @row-click="handleRowClick"
        />
      </section>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import accounting from 'accounting';
import { convertToTitleCase } from '@/utils/helpers';
import MessageOverviewStats from './message-overview-stats.vue'
import TableControl from './message-table/table-control.vue'
import MessageTable from './message-table/message-table.vue'

export default {
  name: 'MessageViewRecipients',
  components: {
    MessageOverviewStats,
    TableControl,
    MessageTable,
  },
  data() {
    return {
      messageStats: null,
      isFetchingMessageStats: null,
      recipientsSelectKeys: ['fan', 'status', 'sysMtime'],
      recipientsExportSelectKeys: ['firstName','lastName','emailAddress','lastStatus','lastActivity'],
      sortRecipientBy: { key: 'sysMtime', order: 'desc' },
      recipientsStatus: null,
      searchString: null,
    };
  },

  watch: {
    currentSelectedMessage(newVal, oldVal) {
      if (newVal && !oldVal) {
        if (newVal.provider === 'sms') {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
        } else {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
        }
      }
    }
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isFetchingMessage: state => state.message.isFetchingMessage,

      recipients: state => state.recipient.recipients,
      isExportingListRecipients: state => state.recipient.isExportingListRecipients,
      totalRecipientsCount: state => state.recipient.totalRecipientsCount,
      isFetchingRecipients: state => state.recipient.isFetchingRecipients,
      isNoMoreRecipients: state => state.recipient.isNoMoreRecipients,
      hasFetchRecipientsFailed: state => state.recipient.hasFetchRecipientsFailed,
    }),
    disableLoadMoreRecipients() {
      return this.isFetchingRecipients || this.isNoMoreRecipients || this.hasFetchRecipientsFailed;
    },

    searchStringTargets() {
      if (!this.currentSelectedMessage) return null;
      return this.currentSelectedMessage.provider === 'sms' ? ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]', 'fan[mobileNumber]'] : ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]']
    },

    // Items which appear in the '...' dropdown button
    dropdownItems() {
      let dropdownActions = []

      dropdownActions.push({
        "name": "Export as CSV",
        "action": this.handleExportClick
      })

      return dropdownActions
    },

    clickTrackingDisabled() {
      if (!this.currentSelectedMessage) {
        return false;
      }
      return this.currentSelectedMessage.meta.disableLinkTracking;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    recipientTableHead() {
      const head = [
        {
          name: 'Name',
          key: 'name',
          format: 'name',
          attributeKey: 'firstName,lastName',
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          attributeKey: 'emailAddress',
        },
        {
          name: 'Status',
          key: 'status',
          format: 'status',
          sortKey: 'status',
          attributeKey: 'status',
        },
      ];

      let lastClickHead = {
        name: 'Last open / click',
        key: 'sysMtime',
        format: 'lastActiveTime',
        sortKey: 'sysMtime',
        attributeKey: 'sysMtime',
      };

      if (this.currentSelectedMessage && this.currentSelectedMessage.provider === 'sms') {
        head.splice(2, 0, {
          name: 'Mobile number',
          key: 'mobileNumber',
          format: 'text',
          attributeKey: 'mobileNumber',
        });

        lastClickHead.name = 'Last click';
      }

      head.push(lastClickHead);

      return head;
    },

    messageStatus() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.status) {
        return '';
      } else if (this.currentSelectedMessage.status === 'completed') {
        return 'Sent';
      } else if (this.currentSelectedMessage.status === 'in-progress') {
        return 'Sending';
      } else {
        return convertToTitleCase(this.currentSelectedMessage.status.replace(/-/, ' '));
      }
    },

    messageStatTotalSuccessfulSends() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return 0;
      }
      return (this.currentSelectedMessage.statusDetails.totalMessages || 0)
        - (this.currentSelectedMessage.statusDetails.bounced || 0)
        - (this.currentSelectedMessage.statusDetails.deferred || 0)
        - (this.currentSelectedMessage.statusDetails.failed || 0);
    },

    messageStatsCount() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return null;
      }
      return `${accounting.formatNumber(this.messageStatTotalSuccessfulSends)} of ${accounting.formatNumber(this.currentSelectedMessage.statusDetails.totalMessages)} sent`;
    },

    // Use message stats endpoint, but fall back to message->statusDetails if there are any issues
    statusDetails() {
      if (!this.messageStats && (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails)) {
        return {};
      }

      return this.messageStats || this.currentSelectedMessage.statusDetails;
    },

    tableData() {
      return this.recipients.map(recipient => ({
        firstName: recipient.fan.firstName,
        lastName: recipient.fan.lastName,
        emailAddress: recipient.fan.emailAddress,
        mobileNumber: recipient.fan.mobileNumber,
        status: convertToTitleCase(recipient.status ? recipient.status.replace(/-/, ' ') : ''),
        lastActiveTime: recipient.sysMtime,
        oid: recipient.fan.oid,
      }));
    },
  },

  async mounted() {

    if (!!this.currentSelectedMessage) {
      this.fetchMessageStats(this.oid);
      this.reloadRecipients();

      if (this.currentSelectedMessage.provider === 'sms') {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
      } else {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
      }
    }
  },

  beforeDestroy() {
    this['message/RESET_CURRENT_SELECTED_MESSAGE']();
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['recipient/RESET_RECIPIENTS']();
  },

  methods: {

    ...mapActions([
      'recipient/EXPORT_MESSAGE_RECIPIENTS_CSV',
      'FETCH_MESSAGE_STATS_BY_OID',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'recipient/FETCH_MESSAGE_RECIPIENTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
    ]),

    ...mapMutations([
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'campaign/RESET_CURRENT_CAMPAIGN',
      'recipient/RESET_RECIPIENTS',
    ]),

    handleExportClick() {
      if (this.isExportingListRecipients) { return }

      this['recipient/EXPORT_MESSAGE_RECIPIENTS_CSV']({
        selectKeys: this.recipientsExportSelectKeys,
        taskOid: this.oid,
        orderBy: this.sortRecipientBy,
        searchString: this.searchString,
      })
    },

    async fetchMessageRecipients (recipientsStatus, fetchCount = true, orderBy = null, searchString = null, searchStringTargets = null, reload = false) {
      await this['recipient/FETCH_MESSAGE_RECIPIENTS']({
        messageTaskOid: this.oid,
        selectKeys: this.recipientsSelectKeys,
        status: recipientsStatus,
        fetchCount,
        orderBy,
        searchString,
        searchStringTargets,
        reload
      });
    },

    async reloadRecipients() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }

      this.fetchMessageRecipients(recipientsStatus, true, this.sortRecipientBy, this.searchString, this.searchStringTargets, true);
    },
    async loadMoreRecipients() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }
      this.fetchMessageRecipients(recipientsStatus, true, this.sortRecipientBy, this.searchString, this.searchStringTargets, false);
    },
    async fetchMessageStats(oid) {
      try {
        this.isFetchingMessageStats = true;
        const messageStats = await this.FETCH_MESSAGE_STATS_BY_OID({oid});
        if (messageStats) {
          this.messageStats = messageStats;
        }
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch message stats' });
      } finally {
        this.isFetchingMessageStats = false;
      }
    },
    async fetchMessageList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid});
      return true;
    },
    fetchMessageSender(oid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid,
        allowDeleted: true,
      });
    },
    handleOverviewTabClick(item) {
      this.recipientsStatus = item.key

      this.reloadRecipients()
      this.fetchMessageStats(this.oid)
      this.fetchMessageList(this.currentSelectedMessage.meta.messageListOid)
      if (this.currentSelectedMessage.meta?.email) {
        this.fetchMessageSender(this.currentSelectedMessage.meta.email.promoterPropertyOid)
      }
    },
    handleSortByChange(sortBy) {
      this.sortRecipientBy = sortBy;
      this.reloadRecipients();
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleSearch(searchString) {
      this.searchString = searchString
      this.reloadRecipients()
    },
    handleSearchClear() {
      this.searchString = ''
      this.reloadRecipients()
    },

    async reloadRecipients() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }

      this.fetchMessageRecipients(recipientsStatus, true, this.sortRecipientBy, this.searchString, this.searchStringTargets, true);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-center-recipients {
  padding-bottom: 100px;

  .message-tabs-wrapper {
    @include flex-row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: white;
    border-bottom: 1px solid $skyBlueGrey500;

    .message-tabs-container {
      width: 80%;
      height: 100%;
      @include flex-row;
      align-items: center;
      justify-content: space-between;

      .tabs-left-container {
        height: 100%;
        @include flex-row;
        align-items: center;
        justify-content: flex-start;

        .tab-wrapper {
          height: 100%;
          @include flex-row;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          cursor: pointer;
          color: $blueGrey700;

          .tab-btn {
            background: white;
            border: none;
          }
        }

        .tab-wrapper:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .link-tracking-wrapper {
    width: 100%;
    @include flex-row;
    align-items: center;
    justify-content: center;

    .link-tracking-container {
      width: 90%;
      max-width: 1200px;
    }
  }

  .sent-numbers-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.3s;

    .sent-numbers-container {
      width: 90%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      max-width: 1200px;
      padding: 30px 0;
    }
  }
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
/* .fade-leave {
  } */
.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden
}
</style>
