var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales-chart-section"},[_c('div',{staticClass:"sales-chart-wrapper"},[_c('am2-select-date-range-modal',{attrs:{"is-show":_vm.showSelectDateRangeModal},on:{"select":_vm.handleDateRangeSelect,"cancel":_vm.handleDateRangeCancel}}),_vm._v(" "),_c('div',{class:[
      'sales-chart-nav-wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max'
      ]},[_c('div',{staticClass:"left-hand-side"},[_c('SalesChartHeadingDropdown',{attrs:{"has-data":_vm.hasData,"options":_vm.headingOptions},model:{value:(_vm.selectedHeading),callback:function ($$v) {_vm.selectedHeading=$$v},expression:"selectedHeading"}}),_vm._v(" "),_c('div',{staticClass:"vertical-divider u-margin-x-4"}),_vm._v(" "),_c('am2-link-button-dropdown',{attrs:{"data-test-id":"dashboard-sales-chart-types","items":_vm.salesTypeDropdownOptions,"item-key":_vm.currentSalesType,"button-props":{
            hasUnderline: false,
            hasArrow: true,
            textProps: {
              size: 'sm',
              weight: 'normal',
            },
          }},on:{"select":_vm.handleSalesTypeSelect}}),_vm._v(" "),_c('ar-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.refreshTimer ? 'Refreshing stats. Please wait - this might take a few minutes.' : 'Stats automatically update every 30 min. Click to refresh now.'
          }),expression:"{\n            content: refreshTimer ? 'Refreshing stats. Please wait - this might take a few minutes.' : 'Stats automatically update every 30 min. Click to refresh now.'\n          }",modifiers:{"top":true}}],class:[
            'u-margin-left-4',
            _vm.refreshTimer && 'rotation' ],attrs:{"icon-props":{
            name: 'refresh',
            width: '20px',
          },"data-test-id":"dashboard-chart-refresh-btn"},on:{"click":_vm.handleRefreshClick}})],1),_vm._v(" "),_c('ar-simple-select',{staticClass:"time-period-selector",style:({
            width: '200px',
          }),attrs:{"items":_vm.timePeriodOptions,"default-select-index":_vm.selectedTimePeriodIndex,"dropdown-style":{
            padding: '8px 0'
          }},on:{"select":_vm.handleTimePeriodSelect}})],1),_vm._v(" "),_c('div',{staticClass:"sales-chart-container"},[_c('div',{staticClass:"apex-sales-chart-wrapper",style:({
        height: _vm.chartWrapperHeight
      })},[_c('am2-apex-utility-chart',{staticClass:"apex-chart",attrs:{"chart-id":"dashboardSalesChart","series":_vm.series,"options":_vm.chartOptions,"loading":_vm.waitingForData,"without-toolbar":"","tooltip-placement":"vertical"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }