<template>
  <am2-card-container
    :style="{ padding: cardSize === 'regular' ? '15px' : '12px 8px' }"
  >
    <section :class="[
      'facebook-integration-card',
      cardSize,
    ]">
      <div class="left-section">
        <img
          class="img"
          :src="pageInfo.profileImageUrl"
        />
        <am2-heading
          type="h1"
          size="xs"
          :title="pageInfo.name"
        />
      </div>
      <div class="right-section">
        <am2-signal-tag v-if="connecting" text="Connecting..." />
        <am2-signal-tag
          v-else-if="!errorMessage"
          text="Connected"
        />
        <ar-state-message
          v-else-if="errorMessage"
          type="error"
          has-icon
          :text="errorMessage"
          :style="{
            maxWidth: '300px',
          }"
        />
      </div>
    </section>
  </am2-card-container>
</template>

<script>
  export default {
  name: 'FacebookPageCard',

  props: {
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
    pageMeta: {
      type: Object,
      default: () => null,
    },
    connecting: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: String,
      default: 'regular',
      validator: function (value) {
        return ['xs', 'regular'].indexOf(value) !== -1;
      },
    }
  },

  computed: {
    errorMessage() {
      if (!this.pageMeta && !this.connecting) {
        return 'Failed to connect, please try to refresh integration';
      }
      let error = '';
      if (this.pageMeta.arBotSubscribe && !this.pageMeta.arBotSubscribe.status) {
        if (this.pageMeta.arBotSubscribe.result.error.type === 'OAuthException') {
          error = 'You do not have sufficient permissions for this page';
        } else {
          error = this.pageMeta.arBotSubscribe.result.error.message;
        }
      }

      if (this.pageMeta.whiteListedDomains && !this.pageMeta.whiteListedDomains.status) {
        if (this.pageMeta.whiteListedDomains.result.error.type === 'OAuthException') {
          error = 'You do not have sufficient permissions for this page';
        } else {
          error = this.pageMeta.whiteListedDomains.result.error.message;
        }
      }

      return error;
    },
  },
};
</script>

<style lang="scss" scoped>
.facebook-integration-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-section {
    display: flex;
    align-items: center;
    .img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 15px;
    }
  }

  &.xs {
    flex-direction: column;
    align-items: flex-start;

    .right-section {
      margin-top: 12px;
    }
  }
}
</style>
