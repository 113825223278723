<template>
  <section
    class="edit-template-page"
  >
    <am2-put-email-template-name-modal
      :opened="displaySaveCurrentEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleSaveCurrentEmailTempalteConfirm"
      @cancel="handleSaveCurrentEmailTempalteCancel"
    />
    <am2-put-email-template-name-modal
      :opened="displayNewEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleNewEmailTempalteConfirm"
      @cancel="handleNewEmailTempalteCancel"
    />
    <am2-select-email-template-modal
      :opened="displaySelectEmailTemplateModal"
      @select="handleSelectEmailTemplateSelect"
      @cancel="handleSelectEmailTemplateCancel"
    />
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      :email-html="templateHtml"
      @close="handleEmailPreviewClose"
    />
    <div class="header-section">
      <div class="header-title-section">
        <ar-icon
          name="email"
          width="18.67px"
          :color="$arStyle.color.email"
          :wrapper-style="{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: `2px solid ${$arStyle.color.skyBlueGrey400}`,
          }"
        />
        <div class="header-title-right-section">
          <ar-text
            size="sm"
            :text="isNewEmailTemplate ? 'Create Email Template' : 'Edit Email Template'"
          />
          <ar-text
            size="xxs"
            :text="scratchEmailTemplate.meta.name"
            :style="{
              color: '#9FA8B5',
              marginTop: '6px',
            }"
          />
        </div>
      </div>
      <div class="header-right-section">
        <am2-link-button-dropdown
          placeholder="Template"
          :items="templateDropdownItems"
          :dropdown-style="{
            padding: '5px 0',
            width: '207px',
          }"
          :item-style="{
            height: '40px',
            padding: '0 20px',
          }"
          align="left"
          :style="{
            marginRight: '36px',
          }"
          @select="handleTemplateItemSelect"
        />
        <am2-link-button-dropdown
          placeholder="Preview"
          :items="previewDropdownItems"
          :dropdown-style="{
            padding: '5px 0',
            width: '207px',
          }"
          :item-style="{
            height: '40px',
            padding: '0 20px',
          }"
          align="left"
          :style="{
            marginRight: '36px',
          }"
          @select="handlePreviewItemSelect"
        />
        <ar-link-button
          text="Exit"
          @click="handleExitClick"
        />
      </div>
    </div>
    <div class="page-content">
      <div
        :class="[
          'email-editor-wrapper',
          scratchEmailTemplate.meta.templateType === 'rich-text' && 'with-rich-text',
        ]"
      >
        <EmailEditor
          ref="email-editor-component"
          :dynamic-tags="availableDynamicTagsInEmailTemplate"
          :type="scratchEmailTemplate.meta.templateType"
          :show-dynamic-tags-tool="false"
          @templateUpdate="handleTemplateOutput"
          @emailHtmlUpdate="handleEmailHtmlOutput"
        />
      </div>
    </div>
    <div
      :class="[
        'navigation-content',
        $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
      ]"
    >
      <div class="navigation-left-content">
        <ar-simple-button
          outlined
          text="Cancel"
          :style="{
            width: '111px',
          }"
          @click="handleCancelClick"
        />
      </div>
      <div class="navigation-right-content">
        <ar-text
          v-if="isAutoSaveEnabled"
          :text="autoSaveCopy"
          size="14px"
          class="u-margin-right-4"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
        />
        <ar-simple-button
          v-if="!isNewEmailTemplate"
          color="purple"
          text="Save as new template"
          :disabled="templateIsNotComplete || isCreatingEmailTemplate || isUpdatingSelectedEmailTemplate"
          @click="handleSaveAsNewTemplateClick"
        />
        <ar-simple-button
          v-if="isNewEmailTemplate"
          text="Save template"
          :style="{
            width: '144px',
          }"
          :loading="isCreatingEmailTemplate"
          :disabled="templateIsNotComplete"
          v-ar-hotkey="{
            general: ['Control', 'Enter'],
            mac: ['Meta', 'Enter'],
            action: handleCreateTemplateClick,
            disabled: templateIsNotComplete,
          }"
          @click="handleCreateTemplateClick"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'debounce';
import EmailEditor from '@/components/editors/email-editor';

export default {
  name: 'EditEmailTemplate',
  layout: 'edit',
  components: {
    EmailEditor,
  },

  data: () => ({
    templateHtml: null,
    previewDropdownItems: [
      {
        name: 'Preview email',
        key: 'preview_email',
      },
    ],
    displaySaveCurrentEmailTemplateModal: false,
    saveCurrentEmailTemplateSuccessCallBack: () => {},
    displayNewEmailTemplateModal: false,
    displayEmailPreviewModal: false,
    displaySendTestEmailModal: false,
    displaySelectEmailTemplateModal: false,
    isDebouncingAutoSave: false,
  }),

  computed: {
    ...mapState({
      emailTemplateOid: state => state.route.params.emailTemplateOid,
      selectedUnlayerTemplate: state => state.emailTemplate.selectedUnlayerTemplate,
      selectedEmailTemplate: state => state.emailTemplate.selectedEmailTemplate,
      isCreatingEmailTemplate: state => state.emailTemplate.isCreatingEmailTemplate,
      isFetchingSelectedEmailTemplate: state => state.emailTemplate.isFetchingSelectedEmailTemplate,
      isUpdatingSelectedEmailTemplate: state => state.emailTemplate.isUpdatingSelectedEmailTemplate,
      scratchEmailTemplate: state => state.emailTemplate.scratchEmailTemplate,
      scratchEmailTemplateUnsaved: state => state.emailTemplate.scratchEmailTemplateUnsaved,
    }),
    ...mapGetters({
      availableDynamicTagsInEmailTemplate: 'emailTemplate/availableDynamicTagsInEmailTemplate',
    }),
    templateDropdownItems() {
      const items = [];
      items.push({
        name: 'Use a template',
        key: 'select_template',
      });

      if (process.env.arEnableRichTextEmail) {
        if (this.scratchEmailTemplate.meta.templateType !== 'unlayer') {
          items.push({
            name: 'Use email editor',
            key: 'use_unlayer_editor',
          });
        }

        if (this.scratchEmailTemplate.meta.templateType !== 'rich-text') {
          items.push({
            name: 'Use text only',
            key: 'use_text_only',
          });
        }
      }

      return items;
    },
    isNewEmailTemplate() {
      return !this.emailTemplateOid;
    },
    templateIsNotComplete() {
      return !this.scratchEmailTemplate.meta.name
        || !this.scratchEmailTemplate.meta.description
        || !this.scratchEmailTemplate.meta.template
        || !this.templateHtml;
    },
    isAutoSaveEnabled() {
      return !this.isNewEmailTemplate;
    },
    autoSaveCopy() {
      if (this.isUpdatingSelectedEmailTemplate) {
        return 'Saving changes...';
      } else if (this.scratchEmailTemplateUnsaved) {
        return 'Changes unsaved';
      } else {
        return 'All changes saved';
      }
    },
  },

  watch: {
    scratchEmailTemplate(val) {
      sessionStorage.setItem('_ar_promoter_new_email_template_', JSON.stringify(val));
    },
  },

  methods: {
    ...mapMutations([
      'emailTemplate/RESET_SCRATCH_EMAIL_TEMPLATE',
      'emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE',
      'emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE',
      'emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'
    ]),
    ...mapActions([
      'SHOW_CONFIRM',
      'emailTemplate/CREATE_EMAIL_TEMPLATE',
      'emailTemplate/UPDATE_EMAIL_TEMPLATE',
      'emailTemplate/FETCH_EMAIL_TEMPLATE',
      'emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE',
    ]),
    // This method is being used by parent component
    async setEmailTemplate() {
      await this.$nextTick();
      if (this.$refs['email-editor-component']) {
        this.$refs['email-editor-component'].setDesign(this.scratchEmailTemplate.meta.template);
      }
    },
    async loadUnlayerEmailTemplate(id) {
      await this['emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE'](id);
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
        meta: {
          template: this.selectedUnlayerTemplate.design,
          templateType: 'unlayer',
        },
      });
    },
    async saveCurrentEmailTemplateAsNewTemplate(name, description) {
      const succeed = await this['emailTemplate/CREATE_EMAIL_TEMPLATE']({
        meta: {
          name,
          description,
          template: this.scratchEmailTemplate.meta.template,
          templateType: this.scratchEmailTemplate.meta.templateType,
        },
      });
      return succeed;
    },
    async saveTemplate(displaySuccessMessage) {
      this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](false);
      const succeed = await this['emailTemplate/UPDATE_EMAIL_TEMPLATE']({
        scratchEmailTemplate: this.scratchEmailTemplate,
        displaySuccessMessage,
      });
      if (!succeed) {
        this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](true);
      }
      return succeed;
    },
    async handleAutoSave() {
      // Remember to set this flag to false
      this.isDebouncingAutoSave = false;
      if (!this.isAutoSaveEnabled) {
        return;
      }
      this.saveTemplate(false);
    },
    async createNewEmailTemplate() {
      this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](false);
      const succeed = await this['emailTemplate/CREATE_EMAIL_TEMPLATE'](
        this.scratchEmailTemplate,
      );
      if (!succeed) {
        this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](true);
      }
      this.$router.push({
        path: '/message-center/templates',
      });
    },
    replaceEmailTemplate(template, templateType) {
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
        meta: {
          template,
          templateType,
        },
      });

      this.setEmailTemplate();
    },
    handleExitClick() {
      this.$router.push({
        path: '/message-center/templates',
      });
    },
    handleTemplateOutput(template) {
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
        meta: {
          template,
        },
      });
    },
    handleEmailHtmlOutput(templateHtml) {
      if (this.templateHtml && this.templateHtml !== templateHtml) {
        // If you already got a template and there's a new one
        this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](true);

        if (this.isAutoSaveEnabled) {
          if (!this.isUpdatingSelectedEmailTemplate && !this.isDebouncingAutoSave) {
            this.handleAutoSaveDebounce();
            this.handleAutoSaveDebounce.flush();
          } else {
            this.handleAutoSaveDebounce();
            this.isDebouncingAutoSave = true;
          }
        }
      }
      this.templateHtml = templateHtml;
    },
    handleTemplateItemSelect(templateItem) {
      if (templateItem.key === 'select_template') {
        this.displaySelectEmailTemplateModal = true;
      } else if (templateItem.key === 'use_text_only') {
        this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
          meta: {
            template: '',
            templateType: 'rich-text',
          },
        });
        this.setEmailTemplate();
      } else if (templateItem.key === 'use_unlayer_editor') {
        this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
          meta: {
            template: null,
            templateType: 'unlayer',
          },
        });
        this.setEmailTemplate();
      }
    },
    handlePreviewItemSelect(previewItem) {
      if (previewItem.key === 'send_test_email') {
        this.displaySendTestEmailModal = true;
      } else if (previewItem.key === 'preview_email') {
        this.displayEmailPreviewModal = true;
      }
    },
    async handleSaveCurrentEmailTempalteConfirm({
      name,
      description,
    }) {
      const succeed = await this.saveCurrentEmailTemplateAsNewTemplate(name, description);

      if (succeed) {
        this.displaySaveCurrentEmailTemplateModal = false;
        this.saveCurrentEmailTemplateSuccessCallBack();
      }
    },
    handleSaveCurrentEmailTempalteCancel() {
      this.displaySaveCurrentEmailTemplateModal = false;
    },
    handleNewEmailTempalteConfirm({
      name,
      description,
    }) {
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
        meta: {
          name,
          description,
        },
      });
      this.createNewEmailTemplate();
    },
    handleNewEmailTempalteCancel() {
      this.displayNewEmailTemplateModal = false;
    },
    async handleSelectEmailTemplateSelect(template, templateType) {
      this.displaySelectEmailTemplateModal = false;

      const agreedToCreateNewTemplate = await this.SHOW_CONFIRM({
        messageHtml: 'Selecting a new template to use will overwrite your current email.<br><b>Do you want to save the current email so it\'s not lost?</b>',
        confirmButtonText: 'Save as a template',
        cancelButtonText: 'Just overwrite it',
      });
      if (agreedToCreateNewTemplate) {
        this.displaySaveCurrentEmailTemplateModal = true;
        this.saveCurrentEmailTemplateSuccessCallBack = () => {
          this.replaceEmailTemplate(template, templateType);
        };
      } else {
        this.replaceEmailTemplate(template, templateType);
      }
    },
    handleSelectEmailTemplateCancel() {
      this.displaySelectEmailTemplateModal = false;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    handleSaveAsNewTemplateClick() {
      this.displayNewEmailTemplateModal = true;
    },
    handleCreateTemplateClick() {
      this.createNewEmailTemplate();
    },
    handleCancelClick() {
      this.$router.push({
        path: '/message-center/templates',
      });
    },
  },

  async mounted() {
    this.handleAutoSaveDebounce = debounce(this.handleAutoSave, 1000);
    this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE_UNSAVED'](false);
    const unsavedScratchEmailTemplate = sessionStorage.getItem('_ar_promoter_new_email_template_');

    if (unsavedScratchEmailTemplate) {
      // If you have unsaved changes, we restore it and continue.
      // This case usually happens when you refresh the page.
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE'](JSON.parse(unsavedScratchEmailTemplate));
    } else if (this.isNewEmailTemplate) {
      // If it's a toal new template
      this['emailTemplate/PATCH_SCRATCH_EMAIL_TEMPLATE']({
        meta: {
          name: this.$route.query.name,
          description: this.$route.query.description,
          templateType: 'unlayer',
        },
      });
      if (this.$route.query.libraryTemplateId) {
        await this.loadUnlayerEmailTemplate(this.$route.query.libraryTemplateId);
      }
    } else {
      // If it's a saved template
      await this['emailTemplate/FETCH_EMAIL_TEMPLATE'](this.emailTemplateOid);
      this['emailTemplate/SET_SCRATCH_EMAIL_TEMPLATE'](this.selectedEmailTemplate);
    }

    this.setEmailTemplate();
  },

  async beforeRouteLeave (to, from, next) {
    if (this.scratchEmailTemplateUnsaved) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if (response) {
        sessionStorage.removeItem('_ar_promoter_new_email_template_');
        next();
      } else {
        next(false);
        return;
      }
    } else {
      sessionStorage.removeItem('_ar_promoter_new_email_template_');
      next();
    }
  },

  beforeDestroy() {
    this['emailTemplate/RESET_SCRATCH_EMAIL_TEMPLATE']();
    window.clearInterval(this.handleAutoSaveDebounce);
  },
};
</script>

<style lang="scss" scoped>
.edit-template-page {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  .header-section {
    flex-shrink: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    min-width: 800px;
    padding-left: 32px;
    padding-right: 40px;
    background: white;
    border-bottom: 1px solid $blueGrey500;
    .header-title-section {
      display: inline-flex;
      align-items: center;
      .header-title-right-section {
        display: flex;
        flex-flow: column;
        margin-left: 15px;
        padding-top: 1px;
      }
    }
    .header-right-section {
      display: inline-flex;
      align-items: center;
    }
    .top-create-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .top-create-left-content {
        padding: 0 0 4px;
      }
      .top-create-right-content {
        display: flex;
        align-items: center;
        padding-right: 29px;
      }
    }
  }
  .page-content {
    flex: 1;
    min-width: 800px;
    overflow: auto;
    .email-editor-wrapper {
      width: 100%;
      height: 100%;
      &.with-rich-text {
        padding: 32px;
        max-width: 909px;
        margin: 0 auto;
      }
    }
  }
  .navigation-content {
    flex-shrink: 0;
    min-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    padding: 0 50px;
    background: white;
    border-top: 1px solid $skyBlueGrey500;

    .navigation-left-content {
      display: inline-flex;
    }

    .navigation-right-content {
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
