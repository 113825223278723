var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreEvents),expression:"loadMoreEvents"}],class:['events-wrapper', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'],attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":10}},[(!_vm.isFeatureEnabled)?_c('am2-unlock-prompt',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],attrs:{"title":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Unlock Events" : "Events","subtitle":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "See all your events from the different ticketing platforms you use. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>" : "See all your events from different ticketing platforms. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>","button-text":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Upgrade Plan" : "Upgrade","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'},on:{"click":_vm.goToPlans}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"events",class:[
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{class:[
        'events-page-top',
        _vm.$arMediaQuery.window.minWidth('md') && 'window-md-min' ]},[_c('am2-top',{class:[
          'message-listing-hero-container',
          _vm.$arMediaQuery.window.minWidth('md') && 'window-md-min' ],attrs:{"dropdown":_vm.dropdown,"button":{
          action: function () { _vm.navigateToNewEventCreation() },
          text: _vm.$arMediaQuery.pageContent.minWidth('sm') ? 'Create event' : 'Create',
          iconName: 'circle-plus',
          iconDistance: '9px',
          type: 'split',
          classes: ['top-create-button'],
          options: [
            {name: 'Import events', action: _vm.toggleMassImport} ],
        },"search":{ action: _vm.serverSideSearch, value: _vm.queryParams.searchString, placeholder: _vm.searchPlaceholderText },"title":"Events","dropdown-style":{ width: '240px' }}})],1),_vm._v(" "),_c('ar-divider',{style:({
        marginTop: _vm.$arMediaQuery.window.minWidth('md') ? '30px' : '22px',
      })}),_vm._v(" "),(!_vm.noEvents || _vm.dateRangeFilter)?_c('div',{class:[
        'events-controls',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max',
        _vm.$arMediaQuery.window.minWidth('md') && 'window-md-min' ]},[_c('div',{staticClass:"overall-details"},[(_vm.eventsCount)?_c('span',[_vm._v("Showing "),_c('b',[_vm._v(_vm._s(_vm.events.length))]),_vm._v(" "+_vm._s(_vm.events.length > 1 ? ' events' : ' event'))]):_vm._e()]),_vm._v(" "),_c('div',{class:['events-controls__subcontrol', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max', _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max']},[_c('div',{staticClass:"events-controls-wrapper"},[_c('DateRangeDropdown',{attrs:{"testing-id":"events-list"},on:{"date-filter":_vm.handleDateFilter}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"order-dropdown",style:({
              width: '200px',
            }),attrs:{"items":_vm.dropdownOrderByItems.items,"default-select-index":_vm.eventOrderSelectedIndex,"dropdown-style":{
              padding: '8px 0'
            }},on:{"select":_vm.handleDropdownOrderByItemsSelect}}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('sm'))?_c('am2-button-group-tabs',{style:({
              width: '100px',
            }),attrs:{"active-index":_vm.queryParams.showAs === 'list' ? 1 : 0,"items":_vm.groupButtonsSelectItems,"data-test-id":"list-type-tabs"},on:{"select":_vm.handleGroupButtonSelectClick}}):_vm._e()],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"events-container",class:_vm.queryParams.showAs === 'tiles' ? 'tiles' : 'list',style:({
        marginTop: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '28px' : '22px',
      })},[(_vm.noEvents && _vm.hasSearchConditions)?_c('am2-no-content-section',{style:({
          width: '100%',
        }),attrs:{"header":"No events found","header-size":"sm"}}):(_vm.noEvents && _vm.dateRangeFilter)?_c('div',{staticClass:"no-date-range-events-wrapper"},[_c('am2-no-content-section',{style:({
            width: '100%',
          }),attrs:{"header":"No events found for selected dates","header-size":"sm"}})],1):(_vm.noEvents)?_c('div',{staticClass:"no-events-wrapper"},[_c('am2-no-content-section',{class:[
            'no-event-section',
            _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"icon-name":"ar-form-in-circle","header":"Create RSVP page","body":"Capture registrations for your physical or virtual event","button-text":"Create RSVP Page","button-props":{
            iconName: null,
          }},on:{"buttonClick":_vm.navigateToNewEventCreation}},[_c('am2-tag',{staticClass:"free-tag",attrs:{"slot":"top-right","text":"Free","text-size":"xxxs","shape":"rectangle","type":"dark-green"},slot:"top-right"}),_vm._v(" "),_c('div',{staticClass:"footer-section",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{class:[
                'imgs-wrapper',
                _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("assets/images/third-party-logos/zoom.png"),"alt":"Zoom"}})]),_vm._v(" "),_c('div',{staticClass:"img-wrapper clubhouse"},[_c('span',{staticClass:"emoji"},[_vm._v("👋")]),_c('img',{attrs:{"src":require("assets/images/third-party-logos/clubhouse.png"),"alt":"Clubhouse"}})]),_vm._v(" "),_c('div',{staticClass:"img-wrapper vimeo"},[_c('img',{attrs:{"src":require("assets/images/third-party-logos/vimeo.png"),"alt":"Vimeo"}})]),_vm._v(" "),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("assets/images/third-party-logos/youtube.png"),"alt":"Youtube"}})]),_vm._v(" "),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("assets/images/third-party-logos/twitch.png"),"alt":"Twitch"}})])])])],1),_vm._v(" "),_c('am2-no-content-section',{class:[
            'no-event-section',
            _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"icon-name":"ar-event-in-circle","header":"Connect your ticketing platforms","body":"Sync all your events, attendees & orders so they’re in one place","button-text":"Setup integrations","button-props":{
            iconName: null,
          }},on:{"buttonClick":_vm.navigateToIntegrations}},[_c('am2-tag',{style:({
              width: '45px',
            }),attrs:{"slot":"top-right","text":"Free","text-size":"xxxs","shape":"rectangle","type":"dark-green"},slot:"top-right"})],1)],1):_c('div',{class:[
          'cards-container',
          _vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_vm._l((_vm.events),function(event){return _c('am2-event-card',{key:event.oid,attrs:{"event":event,"show-as":_vm.setShowAs,"integration-is-possible":_vm.eventCanMerge(event)},on:{"delete":_vm.handleDeleteEvent,"merge":_vm.handleEventMerge,"ticketSalesDelete":_vm.handleTicketSalesDelete,"posOrdersDelete":_vm.handleTicketSalesDelete}})}),_vm._v(" "),_vm._l((_vm.isFetchingEvents ? 6 : 0),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card",class:[
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],attrs:{"type":"event","show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',"level":_vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }