<template>
  <div
    class="am2-category-tabs"
  >
    <div 
      v-for="(item) of items"
      :key="item.key"
      :class="[
        'category',
        realVal == item.key && 'active'
      ]"
      :data-test-id="item.text"
      @click="handleCategoryClick(item.key)"
    >
      <ar-text
        size="xs"
        :text="item.text"
        :style="{
          color: realVal == item.key ? 'white' : $arStyle.color.skyBlueGrey700,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroupSelect',

  props: {
    value: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      realVal: this.value,
    };
  },

  watch: {
    value(newVal) {
      this.realVal = newVal;
    },
  },

  methods: {
    handleCategoryClick(key) {
      this.$emit('input', key);
    },
  },
};
</script>

<style lang="scss" scoped>
.am2-category-tabs {
  .category {
    margin-right: 8px;
    display: inline-block;
    border: 1px solid #dcdee4;
    padding: 9px 18px;
    border-radius: 16px;
    cursor: pointer;
    background: white;
    
    &.active {
      background: $purple500;
      border: 1px solid $purple500;
    }
  }

}
</style>
