var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ar-simple-stepper"},[(_vm.hasDivider)?_c('div',{style:({
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    })},_vm._l((_vm.steps),function(step){return _c('div',{key:step,style:({
        position: 'absolute',
        top: '0',
        left: ((step * _vm.stepBlockWidthPercentage) + "%"),
        width: '1px',
        height: '100%',
        background: 'white',
      })})}),0):_vm._e(),_vm._v(" "),_c('div',{style:({
      width: _vm.filledBarWidth,
      height: '100%',
      background: _vm.$arStyle.color.purple500,
      transition: 'width 0.25s',
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }