<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Admin Fixes"
      class="u-margin-top-6 u-margin-bottom-2" />
    <ar-text
      class="u-margin-bottom-8"
      multiple-lines
      size="xs"
      text="Trigger actions to recalculate data and fix broken resources on promoter accounts. Please don't abuse this
            functionality. Each of these buttons may potentially trigger a lot of work on the server, so we should avoid
            spamming them."
    />

    <div class="fixes-page-wrapper">
      <am2-heading
        type="h3"
        title="Select account"
        size="md"
        :style="{ marginBottom: '10px' }" />

      <am2-promoter-select
        class="select u-margin-bottom-4"
        :value="125"
        @input="handlePromoterSelect"
        :style="{ width: '500px' }"
      />


      <div v-if="selectedPromoterOid">
        <ar-divider
          class="u-margin-y-4"
        />
        <am2-card-container
          class="u-padding-5"
          layout="soft">
            <div
              :style="{ marginBottom: '12px', display: 'flex', width: '100%' }">
              <ar-icon
                width="24px"
                name="ar-logo-mono"
                class="u-margin-right-3"
              />
              <am2-heading
                type="h3"
                title="Fix Dashboard Sales Graph Data"
                size="md" />
            </div>

            <ar-text
              class="u-margin-bottom-3"
              multiple-lines
              size="xs"
              text="If a promoter's dashboard sales chart is incorrect, we can delete and re-create the underlying data to fix it.
                    While this fix is running, the chart may show incomplete or incorrect data!"
            />
          <ar-text
            class="u-margin-bottom-5"
            multiple-lines
            size="xs"
            weight="bold"
            text="Running time - approx 1s per day of sales (assume 5 minutes for a year of continuous daily sales)."
          />
          <div :style="{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }">
            <div :style="{minWidth: '418px'}">
              <ar-simple-button
                id="refresh-sales-stats-snapshots-button"
                data-test-id="refresh-sales-stats-snapshots-button"
                :text="`${salesStatsRefreshPrefixWord}Delete and Recreate Sales Stats Snapshots`"
                :icon-name="confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? 'exclamation-mark' : 'refresh'"
                :icon-props="{
                  width: confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? '18px' : '14px',
                }"
                :disabled="isRefreshingSalesSnapshots"
                :type="confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? 'red' : 'purple'"
                @click="handleRefreshSalesSnapshotsClick"
              />
            </div>
            <ar-text
              v-if="confirmRefreshSalesSnapshots"
              text="<a>Cancel refresh</a>"
              size="xs"
              class="u-margin-left-4"
              allow-html
              :style="{
                alignSelf: 'center',
                color: 'red',
              }"
              @anchorClick="handleCancelSalesStatsRefresh"
            />
          </div>
        </am2-card-container>

        <ar-divider
          class="u-margin-y-4"
        />
        <am2-card-container
          class="u-padding-5"
          layout="soft">
            <div
              :style="{ marginBottom: '12px', display: 'flex', width: '100%' }">
              <ar-icon
                width="24px"
                name="ar-logo-mono"
                class="u-margin-right-3"
              />
              <am2-heading
                type="h3"
                title="Stat refresh"
                size="md" />
            </div>

            <ar-text
              class="u-margin-bottom-3"
              multiple-lines
              size="xs"
              text="If a message list, campaign registration, Event ticket sales stats or tag count are incorrect use this to refresh it."
            />
          <ar-text
            class="u-margin-bottom-5"
            multiple-lines
            size="xs"
            weight="bold"
            text="Select what you want to refresh then select the specific entity."
          />
          <ar-simple-select
            class="u-margin-bottom-5"
            :items="statRefreshList"
            enable-filter
            :style="{ height: '40px', width:'500px' }"
            @select="handleStatRefreshSelect"
            data-test-id="filter-predicate-selector"
          />
          <ar-simple-select
            class="u-margin-bottom-5"
            :items="messageListList"
            enable-filter
            enable-clear
            :style="{ height: '40px', width:'500px' }"
            @select="handleEntitySelect"
          />
          <div :style="{minWidth: '418px'}">
              <ar-simple-button
                id="refresh-stats-button"
                data-test-id="refresh-stats-button"
                :text="statRefreshType ? `${statRefreshPrefixWord}Refresh ${statRefreshType.name}` : 'Please Select'"
                :icon-name="confirmStatsRefresh || isRefreshingStats ? 'exclamation-mark' : 'refresh'"
                :icon-props="{
                  width: confirmStatsRefresh|| isRefreshingStats ? '18px' : '14px',
                }"
                :disabled="(statOid == null)"
                :type="confirmStatsRefresh || isRefreshingStats ? 'red' : 'purple'"
                @click="handleStatRefreshClick"
              />
            </div>
        </am2-card-container>
      </div>
    </div>

  </section>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'AdminFixes',
    layout: 'default',

    data: () => ({
      selectedPromoterOid: null,
      chargebeePromoterPlan: null,
      demoPromoterPlan: null,
      isUpdatingPromoterPlan: false,
      isRefreshingSalesSnapshots: false,
      confirmRefreshSalesSnapshots: false,
      isRefreshingStats: false,
      confirmStatsRefresh: false,
      statRefreshType: "",
      statOid: null,
      messageListList: [],
      statRefreshList: [
      {
          name: 'Message List Stats',
          key: 'messageList'
        },
        {
          name: 'Campaign Registration Stats',
          key: 'campaginStats'
        },
        {
          name: 'Event Ticket Sales Stats',
          key: 'eventTicketStats'
        },
        {
          name: 'Tag Count Update',
          key: 'tagCountUpdate'
        },
        {
          name: 'Task Status Update',
          key: 'taskStats'
        }
      ]
      
    }),

    computed: {
      ...mapGetters ({
        isAdminAccount: 'auth/isAdminAccount'
      }),

      salesStatsRefreshPrefixWord() {
        if (this.isRefreshingSalesSnapshots) {
          return 'RUNNING: ';
        }
        if (this.confirmRefreshSalesSnapshots) {
          return 'CONFIRM: ';
        }
        return '';
      },
      statRefreshPrefixWord() {
        if (this.isRefreshingStats) {
          return 'RUNNING: ';
        }
        if (this.confirmStatsRefresh) {
          return 'CONFIRM: ';
        }
        return '';
      }
    },

    created() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
    },

    methods: {
      ...mapActions([
        'admin/FORCE_REFRESH_PROMOTER_SALES_STATS',
        'admin/FETCH_MESSAGE_LIST',
        'admin/FETCH_CAMPAIGN_LIST',
        'admin/FETCH_EVENT_LIST',
        'admin/FETCH_TAG_LIST',
        'admin/FETCH_TASK_LIST',
        'admin/FORCE_REFRESH_STATS'
      ]),

      handleRefreshSalesSnapshotsClick() {
        if (this.isRefreshingSalesSnapshots) return;
        if (!this.confirmRefreshSalesSnapshots) {
          this.confirmRefreshSalesSnapshots = true;
          return;
        }
        this.isRefreshingSalesSnapshots = true;
        this.confirmRefreshSalesSnapshots = false;
        this['admin/FORCE_REFRESH_PROMOTER_SALES_STATS']({targetPromoterOid: this.selectedPromoterOid})
      
      },

      handleCancelSalesStatsRefresh() {
        this.confirmRefreshSalesSnapshots = false;
      },

      async handlePromoterSelect(promoterOid) {
        this.resetFlags();
        this.selectedPromoterOid = promoterOid;
      },

      async handleFetchMessageLists() {
        let data = await this['admin/FETCH_MESSAGE_LIST']({promoterOid: this.selectedPromoterOid});
  
        this.messageListList = data.map(data => {
          return {
                name: `${data.name} (${data.oid})`,
                key: data.oid,
              }
        });
      },
      async handleFetchCampaignLists() {
        let data = await this['admin/FETCH_CAMPAIGN_LIST']({promoterOid: this.selectedPromoterOid});
  
        this.messageListList = data.map(data => {
          return {
                name: `${data.name} (${data.oid})`,
                key: data.oid,
              }
        });
      },
      async handleFetchEventLists() {
        let data = await this['admin/FETCH_EVENT_LIST']({promoterOid: this.selectedPromoterOid});
  
        this.messageListList = data.map(data => {
          return {
                name: `${data.name} (${data.oid})`,
                key: data.oid,
              }
        });
      },
      async handleFetchTagLists() {
        let data = await this['admin/FETCH_TAG_LIST']({promoterOid: this.selectedPromoterOid});
  
        this.messageListList = data.map(data => {
          return {
                name: `${data.name} (${data.oid})`,
                key: data.oid,
              }
        });
      },
      async handleFetchTaskLists() {
        let data = await this['admin/FETCH_TASK_LIST']({promoterOid: this.selectedPromoterOid});
  
        this.messageListList = data.map(data => {
          return {
                name: `${data.name} (${data.oid})`,
                key: data.oid,
              }
        });
      },
      handleStatRefreshSelect(item){
        this.statRefreshType = item
        this.statOid = null;
        if (item.key === 'messageList'){
          this.handleFetchMessageLists();
        }
        else if (item.key === 'campaginStats'){
          this.handleFetchCampaignLists();
        }
        else if (item.key === 'eventTicketStats'){
          this.handleFetchEventLists();
        }
        else if (item.key === 'tagCountUpdate'){
          this.handleFetchTagLists();
        }
        else if (item.key === 'taskStats'){
          this.handleFetchTaskLists();
        }
       
      },
      handleEntitySelect(item){
        this.statOid = item.key
      },
      handleStatRefreshClick(){
        if (this.isRefreshingStats) return;
        if (!this.confirmStatsRefresh) {
          this.confirmStatsRefresh = true;
          return;
        }
        this.isRefreshingStats = true;
        this.confirmStatsRefresh = false;
        this['admin/FORCE_REFRESH_STATS']({targetPromoterOid: this.selectedPromoterOid, refreshType: this.statRefreshType, statOid: this.statOid});
        this.resetFlags();
      },

      resetFlags() {
        this.statOid = null;
        this.isRefreshingStats = false;
        this.confirmStatsRefresh = false;
        this.isRefreshingSalesSnapshots = false;
        this.confirmRefreshSalesSnapshots = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fixes-page-wrapper {
    margin: 30px;

    .rotation {
      animation: rotator 3s infinite linear;
    }
    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
</style>
