<template>
  <section
    :class="[
      'dashboard',
      $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
    ]"
  >
    <div :class="[
        'main',
        $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
    ]">
      <div :class="[
        'content',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
      ]">
        <!-- TOP Component -->
        <div
          :class="[
            'top-wrapper',
            'header',
            $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          ]"
        >
          <div
            class="top-section">
            <div class="top-section-title">
              <ar-text
                :size="$arMediaQuery.pageContent.maxWidth('sm') ? 'md' : 'lg'"
                text="Dashboard"
              />
            </div>
          </div>
          <div
            :class="[
              'top-right',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            ]"
          >
            <div class="top-right-button">
              <am2-split-dropdown
                :items="createNewOptions"
                text="Create New"
                icon-name="dialog"
                icon-distance="12px"
                id="dashboard-create-new-button"
                data-test-id="dashboard-create-new-button"
                toggle-dropdown-on-button-click
                @select="item => item.action()"
                @buttonClick="() => {null}"
                :style="{
                minWidth: '168px',
              }"
              />
            </div>
          </div>
        </div>

        <am2-tabs
          class="tabs"
          v-if="$arMediaQuery.pageContent.maxWidth('lg')"
          :selected-tab-index="selectedTabIndex"
          :items="tabItems"
          @select="handleMenuSelect"
        />

        <div
          v-if="$arMediaQuery.pageContent.minWidth('xl') || selectedTab === 'stats'"
          class="content-items">
          <!-- Sales chart will go here -->
          <SalesChartSection class="sales-chart u-margin-y-5" />

          <!-- Upcoming events will go here -->
          <am2-card-container
            class="upcoming-events u-padding-6 u-margin-y-5"
            layout="soft"
          >
            <div
              :class="[
                'top-wrapper',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              ]"
            >
              <div
                class="top-section">
                <div class="top-section-title">
                  <ar-text
                    size="md"
                    text="Upcoming events"
                  />
                </div>
              </div>
              <div
                :class="[
                  'top-right',
                  $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                  $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
                ]"
              >
                <div class="top-right-button">
                  <ar-simple-button
                    id="create-new-event-button"
                    data-test-id="create-new-event-button"
                    :text="$arMediaQuery.pageContent.minWidth('md') ? 'Create new event' : 'Create'"
                    @click="handleCreateNewEventClick"
                    :style="{height: '40px'}"
                  />
                </div>
              </div>
            </div>
            <ar-divider
              class="u-margin-top-4"
              :style="{
                backgroundColor: $arStyle.color.skyBlueGrey400,
              }"
            />

            <am2-card-skeleton
              v-for="n in isFetchingEvents ? 6 : 0"
              :key="n"
              :class="[
                $arMediaQuery.pageContent.maxWidth('sm') && 'tiles',
                $arMediaQuery.pageContent.minWidth('lg') && 'micro-list',
                'u-margin-bottom-4',
                $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              ]"
              type="event"
              :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'micro-list'"
              :level="$arMediaQuery.pageContent.minWidth('md') ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
            />

            <div v-if="$arMediaQuery.pageContent.maxWidth('sm') && events.length > 0"
              class="tiles-container">
              <am2-event-card
                v-for="event in eventsDecorated"
                :key="`event-${event.oid}`"
                :event="event"
                show-as="tiles"
                :integration-is-possible="false"
                class="u-margin-top-4 tile"
              />
            </div>

            <div v-else-if="$arMediaQuery.pageContent.minWidth('md') && eventsDecorated.length > 0"
              class="events-rows-container">
              <div v-for="(event, idx) in eventsDecorated" :key="`event-${idx}`" class="event-row">
                <am2-event-card
                  :event="event"
                  show-as="micro-list"
                  :integration-is-possible="false"
                  class="u-margin-y-2 micro-list"
                  :style="{
                    width: '100%',
                  }"
                />
                <ar-divider
                  type="soft"
                />
              </div>
            </div>

            <div v-else-if="!isFetchingEvents && eventsDecorated.length === 0">
              <ar-text
                size="sm"
                weight="normal"
                :text="`No upcoming events`"
                allow-html
                align="center"
                class="u-margin-top-5"
                :style="{
                  color: $arStyle.color.skyBlueGrey600,
                }"
              />
            </div>

            <div v-if="events.length !== eventsDecorated.length">
              <ar-text
                size="sm"
                weight="normal"
                :text="`<a style='text-decoration: none;'>View ${events.length - eventsDecorated.length} more events</a>`"
                class="u-margin-top-4"
                allow-html
                align="center"
                data-test-id="see-more-events"
                @anchorClick="handleToggleExpandedEvents"
              />
            </div>
            <div v-else-if="eventsDecorated.length > 3">
              <ar-text
                size="sm"
                weight="normal"
                :text="`<a style='text-decoration: none;'>Show fewer events</a>`"
                class="u-margin-top-4"
                align="center"
                allow-html
                data-test-id="see-fewer-events"
                @anchorClick="handleToggleExpandedEvents"
              />
            </div>


          </am2-card-container>

          <!-- Active campaigns will go here -->
          <am2-card-container
            class="active-campaigns u-padding-6 u-margin-y-5"
            layout="soft"
          >
            <div
              :class="[
                'top-wrapper',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              ]"
            >
              <div
                class="top-section">
                <div class="top-section-title">
                  <ar-text
                    size="md"
                    text="Active campaigns"
                  />
                </div>
              </div>
              <div
                :class="[
              'top-right',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            ]"
              >
                <div class="top-right-button">
                  <am2-create-campaign-button
                    :text="$arMediaQuery.pageContent.minWidth('md') ? 'Create Campaign' : 'Create'"
                    data-test-id="create-campaign-button-dashboard"
                  />
                </div>
              </div>
            </div>

            <ar-divider
              class="u-margin-top-4"
              :style="{
                backgroundColor: $arStyle.color.skyBlueGrey400,
              }"
            />

            <am2-card-skeleton
              v-for="n in isFetchingCampaigns ? 6 : 0"
              :key="n"
              :class="[
                'base-plan-card',
                $arMediaQuery.pageContent.maxWidth('sm') && 'tiles',
                $arMediaQuery.pageContent.minWidth('lg') && 'micro-list',
                'u-margin-bottom-4',
                $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              ]"
              type="campaign"
              :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'micro-list'"
              :level="$arMediaQuery.pageContent.minWidth('md') ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
            />

            <div v-if="$arMediaQuery.pageContent.maxWidth('sm') && campaigns.length > 0"
                 class="tiles-container">
              <am2-campaign-card
                v-for="campaign in campaignsDecorated"
                :key="`campaign-${campaign.oid}`"
                :campaign="campaign"
                show-as="tiles"
                class="u-margin-bottom-4 tile"
              />
            </div>

            <div v-else-if="$arMediaQuery.pageContent.minWidth('md') && campaignsDecorated.length > 0">
              <div v-for="(campaign, idx) in campaignsDecorated" :key="`campaign-${idx}`" class="campaign-row">
                <am2-campaign-card
                  :campaign="campaign"
                  show-as="micro-list"
                  class="u-margin-y-2 micro-list"
                />
                <ar-divider
                  type="soft"
                />
              </div>
            </div>

            <div v-else-if="!isFetchingCampaigns && campaignsDecorated.length === 0">
              <ar-text
                size="sm"
                weight="normal"
                :text="`No upcoming campaigns`"
                allow-html
                align="center"
                class="u-margin-top-5"
                :style="{
                  color: $arStyle.color.skyBlueGrey600,
                }"
              />
            </div>

            <div v-if="campaigns.length !== campaignsDecorated.length">
              <ar-text
                size="sm"
                weight="normal"
                :text="`<a style='text-decoration: none;'>View ${campaigns.length - campaignsDecorated.length} more campaigns</a>`"
                class="u-margin-top-4"
                align="center"
                allow-html
                data-test-id="see-more-campaigns"
                @anchorClick="handleToggleExpandedCampaigns"
              />
            </div>
            <div v-else-if="campaignsDecorated.length > 3">
              <ar-text
                size="sm"
                weight="normal"
                :text="`<a style='text-decoration: none;'>Show fewer campaigns</a>`"
                class="u-margin-top-4"
                align="center"
                allow-html
                data-test-id="see-fewer-campaigns"
                @anchorClick="handleToggleExpandedCampaigns"
              />
            </div>
          </am2-card-container>
        </div>


      </div>
    </div>


    <div
      v-if="$arMediaQuery.pageContent.minWidth('xl') || selectedTab === 'activity'"
      ref="activity-sidebar"
      v-infinite-scroll="handleFetchOlderActivity"
      infinite-scroll-disabled="disableFetchMoreActivity"
      :infinite-scroll-distance="10"
      :class="[
        'activity-sidebar',
        $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
      ]">
      <div :class="[
        'activity-header',
         $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
      ]">
        <div
          :class="[
            'top-wrapper',
            $arMediaQuery.pageContent.minWidth('xl') ? 'u-padding-top-8' : 'u-padding-y-4',
          ]"
        >
          <div
            class="top-section">
            <div class="top-section-title">
              <ar-text
                v-if="$arMediaQuery.pageContent.minWidth('xl')"
                size="md"
                text="Activity"
              />

              <div
                v-if="$arMediaQuery.pageContent.minWidth('xl')"
                class="vertical-divider u-margin-x-6"
              />

              <am2-link-button-dropdown
                :items="activityTypeDropdownOptions"
                :item-key="currentActivityType"
                @select="handleActivityTypeSelect"
                data-test-id="activity-type-dropdown"
                :class="[
                  $arMediaQuery.pageContent.minWidth('xl') && 'u-padding-top-1',
                  $arMediaQuery.pageContent.maxWidth('lg') && 'u-padding-right-3'
                ]"
                :button-props="{
                  hasUnderline: false,
                  hasArrow: true,
                  textProps: {
                      size: 'sm',
                      weight: 'normal',
                    },
                }"
              />

            </div>
          </div>
        </div>

        <div :class="[
            'new-activity-button-section',
            'u-padding-y-4',
            $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
          ]">
          <ar-simple-button
            text="See new activity"
            data-test-id="see-new-activity-button"
            :disabled="disableSeeNewActivityButton"
            @click="handleRefreshActivity"
            class="u-width-100-percent"
          />
        </div>
      </div>

      <am2-activity-card
        class="activity-row"
        :data-test-id="`dashboard-activity-card-${item.oid}`"
        v-for="item in activityDecorated"
        :key="`activity-${item.oid}`"
        :image-path="item.imagePath"
        :title="item.title"
        :subtitle="item.subtitle"
        :datetime="item.datetime"
        :text="item.text"
        :path="item.path"
        :link="item.link"
        :icon="item.icon"
        :children="item.children ? item.children : []"
        :types="item.type"
      />
      <div
        v-if="isFetchingActivity && activityDecorated.length > 0"
        :style="{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '12px'
        }">
        <am2-loading-bubble />
      </div>
      <div
        class="u-margin-top-8"
        v-else-if="noOlderActivity && activityDecorated.length > 0">
        <ar-text
          size="sm"
          weight="normal"
          :text="`No more activity`"
          allow-html
          align="center"
          :style="{
            color: $arStyle.color.skyBlueGrey600,
          }"
        />
      </div>
      <div
        class="u-margin-top-8"
        v-else-if="!isFetchingActivity && activityDecorated.length === 0">
        <ar-text
          size="sm"
          weight="normal"
          :text="`No fan activity found`"
          allow-html
          align="center"
          class="u-margin-top-5"
          :style="{
            color: $arStyle.color.skyBlueGrey600,
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import accounting from 'accounting';
import dayjs from 'dayjs';

import SalesChartSection from './SalesChartSection.vue'

export default {
  name: 'Dashboard',
  layout: 'default',

  components: {
    SalesChartSection,
  },

  data: () => ({
    showExpandedEvents: false,
    showExpandedCampaigns: false,
    currentActivityType: 'all',
    salesStartDate: null,
    salesEndDate: null,
    selectedTab: 'stats',
    activityPoller: null,
  }),

  computed: {
    ...mapState({
      campaigns: state => state.dashboard.campaigns,
      events: state => state.dashboard.events,
      salesStats: state => state.dashboard.salesStats,
      activity: state => state.dashboard.fanActivity,
      isFetchingEvents: state => state.dashboard.isFetchingEvents,
      isFetchingCampaigns: state => state.dashboard.isFetchingCampaigns,
      isFetchingSalesStats: state => state.dashboard.isFetchingSalesStats,
      isFetchingActivity: state => state.dashboard.isFetchingActivity,
      noOlderActivity: state => state.dashboard.noOlderActivity,
      hasErrorFetchingActivity: state => state.dashboard.hasErrorFetchingActivity,
      newActivityAvailable: state => state.dashboard.newActivityAvailable,
    }),
    selectedTabIndex() {
      return this.tabItems.findIndex(item => item.value === this.selectedTab) || 0;
    },
    disableSeeNewActivityButton() {
      return this.isFetchingActivity || !this.newActivityAvailable;
    },
    disableFetchMoreActivity() {
      return this.isFetchingActivity || this.noOlderActivity || this.hasErrorFetchingActivity;
    },
    tabItems() {
      return [{
        value: 'stats',
        title: 'Stats'
      },{
        value: 'activity',
        title: 'Activity'
      }]
    },
    eventsDecorated() {
      const events = this.events.map( item => {
        let subtitle = '';
        if (item.location) subtitle = item.location;
        if (subtitle.length > 0 && (item.startDate || item.endDate)) subtitle = `${subtitle}, `;
        if (item.startDate && !item.endDate) {
          const prettyStartDate = dayjs(item.startDate).format('D MMM YYYY');
          subtitle = `${subtitle}${prettyStartDate}`;
        } else if (item.startDate) {
          const prettyStartDate = dayjs(item.startDate).format('D MMM');
          subtitle = `${subtitle}${prettyStartDate}`;
        }
        if (item.endDate) {
          const prettyEndDate = dayjs(item.endDate).format('D MMM YYYY');
          subtitle = `${subtitle} - ${prettyEndDate}`;
        }

        const ticketSales = `$${accounting.format(item.ticketStats[0].totalTicketSales / 100)}`;
        const ticketQuantity = `${accounting.format(item.ticketStats[0].totalTicketsSold)} ticket${item.ticketStats[0].totalTicketsSold === 1 ? '' : 's'} sold`;

        const image = item.resources.find(resource => resource.assetType === 'event-image')?.url;

        return {
          ...item,
          oid: item.oid,
          name: item.name,
          subtitle,
          ticketSales,
          ticketQuantity,
          image,
        }
      });
      if (!this.showExpandedEvents) {
        return events.slice(0, 3);
      }
      return events;
    },

    campaignsDecorated() {
      const campaigns = this.campaigns.map( item => {

        const daysDiff = dayjs(item.endDate).diff(dayjs(), 'day');
        const weekDiff = dayjs(item.endDate).diff(dayjs(), 'week');
        const monthDiff = dayjs(item.endDate).diff(dayjs(), 'month');
        let subtitle;
        if (monthDiff > 1) {
          subtitle = `Ends in ${monthDiff} month${monthDiff === 1 ? '' : 's'}`;
        } else if (weekDiff > 2) {
          subtitle = `Ends in ${weekDiff} week${weekDiff === 1 ? '' : 's'}`;
        } else {
          subtitle = `Ends in ${daysDiff} day${daysDiff === 1 ? '' : 's'}`;
        }

        const image = item.resources.find(resource => resource.assetType === 'campaign-image')?.url;
        const registrations = `${accounting.format(item.summaryStatsSnapshot.allRegistrations)} registrations`;
        const views = `${accounting.format(item.summaryStatsSnapshot.uniqueViews)} views`;

        return {
          ...item,
          oid: item.oid,
          name: item.name,
          subtitle,
          image,
          registrations,
          views,
        }
      });

      if (!this.showExpandedCampaigns) {
        return campaigns.slice(0, 3);
      }
      return campaigns;
    },

    salesStatsDecorated() {
      return {
        topics: [],
        chartDatas: [],
      }
    },

    activityDecorated() {
      return this.activity.map(item => {
        return item;
      });
    },

    createNewOptions() {
      const options = [];

      if (process.env.arEnableEmailSending) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleCreateNewEmailMessageClick,
        });
      }
      options.push({
          name: 'SMS',
          icon: {
            name: 'sms',
            color: this.$arStyle.color.sms,
            height: '19px',
            width: '18px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleCreateNewSmsMessageClick,
        },
      );

      return options;
    },

    activityTypeDropdownOptions() {
      return [{
        name: 'Everything',
        key: 'all',
      },{
        name: 'Purchases',
        key: 'purchases',
      },{
        name: 'Campaigns',
        key: 'campaigns',
      },{
        name: 'Events',
        key: 'events',
      }];
    },

  },

  watch: {
    newActivityAvailable: {
      handler: function (val) {
        if (!!val && this.activityPoller !== null) {
          clearInterval(this.activityPoller);
        } else if (!val && this.activityPoller === null) {
          this.createActivityPoll();
        }
      },
      immediate: false,
    },
  },


  methods: {
    ...mapActions([
      'dashboard/FETCH_ACTIVITY',
      'dashboard/FETCH_CAMPAIGNS',
      'dashboard/FETCH_EVENTS',
      'dashboard/FETCH_SALES_STATS',
      'dashboard/CHECK_FOR_NEWER_ACTIVITY',
    ]),
    ...mapMutations([
      'dashboard/RESET_DASHBOARD',
      'dashboard/RESET_ACTIVITY',
      'dashboard/SET_NEW_ACTIVITY_AVAILABLE',
      'dashboard/SET_NO_OLDER_ACTIVITY',
    ]),
    createActivityPoll() {
      console.log("Create activity poll");
      // We only need one poll at a time, so let's remove it if it already exists.
      if (this.activityPoller !== null) {
        clearInterval(this.activityPoller);
      } else {
        this.activityPoller = setInterval(() => {
          this.checkForNewerActivity();
        }, 20000)
      }
    },
    handleMenuSelect(item) {
      this.selectedTab = item.value;
    },
    handleCreateNewEmailMessageClick() {
      this.$router.push({
        path: `/message-center/messages/email/new/basic`,
      });
    },
    handleCreateNewSmsMessageClick() {
      this.$router.push({
        path: `/message-center/messages/sms/new`,
      });
    },
    handleCreateNewEventClick() {
      this.$router.push({
        path: `/events/new`,
      });
    },

    handleSingleCampaignClick(campaignOid) {
      this.$router.push({
        path: `/campaigns/${campaignOid}/view`,
      });
    },

    handleToggleExpandedEvents() {
      this.showExpandedEvents = !this.showExpandedEvents;
    },

    handleToggleExpandedCampaigns() {
      this.showExpandedCampaigns = !this.showExpandedCampaigns;
    },

    handleActivityTypeSelect(item) {
      this.currentActivityType = item.key;
      this.handleRefreshActivity();
    },

    handleRefreshActivity() {
      this.$refs['activity-sidebar'].scrollTop = 0;
      this['dashboard/FETCH_ACTIVITY']({
        activityType: this.currentActivityType,
        reload: true,
      });
    },

    handleFetchOlderActivity() {
      if (!this.activity || this.activity.length === 0) {
        this['dashboard/SET_NO_OLDER_ACTIVITY'](true);
        return;
      }
      const oldestActivity = this.activity.reduce((a, b) => dayjs(b.datetime).valueOf() < dayjs(a.datetime).valueOf() ? b : a);
      const oldestDate = oldestActivity?.datetime;
      this['dashboard/FETCH_ACTIVITY']({
        activityType: this.currentActivityType,
        olderThan: oldestDate,
      });
      this.createActivityPoll();
    },

    checkForNewerActivity() {
      if (!this.activity || this.activity.length === 0) {
        return;
      }
      const newestActivity = this.activity.reduce((a, b) => dayjs(b.datetime).valueOf() > dayjs(a.datetime).valueOf() ? b : a);
      const newestDate = newestActivity?.datetime;
      this['dashboard/CHECK_FOR_NEWER_ACTIVITY']({
        activityType: this.currentActivityType,
        newerThan: newestDate,
      });
    },

    fetchAllData() {
      this['dashboard/FETCH_CAMPAIGNS']();
      this['dashboard/FETCH_EVENTS']();
      this['dashboard/FETCH_ACTIVITY']({
        activityType: this.currentActivityType,
        reload: true,
      });
    },
  },

  beforeDestroy() {
    this['dashboard/RESET_DASHBOARD']();
    if (this.activityPoller !== null) {
      clearInterval(this.activityPoller);
    }
  },

  created() {
    this.fetchAllData();
    this.createActivityPoll();
  }
};
</script>

<style lang="scss" scoped>
  .dashboard {
    display: flex;
    justify-content: space-between;

    &.lg-max {
      justify-content: center;
      flex-direction: column;
    }

    .tabs {
      padding-top: 30px;
    }

    .top-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-section {
        position: relative;
        top: -1px;
        display: flex;
        flex-direction: column;
        min-width: 0; // default min-width is auto, we have to replace it with 0
        align-items: flex-start;
        width: 100%;

        .top-section-title {
          margin-right: 4px;
          display: flex;
          align-items: center;
          height: 30px;


          .vertical-divider {
            width: 1px;
            height: 100%;
            background-color: $skyBlueGrey400;
          }
        }
      }

      .top-right {
        display: flex;
        flex-direction: row;

        &.sm-max {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    .main {
      width: 74%;
      justify-content: center;
      display: flex;


      &.lg-max {
        width: 100%;
      }

      .content {
        display: flex;
        width: 100%;
        height: 100%;
        align-self: center;
        flex-direction: column;
        max-width: 1400px;
        padding: 30px;

        .content-items {
          display: flex;
          flex-direction: column;

          .upcoming-events,
          .active-campaigns {
            display: flex;
            flex-direction: column;
          }
        }

        &.lg-max {
          padding: 14px 30px;
        }

        &.sm-max {
          padding: 12px;
        }

        .header {
          margin-top: 50px;

          &.lg-max {
            margin-top: 12px;
          }
        }

        .tiles-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding-top: 14px;

          .tile {
            max-width: 380px;
          }
        }


      }
    }

    .activity-sidebar {
      background-color: #FFF;
      max-height: 100vh;
      overflow: scroll;
      width: 26%;
      padding: 0 36px 36px 36px;
      min-width: 300px;
      position: sticky;
      top: 0;

      &.lg-max {
        width: 100%;
        max-height: calc(100vh - 150px);
      }

      .activity-header {
        background-color: #FFF;
        position: sticky;
        top: 0;
        z-index: $zIndexHigh;
        border-bottom: 1px solid $skyBlueGrey400;
        &.lg-max {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .new-activity-button-section {
        background-color: #FFF;
        // position: sticky;
        top: -36px;
        width: 100%;
        z-index: $zIndexHigh;

        &.lg-max {
          max-width: 200px;
        }
      }

      .activity-card {
        box-shadow: none;
        border:none;
        border-bottom: 1px solid $skyBlueGrey400;
        border-radius: 0px;
        transition: background-color 0.15s;
        padding-top:4px;
        padding-bottom:4px;

        &:hover {
          background-color: $skyBlueGrey300;
        }
      }
    }
  }

</style>
