<template>
  <section
    class="ar-arep-accounts-page"
    v-infinite-scroll="fetchMoreStaffAccounts"
    :infinite-scroll-disabled="false"
    :infinite-scroll-distance="10"
  >
    <div
      class="accounts-content"
    >
      <am2-top
        :button="topButton"
        :search="{
              action: serverSideSearch,
              placeholder: 'Search accounts',
              value: searchString,
              style: {
                width: '330px'
              }
            }"
        title="Audience Republic Accounts"
        class="top-wrapper"
      />
      <ar-divider
        :style="{
          marginTop: '0',
          marginBottom: '22px',
        }"
      />
      <ar-checkbox
        label="Filter by Audience Republic Email Address"
        :value="onlyAREmailAddress"
        @input="handleIsStaffAccountToggle"
      />
      <am2-card-container
        v-for="account of accounts"
        :key="account.oid"
        :style="{ margin: '20px 0' }"
        class="account-card"
      >
        <div class="title-section">
          <am2-heading
            type="h2"
            size="sm"
            :title="`${account.firstName} ${account.lastName} (${account.emailAddress})`"
          />
          <p class="time-text">
            {{ generateTimeInformation(account.sysCtime, account.promoterAccount) }}
          </p>
        </div>
        <am2-icon-button-dropdown
          :icon-props="{
                name: 'menu',
              }"
          :items="[
                {
                  name: 'Modify',
                  key: 'modify',
                },
                {
                  name: 'Delete',
                  key: 'delete',
                },
              ]"
          @select="(item) => handleStaffAccountActionSelect(account, item)"
        />
      </am2-card-container>
      <am2-loading-section
        class='loading-section'
        v-if="isFetchingAccounts"
      />
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import { sanitizeHtmlChild } from '@/utils/html-element/';

  export default {
    name: 'AdminStaffAccounts',
    layout: 'default',

    data() {
      return {
        searchString: '',
        isFetchingAccounts: false,
        orderBy: { key: 'oid', order: 'desc' },
        disableLoadmore: false,
        accounts: [],
        onlyAREmailAddress: true,
        topButton: {
          text: 'Create account',
          classes: ['top-create-button'],
          action: this.handleCreateAccountClick,
        },
      };
    },
    created() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
      this.fetchStaffAccounts();
    },

    computed: {
      ...mapGetters({
        isAdminAccount: 'auth/isAdminAccount',
      }),
    },

    methods: {
      ...mapActions([
        'FETCH_STAFF_ACCOUNTS',
        'DELETE_STAFF_ACCOUNT',
        'SHOW_CONFIRM',
      ]),

      generateTimeInformation(date) {
        const secDiff = moment().diff(moment.utc(date), 'seconds');
        let timeCreatedCopy = '';

        if (secDiff < 60) {
          timeCreatedCopy = 'Created a few seconds ago';
        } else if (secDiff < 900) {
          timeCreatedCopy = 'Created a few minutes ago';
        } else if (secDiff < 2800) {
          timeCreatedCopy = 'Created half an hour ago';
        } else if (secDiff < 4500) {
          timeCreatedCopy = 'Created one hour ago';
        } else if (secDiff < 86400) {
          timeCreatedCopy = 'Created a few hours ago';
        } else if (secDiff < 864000) {
          timeCreatedCopy = 'Created a few days ago';
        } else {
          timeCreatedCopy = `Created ${moment(date).format('D MMM YYYY')}`
        }

        return timeCreatedCopy;
      },

      async fetchMoreStaffAccounts() {
        if (this.disableLoadmore || this.isFetchingAccounts) {
          return;
        }

        this.disableLoadmore = true;
        this.isFetchingAccounts = true;

        const skip = this.accounts.length;

        if (skip === 0) {
          this.isFetchingAccounts = false;
          this.disableLoadmore =false;
          return;
        }

        try {
          const { rows, count } = await this.FETCH_STAFF_ACCOUNTS({
            skip,
            orderBy: this.orderBy,
            searchString: this.searchString,
            onlyArEmailAddress: this.onlyAREmailAddress,
          });

          this.isFetchingAccounts = false;

          if (rows.length !== 0) {
            this.disableLoadmore = false;
            this.accounts = [...this.accounts, ...rows];
          } else {
            setTimeout(() => {
              this.disableLoadmore = false;
            }, 5000);
          }
        } catch (e) {
          this.isFetchingAccounts = false;
          this.$arNotification.push({ type: 'error', message: 'Failed to fetch more lists' });
        }
      },

      async fetchStaffAccounts() {
        try {
          this.accounts = [];
          this.isFetchingAccounts = true;

          const { rows, count } = await this.FETCH_STAFF_ACCOUNTS({
            skip: 0,
            orderBy: this.orderBy,
            searchString: this.searchString,
            onlyArEmailAddress: this.onlyAREmailAddress,
          });
          this.accounts = rows;
        } catch (e) {
          console.error(e);
          this.$arNotification.push({ type: 'error', message: 'Failed to fetch lists' });
        } finally {
          this.isFetchingAccounts = false;
        }
      },

      async serverSideSearch(search) {
        this.searchString = search;
        this.fetchStaffAccounts();
      },

      handleCreateAccountClick() {
        this.$router.push(`/admin/accounts/new`);
      },

      handleIsStaffAccountToggle(value) {
        this.onlyAREmailAddress = value;
        this.fetchStaffAccounts();
      },

      async deleteAccount(promoterOid, accountOid) {
        try {
          await this.DELETE_STAFF_ACCOUNT({
            promoterOid,
            accountOid
          });
          this.$arNotification.push({ type: 'success', message: 'Account has been deleted' });
          this.fetchStaffAccounts();
        } catch (e) {
          this.$arNotification.push({ type: 'error', message: 'Failed to delete account' });
          console.error(e);
        }
      },

      async handleAccountDelete(account) {
        const ans = await this.SHOW_CONFIRM({
          title: 'Delete this account?',
          messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(account.firstName)}'s</b> account. Are you sure you want to proceed?`,
          confirmButtonText: 'Delete',
          validation: {
            question: `Type ${account.emailAddress} to confirm`,
            answer: account.emailAddress,
          },
          asyncCompleteFunction: () => this.deleteAccount(account.promoterOid, account.oid),
        });
      },

      async handleStaffAccountActionSelect(account, item) {
        if (item.key === 'delete') {
          this.handleAccountDelete(account);
        } else if (item.key === 'modify') {
          this.$router.push({
            path: `/admin/accounts/${account.oid}/edit`
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ar-arep-accounts-page {
    display: flex;
    .accounts-content {
      transition: 0.3s all;
      margin-left: 0;
      width: 100%;
      margin-bottom: 50px;

      .loading-section {
        height: 100px;
      }

      .top-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 26px 0 24px;

        @media (max-width: $lg-min) {
          flex-direction: column;
          justify-content: unset;
          align-items: flex-start;
        }
      }

      .account-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px 30px;

        .time-text {
          color: $blueGrey700;
        }
      }
    }
  }
</style>
