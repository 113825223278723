<template>
  <div class="ar-field-type-select">
    <ar-simple-select
      :items="fieldTypes"
      :default-select-index="fieldTypeSelectIndex"
      enable-filter
      :placeholder="placeholder"
      class="select"
      @select="handleSelectInput"
    />
  </div>
</template>

<script>

export default {
  name: 'FieldTypeSelect',
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select field type...",
    },
  },
  data() {
    return {
      fieldTypes: [
        {
          key: 'singleline',
          name: 'Single line text box'
        },
        {
          key: 'multiline',
          name: 'Multi line text box',
          enableOptions: true,
        },
        {
          key: 'singlecheck',
          name: 'Single checkbox'
        },
        {
          key: 'multicheck',
          name: 'Multiple checkboxes',
          enableOptions: true,
        },
        {
          key: 'dropdown',
          name: 'Dropdown select',
          enableOptions: true,
        },
        {
          key: 'radio',
          name: 'Radio select',
          enableOptions: true,
        },
        {
          key: 'date',
          name: 'Datepicker'
        },
      ],
    };
  },
  computed: {
    fieldTypeSelectIndex() {
      return this.fieldTypes.findIndex(({ key }) => key === this.value);
    },
  },
  methods: {
    filter(query) {
      return this.fieldTypes.filter(fieldType => {
        const search = new RegExp(query.replace(/[;:*?"<>|&'\\()]/g, '').replace(' ', '_'), 'gi');
        return fieldType.value.match(search) !== null;
      });
    },
    handleSelectInput(selected) {
      // if (typeof selected === 'string') {
        this.$emit('input', selected);
      // } else {
      //   this.$emit('input', selected ? selected.value : null);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-field-type-select {
  width: 100%;
  height: 100%;

  .select {
    width: 100%;
  }
}
</style>
