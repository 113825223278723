<template>
  <section
    :class="[
      'campaign-edit-rewards',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    ]"
  >
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 3"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Set the rewards"
      :style="{marginBottom: '38.5px'}"
    />

    <p class="large">
      {{ descriptionCopy }}
    </p>

    <div
      :class="[
        'campaign-edit-rewards__presentation',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <am2-heading
        type="h2"
        size="md"
        title="Rewards"
      />
      <p class="text-with-margin">Create an engaging reward headline and description. This copy will also be part of the notifications sent out to encourage fans to earn more points.</p>

      <!-- Headline name -->
      <am2-field
        name="rewards-headline"
        label="Headline"
        description="Create an engaging headline which includes a clear call to action and the incentive."
      >
        <div>
          <ar-input
            v-validate="'required'"
            id="rewards-headline"
            ref="rewards-headline"
            v-model="campaignViewModel.rewards.headline"
            :value="campaignViewModel.rewards.headline"
            name="rewards-headline"
            data-vv-name="rewardsHeadline"
            class="input--block"
            type="text"
          />
          <ar-state-message
            v-if="veeErrors.has('rewardsHeadline')"
            :style="{ marginTop: '8px' }"
            type="error"
            :text="veeErrors.first('rewardsHeadline')"
          />
        </div>
      </am2-field>

      <am2-field
        name="rewards-description"
        label="Description"
        description="Tell people why they should earn points, and how they can do it."
      >
        <div>
          <ar-textarea
            v-validate="'required'"
            v-model="campaignViewModel.rewards.description"
            :rows="3"
            name="rewards-description"
            data-vv-name="rewardsDescription"
          />
        </div>
        <ar-state-message
          v-if="veeErrors.has('rewardsDescription')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('rewardsDescription')"
        />
      </am2-field>

    </div>

    <div class="campaign-edit-rewards__tiers">
      <div
        v-for="(tier, index) in campaignViewModel.rewards.tiers"
        :key="`${tier.key}_${index + 1}`"
        :id="`tier_${index+1}`"
        class="campaign-edit-rewards__tier"
      >
        <div
          :class="[
            'container',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          ]"
        >
          <div class="row">
            <div class="col-sm-12 tier-header">
              <am2-heading
                type="h3"
                size="md"
                :title="`Tier #${ index + 1 }`"
              />

              <ar-link-button
                text="Delete"
                v-if="tiersDeletable"
                @click.native="()=> removeTier(index)"
              />
            </div>
            <div class="col-sm-12">
              <ar-divider :style="{ margin: '32px 0' }" />
            </div>
          </div>
          <!-- <template v-if="tier.ticket || tier.prize"> -->
          <div class="row">
            <template v-for="(item) in tierItemsAsList(tier)">
              <!--Multiple items in each tier-->
              <div v-if="item.type !== 'prize'" :key="`${item.type}_${index}`" class="col-sm-12">
                <!-- It's for Paid and Free -->
                <div
                  v-if="item.type !== 'free'"
                  :is="types[tier.ticket.type].component"
                  :id="`tier_${index+1}_ticket`"
                  :tier="tier.ticket"
                  :campaign="campaignViewModel"
                  :tier-index="index"
                  :tier-item-order="tier.ticket.insertionOrder"
                  :class="[
                    'campaign-edit-rewards__wrapper',
                    $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                  ]"
                  @input="val => updateTicket(val, index)"
                  @removePrize="() => removePrize('ticket', index)"
                />
                <div
                  v-else
                  :class="[
                    'campaign-edit-rewards__wrapper',
                    $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                  ]"
                >
                  <ar-snackbar
                    type="error"
                    message="The Free Ticket option is no longer available. Please use Paid Ticket or Prize instead. <a>Remove</a>"
                    @anchorClick="() => { removePrize('free', index) }"
                  />
                </div>
              </div>
              <div v-else :key="`${item.type}_${index}`" class="col-sm-12">
                <!-- It's for Prize -->
                <div
                  :is="types[tier.prize.type].component"
                  :id="`tier_${index+1}_prize`"
                  :tier="tier.prize"
                  :campaign="campaignViewModel"
                  :tier-index="index"
                  :tier-item-order="tier.prize.insertionOrder"
                  :class="[
                    'campaign-edit-rewards__wrapper',
                    $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                  ]"
                  @prizeChange="val => updatePrize(val, index)"
                  @imageUploaded="addImageToResources"
                  @imageDeleted="deleteImageFromResources"
                  @input="val => updatePrize(val, index)"
                  @removePrize="() => removePrize('prize', index)"
                  :removable="!isCompetition"
                />
              </div>
            </template>
          </div>

          <div class="row"
            v-if="!isCompetition"
          >
            <div class="tiers-types col-sm-12">
              <div class="row">
                <div
                  v-for="(type, key) in types"
                  :key="key"
                  class="col-md-6 col-xs-12">

                  <ar-simple-button
                    :disabled="!canAddTierItem(key, index)"
                    @click.native="addTierItem(key, index)"
                    icon-name="circle-plus"
                    :text="type.name"
                    outlined
                    class="tier-type-btn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <ar-divider :style="{ margin: '25px 0' }" />
            </div>
          </div>
          <template>

            <am2-field
              name="number-of-winners"
              label="Number of winners"
              class="number-of-winners-label"
              description="Set the number of winners for this tier. This will also be the number of purchase links that will be sent out when notifying winners. Setting ticket purchase limits are done with the ticketing provider."
            />

            <div class="number-of-winners-container">
              <!--This is to hide the value when remaining winners is checked -->
              <div
                :class="[
                  'number-of-winners-horiz-wrapper',
                  $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                ]">
                <ar-input
                  v-if="tier.isRemainingWinners && index >= campaignViewModel.rewards.tiers.length - 1"
                  :class="{ 'winner-input': true, 'last-tier-winners' : index >= campaignViewModel.rewards.tiers.length - 1 }"
                  :disabled="true"
                  type="text"
                />

                <ar-input
                  v-validate="'required|numeric'"
                  v-else
                  id="number-of-winners"
                  ref="number-of-winners"
                  :value="tier.numberOfWinners"
                  v-model.number="tier.numberOfWinners"
                  :class="{ 'winner-input': true }"
                  :data-vv-name="`numWinners_${index}`"
                  data-vv-as="number of winners"
                  type="number"
                />
                <ar-checkbox
                  v-if="index >= campaignViewModel.rewards.tiers.length - 1"
                  class="last-tier-checkbox"
                  v-model="tier.isRemainingWinners"
                  label="All remaining fans who register"
                />
              </div>

              <ar-state-message
                v-if="veeErrors.has(`numWinners_${index}`)"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first(`numWinners_${index}`)"
              />
            </div>
          </template>
        </div>



      </div>

      <div v-if="!campaignViewModel.rewards.tiers || campaignViewModel.rewards.tiers.length === 0">
        <div class="campaign-edit-rewards__tier">
          <div
            :class="[
              'container',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            ]">
            <div class="row">
              <div class="col-sm-12">
                <am2-heading
                  type="h3"
                  size="md"
                  :title="`Tier #1`"
                />
              </div>
              <div class="col-sm-12">
                <ar-divider :style="{ margin: '25px 0' }" />
              </div>
              <p class="col-sm-12" :style="{ marginBottom: '25px' }">
                What type of reward would you like to start with?
              </p>
              <div class="col-sm-12">
                <div class="tiers-types">
                  <div class="row">
                    <div
                      v-for="(type, key) in allowedTypes"
                      :key="key"
                      :class="[
                        `col-md-${Math.floor(12 / numAllowedTypes)} col-xs-12`,
                        $arMediaQuery.pageContent.maxWidth('sm') && 'u-margin-top-2',
                      ]">

                      <ar-simple-button
                        @click.native="addFirstTier(key)"
                        icon-name="circle-plus"
                        :text="type.name"
                        outlined
                        class="tier-type-btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="campaignViewModel.rewards.tiers && campaignViewModel.rewards.tiers.length > 0">
        <ar-simple-button
          text="Add another tier"
          class="add-tier"
          icon-name="circle-plus"
          icon-distance="20px"
          @click.native="addTier()"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { validatorDictionary } from '@/utils/campaign';
import { copyGenerator } from '@/utils/campaign';

import Paid from './paid';
import Prize from './prize';

export default {
  name: 'EditRewards',

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  data() {
    return {
      types: {
        paid: {
          name: 'Paid ticket',
          type: 'paid',
          component: Paid,
        },
        prize: {
          name: 'Prize',
          type: 'prize',
          component: Prize,
        },
      },
      campaignViewModel: {
        rewards: {},
        resources: [],
      },
    };
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        const { resources, rewards } = value
        const resourceOids = resources.reduce((accum, resource) => accum.push(resource.oid) && accum, [])

        this.setEditCampaign({
          rewards,
          resources,
          resourceOids,
        });
      },
      deep: true
    }
  },

  mounted() {
    this.$validator.dictionary.merge(validatorDictionary());

    this.initializeCampaignViewModel();
  },

  provide() {
    return {
      $validator: this.$validator,
    };
  },

  computed: {
    descriptionCopy() {
      return copyGenerator('rewards', this.editCampaign.type).description;
    },
    isCompetition() {
      return this.editCampaign.type === 'competition';
    },
    allowedTypes() {
      if(this.isCompetition) {
        return {
          prize: {
            name: 'Prize',
            type: 'prize',
            component: Prize,
          }
        };
      } else {
        return this.types;
      }
    },
    numAllowedTypes() {
      return Object.keys(this.allowedTypes).length;
    },
    tiersDeletable() {
      return this.isCompetition ? this.campaignViewModel.rewards.tiers.length > 1 : true;
    }
  },

  methods: {
    initializeCampaignViewModel() {
      this.campaignViewModel.rewards = this.editCampaign.rewards;
      this.campaignViewModel.resources = this.editCampaign.resources;

      // JNM: When editing "old style" campaigns, setup with AM1, the rewards object might be empty
      // if so, initialise as well with the tiers, headline and description defaults.
      if (!this.campaignViewModel.rewards || Object.keys(this.campaignViewModel.rewards).length === 0) {
        this.campaignViewModel.rewards = {
          tiers: [],
          headline: '',
          description: '',
        }
      }

      if (
        this.isCompetition &&
        this.campaignViewModel.rewards.tiers.length === 0
      ) {
        this.addFirstTier('prize');
      }
    },
    addFirstTier(type) {
      this.campaignViewModel.rewards.tiers.push({
        numberOfWinners: null,
        isRemainingWinners: false,
        ticket: null,
        prize: null,
      });
      this.$nextTick(() => {
        this.addTierItem(type, 0);
        const tier = document.getElementById(`tier_${this.campaignViewModel.rewards.tiers.length}`);
        this.$scrollTo(tier, 500, { offset: -160 });
      });
    },
    addTier() {
      const adjustRemainingWinners = this.campaignViewModel.rewards.tiers.map(t => ({
        ...t,
        isRemainingWinners: false,
      }));
      adjustRemainingWinners.push({
        numberOfWinners: null,
        isRemainingWinners: false,
        ticket: null,
        prize: null,
      });

      this.campaignViewModel.rewards.tiers = adjustRemainingWinners;

      if (this.isCompetition) {
        const lastIndex = this.campaignViewModel.rewards.tiers.length - 1;
        this.addTierItem('prize', lastIndex);
      } else {
        this.$nextTick(() => {
          const tier = document.getElementById(`tier_${this.campaignViewModel.rewards.tiers.length}`);
          this.$scrollTo(tier, 500, { offset: -160 });
        });
      }
    },
    addTierItem(type, tierIndex) {
      const tier = this.campaignViewModel.rewards.tiers[tierIndex];
      if (type === 'paid' || type === 'free') {
        const insertionOrder = tier.prize === null ? 0 : tier.prize.insertionOrder + 1;
        this.$set(tier, 'ticket', { type, insertionOrder });
        type = 'ticket';
      } else if (type === 'prize') {
        const insertionOrder = tier.ticket === null ? 0 : tier.ticket.insertionOrder + 1;
        this.$set(tier, 'prize', { type, insertionOrder });
      }
      this.$nextTick(() => {
        const item = document.getElementById(`tier_${tierIndex + 1}_${type}`);
        this.$scrollTo(item, 500, { offset: -160 });
      });
    },
    updateTicket(val, tierIndex) {
      this.$set(this.campaignViewModel.rewards.tiers[tierIndex], 'ticket', { ...val });
    },
    updatePrize(val, tierIndex) {
      this.$set(this.campaignViewModel.rewards.tiers[tierIndex], 'prize', { ...val });
    },
    removeRewardResource(tierIndex, type) {
      const resourceIndex = this.campaignViewModel.resources.findIndex(r => {
        const rewardDetails = this.campaignViewModel
            .rewards
            .tiers[tierIndex][type]
            .details;

        if (rewardDetails !== undefined) {
          return r.oid === rewardDetails.resourceOid;
        }

        return false;
      });

        if (resourceIndex >= 0) {
          this.campaignViewModel.resources.splice(resourceIndex, 1);
        }
    },
    removeTier(index) {
      if (this.campaignViewModel.rewards) {
        if (this.campaignViewModel.rewards.tiers[index].prize) {
          this.removeRewardResource(index, 'prize');
        }
        this.campaignViewModel.rewards.tiers.splice(index, 1);
      }
    },
    removePrize(type, tierIndex) {
      if (type === 'prize') {
        // remove resource oid from list
        this.removeRewardResource(tierIndex, type);
      }

      this.$set(this.campaignViewModel.rewards.tiers[tierIndex], type, null);
    },
    addImageToResources(imageData) {
      this.campaignViewModel.resources.push(imageData);
    },
    deleteImageFromResources(resourceOid) {
      this.campaignViewModel.resources = this.campaignViewModel.resources.filter(
        r => r.oid !== resourceOid,
      );
    },
    canAddTierItem(key, tierIndex) {
      if (key === 'free' || key === 'paid') {
        if (this.campaignViewModel.rewards.tiers[tierIndex].ticket) {
          return false;
        }
      }
      if (key === 'prize') {
        if (this.campaignViewModel.rewards.tiers[tierIndex].prize) {
          return false;
        }
      }
      return true;
    },
    tierItemsAsList(tierItems) {
      const { ticket, prize } = tierItems;
      const list = [];
      if (ticket !== null) {
        list.push(ticket);
      }

      if (prize !== null) {
        list.push(prize);
      }

      return list.sort((a, b) => {
        if (a.insertionOrder < b.insertionOrder) {
          return -1;
        }

        if (a.insertionOrder > b.insertionOrder) {
          return 1;
        }

        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/styles/base/mixins';
.campaign-edit-rewards {
  &__presentation {
    background-color: rgba(247, 249, 251, 1);
    padding: 34px 40px;
    margin: 2rem auto;
    border-radius: 4px;
    &.sm-max {
      padding: 26px 16px;
    }
  }

  &__tier {
    border: 1px solid $skyBlueGrey500;
    border-radius: 4px;
    margin: 2rem auto;
    position: relative;

    .container {
      padding: 34px 40px;
      &.sm-max {
        padding: 26px 16px;
      }
    }

    .tiers-types {
      button {
        width: 100%;
        &:hover {
          border-color: $purple500 !important;
        }
      }
    }

    .tier-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .ar-field {
    margin-top: 1rem;
   .ar-field__label {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    background-color: rgba(247, 249, 251, 1);
    border-radius: 4px;
    padding: 2rem;
    margin: 2rem auto;

    &.xs-max {
      padding: 16px 12px;
      margin: 12px auto;

      .row {
        flex-direction: column;

        .ar-field {
          margin-top:12px;
          margin-bottom:4px;
        }
      }
    }


    >>> .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .ar-field .ar-field__label {
      color: $blueGrey700;
      font-weight: 100;
      margin-top: 10px;
      margin-bottom: 8px;
    }

    .ar-dropzone {
      width: 252px;
      min-height: 150px;
    }
    .image-upload-hint {
      display: flex;
      align-items: center;
      color: $blueGrey600;
    }
  }

  .add-tier {
    margin-top: 2rem;
    width: 100%;
    /* color: white; */
  }

  >>> .remove-tier {
    cursor: pointer;

    g {
      fill: $skyBlueGrey600;
    }
  }

  .tier-type-btn {
    width: 100%;
  }
  .label-text {
    margin-bottom: 1rem;
  }
  .text-with-margin {
    margin-top: 0.5rem;
  }

  .add-tier-icon {
    width: 20px;
    vertical-align: bottom;
    margin-right: 5px;
  }
  .add-tier-name {
    vertical-align: text-bottom;
  }

  .number-of-winners-container {

    .number-of-winners-horiz-wrapper {
      display: flex;
      flex: 1;
      .winner-input {
        width: calc(100% - 245px);
      }
      .last-tier-checkbox {
        display: flex;
        width: 245px;
        align-items: center;
        margin-left: 30px;
      }
      &.xs-max {
        flex-direction:column;
        .winner-input,
        .last-tier-checkbox {
          margin-left:0;
          width:100%;
          margin-bottom:12px;
        }
      }
    }
  }
  .invalid-tier-add-error {
    margin-left: 9px;
    margin-top: 10px;
  }
  &.sm-max {
    .button:not(.button--text).is-primary,
    .button:not(.button--text).is-secondary {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
