var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{class:[
    'ar-event-card-skeleton',
    _vm.showAs ]},[_c('ar-skeleton',{class:[
      'left-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],attrs:{"level":_vm.level}}),_vm._v(" "),_c('div',{staticClass:"right-section"},[_c('div',{staticClass:"text-bars"},[_c('am2-typography-skeleton',{staticClass:"text-skeleton upper",style:({
          maxWidth: _vm.showAs === 'tiles' ? '400px' : '200px',
        }),attrs:{"size":"md","level":_vm.level + 2}}),_vm._v(" "),(_vm.showAs !== 'list')?_c('am2-typography-skeleton',{staticClass:"text-skeleton lower",style:({
          maxWidth: '250px',
        }),attrs:{"size":"md","level":_vm.level + 2}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"meta-bars-skeleton"},[_c('am2-typography-skeleton',{staticClass:"text-skeleton",style:({
          maxWidth: '110px',
        }),attrs:{"size":"xxxs","level":_vm.level + 2}}),_vm._v(" "),_c('am2-typography-skeleton',{class:[
          'text-skeleton',
          'u-margin-left-2' ],style:({
          maxWidth: '60px',
        }),attrs:{"size":"xxxs","level":_vm.level + 2}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }