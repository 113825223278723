var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-ask-memberful-client-credentials-modal',{attrs:{"is-show":_vm.showAskMemberfulCredentialsModal,"loading":_vm.isCreatingMemberfulIntegration},on:{"confirm":_vm.handleMemberfulClientCredentialsConfirm,"cancel":function($event){_vm.showAskMemberfulCredentialsModal = false}}}),_vm._v(" "),_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Memberful","description":"Automatically sync your members from Memberful","icon-props":{
          name: 'memberful',
          color: _vm.$arStyle.color.memberful,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingMemberfulIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[(!_vm.isFetchingMemberfulIntegration && _vm.memberfulIntegration && _vm.currentIntegration)?_c('am2-integration-account-select',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.currentIntegration.oid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleIntegrationChange,"new-account":_vm.handleAddNewIntegration}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Open Memberful',
            value: 'goToMemberfulSite',
          },
          {
            name: 'Reconnect Integration',
            value: 'reconnectIntegration',
          },
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.isFetchingMemberfulIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[_c('am2-tabs',{staticClass:"tab-options",attrs:{"data-test-id":"memberful-tabs","items":[
        { title: 'Webhook URL' },
        { title: 'Status' } ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Memberful connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('section',[(_vm.selectedTabIndex === 0)?_c('div',[_c('am2-card-container',{staticClass:"webhook-container"},[_c('ar-text',{staticClass:"u-margin-bottom-6",style:({
              color: _vm.$arStyle.color.blueGrey700,
              maxWidth: '590px',
              marginBottom: '50px',
            }),attrs:{"size":"xs","text":"Copy the URL below to add a new Webhook inside your Memberful Account Settings.","align":"center","allow-html":"","multiple-lines":"","line-height":"28px"}}),_vm._v(" "),_c('am2-new-slug-input',{style:({
              maxWidth: '708px',
            }),attrs:{"data-test-id":"memberful-tab-webhook-url-input","value":_vm.webhookUrl,"readonly":""}}),_vm._v(" "),_c('div',{staticClass:"read-more-container u-margin-bottom-6"},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xxxs","text":"Read more about "}}),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-left-1",attrs:{"data-test-id":"memberful-tab-setup-support-link","text":"how to add a webhook to your Memberful account","has-underline":true,"text-props":{
                size: 'xxxs'
              }},on:{"click":_vm.handleSetupClick}})],1)],1)],1):_c('div',[_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"data-test-id":"memberful-status-tab-sync","is-fetching-sync-task":_vm.isFetchingSyncTask,"sync-status":_vm.syncStatus,"sync-time":_vm.syncTime,"sync-copies":{
            'in-progress': 'Syncing data from Memberful',
            'pending': 'The data sync is still pending',
            'completed': 'Your Memberful account is currently connected',
            'cancelled': 'Your Memberful account connection is cancelled',
            'failed': this.syncErrorMessage,
            'default': 'Syncing tasks not found',
          }},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }