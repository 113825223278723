<template>
  <am2-card-container
    :class="[
      'ar-simple-card-skeleton',
      showAs,
    ]"
  >
    <ar-skeleton class="left-section" :level="level" />
    <div class="right-section">
      <am2-typography-skeleton
        class="text-skeleton upper"
        size="sm"
        :level="level + 2"
        :style="{
          maxWidth: '200px',
        }"
      />
      <am2-typography-skeleton
        class="text-skeleton lower"
        size="xs"
        :level="level + 2"
        :style="{
          maxWidth: '250px',
        }"
      />
    </div>
  </am2-card-container>
</template>

<script>
export default {
  name: 'SimpleCardSkeleton',

  props: {
    showAs: {
      type: String,
      default: 'list',
      validator: (value) =>
        ['list', 'tiles'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-simple-card-skeleton {
  display: flex;

  .right-section {
    display: flex;
    flex-flow: column;
    padding-left: 20px;
  }

  &.list {
    flex-flow: row;
    align-items: center;
    height: 80px;

    .left-section {
      width: 150px;
      height: 100%;
    }

    .right-section {
      justify-content: space-between;
      .text-skeleton {
        height: 20px;

        &.upper {
          width: 200px;
        }

        &.lower {
          width: 250px;
          height: 16px;
          margin-top: 8px;
        }
      }
    }
  }
  &.tiles {
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .left-section {
      width: 100%;
      height: 250px;
    }

    .right-section {
      margin-top: 8px;
      padding: 8px 21px 65px;
      width: 100%;
      position: relative;

      .text-skeleton {
        height: 20px;

        &.upper {
          width: calc(100% - 50px);
        }

        &.lower {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
