import { InputRule } from "prosemirror-inputrules"
import { arDynamicTagsRegex, arDynamicTagsRegexGlobal } from '@/utils/regex/';

class DynamicTagView {
  constructor(node, view, getPos) {
    this.node = node;
    this.view = view;
    this.getPos = getPos;

    this.dom = this.createTag(node.attrs.tagName);
  }

  createTag(tagName) {
    const tagElem = document.createElement('div');
    tagElem.style.display = 'inline-flex';
    tagElem.style.padding = '2px 6px';
    tagElem.style.alignItems = 'center';
    tagElem.style.background = this.node.attrs.exist ? '#E3E6EC' : '#F7DDE1';
    tagElem.style.borderRadius = '3px';
    tagElem.style.zIndex = '2';

    const textElem = document.createElement('span');
    textElem.style.position = 'relative';
    textElem.style.top = '1px';
    textElem.style.color = this.node.attrs.exist ? 'inhert' : '#fc4349';
    textElem.style.opacity = this.node.attrs.exist ? '1' : '0.9';
    textElem.style.fontSize = 'inhert';
    textElem.style.fontWeight = 'bold';
    textElem.style.lineHeight = '1.3';
    textElem.innerText = tagName;

    tagElem.appendChild(textElem);

    const crossIconElem = document.createElement('div');
    const crossIconColor = this.node.attrs.exist ? '%238E97A6' : '%23fc4349';
    crossIconElem.style.display = 'inline-block';
    crossIconElem.style.width = '10px';
    crossIconElem.style.height = '10px';
    crossIconElem.style.marginLeft = '6px';
    crossIconElem.style.backgroundImage = `url("data:image/svg+xml,%0A%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='04.11' transform='translate(-416.000000, -659.000000)' stroke='${crossIconColor}'%3E%3Cg id='Group-15' transform='translate(80.000000, 335.000000)'%3E%3Cg id='Group-5' transform='translate(210.000000, 304.000000)'%3E%3Cg id='Group-18-Copy' transform='translate(40.000000, 14.000000)'%3E%3Cg id='Group-17' transform='translate(86.000000, 6.000000)'%3E%3Cpath d='M0.5,0.5 L9.5,9.5' id='Path'%3E%3C/path%3E%3Cpath d='M9.5,0.5 L0.5,9.5' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
    crossIconElem.style.backgroundRepeat = 'no-repeat';
    crossIconElem.style.cursor = 'pointer';

    crossIconElem.addEventListener('click', () => {
      const tr = this.view.state.tr;
      const from = this.getPos();
      tr.deleteRange(from, from + 1);
      this.view.dispatch(tr);
    });

    tagElem.appendChild(crossIconElem);

    return tagElem;
  }
}

export default class DynamicTag {
  constructor({ tagNames }) {
    this.tagNames = tagNames;
  }

  get type() {
    return 'node';
  }

  get name() {
    return 'dynamicTag';
  }

  get schema() {
    return {
      attrs: { tagName: { }, exist: {  } },
      group: 'inline',
      inline: true,
      content: '',
      selectable: false,
      marks: '',
      toDOM: (node) => {
        return [
          'dynamic-tag',
          {
            ['tag-name']: node.attrs.tagName,
          }
        ];
      },
      parseDOM: [
        {
          tag: 'dynamic-tag',
          getAttrs: (dom) => {
            const tagName = dom.getAttribute('tag-name');
            return {
              tagName,
              exist: this.tagNames.indexOf(tagName) > -1,
            };
          },
        },
      ],
    };
  }

  commands({ schema, view }) {
    return (tagName) => {
      const { $from } = view.state.selection;
      const index = $from.index();
      if (!$from.parent.canReplaceWith(index, index, schema.nodes.dynamicTag)) {
        return false
      }
      if (view.dispatch) {
        const tr = view.state.tr;
        tr.replaceSelectionWith(
          schema.nodes.dynamicTag.create({ tagName, exist: this.tagNames.indexOf(tagName) > -1 })
        );
        view.dispatch(tr);
        view.focus();
      }
      return true;
    }
  }

  inputRules({ schema }) {
    return [{
      match: arDynamicTagsRegex,
      handler: (view, match, start, end) => {
        const tr = view.state.tr;
        const dynamicNode = schema.nodes.dynamicTag;
        const tagName = match[1];
        const attrs = {
          tagName: match[1],
          exist: this.tagNames.indexOf(tagName) > -1,
        }
        tr.replaceRangeWith(start, end, dynamicNode.create(attrs));
        return tr;
      },
    }];
  }

  nodeView(node, view, getPos) {
    return new DynamicTagView(node, view, getPos);
  }
}
