<template>
  <!-- Curretnly set totalSetp to "null" -->
  <AuthenticateFrame
    :totalStep="null"
    :currentStep="1"
  >
    <form
      @submit.prevent="handleCreateAccountSubmit"
      class="authenticate-signup"
    >
      <ar-text
        text="Create your free account"
        size="lg"
        multiple-lines
        :style="{
          marginBottom: '14px',
        }"
      />
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '28px',
        }"
      >
        <ar-text
          size="xxxs"
          text="Already have an account?"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
            marginTop: '1px',
          }"
        />
        <ar-link-button
          text="Login here"
          :text-props="{
            size: 'xxxs',
          }"
          :style="{
            marginLeft: '4px',
          }"
          @click="handleLoginLinkClick"
        />
      </div>
      <div
        :style="{
          marginBottom: '20px',
        }"
      >
        <ar-input
          v-model="registerData.emailAddress"
          v-validate="'required|email'"
          autocomplete="email"
          data-vv-name="emailAddress"
          data-vv-as="email address"
          placeholder="Email address"
          :has-error="veeErrors.has('emailAddress')"
        />
        <ar-state-message
          v-if="veeErrors.has('emailAddress')"
          :text="veeErrors.first('emailAddress')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <div
        :style="{
          marginBottom: '20px',
        }"
      >
        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
          }"
        >
          <div
            :style="{
              width: 'calc(50% - 7px)'
            }"
          >
            <ar-input
              v-model="registerData.firstName"
              v-validate="'required'"
              data-vv-name="firstName"
              autocomplete="given-name"
              data-vv-as="first name"
              placeholder="First name"
              :has-error="veeErrors.has('firstName')"
            />
            <ar-state-message
              v-if="veeErrors.has('firstName')"
              :text="veeErrors.first('firstName')"
              type="error"
              :style="{ marginTop: '8px' }"
            />
          </div>
          <div
            :style="{
              width: 'calc(50% - 7px)'
            }"
          >
            <ar-input
              v-model="registerData.lastName"
              v-validate="'required'"
              data-vv-name="lastName"
              autocomplete="family-name"
              data-vv-as="last name"
              placeholder="Last name"
              :has-error="veeErrors.has('lastName')"
            />
            <ar-state-message
              v-if="veeErrors.has('lastName')"
              :text="veeErrors.first('lastName')"
              type="error"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </div>
      </div>
      <div
        :style="{
          marginBottom: '20px',
        }"
      >
        <ar-input
          v-model="registerData.company"
          v-validate="'required'"
          data-vv-name="company"
          data-vv-as="company"
          autocomplete="organization"
          :has-error="veeErrors.has('company')"
          placeholder="Company"
        />
        <ar-state-message
          v-if="veeErrors.has('company')"
          :text="veeErrors.first('company')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <div
      :style="{
        marginBottom: '20px'
      }">
        <ar-simple-select
          data-vv-name="companyCategory"
          data-vv-as="company type"
          v-validate:hasCompanyTypeSelected="'required:true'"
          autocomplete="companyCategory"
          :has-error="veeErrors.has('companyCategory')"
          placeholder="Company type"
          :items="companyCategoryDropdownItems"
          :default-select-index="companyCategorySelectedIndex"
          @select="handleCompanyTypeDropdownSelect"
          data-test-id="company-category-selector"
        />
        <ar-state-message
          v-if="veeErrors.has('companyCategory')"
          :text="veeErrors.first('companyCategory')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <div
        :style="{
          marginBottom: '20px',
        }"
      >
        <ar-mobile-input
          v-model="registerData.mobileNumber"
          v-validate="'required|mobileNumber'"
          data-vv-name="mobileNumber"
          data-vv-as="mobile number"
          autocomplete="tel-national"
          :has-error="veeErrors.has('mobileNumber')"
          :style="{
            width: '100%',
          }"
          @select="handleMobileNumberInputCountrySelect"
        />
        <ar-state-message
          v-if="veeErrors.has('mobileNumber')"
          :text="veeErrors.first('mobileNumber')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <div
        :style="{
          marginBottom: '40px',
        }"
      >
        <ar-input
          v-model="registerData.password"
          v-validate="'required|arPasswordLength|max:256'"
          data-vv-name="password"
          data-vv-as="password"
          placeholder="Create a password"
          autocomplete="new-password"
          :has-error="veeErrors.has('password')"
          text-secure
          type="password"
        />
        <ar-state-message
          v-if="veeErrors.has('password')"
          :text="veeErrors.first('password')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <ar-simple-button
        type="gradient-purple"
        shape="pill"
        text="Create account"
        :loading="isDoingSignup"
        :style="{
          width: '100%',
          marginBottom: '20px',
        }"
      />
      <ar-text
        class="comment"
        size="xxxs"
        align="center"
        :text="commentCopy"
        allow-html
        multiple-lines
      />
    </form>
  </AuthenticateFrame>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { companyCategoryTypes } from '~/utils/constants';

import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'signup',
  components: {
    AuthenticateFrame,
  },
  layout: 'split',
  data() {
    return {
      registerData: {
        emailAddress: null,
        firstName: null,
        lastName: null,
        company: null,
        mobileNumber: null,
        password: null,
        country: null,
        companyCategory: null,
      },
      companyCategorySelectedIndex: null,
      commentCopy: 'By continuing you agree to our <a href="https://audiencerepublic.com/privacy/" target="_blank" style="font-size: 12px">Privacy Policy</a> and <a href="https://audiencerepublic.com/terms-fans/" target="_blank" style="font-size: 12px">Terms of Service</a>',
    };
  },
  computed: {
    ...mapState({
      waveApp: state => state.route.query.waveApp === 'true',
      account: state => state.auth.account,
      isDoingSignup: state => state.auth.isDoingSignup,
    }),
    ...mapGetters({
      promoterAccountOid: 'auth/promoterAccountOid',
    }),
    companyCategoryDropdownItems() {
      return companyCategoryTypes();
    },
    hasCompanyTypeSelected() {
      return this.registerData.companyCategory !== null;
    },
  },
  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);
  },
  mounted() {
    // You're logged in ? Let's go to Subscriptions page
    if (this.promoterAccountOid) {
      this.$router.replace({ path: '/settings/subscriptions' });
    }
  },
  methods: {
    ...mapActions([
      'auth/SIGNUP',
      'auth/REGISTER_TO_WAVE',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    createWaveAccount() {
      this['auth/REGISTER_TO_WAVE'](this.registerData);
    },
    async createAmAccount() {
      const succeed = await this['auth/SIGNUP'](this.registerData);
      if (succeed) {
        this.$router.push({
          path: '/audience',
        });
      }
    },
    handleMobileNumberInputCountrySelect(item) {
      this.registerData.country = item.iso;
    },
    handleCompanyTypeDropdownSelect(item) {
      this.registerData.companyCategory = item.key;
      this.companyCategorySelectedIndex = this.companyCategoryDropdownItems.findIndex(dropdownItem => dropdownItem.key === item.key)
    },
    async handleCreateAccountSubmit() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (this.waveApp) {
        this.createWaveAccount();
      } else {
        this.createAmAccount();
      }
    },
    handleLoginLinkClick() {
      if (this.waveApp) {
        window.location.href = process.env.arWaveRegistrationLoginUrl;
      } else {
        this.$router.push({
          path: '/authenticate/login',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.authenticate-signup {
  .comment {
    padding: 0 20px;
    color: $skyBlueGrey700;
  }
}
</style>
