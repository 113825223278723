<template>
  <section>
    <ar-field
      label="Your Company Name"
      :error-messages="[veeErrors.first('companyName')]"
    >
      <ar-input
        :value="scratchPrivacyPortal.companyName"
        @input="handleCompanyNameInput"
        v-validate="'required'"
        autocomplete="organization"
        data-vv-name="companyName"
        data-vv-as="company name"
        placeholder="Your company name"
      />
    </ar-field>
    <ar-field
      class="u-margin-top-6"
      label="Privacy Portal URL"
      description="You’ll provide this link to customers so they can access the privacy portal"
      :error-messages="[veeErrors.first('subdomain')]"
    >
      <div class="u-display-flex u-align-items-center">
        <ar-input
          class="u-flex-grow-1 u-margin-right-3"
          :value="scratchPrivacyPortal.subdomain"
          @input="handleSubdomainInput"
          v-validate="{
            required: true,
            arPrivacyPortalSubdomain: {
              currentSubdomain: currentPrivacyPortalSubdomain,
            },
          }"
          data-vv-name="subdomain"
          data-vv-as="subdomain"
          placeholder="subdomain"
        />
        <ar-text
          class="u-margin-right-2"
          size="xs"
          :text="`.${privacyPortalDomain}`"
        />
      </div>
    </ar-field>
    <!-- Company Logo -->
    <ar-field
      class="u-margin-top-7"
      label="Company Logo"
      description="Recommended dimensions 300px x 300px"
      :error-messages="[veeErrors.first('companyLogo'), veeErrors.first('companyLogoUploading'), imageUploadErrorMsg]"
    >
      <!-- Validate if image url exists -->
      <div
        v-show="false"
        v-validate:privacyPortalImageUrl="'required:true'"
        data-vv-name="companyLogo"
        data-vv-as="company logo"
      />
      <div
        v-if="isUploadingCompanyLogo"
        v-validate:isUploadingCompanyLogo="'uploadingAssets'"
        data-vv-name="companyLogoUploading"
        data-vv-as="company logo"
      />
      <!-- We don't show this, we just need its functions -->
      <am2-dropzone
        v-show="false"
        ref="img-dropzone"
        :file-type="['image/jpeg', 'image/jpg', 'image/png']"
        file-type-alias="image"
        :placeholder-icon="{
          name: 'image',
        }"
        :image-options="{
          dimensions: {
            width: 300,
            height: 300
          }
        }"
        :ratio="1"
        feedback-type="image"
        :feedback="privacyPortalImageUrl"
        enable-ar-s3-bucket-upload
        :ar-s3-bucket-upload-options-list="[{
          assetType: 'privacy-portal-image',
          campaignOid: null,
          eventOid: null,
        }]"
        @delete="handleImageDelete"
        @uploadStart="isUploadingCompanyLogo = true"
        @uploadEnd="isUploadingCompanyLogo = false"
        @upload="handleImageUpload"
        @error="handleImageUploadError"
      />
      <ar-skeleton
        v-if="isUploadingCompanyLogo"
        :style="{
          width: '100px',
          height: '100px',
          borderRadius: '5px',
        }"
      />
      <ar-framed-image
        v-else-if="privacyPortalImageUrl"
        :src="privacyPortalImageUrl"
        :style="{
          width: '100px',
          height: '100px',
        }"
      />
      <div class="u-display-flex u-margin-top-4">
        <ar-icon
          v-if="!privacyPortalImageUrl"
          class="u-margin-right-2"
          name="image"
          height="21px"
          :color="$arStyle.color.blueGrey600"
        />
        <ar-link-button
          v-if="!privacyPortalImageUrl"
          :disabled="isUploadingCompanyLogo"
          text="Upload image"
          @click="handleUploadImageClick"
        />
        <ar-link-button
          v-if="privacyPortalImageUrl"
          :disabled="isUploadingCompanyLogo"
          text="Upload new image"
          @click="handleUploadImageClick"
        />
      </div>
    </ar-field>
    <ar-field
      class="u-margin-top-7"
      label="Colors"
      description="Use your own brand colors"
    >
      <am2-color-input
        :value="scratchPrivacyPortal.meta.presentation.color"
        @input="handleColorInput"
      />
    </ar-field>
    <ar-field
      class="u-margin-top-7"
      label="Your Privacy Policy (optional)"
      description="Add your privacy policy link to the privacy portal"
      :error-messages="[veeErrors.first('privacyPolicy')]"
    >
      <ar-input
        class="u-width-100-percent"
        placeholder="https://"
        :value="scratchPrivacyPortal.meta.privacyPolicy"
        @input="handlePrivacyPolicyInput"
        v-validate="{
          url: { require_protocol: true },
        }"
        autocomplete="url"
        data-vv-name="privacyPolicy"
        data-vv-as="privacyPolicy"
      />
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      privacyPortalDomain: process.env.arPrivacyPortalDomain,
      isUploadingCompanyLogo: false,
      imageUploadErrorMsg: null,
    };
  },
  computed: {
    ...mapState({
      currentPrivacyPortal: state => state.privacyPortal.currentPrivacyPortal,
      scratchPrivacyPortal: state => state.privacyPortal.scratchPrivacyPortal,
    }),
    ...mapGetters({
      getPrivacyPortalImageUrl: 'privacyPortal/getPrivacyPortalImageUrl',
    }),
    privacyPortalImageUrl() {
      return this.getPrivacyPortalImageUrl(this.scratchPrivacyPortal);
    },
    currentPrivacyPortalSubdomain() {
      return this.currentPrivacyPortal?.subdomain;
    },
  },
  methods: {
    ...mapMutations([
      'privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY',
    ]),
    handleImageDelete() {
      const newResources = this.scratchPrivacyPortal.resources.filter(
        resource => resource.assetType !== 'privacy-portal-image'
      );
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({ resources: newResources });
    },
    handleImageUpload({ arS3BucketResources }) {
      this.handleImageDelete();
      const newResources = this.scratchPrivacyPortal.resources.concat([arS3BucketResources[0]]);
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({ resources: newResources });
    },
    handleImageUploadError(errorMsg) {
      this.imageUploadErrorMsg = errorMsg;
    },
    handleUploadImageClick() {
      this.imageUploadErrorMsg = null;
      this.$refs['img-dropzone'].selectFile();
    },
    handleCompanyNameInput(companyName) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        companyName,
      });
    },
    handleSubdomainInput(subdomain) {
      let newSubdomain = subdomain;
      newSubdomain = newSubdomain.toLowerCase().replace(/[^\da-z-]/gi, '-');
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        subdomain: newSubdomain,
      });
    },
    handleColorInput(color) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          presentation: { color },
        },
      });
    },
    handlePrivacyPolicyInput(privacyPolicy) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: { privacyPolicy },
      });
    },
  },
};
</script>
