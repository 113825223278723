var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"event-rsvp-form-modify-basic"},[(_vm.hasHeading)?_c('div',{staticClass:"u-margin-bottom-12"},[_c('ar-text',{style:({
        color: '#9FA8B5',
      }),attrs:{"size":"xxs","text":("STEP 1 OF " + _vm.totalStep),"weight":"bold"}}),_vm._v(" "),_c('am2-heading',{staticClass:"u-margin-top-2",attrs:{"type":"h1","size":"lg","title":"Create RSVP Page","multiple-lines":""}})],1):_vm._e(),_vm._v(" "),_c('ar-field',{attrs:{"label":"Event description"}},[_c('ar-textarea',{attrs:{"value":_vm.scratchEventRSVPForm && _vm.scratchEventRSVPForm.presentation ? _vm.scratchEventRSVPForm.presentation.description : null,"rows":4,"data-test-id":"event-rsvp-description"},on:{"input":_vm.handleDescriptionInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Public Event URL","error-messages":[_vm.veeErrors.first('url-slug')]}},[_c('am2-new-slug-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: true,
        arCampaignSlug: {
          currentSlug: this.currentUrlSlug,
          prefix: '/r/',
        },
      }),expression:"{\n        required: true,\n        arCampaignSlug: {\n          currentSlug: this.currentUrlSlug,\n          prefix: '/r/',\n        },\n      }"}],staticClass:"u-margin-top-4",attrs:{"data-vv-name":"url-slug","data-vv-as":"Public Event URL","data-vv-validate-on":"input","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"value":_vm.scratchEventRSVPForm && _vm.scratchEventRSVPForm.urlSlug,"prefix":(_vm.c2ClientDomain + "/r/"),"enableCopy":_vm.isEditEventRSVPForm,"data-test-id":"event-rsvp-url-slug"},on:{"input":_vm.handleUrlSlugInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Custom color","error-messages":[_vm.veeErrors.first('customColor')]}},[_c('am2-color-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchEventRSVPForm && _vm.scratchEventRSVPForm.presentation ? _vm.scratchEventRSVPForm.presentation.color : '#7344c0',"data-vv-name":"customColor","data-vv-as":"customColor"},on:{"input":_vm.handleCustomColorInput}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }