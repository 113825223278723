var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-select"},[_c('ar-select',{ref:"select",style:({
      height: '100%',
    }),attrs:{"items":_vm.selectItems,"enable-filter":"","filter-placeholder":_vm.filterPlaceholder,"data-test-id":"brand-select-dropdown","layer":"global-regular","default-filter-string":_vm.brandName,"filter":_vm.brandFilter},on:{"select":_vm.handleSelect,"actionClick":_vm.handleActionClick,"close":function () { _vm.$emit('close') },"filterStringInput":_vm.handleFilterStringInput},scopedSlots:_vm._u([{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{staticClass:"brand-action-item",style:({
          background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
        }),attrs:{"data-test-id":("brand-action-item-" + (itemScope.item.key))}},[_c('ar-icon',{staticClass:"plus-icon",attrs:{"name":"circle-plus","color":_vm.$arStyle.color.purple500,"height":"16px","width":"16px"}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.purple500,
            marginLeft: '8px',
          }),attrs:{"size":"xs","text":itemScope.item.name,"weight":"bold"}})],1):_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          borderBottom: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
          background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
        }),attrs:{"data-test-id":("brand-item-" + (itemScope.item.key))}},[_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
            flexGrow: '1',
            overflow: 'hidden',
            justifyContent: 'flex-start',
          })},[_c('ar-text',{style:({
              width: 'auto',
              color: itemScope.active ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.blueGrey800,
            }),attrs:{"size":"xs","text":itemScope.item.name,"weight":"normal"}})],1),_vm._v(" "),(itemScope.item.key != 'new_brand')?_c('ar-link-button',{attrs:{"text":"save","data-test-id":("brand-select-" + (itemScope.item.key) + "-save")},on:{"click":function($event){return _vm.saveButtonPressed(itemScope.item)}}}):_vm._e(),_vm._v(" "),(itemScope.item.key != 'new_brand')?_c('ar-link-button',{staticClass:"u-margin-left-2",attrs:{"text":"delete","data-test-id":("brand-select-" + (itemScope.item.key) + "-delete")},on:{"click":function($event){return _vm.deleteButtonPressed(itemScope.item)}}}):_vm._e()],1)]}}])},[_c('template',{slot:"selected-item"},[_c('div',{style:({
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }),attrs:{"data-test-id":"brand-select-out-of-scope-selected-item"}},[_c('ar-text',{style:({
            color: _vm.brandName ? _vm.$arStyle.color.blueGrey800 : _vm.$arStyle.color.blueGrey600,
          }),attrs:{"size":"xs","text":_vm.brandName || 'Enter brand name...'}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }