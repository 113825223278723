<template>
  <section class="external-container">
    <div v-if="!error" class="message-preview-container">
      <am2-elegant-tabs
        class="tabs"
        :items="modeTabs"
        :tab-key="mode"
        @select="handleModeSelect"
      />
        <div
          v-if="mode === 'mobile'"
          class="mobile-preview-section"
        >
          <am2-mobile-container
            :has-footer="false"
            size="fullHeight"
          >
            <am2-email-preview
              opened
              :email-template="emailWithInfo"
              :subject="subject"
              :preview-text="previewText"
              :sender-name="senderName"
            />
          </am2-mobile-container>
        </div>
        <div
          v-if="mode === 'desktop'"
          class="desktop-preview-section"
        >
          <am2-email-preview
            :mobile="false"
            opened
            :email-template="emailWithInfo"
            :subject="subject"
            :preview-text="previewText"
            :sender-name="senderName"
          />
        </div>
    </div>
    <div v-else class="center-text-cointainer">
      <p>Preview Error: {{error}}</p>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'MessagePreview',
  layout: 'mono-no-auth',

  data() {
    return {
      mode: 'desktop',
      modeTabs: [
        {
          name: 'Desktop',
          key: 'desktop',
        },
        {
          name: 'Mobile',
          key: 'mobile',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      hash: state => state.route.params.hash,
      message: state => state.messagePreview.message,
      emailHtml: state => state.messagePreview.emailHtml,
      error: state => state.messagePreview.error,
    }),

    ...mapGetters({
      injectInfoToPreviewEmail: 'messagePreview/injectInfoToPreviewEmail',
    }),

    emailWithInfo() {
      return this.injectInfoToPreviewEmail();
    },

    subject() {
      return this.message?.messageBody?.subject || '';
    },

    previewText() {
      return this.message?.messageBody?.previewText || '';
    },

    senderName() {
      return this.message?.senderName || '';
    },
  },

  watch: {
    message() {
      this['messagePreview/GENERATE_EMAIL_HTML']();
    },
  },

  methods: {
    ...mapActions([
      'messagePreview/FETCH_MESSAGE',
      'messagePreview/GENERATE_EMAIL_HTML',
    ]),

    async loadMessage() {
      await this['messagePreview/FETCH_MESSAGE'](this.hash);
    },

    handleModeSelect(tabItem) {
      this.mode = tabItem.key;
    },
  },

  async mounted() {
    this.loadMessage();
  },
}
</script>

<style lang="scss" scoped>
  .tabs {
    width: 200px;
    margin: 0 auto;
  }

  .external-container {
    height: 100vh;
    background: #FFF;
  }

  .message-preview-container {
    position: relative;
    height: 100%;
    background: #FFF;
  }

  .mobile-preview-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    height: calc(100% - 30px);
  }

  .desktop-preview-section {
    padding: 12px 0 0;
    height: calc(100% - 30px);
  }

  .center-text-cointainer {
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
</style>