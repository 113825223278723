var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-card"},[_c('div',{class:['index-card--wrapper', _vm.showAs, _vm.cardSize]},[_c('nuxt-link',{staticClass:"card-link",attrs:{"to":{ path: ("/tours/" + (_vm.tour.oid) + "/view/campaigns") },"data-test-id":("tour-card-" + _vm.tourNameInKebabCase)}},[(_vm.showAs === 'list')?_c('article',{staticClass:"card-list-item",class:[
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max'
        ]},[_c('div',{staticClass:"card-image"},[_c('div',{staticClass:"tour-list-image-wrapper"},[[(_vm.cardImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.cardImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-tour-button-overlay"},[_c('button',{staticClass:"view-tour-button"},[_vm._v("View")])]),_vm._v(" "),(_vm.cardImage)?_c('img',{staticClass:"tour-image",attrs:{"src":_vm.cardImage}}):_c('div',{staticClass:"tour-tile-image-default"})],2)]),_vm._v(" "),_c('div',{class:[
            'card-content',
            _vm.cardSize ]},[_c('header',{staticClass:"card-detail"},[_c('ar-text',{staticClass:"tour-list-heading",attrs:{"size":"17px","text":_vm.tour.name,"weight":"bold","multiple-lines":"","max-lines":1,"line-height":"25px"}}),_vm._v(" "),_c('ar-text',{staticClass:"card-list-copy",attrs:{"size":"15px","text":_vm.campaignCopy,"multiple-lines":"","max-lines":1}})],1),_vm._v(" "),_c('div',{class:[
            'card-footer',
            _vm.cardSize ]},[_c('div',{staticClass:"registrations-and-views"},[_c('div',{staticClass:"registrations"},[_c('ar-icon',{attrs:{"name":"user","height":"13px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.totalRegistrations)+" Registrations")])],1),_vm._v(" "),_c('div',{staticClass:"views"},[_c('ar-icon',{attrs:{"name":"preview","width":"17px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.uniqueViews)+" Views")])],1)])])])]):_c('article',{staticClass:"card-tile"},[_c('div',{staticClass:"card-image"},[_c('div',{staticClass:"tour-list-image-wrapper"},[[(_vm.cardImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.cardImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-tour-button-overlay"},[_c('button',{staticClass:"view-tour-button"},[_vm._v("View")])]),_vm._v(" "),(_vm.cardImage)?_c('img',{staticClass:"tour-image",attrs:{"src":_vm.cardImage}}):_c('div',{staticClass:"tour-tile-image-default"})],2)]),_vm._v(" "),_c('div',{class:[
            'card-content',
            _vm.cardSize ]},[_c('header',{staticClass:"card-detail"},[_c('div',{staticClass:"heading-and-tag"},[_c('ar-text',{staticClass:"tour-card-heading",attrs:{"size":"md","text":_vm.tour.name,"multiple-lines":"","max-lines":1,"line-height":"25px"}})],1),_vm._v(" "),_c('ar-text',{staticClass:"card-list-copy",attrs:{"size":"sm","text":_vm.campaignCopy,"multiple-lines":"","max-lines":1}})],1),_vm._v(" "),_c('div',{class:[
            'card-footer',
            _vm.cardSize ]},[_c('div',{staticClass:"registrations-and-views"},[_c('div',{staticClass:"registrations"},[_c('ar-icon',{attrs:{"name":"user","height":"13px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.totalRegistrations)+" "+_vm._s(_vm.cardSize !== 'xs' ? 'Registrations' : null))])],1),_vm._v(" "),_c('div',{staticClass:"views"},[_c('ar-icon',{attrs:{"name":"preview","width":"17px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.uniqueViews)+" "+_vm._s(_vm.cardSize !== 'xs' ? 'Views' : null))])],1)])])])])]),_vm._v(" "),_c('am2-tour-dropdown',{staticClass:"tour-dropdown",style:({
        top: _vm.showAs === 'tiles' ? '13px' : '17px',
        right: _vm.showAs === 'tiles' ? '13px' : '17px',
      }),attrs:{"menu-icon-color":_vm.showAs === 'tiles' ? 'white' : null,"kind":"menu","menu-style":"secondary","tour":_vm.tour},on:{"delete":_vm.handleTourDelete}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }