<template>
  <section class="onboarding-frame">
    <div
      :class="[
        'content-section',
        $arMediaQuery.window.maxWidth('sm') && 'full-width',
      ]">
      <div class="right-upper-section">
        <am2-step-link
          v-if="hasGoBackLink"
          text="Go back"
          @click="handleGoBackLinkClick"
        />
        <am2-simple-stepper
          v-if="totalStep"
          :steps-count="totalStep"
          :current-step="currentStep"
          has-divider
          :style="{
            width: '120px',
            height: '5px',
            borderRadius: '2.5px',
          }"
        />
        <slot name="right-upper-section" />
      </div>
      <slot />
    </div>
    <div v-if="hasNextStepPannel" class="step-control-section">
      <div class="step-control-content">
        <ar-link-button
          text="skip this step"
          :has-underline="false"
          :style="{
            marginRight: '20px',
          }"
          @click="handleNextStepClick"
        />
        <ar-simple-button
          type="gradient-purple"
          shape="pill"
          text="Next"
          :style="{
            width: '120px',
          }"
          @click="handleNextStepClick"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AuthenticateFrame',

  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    totalStep: {
      type: Number,
      default: null,
    },
    hasNextStepPannel: {
      type: Boolean,
      default: false,
    },
    hasGoBackLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleGoBackLinkClick() {
      this.$emit('previousStep');
    },
    handleNextStepClick() {
      this.$emit('nextStep');
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-frame {
  position: relative;
  min-height: 100vh;
  background: white;
  padding: 50px 0 50px;

  .content-section {
    position: relative;
    margin: 0 auto;
    padding: 80px 0 0;
    width: calc(100% - 80px);
    max-width: 500px;

    &.full-width {
      margin: 60px auto 0;
      width: calc(100% - 80px);
    }

    .right-upper-section {
      position: absolute;
      top: 0;
    }
  }

  .step-control-section {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 90px;
    width: 100%;
    border-top: 1px solid $blueGrey500;
    padding: 0 20px;

    .step-control-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      margin: 0 auto;
      width: 100%;
    }
  }
}
</style>
