<template>
  <section class="event-rsvp-page">
    <div
      :class="[
        'header-section',
        displayMobilePreview && 'now-visible',
      ]"
    >
      <am2-top-create
        type="white"
        :total-steps="totalStep"
        :current-step="currentStep"
        :exit-path="(!this.currentEvent || this.currentEvent.oid == null) ? `/events/new` : `/events/${this.currentEvent.oid}/edit`"
        title="Create RSVP Page"
        :subtitle="scratchEvent.name"
        :style="{
          position: 'relative',
        }"
      />
    </div>
    <div class="body-section">
      <div
        :class="[
          'edit-section',
          hasNoSpaceForRightPreview && 'full-size',
          $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        ]"
      >
        <div
          :class="[
            'edit-top-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
          ref="content"
        >
          <div class="page-content">
            <nuxt-child
              :total-step="totalStep"
              has-heading
              ref="modify-eventRSVP-form-page"
            />
          </div>
        </div>

        <div
          :class="[
            'edit-navigation-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
        >
          <ar-simple-button
            v-if="currentStep === 1"
            text="Cancel"
            outlined
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }"
            @click="handleCancelClick"
            data-test-id="cancel-button"
          />
          <ar-simple-button
            v-else-if="currentStep > 1"
            text="Back"
            outlined
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }"
            @click="handlePreviousStepClick"
            data-test-id="back-button"
          />

          <div class="u-display-flex">
            <ar-simple-button
              v-if="hasNoSpaceForRightPreview"
              :text="rightPreviewIsToggledUp ? 'Edit' : 'Preview'"
              class="u-margin-right-3"
              @click="handlePreviewClick"
              outlined
              :icon-name="(rightPreviewIsToggledUp || $arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              :style="{
                width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px',
              }"
            />
            <ar-simple-button
              v-if="currentStep < totalStep"
              text="Next"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleNextStepClick,
              }"
              @click="handleNextStepClick"
              data-test-id="next-button"
            />
            <ar-simple-button
              v-else-if="currentStep === totalStep"
              :text="'Finish'"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }"
              :loading="isPatchingEventRSVPForm || isCreatingEventRSVPForm"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleSaveOptInFormClick,
              }"
              @click="handleSaveOptInFormClick"
              data-test-id="finish-button"
            />
          </div>
        </div>
      </div>
      <div :class="[
        'right-preview-section',
        hasNoSpaceForRightPreview && 'go-on-top-of-edit-section',
        $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        displayMobilePreview && 'is-visible'
      ]">
        <am2-campaign-preview
          ref="campaign-preview"
          :has-account="false"
          :has-fan="false"
          :has-footer="!hasNoSpaceForRightPreview"
          :preview-page="previewPath"
          size="fullHeight"
          :campaign="prunedScratchEventRSVPFormForPreview"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { blob2File } from '@/utils/html-element/';

export default {
  name: 'Event',

  layout: 'edit',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['events'], featureName: 'Events' }),
  ],

  data() {
    return {
      hasBeenUpdated: false,
      rightPreviewIsToggledUp: false,
      windowHeight: window.innerHeight,
    };
  },

  async created() {
    if (this.$route.params.oid) {
      this.resetEditingData();

      const succeed = await this['event/FETCH_CURRENT_EVENT'](this.$route.params.oid);
      if (succeed) {
        this['event/SET_SCRATCH_EVENT'](this.currentEvent);
        await this['eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM'](this.scratchEvent);
        if (this.scratchEventImageUrl) {
          // If you have image in event, we will download it and re-upload it to RSVP event.
          const eventImageFile = await this.downloadEventImage(this.scratchEventImageUrl);
          await this['eventRSVPForm/UPLOAD_IMAGE_FOR_SCRATCH_EVENT_RSVP_FORM'](eventImageFile);
        }
      }
    }

    this.$nextTick(() => {
      if (this.$refs['campaign-preview']) {
        this.$refs['campaign-preview'].setupInitialPreviewData();
      }
    });

  },

  computed: {
    ...mapState({
      step: (state) => state.route.path.substring(state.route.path.lastIndexOf('/') + 1),
      eventRSVPFormOid: (state) => state.route.params.eventRSVPFormOid,
      currentEventRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      isFetchingEventRSVPForm: state => state.eventRSVPForm.isFetchingEventRSVPForm,
      isPatchingEventRSVPForm: state => state.eventRSVPForm.isPatchingEventRSVPForm,
      isCreatingEventRSVPForm: state => state.eventRSVPForm.isCreatingEventRSVPForm,
      isCreatingScratchEventRSVPForm: state => state.eventRSVPForm.isCreatingScratchEventRSVPForm,
      currentEvent: state => state.event.currentEvent,
      scratchEvent: state => state.event.scratchEvent,
      connectZoomProviderInfo: state => state.event.connectZoomProviderInfo,
      isNewEvent: state => state.eventRSVPForm.isNewEvent,
    }),

    ...mapGetters({
      prunedScratchEventRSVPFormForPreview: 'eventRSVPForm/prunedScratchEventRSVPFormForPreview',
      prunedScratchEventRSVPFormForServer: 'eventRSVPForm/prunedScratchEventRSVPFormForServer',
      scratchEventImageUrl: 'event/scratchEventImageUrl',
      scratchEventIsPhysicalEvent: 'event/scratchEventIsPhysicalEvent',
      scratchEventIsZoomEvent: 'event/scratchEventIsZoomEvent',
    }),

    hasPlatformStep() {
      return !this.scratchEventIsPhysicalEvent && !this.scratchEventIsZoomEvent;
    },

    steps() {
      return this.hasPlatformStep ? [
        'basic',
        'register',
        'platform'
      ] : [ 'basic', 'register',];
    },

    previewPath() {
      if (this.steps[this.currentStepIndex] === 'register') {
        return '/register';
      }
      return '/';
    },

    totalStep() {
      return this.steps.length;
    },

    hasNoSpaceForRightPreview() {
      return this.windowHeight < 600 || this.$arMediaQuery.window.maxWidth('md');
    },

    displayMobilePreview() {
      return this.hasNoSpaceForRightPreview && this.rightPreviewIsToggledUp;
    },

    currentStepIndex() {
      var cur = this.steps.findIndex(step => step === this.step);
      return cur;
    },

    currentStep() {
      return this.currentStepIndex + 1;
    },
  },
  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'eventRSVPForm/CREATE_EVENT_RSVP_FORM',
      'eventRSVPForm/PATCH_EVENT_RSVP_FORM',
      'eventRSVPForm/UPLOAD_IMAGE_FOR_SCRATCH_EVENT_RSVP_FORM',
      'event/FETCH_CURRENT_EVENT',
      'event/UPDATE_EVENT',
    ]),
    ...mapMutations([
      'event/SET_SCRATCH_EVENT',
      'event/INJECT_CONNECT_ZOOM_PROVIDER_INFO_TO_SCRATCH_EVENT',
      'event/RESET_SCRATCH_EVENT',
      'event/RESET_CURRENT_EVENT',
      'event/RESET_CONNECT_ZOOM_PROVIDER_INFO',
      'eventRSVPForm/RESET_SCRATCH_EVENT_RSVP_FORM',
      'eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM',
      'event/PATCH_SCRATCH_EVENT',
    ]),
    goToPreviousStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex - 1]}`,
      });
    },
    goToNextStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex + 1]}`,
      });
    },
    async downloadEventImage(eventImageUrl) {
      const { data } = await this.$axios.mono.get(eventImageUrl, {
        responseType: 'blob',
      });
      return blob2File(data, 'campaign-image');
    },
    handleCancelClick() {
      if (this.currentEvent.oid == null) {
        this.$router.push({
          path: `/events`,
        });
      } else {
        this.$router.push({
          path: `/events/${this.currentEvent.oid}/edit/`,
        });
      }
    },
    handlePreviousStepClick() {
      this.goToPreviousStep();

      setTimeout(() => {
        this.$refs['content'].scrollTop = 0;
      }, 100)
    },
    handlePreviewClick() {
      this.rightPreviewIsToggledUp = !this.rightPreviewIsToggledUp;
    },
    async handleNextStepClick() {
      const valid = await this.validateInputs();
      if (valid) {
        this.goToNextStep();

        setTimeout(() => {
          this.$refs['content'].scrollTop = 0;
        }, 100)
      }
    },
    async handleSaveOptInFormClick() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      this['eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM'](this.scratchEvent);
      await this['eventRSVPForm/CREATE_EVENT_RSVP_FORM'](this.prunedScratchEventRSVPFormForServer);

      // Don't forget to also update event, it might be updated according to rsvp page changes
      // Okay, client also asks to connect the event to Zoom
      if (this.connectZoomProviderInfo) {
        this['event/INJECT_CONNECT_ZOOM_PROVIDER_INFO_TO_SCRATCH_EVENT'](this.connectZoomProviderInfo);
      }
      const succeed = await this['event/UPDATE_EVENT'](this.scratchEvent);
      if (!succeed) {
        return;
      }

      this.$router.push({
        path: `/events/${this.scratchEventRSVPForm.eventOid}/view/attendees`,
      });
    },
    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },
    async validateInputs() {
      if (!this.$refs['modify-eventRSVP-form-page']) { return; }
      await this.$refs['modify-eventRSVP-form-page'].$validator.reset();
      const valid = await this.$refs['modify-eventRSVP-form-page'].$validator.validateAll();
      if (!valid) {
        this.$arNotification.push({ type: 'error', message: 'Could not save details, please review error messages' });
      }
      return valid;
    },
    resetEditingData() {
      this['eventRSVPForm/RESET_SCRATCH_EVENT_RSVP_FORM']();
      this['event/RESET_CURRENT_EVENT']();
      this['event/RESET_SCRATCH_EVENT']();
      this['event/RESET_CONNECT_ZOOM_PROVIDER_INFO']();
    },
  },

  beforeDestroy() {
    this.resetEditingData();
  },

}
</script>



<style scoped lang="scss">
.event-rsvp-page {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .header-section {
    flex-shrink: 0;

    &.not-visible {
      display: none;
    }
  }
  .body-section {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    > .edit-section {
      position: relative;
      z-index: $zIndexRegular;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 700px;
      max-width: 768px;
      background: #fff;
      overflow: hidden;

      &.full-size {
        min-width: unset;
        max-width: unset;
      }

      > .edit-top-section {
        flex-grow: 1;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 50px 80px;

        &.small-window-size {
          padding: 50px 20px 80px;
        }

        .page-content {
          max-width: 760px;
          margin: 0 auto;
        }
      }

      .edit-navigation-section {
        flex-shrink: 0;
        height: 110px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 80px;

        &.small-window-size {
          padding: 0 12px;
          height: 75px;
        }
      }
    }
    .right-preview-section {
      display: flex;
      align-items: center;
      height: 100%;
      max-height: 780px;
      padding: 45px;

      &.go-on-top-of-edit-section {
        justify-content: center;
        position: fixed;
        padding: 12px;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 110px);
        background-color:#FFF;
        z-index: $zIndexLow;
        &.small-window-size {
          height: calc(100vh - 75px);
        }
        &.is-visible {
          z-index: $zIndexHigh;
        }
      }
    }
  }
}
</style>
