<template>
  <ar-modal
    :is-open="isSaveEventModalVisible"
    class="save-event-modal"
    header="Save event"
    hide-footer
    width="600px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleClose"
  >
    <div slot="body" class="wrapper">
      <ar-text
        size="xs"
        :text="reasonHtmlCopy"
        multiple-lines
        allow-html
      />
      <div
        :style="{
          marginTop: '15px',
        }"
      >
        <ar-checkbox
          v-model="dontShowAgain"
          label="Don't show again"
        />
      </div>
      <ar-simple-button
        text="Done"
        @click="handleDone"
        :style="{ width: '100%', marginTop: '42px' }"
      />
      <div v-if="eventbriteUrl" class="link-container">
        <ar-link-button
          text="Open Eventbrite event"
          @click="handleOpenEventbriteEventClick"
        />
      </div>
    </div>


  </ar-modal>
</template>

<script>
export default {
  name: 'SaveEventModal',
  props: {
    isSaveEventModalVisible: {
      type: Boolean,
      default: false,
    },
    eventbriteUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      reasonHtmlCopy: 'Event detail updates that you have made on Audience Republic <u><b>will not</b></u> update the event details on Eventbrite. Eventbrite will need to be updated separately.',
      dontShowAgain: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleDone() {
      this.$emit('confirmSave', this.dontShowAgain);
    },
    handleOpenEventbriteEventClick() {
      window.open(this.eventbriteUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.save-event-modal {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    .link-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 24px;
      padding-bottom: 10px;
    }
  }
}
</style>
