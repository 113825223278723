var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMorePurchases),expression:"loadMorePurchases"}],staticClass:"customer-profile-purchases",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"20"}},[_c('am2-purchase-details-modal',{attrs:{"is-show":_vm.modalIsOpen,"purchase-data":_vm.modalData},on:{"close":_vm.handleClosePurchaseModal}}),_vm._v(" "),(_vm.computedPurchases.length > 0 || _vm.searchQuery || _vm.customerState.isFetchingPurchases)?_c('am2-purchases-control-section',{attrs:{"search-text":_vm.searchQueryModel},on:{"searchTextChange":_vm.handleSearchQueryModelUpdate,"searchAction":function (query) { return _vm.handleSearch(query); }}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
      show: !_vm.isCustomerProfileFeatureEnabled,
      showUpgradeOn: 'hover',
      iconName: 'audience',
      title: _vm.lockedTitle.purchases,
      message: 'Unlock customer profiles to get access to purchase history of your fans.',
    }),expression:"{\n      show: !isCustomerProfileFeatureEnabled,\n      showUpgradeOn: 'hover',\n      iconName: 'audience',\n      title: lockedTitle.purchases,\n      message: 'Unlock customer profiles to get access to purchase history of your fans.',\n    }"}],style:({
      borderRadius: !_vm.isCustomerProfileFeatureEnabled ? '5px' : null,
      overflow: !_vm.isCustomerProfileFeatureEnabled ? 'hidden' : null,
    })},[(_vm.computedPurchases.length > 0 || _vm.searchQuery || _vm.customerState.isFetchingPurchases)?_c('am2-purchases-table',{attrs:{"head":_vm.headings,"body":_vm.computedPurchases,"loading":_vm.customerState.isFetchingPurchases && _vm.computedPurchases.length === 0,"order-by":_vm.orderBy,"locked":!_vm.isCustomerProfileFeatureEnabled},on:{"sortBy":_vm.handleSortChange,"openPurchaseModal":_vm.handleOpenPurchaseModal}}):_vm._e()],1),_vm._v(" "),(_vm.computedPurchases.length === 0 && !_vm.customerState.isFetchingPurchases && !_vm.searchQuery)?_c('am2-no-content-section',{class:['ar-transition-group-item', 'u-width-100-percent'],attrs:{"header":_vm.customerState.customer
        ? ((_vm.customerState.customer.name) + " has not made any purchases")
        : 'Customer has not made any purchases',"header-size":"sm"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }