<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Edit Email Template"
      width="100%"
      height="100%"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="email-editor-modal"
    >
      <div slot="body" class="body">
        <ar-link-button
          data-test-id="automation-email-select-template-link"
          class="select-template-link"
          text="Select Template"
          :color="$arStyle.color.$purple500"   
          :textProps="{
            size: 'sm',
          }"
          :disabled="showSelectEmailTemplateSection"
          @click="handleSelectTemplateSelector"
        />
        <EmailEditor
          ref="email-editor"
          id="automation-email-editor"
          class="email-editor"
          :type="templateType"
          :show-dynamic-tags-tool="false"
          @templateUpdate="handleDesignOutput"
          @emailHtmlUpdate="handleHtmlOutput"
        />

        <am2-select-email-template-section
          v-show="showSelectEmailTemplateSection"
          id="automation-email-template-section"
          @select="(template, templateType, emailHtml) => handleTemplateSelect(template, templateType, emailHtml)"
        />

      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Cancel"
          outlined
          :style="{
            width: '140px',
          }"
          data-test-id="automation-email-footer-cancel-button"
          @click="handleCancelClick"
        />
        <ar-simple-button
          text="Done"
          :style="{
            width: '140px',
          }"
          data-test-id="automation-email-footer-done-button"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import EmailEditor from '@/components/editors/email-editor';
export default {
  name: 'EmailEditorModal',
  components: {
    EmailEditor,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    templateType: {
      type: String,
      default: 'unlayer',
      validator: (val) => ['unlayer', 'rich-text', 'custom'].indexOf(val) > -1,
    },
    defaultTemplate: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      template: null,
      emailHtml: null,
      showSelectEmailTemplateSection: false,
    };
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    },
  },

  methods: {
    handleTemplateSelect(template, templateType, emailHtml) {
      this.$refs['email-editor'].setDesign(template)
      this.$emit('select', template, templateType, emailHtml)
      this.showSelectEmailTemplateSection = false
    },
    async initEmailEditor() {
      await this.$nextTick();
      if (this.defaultTemplate) {
        this.$refs['email-editor'].setDesign(this.defaultTemplate);
      }
    },
    handleSelectTemplateSelector() {
      this.showSelectEmailTemplateSection = true
    },
    handleDesignOutput(template) {
      this.template = template;
    },
    handleHtmlOutput(emailHtml) {
      this.emailHtml = emailHtml;
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    handleDoneClick() {
      this.$emit('update', this.template, this.emailHtml);
    },
    handleModalClose() {
      if (this.showSelectEmailTemplateSection) {
        this.showSelectEmailTemplateSection = false
        return
      }
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-template-link {
  padding: 12px;
  position: fixed;
  top: 19px;
  right: 82px;
  z-index: 999;
  cursor: pointer;
}

.email-editor-modal {
  .body {
    width: 100%;
    height: 100%;
    .email-editor {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 23px 29px;
  }
}
</style>
