<template>
  <div class="purchases-table-wrapper">
    <am2-table
      ref="purchases-table"
      :heads="decoratedHead"
      :rows="body"
      :loading="loading"
      @sortBy="handleSortByChange"
      class="purchases-table"
      empty-text="No purchase data available"
      enable-row-click
      :default-sort-by="orderBy"
      :locked="locked"
      @rowClick="handlePurchaseRowClick"
    >
      <div
        slot="description"
        slot-scope="{ data }"
        class="cell cell-left"
      >
        <ar-text
          size="xs"
          :text="getDescription(data)"
        />
      </div>
      <div
        slot="source"
        slot-scope="{ data }"
        class="cell cell-left"
      >
        <section
          :style="{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }">
          <ar-icon
            class="avatar"
            type="primary"
            :name="data.source.icon"
            :color="data.source.iconColor"
            width="20px"
            height="20px"
          />
          <div :style="{marginLeft: '12px'}">
            <ar-text
              class="name-text"
              size="xs"
              multiple-lines
              :text="data.source.platform"
            />
            <ar-text
              class="name-text"
              size="xxxs"
              multiple-lines
              :text="data.source.description"
              :style="{
                color: $arStyle.color.skyBlueGrey700,
                paddingTop: '2px',
              }"
            />
          </div>
        </section>
      </div>


      <div
        slot="quantity"
        slot-scope="{ data }"
        class="cell"
      >
        <ar-text
          size="xs"
          :text="data.quantity"
        />
      </div>

      <div
        slot="totalPrice"
        slot-scope="{ data }"
        class="cell"
      >
        <ar-text
          size="xs"
          :text="getTotalPrice(data)"
          :style="{
            textDecoration: data.status === 'Cancelled' ? 'line-through' : null,
          }"
        />
      </div>

      <div
        slot="orderId"
        slot-scope="{ data }"
        class="cell"
      >
        <ar-text
          size="xs"
          :text="data.orderId"
        />
      </div>

      <div
        slot="status"
        slot-scope="{ data }"
        class="cell status-cell"
      >
        <am2-tag
          :type="getTagColor(data.status)"
          :text="data.status"
          :style="{
            textTransform: 'capitalize',
            border: '1px solid transparent',
          }"
        />
      </div>

      <div
        slot="purchaseDateTime"
        slot-scope="{ data }"
        class="cell"
      >
        <ar-text
          size="xs"
          :text="timeAgo(data.purchaseDateTime)"
          v-tooltip.top="{
            content: prettyTime(data.purchaseDateTime)
          }"
        />
      </div>

    </am2-table>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import moment from 'moment';
  import { formatCurrency } from '@/utils/helpers'

  export default {
    name: 'PurchasesTable',

    props: {
      head: {
        type: Array,
        default: () => [],
      },
      body: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      locked: {
        type: Boolean,
        default: false,
      },
      orderBy: {
        type: Object,
      }
    },

    computed: {
      decoratedHead() {
        return this.head.map((item, index) => {
          let width;
          if (item.key === 'description') {
            width = 286;
          } else if (item.key === 'source') {
            width = 192;
          } else if (item.key === 'purchaseDateTime') {
            width = 140;
          } else if (item.key === 'totalPrice') {
            width = 100;
          } else if (item.key === 'orderId') {
            width = 128;
          } else if (item.key === 'status') {
            width = 138;
          } else if (item.key === 'items') {
            width = 40;
          } else {
            width = 85;
          }
          return {
            ...item,
            width,
            align: (index == 0 || item.key === 'source') ? 'left' : 'right',
          };
        });
      }
    },

    methods: {
      getDescription(data) {
        return data.lineItems.map((d) => d.ticketType).join(", ");
      },

      getTagColor(status) {
        if (status === 'completed' || status === 'paid') {
          return 'green'
        }
        if (status === 'refunded' || status === 'partial-refund') {
          return 'orange'
        }

        return 'grey';
      },

      getTotalPrice(data) {
        if (!data.lineItems) return `0 (${data.currency})`;

        let totalPrice;
        if (data.status === 'paid' || data.status === 'completed' || data.status === 'partial-refund') {
          totalPrice = data.grossTotal;
        } else if (data.status === 'refunded' || data.status === 'cancelled') {
          totalPrice = data.grossTotal;
        }

        let formattedPrice = this.formatCurrencyLocal(totalPrice / 100);
        if(data.currency) formattedPrice = `${formattedPrice} (${data.currency})`;
        if(data.status === 'refunded' || data.status === 'cancelled') formattedPrice = `-${formattedPrice}`;

        return formattedPrice;
      },


      handleSortByChange(sortBy) {
        this.$emit('sortBy', sortBy);
      },

      handlePurchaseRowClick(item) {
        this.$emit('openPurchaseModal', item);
      },

      formatCurrencyLocal(num) {
        return formatCurrency(num);
      },

      prettyTime(data) {
        if(!data) return '';
        return moment.utc(data).local().format('ddd, MMMM Do YYYY, h:mma');
      },

      timeAgo(data) {
        if(!data) return '';
        return moment.utc(data).local().fromNow();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .purchases-table-wrapper {
    .purchases-table {
      .tr:hover {
        .td {
          .cell {
            .tag {
              border: 1px solid white !important;
            }
          }
        }
      }
      .cell {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px 14px;
        justify-content: flex-end;

        &-left {
          justify-content: flex-start;
        }
      }
      .name-text {
        max-width: 100%;
        overflow: hidden;

        >>> a {
          color: $blueGrey800;
        }
      }
    }
  }
</style>
