const desktopCss = () => `
  .{{rootClassName}} {
    display: table;
    border-spacing: 0;
    padding: 0;
    width: 100%;
  }

  .{{rootClassName}} .ar-image-wrapper {
    display: table-cell;
    width: 153px;
    height: 80px;
    /* Putting line-height: 1px; here can remove ghost padding beneath image in table cell */
    line-height: 1px;
  }

  .{{rootClassName}} .ar-image-wrapper .ar-empty-image {
    width: 100%;
    height: 100%;
    background: #2a2149;
  }

  .{{rootClassName}} .ar-copies-wrapper {
    display: table-cell;
    height: 80px;
    padding-left: 15px;
    vertical-align: middle;
  }

  .{{rootClassName}} .ar-copies-wrapper .ar-event-name {
    font-family: Helvetica;
    letter-spacing: 0;
    line-height: normal;
  }

  .{{rootClassName}} .ar-copies-wrapper .ar-event-location {
    font-family: Helvetica;
    letter-spacing: 0;
    padding-top: 4px;
    line-height: normal;
  }

  .{{rootClassName}} .ar-copies-wrapper .ar-event-date {
    font-family: Helvetica;
    letter-spacing: 0;
    padding-top: 4px;
    line-height: normal;
  }

  .{{rootClassName}} .ar-link-button-wrapper {
    display: table-cell;
    width: 91px;
    height: 80px;
    vertical-align: middle;
  }

  .{{rootClassName}} .ar-link-button-wrapper .ar-link-button {
    width: 100%;
    border: none;
    border-radius: 3px;
    height: 33px;
    font-size: 12px;
  }
`;

const mobileCss = (prefix: string) => `
  ${prefix} .{{rootClassName}} .ar-image-wrapper {
    display: block !important;
    width: 100% !important;
    height: auto !important;
  }

  ${prefix} .{{rootClassName}} .ar-image-wrapper .ar-empty-image {
    padding-top: 52.32%;
  }

  ${prefix} .{{rootClassName}} .ar-image-wrapper .ar-image {
    width: 100% !important;
    height: auto !important;
  }

  ${prefix} .{{rootClassName}} .ar-copies-wrapper {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    padding-left: 0 !important;
    padding-top: 10px;
  }

  ${prefix} .{{rootClassName}} .ar-copies-wrapper .ar-event-name {
    font-weight: bold;
  }

  ${prefix} .{{rootClassName}} .ar-link-button-wrapper {
    display: block !important;
    width: 100% !important;
    height: auto;
    padding-top: 15px;
  }

  ${prefix} .{{rootClassName}} .ar-link-button-wrapper .ar-link-button {
    width: 105px;
  }
`;

export const htmlCssTemplate = `
  ${desktopCss()}

  @media only screen and (max-width: 480px) {
    ${mobileCss('')}
  }

  ${mobileCss('.ar-wrap-content')}
  ${mobileCss('.editor-mobile')}
`;

export const emailHtmlRenderer = `
  function (values) {
    var {
      arEvent,
      wrap,
      showImage,
      showTitle,
      titleTextColor,
      titleFontSize,
      showVenueName,
      venueNameTextColor,
      venueNameFontSize,
      showDate,
      dateTextColor,
      dateFontSize,
      showBuyTicketsButton,
      buyTicketsButtonText,
      buyTicketsButtonTextColor,
      buyTicketsButtonBackgroundColor,
      buyTicketsButtonLink,
      _meta: {
        htmlID,
      },
    } = values;
    var event = arEvent;
    var imageUrl = event ? event.imageUrl || null : '${process.env.arAudienceManagerUrl}/images/unlayer/unlayer-placeholder-image.jpeg';
    var eventName = event ? event.name || 'No event name' : 'Event Name';
    var eventLocation = event ? event.location || 'No venue name' : 'Venue Name';
    var eventDate = event ? event.date || 'No event date' : 'Event Date';
    return \`
      <div class="\${wrap ? 'ar-wrap-content' : ''}">
        <div class="ar-event-card \${htmlID}">
          \${showImage && imageUrl ? \`
            <div class="ar-image-wrapper"">
              <img
                class="ar-image"
                alt="event image"
                src="\${imageUrl}"
                width="auto"
                height="80px"
              />
            </div>
          \` : ''}
          \${showImage && !imageUrl ? \`
            <div class="ar-image-wrapper">
              <div class="ar-empty-image">
              </div>
            </div>
          \` : ''}
          <div class="ar-copies-wrapper" style="padding-left: \${showImage ? '15px' : '0'};">
            \${showTitle ? \`
              <div class="ar-event-name">
                <span style="color: \${titleTextColor}; font-size: \${titleFontSize}px;">\${eventName}</span>
              </div>
            \` : ''}
            \${showVenueName ? \`
              <div class="ar-event-location">
                <span style="color: \${venueNameTextColor}; font-size: \${venueNameFontSize}px;">\${eventLocation}</span>
              </div>
            \` : ''}
            \${showDate ? \`
              <div class="ar-event-date">
                <span style="color: \${dateTextColor}; font-size: \${dateFontSize}px;">\${eventDate}</span>
              </div>
            \` : ''}
          </div>
          \${showBuyTicketsButton ? \`
            <div class="ar-link-button-wrapper">
              <a href="\${buyTicketsButtonLink}" target="_blank">
                <button class="ar-link-button" style="background-color: \${buyTicketsButtonBackgroundColor}; color: \${buyTicketsButtonTextColor};">
                  \${buyTicketsButtonText}
                </button>
              </a>
            </div>
          \` : ''}
        </div>
      </div>
    \`;
  }
`;
