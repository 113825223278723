<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-input-modal"
      :header="title"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-input-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <label class="label">{{ inputTitle }}</label>
        <ar-input
          v-if="isShow"
          v-model="value"
          :placeholder="inputPlaceholder"
          :suffix="inputSuffix"
          :suffix-props="{
            style: {
              paddingRight: '8px',
            }
          }"
          :enable-suffix-background="!!inputSuffix"
          @enter="handleConfirmClick"
        />
      </div>
      <div
        slot="footer"
        :class="[
          'ask-input-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Save"
          :loading="loading"
          :disabled="!value"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    inputTitle: {
      type: String,
      default: null,
      required: true,
    },
    inputSuffix: {
      type: String,
      default: null,
    },
    inputPlaceholder: {
      type: String,
      default: null,
    },
    placeHolder: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputProps: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      value: null,
    };
  },

  computed: {
    decoratedInputProps() {
      return {
        autoFocus: true,
        ...this.inputProps,
      };
    }
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', this.value);
      this.value = null;
    },
    handleCloseClick() {
      this.$emit('close');
      this.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-input-modal {
  .ask-input-modal-body {
    padding: 20px 30px 30px;
    .label {
      display: block;
      font-size: 14px;
      color: $blueGrey800;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 4px;
      font-weight: bold;
    }
  }
  .ask-input-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
