<template>
  <portal
    to="modal"
    :disabled="!displayModal"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      v-if="createdSignupForm"
      :is-open="displayModal"
      class="confirm-signup-form-creation-modal"
      hide-header
      width="815px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        class="body"
      >
        <div
          :class="[
            'success-info',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-2',
          ]"
        >
          <ar-icon
            name="check-alt"
            width="23px"
            :color="$arStyle.color.green500"
            :wrapper-style="{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              background: $arStyle.color.green200,
            }"
          />
          <ar-text
            :class="[
              $arMediaQuery.window.maxWidth('xs') ? 'u-margin-top-3' : 'u-margin-top-7',
            ]"
            size="md"
            text="Success! Your Signup Form has been created"
            multiple-lines
          />
        </div>
        <div
          :class="[
            'shor-uri-section',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-2',
            $arMediaQuery.window.maxWidth('xs') && 'xs-max',
          ]"
        >
          <div
            :class="[
              'u-width-100-percent',
              'u-display-flex',
              $arMediaQuery.window.maxWidth('xs') && 'u-flex-flow-column'
            ]"
          >
            <ar-field
              class="u-flex-grow-1"
              :error-messages="[veeErrors.first('url-slug')]"
            >
              <am2-new-slug-input
                ref="slug-input"
                :prefix="`${c2ClientDomain}/m/`"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
                v-model="newUrlSlug"
                :readonly="!showEditSlug"
                v-validate="{
                  required: true,
                  arCampaignSlug: {
                    currentSlug: this.currentUrlSlug,
                    prefix: '/m/',
                  },
                }"
                data-vv-name="url-slug"
                data-vv-as="URL"
                data-vv-validate-on="input"
                @enter="handleInputEnter"
              />
            </ar-field>
            <ar-simple-button
              v-if="showEditSlug"
              :class="[
                $arMediaQuery.window.maxWidth('xs') && 'u-margin-top-2',
                $arMediaQuery.window.minWidth('sm') && 'u-margin-left-3',
              ]"
              text="Save"
              :loading="isPatchingSignupForm"
              @click="handleSaveEditClick"
            />
          </div>
          <ar-link-button
            v-if="!showEditSlug"
            color="black"
            class="u-margin-top-4"
            text="Edit URL"
            @click="handleEditURLClick"
          />
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-2',
        ]"
      >
        <ar-simple-button
          text="View Signup Form"
          outlined
          @click="handleViewSignupFormClick"
        />
        <ar-simple-button
          class="u-margin-left-4"
          text="Done"
          :style="{
            width: '108px',
          }"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      showEditSlug: false,
      newUrlSlug: '',
    };
  },
  computed: {
    ...mapState({
      createdSignupForm: state => state.signupForm.createdSignupForm,
      isPatchingSignupForm: state => state.signupForm.isPatchingSignupForm,
    }),
    displayModal() {
      return !!this.createdSignupForm;
    },
    currentUrlSlug() {
      return this.createdSignupForm?.urlSlug;
    },
  },

  watch: {
    createdSignupForm: {
      handler(val) {
        if (!val) { return; }
        this.newUrlSlug = val.urlSlug;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      'signupForm/PATCH_SIGNUP_FORM_SLUG_URL',
    ]),
    ...mapMutations([
      'signupForm/RESET_CREATED_SIGNUP_FORM',
      'signupForm/PUT_SLUR_URL_IN_CREATED_SIGNUP_FORM',
    ]),
    toggleEditSlugInput(display) {
      this.showEditSlug = display;
      this.newUrlSlug = this.createdSignupForm.urlSlug;

      if (display) {
        if (this.$refs['slug-input']) {
          this.$refs['slug-input'].focus();
        }
      }
    },
    async patchSignupFormURLSlug() {
      const isValid = await this.$validator.validate('url-slug');
      if (!isValid) { return; }

      const succeed = await this['signupForm/PATCH_SIGNUP_FORM_SLUG_URL']({
        signupFormOid: this.createdSignupForm.oid,
        urlSlug: this.newUrlSlug,
      });
      if (!succeed) { return; }

      this['signupForm/PUT_SLUR_URL_IN_CREATED_SIGNUP_FORM'](this.newUrlSlug);
      this.toggleEditSlugInput(false);
    },
    handleViewSignupFormClick() {
      window.open(`${this.c2ClientDomain}/m/${this.createdSignupForm.urlSlug}`, '_blank');
    },
    handleDoneClick() {
      this['signupForm/RESET_CREATED_SIGNUP_FORM']();
    },
    handleCloseClick() {
      this['signupForm/RESET_CREATED_SIGNUP_FORM']();
    },
    handleEditURLClick() {
      this.toggleEditSlugInput(true);
    },
    handleInputFocus() {
      this.toggleEditSlugInput(true);
    },
    handleInputEnter() {
      this.patchSignupFormURLSlug();
    },
    handleSaveEditClick() {
      this.patchSignupFormURLSlug();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-signup-form-creation-modal {
  .body {
    .success-info {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 43px 54px 35px; 
    }
    .shor-uri-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      border-top: 1px solid $blueGrey500;
      background: #F6F8FA;
      padding: 45px 54px 31px;

      &.xs-max {
        padding: 45px 10px 31px;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
