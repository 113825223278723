var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-ask-shopify-access-token-modal',{attrs:{"is-show":_vm.showAskShopifyAccessTokenModal,"loading":_vm.isCreatingShopifyIntegration},on:{"confirm":_vm.handleShopifyAccessTokenConfirm,"cancel":function($event){_vm.showAskShopifyAccessTokenModal = false}}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Shopify","description":"Automatically sync your sales & customers from Shopify","icon-props":{
          name: 'shopify',
          color: null,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingShopifyIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[(!_vm.isFetchingShopifyIntegration && _vm.shopifyIntegration && _vm.currentIntegration)?_c('am2-integration-account-select',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.currentIntegration.oid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleIntegrationChange,"new-account":_vm.handleAddNewIntegration}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Open Shopify',
            value: 'goToShopifySite',
          },
          {
            name: 'Reconnect Integration',
            value: 'reconnectIntegration',
          },
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Shopify connection is no longer valid. This means we can't sync your orders or customer data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('section',[_c('am2-card-container',{staticClass:"sync-block-container"},[(_vm.latestTaskStatus === 'in-progress')?_c('div',{staticClass:"sync-block"},[_c('am2-loading-bubble',{staticClass:"sync-animation"}),_vm._v(" "),_c('div',{staticClass:"text-block"},[_c('am2-heading',{staticClass:"header",attrs:{"size":"md","type":"h2","title":_vm.latestTaskStatus === 'in-progress' ? _vm.taskInProgressCopy : _vm.taskNotInProgressCopy}}),_vm._v(" "),_c('div',{staticClass:"button-block"},[_c('ar-simple-button',{staticClass:"button sync-btn",attrs:{"text":"Stop sync","outlined":""},on:{"click":_vm.handleStopSync}})],1)],1)],1):_c('div',{staticClass:"sync-block"},[_c('div',{staticClass:"tick-container"},[_c('ar-icon',{staticClass:"tick-icon",attrs:{"name":"check","width":"18","height":"17"}})],1),_vm._v(" "),_c('div',{staticClass:"text-block u-margin-top-8"},[_c('am2-heading',{staticClass:"header",attrs:{"size":"md","type":"h2","title":_vm.latestTaskStatus === 'in-progress' ? _vm.taskInProgressCopy : _vm.taskNotInProgressCopy}}),_vm._v(" "),_c('div',{staticClass:"button-block u-margin-top-9"},[_c('ar-simple-button',{staticClass:"button sync-btn",attrs:{"text":"Sync now","loading":_vm.latestTaskStatus === 'in-progress'},on:{"click":_vm.handleStartSync}})],1),_vm._v(" "),(_vm.hasSyncedBefore)?_c('div',{staticClass:"last-sync-time"},[_c('ar-text',{style:({
                  color: '#8E97A7',
                }),attrs:{"text":("Last synced " + _vm.timeSinceLastSync),"size":"xxs"}})],1):_vm._e()],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }