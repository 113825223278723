var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['ar-privacy-portal-page', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('am2-edit-privacy-portal-modal',{attrs:{"is-show":_vm.showEditPrivacyPortalModal,"privacy-portal":_vm.currentPrivacyPortal},on:{"create":_vm.handlePrivacyPortalCreate,"update":function($event){_vm.showEditPrivacyPortalModal = false},"cancel":_vm.handlePrivacyPortalModalClose}}),_vm._v(" "),_c('div',{staticClass:"top-section"},[_c('ar-text',{attrs:{"size":"lg","text":"Privacy Portal"}}),_vm._v(" "),(_vm.currentPrivacyPortal)?_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{
        iconName: 'settings',
      },"items":_vm.settingActions},on:{"select":_vm.handleSettingActionSelect}}):_vm._e()],1),_vm._v(" "),_c('ar-divider',{style:({
      marginTop: '35px',
      marginBottom: '28px',
    })}),_vm._v(" "),_c('am2-card-container',{class:[
      'u-position-relative' ],style:({
      padding: '78px 30px 62px',
    })},[(_vm.isPrivacyPortalPublished)?_c('am2-signal-tag',{class:[
        'u-position-absolute' ],style:({
        right: '34px',
        top: '26px',
      }),attrs:{"text":"Active"}}):_vm._e(),_vm._v(" "),_c('div',{class:[
        'u-margin-bottom-10',
        'u-display-flex',
        'u-justify-content-center',
        'u-align-items-center' ]},[_c('ar-icon',{attrs:{"name":"privacy","color":_vm.$arStyle.color.purple500}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-4",attrs:{"size":"lg","text":"Privacy Portal"}})],1),_vm._v(" "),(!_vm.currentPrivacyPortal)?_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-flex-flow-column' ]},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey700,
          maxWidth: '590px',
        }),attrs:{"size":"xs","text":"Set up a privacy portal to allow people to view, edit, download or delete the information you have about them.","multiple-lines":"","line-height":"28px","align":"center"}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-top-15",style:({
          width: '250px',
        }),attrs:{"text":"Setup Privacy Portal","disabled":_vm.setupPrivacyPortalButtonDisabled},on:{"click":_vm.handleSetupButtonClick}})],1):_vm._e(),_vm._v(" "),(_vm.currentPrivacyPortal && _vm.isPrivacyPortalPublished)?_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-flex-flow-column' ]},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey700,
          maxWidth: '590px',
        }),attrs:{"size":"xs","text":"Publish the link to your Privacy Portal on your website, so people can view, edit, download or delete the information you have about them.","align":"center","allow-html":"","multiple-lines":"","line-height":"28px"}}),_vm._v(" "),_c('am2-new-slug-input',{staticClass:"u-margin-top-14",style:({
          maxWidth: '708px',
        }),attrs:{"value":_vm.privacyPortalUrl,"readonly":""}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-top-15",style:({
          width: '250px',
        }),attrs:{"text":"View Privacy Portal"},on:{"click":_vm.handleViewPrivacyPortalClick}})],1):_vm._e(),_vm._v(" "),(_vm.currentPrivacyPortal && !_vm.isPrivacyPortalPublished)?_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-flex-flow-column' ]},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey700,
          maxWidth: '590px',
        }),attrs:{"size":"xs","text":"Your Privacy Portal has been disabled.<br/>Enable your privacy portal so people can view, edit, download or delete the information you have about them.","align":"center","allow-html":"","multiple-lines":"","line-height":"28px"}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-top-15",style:({
          width: '250px',
        }),attrs:{"text":"Enable Privacy Portal","loading":_vm.isUpdatingPrivacyPortal},on:{"click":_vm.handleEnablePrivacyPortalClick}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }