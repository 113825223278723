var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'dashboard',
    _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[_c('div',{class:[
      'main',
      _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[_c('div',{class:[
      'content',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[_c('div',{class:[
          'top-wrapper',
          'header',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"top-section-title"},[_c('ar-text',{attrs:{"size":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'md' : 'lg',"text":"Dashboard"}})],1)]),_vm._v(" "),_c('div',{class:[
            'top-right',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"top-right-button"},[_c('am2-split-dropdown',{style:({
              minWidth: '168px',
            }),attrs:{"items":_vm.createNewOptions,"text":"Create New","icon-name":"dialog","icon-distance":"12px","id":"dashboard-create-new-button","data-test-id":"dashboard-create-new-button","toggle-dropdown-on-button-click":""},on:{"select":function (item) { return item.action(); },"buttonClick":function () {null}}})],1)])]),_vm._v(" "),(_vm.$arMediaQuery.pageContent.maxWidth('lg'))?_c('am2-tabs',{staticClass:"tabs",attrs:{"selected-tab-index":_vm.selectedTabIndex,"items":_vm.tabItems},on:{"select":_vm.handleMenuSelect}}):_vm._e(),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('xl') || _vm.selectedTab === 'stats')?_c('div',{staticClass:"content-items"},[_c('SalesChartSection',{staticClass:"sales-chart u-margin-y-5"}),_vm._v(" "),_c('am2-card-container',{staticClass:"upcoming-events u-padding-6 u-margin-y-5",attrs:{"layout":"soft"}},[_c('div',{class:[
              'top-wrapper',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"top-section-title"},[_c('ar-text',{attrs:{"size":"md","text":"Upcoming events"}})],1)]),_vm._v(" "),_c('div',{class:[
                'top-right',
                _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"top-right-button"},[_c('ar-simple-button',{style:({height: '40px'}),attrs:{"id":"create-new-event-button","data-test-id":"create-new-event-button","text":_vm.$arMediaQuery.pageContent.minWidth('md') ? 'Create new event' : 'Create'},on:{"click":_vm.handleCreateNewEventClick}})],1)])]),_vm._v(" "),_c('ar-divider',{staticClass:"u-margin-top-4",style:({
              backgroundColor: _vm.$arStyle.color.skyBlueGrey400,
            })}),_vm._v(" "),_vm._l((_vm.isFetchingEvents ? 6 : 0),function(n){return _c('am2-card-skeleton',{key:n,class:[
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'tiles',
              _vm.$arMediaQuery.pageContent.minWidth('lg') && 'micro-list',
              'u-margin-bottom-4',
              _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],attrs:{"type":"event","show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'micro-list',"level":_vm.$arMediaQuery.pageContent.minWidth('md') ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.maxWidth('sm') && _vm.events.length > 0)?_c('div',{staticClass:"tiles-container"},_vm._l((_vm.eventsDecorated),function(event){return _c('am2-event-card',{key:("event-" + (event.oid)),staticClass:"u-margin-top-4 tile",attrs:{"event":event,"show-as":"tiles","integration-is-possible":false}})}),1):(_vm.$arMediaQuery.pageContent.minWidth('md') && _vm.eventsDecorated.length > 0)?_c('div',{staticClass:"events-rows-container"},_vm._l((_vm.eventsDecorated),function(event,idx){return _c('div',{key:("event-" + idx),staticClass:"event-row"},[_c('am2-event-card',{staticClass:"u-margin-y-2 micro-list",style:({
                  width: '100%',
                }),attrs:{"event":event,"show-as":"micro-list","integration-is-possible":false}}),_vm._v(" "),_c('ar-divider',{attrs:{"type":"soft"}})],1)}),0):(!_vm.isFetchingEvents && _vm.eventsDecorated.length === 0)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-5",style:({
                color: _vm.$arStyle.color.skyBlueGrey600,
              }),attrs:{"size":"sm","weight":"normal","text":"No upcoming events","allow-html":"","align":"center"}})],1):_vm._e(),_vm._v(" "),(_vm.events.length !== _vm.eventsDecorated.length)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-4",attrs:{"size":"sm","weight":"normal","text":("<a style='text-decoration: none;'>View " + (_vm.events.length - _vm.eventsDecorated.length) + " more events</a>"),"allow-html":"","align":"center","data-test-id":"see-more-events"},on:{"anchorClick":_vm.handleToggleExpandedEvents}})],1):(_vm.eventsDecorated.length > 3)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-4",attrs:{"size":"sm","weight":"normal","text":"<a style='text-decoration: none;'>Show fewer events</a>","align":"center","allow-html":"","data-test-id":"see-fewer-events"},on:{"anchorClick":_vm.handleToggleExpandedEvents}})],1):_vm._e()],2),_vm._v(" "),_c('am2-card-container',{staticClass:"active-campaigns u-padding-6 u-margin-y-5",attrs:{"layout":"soft"}},[_c('div',{class:[
              'top-wrapper',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"top-section-title"},[_c('ar-text',{attrs:{"size":"md","text":"Active campaigns"}})],1)]),_vm._v(" "),_c('div',{class:[
            'top-right',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"top-right-button"},[_c('am2-create-campaign-button',{attrs:{"text":_vm.$arMediaQuery.pageContent.minWidth('md') ? 'Create Campaign' : 'Create',"data-test-id":"create-campaign-button-dashboard"}})],1)])]),_vm._v(" "),_c('ar-divider',{staticClass:"u-margin-top-4",style:({
              backgroundColor: _vm.$arStyle.color.skyBlueGrey400,
            })}),_vm._v(" "),_vm._l((_vm.isFetchingCampaigns ? 6 : 0),function(n){return _c('am2-card-skeleton',{key:n,class:[
              'base-plan-card',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'tiles',
              _vm.$arMediaQuery.pageContent.minWidth('lg') && 'micro-list',
              'u-margin-bottom-4',
              _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],attrs:{"type":"campaign","show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'micro-list',"level":_vm.$arMediaQuery.pageContent.minWidth('md') ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.maxWidth('sm') && _vm.campaigns.length > 0)?_c('div',{staticClass:"tiles-container"},_vm._l((_vm.campaignsDecorated),function(campaign){return _c('am2-campaign-card',{key:("campaign-" + (campaign.oid)),staticClass:"u-margin-bottom-4 tile",attrs:{"campaign":campaign,"show-as":"tiles"}})}),1):(_vm.$arMediaQuery.pageContent.minWidth('md') && _vm.campaignsDecorated.length > 0)?_c('div',_vm._l((_vm.campaignsDecorated),function(campaign,idx){return _c('div',{key:("campaign-" + idx),staticClass:"campaign-row"},[_c('am2-campaign-card',{staticClass:"u-margin-y-2 micro-list",attrs:{"campaign":campaign,"show-as":"micro-list"}}),_vm._v(" "),_c('ar-divider',{attrs:{"type":"soft"}})],1)}),0):(!_vm.isFetchingCampaigns && _vm.campaignsDecorated.length === 0)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-5",style:({
                color: _vm.$arStyle.color.skyBlueGrey600,
              }),attrs:{"size":"sm","weight":"normal","text":"No upcoming campaigns","allow-html":"","align":"center"}})],1):_vm._e(),_vm._v(" "),(_vm.campaigns.length !== _vm.campaignsDecorated.length)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-4",attrs:{"size":"sm","weight":"normal","text":("<a style='text-decoration: none;'>View " + (_vm.campaigns.length - _vm.campaignsDecorated.length) + " more campaigns</a>"),"align":"center","allow-html":"","data-test-id":"see-more-campaigns"},on:{"anchorClick":_vm.handleToggleExpandedCampaigns}})],1):(_vm.campaignsDecorated.length > 3)?_c('div',[_c('ar-text',{staticClass:"u-margin-top-4",attrs:{"size":"sm","weight":"normal","text":"<a style='text-decoration: none;'>Show fewer campaigns</a>","align":"center","allow-html":"","data-test-id":"see-fewer-campaigns"},on:{"anchorClick":_vm.handleToggleExpandedCampaigns}})],1):_vm._e()],2)],1):_vm._e()],1)]),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('xl') || _vm.selectedTab === 'activity')?_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.handleFetchOlderActivity),expression:"handleFetchOlderActivity"}],ref:"activity-sidebar",class:[
      'activity-sidebar',
      _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
    ],attrs:{"infinite-scroll-disabled":"disableFetchMoreActivity","infinite-scroll-distance":10}},[_c('div',{class:[
      'activity-header',
       _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
    ]},[_c('div',{class:[
          'top-wrapper',
          _vm.$arMediaQuery.pageContent.minWidth('xl') ? 'u-padding-top-8' : 'u-padding-y-4' ]},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"top-section-title"},[(_vm.$arMediaQuery.pageContent.minWidth('xl'))?_c('ar-text',{attrs:{"size":"md","text":"Activity"}}):_vm._e(),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('xl'))?_c('div',{staticClass:"vertical-divider u-margin-x-6"}):_vm._e(),_vm._v(" "),_c('am2-link-button-dropdown',{class:[
                _vm.$arMediaQuery.pageContent.minWidth('xl') && 'u-padding-top-1',
                _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'u-padding-right-3'
              ],attrs:{"items":_vm.activityTypeDropdownOptions,"item-key":_vm.currentActivityType,"data-test-id":"activity-type-dropdown","button-props":{
                hasUnderline: false,
                hasArrow: true,
                textProps: {
                    size: 'sm',
                    weight: 'normal',
                  },
              }},on:{"select":_vm.handleActivityTypeSelect}})],1)])]),_vm._v(" "),_c('div',{class:[
          'new-activity-button-section',
          'u-padding-y-4',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max'
        ]},[_c('ar-simple-button',{staticClass:"u-width-100-percent",attrs:{"text":"See new activity","data-test-id":"see-new-activity-button","disabled":_vm.disableSeeNewActivityButton},on:{"click":_vm.handleRefreshActivity}})],1)]),_vm._v(" "),_vm._l((_vm.activityDecorated),function(item){return _c('am2-activity-card',{key:("activity-" + (item.oid)),staticClass:"activity-row",attrs:{"data-test-id":("dashboard-activity-card-" + (item.oid)),"image-path":item.imagePath,"title":item.title,"subtitle":item.subtitle,"datetime":item.datetime,"text":item.text,"path":item.path,"link":item.link,"icon":item.icon,"children":item.children ? item.children : [],"types":item.type}})}),_vm._v(" "),(_vm.isFetchingActivity && _vm.activityDecorated.length > 0)?_c('div',{style:({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '12px'
      })},[_c('am2-loading-bubble')],1):(_vm.noOlderActivity && _vm.activityDecorated.length > 0)?_c('div',{staticClass:"u-margin-top-8"},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey600,
        }),attrs:{"size":"sm","weight":"normal","text":"No more activity","allow-html":"","align":"center"}})],1):(!_vm.isFetchingActivity && _vm.activityDecorated.length === 0)?_c('div',{staticClass:"u-margin-top-8"},[_c('ar-text',{staticClass:"u-margin-top-5",style:({
          color: _vm.$arStyle.color.skyBlueGrey600,
        }),attrs:{"size":"sm","weight":"normal","text":"No fan activity found","allow-html":"","align":"center"}})],1):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }