<template>
  <AuthenticateFrame>
    <template
      slot="right-upper-section"
    >
      <div
        v-if="arEnableOnboardingProcess && $arMediaQuery.window.maxWidth('xs')"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <ar-text
          size="xxxs"
          text="Need an account?"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
        <ar-link-button
          text="Create account here"
          :text-props="{
            size: 'xxxs',
          }"
          :style="{
            marginLeft: '4px',
          }"
          @click="handleCreateAccountLinkClick"
        />
      </div>
    </template>
    <ar-text
      :text="isMasqueradeLoginPage ? 'Masquerade Login' : 'Login'"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '20px',
      }"
    />
    <form
      @submit.prevent="login"
      :style="{
        marginTop: '40px',
      }"
    >
      <div>
        <ar-input
          data-lpignore="true"
          v-validate="'required|email'"
          v-model="email"
          name="email"
          id="email"
          autocomplete="email"
          data-vv-name="email"
          type="email"
          auto-focus
          placeholder="Email address"
          :has-error="veeErrors.has('email')"
        />

        <ar-state-message
          v-show="veeErrors.has('email')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('email')"
        />
      </div>

      <div
        :style="{
          marginTop: '20px',
        }"
      >
        <ar-input
          data-lpignore="true"
          v-validate="'required|max:256'"
          v-model="password"
          :class="{'is-danger': veeErrors.has('password')}"
          class="ar-form-control"
          type="password"
          name="password"
          id="password"
          data-vv-name="password"
          placeholder="Password"
          :has-error="veeErrors.has('password')"
        />

        <ar-state-message
          v-show="veeErrors.has('password')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('password')"
        />
      </div>

      <div
        v-if="isMasqueradeLoginPage"
        :style="{
          marginTop: '20px',
        }"
      >
        <ar-input
          data-lpignore="true"
          v-validate="'required|email'"
          v-model="targetAccountEmailAddress"
          name="target-account-email"
          id="target-account-email"
          data-vv-name="targetAccountEmailAddress"
          type="email"
          auto-focus
          placeholder="Target account email address"
          :has-error="veeErrors.has('targetAccountEmailAddress')"
        />

        <ar-state-message
          v-show="veeErrors.has('targetAccountEmailAddress')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('targetAccountEmailAddress')"
        />
      </div>

      <div
        v-if="!isMasqueradeLoginPage && arEnableOnboardingProcess"
        :style="{
          display: 'flex',
          justifyContent: 'flex-end',
        }"
      >
        <ar-link-button
          text="Forgot password?"
          :text-props="{
            size: 'xxxs',
          }"
          :style="{
            marginTop: '18px',
          }"
          @click="handleForgotPasswordLinkClick"
        />
      </div>

      <ar-simple-button
        :loading="isDoingLogin"
        type="gradient-purple"
        shape="pill"
        :text="loginButtonText"
        :disabled="nextLoginRetryCountdown > 0"
        :style="{
          marginTop: '28px',
          width: '100%',
        }"
      />
    </form>
    <div
      v-if="isMasqueradeLoginPage"
      :style="{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }"
    >
      <ar-text
        size="xxxs"
        text="This is masquerade login page, you can go back to general "
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
      <ar-link-button
        size="xxxs"
        text="login page"
        :style="{
          marginLeft: '4px',
        }"
        @click="handleGoToLoginPageClick"
      />
    </div>
    <div
      v-else-if="arEnableOnboardingProcess && $arMediaQuery.window.minWidth('sm')"
      :style="{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }"
    >
      <ar-text
        size="xxxs"
        text="Need an account?"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
      <ar-link-button
        text="Create account here"
        :text-props="{
          size: 'xxxs',
        }"
        :style="{
          marginLeft: '4px',
        }"
        @click="handleCreateAccountLinkClick"
      />
    </div>
  </AuthenticateFrame>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

import AuthenticateFrame from './authenticate-frame';

export default {
  name: 'AuthenticateLogin',

  components: {
    AuthenticateFrame,
  },
  layout: 'split',

  data() {
    return {
      email: null,
      password: null,
      targetAccountEmailAddress: null, // If needed
      loginFailed: false,
      arEnableOnboardingProcess: process.env.arEnableOnboardingProcess,
    };
  },

  computed: {
    ...mapState({
      account: state => state.auth.account,
      path: state => state.route.path,
      isDoingLogin: state => state.auth.isDoingLogin,
    }),
    ...mapGetters({
      nextLoginRetryCountdown: 'auth/nextLoginRetryCountdown',
      promoterAccountOid: 'auth/promoterAccountOid',
    }),
    loginButtonText() {
      if (this.nextLoginRetryCountdown > 0) {
        return `${this.nextLoginRetryCountdown} seconds remaining`
      } else {
        if (this.isMasqueradeLoginPage) {
          return 'Login with target account'
        }
          return 'Log in'
        }
    },
    isMasqueradeLoginPage() {
      if (!this.path) {
        return false;
      }
      return this.path.indexOf('/masquerade-login') > -1;
    },
  },

  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);

    this.$validator.dictionary.merge({
      en: {
        custom: {
          email: {
            required: 'The email address field is required',
            email: 'Please enter a valid email address',
          },
          targetAccountEmailAddress: {
            required: 'The target account email address field is required',
            email: 'Please enter a valid email address',
          },
        },
      },
    });
  },

  mounted() {
    // You're logged in ? Let's go to campaigns
    if (this.promoterAccountOid) {
      this.$router.push({ path: '/campaigns' });
    }
  },

  methods: {
    ...mapActions(['auth/LOGIN']),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
      'auth/SET_STORED_EMAIL_ADDRESS',
    ]),
    async login() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      // If it's masquerade login page, we use special endpoint to get our session
      if (this.isMasqueradeLoginPage) {
        await this['auth/LOGIN']({
          emailAddress: this.email,
          password: this.password,
          targetAccountEmailAddress: this.targetAccountEmailAddress,
          masquerade: true,
        });
      } else {
        await this['auth/LOGIN']({
          emailAddress: this.email,
          password: this.password,
          masquerade: false,
        });
      }
    },

    handleGoToLoginPageClick() {
      this.$router.push({
        path: '/authenticate/login',
      });
    },

    async handleForgotPasswordLinkClick() {
      this['auth/SET_STORED_EMAIL_ADDRESS'](this.email);
      this.$router.push({
        path: '/authenticate/forgot-password',
      });
    },

    handleCreateAccountLinkClick() {
      this.$router.push({
        path: '/authenticate/signup',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
}
</style>
