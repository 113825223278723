<template>
  <div class="u-display-inline-block">
    <am2-merge-event-modal
      v-if="allowMerging"
      :is-show="openMergeEventModal"
      :source-event="event"
      @merge="handleMergeEventComplete"
      @cancel="handleMergeEventCancel"
    />
    <am2-expand-button-dropdown
      v-if="kind==='expand-icon-button'"
      align="left"
      :button-props="{ text: preventExpand ? '' : 'Settings', iconName: 'settings' }"
      :items="actions"
      :style="{ marginLeft: '8px' }"
      @select="handleSettingOptionSelect"
      :data-test-id="`event-dropdown-${eventNameInKebabCase}`"
    />
    <am2-icon-button-dropdown
      v-else
      :icon-props="{
        name: 'menu',
      }"
      :color="menuIconColor"
      :hovered-color="menuIconColor === 'white' ? $arStyle.color.blueGrey700 : $arStyle.color.purple500"
      :items="actions"
      :dropdown-style="{
        width: '160px',
      }"
      @select="handleSettingOptionSelect"
      :data-test-id="`event-dropdown-${eventNameInKebabCase}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { campaignBaseUri } from '@/utils/campaign';

export default {
  name: 'EventDropdown',

  props: {
    kind: {
      type: String,
      required: true
    },
    title: {
      type: String,
    },
    preventExpand: {
      type: Boolean,
    },
    menuStyle: {
      type: String
    },
    oid: {
      type: Number,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    menuIconColor: {
      type: String,
      default: null,
    },
    allowMerging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openMergeEventModal: false,
      isEventRSVPEnabled: process.env.arEnableEventRSVP,
    };
  },

  computed: {
    ...mapGetters({
      getEventImageUrl: 'event/getEventImageUrl',
    }),
    eventNameInKebabCase() {
      if (!this.event) {
        return '';
      }
      return this.$arUtils.general.generateDataTestPrettyName(this.event.name);
    },
    rsvpCampaign() {
      return this.event.campaigns.find((campaign) => {
        return campaign.type == "rsvp"
      }) || null;
    },
    actions() {
      const defaultActions = [
        {
          name: 'Edit',
          action: this.openEventEdit,
        },
        {
          name: 'Duplicate',
          action: this.duplicateEvent,
        }
      ];

      if (this.isEventRSVPEnabled) {
        if (this.rsvpCampaign) {
          defaultActions.splice(1, 0, {
            name: 'View RSVP page',
            action: this.viewRSVP,
          });
        } else {
          defaultActions.splice(1, 0, {
            name: 'Create RSVP page',
            action: this.createRSVP,
          });
        }
      }

      if (this.allowMerging) {
        defaultActions.push({
          name: 'Merge',
          action: this.openMergeModal,
        })
      }

      return defaultActions.concat([
        {
          name: 'Import ticket sales',
          action: this.handleTicketSalesImportClick,
        },

        {
          name: 'Import POS orders',
          action: this.handleProductSalesImportClick,
        },
        {
          name: 'Clear POS sales',
          action: this.handlePosOrdersDeleteClick,
        },
        {
          name: 'Clear ticket sales',
          action: this.handleTicketSalesDeleteClick,
        },
        {
          name: 'Delete',
          action: this.deleteEvent,
        }
      ]);
    },

  },

  methods: {
    ...mapActions([
      'event/CLONE_EVENT',
      'event/DELETE_EVENT',
      'OPEN_MERGE_EVENTS_MODAL',
      'SHOW_CONFIRM',
      'DELETE_TICKET_SALES',
      'event/DELETE_POS_ORDERS',
      'OPEN_IMPORT_EVENT_DATA_MODAL',
      'OPEN_IMPORT_PRODUCT_DATA_MODAL'
    ]),

    handleSettingOptionSelect(item) {
      item.action();
    },

    openEventEdit() {
      return this.$router.push(`/events/${this.oid}/edit`);
    },

    async duplicateEvent() {
      const clonedEventOid = await this['event/CLONE_EVENT'](this.event);

      if (clonedEventOid) {
        this.$router.push({
          path: `/events/${clonedEventOid}/view/campaigns`,
        });
      }
    },

    async deleteEvent() {
      const deletedEventOid = await this['event/DELETE_EVENT'](this.event);
      if (deletedEventOid) {
        this.$emit('delete', deletedEventOid);
      }
    },

    async clearTicketSales() {
      try {
        const result = await this.DELETE_TICKET_SALES(this.oid);
        this.$arNotification.push({ type: 'success', message: 'Ticket sales will be cleared shortly' });

        this.$emit('ticketSalesDelete');
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Could not clear ticket sales' });
      }
    },

    async clearPosOrders() {
      try {
        await this['event/DELETE_POS_ORDERS'](this.event);
        this.$emit('posOrdersDelete');
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Could not clear pos orders' });
      }
    },

    openMergeModal() {
      this.openMergeEventModal = true;
    },

    handleMergeEventCancel() {
      this.openMergeEventModal = false;
    },

    handleMergeEventComplete() {
      this.openMergeEventModal = false;
      this.$emit('merge');
    },

    async handleTicketSalesDeleteClick() {
      await this.SHOW_CONFIRM({
        messageHtml: `Are you sure you want to clear the ticket sales for this event? This cannot be undone.`,
        asyncCompleteFunction: this.clearTicketSales,
      });
    },

    async handlePosOrdersDeleteClick() {
      await this.SHOW_CONFIRM({
        messageHtml: `Are you sure you want to clear the POS orders for this event? This cannot be undone.`,
        asyncCompleteFunction: this.clearPosOrders,
      });
    },

    async handleTicketSalesImportClick() {
      this.OPEN_IMPORT_EVENT_DATA_MODAL({
        eventOid: this.oid,
        eventTimeZone: this.event.timeZone,
      });
    },

    async handleProductSalesImportClick() {
      this.OPEN_IMPORT_PRODUCT_DATA_MODAL({
        eventOid: this.oid, // eventOid passed to import modal
        eventTimeZone: this.event.timeZone,
      });
    },

    viewRSVP() {
      window.open(`${campaignBaseUri(this.rsvpCampaign.type)}${this.rsvpCampaign.urlSlug}`, '__blank');
    },

    createRSVP() {
      // Event image is necessary for creating RSVP Event
      const eventImageUrl = this.getEventImageUrl(this.event);
      if (!eventImageUrl) {
        this.SHOW_CONFIRM({
          messageHtml: 'Event image is required for creating RSVP Page, please upload image to the event first.',
        });
        return;
      }
      return this.$router.push(`/events/${this.oid}/edit/event-rsvp/basic`);
    }
  },
};
</script>




