<template>
  <ar-modal
    :is-open="isModalVisible"
    class="change-event-modal"
    header="Change Event"
    width="592px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="isModalVisible = false"
  >
    <div slot="body" class="change-event-modal__wrapper">
      <!-- Select input -->
      <div class="change-event-modal__search-wrapper col-xs-12">
        <am2-search
          v-model="query"
          placeholder="Search events"
          class="change-event-modal__search input--w100 padding-left"
        />
      </div>
      <EventList
        v-if="events && events.length > 0"
        :events-array="events"
        :query="query"
        class="col-xs-12"
        @eventSelected="handleEventSelected"/>
    </div>
    <div slot="footer" :class="[
      'change-event-modal__footer-wrapper',
      $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
    ]">
      <ar-simple-button
        text="Done"
        @click="handleSubmit"
        :style="{ width: '100px' }"
      />
    </div>


  </ar-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import EventList from './EventList';

export default {
  name: 'ChangeEventModal',

  components: {
    EventList,
  },

  props: {
    isChangeEventModalVisible: {
      type: Boolean,
      default: false,
    },
    isNewCampaign: {
      type: Boolean,
      required: true,
    },
    campaignOid: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      query: '',
      selectedEvent: {},
    };
  },

  watch: {
    isChangeEventModalVisible(val) {
      if (val) {
        this.fetchEvents();
      }
    }
  },

  computed: {
    ...mapState({
      events: state => state.event.events,
      isFetchingEventsEvents: state => state.event.isFetchingEventsEvents,
    }),
    isModalVisible: {
      get() {
        return this.isChangeEventModalVisible;
      },
      set(value) {
        this.$emit('change', value);
      },
    },

    sortedEventsOid() {
      return Object.keys(this.events).sort((a, b) => {
        if (moment(this.events[b].endDate).isAfter(moment(this.events[a].endDate))) {
          return 1;
        }
        return -1;
      });
    },
  },

  created() {
    this.fetchEvents();
  },

  beforeDestroy() {
    this['event/RESET_EVENTS']();
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
    ]),
    ...mapMutations([
      'event/RESET_EVENTS',
    ]),

    handleEventSelected(event) {
      this.selectedEvent = event;
    },
    handleSubmit() {
      this.$emit('changeEvent', this.selectedEvent);
      this.isModalVisible = false;
    },
    fetchEvents() {
      this['event/FETCH_MORE_EVENTS']({
        top: 'all',
        reload: true,
      });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/styles/base/mixins';
.change-event-modal {

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    @media (max-height: 768px) {
      min-height: 300px;
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__search {
    width: 100%;
    margin: 12px 4px;
  }
  &__search-wrapper {
    display: flex;
    background-color: #f6f9fc;
    border-bottom: 1px solid #c8d0d7;
  }

  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 34px 8px;
    align-items: center;
    justify-content: flex-end;
    z-index: $zIndexRegular;
    position: relative;
    &.sm-min {
      padding: 50px;
    }
  }
}
</style>
