<template>
  <AuthenticateFrame
    :totalStep="3"
    :currentStep="2"
    hasNextStepPannel
    :page-action-complete="pageActionComplete"
    @nextStep="handleNextClick"
  >
    <section class="import-sales-data">
      <ar-text
        text="Import your sales data"
        size="lg"
        multiple-lines
        :style="{
          marginBottom: '20px',
        }"
      />
      <p class="description">
        Sync your sales data from ticketing platforms and online stores
      </p>
    </section>
    <section :style="{ marginTop: '40px' }">
      <ConnectCard
        icon-name="eventbrite"
        title="Eventbrite"
        :connecting="isFetchingIntegrations || (isConnecting && connectingService === 'eventbrite')"
        :is-connected="connectSuccessMap.eventbrite"
        @connect="handleConnectClick('eventbrite', 'eventbrite')"
      />
    </section>
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import AuthenticateFrame from '../authenticate-frame';
import ConnectCard from './components/connect-card';

export default {
  name: 'ImportSalesData',
  components: {
    AuthenticateFrame,
    ConnectCard,
  },
  layout: 'split',
  data() {
    return {
      pageActionComplete: false,
      isFetchingIntegrations: false,
      isConnecting: false,
      connectingService: null,
      connectSuccessMap: {},
    };
  },
  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](2);

    this.fetchIntegrations();
  },
  methods: {
    ...mapActions([
      'FETCH_INTEGRATIONS',
      'CONNECT_TO_INTEGRATION',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),

    async fetchIntegrations() {
      try {
        this.isFetchingIntegrations = true;
        const eventbriteIntegrations = await this.FETCH_INTEGRATIONS({
          top: 1,
          filters: {
            expressions: [{
              key: 'provider',
              value: 'eventbrite',
              operator: '=',
            }, {
              key: 'status',
              value: 'completed',
              operator: '=',
            }],
            logicalOperators: ['AND']
          }
        });
        this.connectSuccessMap.eventbrite = eventbriteIntegrations[0] ? true : false;
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch integrations' });
      } finally {
        this.isFetchingIntegrations = false;
      }
    },

    handleNextClick() {
      this.$router.push({ path: '/authenticate/billing-details' });
    },

    async handleConnectClick(provider, app) {
      let service;
      if (provider === app) {
        service = provider;
      } else {
        service = `${provider}-${app}`;
      }
      this.isConnecting = true;
      this.connectingService = service;
      try {
        const res = await this.CONNECT_TO_INTEGRATION({ provider, app });
        this.connectSuccessMap[service] = true;
        this.isConnecting = false;
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }
        switch (`${provider}-${app}`) {
          case 'eventbrite-eventbrite':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Eventbrite: ${e.message}` });
            break;
          default:
            this.$arNotification.push({ type: 'error', message: 'Failed to connect Eventbrite' });
            break;
        }
        throw e;
      } finally {
        this.isConnecting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import-sales-data {
  .description {
    font-size: 16px;
    color: $skyBlueGrey700;
    letter-spacing: 0;
    line-height: 20px;
  }
}
</style>
