<template>
  <div class="list-view list-container" ref="select">
    <div
      v-if="searchable"
      class="search-container"
    >
      <am2-search
        :style="{ width: searchSettings.button ? 'calc(100% - 194px)' : '100%' }"
        :placeholder="searchSettings.placeholder"
        @input="(search) => this.currentSearch = search" />
      <ar-simple-button
        v-if="searchSettings.button"
        icon-name="circle-plus"
        icon-distance="9px"
        :text="searchSettings.button.text"
        :style="{ width: '185px', marginLeft: '9px', padding: '6px 22px' }"
        @click="searchSettings.button.click"
      />
    </div>
    <div v-for="(option, index) in filteredOptions" 
      :key="`${option.oid}-${index}`" 
      :class="[
        'list-item',
        layout
      ]"
      @click="selectOption(option.oid)">
        <slot name="template" v-bind="option">
          {{ option }}
        </slot>
        <div
          v-if="selected.indexOf(option.oid) !== -1"
          class="tick-container">
          <ar-icon
            name="check"
            color="white"
          />
        </div>
        <div v-else class="empty-circle" />
      
    </div>
    <div v-if="fetching" class="loading">
      <am2-loading-bubble type="secondary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListView',

  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    searchSettings: {
      type: Object,
      default: null,
    },
    maxOptions: {
      type: Number,
      default: null,
    },
    fetchDataAdapter: {
      type: Function
    },
    onSelected: {
      type: Function
    },
    initialSelected: {
      type: Array,
      default: () => []
    },
    layout: {
      type: String,
      default: "regular"
    },
    selectedCategory: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      options: [],
      fetching: false,
      currentSearch: null,
      selected: [],
      validOptions: this.options,
    };
  },

  created() {
    if(this.initialSelected && this.initialSelected.length > 0) {
      this.selected = this.initialSelected
    }
    this.fetchData()
  },

  computed: {
    searchable() {
      return !!this.searchSettings;
    },

    filteredOptions() {
      if(!this.currentSearch) {
        return this.options
      }
      return this.options.filter(option => {
        return this.selected.indexOf(option.oid) !== -1 || 
          (option.name || '').toLowerCase().indexOf(this.currentSearch.toLowerCase()) > -1
      })
    }
  },

  watch: {
    selectedCategory () {
      this.fetchData();
    }
  },

  methods: {

    async fetchData() {
      this.fetching = true;
      try {
        this.options = await this.fetchDataAdapter()
        this.fetching = false;
      } catch (e) {
        this.fetching = false;
      }
    },


    selectOption(optionOid) {
      if(this.multiple) {
        const selectedOptionIndex = this.selected.indexOf(optionOid);
        if(selectedOptionIndex === -1) {
          this.selected.push(optionOid);
        } else {
          this.selected.splice(selectedOptionIndex, 1)
        }
      } else {
        this.selected = [optionOid];
      }
      this.onSelected(this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
$blueGrey700-width: 20px;
$blueGrey700-container-width: calc(#{$blueGrey700-width} + 10px);

.list-view {
  .search-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 18px;
  }
}

.loading {
  margin-top: 38.5px;
  text-align: center;
}

.list-container {
  .list-item {
    height: 80px;
    border-radius: 4px;
    border: 1px solid $blueGrey400;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 18px;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      border: 1px solid $green500;
      box-shadow: 0px 0px 2px $green500;
    }
    &.compact {
      height: 60px;
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - #{$blueGrey700-container-width});
      text-overflow: ellipsis;
      overflow: hidden;
      .label {
        width: calc(100% - 90px);
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .subtitle {
          font-size: 12px;
          color: $blueGrey700;
        }
      }
    }
    .image {
      height: 50px;
      width: 70px;
      margin-right: 20px;
      border-radius: 4px;
    }
    .tick-container {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $green500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .empty-circle {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid $skyBlueGrey500;
    }
  }
}
</style>
