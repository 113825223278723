<template>

  <section class="message-center-insights">
      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
      >
        <am2-clicks-stats-table
          :loading="false"
          :locked="false"
          :style="{ height: 'auto' }"
          :data="filteredClicksData"
          heading="Link Click Analytics"
          :column-names="['LINK', 'UNIQUE CLICKS', 'CLICKS']"
          empty-text="No data is available"
          :showSeeAllButton="showSeeAllButton"
          @handleSeeAll="handleSeeAll"
          @handleSeeLess="handleSeeLess"
          :timestamp="clickStatsTimestamp"
        />
      </am2-card-container>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MessageViewInsights',
  data() {
    return {
      clickStatsData: null,
      clickStatsTimestamp: null,
      head: ['Link'],
      topLimit: 5,
      filteredClicksData: null,
      showSeeAllButton: null,
    }
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
    }),
  },
  async mounted() {
    const succeed = await this['message/FETCH_MESSAGE'](this.oid);
    if(succeed && !!this.currentSelectedMessage?.statusDetails?.clicksSnapshot) {
      let latestDate = null;
      this.clickStatsData = this.currentSelectedMessage.statusDetails.clicksSnapshot.map((clicksSnapshotItem) => {
        latestDate = this.getLatestSyncDate(latestDate, clicksSnapshotItem.sysMtime)

        return {
          name: clicksSnapshotItem.targetUrl,
          uniqueTotal: clicksSnapshotItem.uniqueTotal,
          total: clicksSnapshotItem.total,
          timestamp: clicksSnapshotItem.sysMtime,
        }
      }).sort((a, b) => {
        if (!a?.total || !b?.total) return 0;
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
      })

      this.clickStatsTimestamp = latestDate

      if (this.clickStatsData) {
        if (this.clickStatsData.length > this.topLimit) {
          if (!this.showSeeAllButton) {
            this.showSeeAllButton = true
          } else {
            this.showSeeAllButton = false
          }
        }
      }

      this.filterClicksDataBasedOnTopLimit()
    }
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGE',
    ]),

    handleSeeAll() {
      this.filteredClicksData = this.clickStatsData
      this.showSeeAllButton = false;
    },
    handleSeeLess() {
      this.filterClicksDataBasedOnTopLimit()
      this.showSeeAllButton = true;
    },
    filterClicksDataBasedOnTopLimit() {
      if (!this.clickStatsData || this.clickStatsData.length === 0) return;
      
      // Limit to the top limit which is 5 or the clickStatsData length if it's less than the top limit
      this.filteredClicksData = this.clickStatsData.slice(0, Math.min(this.clickStatsData.length, this.topLimit))
    },
    getLatestSyncDate(latestDate, currentLinkDate) {
      if (!latestDate) {
        return currentLinkDate
      }

      if (!currentLinkDate) {
        return latestDate;
      }

      const latestDateConverted = new Date(latestDate)
      const currentLinkDateCoverted = new Date(currentLinkDate)

      if (latestDateConverted < currentLinkDateCoverted) {
        return currentLinkDate
      } else {
        return latestDate
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-center-insights {
  margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  .chart-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 100%;
    max-width: 1200px;
    padding: 25px;
  }
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden
}
</style>
