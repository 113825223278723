<template>
  <section
    class="registrants-container"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <div
      :style="{
        padding: '22px 0 22px',
        backgroundColor: '#F7F9FC',
      }"
      v-ar-sticky-top="{
        priority: 1,
      }"
    >
      <am2-fans-control-section
        class="registrants-top-wrapper"
        fan-appellation="registrant"
        :fans-count="totalAudienceCount"
        :fansSearch="{
          action: serverSideSearch,
        }"
        :loading="(!campaign || isFetchingCampaign ||isFetchingAudience) && audience.length === 0"
        :csvExport="{
          action: handleExportClick,
          expanded: true,
        }"
      />
    </div>

    <am2-fan-table
      v-if="campaign"
      :head="tableHeaders"
      :body="tableRows"
      :loading="isFetchingAudience && tableRows.length === 0"
      has-index
      empty-text="No audience data"
      tableType="audience"
      @sortBy="handleSortChange"
      :enable-row-click="customerProfilesEnabled"
      @rowClick="handleRowClick"
      has-sticky-header
    />

  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
const initialOrderBy = { key: 'points', order: 'desc' };
const freeOrderBy = { key: 'registrationTime', order: 'desc' };
export default {
  name: 'CampaignAudience',

  props: {
    campaign: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      orderBy: initialOrderBy,
      isFetchingCampaign: false,
      searchString: '',
    };
  },

  computed: {
    ...mapState({
      audience: state => state.audience.audience,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      isFetchingAudience: state => state.audience.isFetchingAudience,
      isNoMoreAudience: state => state.audience.isNoMoreAudience,
      campaignOid: state => state.route.params.oid,
      campaignRegistrationStats: state => state.campaign.currentCampaignRegistrationStats,
    }),
    /**
     * The default table headings used across all registrations
     * Some campaign types or campaign configurations will add to this list
     *
     * @returns
     * [
     *   name: String,        // The name as it'll appear in the table. Keep null/false to hide the data from the table and only use it for export
     *   key: String,         // The Vue key we use in the table - also handy to reference the data.
     *   sortKey: String,     // The key the server will use to sort the data
     *   format: String,      // The format we pass to the table component so that it knows how to format the data
     *   mutator: String      // Name of a locally-scoped function which will mutate the value into something else to display in the table
     *   exportKey: String[], // The key we use when exporting the data. Keep null/false to hide the data from export and only display it in table
     *   fetchKey: String[],  // the key we use when fetching the data. Keep null/false to hide the data from the table and only use it for export
     * ]
     */
    registrantDataColumns() {
      return [
        {
          name: 'Name',
          key: 'name',
          // Specialise the sort key because the server does not store a "name" field but rather first and last name separately.
          sortKey: 'firstName',
          format: 'name',
          mutator: 'mutateRowDataName',
          exportKey: ['firstName','lastName'],
          fetchKey: ['firstName','lastName'],
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          sortKey: 'emailAddress',
          mutator: null,
          exportKey: 'emailAddress',
          fetchKey: 'emailAddress',
        },
        {
          name: 'Mobile',
          key: 'mobileNumber',
          format: 'text',
          sortKey: 'mobileNumber',
          mutator: null,
          exportKey: 'mobileNumber',
          fetchKey: 'mobileNumber',
        },
        ...(!this.isFreeCampaign ? [{
          name: 'Referrals',
          key: 'referrals',
          sortKey: 'referrals',
          format: "number",
          mutator: null,
          exportKey: 'referrals',
          fetchKey: 'referrals',
        }] : []),
        ...(!this.isFreeCampaign ? [{
          name: 'Campaign points',
          key: 'points',
          sortKey: 'points',
          format: "points",
          mutator: null,
          exportKey: 'points',
          fetchKey: 'points',
        }] : []),
        {
          name: 'Registration time',
          key: 'registrationTime',
          format: 'registrationTime',
          sortKey: 'registrationTime',
          mutator: null,
          exportKey: 'registrationTime',
          fetchKey: 'registrationTime',
        }
      ]
    },
    exportKeys() {
      return ['emailAddress', 'firstName', 'lastName', 'mobileNumber', 'dob', 'streetAddress', 'postcode', 'city', 'state', 'country', ...(!this.isFreeCampaign ? ['referrals'] : []), ...(!this.isFreeCampaign ? ['points'] : []), 'registrationTime']
    },
    isFreeCampaign() {
      return this.campaign?.presentation.flow.length <= 1
    },
    infiniteScrollDisabled() {
      return this.isFetchingAudience || this.isNoMoreAudience;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },
    filter() {
      return {
        logic: [],
        conditions: [
          {
            name: 'campaigns',
            type: 'condition-search-picker',
            data: {
              condition: 'true_to_all',
              values: [ this.campaignOid ],
            },
          },
        ],
      };
    },

    fanAttributeFetchKeys() {
      if (!this.campaign) {
        return [];
      }

      // Make sure the heading has a fetchKey, name and regular old key
      const validDataColumns = this.registrantDataColumns.filter(heading => !!heading.name && !!heading.key && !!heading.fetchKey);

      let fanAttributeKeys = [];
      for (let i = 0; i < validDataColumns.length; i += 1) {
        const heading = validDataColumns[i];
        if (Array.isArray(heading.fetchKey)) {
          fanAttributeKeys = fanAttributeKeys.concat(heading.fetchKey);
        } else {
          fanAttributeKeys.push(heading.fetchKey);
        }
      }
      return fanAttributeKeys;
    },

    tableHeaders() {
      return this.registrantDataColumns.filter( item => !!item.name && !!item.key && !!item.fetchKey)
    },

    tableRows() {
      if (!this.audience) {
        return [];
      }
      const mutableColumns = this.registrantDataColumns.filter( column => !!column.mutator);
      return this.audience.map(fan => {
        mutableColumns.forEach( mutableColumn => {
          const mutator = mutableColumn.mutator;
          fan = this[mutator] instanceof Function ? this[mutator](fan) : fan;
        });
        return fan;
      });
    },
  },

  created() {
    // If Campaign is ready, load data, otherwise, wait
    if (this.campaign) {
      if (this.isFreeCampaign) {
        this.orderBy = freeOrderBy;
      }
      this.maybeAddAdditionalColumns();
      this.reloadData();
    }
  },

  watch: {
    campaign(newCampaign, oldCampaign) {
      if (newCampaign?.presentation.flow.length <= 1) {
        this.orderBy = freeOrderBy;
      }
      if (newCampaign && !oldCampaign) {
        this.maybeAddAdditionalColumns();
        this.reloadData();
      }
    }
  },

  beforeDestroy() {
    this['audience/RESET_AUDIENCE']();
  },

  methods: {
    ...mapActions([
      'audience/FETCH_MORE_AUDIENCE',
      'audience/EXPORT_AUDIENCE_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'audience/RESET_AUDIENCE',
      'audience/SET_AUDIENCE_COUNT',
    ]),
    handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      this.orderBy = {
        key,
        order,
      };

      this.reloadData()
    },

    loadMoreData() {
      if (!this.campaign) return;
      this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeFetchKeys,
        filter: this.filter,
        orderBy: this.orderBy,
      });
    },

    async serverSideSearch(searchString) {
      this.searchString = searchString;
      this.reloadData();
    },

    reloadData() {
      if (!this.campaign) return;
      this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeFetchKeys,
        filter: this.filter,
        orderBy: this.orderBy,
        reload: true,
        skipCount: true,
      });
      if (this.campaignRegistrationStats) this['audience/SET_AUDIENCE_COUNT'](this.campaignRegistrationStats.allRegistrations)
    },

    handleExportClick() {
      this['audience/EXPORT_AUDIENCE_CSV'](
        {
          filter: this.filter,
          searchString: this.searchString,
          selectKeys: this.exportKeys,
          orderBy: this.orderBy || { key: 'points', order: 'desc' },
        }
      );

      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 1250);
    },

    // Mutators must return the row that they're mutating!
    mutateRowDataName(row) {
      row.avatar = row.profileImageUrl;
      return row;
    },
    mutateRowDataPaid(row) {
      // Set $0 to null so that values don't apepar
      if(row.paid === 0) row.paid = null;
      return row;
    },


    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    maybeAddAdditionalColumns() {
      // If this is a campaign for which we are a ticketing agency, then add a paid column to the data
      if (this.campaign.settings.payment && this.campaign.settings.payment.required && !this.registrantDataColumns.some (item => item.key === 'paid')) {
        this.registrantDataColumns.push(
          {
            name: 'Amount Paid',
            key: 'paid',
            format: 'currency',
            sortKey: null,
            mutator: 'mutateRowDataPaid',
            fetchKey: `ticketSalesPerEvent[${this.campaign.eventOid}] as paid`,
            exportKey: `ticketSalesPerEvent[${this.campaign.eventOid}] as salesAmount`,
          },
        );
      }

      // In addition to those fields in the table we also export those fields that were included
      // in the registration fields for the Campaign.
      const registrationFields = this.campaign.registrations.fields
      if (registrationFields) {
        for (const [key, value] of Object.entries(registrationFields)) {

          // Just make sure to check they dont already exist!
          const alreadyExists = this.registrantDataColumns.some( item => {
            if(Array.isArray(item.exportKey)) {
              return item.exportKey.some( exportKey => exportKey === key);
            }
            return item.exportKey === key;
          });

          if (value && !alreadyExists) {
            this.registrantDataColumns.push(
              {
                name: null,
                key: key,
                format: null,
                sortKey: null,
                fetchKey: null,
                exportKey: key,
              },
            );
          }
        }
      }

      // SMS/Email opt-in is special because they're classed as social accounts follow.
      // We can check this by looking at the socialActions['socialAccounts']['sms'] and ['email']
      // SocialActions might be null, be careful
      if (this.campaign.socialActions && this.campaign.socialActions.socialAccounts) {
        const socialAccounts = this.campaign.socialActions.socialAccounts

        const smsOptInAvailable = socialAccounts.find((account) => account.sms === "enable" ) ? true : false
        const emailOptInAvailable = socialAccounts.find((account) => account.email === "enable") ? true : false

        if (smsOptInAvailable && !this.registrantDataColumns.some( item => item.key === 'smsOptIn')) {
          this.registrantDataColumns.push(
            {
              name: null,
              key: 'smsOptIn',
              format: null,
              sortKey: null,
              fetchKey: null,
              exportKey: "additionalInfo[subscriptions,sms] as smsOptIn",
            },
          );
          this.exportKeys.push("additionalInfo[subscriptions,sms] as smsOptIn")
        }

        if (emailOptInAvailable && !this.registrantDataColumns.some( item => item.key === 'emailOptIn')) {
          this.registrantDataColumns.push(
            {
              name: null,
              key: 'emailOptIn',
              format: null,
              sortKey: null,
              fetchKey: null,
              exportKey: "additionalInfo[subscriptions,email] as emailOptIn",
            },
          );
          this.exportKeys.push("additionalInfo[subscriptions,email] as emailOptIn")
        }
      }
    }
  },
};
</script>

<style lang="scss">
/* LEADERBOARD ------------------------ */
.registrants-container {

  .registrants-top-wrapper {
    margin-bottom: 27px;

    .registrants-top {
      width: calc(100% - 30px);
      margin: 0;
    }

    .registrants-menu-button {
      background: $skyBlueGrey400;
      top: 0;

      .registrants-menu-button-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 30px;
        height: 30px;
      }

      .registrants-menu-button-text {
        display: inline-block;
        vertical-align: middle;
        top: 2px;
      }
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }
}
</style>
