<template>
  <div :class="['condition-renderer-wrapper', isRetiring && 'retire']" v-if="!!filterCriteria">
    <FreeTextPicker
      v-if="filterCriteria.type === 'text' || filterCriteria.type === 'text-picker'"
      :data-test-id="`filter-${filterCriteria.resource}`"
      :filter-criteria="filterCriteria"
      :filter-condition="filterCondition"
      :condition-button-text="conditionButtonText"
      :disable-read-mode="disableReadMode"
      :hide-button="hideButton"
      @apply="applyCondition"
      @delete="deleteCondition"
    />
    <!-- Actually they should both be same type, but we didn't have thought about that. -->
    <NumericPicker
      v-if="filterCriteria.type === 'currency' || filterCriteria.type === 'numeric'"
      :data-test-id="`filter-${filterCriteria.resource}`"
      :filter-criteria="filterCriteria"
      :filter-condition="filterCondition"
      :condition-button-text="conditionButtonText"
      :hide-button="hideButton"
      :disable-read-mode="disableReadMode"
      @apply="applyCondition"
      @delete="deleteCondition"
    />
    <SelectPicker
      v-else-if="filterCriteria.type === 'select'"
      :data-test-id="`filter-${filterCriteria.resource}`"
      :filter-criteria="filterCriteria"
      :filter-condition="filterCondition"
      :condition-button-text="conditionButtonText"
      :hide-button="hideButton"
      :disable-read-mode="disableReadMode"
      @apply="applyCondition"
      @delete="deleteCondition"
    />
    <DatePicker
      v-if="filterCriteria.type === 'date' || filterCriteria.type === 'date-picker'"
      :data-test-id="`filter-${filterCriteria.resource}`"
      :filter-criteria="filterCriteria"
      :filter-condition="filterCondition"
      :condition-button-text="conditionButtonText"
      :hide-button="hideButton"
      :disable-read-mode="disableReadMode"
      @apply="applyCondition"
      @delete="deleteCondition"
    />
    <ConditionSearchPicker
      v-else-if="filterCriteria.type === 'condition-search-picker'"
      :data-test-id="`filter-${filterCriteria.resource}`"
      :filter-criteria="filterCriteria"
      :filter-condition="filterCondition"
      :condition-button-text="conditionButtonText"
      :hide-button="hideButton"
      :disable-read-mode="disableReadMode"
      @apply="applyCondition"
      @delete="deleteCondition"
      @resourcesUpdate="handleResourcesUpdate"
    />
  </div>
</template>

<script>
import FreeTextPicker from '../FreeTextPicker'
import NumericPicker from '../NumericPicker'
import SelectPicker from '../SelectPicker'
import DatePicker from '../DatePicker'
import ConditionSearchPicker from '../ConditionSearchPicker'

export default {
  name: 'ConditionRenderer',
  components: {
    FreeTextPicker,
    NumericPicker,
    SelectPicker,
    DatePicker,
    ConditionSearchPicker,
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null,
    },
    filterCondition: {
      type: Object,
      default: () => null,
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRetiring: false,
    };
  },
  methods: {
    applyCondition(newFilterCondition) {
      this.$emit('apply', newFilterCondition)
    },

    deleteCondition() {
      this.isRetiring = true;
      setTimeout(() => {
        this.$emit('delete')
      }, 300);
    },
    handleResourcesUpdate(resource, items) {
      this.$emit('resourcesUpdate', resource, items)
    }
  },
};
</script>

<style lang="scss" scoped>
.condition-renderer-wrapper {
  transition: all 0.3s;
  opacity: 1;
  &.retire {
    opacity: 0;
  }
}
</style>
