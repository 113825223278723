<template>
  <section
    class="color-input-wrapper"
  >
    <div class="color-input" @click="handleColorPickerClick">
      <div
        class="color-square"
        :style="{
          background: realValue,
        }"
      />
      <ar-text
        class="u-margin-left-4"
        size="xs"
        :text="placeholder"
        :style="{
          color: $arStyle.color.skyBlueGrey800,
        }"
      />
      <ar-icon
        class="u-margin-left-4"
        name="color-picker"
        :color="$arStyle.color.blueGrey700"
      />
    </div>
    <div
      ref="invisible-modal"
      :style="{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: this.$arStyle.zIndex.globalHighest,
        display: isColorPickerOpen ? 'block' : 'none',
      }"
      @click.stop.prevent="handleInvisibleModalClick"
    />
    <div
      ref="third-party-color-picker-wrapper"
      :style="{
        display: 'inline-block',
        position: 'fixed',
        top: thirdPartyColorPickerWrapperPosition.top,
        bottom: thirdPartyColorPickerWrapperPosition.bottom,
        left: thirdPartyColorPickerWrapperPosition.left,
        width: thirdPartyColorPickerWrapperPosition.width,
        height: isColorPickerOpen ? thirdPartyColorPickerWrapperPosition.height : '0',
        opacity: isColorPickerOpen ? '1' : '0.7',
        overflow: 'hidden',
        transition: 'height 0.2s, opacity 0.2s',
        zIndex: this.$arStyle.zIndex.globalHighest + 1,
      }"
    >
      <Sketch
        ref="third-party-color-picker"
        :style="{
          position: 'relative',
          display: 'inline-block',
          margin: thirdPartyColorPickerWrapperPosition.padding,
        }"
        disable-alpha
        :value="realValue"
        @input="handleColorChange"
      />
    </div>
  </section>
</template>

<script>
import { Sketch } from 'vue-color';
import { isClickOutside } from '@/utils/html-element/';

const defaultColor = '#7344c0';

export default {
  name: 'ColorInput',

  components: {
    Sketch,
  },

  props: {
    value: {
      type: String,
      default: defaultColor,
    },
  },

  data() {
    return {
      isColorPickerOpen: false,
      thirdPartyColorPickerWrapperPosition: {},
      realValue: null,
    };
  },

  computed: {
    placeholder() {
      if (this.realValue && this.realValue.toLowerCase() !== defaultColor) {
        return this.realValue;
      } else {
        return 'Choose your own color';
      }
    },
  },

  watch: {
    value: {
      handler: function(val) {
        this.realValue = val;
      },
      immediate: true,
    },
    isColorPickerOpen(val) {
      if (val) {
        this.updateSelectPosition();

        // Lock scroll
        this.lockScrollY = window.scrollY;
        addEventListener('scroll', this.handleScroll);
      } else {
        removeEventListener('scroll', this.handleScroll);
      }
    },
  },

  mounted() {
    this.attachSelectBoxToBody();
    this.updateSelectPosition();
    addEventListener('click', this.handleIsClickOutside);
    addEventListener('resize', this.updateSelectPosition);
  },

  beforeDestroy() {
    this.removeSelectBoxFromBody();
    removeEventListener('click', this.handleIsClickOutside);
    removeEventListener('resize', this.updateSelectPosition);
    removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    // Just attach the dialog to body level, remove it when we don't need it anymore
    attachSelectBoxToBody() {
      document.body.appendChild(this.$refs['invisible-modal']);
      document.body.appendChild(this.$refs['third-party-color-picker-wrapper']);
    },
    removeSelectBoxFromBody() {
      document.body.removeChild(this.$refs['invisible-modal']);
      document.body.removeChild(this.$refs['third-party-color-picker-wrapper']);
    },
    updateSelectPosition() {
      const thirdPartyColorPickerHt = 302;
      const thirdPartyColorPickerWd = 220;
      const elementHt = this.$el.offsetHeight;
      const elementWd = this.$el.offsetWidth;
      const { top, left, bottom } = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const padding = 1;
      if (thirdPartyColorPickerHt + top + elementHt > windowHeight) {
        this.thirdPartyColorPickerWrapperPosition = {
          bottom: `${windowHeight - top + 5 - padding}px`,
          left: `${left - padding}px`,
          width: `${thirdPartyColorPickerWd + padding * 2}px`,
          height: `${thirdPartyColorPickerHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      } else {
        this.thirdPartyColorPickerWrapperPosition = {
          top: `${top + elementHt + 5 - padding}px`,
          left: `${left - padding}px`,
          width: `${thirdPartyColorPickerWd + padding * 2}px`,
          height: `${thirdPartyColorPickerHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      }
    },
    handleColorPickerClick() {
      this.isColorPickerOpen = true;
    },
    handleColorChange(val) {
      this.realValue = val.hex;
      this.$emit('input', val.hex);
    },
    handleInvisibleModalClick(event) {
      this.isColorPickerOpen = false;
    },
    handleScroll(event) {
      event.preventDefault();
      window.scrollTo(window.scrollX, this.lockScrollY);
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.color-input-wrapper {
  position: relative;
  display: inline-block;
  height: 50px;
  background: $skyBlueGrey300;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: $skyBlueGrey500;
  }

  .color-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 18px 10px 10px;

    .color-square {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 3px;
    }
  }
}
</style>
