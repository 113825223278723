var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"events-view"},[[(_vm.isFetchingEvent)?_c('am2-hero-skeleton',{attrs:{"type":"hero","back-path":_vm.sanitizedReferringPage,"is-events":""}}):(_vm.currentEvent)?_c('am2-hero',{attrs:{"image":_vm.eventImage,"title":_vm.currentEvent.name,"event-name":_vm.currentEvent.name,"timeframe":_vm.eventTimeframe,"location":_vm.currentEvent.location,"back-path":_vm.sanitizedReferringPage,"is-events":"","urlSlug":_vm.getCurrentUrlSlug}}):_vm._e(),_vm._v(" "),_c('am2-navbar',{staticClass:"navigation-events",attrs:{"menu":_vm.menu}},[_c('div',{class:[
          'buttons-section',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
          _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
          _vm.$arMediaQuery.pageContent.minWidth('lg') && 'lg-min'
        ],attrs:{"slot":"controls"},slot:"controls"},[_c('div',{class:[
        'buttons-section__main-buttons-group',
        (_vm.$arMediaQuery.pageContent.minWidth('lg') || _vm.optionsPaneVisible) && 'is-visible' ]},[_c('am2-create-campaign-button',{style:({ marginRight: '8px' }),attrs:{"text":_vm.$arMediaQuery.pageContent.minWidth('lg') ? 'Create campaign' : 'New campaign',"outlined":"","campaignArgs":{
              eventOid: _vm.currentEvent && _vm.currentEvent.oid,
              skipEventSelection: true,
            },"data-test-id":"create-campaign-button-events-nav"}}),_vm._v(" "),(_vm.hasEventbriteIntegration)?_c('ar-simple-button',{style:({ marginRight: '8px' }),attrs:{"text":_vm.$arMediaQuery.pageContent.minWidth('lg') ? 'Sync ticket sales' : 'Sync sales',"loading":_vm.isSyncing},on:{"click":_vm.syncWithIntegrations}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"buttons-section__secondary-buttons-group"},[(_vm.$arMediaQuery.pageContent.maxWidth('md'))?_c('ar-simple-button',{style:({
              marginRight: _vm.optionsPaneVisible && _vm.$arMediaQuery.pageContent.minWidth('md') ? '0' : '8px',
              height: '50px',
              transition: '0.3s',
              padding: '0 18px',
            }),attrs:{"icon-name":_vm.optionsPaneVisible ? 'arrow' : 'edit',"text":_vm.showOptionsText,"iconProps":{
              rotate: _vm.optionsPaneVisible && _vm.$arMediaQuery.pageContent.minWidth('md') ? -90 : null,
            },"type":"grey"},on:{"click":_vm.handleOptionsClick}}):_vm._e(),_vm._v(" "),(_vm.arEnableMessageFromEvent)?_c('am2-split-dropdown',{staticClass:"campaign-button",style:({
              width: '196px',
            }),attrs:{"text":"New Message","toggle-dropdown-on-button-click":"","items":_vm.messageButtonOptions,"icon-name":"dialog","icon-distance":"12px"},on:{"select":function (item) { return item.action(); }}}):_vm._e(),_vm._v(" "),(_vm.currentEvent && _vm.currentEvent.oid && (!_vm.optionsPaneVisible || _vm.$arMediaQuery.pageContent.maxWidth('sm') || _vm.$arMediaQuery.pageContent.minWidth('lg')))?_c('am2-event-dropdown',{style:({ marginLeft: '0' }),attrs:{"oid":_vm.currentEvent.oid,"event":_vm.currentEvent,"name":_vm.currentEvent.name,"title":"Settings","kind":"expand-icon-button","preventExpand":!_vm.$arMediaQuery.pageContent.minWidth('md')},on:{"ticketSalesDelete":_vm.refreshEventData,"delete":_vm.handleEventDelete}}):_vm._e()],1)])]),_vm._v(" "),(_vm.hasPendingTasks)?_c('section',{class:['content', _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min'],style:({paddingBottom: 0})},[_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"warning","message":_vm.pendingTasksString}})],1):_vm._e(),_vm._v(" "),_c('nuxt-child',{class:['content', _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min'],attrs:{"event":_vm.currentEvent,"is-fetching-event":_vm.isFetchingEvent},on:{"navbarAction":_vm.runActionFromChild}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }