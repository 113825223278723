<template>
  <section class="signup-form-modify-basic">
    <ar-text
      size="xxs"
      text="STEP 1"
      :style="{
        color: '#9FA8B5',
      }"
      weight="bold"
    />
    <am2-heading
      class="u-margin-top-2"
      type="h1"
      size="lg"
      :title="isEditSignupForm ? `Edit Signup Form` : `Setup your signup form`"
      multiple-lines
    />

    <!-- signup form title -->
    <ar-field
      class="u-margin-top-13"
      label="Heading"
      :error-messages="[veeErrors.first('headline')]"
    >
      <ar-input
        v-validate="'required'"
        :value="scratchSignupForm.name"
        @input="handleTitleInput"
        autocomplete="campaign-headline"
        data-vv-name="headline"
        data-vv-as="Heading"
      />
    </ar-field>

    <!-- signup form description -->
    <ar-field
      v-if="showDescription"
      class="u-margin-top-12"
      label="Description (optional)"
    >
      <ar-textarea
        :value="scratchSignupForm.presentation.description"
        @input="handleDescriptionInput"
        :rows="5"
      />
    </ar-field>
    <ar-link-button
      v-if="!showDescription"
      class="u-margin-top-3"
      text="Add a description"
      @click="showDescription = true"
    />

    <ar-field
      class="u-margin-top-12"
      label="Signup Form URL"
      :error-messages="[veeErrors.first('url-slug')]"
    >
      <!-- Only for this, we validate it immediately after @input -->
      <am2-new-slug-input
        class="u-margin-top-4"
        v-validate="{
          required: true,
          arCampaignSlug: {
            currentSlug: this.currentUrlSlug,
            prefix: '/m/',
          },
        }"
        data-vv-name="url-slug"
        data-vv-as="Signup Form URL"
        data-vv-validate-on="input"
        :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
        :value="scratchSignupForm.urlSlug"
        @input="handleUrlSlugInput"
        :prefix="`${c2ClientDomain}/m/`"
        :enableCopy="isEditSignupForm"
      />
    </ar-field>

    <!-- Custom Brand Color -->
    <ar-field
      class="u-margin-top-12"
      label="Subscribe to list"
      :error-messages="[veeErrors.first('list')]"
    >
      <am2-message-list-select
        v-validate="{
          required: true,
          'has-enabled-channels': {
            email: !!channelValueInScratchSignupForm('email'),
            sms: !!channelValueInScratchSignupForm('sms'),
            messenger: !!channelValueInScratchSignupForm('facebook:messenger'),
          }
        }"
        :value="scratchSignupForm.messageListOid"
        data-vv-name="list"
        data-vv-as="list"
        :channels="['sms', 'email', 'facebookMessenger']"
        @select="handleMessageListSelect"
      />
      <div
        v-if="messageListInScratchSignupForm"
        :class="[
          'channels-select-section',
          'u-margin-top-2',
        ]"
      >
        <ar-text
          size="xs"
          text="Select enabled channels for this list:"
          multiple-lines
        />
        <div
          :class="[
            'u-margin-top-3',
            'u-display-flex',
            $arMediaQuery.pageContent.maxWidth('sm') && 'u-flex-flow-column',
            $arMediaQuery.pageContent.maxWidth('sm') ? 'u-align-items-flex-start' : 'u-align-items-center',
          ]"
        >
          <ar-checkbox
            :value="channelValueInScratchSignupForm('email')"
            @input="handleChannelEnableInput('email', ...arguments)"
            ticked-value="enable"
            :disabled="!messageListInScratchSignupForm.email"
            v-tooltip="{
              content: !messageListInScratchSignupForm.email ? 'Email is not enabled for this list. Enable in List Settings.' : null,
            }"
            label="Email"
            icon-name="email"
            :icon-props="{
              width: '18px',
              color: $arStyle.color.email,
            }"
            data-test-id="signup-form-email"
          />
          <ar-checkbox
            :class="[
              $arMediaQuery.pageContent.minWidth('md') && 'u-margin-left-12',
              $arMediaQuery.pageContent.maxWidth('sm') && 'u-margin-top-2',
            ]"
            :value="channelValueInScratchSignupForm('sms')"
            @input="handleChannelEnableInput('sms', ...arguments)"
            ticked-value="enable"
            :disabled="!messageListInScratchSignupForm.sms"
            v-tooltip="{
              content: !messageListInScratchSignupForm.sms ? 'SMS is not enabled for this list. Enable in List Settings.' : null,
            }"
            label="SMS"
            icon-name="sms"
            :icon-props="{
              width: '18px',
              color: $arStyle.color.sms,
            }"
            data-test-id="signup-form-sms"
          />
        </div>
      </div>
    </ar-field>

    <!-- Custom Brand Color -->
    <ar-field
      class="u-margin-top-12"
      label="Custom color"
      description="Customize the Signup Form with your brand colors"
      :error-messages="[veeErrors.first('customColor')]"
    >
      <am2-color-input
        v-validate="'required'"
        :value="scratchSignupForm.presentation.color"
        @input="handleCustomColorInput"
        data-vv-name="customColor"
        data-vv-as="customColor"
      />
    </ar-field>

    <!-- signup form image -->
    <ar-field
      class="u-margin-top-12"
      label="Image (optional)"
      description="Add an image for your Signup Form (Recommended dimensions are 1200x628)"
      :error-messages="[veeErrors.first('image'), veeErrors.first('singupFormImageUploading')]"
    >
      <div
        :style="{
          height: (signupFormImageUrl || isUploadingSignupFormImage) ? null : '0',
          overflow: 'hidden',
        }"
      >
        <div
          v-if="isUploadingSignupFormImage"
          v-validate:isUploadingSignupFormImage="'uploadingAssets'"
          data-vv-name="singupFormImageUploading"
          data-vv-as="singup form image"
        />
        <am2-dropzone
          ref="img-dropzone"
          data-vv-name="image"
          data-vv-as="image"
          :file-type="['image/jpeg', 'image/jpg', 'image/png']"
          file-type-alias="image"
          :placeholder-icon="{
            name: 'image',
          }"
          :image-options="{
            dimensions: {
              width: 1200,
              height: 628
            }
          }"
          :ratio="0.525"
          feedback-type="image"
          :feedback="signupFormImageUrl"
          enable-ar-s3-bucket-upload
          :ar-s3-bucket-upload-options-list="[{
            assetType: 'campaign-image',
            campaignOid: scratchSignupForm.oid,
            eventOid: null,
          }]"
          @delete="handleImageDelete"
          @uploadStart="isUploadingSignupFormImage = true"
          @uploadEnd="isUploadingSignupFormImage = false"
          @upload="handleImageUpload"
        />
      </div>
      <div v-if="!signupFormImageUrl && !isUploadingSignupFormImage" class="u-display-flex">
        <ar-icon
          class="u-margin-right-2"
          name="image"
          height="21px"
          :color="$arStyle.color.blueGrey600"
        />
        <ar-link-button
          text="Upload image"
          @click="handleUploadImageClick"
        />
      </div>
    </ar-field>
  </section>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { clone } from '@/utils/helpers';

export default {
  name: 'Basic',

  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      showDescription: false,
      showHtmlSnippets: false,
      showAdvancedSettings: false,
      isUploadingSignupFormImage: false,
      urlSlugManuallyUpdated: false,
    };
  },

  computed: {
    ...mapState({
      currentSignupForm: state => state.signupForm.currentSignupForm,
      scratchSignupForm: state => state.signupForm.scratchSignupForm,
      messageListInScratchSignupForm: state => state.signupForm.messageListInScratchSignupForm,
    }),
    ...mapGetters({
      channelValueInScratchSignupForm: 'signupForm/channelValueInScratchSignupForm',
    }),
    signupFormImageUrl() {
      const imageResource = this.scratchSignupForm.resources.find(resource => resource.assetType === 'campaign-image');
      return imageResource ? imageResource.url : null;
    },
    urlSlugShouldAutoUpdate() {
      if (this.currentSignupForm && this.currentSignupForm.urlSlug) return false;
      if (this.urlSlugManuallyUpdated) return false;
      return true;
    },
    currentUrlSlug() {
      return this.currentSignupForm?.urlSlug;
    },
    isEditSignupForm() {
      return !!this.scratchSignupForm.oid;
    },
  },

  watch: {
    scratchSignupForm: {
      handler(val) {
        if (!this.scratchSignupForm) { return; }
        if (this.scratchSignupForm.presentation.description && !this.showDescription) {
          this.showDescription = true;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          messageListoid: {
            required: 'Please select a list.',
          },
        },
      },
    });
    this.$validator.extend('has-enabled-channels', {
      getMessage: () => 'You must select a channel before continuing',
      validate: (value, params) => {
        return !!params.email || !!params.sms || !!params.messenger;
      },
    });
  },

  methods: {
    ...mapMutations([
      'signupForm/PUT_SCRATCH_SIGNUP_FORM',
      'signupForm/PUT_OPT_IN_ACTION_IN_SCRATCH_SIGNUP_FORM',
      'signupForm/SET_MESSAGE_LIST_IN_SCRATCH_SIGNUP_FORM',
    ]),
    toggleAdvancedSettings() {
      this.showAdvancedSettings = !this.showAdvancedSettings;
    },

    toggleHtmlSnippets() {
      this.showHtmlSnippets = !this.showHtmlSnippets;
    },

    handleUploadImageClick() {
      this.$refs['img-dropzone'].selectFile();
    },

    autoUpdateUrlSlug(title) {
      if (!this.urlSlugShouldAutoUpdate) return;
      let urlSlug = title.replace(/[^a-zA-Z0-9 /-]/g, "").replace(/ /g, "-").toLowerCase();
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ urlSlug });
    },

    handleTitleInput(newTitle) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({
        name: newTitle,
        presentation: {
          metaTitle: newTitle,
        },
      });
      this.autoUpdateUrlSlug(newTitle);
    },

    handleMessageListSelect(messageList) {
      this['signupForm/SET_MESSAGE_LIST_IN_SCRATCH_SIGNUP_FORM'](messageList);
    },

    handleChannelEnableInput(optinType, value) {
      this['signupForm/PUT_OPT_IN_ACTION_IN_SCRATCH_SIGNUP_FORM']({
        optinType,
        value,
      });
    },

    handleHeadHtmlInput(headHtml) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { headHtml } });
    },

    handleBodyHtmlInput(bodyHtml) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { bodyHtml } });
    },

    handleDescriptionInput(newDescription) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({
        presentation: {
          description: newDescription,
          metaDescription: newDescription,
        },
      });
    },

    handleCustomColorInput(color) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { color } });
    },

    handleUrlSlugInput(urlSlug) {
      this.urlSlugManuallyUpdated = true;
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ urlSlug });
    },

    handleCustomCtaInput(customCta) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { customCta } });
    },

    handleDefaultLanguageInput(defaultLanguage) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { defaultLanguage } });
    },

    handleSuccessMessageInput(successMessage) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { successMessage } });
    },

    handleNoImageBackgroundCheck(hasBackgroundImage) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { noImageBackground: !hasBackgroundImage } });
    },

    handleImageUpload({ arS3BucketResources }) {
      this.handleImageDelete();
      const newResources = this.scratchSignupForm.resources.concat([arS3BucketResources[0]]);
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ resources: newResources });
    },

    handleImageDelete() {
      const newResources = this.scratchSignupForm.resources.filter(
        resource => resource.assetType !== 'campaign-image'
      );
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ resources: newResources });
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-form-modify-basic {
  .advanced-settings-section {
    padding: 40px;
    margin: 15px 0 0;
  }

  .channels-select-section {
    border-radius: 3px;
    padding: 20px;
    background: $skyBlueGrey300;
  }
}
</style>
