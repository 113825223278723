<template>
  <EditableFrame
    class="birthday-trigger-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    @close="$emit('close')"
  >
    <ar-text
      class="birthday-trigger-heading"
      size="13px"
      text="BIRTHDAY TRIGGER"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="birthday-trigger-copy-wrapper">
      <ar-text
      class="birthday-trigger-copy"
      size="13px"
      text="When added to an automation, steps below this trigger will begin on the day of a fan's birthday."
      weight="normal"
      multiple-lines
      letter-spacing="1px"
    />
    </div>
    </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Birthday',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions({
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleSave() {
      let contextData = {
        config: {}
      }

      this.updateTriggerContextData({
        id: this.item.id,
        data: contextData
      })
      this.$emit('close')
    },
  },
  mounted() {
    if (!this.item.config) {
      this.handleSave()
    }
  }
}
</script>
<style lang="scss" scoped>
.birthday-trigger-wrapper {
  width: 100%;
  height: 100%;

  .birthday-trigger-copy-wrapper {
    .birthday-trigger-copy {
      margin-top: 14px;
    }
  }
}
</style>