<template>
  <!-- For readonly -->
  <ar-input
    v-if="readonly"
    ref="input"
    icon-name="link"
    :icon-props="{
      color: $arStyle.color.blueGrey800,
    }"
    :value="fullLink"
    :label="enableCopy ? copyLinkLabel : null"
    :label-props="{
      weight: 'bold',
      style: { color: hasCopied ? $arStyle.color.green500 : $arStyle.color.blueGrey800, },
    }"
    readonly
    @labelClick="handleInputLabelClick"
    data-test-id="new-slug-input-readonly"
  />
  <ar-input
    v-else
    ref="input"
    :prefix="fixedPrefix"
    :value="realVal"
    @input="handleSlugChange"
    @enter="handleInputEnter"
    autocomplete="slug"
    :label="enableCopy ? copyLinkLabel : null"
    :label-props="{
      weight: 'bold',
      style: { color: hasCopied ? $arStyle.color.green500 : $arStyle.color.blueGrey800, },
    }"
    @labelClick="handleInputLabelClick"
    data-test-id="new-slug-input"
  />
</template>


<script>
import { copyToClipboard } from '@/utils/html-element';

export default {
  name: 'SlugInput',

  props: {
    value: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enableCopy: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (val) => ['medium', 'small'].indexOf(val) > -1,
    },
  },

  data() {
    return {
      realVal: this.value,
      hasCopied: false,
    }
  },

  computed: {
    fixedPrefix() {
      if (this.size === 'small') {
        return this.prefix.replace('https://', '');
      } else {
        return this.prefix;
      }
    },
    copyLinkLabel() {
      if (this.hasCopied) {
        return 'Copied';
      } else {
        return this.size === 'small' ? 'Copy' : 'Copy Link';
      }
    },
    fullLink() {
      return `${this.prefix || ''}${this.realVal || ''}`;
    },
  },

  watch: {
    value: {
      handler(val) {
        this.realVal = val;
      },
      immediate: true,
    },
    realVal() {
      this.hasCopied = false;
    },
  },

  methods: {
    focus() {
      this.$refs['input'].focus();
    },
    handleInputEnter() {
      this.$emit('enter');
    },
    handleSlugChange(val) {
      const newVal = val.toLowerCase().replace(/[^\da-z-]/gi, '-');
      this.realVal = newVal;
      this.$emit('input', newVal);
    },
    handleInputLabelClick() {
      copyToClipboard(this.fullLink);
      this.hasCopied = true;

      // Reset copy status
      setTimeout(() => {
        this.hasCopied = false;
      }, 5000);
    },
  },
}
</script>
