var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"data-list-container"},[_c('div',{ref:"invisible-modal",staticClass:"invisible-modal",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: _vm.$arStyle.zIndex.globalRegular,
      display: !_vm.noActiveIndex ? 'block' : 'none',
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleInvisibleModalClick.apply(null, arguments)}}}),_vm._v(" "),_c('am2-heading',{staticClass:"section-heading",attrs:{"type":"h1","size":"md","title":_vm.title}}),_vm._v(" "),_vm._l((_vm.items),function(item,itemIndex){return _c('ar-simple-form',{key:itemIndex,ref:"simple-form",refInFor:true,style:({
      position: 'relative',
      zIndex: !_vm.noActiveIndex ? _vm.$arStyle.zIndex.globalRegular + 1 : null,
    }),attrs:{"icon-name":item.icon,"placeholder":("Add " + (item.title)),"parsed-data":_vm.returnParsedData(item),"disabled":!item.modifiable,"opened":_vm.activeFormIndex === itemIndex},on:{"open":function($event){return _vm.handleFormOpen(itemIndex)},"submit":function($event){return _vm.handleFormSubmit(item, itemIndex)},"cancel":function($event){return _vm.handleSimpleFormCancel()}}},[(_vm.activeFormIndex === itemIndex)?_c('div',{attrs:{"slot":"fields"},slot:"fields"},_vm._l((item.inputs),function(input,itemInputIndex){return _c('div',{key:itemInputIndex,class:[
          'single-input',
          !!item.inputs[itemInputIndex + 1] && 'u-margin-bottom-1' ]},[(input.type === 'String')?_c('ar-input',{key:itemIndex + itemInputIndex + input.savedData,style:({
            height: '42px',
          }),attrs:{"type":"text","placeholder":input.placeholder,"input-font-size":"xs","id":input.name,"autocomplete":input.name},model:{value:(_vm.temporaryValues[itemIndex][input.name]),callback:function ($$v) {_vm.$set(_vm.temporaryValues[itemIndex], input.name, $$v)},expression:"temporaryValues[itemIndex][input.name]"}}):(input.type === 'Number')?_c('ar-input',{key:itemIndex + itemInputIndex + input.savedData,style:({
            height: '42px',
          }),attrs:{"type":"number","placeholder":input.placeholder,"input-font-size":"xs","id":input.name,"autocomplete":input.name},model:{value:(_vm.temporaryValues[itemIndex][input.name]),callback:function ($$v) {_vm.$set(_vm.temporaryValues[itemIndex], input.name, $$v)},expression:"temporaryValues[itemIndex][input.name]"}}):(input.type === 'Date')?_c('ar-field',{staticClass:"u-width-100-percent",attrs:{"error-messages":[_vm.veeErrors.first(input.name)]}},[_c('ar-date-input',{directives:[{name:"validate",rawName:"v-validate",value:('date-of-birth'),expression:"'date-of-birth'"}],attrs:{"data-vv-name":input.name,"data-vv-as":"date"},model:{value:(_vm.temporaryValues[itemIndex][input.name]),callback:function ($$v) {_vm.$set(_vm.temporaryValues[itemIndex], input.name, $$v)},expression:"temporaryValues[itemIndex][input.name]"}})],1):(input.type === 'Mobile')?_c('ar-field',{staticClass:"u-width-100-percent",attrs:{"error-messages":[_vm.veeErrors.first(input.name)]}},[_c('ar-mobile-input',{directives:[{name:"validate",rawName:"v-validate",value:('mobileNumber'),expression:"'mobileNumber'"}],key:itemIndex + itemInputIndex + input.savedData,attrs:{"data-vv-name":input.name,"data-vv-as":"mobile number","autocomplete":"tel-national","input-font-size":"xs","enable-clear":"","multiple-lines":""},model:{value:(_vm.temporaryValues[itemIndex][input.name]),callback:function ($$v) {_vm.$set(_vm.temporaryValues[itemIndex], input.name, $$v)},expression:"temporaryValues[itemIndex][input.name]"}})],1):(input.type === 'Select')?_c('ar-simple-select',{key:itemIndex + itemInputIndex + input.savedData,style:({
            height: '42px',
            width: '100%',
          }),attrs:{"items":input.options,"placeholder":"Select One","enable-clear":"","default-select-index":input.options.findIndex( function (item) { return item.name === _vm.temporaryValues[itemIndex][input.name]; })},on:{"select":function (selection) { _vm.modifyData(input.name, selection.name, itemIndex) },"clear":function () { _vm.modifyData(input.name, null, itemIndex) }}}):(input.type === 'Gender')?_c('ar-simple-select',{key:itemIndex + itemInputIndex + input.savedData,style:({
            height: '42px',
            width: '100%',
          }),attrs:{"items":input.options,"placeholder":"Select gender...","enable-clear":"","default-select-index":input.options.findIndex( function (item) { return item.name === _vm.temporaryValues[itemIndex][input.name]; })},on:{"select":function (selection) { selection.key !== null ? _vm.modifyData(input.name, selection.name, itemIndex) : _vm.modifyData(input.name, null, itemIndex) },"clear":function () { _vm.modifyData(input.name, null, itemIndex) }}}):(input.type === 'State' && _vm.stateShouldUseSelect)?_c('ar-state-select',{key:("state-" + (_vm.temporaryValues[itemIndex].country)),style:({
            width: '100%',
            height: '42px',
          }),attrs:{"value":_vm.temporaryValues[itemIndex][input.name],"country-iso-code":_vm.temporaryValues[itemIndex]['country'] ? _vm.temporaryValues[itemIndex]['country'] : null,"enable-clear":""},on:{"select":function (selection) { _vm.modifyData(input.name, selection.name, itemIndex ) },"clear":function () { _vm.modifyData(input.name, null, itemIndex) }}}):(input.type === 'State' && !_vm.stateShouldUseSelect)?_c('ar-input',{key:("state-" + (_vm.temporaryValues[itemIndex].country)),style:({
            height: '42px',
          }),attrs:{"type":"text","placeholder":input.placeholder,"input-font-size":"xs","id":input.name,"autocomplete":"address-level1"},model:{value:(_vm.temporaryValues[itemIndex][input.name]),callback:function ($$v) {_vm.$set(_vm.temporaryValues[itemIndex], input.name, $$v)},expression:"temporaryValues[itemIndex][input.name]"}}):(input.type === 'Country')?_c('ar-country-select',{style:({
            width: '100%',
            height: '42px',
          }),attrs:{"value":_vm.temporaryValues[itemIndex][input.name],"value-type":"iso","enable-clear":""},on:{"select":function (selection) { _vm.modifyData(input.name, selection.iso, itemIndex) },"clear":function () { _vm.modifyData(input.name, null, itemIndex) }}}):_vm._e()],1)}),0):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }