<template>
  <div
    :class="[
      'select-box',
      selected && 'active',
    ]"
    @click="handleSelectBoxClick"
  >
    <div class="img-section">
      <div
        class="image"
        :style="{
          backgroundImage: `url(\'${imgSrc}\')`,
        }"
      />
    </div>
    <div class="copies-section">
      <ar-text
        size="16px"
        :text="title"
        weight="bold"
        :style="{
          color: $arStyle.color.skyBlueGrey800,
        }"
      />
      <div
        class="description-copies-section"
        :style="{
          marginTop: '7px',
        }"
      >
        <ar-text
          class="description-copy"
          size="12px"
          :text="description"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
        <ar-text
          v-if="extraDescription"
          class="description-copy"
          size="12px"
          text="|"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
            marginLeft: '10px',
            marginRight: '10px',
          }"
        />
        <ar-text
          v-if="extraDescription"
          class="description-copy"
          size="12px"
          :text="extraDescription"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
      </div>
    </div>
    <div class="checkbox-section">
      <ar-checkbox
        :value="selected"
        :color="$arStyle.color.green500"
        type="radio"
        :style="{
          pointerEvents: 'none',
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectBox',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    extraDescription: {
      type: String,
      default: null,
    },
  },

  computed: {},

  methods: {
    handleSelectBoxClick() {
      this.$emit('click');
    },
  },

  created() {
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 80px;
  border-radius: 3px;
  border: 1px solid $blueGrey400;
  padding: 15px 20px 15px 15px;
  cursor: pointer;
  overflow: hidden;

  &.active {
    border: 1px solid $green500;
  }

  .img-section {
    flex-shrink: 0;
    .image {
      background-size: cover;
      width: 70px;
      height: 50px;
      border-radius: 3px;
      background-color: #2B214A;
    }
  }

  .copies-section {
    flex: 1 1;
    padding-left: 15px;
    padding-top: 2px;
    overflow: hidden;

    .description-copies-section {
      display: flex;
      flex-flow: row;
      align-items: center;
      overflow: hidden;

      .description-copy {
        overflow: hidden;
      }
    }
  }

  .checkbox-section {
    flex-shrink: 0;
  }
}
</style>
