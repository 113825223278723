<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="ar-update-billing-details-modal"
      :is-open="isShow"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
    >
      <div
        slot="header"
        class="header"
      >
        <ar-icon
          :name="paymentSource && paymentSource.additionalInfo ? `payment-${paymentSource.additionalInfo.card.brand}` : null"
          height="18px"
          class="u-margin-left-1 u-margin-right-2"
        />
        <ar-text
          :text="paymentSource && paymentSource.additionalInfo ? `Card ending in <b>${paymentSource.additionalInfo.card.last4}</b>` : null"
          size="sm"
          allow-html
        />
      </div>
      <div
        slot="body"
        class="body"
        :style="{
          margin: $arMediaQuery.pageContent.maxWidth('xs') ? '24px 12px' : '30px',
        }"
      >
        <ar-field
          class="field"
          label="Name on Card"
          :error-messages="[veeErrors.first('nameOnCard')]"
        >
          <ar-input
            v-model="combinedName"
            v-validate="'required'"
            auotfill="cc-name"
            data-vv-as="name on card"
            data-vv-name="nameOnCard"
            placeholder="Name on Card"
          />
        </ar-field>
        <ar-field
          class="field"
          label="Billing address"
          :error-messages="[veeErrors.first('address1')]"
        >
          <ar-input
            v-model="billingDetails.billingAddr1"
            v-validate="'required'"
            autocomplete="address-line1"
            data-vv-as="billing address"
            data-vv-name="address1"
            placeholder="Billing address 1"
          />
          <ar-input
            v-model="billingDetails.billingAddr2"
            placeholder="Billing address 2"
            autocomplete="address-line2"
            class="u-margin-top-3"
          />
        </ar-field>
        <div class="multiple-fields">
          <ar-field
            class="field"
            label="Country"
            :error-messages="[veeErrors.first('country')]"
          >
            <ar-country-select
              :value="billingDetails.billingCountry"
              @input="handleBillingCountryInput"
              v-validate="'required'"
              data-vv-as="country"
              data-vv-name="country"
              value-type="iso"
            />
          </ar-field>
          <ar-field
            class="field"
            label="Zip/post code"
            :error-messages="[veeErrors.first('postcode')]"
          >
            <ar-input
              v-model="billingDetails.billingZip"
              v-validate="'required'"
              autocomplete="postal-code"
              data-vv-as="Zip/post code"
              data-vv-name="postcode"
              placeholder="Zip/post code"
            />
          </ar-field>
        </div>
        <div class="multiple-fields">
          <ar-field
            class="field"
            label="City"
            :error-messages="[veeErrors.first('city')]"
          >
            <ar-input
              v-model="billingDetails.billingCity"
              v-validate="'required'"
              autocomplete="address-level2"
              data-vv-as="city"
              data-vv-name="city"
              placeholder="City"
            />
          </ar-field>
          <ar-field
            class="field"
            label="State"
            :error-messages="[veeErrors.first('state')]"
          >
            <ar-state-select
              v-if="hasStateSelect"
              :country-iso-code="billingDetails.billingCountry"
              v-model="billingDetails.billingState"
              v-validate="'required'"
              data-vv-name="state"
            />
            <ar-input
              v-else
              v-model="billingDetails.billingState"
              v-validate="'required'"
              autocomplete="address-level1"
              data-vv-as="state"
              data-vv-name="state"
              placeholder="State"
            />
          </ar-field>
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'footer',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-3',
        ]">
        <ar-simple-button
          class="button"
          text="Cancel"
          outlined
          @click="handleCancelClick"
        />
        <ar-simple-button
          class="button"
          text="Save"
          :loading="isUpdatingPaymentSource"
          :disabled="!paymentSource"
          @click="handleSaveClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'UpdateBillingDetailsModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    paymentSource: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      isFetchingPaymentCustomer: false,
      combinedName: null,
      billingDetails: {
        firstName: null,
        lastName: null,
        billingAddr1 : null,
        billingAddr2: null,
        billingCity: null,
        billingState: null,
        billingZip: null,
        billingCountry: null,
      },
      countriesWithStateSelect: ['au', 'us'],
    };
  },

  computed: {
    ...mapState({
      isUpdatingPaymentSource: state => state.payment.isUpdatingPaymentSource,
    }),
    hasStateSelect() {
      return this.countriesWithStateSelect.indexOf(this.billingDetails.billingCountry) > -1;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.$nextTick(() => {
          this.initializeEditBillingDetails(this.paymentSource);
        });
      }
    },
  },

  methods: {
    ...mapActions([
      'payment/UPDATE_PAYMENT_SOURCE_BILLING_DETAILS',
    ]),

    async initializeEditBillingDetails(paymentSource) {
      if (!paymentSource) return;

      const cardDetails = paymentSource.additionalInfo && paymentSource.additionalInfo.card ? paymentSource.additionalInfo.card : {};
      this.billingDetails = {
        billingAddr1 : cardDetails.billingAddr1,
        billingAddr2: cardDetails.billingAddr2,
        billingCity: cardDetails.billingCity,
        billingState: cardDetails.billingState,
        billingZip: cardDetails.billingZip,
        billingCountry: cardDetails.billingCountry && cardDetails.billingCountry.toLowerCase(),
      };
      this.combinedName = `${cardDetails.firstName} ${cardDetails.lastName}`;
    },
    handleBillingCountryInput(newCountry) {
      this.billingDetails.billingCountry = newCountry;

      // You are less likely to have same state name after you change the country
      this.billingDetails.billingState = null;
    },

    handleCancelClick() {
      this.$emit('cancel');
    },

    handleModalClose() {
      this.$emit('cancel');
    },

    async handleSaveClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const combinedNameBreak = this.combinedName.indexOf(' ');
      this.billingDetails.firstName =
        combinedNameBreak ?
          this.combinedName.substr(0, combinedNameBreak) :
          this.combinedName;

      this.billingDetails.lastName =
        combinedNameBreak && this.combinedName.length > combinedNameBreak + 1 ?
          this.combinedName.substring(combinedNameBreak + 1, this.combinedName.length) :
          null;

      const succeed = await this['payment/UPDATE_PAYMENT_SOURCE_BILLING_DETAILS']({
        oid: this.paymentSource.oid,
        changes: this.billingDetails,
      });

      if (succeed) {
        this.$emit('update');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ar-update-billing-details-modal {
  .header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body {
    margin: 30px;

    .field {
      margin-bottom: 26px;
    }

    .multiple-fields {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      .field {
        width: calc(50% - 15px);
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 33px 30px;

    .button {
      width: 120px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
</style>
