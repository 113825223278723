var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isOpen}},[_c('ar-modal',{staticClass:"ar-subscribe-facebook-pages-modal",attrs:{"is-open":_vm.isOpen,"hide-header":"","hide-footer":"","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'modal-body',
        'u-padding-y-9',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'u-padding-x-3' : 'u-padding-x-5' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"title-section"},[_c('ar-icon',_vm._b({},'ar-icon',_vm.iconProps,false)),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-7",attrs:{"size":"md","weight":"bold","text":"Add Facebook Messenger Page"}}),_vm._v(" "),_c('ar-text',{class:[
            'u-margin-top-2' ],style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":"Add your Facebook Pages to your accounts so that you can send Facebook messages to your audience","multiple-lines":"","align":"center"}})],1),_vm._v(" "),_c('div',{staticClass:"content-body u-margin-top-10"},[(_vm.isFetchingFacebookMessengerIntegration || _vm.isFetchingFacebookPages)?_c('am2-loading-section'):(!_vm.facebookMessengerIntegration)?_c('div',{staticClass:"no-integration-section"},[_c('ar-simple-button',{staticClass:"u-width-100-percent",attrs:{"type":"messenger","text":"Connect Facebook Messenger","loading":_vm.isConnectingFacebookMessenger},on:{"click":_vm.handleConnectFacebookMessengerClick}}),_vm._v(" "),_c('div',{class:[
              'description',
              'u-margin-top-4',
              'u-padding-x-5' ]},[_c('ar-icon',{attrs:{"name":"lock","height":"16px","color":_vm.$arStyle.color.skyBlueGrey700}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-2",style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xs","text":"Your credentials are encrypted & can be removed at any time","multiple-lines":""}})],1)],1):(_vm.facebookPages.length === 0)?_c('am2-no-content-section',{attrs:{"icon-name":"facebook","body":"Seems like you don't have any Facebook pages yet, please add one on <a>Facebook</a> and click refresh pages"},on:{"bodyLinkClick":_vm.handleNoFacebookPageSectionClick}}):_c('am2-card-container',{staticClass:"facebook-page-section-wrapper"},_vm._l((_vm.facebookPages),function(facebookPage,facebookPageIdx){return _c('div',{key:facebookPageIdx},[_c('div',{class:[
                'facebook-page-section',
                facebookPage.hasSubscription && 'has-subscription' ],on:{"click":function($event){return _vm.handleFacebookPageSectionClick(facebookPage)}}},[_c('div',{class:[
                  'facebook-page-title-and-tags',
                  _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('div',{staticClass:"facebook-page-left-section"},[(facebookPage.notRenewing && !_vm.$arMediaQuery.pageContent.maxWidth('xs'))?_c('ar-simple-button',{style:({
                      height: '40px',
                    }),attrs:{"text":"Renew","outlined":"","loading":_vm.undoSubscriptionCancellationStatusMap[facebookPage.paymentSubscription.oid]},on:{"click":function($event){return _vm.handleRenewSubscriptionClick(facebookPage.paymentSubscription)}}}):(facebookPage.hasSubscription)?_c('ar-checkbox',{style:({
                      pointerEvents: 'none',
                    }),attrs:{"value":true,"disabled":true}}):_c('ar-checkbox',{style:({
                      pointerEvents: 'none',
                    }),attrs:{"value":_vm.tickedFacebookPageMap[facebookPage.puid]}}),_vm._v(" "),_c('ar-avatar',{staticClass:"page-avatar u-margin-left-3",attrs:{"image-path":facebookPage.profileImageUrl,"name":facebookPage.name,"size":"30px"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-2",attrs:{"size":"xs","text":facebookPage.name,"multiple-lines":""}})],1),_vm._v(" "),_c('div',{staticClass:"facebook-page-right-section"},[(facebookPage.hasSubscription)?_c('div',{staticClass:"subscribed-tags"},[_c('am2-tag',{staticClass:"status-tag",attrs:{"type":facebookPage.notRenewing ? 'red' : 'green',"shape":"rectangle","text":facebookPage.notRenewing ? facebookPage.endsIn : 'Added'}})],1):_c('div',{staticClass:"select-tags"},[(_vm.tickedFacebookPageMap[facebookPage.puid] && !_vm.tickedFreeFacebookPageMap[facebookPage.puid])?_c('am2-tag',{staticClass:"status-tag",attrs:{"type":"purple","shape":"rectangle","text":_vm.planPriceCopy,"sideText":_vm.planPriceTagSideText}}):(_vm.tickedFacebookPageMap[facebookPage.puid] && _vm.tickedFreeFacebookPageMap[facebookPage.puid])?_c('am2-tag',{staticClass:"status-tag",attrs:{"type":"purple","shape":"rectangle","text":"Free"}}):_c('am2-tag',{staticClass:"status-tag",attrs:{"type":"white","shape":"rectangle","text":_vm.planPriceCopy,"sideText":_vm.planPriceTagSideText}})],1)])]),_vm._v(" "),(facebookPage.notRenewing && _vm.$arMediaQuery.pageContent.maxWidth('xs'))?_c('div',{staticClass:"renew-button-section"},[(facebookPage.notRenewing)?_c('ar-simple-button',{style:({
                    height: '40px',
                  }),attrs:{"text":"Renew","outlined":"","loading":_vm.undoSubscriptionCancellationStatusMap[facebookPage.paymentSubscription.oid]},on:{"click":function($event){return _vm.handleRenewSubscriptionClick(facebookPage.paymentSubscription)}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('ar-divider',{directives:[{name:"show",rawName:"v-show",value:(facebookPageIdx !== _vm.facebookPages.length - 1),expression:"facebookPageIdx !== facebookPages.length - 1"}]})],1)}),0),_vm._v(" "),(_vm.facebookMessengerIntegration)?_c('ar-link-button',{staticClass:"u-margin-top-4",attrs:{"text":"Refresh pages"},on:{"click":_vm.handleRefreshPagesClick}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"content-footer u-margin-top-10"},[_c('ar-simple-button',{class:[
            'u-width-100-percent' ],attrs:{"type":"gradient-purple","text":_vm.purchaseButtonText,"loading":_vm.isSubscribingToFacebookPages,"disabled":_vm.disablePurchaseButton},on:{"click":_vm.handlePurchaseClick}}),_vm._v(" "),_c('am2-payment-source-section',{staticClass:"u-margin-top-6",attrs:{"payment-source-puid":_vm.paymentSourcePuid,"add-payment-source-on":"expansion","readonly":_vm.hasAnyPaymentSubscription},on:{"select":_vm.handlePaymentSourceSelect}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }