var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['subscription-block', _vm.blockSize],style:({
    height: _vm.enable || _vm.blockSize === 'xs' ? 'unset' : '96px',
  }),on:{"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false},"click":_vm.subscriptionBlockClick}},[_c('div',{staticClass:"subscription-info"},[_c('div',{staticClass:"left"},[_c('ar-checkbox',{ref:"checkbox",style:({
          pointerEvents: 'none',
        }),attrs:{"value":_vm.enable,"disabled":_vm.enableCheckboxDisabled},on:{"change":_vm.handleSubscriptionToggle}}),_vm._v(" "),_c('div',{staticClass:"subscription-type"},[_c('ar-icon',{class:['icon', _vm.iconName],attrs:{"name":_vm.iconName,"width":"25px","height":"25px"}}),_vm._v(" "),_c('div',{staticClass:"identifiers"},[_c('ar-text',{attrs:{"text":_vm.channelName,"size":"xs","weight":"bold"}}),_vm._v(" "),(_vm.identifier)?_c('ar-text',{style:({
              marginTop: '7px',
              color: _vm.$arStyle.color.blueGrey700,
              maxWidth: _vm.blockSize === 'xs' ? 'calc(100% - 10px)' : '200px',
              width: _vm.blockSize === 'xs' ? '100%' : 'auto',
            }),attrs:{"text":_vm.identifier,"size":"xs"}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.contactsTooltipText,
          show: _vm.isHovered,
          trigger: 'manual',
        }),expression:"{\n          content: contactsTooltipText,\n          show: isHovered,\n          trigger: 'manual',\n        }",modifiers:{"top":true}}],ref:"right-section-tooltip-area",style:({
          height: '20px',
        })},[_c('ar-text',{staticClass:"subscribe-cta",attrs:{"size":"xxxs","text":_vm.cta}})],1),_vm._v(" "),_c('am2-gauge',{class:[
          'subscriber-gauge',
          _vm.enableAll && 'enable-all',
          !(_vm.hasSubscribeAllButton) && 'no-subscribe-all'],attrs:{"max":_vm.loading ? null : _vm.totalCount,"fill-values":_vm.gaugeValues}})],1)]),_vm._v(" "),(_vm.enable && _vm.enableAll && _vm.notSubscribedYetCount > 0)?_c('div',{staticClass:"subscribe-all-warning",on:{"click":_vm.handleUnsubcribeAll}},[_c('ar-snackbar',{attrs:{"type":"warning","message":_vm.excludeContactsMessage}})],1):(_vm.enable && !_vm.enableAll && _vm.notSubscribedYetCount > 0)?_c('div',{staticClass:"subscribe-all-warning",on:{"click":_vm.handleSubscribeAll}},[_c('ar-snackbar',{attrs:{"type":"warning","message":_vm.undoExcludeContactsMessage}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }