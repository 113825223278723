var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"activity-card-skeleton",attrs:{"layout":"soft"}},[_c('div',{staticClass:"activity-card-skeleton__main"},[_c('div',{style:({
        backgroundColor: _vm.$arStyle.color.skyBlueGrey600,
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        overflow: 'hidden',
      })}),_vm._v(" "),_c('div',{staticClass:"card-details"},[_c('div',{staticClass:"card-details__top"},[_c('am2-typography-skeleton',{staticClass:"text-skeleton",style:({
            maxWidth: '250px',
          }),attrs:{"size":"xs"}})],1),_vm._v(" "),_c('am2-typography-skeleton',{staticClass:"text-skeleton text-timeago",style:({
          maxWidth: '250px',
        }),attrs:{"size":"xs"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }