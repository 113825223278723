<template>
  <div
    class="csv-review-table-wrapper"
    :style="{
      background: bgColor,
    }"
  >
    <div
      :class="{
        ['table-shadow']: true,
        'right': displayRightTableShadow,
      }"
    />
    <div
      :class="{
        ['table-shadow']: true,
        'left': displayLeftTableShadow,
      }"
    />
    <div
      ref="table-content"
      class="table-wrapper"
    >
      <table
        ref="table"
        class="csv-review-table"
        :style="{
          marginBottom: remaningLines ? '30px' : null,
        }"
      >
        <thead>
          <tr>
            <th
              v-for="(heading, headIdx) of head"
              :key="headIdx"
              align="left"
            >
              <ar-text
                size="xs"
                :text="heading"
                weight="bold"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIdx) of displayedRows"
            :key="rowIdx"
          >
            <td
              v-for="(cell, colIdx) of displayedRows[rowIdx]"
              :key="colIdx"
              align="left"
            >
              <ar-text
                size="xs"
                :text="cell"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <ar-text
        v-if="remaningLines"
        size="xs"
        :text="`+ ${remaningLines} lines`"
        :style="{
          color: $arStyle.color.blueGrey700,
        }"
        class="remain-lines"
      />
    </div>
  </div>
</template>

<script>
import { hexToRGBA } from '@/utils/helpers';

let tableContentElemScrollLeft = 0;

export default {
  name: 'CsvReviewTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayedRowsCount: 5,
      displayRightTableShadow: true,
      displayLeftTableShadow: false,
    };
  },
  computed: {
    bgColor() {
      return `${hexToRGBA(this.$arStyle.color.skyBlueGrey400, 0.5)}`;
    },
    displayedRows() {
      return this.body.slice(0, this.displayedRowsCount);
    },
    remaningLines() {
      return this.body.length - this.displayedRows.length;
    },
  },
  mounted() {
    this.$refs['table-content'].addEventListener('wheel', this.handleTableContentOnWheel);
  },
  // Remember to clear all listeners
  beforeDestroy() {
    this.$refs['table-content'].removeEventListener('wheel', this.handleTableContentOnWheel);
  },

  methods: {
    handleTableContentOnWheel(e) {
      if (tableContentElemScrollLeft === this.$refs['table-content'].scrollLeft) {
        return;
      }
      if (this.checkIfTableShadowNeededTimeout) {
        clearTimeout(this.checkIfTableShadowNeededTimeout);
      }
      tableContentElemScrollLeft = this.$refs['table-content'].scrollLeft;
      this.checkIfTableShadowNeededTimeout = setTimeout(() => {
        this.checkIfTableShadowNeeded();
      }, 50);
    },
    checkIfTableShadowNeeded() {
      if (!this.$refs.table) {
        return;
      }
      this.displayRightTableShadow =  this.$refs.table.offsetWidth > (this.$refs['table-content'].scrollLeft + this.$refs['table-content'].offsetWidth);
      this.displayLeftTableShadow = this.$refs['table-content'].scrollLeft > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.csv-review-table-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  .table-shadow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: $zIndexHigh;

    &.right {
      right: 0;
      box-shadow: inset -15px 0 14px -16px $blueGrey800;
    }

    &.left {
      left: 0;
      box-shadow: inset 15px 0 14px -16px $blueGrey800;
    }
  }

  .table-wrapper {
    overflow: auto;
    padding: 20px;
    .csv-review-table {
      width: 100%;
      border-spacing: 0;

      thead {
        tr {
          th {
            border-bottom: 1px solid $blueGrey500;
            padding-bottom: 15px;
            padding-right: 10px;
            min-width: 150px;
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: 1px solid $blueGrey500;
            padding: 19px 10px 16px 0;
          }
        }
      }
    }
    .remain-lines {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
}
</style>
