<template>
  <am2-dropdown-binder
    :align="align"
    :pixel-shift="5"
    :vertical-pixel-shift="0"
    button-name="ar-icon-button"
    :button-props="{
      iconProps,
      color,
      hoveredColor,
    }"
    :dropdown-props="{
      items,
      arrowDistance: '16px',
      style: decoratedDropdownStyle,
      itemStyle: dropdownItemStyle,
    }"
    :divider-style="dividerStyle"
    @select="handleItemSelect"
    @toggle="handleDropdownToggle"
  />
</template>
<script>
export default {
  name: 'IconButtonDropdown',
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].indexOf(val) > -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: null,
    },
    hoveredColor: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
      default: () => ({
        name: 'menu',
      }),
    },
    dropdownStyle: {
      type: Object,
      default: () => ({}),
    },
    dropdownItemStyle: {
      type: Object,
      default: () => ({
        height: '31px',
      }),
    },
    dividerStyle: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    decoratedDropdownStyle() {
      return {
        width: '128px',
        ...this.dropdownStyle,
      };
    },
  },
  methods: {
    handleItemSelect(item) {
      this.$emit('select', item);
    },
    handleDropdownToggle(isOpen) {
      this.$emit('toggle', isOpen);
    },
  },
}
</script>

