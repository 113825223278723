<template>
  <section>
    <am2-loading-section
      v-if="isFetchingTourCampaigns"
      :style="{
        height: '100px',
      }"
    />
    <div
      v-else
      v-for="campaign in tourCampaignsOrderedByEventStartDate"
      :key="campaign.oid"
      class="list"
    >
      <am2-campaign-card
        :show-as="$arMediaQuery.pageContent.maxWidth('xs') ? 'tiles' : 'list'"
        :card-size="$arMediaQuery.pageContent.maxWidth('sm') ? 'xs' : 'regular'"
        :campaign="campaign"
        @delete="() => $emit('on-campaign-delete')"
      />
    </div>


  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { campaignBaseUri, campaignImage } from '@/utils/campaign';

export default {
  name: 'TourCampaigns',

  props: {
    tour: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    ...mapState({
      tourCampaigns: state => state.tour.tourCampaigns,
      isFetchingTourCampaigns: state => state.tour.isFetchingTourCampaigns,
    }),
    ...mapGetters({
      tourCampaignsOrderedByEventStartDate: 'tour/tourCampaignsOrderedByEventStartDate',
    })
  },

  watch: {
    tour: {
      handler(val) {
        this.fetchCampaigns(val.campaignOids);
      },
      deep: true
    },
  },

  methods: {
    ...mapActions([
      'tour/FETCH_TOUR_CAMPAIGNS',
    ]),

    getCampaignImage(oid) {
      return campaignImage(this.tourCampaigns[oid])
    },
    isCampaignActive(campaignEndDate) {
      return moment.utc(campaignEndDate).isAfter(moment.utc());
    },
    previewURL({ type, urlSlug }) {
      return `${campaignBaseUri(type)}${urlSlug}`;
    },
    goToCampaignSetup() {
      this.$emit('navbarAction', 'CREATE_CAMPAIGN');
    },
    fetchCampaigns(campaignOids) {
      this['tour/FETCH_TOUR_CAMPAIGNS'](campaignOids);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  >>> .index-card {
    margin-bottom: 10px;
  }
}
</style>
