<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-event-genius-access-token-modal"
      header="Connect To Event Genius"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-event-genius-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Username"
        >
          <ar-input
            v-model="username"
            name="username"
            placeholder="Please enter your Event Genius username"
          />
        </ar-field>
        <ar-field
          label="Password"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="password"
            name="password"
            type="password"
            placeholder="Please enter your Event Genius password"
            autocomplete="new-password"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'ask-event-genius-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: null,
      password: null,
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.username || !this.password;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.username = null;
        this.password = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', this.username, this.password);
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-event-genius-access-token-modal {
  .ask-event-genius-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-event-genius-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
