var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-card",attrs:{"data-test-id":("event-card-" + _vm.eventNameInKebabCase)},on:{"dragenter":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.handleDragLeave.apply(null, arguments)},"dragend":function($event){$event.preventDefault();return _vm.handleDragLeave.apply(null, arguments)}}},[_c('am2-card-container',{class:[
      'event-card--wrapper',
      _vm.showAs,
      _vm.layout,
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max'
    ],attrs:{"layout":_vm.layout === 'customer' ? 'soft' : 'regular'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeMenu.apply(null, arguments)}}},[(_vm.layout === 'promoter' && _vm.showAs !== 'micro-list')?_c('am2-event-dropdown',{class:['event-dropdown', _vm.showAs],attrs:{"menu-icon-color":_vm.showAs === 'tiles' ? 'white' : null,"event":_vm.event,"oid":_vm.event.oid,"name":_vm.event.name,"allow-merging":_vm.integrationIsPossible,"kind":"menu","menu-style":"secondary"},on:{"delete":_vm.handleEventDelete,"merge":_vm.handleEventMerge,"ticketSalesDelete":_vm.handleTicketSalesDelete,"posOrdersDelete":_vm.handlePosOrdersDelete}}):_vm._e(),_vm._v(" "),(_vm.dragOver)?_c('am2-dropzone',{staticClass:"dropzone",attrs:{"file-type":['text/csv'],"file-size":209715200,"file-type-alias":"CSV file","placeholder-text-template":'Drag & drop {{fileTypeAlias}} to import ticket sales data',"placeholder-icon":{
        name: 'upload',
        width: '32px',
        height: '32px',
        color: _vm.$arStyle.color.purple500,
      }},on:{"upload":_vm.handleCSVUploaded}}):[_c('nuxt-link',{staticClass:"card-link",attrs:{"to":{ path: _vm.eventCardLink },"draggable":"false"}},[(_vm.showAs === 'list')?_c('article',{staticClass:"card-list-item",class:[
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max' ]},[_c('div',{staticClass:"event-list-image-wrapper",class:[
            _vm.layout,
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[[(_vm.eventImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.eventImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-event-button-overlay"},[_c('button',{staticClass:"view-event-button"},[_vm._v("View event")])]),_vm._v(" "),(_vm.eventImage)?_c('img',{staticClass:"event-image",attrs:{"src":_vm.eventImage}}):_c('div',{staticClass:"event-tile-image-default"})],2),_vm._v(" "),_c('div',{class:[
            'card-content',
            _vm.cardSize,
            _vm.layout,
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"card-copy-wrapper"},[_c('header',{class:[ 'card-detail', _vm.layout ]},[_c('div',{staticClass:"card-detail__header",class:[
                    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
                    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max' ]},[_c('ar-text',{staticClass:"event-list-item-heading",attrs:{"size":"17px","text":_vm.event.name,"weight":"bold","multiple-lines":"","max-lines":1,"line-height":"25px"}}),_vm._v(" "),(_vm.layout === 'promoter' && !!_vm.integrations.length)?_c('div',{staticClass:"integration-icons-wrapper"},[_vm._l((_vm.integrations),function(integration,index){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: integration.tooltip }),expression:"{ content: integration.tooltip }",modifiers:{"top":true}}],key:index,staticClass:"integration-bubble-wrapper"},[_c('ar-icon',{attrs:{"name":integration.type,"height":_vm.integrationIconSizeMap[integration.type],"color":_vm.integrationIconColorMap[integration.type]}})],1)]})],2):_vm._e()],1),_vm._v(" "),(_vm.layout === 'customer')?_c('div',{staticClass:"location-and-date"},[_c('ar-text',{style:({
                        color: _vm.$arStyle.color.blueGrey700,
                      }),attrs:{"size":"xs","text":_vm.eventLocationAndDateString}})],1):_vm._e()]),_vm._v(" "),_c('div',{class:[ 'card-footer', _vm.layout ]},[(_vm.layout === 'promoter')?_c('div',{staticClass:"location-and-date"},[(_vm.event.location)?_c('div',{staticClass:"location"},[_c('ar-icon',{attrs:{"height":"16px","name":"location","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{style:({
                        color: _vm.$arStyle.color.blueGrey700,
                        marginLeft: '7px',
                        overflow: 'hidden',
                      }),attrs:{"size":"xs","text":_vm.event.location}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"date"},[_c('ar-icon',{attrs:{"height":"16px","name":"clock","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{style:({
                        color: _vm.$arStyle.color.blueGrey700,
                        marginLeft: '7px',
                      }),attrs:{"size":"xs","text":_vm.dateRange}})],1)]):_vm._e(),_vm._v(" "),(_vm.displayTicketsStats)?_c('div',{class:['tickets-and-capital', _vm.layout]},[(_vm.layout === 'promoter')?_c('div',{staticClass:"tickets"},[_c('ar-icon',{attrs:{"width":"22px","name":"ticket"}}),_vm._v(" "),_c('ar-text',{style:({
                        marginLeft: '5px',
                        color: _vm.$arStyle.color.blueGrey700,
                      }),attrs:{"size":"xs","text":((_vm.formatIntegerLocal(_vm.eventTotalTicketSold)) + " " + (_vm.eventTotalTicketSold === 1 ? 'ticket' : 'tickets'))}})],1):_vm._e(),_vm._v(" "),(_vm.layout === 'customer')?_c('am2-tag',{staticClass:"customer-list-item-tag",style:({
                      padding: "0 15px 0 10px",
                    }),attrs:{"type":"purple","text":((_vm.formatIntegerLocal(_vm.eventTotalTicketSold)) + " " + (_vm.eventTotalTicketSold === 1 ? 'ticket' : 'tickets')),"icon-name":"ticket","icon-props":{
                      width: '20px',
                    }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"event-sales-copy-wrapper"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({ content: _vm.getTicketSalesTooltipCopy }),expression:"{ content: getTicketSalesTooltipCopy }",modifiers:{"bottom":true}}],staticClass:"u-display-flex u-align-items-flex-end"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.getDollars}}),_vm._v(" "),_c('ar-text',{staticClass:"event-cents",attrs:{"size":"10px","text":_vm.getCents}})],1)])],1):_vm._e()])])])]):(_vm.showAs === 'micro-list')?_c('article',{class:[
                   'card-micro-list-item',
                    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
                    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max' ]},[_c('div',{staticClass:"row-item u-padding-y-5 u-padding-x-2",attrs:{"data-test-id":("micro-event-card-" + _vm.eventNameInKebabCase)},on:{"click":function($event){return _vm.handleSingleEventClick(_vm.event.oid)}}},[_c('div',{staticClass:"image-container"},[_c('div',{staticClass:"image-wrapper",class:[
                      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
                      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[(_vm.event.image)?_c('img',{staticClass:"item-image",attrs:{"src":_vm.event.image}}):_c('div',{staticClass:"image-default"})])]),_vm._v(" "),_c('div',{staticClass:"data-container"},[_c('ar-text',{staticClass:"u-margin-bottom-2",attrs:{"size":"sm","weight":"bold","text":_vm.event.name}}),_vm._v(" "),_c('ar-text',{style:({
                    color: _vm.$arStyle.color.skyBlueGrey700,
                  }),attrs:{"size":"sm","weight":"normal","text":_vm.event.subtitle}})],1),_vm._v(" "),_c('div',{staticClass:"stats-container"},[_c('ar-text',{staticClass:"u-margin-bottom-2",attrs:{"size":"sm","weight":"bold","text":_vm.event.ticketSales}}),_vm._v(" "),_c('ar-text',{style:({
                    color: _vm.$arStyle.color.skyBlueGrey700,
                  }),attrs:{"size":"sm","weight":"normal","text":_vm.event.ticketQuantity}})],1)])]):_c('article',{staticClass:"card-tile",on:{"mouseover":function($event){return _vm.showViewEventButton(true)},"mouseleave":function($event){return _vm.showViewEventButton(false)}}},[_c('div',{class:[
            'card-image',
            _vm.serverActionOccuring && 'is-syncing' ].concat( _vm.tileImageLoaded && 'image-loaded',
            [_vm.layout]
          )},[_c('div',{staticClass:"event-tile-image-wrapper"},[[(_vm.eventImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.eventImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-event-button-overlay",class:_vm.viewEventCSS},[_c('button',{staticClass:"view-event-button"},[_vm._v("View event")])]),_vm._v(" "),(_vm.eventImage)?_c('img',{staticClass:"event-image",attrs:{"src":_vm.eventImage}}):_c('div',{staticClass:"event-tile-image-default"})],2),_vm._v(" "),(_vm.serverActionOccuring)?_c('div',{staticClass:"server-action-overlay"},[_c('am2-loading-bubble',{staticClass:"sync-bubbles"}),_vm._v("\n              "+_vm._s(((_vm.isCurrentlySyncing ? 'Syncing' : 'Merging') + "..."))+"\n            ")],1):_vm._e()]),_vm._v(" "),_c('div',{class:[
              'card-content',
              _vm.cardSize,
              _vm.layout ]},[_c('div',{staticClass:"card-copy-wrapper"},[(_vm.layout === 'promoter' && !!_vm.integrations.length)?_c('div',{staticClass:"integration-icons-wrapper"},[_vm._l((_vm.integrations),function(integration,index){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: integration.tooltip }),expression:"{ content: integration.tooltip }",modifiers:{"top":true}}],key:index,staticClass:"integration-bubble-wrapper"},[_c('ar-icon',{attrs:{"name":integration.type,"height":_vm.integrationIconSizeMap[integration.type],"color":_vm.integrationIconColorMap[integration.type]}})],1)]})],2):_vm._e(),_vm._v(" "),_c('header',{class:['card-detail', _vm.layout]},[_c('ar-text',{style:({
                    marginBottom: '7px',
                  }),attrs:{"size":"20px","text":_vm.event.name,"multiple-lines":"","max-lines":1,"line-height":"25px"}}),_vm._v(" "),_c('p',{staticClass:"event-location-text",style:({
                    wordBreak: 'break-word',
                  })},[_vm._v(_vm._s(_vm.eventLocationAndDateString))])],1),_vm._v(" "),(_vm.displayTicketsStats)?_c('div',{class:['card-footer', _vm.layout]},[(_vm.layout === 'promoter')?_c('div',{staticClass:"tickets"},[_c('ar-icon',{attrs:{"width":"22px","name":"ticket"}}),_vm._v(" "),_c('ar-text',{style:({
                      marginLeft: '5px',
                      color: _vm.$arStyle.color.blueGrey700,
                    }),attrs:{"size":"xs","text":((_vm.formatIntegerLocal(_vm.eventTotalTicketSold)) + " tickets")}})],1):_vm._e(),_vm._v(" "),(_vm.layout === 'customer')?_c('am2-tag',{attrs:{"type":"purple","shape":"round","text":((_vm.formatIntegerLocal(_vm.eventTotalTicketSold)) + " tickets"),"icon-name":"ticket","icon-props":{
                    width: '20px',
                  }}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: _vm.getTicketSalesTooltipCopy }),expression:"{ content: getTicketSalesTooltipCopy }",modifiers:{"top":true}}],staticClass:"event-sales-copy-wrapper"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.getDollars}}),_vm._v(" "),_c('ar-text',{staticClass:"event-cents",attrs:{"size":"10px","text":_vm.getCents}})],1)],1):_vm._e()])])])])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }