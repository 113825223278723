<template>
  <section class="dropdown-binder" @keyup.esc="toggleDropdown(false)" tabindex="0" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keyup.enter="handleItemEnter">
    <div class="u-display-flex u-align-items-center">
      <!-- <component
        ref="binded-component"
        :is="buttonName"
        v-bind="buttonPropsDecorated"
        @click.native="handleButtonClick"
      /> -->

      <!-- 
        :type="isOpen ? 'purple' : 'grey'"
        
            @click="showFilterPrompt"-->
      <ar-simple-button
        icon-name="filter-alt"
        side-length="40px"
        :icon-props="{
          height: '16px',
        }"
        text="Filter"
        :type="isOpen ? 'purple' : 'grey'"
        outlined
        class="filter-btn"
        @click.native="handleButtonClick"
        textWeight="normal"
        iconDistance="10px"
        :style="{
          marginRight: '6px',
        }"
      />

    </div>
    <!-- Tricky part, we're gonna append this to body level to avoid annoying z-index stuff -->
    <!-- Also, invisible-modal is to prevent scrolling -->
    <div
      ref="invisible-modal"
      :style="{
        position: 'fixed',
        top: '0',
        right: '0',
        width: '100vw',
        height: '100vh',
        zIndex: this.$arStyle.zIndex.globalHigh,
        display: isOpen ? 'block' : 'none',
      }"
      @click.stop.prevent="handleInvisibleModalClick"
    />
    <!-- 
        height: isOpen ? dialogBoxPosition.height : '0', -->

        <!-- 
        opacity: isOpen ? '1' : '0', -->
        <!-- 
        display: 'inline-block', -->
    <div
      ref="dropdown-wrapper"
      :style="{
        display: isOpen ? 'inline-block' : 'none',
        position: 'fixed',
        top: dialogBoxPosition.top,
        left: dialogBoxPosition.left,
        right: dialogBoxPosition.right,
        bottom: dialogBoxPosition.bottom,
        overflow: 'hidden',
        zIndex: this.$arStyle.zIndex.globalHigh,
      }"
    >
    <!-- 
        :hasFixedFooter="hasFixedFooter"
        @fixedFooterClick="() => { $emit('fixedFooterClick') }" -->
      <FilterDropdown
        ref="dropdown"
        v-model="arrowCounter"
        v-bind="dropdownPropsDecorated"
        :is-open="isOpen"
        :style="dropdownStyle"
        @select="handleItemSelect"
        @tabSelect="handleTabSelect"
        @searchStringChange="handleSearchStringChange"
        @controlClick="handleControlClick"
        @close="toggleDropdown(false)"
        @submit="handleSubmit"
      />
    </div>
  </section>
</template>

<script>
import { isClickOutside } from '@/utils/html-element/';
import FilterDropdown from './FilterDropdown';

export default {
  name: 'FilterDropdownBinder',
  components: {
    FilterDropdown,
  },
  props: {
    align: {
      type: String,
      default: 'right'
    },
    pixelShift: {
      type: Number,
      default: 5,
    },
    verticalPixelShift: {
      type: Number,
      default: 5,
    },
    buttonName: {
      type: String,
      default: 'simple-button',
    },
    buttonProps: {
      type: Object,
      default: () => ({
        text: 'have your props'
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownProps: {
      type: Object,
      default: () => ({
        maxHeight: '300px',
        hasFilter: false,
        items: [],
      }),
    },
    hasFixedFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      dialogBoxPosition: {},
      lockScrollY: null,
      verticalAlign: 'bottom',
      arrowCounter: -1,
    };
  },
  computed: {
    buttonPropsDecorated() {
      return {
        ...this.buttonProps,
        disabled: this.disabled,
        active: this.isOpen,
      };
    },
    dialogArrowAlign() {
      return `${this.verticalAlign}-${this.align}`;
    },
    dropdownPropsDecorated() {
      return {
        ...this.dropdownProps,
        loading: this.loading,
        arrowAlign: this.dialogArrowAlign,
      }
    },
    dropdownStyle() {
      return {
        ...this.dropdownProps.style,
        margin: this.dialogBoxPosition.padding,
        boxShadow: '1px 2px 8px 1px rgba(0, 0, 0, .07)',
      }
    },
  },
  watch: {
    loading() {
      setTimeout(this.updateDropdownPosition, 30);
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize);
  },
  mounted() {
    this.attachSelectBoxToBody();
    this.updateDropdownPosition();
  },
  beforeDestroy() {
    this.removeSelectBoxFromBody();
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleItemEnter() {
      this.$emit('select', this.dropdownProps.items[this.arrowCounter])
      this.toggleDropdown(false)
    },
    onArrowDown (e) {
      e.preventDefault()
            
      if (this.arrowCounter < this.dropdownProps.items.length - 1) {
        this.arrowCounter++
      } else if (this.arrowCounter === this.dropdownProps.items.length - 1) {
        this.arrowCounter = 0
      }
    },
    onArrowUp (e) {
      e.preventDefault()
      if (this.arrowCounter < 0) {
        this.arrowCounter = this.dropdownProps.items.length - 1
      } else if (this.arrowCounter > 0) {
        this.arrowCounter--
      } else if (this.arrowCounter === 0) {
        this.arrowCounter = this.dropdownProps.items.length - 1
      }
    },
    // Just attach the dialog to body level, remove it when we don't need it anymore
    attachSelectBoxToBody() {
      document.body.appendChild(this.$refs['invisible-modal']);
      document.body.appendChild(this.$refs['dropdown-wrapper']);
    },
    removeSelectBoxFromBody() {
      document.body.removeChild(this.$refs['invisible-modal']);
      document.body.removeChild(this.$refs['dropdown-wrapper']);
    },
    getDropdownElem() {
      return this.$refs['dropdown'];
    },
    updateDropdownPosition() {
      if (!this.getDropdownElem()) {
        return;
      }
      const DropdownHt = this.getDropdownElem().$el.offsetHeight;
      const elementHt = this.$el.offsetHeight;
      const { top, left, right, bottom } = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      let fixedLeft;
      let fixedRight;
      const padding = 5;

      if (this.align === 'left') {
        fixedRight = `${windowWidth - right - padding - this.pixelShift}px`;
      } else {
        fixedLeft = `${left - padding - this.pixelShift}px`;
      }
      if (top + elementHt + DropdownHt + 10 > windowHeight) {
        this.verticalAlign = 'top';
        this.dialogBoxPosition = {
          bottom: `${windowHeight - top + this.verticalPixelShift}px`,
          left: fixedLeft,
          right: fixedRight,
          height: `${DropdownHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      } else {
        this.verticalAlign = 'bottom';
        this.dialogBoxPosition = {
          top: `${top + elementHt + this.verticalPixelShift}px`,
          left: fixedLeft,
          right: fixedRight,
          height: `${DropdownHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      }
    },
    toggleDropdown(val) {
      this.isOpen = val;
      if (val) {
        this.updateDropdownPosition();

        // Lock scroll
        this.lockScrollY = window.scrollY;
        window.addEventListener('scroll', this.handleScroll);

        this.getDropdownElem().resetScroll();
      } else {
        // Reset filterString in case it exists
        this.getDropdownElem().filterString = '';
        window.removeEventListener('scroll', this.handleScroll);
      }

      this.$emit('toggle', val);
      this.arrowCounter = -1
    },
    handleButtonClick(e) {
      // That is the end of the click, we don't want to propagate it to parents
      e.stopPropagation();
      if (this.disabled) {
        return;
      }
      this.toggleDropdown(!this.isOpen);
    },
    handleTabSelect(tab) {
      this.$emit('tabSelect', tab);
      setTimeout(this.updateDropdownPosition, 30);
    },
    handleItemSelect(item) {
      this.$emit('select', item);
      this.toggleDropdown(false);
    },
    handleControlClick(item, control) {
      this.$emit('controlClick', item, control);
    },
    handleSearchStringChange(val) {
      this.$emit('searchStringChange', val);
      setTimeout(this.updateDropdownPosition, 30);
    },
    handleInvisibleModalClick(event) {
      this.toggleDropdown(false);
    },
    handleWindowResize() {
      this.updateDropdownPosition();
    },
    handleScroll(event) {
      event.preventDefault();
      window.scrollTo(window.scrollX, this.lockScrollY);
      event.stopPropagation();
    },
    handleSubmit(dateFilterIndex) {
      this.$emit('submit', dateFilterIndex)
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-binder {
  position: relative;
  display: inline-block;
  outline: none;
}
</style>
