var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"color-input-wrapper"},[_c('div',{staticClass:"color-input",on:{"click":_vm.handleColorPickerClick}},[_c('div',{staticClass:"color-square",style:({
        background: _vm.realValue,
      })}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-4",style:({
        color: _vm.$arStyle.color.skyBlueGrey800,
      }),attrs:{"size":"xs","text":_vm.placeholder}}),_vm._v(" "),_c('ar-icon',{staticClass:"u-margin-left-4",attrs:{"name":"color-picker","color":_vm.$arStyle.color.blueGrey700}})],1),_vm._v(" "),_c('div',{ref:"invisible-modal",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: this.$arStyle.zIndex.globalHighest,
      display: _vm.isColorPickerOpen ? 'block' : 'none',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleInvisibleModalClick.apply(null, arguments)}}}),_vm._v(" "),_c('div',{ref:"third-party-color-picker-wrapper",style:({
      display: 'inline-block',
      position: 'fixed',
      top: _vm.thirdPartyColorPickerWrapperPosition.top,
      bottom: _vm.thirdPartyColorPickerWrapperPosition.bottom,
      left: _vm.thirdPartyColorPickerWrapperPosition.left,
      width: _vm.thirdPartyColorPickerWrapperPosition.width,
      height: _vm.isColorPickerOpen ? _vm.thirdPartyColorPickerWrapperPosition.height : '0',
      opacity: _vm.isColorPickerOpen ? '1' : '0.7',
      overflow: 'hidden',
      transition: 'height 0.2s, opacity 0.2s',
      zIndex: this.$arStyle.zIndex.globalHighest + 1,
    })},[_c('Sketch',{ref:"third-party-color-picker",style:({
        position: 'relative',
        display: 'inline-block',
        margin: _vm.thirdPartyColorPickerWrapperPosition.padding,
      }),attrs:{"disable-alpha":"","value":_vm.realValue},on:{"input":_vm.handleColorChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }