import { render, staticRenderFns } from "./connect-card.vue?vue&type=template&id=dcb3221e&scoped=true&"
import script from "./connect-card.vue?vue&type=script&lang=js&"
export * from "./connect-card.vue?vue&type=script&lang=js&"
import style0 from "./connect-card.vue?vue&type=style&index=0&id=dcb3221e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcb3221e",
  null
  
)

export default component.exports