<template>
  <div
    :class="[
      'audience-page-menu-wrapper',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    ]"
  >
    <am2-multi-select-modal
      v-if="columnSettings"
      title="Edit columns"
      :is-show="showColumnSwitchModal"
      :items="columnSettings.availableColumns"
      :value="columnSettings.selectedColumnKeys"
      @input="handleMultiSelectModalConfirm"
      @close="showColumnSwitchModal = false"
    />
    <section>
      <!-- <ar-text
        v-if="tickedFansCount === 0 && !hideFansCountCopy && initialLoadFinished"
        size="sm"
        allow-html
        :text="fansCountCopy"
      /> -->
      <!-- <am2-typography-skeleton
        v-else-if="tickedFansCount === 0 && loading"
        size="sm"
        :style="{
          width: '124px',
          marginTop: '12px',
          marginBottom: '12px',
        }"
      /> -->
      <section
        v-if="tickedFansCount !== 0 && !hideFansCountCopy"
        class="audience-page-menu-caption"
      >
        <ar-text
          size="sm"
          :text="`${tickedFansCountText} ${fanAppellation}${tickedFansCount === 1 ? '' : 's'} selected`"
          weight="bold"
        />
        <section v-if="selectClear" class="clear-text-section">
          <ar-text
            size="sm"
            text="("
          />
          <ar-link-button
            text="clear selection"
            :style="{
              position: 'relative',
            }"
            :has-underline="false"
            has-underline-on-hover
            @click="handleClearSelectionClick"
          />
          <ar-text
            size="sm"
            text=")"
          />
        </section>
      </section>
    </section>
    <div
      :class="[
        'audience-page-menu-function-buttons-wrapper',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <div
        v-if="$arMediaQuery.pageContent.minWidth('md')"
        :style="{
          marginBottom: tickedFansCount !== 0 ? '-10px' : null
        }"
      >
        <ar-simple-button
          v-if="tickedFansCount !== 0 && addFansToMessageList"
          transparent
          text="Add to list"
          :style="{
            height: '40px',
            margin: '0 0 10px 10px',
          }"
          @click="handleAddFanToMessageList"
          v-ar-feature-mask="{
          mode: 'transparent',
          show: !isFeatureEnabled(['messages']),
          title: messagesFeatureLockedCopy.title,
          message: messagesFeatureLockedCopy.message,
        }"
        />
        <am2-simple-button-dropdown
          v-if="tickedFansCount !== 0 && massEditFanTag"
          align="right"
          :button-props="{
            text: 'Tags',
            transparent: true,
            style: { height: '40px', margin: '0 0 10px 10px' }
          }"
          :items="[
            {
              key: 'add',
              name: 'Add tags',
            },
            {
              key: 'remove',
              name: 'Remove tags',
            },
          ]"
          :dropdown-style="{
            width: '120px',
          }"
          @select="handleTagOption"
        />
        <ar-simple-button
          v-if="tickedFansCount !== 0 && massFansEdit"
          transparent
          text="Edit"
          :style="{
          height: '40px',
          margin: '0 0 10px 10px',
        }"
          @click="handleFanEditClick"
        />
        <ar-simple-button
          v-if="tickedFansCount !== 0 && massFansDelete"
          transparent
          text="Delete"
          :style="{
          height: '40px',
          margin: '0 0 10px 10px',
        }"
          @click="handleFanDeleteClick"
        />
        <!-- <am2-search
          v-if="fansSearch"
          :style="{
          width: '300px',
          height: '40px',
        }"
          :placeholder="`Search ${fansCountText} ${fanAppellation}s`"
          v-model="searchText"
          @enter="handleFansSearchEnter"
          @clear="handleFansSearchClear"
        /> -->
      </div>

      <ar-simple-button
        v-if="$arMediaQuery.pageContent.maxWidth('sm') && tickedFansCount !== 0"
        icon-name="edit"
        :text="optionsPaneVisible ? 'Hide Options' : 'Show Options'"
        :style="{
          marginLeft: '10px',
          height: '40px',
        }"
        type="grey"
        @click="handleOptionsClick"
      />

      <ar-simple-button
        v-if="importContacts"
        transparent
        text="Import contacts"
        :style="{
          marginLeft: '10px',
          height: '40px',
        }"
        @click="handleImportClick"
      />


      <ar-simple-button
        v-if="csvExport && fansCount && fansCount > 0 && csvExport.expanded"
        type="grey"
        icon-name="export"
        text="Export"
        :style="{
          marginLeft: '10px',
          maxHeight: '40px',
        }"
        :icon-props="{
          width: '20px',
          height: '20px',
        }"
        sideLength="40px"
        :disabled="csvExport.disabled"
        v-tooltip.top="{
          content: csvExport.disabled ? 'Upgrade your plan to export data.' : null
        }"
        @click="handleExportClick"
      />
      <am2-expand-icon-button
        v-else-if="csvExport && fansCount && fansCount > 0 && !csvExport.expanded"
        icon-name="export"
        text="Export"
        :style="{
          marginLeft: '10px',
          maxHeight: '40px',
        }"
        sideLength="40px"
        :disabled="csvExport.disabled"
        v-tooltip.top="{
          content: csvExport.disabled ? 'Upgrade your plan to export data.' : null
        }"
        @click="handleExportClick"
      />

      <am2-expand-icon-button
        v-if="genericSettingsButton"
        icon-name="settings"
        text="Settings"
        :style="{
          marginLeft: '10px',
          maxHeight: '40px',
        }"
        sideLength="40px"
        @click="handleGenericSettingsClick"
      />
      <am2-expand-icon-button
        v-else-if="columnSettings"
        icon-name="settings"
        text="Settings"
        :style="{
          marginLeft: '10px',
          maxHeight: '40px',
        }"
        :disabled="columnSettings.disabled"
        v-tooltip.top="{
          content: columnSettings.disabled ? 'Upgrade your plan to edit columns.' : null
        }"
        sideLength="40px"
        @click="handleSettingsClick"
      />
    </div>
    <div
      v-if="$arMediaQuery.pageContent.maxWidth('sm')"
      :class="[
        'dropdown-pane',
        optionsPaneVisible && 'is-visible',
      ]"
    >
      <ar-simple-button
        v-if="tickedFansCount !== 0 && addFansToMessageList"
        transparent
        text="Add to list"
        :style="{
          height: '40px',
          fontSize: '12px',
          margin: '10px 0 0 10px',
        }"
        @click="handleAddFanToMessageList"
        v-ar-feature-mask="{
          mode: 'transparent',
          show: !isFeatureEnabled(['messages']),
          title: messagesFeatureLockedCopy.title,
          message: messagesFeatureLockedCopy.message,
        }"
      />
      <am2-simple-button-dropdown
        v-if="tickedFansCount !== 0 && massEditFanTag"
        align="right"
        :button-props="{
          text: 'Tags',
          transparent: true,
          style: { height: '40px', margin: '10px 0 0 10px' }
        }"
        :items="[
          {
            key: 'add',
            name: 'Add tags',
          },
          {
            key: 'remove',
            name: 'Remove tags',
          },
        ]"
        :dropdown-style="{
          width: '120px',
        }"
        @select="handleTagOption"
      />
      <ar-simple-button
        v-if="tickedFansCount !== 0 && massFansEdit"
        transparent
        text="Edit"
        :style="{
          height: '40px',
          margin: '10px 0 0 10px',
        }"
        @click="handleFanEditClick"
      />
      <ar-simple-button
        v-if="tickedFansCount !== 0 && massFansDelete"
        transparent
        text="Delete"
        :style="{
          height: '40px',
          margin: '10px 0 0 10px',
        }"
        @click="handleFanDeleteClick"
      />
      <!-- <am2-search
        v-if="fansSearch"
        :style="{
          width: '300px',
          height: '40px',
          margin: '10px 0 0 10px'
        }"
        :placeholder="`Search ${fansCountText} ${fanAppellation}s`"
        v-model="searchText"
        @enter="handleFansSearchEnter"
        @clear="handleFansSearchClear"
      /> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import accounting from 'accounting';
import { clone } from '@/utils/helpers/';
import { generateFeatureLockerTitle, generateFeatureLockerMessage } from '@/utils/feature/';


export default {
  name: 'OrdersControlSection',
  props: {
    fanFilter: {
      type: Object,
      default: null,
    },
    fanAppellation: {
      type: String,
      default: 'contact',
    },
    fansCount: { // Necessary
      type: Number,
      default: 0,
      required: true,
    },
    fansCountCopyFormat: { // Necessary
      type: String,
      default: '<b>{fansCount}</b> {fanAppellation}s',
    },
    tickedFansCount: {
      type: Number,
      default: 0,
    },
    isTickAll: {
      type: Boolean,
      default: false,
    },
    genericSettingsButton: {
      type: Boolean,
      default: false,
    },
    importContacts: {
      type: Object,
      default: () => null,
    },
    selectClear: {
      type: Object,
      default: () => null,
    },
    addFansToMessageList: {
      type: Object,
      default: () => null,
    },
    massFansEdit: {
      type: Object,
      default: () => null,
    },
    massFansDelete: {
      type: Object,
      default: () => null,
    },
    massEditFanTag: {
      type: Object,
      default: () => null,
    },
    columnSettings: {
      type: Object,
      default: () => null,
    },
    fansSearch: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    csvExport: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    messagesFeatureLockedCopy: {
      title: generateFeatureLockerTitle('Lists'),
      message: generateFeatureLockerMessage('Lists'),
    },
    enableMessage: process.env.arEnableFbMessenger,
    isFacebookMessengerFeatureEnabled: false,
    showColumnSwitchModal: false,
    searchText: null,
    optionsPaneVisible: false,
    initialLoadFinished: false, // Will be permanently set to true once the loading prop is set to true
  }),
  watch: {
    loading: {
      handler(val) {
        if (val === false) this.initialLoadFinished = true;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    hideFansCountCopy() {
      if (this.loading && !this.initialLoadFinished) return true;
      // If audience feature is disabled and you have fan filter
      return !this.isFeatureEnabled(['audience']) && !!this.fanFilter;
    },
    fansCountText() {
      return accounting.formatNumber(this.fansCount);
    },
    fansCountCopy() {
      return this.fansCountCopyFormat.replace('{fansCount}', this.fansCountText).replace('{fanAppellation}', this.fanAppellation);
    },
    tickedFansCountText() {
      return accounting.formatNumber(this.isTickAll ? this.fansCount : this.tickedFansCount);
    },
  },
  methods: {
    handleImportClick() {
      this.importContacts.action();
    },
    handleClearSelectionClick() {
      this.optionsPaneVisible = false;
      this.selectClear.action();
    },
    handleAddFanToMessageList() {
      this.addFansToMessageList.action();
    },

    handleFanEditClick() {
      this.massFansEdit.action();
    },

    handleFanDeleteClick() {
      this.massFansDelete.action();
    },

    handleTagOption(item) {
      this.massEditFanTag.action(item.key);
    },

    handleFansSearchEnter() {
      this.optionsPaneVisible = false;
      this.fansSearch.action(this.searchText);
    },

    handleFansSearchClear() {
      this.fansSearch.action('');
    },

    handleExportClick() {
      this.csvExport.action();
    },
    handleGenericSettingsClick() {
      this.$emit('settingsClick');
    },
    handleSettingsClick() {
      this.showColumnSwitchModal = true;
    },
    handleMultiSelectModalConfirm(selectedColumnKeys) {
      this.showColumnSwitchModal = false;
      this.columnSettings.action(selectedColumnKeys);
    },
    handleOptionsClick() {
      this.$emit('optionsPaneToggle', !this.optionsPaneVisible);
      this.optionsPaneVisible = !this.optionsPaneVisible;
    },
  }
};
</script>

<style lang="scss" scoped>
.audience-page-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.sm-max {
    flex-direction:column;
    align-items: flex-start;

    .audience-page-menu-caption,
    >.ar-typography {
      margin: 0 0 16px 4px;
    }
  }

  .dropdown-pane {
    overflow:hidden;
    transition: 0.2s;
    display: flex;
    width: 100%;
    margin-top: 5px;
    justify-content: flex-end;
    flex-wrap: wrap;
    opacity: 0;
    max-height: 0;

    &.is-visible {
      max-height: 100px;
      opacity:1;
    }
  }

  .audience-page-menu-caption {
    display: flex;

    .clear-text-section {
      display: flex;
      margin-left: 4px;
    }
  }

  .audience-page-menu-function-buttons-wrapper {
    display: flex;
    grid-column-gap: 10px;

    &.sm-max {
      align-self: flex-end;
    }
  }
}
</style>
