var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ar-dropzone"},[(_vm.imageOptions.dimensions)?_c('am2-resize-image-modal',{attrs:{"is-show":_vm.displayResizeImageModal,"target-dimensions":_vm.imageOptions.dimensions,"file":_vm.tempFile},on:{"resize":_vm.handleImageResize,"cancel":_vm.handleResizeImageCancel}}):_vm._e(),_vm._v(" "),(_vm.feedback)?_c('div',{class:[
      'setting-dropdown-wrapper',
      _vm.loading && 'disabled' ]},[_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
        name: 'edit',
      },"color":"white","hovered-color":"white","items":_vm.actions,"dropdown-style":{
        width: '155px',
      }},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"dropzone",class:[
      'content-section',
      _vm.errorMsg && 'has-error',
      _vm.dragOver && 'is-dragover',
      (_vm.feedbackType + "-feedback"),
      _vm.feedback && 'is-complete',
      _vm.loading && 'disabled' ],style:({
      height: _vm.dropzoneHeight,
    }),on:{"dragover":function($event){$event.preventDefault();_vm.dragOver = true},"dragenter":function($event){$event.preventDefault();_vm.dragOver = true},"dragleave":function($event){$event.preventDefault();_vm.dragOver = false},"dragend":function($event){$event.preventDefault();_vm.dragOver = false},"drop":function($event){$event.preventDefault();return _vm.chooseFile.apply(null, arguments)},"click":_vm.handleDropzoneClick}},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileBtn",attrs:{"for":'dzone_'+_vm._uid}}),_vm._v(" "),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileCtrl",attrs:{"id":'dzone_'+_vm._uid,"accept":_vm.fileTypeInclusive,"type":"file"},on:{"change":_vm.chooseFile,"focus":_vm.resetFocus}}),_vm._v(" "),(_vm.loading)?[_c('am2-loading-spinner',{attrs:{"size":"48px"}}),_vm._v(" "),(_vm.isProcessingCsvFile)?_c('ar-text',{staticClass:"u-margin-top-3",style:({
          color: _vm.$arStyle.color.skyBlueGrey800,
        }),attrs:{"size":"xs","text":("Processing CSV file (" + _vm.processCsvFileProgress + "%)"),"weight":"bold"}}):_vm._e()]:(_vm.feedback)?[(_vm.feedbackType === 'default')?_c('div',{staticClass:"feedback-section default"},[(!_vm.errorMsg)?_c('div',{staticClass:"tick-container"},[_c('ar-icon',{attrs:{"name":"check","height":"12px","color":"white"}})],1):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"text u-margin-top-2",attrs:{"size":"xs","text":_vm.feedback,"allow-html":""}})],1):(_vm.feedbackType === 'image')?_c('div',{staticClass:"feedback-section image",style:({
          backgroundImage: _vm.feedback ? ("url('" + _vm.feedback + "')") : null,
          border: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
        })}):_vm._e()]:[_c('div',{staticClass:"placeholder-section",attrs:{"data-test-id":"csv-upload-trigger"}},[_c('ar-icon',_vm._b({},'ar-icon',_vm.decoratedPlaceholderIcon,false)),_vm._v(" "),_c('ar-text',{staticClass:"placholder-text",attrs:{"size":"xs","text":_vm.placeholderText,"allow-html":"","multiple-lines":"","line-height":"25px","align":"center"}})],1)]],2),_vm._v(" "),(_vm.errorMsg)?_c('ar-state-message',{attrs:{"type":"error","text":_vm.errorMsg}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }