<template>
  <div class="ar-stripe-element-form">
    <form
      class="billing-form"
      @submit="handleBillingDetailsForm"
    >
      <div>
        <div
          class="card-info-input-set"
        >
          <ar-input
            v-model="additionalOwnerInfo.name"
            placeholder="Name on card"
            v-validate="'required'"
            data-vv-name="ownerName"
            autocomplete="cc-name"
            :has-error="veeErrors.has('ownerName')"
          />
        </div>
        <ar-state-message
          v-if="veeErrors.has('ownerName')"
          :text="veeErrors.first('ownerName')"
          type="error"
          :style="{
            marginTop: '8px'
          }"
        />
        <div
          class="card-info-input-set"
          :style="{
            marginTop: '20px',
          }"
        >
          <div
            v-show="stripeElementLoadingMap.cardNumber"
            class="card-info-input u-width-100-percent"
            :style="{
              cursor: stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
            }"
          >
            <ar-text
              size="xs"
              text=""
              :style="{
                color: $arStyle.color.blueGrey600,
              }"
            />
          </div>
          <div
            v-show="!stripeElementLoadingMap.cardNumber"
            :id="`card-element-number-${_uid}`"
            :class="['card-info-input', focusStripeInput === 'cardNumber' && 'focus', cardFieldErrorsMap.cardNumber && 'error']"
            :style="{
              width: '100%',
            }"
          />
        </div>
        <ar-state-message
          v-if="cardFieldErrorsMap.cardNumber"
          :text="cardFieldErrorsMap.cardNumber"
          type="error"
          :style="{
            marginTop: '8px'
          }"
        />
        <div
           class="card-info-input-set"
          :style="{
            marginTop: '20px',
          }"
        >
          <div
            :style="{
              width: 'calc(50% - 8px)',
            }"
          >
            <div
              v-show="stripeElementLoadingMap.cardExpiry"
              class="card-info-input"
              :style="{
                cursor: stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
              }"
            >
              <ar-text
                size="xs"
                text=""
                :style="{
                  color: $arStyle.color.blueGrey600,
                }"
              />
            </div>
            <div
              v-show="!stripeElementLoadingMap.cardExpiry"
              :id="`card-element-expiry-${_uid}`"
              :class="['card-info-input',focusStripeInput === 'cardExpiry' && 'focus', cardFieldErrorsMap.cardExpiry && 'error']"
            />
            <ar-state-message
              v-if="cardFieldErrorsMap.cardExpiry"
              :text="cardFieldErrorsMap.cardExpiry"
              type="error"
              :style="{
                marginTop: '8px'
              }"
            />
          </div>
          <div
            :style="{
              width: 'calc(50% - 8px)',
            }"
          >
            <div
              v-show="stripeElementLoadingMap.cardCvc"
              class="card-info-input"
              :style="{
                cursor: stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
              }"
            >
              <ar-text
                size="xs"
                text=""
                :style="{
                  color: $arStyle.color.blueGrey600,
                }"
              />
            </div>
            <div
              v-show="!stripeElementLoadingMap.cardCvc"
              :id="`card-element-cvc-${_uid}`"
              :class="['card-info-input',focusStripeInput === 'cardCvc' && 'focus', cardFieldErrorsMap.cardCvc && 'error']"
            />
            <ar-state-message
              v-if="cardFieldErrorsMap.cardCvc"
              :text="cardFieldErrorsMap.cardCvc"
              type="error"
              :style="{
                marginTop: '8px'
              }"
            />
          </div>
        </div>
      </div>


      <!-- In case we have other errors that we can't handle. -->
      <ar-state-message
        v-if="cardGeneralError"
        :text="cardGeneralError"
        type="error"
        :style="{
          marginTop: '40px',
          justifyContent: 'center',
        }"
      />

      <ar-simple-button
        type="gradient-purple"
        :text="submitButtonText"
        shape="pill"
        :loading="loading || isValidatingCard"
        :style="{
          width: '100%',
          marginTop: cardGeneralError ? '8px' : '40px',
        }"
      />
    </form>
  </div>
</template>

<script>
import { clone } from '@/utils/helpers';

export default {
  name: 'StripeElementForm',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: 'Done',
    },
  },

  data() {
    return {
      additionalOwnerInfo: {
        name: null,
        // Additional info that we need to collect later
        // address: {
        //   line1: null,
        //   line2: null,
        //   city: null,
        //   country: null,
        //   postal_code: null,
        //   state: null,
        // },
        // email: null,
      },
      stripeElementLoadingMap: {
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
      },
      cardFieldErrorsMap: {},
      cardFieldBufferingErrorsMap: {},
      cardGeneralError: null,
      focusStripeInput: null,
      stripePublicKey: process.env.arStripePulicKey,
      stripeClient: null, // For Stripe
      cardNumberElement: null, // For Stripe
      isValidatingCard: false,
    };
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          ownerName: {
            required: 'Name on card is required',
          },
        },
      },
    });
  },

  mounted() {
    if (window.Stripe) {
      this.renderStripeForm();
    } else {
      const js = document.createElement("script");

      js.type = 'text/javascript';
      js.src = 'https://js.stripe.com/v3/';

      js.onload = () => {
        this.renderStripeForm();
      };
      document.body.appendChild(js);
    }
  },

  methods: {
    prefillName(name) {
      this.additionalOwnerInfo.name = name || '';
    },

    async renderStripeForm() {
      // Create a Stripe client.
      const stripe = Stripe(this.stripePublicKey);

      // Create an instance of Elements.
      const elements = stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const inputStyle = {
        base: {
          color: this.$arStyle.color.blueGrey800,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: this.$arStyle.color.blueGrey600,
          },
        },
        invalid: {
          iconColor: this.$arStyle.color.red500,
          color: this.$arStyle.color.blueGrey800,
        }
      };

      const cardNumber = elements.create('cardNumber', {
        style: inputStyle,
        placeholder: 'Card Number'
      });
      cardNumber.mount(`#card-element-number-${this._uid}`);
      cardNumber.addEventListener('change', this.handleStripeInputChange('cardNumber'));
      cardNumber.addEventListener('focus', this.handleStripeInputFocus('cardNumber'));
      cardNumber.addEventListener('blur', this.handleStripeInputBlur('cardNumber'));
      cardNumber.addEventListener('ready', this.handleStripeInputReady('cardNumber'));

      const cardExpiry = elements.create('cardExpiry', {
        style: inputStyle,
      });
      cardExpiry.mount(`#card-element-expiry-${this._uid}`);
      cardExpiry.addEventListener('change', this.handleStripeInputChange('cardExpiry'));
      cardExpiry.addEventListener('focus', this.handleStripeInputFocus('cardExpiry'));
      cardExpiry.addEventListener('blur', this.handleStripeInputBlur('cardExpiry'));
      cardExpiry.addEventListener('ready', this.handleStripeInputReady('cardExpiry'));

      const cardCvc = elements.create('cardCvc', {
        style: inputStyle,
      });
      cardCvc.mount(`#card-element-cvc-${this._uid}`);
      cardCvc.addEventListener('change', this.handleStripeInputChange('cardCvc'));
      cardCvc.addEventListener('focus', this.handleStripeInputFocus('cardCvc'));
      cardCvc.addEventListener('blur', this.handleStripeInputBlur('cardCvc'));
      cardCvc.addEventListener('ready', this.handleStripeInputReady('cardCvc'));

      this.stripeClient = stripe;
      this.cardNumberElement = cardNumber;
    },

    async handleBillingDetailsForm(event) {
      event.preventDefault();

      // Also check some other fields
      const isOtherFieldsValid = await this.$validator.validateAll();

      // Use Stripe.js to create a source. We only need to pass in one Element
      // from the Element group in order to create a source. We can also pass
      // in the additional owner data we collected in our form.
      try {
        this.isValidatingCard = true;
        const ownerInfo = {
          owner: this.additionalOwnerInfo,
        };
        const result = await this.stripeClient.createSource(this.cardNumberElement, ownerInfo);
        if (result.error) {
          // Cope from bufferingErrorsMap
          this.cardFieldErrorsMap = clone(this.cardFieldBufferingErrorsMap);

          // If we don't have specific error to any input, but we have general error from Stripe
          if (Object.keys(this.cardFieldErrorsMap).length === 0) {
            this.cardGeneralError = this.removeFullStop(result.error.message);
          }
        } else {
          // Reset map if succeed
          this.cardFieldErrorsMap = {};
          this.cardGeneralError = null;
          // Send the source to your server.
          if (isOtherFieldsValid) {
            this.stripeSourceHandler(result.source);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isValidatingCard = false;
      }
    },

    // Need a way to customize the message we show to user based on Stripe error code.
    // e.g: error type: "incomplete_cvc", ref: https://stripe.com/docs/error-codes
    removeFullStop(str) {
      return str.slice(-1) === '.' ? str.substring(0, str.length - 1) : str;
    },

    handleStripeInputChange(fieldName) {
      return (event) => {
        if (event.error) {
          this.$set(this.cardFieldBufferingErrorsMap, fieldName, this.removeFullStop(event.error.message));
        } else {
          this.$set(this.cardFieldBufferingErrorsMap, fieldName, null);
        }
      };
    },

    // Submit the form with the source ID.
    stripeSourceHandler(source) {
      this.$emit('submit', source);
    },

    handleStripeInputFocus(fieldName) {
      return () => {
        this.focusStripeInput = fieldName;
      }
    },

    handleStripeInputBlur(fieldName) {
      return (event) => {
        this.focusStripeInput = null;
      }
    },

    handleStripeInputReady(fieldName) {
      return () => {
        this.$set(this.stripeElementLoadingMap, fieldName, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-stripe-element-form {
  .billing-form {

    .card-info-input-set {
      display: flex;
      justify-content: space-between;

      .card-info-input {
        min-height: 50px;
        padding: 15px 14px;
        border: 1px solid $skyBlueGrey500;
        border-radius: 4px;
        cursor: text;

        &.focus {
          border: 1px solid $green500;
          box-shadow: 0 0 0 3px $green200;
        }

        &.error {
          border: 1px solid $red500;
          box-shadow: 0 0 0 3px $red400;
        }
      }
    }
  }
}
</style>
