<template>
  <InfoBranch
    v-if="!!item.config"
    :content="productTitle"
    :description="productStore"
    :style="{
      marginTop: '2px',
    }"
  />
</template>
<script>
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'PurchasedProduct',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  computed: {
    productTitle() {
      return this.item.config?.frontend?.title
    },
    productStore() {
      return this.item.config?.frontend?.vendor
    },
  }
}
</script>