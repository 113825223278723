<template>
  <div>
    <InfoBranch
      v-if="previewMessage"
      :content="previewMessage"
      description="Message"
    />
  </div>
</template>
<script>
import InfoBranch from '../utils/InfoBranch';
export default {
  name: 'SendSMS',
  components: {
    InfoBranch,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    previewMessage() {
      const preview = this.item?.config?.message || '';
      if (preview.length > 50) {
        return preview.substring(0, 50) + '...';
      } else {
        return preview;
      }
    },
  },
}
</script>