<template>
  <section
    :class="[
      'column-chooser-tabs',
      $arMediaQuery.pageContent.maxWidth('xs') && items.length > 3 && 'is-scrollable',
    ]">
    <div
      v-for="(option, index) in items"
      :key="option.id"
      :class="{
        'option': true,
        active: checkIfItemActive(index),
      }"
      :style="{
        ...itemStyle,
        cursor: option.disabled ? 'not-allowed' : null,
      }"
      v-tooltip.top="option.disabled && option.disabledTooltip ? {
        content: option.disabledTooltip,
      } : null"
      @click="selectTab(index)"
      :data-test-id="`tab-option-${convertNameToKebabCase(option.title)}`"
    >
      <ar-icon
        v-if="option.iconName"
        height="16px"
        :name="option.iconName"
      />
      <ar-text
        size="xs"
        class="span"
        :text="option.title"
        :weight="checkIfItemActive(index) ? 'bold' : 'normal'"
        :style="{ marginLeft: option.iconName ? '8px' : null }"
      />
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex';
export default {
  name: 'Tabs',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedTabIndex: {
      type: Number,
      default: null
    },
    itemStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalSelectedTabIndex: 0,
    };
  },
  computed: {
  },
  methods: {
    convertNameToKebabCase(text) {
      if (!text) {
        return '';
      }
      return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\da-z-]/gi, '');
    },
    checkIfItemActive(idx) {
      return this.selectedTabIndex !== null ? idx === this.selectedTabIndex : idx === this.internalSelectedTabIndex;
    },
    selectTab(index) {
      const selected = this.items[index];
      if (selected.disabled) {
        return;
      }
      if (!this.selectedTabIndex) {
        this.internalSelectedTabIndex = index;
      }
      this.$emit('select', selected, index);
    },
  },
};
</script>
<style lang="scss" scoped>
.column-chooser-tabs {
  display: flex;
  flex-direction: row;

  &.is-scrollable {
    overflow-x:scroll;
  }

  .option {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: 30px;
    margin-right: 30px;
    border-bottom: solid 2px rgba($purple500, 0);

    .span {
      position: relative;
      color: $blueGrey700;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      .span {
        color: $skyBlueGrey800;
      }
      border-bottom: solid 2px $purple500;
    }
  }
}
</style>
