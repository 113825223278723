<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="custom-date-range-modal"
      data-test-id="custome-date-range-modal"
      :header="title"
      width="630px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">

          <ar-field
            label="From"
            :style="{
              width: '160px',
            }"
          >
            <am2-datepicker
              data-test-id="custome-date-range-from"
              :value="startDateTimeViewModel"
              :format="'MMMM Do, YYYY'"
              :style="{
                height: '50px',
                width: '160px',
              }"
              @input="handleStartDateChange"
            />
          </ar-field>

          <ar-divider
            :style="{
              position: 'relative',
              top: '57px',
              width: '40px',
              margin: '0 13px',
            }"
          />

          <ar-field
            label="To"
            :style="{
              width: '160px',
            }"
          >
            <am2-datepicker
              data-test-id="custome-date-range-to"
              :value="endDateTimeViewModel"
              :format="'MMMM Do, YYYY'"
              :style="{
                height: '50px',
                width: '160px',
              }"
              @input="handleEndDateChange"
            />
          </ar-field>

          <ar-simple-button
            text="Apply"
            :style="{
              margin: '27px 0 0 48px',
              width: '108px',
              height: '50px',
            }"
            data-test-id="custome-date-range-apply"
            :disabled="!showApplyButton"
            @click="handleConfirmClick"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from "vuedraggable";

const defaultOptionViewModel = {
  name: null,
  index: null
};

export default {
  name: 'CustomDateRangeModal',
  components: {
    draggable
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      startDateTimeViewModel: null,
      endDateTimeViewModel: null,
    };
  },

  computed: {
    showApplyButton() {
      return this.startDateTimeViewModel && this.startDateTimeViewModel != "" && this.endDateTimeViewModel && this.endDateTimeViewModel != ""
    },
  },

  methods: {
    async handleConfirmClick() {
      const isValid = await this.$validator.validateAll();
      if(!isValid) return;

      this.$emit('input', {
        start: this.startDateTimeViewModel,
        end: this.endDateTimeViewModel,
      });
    },
    async handleCloseClick() {
      this.$emit('close');
    },
    handleStartDateChange(text) {
      this.startDateTimeViewModel = text;
    },

    handleEndDateChange(text) {
      this.endDateTimeViewModel = text;

    }
  },
};
</script>

<style lang="scss" scoped>
.custom-date-range-modal {
  .body {
    .modal-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 33px 42px 51px 42px;
    }
  }
}
</style>
