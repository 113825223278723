var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-select',{staticClass:"ar-language-select",attrs:{"items":_vm.items,"defaultSelectIndex":_vm.selectedIndex,"placeholder":"Select language","hide-arrow":false,"enable-filter":true},on:{"select":_vm.handleSelect,"clear":function($event){return _vm.$emit('clear')}},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        padding: '0 14px',
      })},[_c('ar-flag-icon',{style:({
          marginRight: '10px'
        }),attrs:{"name":selectedItemScope.item.countryIso}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"sm","text":selectedItemScope.item.name}})],1):_c('div',{style:({
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 14px',
      })},[_c('ar-text',{attrs:{"size":"xs","text":"Select language"}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        padding: '12px 15px',
        cursor: 'pointer',
        background: itemScope.active || itemScope.selected ? _vm.$arStyle.color.purple100 : null,
      })},[_c('ar-flag-icon',{style:({
          marginRight: '10px'
        }),attrs:{"name":itemScope.item.countryIso}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"xs","text":itemScope.item.name,"weight":itemScope.selected ? 'bold' : 'normal'}})],1)]}}])},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"no-item"},[_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        padding: '12px 15px',
      })},[_c('span',{style:({
          fontSize: '14px',
          lineHeight: '1',
          color: _vm.$arStyle.color.$blueGrey800,
        })},[_vm._v("No data")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }