import { ActionTree } from 'vuex'
import { RootState } from '@/store/modules/types';
import { RecipientState } from './types';
import moment from 'moment';
import { magicDownload } from '@/utils/html-element/';
import { makeSafeOrderbyParams } from '~/utils/api/makeSafeOrderbyParams';

export const recipientActions: ActionTree<RecipientState, RootState> = {
  async FETCH_MESSAGE_RECIPIENTS({ rootState, state, commit, dispatch }, {
    messageTaskOid,
    top = 50,
    selectKeys,
    orderBy = { key: 'sysMtime', order: 'desc' },
    responseType = 'application/json',
    searchString = null,
    searchStringTargets = ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]'],
    status = [],
    reload = false,
  }) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = rootState.auth.account;
    if (state.isFetchingRecipients) { return; }

    if (reload) {
      commit('RESET_RECIPIENTS');
      let sentCount = 0;
      const statusDetails = rootState.message?.currentSelectedMessage?.statusDetails;

      if (rootState.message.currentSelectedMessage &&
        !!statusDetails
      ) {
        if (rootState.message.currentSelectedMessage?.provider === 'email') {
          if (status.length === 0 || status[0] === null) {
            sentCount = statusDetails.totalMessages || 0;
          } else if (status.length > 1 && status[0] === 'opened') {
            sentCount = (statusDetails.opened || 0) + (statusDetails.clicked || 0);
          } else if (status.length > 0 && status[0] === 'clicked') {
            sentCount = statusDetails.clicked || 0;
          } else if (status.length > 0 && status[0] === 'bounced') {
            sentCount = statusDetails.bounced || 0;
          } else if (status.length > 0 && status[0] === 'unsubscribed') {
            sentCount = statusDetails.unsubscribed || 0;
          } else if (status.length > 1 && status[0] === 'failed') {
            sentCount = (statusDetails.failed  || 0) + (statusDetails.deferred || 0);
          }
        } else {
          if (status.length === 0 || status[0] === null) {
            sentCount = statusDetails.totalMessages || 0;
          } else if (status.indexOf('sent') > -1 && status.indexOf('opened') > -1 && status.indexOf('clicked') > -1 ) {
            sentCount = (statusDetails.sent  || 0) + (statusDetails.opened || 0) + (statusDetails.clicked || 0)
          } else if (status.indexOf('opened') > -1 && status.indexOf('clicked') > -1 ) {
            sentCount = (statusDetails.opened || 0) + (statusDetails.clicked || 0)
          } else if (status.length > 0 && status[0] === 'clicked') {
            sentCount = statusDetails.clicked || 0;
          } else if (status.length > 0 && status[0] === 'unsubscribed') {
            sentCount = statusDetails.unsubscribed || 0;
          } else if (status.length > 1 && status[0] === 'failed') {
            sentCount = (statusDetails.failed  || 0) + (statusDetails.deferred || 0);
          }
        }
      }

      // For now, remove the message recipient count fetch. The UI doesn't really use it anywhere, so let's axe
      // it until such a time as we need it. Use SET_TOTAL_RECIPIENTS_COUNT to fetch it
      commit('SET_TOTAL_RECIPIENTS_COUNT', sentCount);
    }

    try {
      commit('SET_IS_FETCHING_RECIPIENTS', true)
      let uri = `/promoter/${promoterOid}/fan-message`;

      // Generate filter query
      let filter = '';
      filter += `taskOid=${messageTaskOid}`;

      if (status && status.length > 1) {
        filter += ` AND (${
          status
            .map((item: string) =>`status=${item}`).join(' OR ')
        })`;
      } else if (status && status.length === 1 && status[0] !== undefined && status[0] !== null) {
        filter += ` AND status=${status[0]}`;
      }

      // Filtering with a search string will come later
      if (searchString) {
        // We also allow users to search "status" by using keyword, so don't forget "status" in "searchStringTargets"
        filter += ` AND (${
          searchStringTargets
            .map((target: string) => `${target} ILIKE "%${searchString}%"`).join(' OR ')
        })`;
      }

      const params = {
        $top: top,
        $skip: reload ? 0 : state.recipients.length,
        $orderby: makeSafeOrderbyParams(orderBy),
        $select: selectKeys ? selectKeys.join(',') : null,
        $filter: filter,
      }

      let { data } = await this.$axios.get(uri,
        { params,
          headers: {
            Accept: responseType
          }
        })

      if (data.length === 0) {
        commit('SET_IS_NO_MORE_RECIPIENTS', true)
      }

      if (reload) {
        commit('SET_RECIPIENTS', data);
      } else {
        commit('CONCAT_RECIPIENTS', data);
      }

      return
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch recipients' });
      commit('SET_HAS_FETCH_RECIPIENTS_FAILED', true);
      // throw error;
      return false;
    } finally {
      commit('SET_IS_FETCHING_RECIPIENTS', false);
    }
  },


  async FETCH_TOTAL_RECIPIENTS_COUNT({ rootState, state, commit }, {
    messageTaskOid,
    searchString = null,
    searchStringTargets = ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]'],
    status = [],
  }) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = rootState.auth.account;

    try {
      commit('SET_IS_FETCHING_TOTAL_RECIPIENTS_COUNT', true);
      const count = await this.$api.recipients.fetchMessageRecipientCount(
        promoterOid,
        messageTaskOid,
        searchString,
        searchStringTargets,
        status);
      commit('SET_TOTAL_RECIPIENTS_COUNT', count);
      commit('SET_IS_FETCHING_TOTAL_RECIPIENTS_COUNT', false);
    } catch(error) {
      if (this.$axios.isCancel(error)) {
        console.error(error);
      } else {
        console.error(error);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch recipient count' });
        commit('SET_IS_FETCHING_TOTAL_RECIPIENTS_COUNT', false);
      }
    }
  },

  async EXPORT_MESSAGE_RECIPIENTS_CSV({ rootState }, {
    selectKeys,
    taskOid,
    orderBy,
    responseType = 'application/json',
    searchString = null,
    searchStringTargets = ['firstName', 'lastName', 'emailAddress', 'lastStatus'],
  }) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = rootState.auth.account;
    try {


      // This is how we'd set up search-based exports once the backend supports it
      // Keeping it in for now to make it easier to implement down the line.

      // let filter = '';
      // if (searchString) {
      //   filter += `${
      //     searchStringTargets
      //       .map((target: string) => `${target} ILIKE "%${searchString}%"`).join(' OR ')
      //   }`;
      // }

      const params = {
        $top: 'all',
        $select: selectKeys.join(','),
        $orderby: `${orderBy.key}%20${orderBy.order}`,
        $messageTaskOid: taskOid,
        // $filter: filter,
      }

      let uri = `/promoter/${promoterOid}/filter-fan-export`;

      const { status, data } = await this.$axios.get(
        uri,
        {
          params,
          headers: { Accept: responseType },
        },
      );
      // If we get list of fanOids, don't bother with filter then.
      // if (fanOids && fanOids.length > 0) {
      //   const query = fanOids.reduce((str, oid, index) => {
      //     str += `oid=${oid}`;
      //     if (index + 1 !== fanOids.length) {
      //       str += ` OR `;
      //     }

      //     return str;
      //   }, '');

      //   uri += `&$filter=${query}`;
      // }

      if (status === 200) {
        const fileName = `recipients-${moment().format('DD/MM/YYYY')}.csv`;
        magicDownload(data, fileName);
      } else {
        this.$arNotification.push({
          type: 'warning',
          message: data,
          timeout: 5000,
        });
      }
    } catch (err) {
      console.error(err);
      this.$arNotification.push({ type: 'error', message: 'Failed to export message recipients' });
    }
  },
};
