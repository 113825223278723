<template>
  <section class="eventRSVP-form-details">
    <!-- <am2-custom-field-modal
      title="Add custom field"
      :is-show="showCustomFieldModal"
      @close="closeCustomFieldClick"
      @input="handleCustomFieldModalConfirm"
    /> -->
    <div v-if="hasHeading" class="u-margin-bottom-7">
      <ar-text
        size="xxs"
        :text="`STEP 2 OF ${totalStep}`"
        :style="{
          color: '#9FA8B5',
        }"
        weight="bold"
      />
      <am2-heading
        class="u-margin-top-2"
        type="h1"
        size="lg"
        title="Registration fields"
        multiple-lines
      />
      <ar-text
        size="sm"
        text="Confirm the fields you want to capture on registration"
        class="u-margin-top-2"
      />
    </div>

    <!-- Form fields -->
    <am2-card-container
      :class="[
        'u-padding-y-6',
        'u-padding-x-6'
      ]"
    >
      <!-- section heading -->
      <div
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-margin-bottom-4',
        ]"
      >
        <ar-text
          class="u-flex-grow-1"
          size="xs"
          text="Fields"
          weight="bold"
        />
        <ar-text
          size="xs"
          text="Optional"
          align="right"
          weight="bold"
          :style="{
            width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
            marginRight: '30px',
          }"
        />
      </div>
      <div
        v-for="(field, idx) of displayedFields"
        :key="idx"
      >
        <div
          :class="[
            'u-display-flex',
            'u-align-items-center',
          ]"
          :style="{
            height: '52px',
          }"
        >
          <ar-text
            class="u-flex-grow-1"
            size="xs"
            :text="field.name"
          />
          <ar-checkbox
            :value="field.optional"
            @input="handleRequiredCheck(field.key, ...arguments)"
            :disabled="checkFieldIsNotOptional(field)"
            align="right"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
              marginRight: checkFieldIsNotOptional(field) ? '30px' : '0px'
            }"
          />
          <ar-icon
            v-if="!checkFieldIsNotOptional(field)"
            class="icon remove-button u-margin-left-5"
            name="cross"
            height="10px"
            width="10px"
            stroke-width="4"
            stroke-linecap="round"
            :color="$arStyle.color.blueGrey700"
            @click.native="handleRemoveField(field.key, field.order)"
          />
        </div>
        <ar-divider />
      </div>
      <div class="u-margin-top-5">
        <am2-link-button-dropdown
          placeholder="+ Add field"
          :items="unlistedFieldItems"
          search-placeholder="Search fields"
          has-search
          no-option-text="No more fields found"
          max-height="300px"
          :dropdown-style="{
            width: '280px',
          }"
          :button-props="{
            hasArrow: false,
            hasUnderline: true,
            textProps: {
              size: 'xs',
            },
          }"
          @select="handleFieldSelect"
        />
      </div>
    </am2-card-container>
    <!-- Let's hide Stripe connection, it's not ready
      <am2-card-container
        v-if="scratchEvent.location == null"
        :class="[
          'u-margin-top-5',
          'u-padding-x-6',
          'u-padding-y-4',
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            $arMediaQuery.pageContent.maxWidth('xs') && 'u-flex-flow-column'
          ]"
        >
          <am2-switch
            @input="handleStripeEnableChange"
          />
          <div
            :class="[
              'switch-container',
              'u-display-flex',
              'u-flex-flow-row',
              'u-justify-content-space-between',
              'u-align-items-center',
              $arMediaQuery.pageContent.minWidth('sm') && 'u-margin-left-6',
            ]"
          >
            <ar-text
              size="xs"
              text="Sell tickets"
              weight="bold"
            />
            <am2-tag
              type="green"
              shape="rectangle"
              class="state-tag"
              text="Free"
            />

          </div>
        </div>
        <div
          :class="[
            `u-margin-top-7`,
          ]"
          v-show="showStripeFields"
        >
          <ar-text
            size="xs"
            text="To start selling tickets, connect your Stripe Account. Stripe processing fees apply."
            class="u-margin-bottom-3"
            multiple-lines
          />
          <ar-simple-button
            :text="'Connect Stripe'"
            :style="{
              width: '160px',
              height: '39px',
            }"
            :icon-name="'stripe'"
            icon-distance="10px"
            :icon-props="{
              height: '17px',
              color: 'white',
            }"
            type="#635BFF"
          />
        </div>
      </am2-card-container>
    -->
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { clone } from '@/utils/helpers';

export default {
  name: 'Details',

  props: {
    hasHeading: {
      type: Boolean,
      default: false,
    },
    totalStep: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      // showCustomFieldModal: false,
      showStripeFields: false,
      predefinedFields: [
        {
          name: 'Email address',
          key: 'emailAddress',
        },
        {
          name: 'First name',
          key: 'firstName',
        },
        {
          name: 'Last name',
          key: 'lastName',
        },
        {
          name: 'Mobile number',
          key: 'mobileNumber',
        },
        {
          name: 'City',
          key: 'city',
        },
        {
          name: 'State',
          key: 'state',
        },
        {
          name: 'Zip/post code',
          key: 'postcode',
        },
        {
          name: 'Country',
          key: 'country',
        },
        {
          name: 'Date of birth',
          key: 'dob',
        },
        {
          name: 'Gender',
          key: 'gender',
        },
        // {
        //   name: 'Street address',
        //   key: 'street address',
        // },
      ],
    };
  },

  computed: {
    ...mapState({
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      scratchEvent: state => state.event.scratchEvent,
    }),
    unlistedFieldItems() {
      return this.predefinedFields.filter(item => {
        const field = this.scratchEventRSVPForm.registrations.fields[item.key];
        if (field && field.enabled) {
          return false;
        } else {
          return true;
        }
      });
    },
    displayedFields() {
      const fieldKeys = Object.keys(this.scratchEventRSVPForm.registrations.fields);
      return fieldKeys
        .map(fieldKey => {
          return {
            ...this.scratchEventRSVPForm.registrations.fields[fieldKey],
            key: fieldKey,
          };
        })
        .filter(field => {
          return field.enabled;
        })
        .sort((fieldA, fieldB) => {
          return fieldA.order - fieldB.order;
        });
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
    ]),

    handleRequiredCheck(key, isOptional) {
      const tempFields = clone(this.scratchEventRSVPForm.registrations.fields);
      tempFields[key].optional = isOptional;

      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        registrations: {
          fields: tempFields,
        },
      });
    },

    handleFieldSelect(fieldItem) {
      const tempFields = clone(this.scratchEventRSVPForm.registrations.fields);
      const numberOfFieldsDisplayed = this.displayedFields.length;
      tempFields[fieldItem.key].enabled = true;
      tempFields[fieldItem.key].optional = false;
      tempFields[fieldItem.key].order = numberOfFieldsDisplayed;

      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        registrations: {
          fields: tempFields,
        },
      });
    },

    // handleCustomFieldClick() {
    //   this.showCustomFieldModal = true;
    // },

    // closeCustomFieldClick() {
    //   this.showCustomFieldModal = false;
    // },

    // handleCustomFieldModalConfirm(item) {
    //   this.showCustomFieldModal = false;

    //   let tempFields = clone(this.scratchEventRSVPForm.registrations.fields)
    //   tempFields.push(item)

    //   this.patchEventRSVPForm(tempFields);
    // },

    checkFieldIsNotOptional(field) {
      return field.key === 'emailAddress';
    },

    handleStripeEnableChange(value) {
      this.showStripeFields = value;
    },

    handleRemoveField(key, order) {
      const tempFields = clone(this.scratchEventRSVPForm.registrations.fields);
      tempFields[key].enabled = false;
      tempFields[key].order = -1;

      // Let's also update the orders
      for (const [fieldKey, field] of Object.entries(tempFields)) {
        if (field.order > order) {
          field.order = field.order - 1;
        }
      }

      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        registrations: {
          fields: tempFields,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eventRSVP-form-details {
  .switch-container {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    width: calc(100% - 60px);
  }

  .remove-button {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
