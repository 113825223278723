<template>
  <div v-if="isActive">
    Ends in {{ toString(days) }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
  </div>
  <div v-else>
    Ended on {{ formatDate(date) }}
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Countdown',

  props: {
    date: {
      type: String,
      required: true,
    },
    timeZone: {
      type: String,
      default: 'UTC'
    }
  },

  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      isActive: this.isCampaignActive(),
      timer: null
    };
  },

  computed: {
    dateInCurrentLocale() {
      return moment.tz(this.date, this.timeZone)
    },
    normalizedDate() {
      return Math.trunc(this.dateInCurrentLocale.unix());
    },
    seconds() {
      return (this.normalizedDate - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.normalizedDate - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.normalizedDate - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.normalizedDate - this.now) / 60 / 60 / 24);
    },
  },

  mounted() {
    if (this.isCampaignActive()) {
      this.timer =
        setInterval(() => {
          // Test if active and then set isActive = false.
          if (this.isCampaignActive()) {
            this.now = Math.trunc(new Date().getTime() / 1000);
          } else {
            this.$emit('campaign-ended');
            this.isActive = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
    }
  },

  methods: {
    isCampaignActive() {
      const currentTime = moment.utc();

      return moment.tz(this.date, this.timeZone).isAfter(currentTime)
    },
    formatDate(date) {
      return moment(date).format('ddd Do MMM, YYYY');
    },
    toString(value) {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`;
      }
      return value.toString();
    },
  },
};
</script>
