<template>
  <section class="wrapper content content--centered">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick"
    />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Facebook Messenger"
          description="Send messages to all contacts on Facebook Messenger"
          :text-size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
          :icon-props="{
            name: 'messenger',
            color: $arStyle.color.messenger,
          }"
        />
      </div>
      <div class="title-section-right" v-if="!integrationFailed">
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="settingButtonOptions"
          @select="handleSettingOptionSelect"
        />
      </div>
    </div>
    <section
      v-if="integrationFailed"
      :style="{ paddingTop: '55px' }"
    >
      <ar-snackbar
        type="warning"
        message="Your Facebook Messenger connection is no longer valid. This means you won't be able to opt-in fans or message your subscribers <a>Please reconnect your account</a>"
        @anchorClick="reconnectIntegration"
        :style="{ width: '100%' }"
      />
    </section>
    <section v-else>
      <ar-divider
        :style="{
          margin: $arMediaQuery.pageContent.minWidth('sm') ? '55px 0 41px' : '24px 0 32px',
        }"
      />
      <am2-heading
        type="h1"
        class="block-heading"
        title="Connected pages"
        size="sm"
      />
      <div
        v-if="(isFetchingIntegration || isPolling) && facebookPages.length === 0"
        class="loading-section"
      >
        <am2-loading-bubble />
      </div>
      <am2-card-container v-else class="page-cards-block">
        <FacebookPageCard
          v-for="page of facebookPages"
          :key="page.id"
          :page-info="page"
          :page-meta="facebookPageMetaMap[page.id]"
          :connecting="!facebookPageMetaMap[page.id] && isPolling"
          class="page-card"
          :card-size="$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs'"
        />
      </am2-card-container>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FacebookPageCard from './components/facebook-page-card';

export default {
  name: 'FacebookMessenger',
  layout: 'default',

  components: {
    FacebookPageCard,
  },

  data: () => ({
    allMessengerIntegrations: [],
    integration: null,
    selectedIntegrationIndex: 0,
    isFetchingIntegration: false,
    settingButtonOptions: [
      {
        name: 'Open Facebook',
        value: 'GO_TO_FACEBOOK'
      },
      {
        name: 'Refresh connected pages',
        value: 'REFRESH_INTEGRATION',
      },
      {
        name: 'Reconnect Account',
        value: 'RECONNECT_INTEGRATION',
      },
      {
        name: 'Remove Account',
        value: 'DELETE_INTEGRATION',
      },
    ],
    facebookProfile: null,
    isPolling: false,
  }),

  computed: {
    // We also need to check if page has any issue under `meta` object, so we put that into page object
    facebookPages() {
      if (!this.integration) {
        return [];
      }
      if (!this.integration.integration.pages) {
        return [];
      }

      const pageMap = this.integration.integration.pages;
      return Object.keys(pageMap).map(pageId => pageMap[pageId]);
    },

    facebookPageMetaMap() {
      // We changed the structure, the `facebook` layer shall be removed later
      let pageMetaMap = this.integration.meta.facebook ? this.integration.meta.facebook.pages : this.integration.meta.pages;
      pageMetaMap = pageMetaMap || {};

      return pageMetaMap;
    },

    integrationFailed() {
      // Don't prematurely mark the integration as
      // 'failed' if it hasn't finished 'loading' in yet.
      return this.integration ? this.integration.status === 'failed' : false;
    },
  },

  async mounted() {
    await this.fetchMessengerIntegrations();
    // Have a poll to check if connection is done
    this.startPollingMessengerIntegration();
  },

  beforeDestroy() {
    if (this.pollMessengerIntegrationInterval) {
      clearInterval(this.pollMessengerIntegrationInterval);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'FETCH_INTEGRATIONS',
      'DELETE_INTEGRATION',
      'CONNECT_TO_INTEGRATION',
    ]),

    startPollingMessengerIntegration() {
      this.isPolling = true;
      this.pollMessengerIntegrationInterval = setInterval(this.pollMessengerIntegration, 3000);
    },

    async pollMessengerIntegration() {
      if (this.integration.status !== 'in-progress') {
        clearInterval(this.pollMessengerIntegrationInterval);
        this.isPolling = false;
        return;
      }

      this.fetchMessengerIntegrations();
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'GO_TO_FACEBOOK') {
        window.open('https://www.facebook.com', '_blank');
      } else if (item.value === 'DELETE_INTEGRATION') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Facebook Messenger integration will stop you from messaging fans on Facebook through Audience Republic',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this.DELETE_INTEGRATION(this.integration.oid);
            this.$router.push({ path: '/settings/integrations' });
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove account' });
          }
        }
      } else if (item.value === 'REFRESH_INTEGRATION') {
        this.reconnectIntegration();
      } else if (item.value === 'RECONNECT_INTEGRATION') {
        this.reconnectIntegration();
      }
    },

    async reconnectIntegration() {
      try {
        await this.DELETE_INTEGRATION(this.integration.oid);
        const res = await this.CONNECT_TO_INTEGRATION({
          provider: 'facebook',
          app: 'messenger',
        });
        this.fetchMessengerIntegrations();
        this.startPollingMessengerIntegration();
        this.$arNotification.push({ type: 'success', message: 'Facebook Messenger account successfully reconnected!' });
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to reconnect account' });
      }
    },

    async fetchMessengerIntegrations() {
      try {
        const facebookMessengerIntegrations = await this.FETCH_INTEGRATIONS({
          top: 1,
          filters: {
            expressions: [{
              key: 'provider',
              value: 'facebook',
              operator: '=',
            }, {
              key: 'app',
              value: 'messenger',
              operator: '=',
            }],
            logicalOperators: ['AND'],
          },
          orderby: "sysMtime desc",
        });

        this.allMessengerIntegrations = facebookMessengerIntegrations;
        this.integration = this.allMessengerIntegrations[this.selectedIntegrationIndex];
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to get Facebook Messenger integration' });
      }
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    flex-direction:column;

    .title-section-right {
      display: inline-flex;
      align-items: center;
      align-self: flex-end;
      padding-top:12px;
    }

    &.sm-min {
      flex-direction:row;

      .title-section-right {
        align-self: center;
        padding-top:0;
      }
    }
  }

  .block-heading {
    margin-bottom: 16px;
    font-weight: bold;
  }

  .loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  .page-cards-block {
    margin-bottom: 42px;
    border-radius: 4px;
    overflow: hidden;

    .page-card {
      margin-bottom: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $skyBlueGrey500;
    }
  }
}
</style>
