var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"ask-dice-access-token-modal",attrs:{"is-open":_vm.isShow,"header":"Connect To DICE","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'ask-dice-access-token-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-display-flex u-margin-bottom-3 dice-modal-wrapper"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: "Automatically subscribe all attendees of events from this DICE account.",
          }),expression:"{\n            content: `Automatically subscribe all attendees of events from this DICE account.`,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('messageList')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email']},model:{value:(_vm.messageList),callback:function ($$v) {_vm.messageList=$$v},expression:"messageList"}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-5",attrs:{"label":"Access Token"}},[_c('ar-input',{attrs:{"name":"accessToken","placeholder":"Please enter your DICE access token","id":"dice-token-input","type":"password","data-test-id":"dice-token-input"},model:{value:(_vm.accessToken),callback:function ($$v) {_vm.accessToken=$$v},expression:"accessToken"}})],1)],1),_vm._v(" "),_c('div',{class:[
        'ask-dice-access-token-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-text',{staticClass:"u-margin-top-2",style:({
          color: _vm.$arStyle.color.blueGrey800,
        }),attrs:{"size":"xs","text":"More settings","weight":"bold"}}),_vm._v(" "),_c('am2-switch-field-card',{staticClass:"u-margin-top-4",attrs:{"title":"Automatically apply tags to fans imported from DICE","subtitle":"Tags will be customised per artist, genre & venue","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal',"value":_vm.applyPrefix,"display-field":_vm.applyPrefix,"data-test-id":"dice-automatic-tag-switch"},on:{"input":_vm.toggleApplyPrefix}},[_c('ar-field',[(_vm.applyPrefix)?_c('div',{staticClass:"u-margin-top-5"},[_c('ar-field',{staticClass:"u-margin-top-6"},[_c('ar-text',{style:({
                  color: _vm.$arStyle.color.blueGrey700,
                }),attrs:{"size":"xs","text":"Enable customised tag names on","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-justify-content-flex-start u-margin-top-3"},[_c('ar-checkbox',{key:"artists",staticClass:"u-margin-right-5",attrs:{"label":"Artists","data-test-id":"dice-automatic-tag-artists"},model:{value:(_vm.tagNameType.artists),callback:function ($$v) {_vm.$set(_vm.tagNameType, "artists", $$v)},expression:"tagNameType.artists"}}),_vm._v(" "),_c('ar-checkbox',{key:"genres",staticClass:"u-margin-right-5",attrs:{"label":"Genres","data-test-id":"dice-automatic-tag-genres"},model:{value:(_vm.tagNameType.genres),callback:function ($$v) {_vm.$set(_vm.tagNameType, "genres", $$v)},expression:"tagNameType.genres"}}),_vm._v(" "),_c('ar-checkbox',{key:"venues",attrs:{"label":"Venues","data-test-id":"dice-automatic-tag-venues"},model:{value:(_vm.tagNameType.venues),callback:function ($$v) {_vm.$set(_vm.tagNameType, "venues", $$v)},expression:"tagNameType.venues"}})],1)],1),_vm._v(" "),_c('ar-field',[_c('div',{staticClass:"u-display-flex u-margin-bottom-3 dice-modal-wrapper u-margin-top-5"},[_c('ar-text',{style:({
                    color: _vm.$arStyle.color.blueGrey700,
                  }),attrs:{"size":"xs","text":"Tag prefix (optional)","weight":"bold","multiple-lines":""}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                    content: "We suggest to start with 'DICE-' as a prefix to be clear that the audience attended a DICE event.",
                  }),expression:"{\n                    content: `We suggest to start with 'DICE-' as a prefix to be clear that the audience attended a DICE event.`,\n                  }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-input',{attrs:{"placeholder":"Please enter your tag prefix","value":_vm.tagName,"autocomplete":"organization","data-vv-name":"tagName","data-vv-as":"tag name","type":"text","data-test-id":"dice-automatic-tag-prefix"},on:{"input":_vm.handleTagNameInput}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
                  color: _vm.$arStyle.color.blueGrey700,
                }),attrs:{"size":"xxs","text":"For example, if you set ‘DICE’ as the prefix, the tags imported into audience republic for the genre ‘Rock’ will appear as dice-rock against the fan record.","multiple-lines":""}})],1)],1):_vm._e()])],1)],1),_vm._v(" "),_c('div',{class:[
        'ask-dice-access-token-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled,"id":"dice-modal-confirm-button","data-test-id":"dice-modal-confirm-button"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }