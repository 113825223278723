export default class Breaker {
  constructor() {}

  get type() {
    return 'node';
  }

  get name() {
    return 'breaker';
  }

  get schema() {
    return {
      inline: true,
      group: 'inline',
      selectable: false,
      parseDOM: [
        { tag: 'br' },
      ],
      toDOM() {
        return ['br'];
      }
    };
  }

  keys({ schema }) {
    return {
      'Enter': (state, dispatch) => {
        const tr = state.tr;
        tr.replaceSelectionWith(schema.nodes.breaker.create());
        dispatch(tr);
      },
    }
  }
}