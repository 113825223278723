var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dialog-select",attrs:{"data-test-id":"filter-dropdown"}},[_c('div',{staticClass:"content"},[_c('div',[_c('ar-text',{staticClass:"u-margin-bottom-3",attrs:{"size":"xs","text":"Filter Ticket Sales","weight":"bold"}})],1),_vm._v(" "),(_vm.tabs.length > 0)?_c('div',{staticClass:"tabs-section"},[_c('am2-elegant-tabs',{attrs:{"items":_vm.tabs,"tab-key":_vm.tabKey},on:{"select":_vm.handleTabSelect}})],1):_vm._e(),_vm._v(" "),_c('ar-field',{attrs:{"error-messages":[_vm.veeErrors.first('criteria')]}},[_c('ar-simple-select',{directives:[{name:"validate",rawName:"v-validate:hasEventSalesSegment",value:('required:true'),expression:"'required:true'",arg:"hasEventSalesSegment"}],staticClass:"criteria-dropdown u-margin-bottom-3",style:({
          width: '100%',
          zIndex: this.$arStyle.zIndex.globalHighest,
          height: '40px',
        }),attrs:{"name":"segment-criteria","data-test-id":"event-filter-criteria","dropdown-style":{
          padding: '8px 0',
          zIndex: this.$arStyle.zIndex.globalHighest,
        },"items":_vm.eventOrderByItems,"default-select-index":_vm.eventSalesSegmentDropdownIndex,"data-vv-as":"criteria","data-vv-name":"criteria"},on:{"select":_vm.handleDropdownSegmentCriteria}})],1),_vm._v(" "),(_vm.filterByCriteria != 'segments')?_c('ar-field',{attrs:{"error-messages":[_vm.veeErrors.first('conditions')]}},[(_vm.eventSalesSegmentDropdownIndex != null)?_c('ar-simple-select',{directives:[{name:"validate",rawName:"v-validate:hasEventSalesSegmentCondition",value:('required:true'),expression:"'required:true'",arg:"hasEventSalesSegmentCondition"}],staticClass:"condition-dropdown u-margin-bottom-3",style:({
          width: '100%',
          zIndex: this.$arStyle.zIndex.globalHighest,
          height: '40px',
        }),attrs:{"name":"segment-filter-type","data-test-id":"event-filter-condition","dropdown-style":{
          padding: '8px 0',
          zIndex: this.$arStyle.zIndex.globalHighest,
        },"items":_vm.getFilterConditions,"default-select-index":_vm.filterConditionSelected && _vm.filterConditionSelected.index,"data-vv-as":"conditions","data-vv-name":"conditions"},on:{"select":_vm.handleDropdownSegmentCondition}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.filterConditionSelected && _vm.filterByCriteria != 'gender' && _vm.filterByCriteria != 'tags' && _vm.filterByCriteria != 'segments')?_c('div',{staticClass:"text-fields"},[(_vm.showFilterText)?_c('ar-field',{staticClass:"field",attrs:{"error-messages":[_vm.veeErrors.first('filter-text')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate:hasEventSalesSegmentText",value:('required:true'),expression:"'required:true'",arg:"hasEventSalesSegmentText"}],ref:"filterText",style:({
            width: '100%',
            height: '40px',
          }),attrs:{"data-test-id":"event-filter-text","data-vv-name":"filter-text","data-vv-as":"text"},on:{"input":_vm.handleSegmentFilterText}})],1):_vm._e(),_vm._v(" "),(this.filterByCriteria == 'country' && _vm.filterConditionSelected && _vm.filterConditionSelected.key == 'is_exactly')?_c('ar-field',{staticClass:"field",attrs:{"error-messages":[_vm.veeErrors.first('country')]}},[_c('ar-country-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],style:({
            width: '100%',
            height: '40px',
          }),attrs:{"data-test-id":"event-filter-country","value-type":"iso","data-vv-as":"country","data-vv-name":"country","value":_vm.filterCountry},on:{"select":_vm.handleCountrySelect}})],1):_vm._e(),_vm._v(" "),((this.filterByCriteria == 'tags' && _vm.filterConditionSelected == null) || (_vm.filterConditionSelected && _vm.filterConditionSelected.key == 'is_between'))?_c('div',{staticClass:"between_filter"},[_c('ar-field',{staticClass:"field",style:({
            width: 'calc(50% - 30px)',
          }),attrs:{"data-test-id":"event-filter-start-text","error-messages":[_vm.veeErrors.first('filter-start')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|integer|isPositiveInt'),expression:"'required|integer|isPositiveInt'"}],style:({
              height: '40px',
            }),attrs:{"data-vv-name":"filter-start","data-vv-as":"Filter Start","value":_vm.filterSegmentStart},on:{"input":_vm.handleSegmentFilterStart}})],1),_vm._v(" "),_c('div',{staticClass:"betweener-dash"}),_vm._v(" "),_c('ar-field',{staticClass:"field",style:({
            width: 'calc(50% - 30px)',
          }),attrs:{"error-messages":[_vm.veeErrors.first('filter-end')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:("required|integer|isPositiveInt"),expression:"`required|integer|isPositiveInt`"}],style:({
              height: '40px',
            }),attrs:{"data-test-id":"event-filter-end-text","data-vv-name":"filter-end","data-vv-as":"Filter End","value":_vm.filterSegmentEnd},on:{"input":_vm.handleSegmentFilterEnd}})],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.filterConditionSelected && _vm.filterByCriteria == 'tags')?_c('div',{staticClass:"function-wrapper"},[_c('am2-search',{staticClass:"search",attrs:{"placeholder":"Search tags","auto-focus":"","data-test-id":"event-filter-tags-search"},on:{"input":_vm.handleTagsSearchStringChange}}),_vm._v(" "),_c('div',{staticClass:"options-wrapper",style:({ minHeight: _vm.isFetchingTags ? '16px' : null })},[_c('div',{staticClass:"loading-bubble-wrapper"},[(_vm.isFetchingTags)?_c('am2-loading-bubble'):_vm._e()],1),_vm._v(" "),_c('div',{class:{
            'options-content': true,
            disabled: _vm.isFetchingTags,
          }},[_vm._l((_vm.displayedCandidateItems),function(item,index){return _c('div',{key:item.oid,staticClass:"checkbox-section",attrs:{"data-test-id":"event-filter-tags-checkboxes"}},[_c('ar-checkbox',{ref:"checkbox",refInFor:true,staticClass:"checkbox",attrs:{"value":_vm.itemTickedMap && _vm.itemTickedMap[item.oid],"data-test-id":("event-filter-tags-checkbox-item-" + (item.oid))},on:{"change":function (val) { return _vm.handleCheckBoxChange(item, val); }},model:{value:(_vm.itemTickedMap[item.oid]),callback:function ($$v) {_vm.$set(_vm.itemTickedMap, item.oid, $$v)},expression:"itemTickedMap[item.oid]"}}),_vm._v(" "),_c('div',{staticClass:"checkbox-label-section",attrs:{"data-test-id":("event-filter-tags-checkbox-label-" + (item.oid))},on:{"click":function($event){return _vm.handleCheckboxLabelClick(index)}}},[_c('div',{staticClass:"checkbox-label-first-line"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:((item.name || 'Has been removed' | _vm.capitalize).length > 26 ? {
                    content: item.name || 'Has been removed' | _vm.capitalize
                  } : null),expression:"(item.name || 'Has been removed' | capitalize).length > 26 ? {\n                    content: item.name || 'Has been removed' | capitalize\n                  } : null",modifiers:{"top":true}}],style:({
                    width: 'calc(100% - 60px)',
                    color: !item.name ? _vm.$arStyle.color.red500 : null,
                    margin: '0 8px 12px 0',
                  }),attrs:{"size":"xs","text":item.name || 'Has been removed'}}),_vm._v(" "),(_vm.calculateItemStat(item) !== null)?_c('ar-text',{style:({
                    width: '60px',
                    color: _vm.$arStyle.color.blueGrey700,
                  }),attrs:{"size":"xs","text":_vm.calculateItemStat(item),"align":"right"}}):_vm._e()],1),_vm._v(" "),_c('ar-text',{style:({
                  color: _vm.$arStyle.color.blueGrey600,
                }),attrs:{"size":"xxxs","text":_vm.getDateString(item.startDate, item.endDate, item.timeZone)}})],1)],1)}),_vm._v(" "),(_vm.displaySeeLessLink)?_c('ar-link-button',{staticClass:"show-all see-less",attrs:{"text":"See less"},on:{"click":_vm.decrementDisplayedItems}}):_vm._e(),_vm._v(" "),(_vm.displayShowMoreLink)?_c('ar-link-button',{staticClass:"show-all",attrs:{"data-test-id":"event-filter-tags-show-all","text":("Show more (" + _vm.tagsNextIncrement + (_vm.tagsNextIncrement < _vm.candidateItems.length ? "+" : "") + ")")},on:{"click":_vm.incrementDisplayedItems}}):_vm._e()],2)])],1):_vm._e(),_vm._v(" "),(_vm.filterConditionSelected && _vm.filterByCriteria == 'segments')?_c('div',{staticClass:"function-wrapper"},[_c('am2-search',{ref:"search",staticClass:"filter",attrs:{"data-test-id":"event-filter-search","placeholder":"Search segment","enable-clear":false},on:{"input":_vm.handleSegmentsSearchStringChange}}),_vm._v(" "),_c('div',{staticClass:"fader"}),_vm._v(" "),_c('div',{ref:"items-section",staticClass:"items-section",style:({
          maxHeight: _vm.itemsSectionMaxHeight,
        }),on:{"mouseleave":_vm.handleMouseLeaveItemsSection}},[(_vm.loading)?_vm._l((3),function(n){return _c('div',{key:n,staticClass:"item",style:(_vm.itemStyle)},[_c('am2-typography-skeleton',{attrs:{"size":"xs","level":2 * n - 1}})],1)}):(_vm.items.length > 0)?_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,ref:"dropdownList",refInFor:true,staticClass:"dropdown-item",attrs:{"data-test-id":"filter-dropdown-segment-list"},on:{"mouseenter":function($event){return _vm.handleMouseEnterItem(idx)}}},[_c('div',{staticClass:"item text",class:[
                _vm.arrowCounter === idx && 'hover-style',
                (_vm.selectedSavedSegment && _vm.selectedSavedSegment.index == idx) && 'hover-style active'
              ],style:(_vm.itemStyle),attrs:{"data-test-id":("filter-dropdown-segment-list-item-" + idx)},on:{"click":function () { _vm.handleItemClick(idx); }}},[_c('ar-text',_vm._b({staticClass:"typography",attrs:{"text":item.name,"size":"xs"}},'ar-text',item.typography,false))],1)])}):(_vm.hasSearch && _vm.items.length === 0)?_c('div',{staticClass:"item",style:(_vm.itemStyle)},[_c('ar-text',{attrs:{"text":_vm.noOptionText,"size":"xs"}})],1):_vm._e()],2)],1):_vm._e()],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"bottom-fader"}),_vm._v(" "),_c('div',{staticClass:"custom-divider",style:(_vm.itemStyle)},[_c('ar-simple-button',{style:({
          width: '101px',
          height: '36px',
        }),attrs:{"data-test-id":"event-filter-apply","text":"Apply"},nativeOn:{"click":function($event){return _vm.handleFiltering.apply(null, arguments)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }