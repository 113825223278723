var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ar-stripe-element-form"},[_c('form',{staticClass:"billing-form",on:{"submit":_vm.handleBillingDetailsForm}},[_c('div',[_c('div',{staticClass:"card-info-input-set"},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Name on card","data-vv-name":"ownerName","autocomplete":"cc-name","has-error":_vm.veeErrors.has('ownerName')},model:{value:(_vm.additionalOwnerInfo.name),callback:function ($$v) {_vm.$set(_vm.additionalOwnerInfo, "name", $$v)},expression:"additionalOwnerInfo.name"}})],1),_vm._v(" "),(_vm.veeErrors.has('ownerName'))?_c('ar-state-message',{style:({
          marginTop: '8px'
        }),attrs:{"text":_vm.veeErrors.first('ownerName'),"type":"error"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-info-input-set",style:({
          marginTop: '20px',
        })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stripeElementLoadingMap.cardNumber),expression:"stripeElementLoadingMap.cardNumber"}],staticClass:"card-info-input u-width-100-percent",style:({
            cursor: _vm.stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
          })},[_c('ar-text',{style:({
              color: _vm.$arStyle.color.blueGrey600,
            }),attrs:{"size":"xs","text":""}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.stripeElementLoadingMap.cardNumber),expression:"!stripeElementLoadingMap.cardNumber"}],class:['card-info-input', _vm.focusStripeInput === 'cardNumber' && 'focus', _vm.cardFieldErrorsMap.cardNumber && 'error'],style:({
            width: '100%',
          }),attrs:{"id":("card-element-number-" + _vm._uid)}})]),_vm._v(" "),(_vm.cardFieldErrorsMap.cardNumber)?_c('ar-state-message',{style:({
          marginTop: '8px'
        }),attrs:{"text":_vm.cardFieldErrorsMap.cardNumber,"type":"error"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-info-input-set",style:({
          marginTop: '20px',
        })},[_c('div',{style:({
            width: 'calc(50% - 8px)',
          })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stripeElementLoadingMap.cardExpiry),expression:"stripeElementLoadingMap.cardExpiry"}],staticClass:"card-info-input",style:({
              cursor: _vm.stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
            })},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.blueGrey600,
              }),attrs:{"size":"xs","text":""}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.stripeElementLoadingMap.cardExpiry),expression:"!stripeElementLoadingMap.cardExpiry"}],class:['card-info-input',_vm.focusStripeInput === 'cardExpiry' && 'focus', _vm.cardFieldErrorsMap.cardExpiry && 'error'],attrs:{"id":("card-element-expiry-" + _vm._uid)}}),_vm._v(" "),(_vm.cardFieldErrorsMap.cardExpiry)?_c('ar-state-message',{style:({
              marginTop: '8px'
            }),attrs:{"text":_vm.cardFieldErrorsMap.cardExpiry,"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
            width: 'calc(50% - 8px)',
          })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stripeElementLoadingMap.cardCvc),expression:"stripeElementLoadingMap.cardCvc"}],staticClass:"card-info-input",style:({
              cursor: _vm.stripeElementLoadingMap.cardExpiry ? 'not-allowed' : null,
            })},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.blueGrey600,
              }),attrs:{"size":"xs","text":""}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.stripeElementLoadingMap.cardCvc),expression:"!stripeElementLoadingMap.cardCvc"}],class:['card-info-input',_vm.focusStripeInput === 'cardCvc' && 'focus', _vm.cardFieldErrorsMap.cardCvc && 'error'],attrs:{"id":("card-element-cvc-" + _vm._uid)}}),_vm._v(" "),(_vm.cardFieldErrorsMap.cardCvc)?_c('ar-state-message',{style:({
              marginTop: '8px'
            }),attrs:{"text":_vm.cardFieldErrorsMap.cardCvc,"type":"error"}}):_vm._e()],1)])],1),_vm._v(" "),(_vm.cardGeneralError)?_c('ar-state-message',{style:({
        marginTop: '40px',
        justifyContent: 'center',
      }),attrs:{"text":_vm.cardGeneralError,"type":"error"}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({
        width: '100%',
        marginTop: _vm.cardGeneralError ? '8px' : '40px',
      }),attrs:{"type":"gradient-purple","text":_vm.submitButtonText,"shape":"pill","loading":_vm.loading || _vm.isValidatingCard}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }