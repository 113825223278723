<template>
<div>
  <InfoBranch
    v-if="!!item.config"
    :content="waitUntilDateString"
    :description="item.config.frontend.timezone"
    :style="{
      marginTop: '2px',
    }"
  />
  </div>
</template>
<script>
import InfoBranch from '../utils/InfoBranch';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
export default {
  name: 'WaitUntil',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    waitUntilDateString() {
      return !!this.item.config && dayjs.tz(this.item.config['wait-until-unix-time-sec'] * 1000, this.item.config.frontend.timezone).format('llll')
    }
  },
}
</script>