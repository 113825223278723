<template>
  <am2-card-container
    :class="[
      'ar-message-list-card-skeleton',
      showAs,
    ]"
  >
    <div class="description-section">
      <am2-typography-skeleton
        size="sm"
        :level="level + 2"
        :style="{
          maxWidth: '150px',
        }"
      />
      <am2-typography-skeleton
        class="u-margin-top-2"
        size="xs"
        :level="level + 2"
        :style="{
          maxWidth: '250px',
        }"
      />
    </div>
    <div class="stats-section">
    </div>
  </am2-card-container>
</template>

<script>
export default {
  name: 'SimpleCardSkeleton',

  props: {
    showAs: {
      type: String,
      default: 'list',
      validator: (value) =>
        ['list', 'tiles'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-message-list-card-skeleton {
  display: flex;

  &.list {
    flex-flow: row;
    align-items: center;
    height: 80px;

    .description-section {
      flex-grow: 1;
      padding: 18px 34px;
      height: 100%;
    }
    .stats-section {
      width: 100%;
      max-width: 255px;
    }
  }
  &.tiles {
    flex-flow: column;
    justify-content: center;

    .description-section {
      border-right: none;
      border-bottom: 1px solid $blueGrey500;
      padding: 18px 18px 18px 22px;
    }
    .stats-section {
      height: 57px;
    }
  }
}
</style>
