var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'select-box',
    _vm.selected && 'active' ],on:{"click":_vm.handleSelectBoxClick}},[_c('div',{staticClass:"img-section"},[_c('div',{staticClass:"image",style:({
        backgroundImage: ("url('" + _vm.imgSrc + "')"),
      })})]),_vm._v(" "),_c('div',{staticClass:"copies-section"},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey800,
      }),attrs:{"size":"16px","text":_vm.title,"weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"description-copies-section",style:({
        marginTop: '7px',
      })},[_c('ar-text',{staticClass:"description-copy",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"12px","text":_vm.description}}),_vm._v(" "),(_vm.extraDescription)?_c('ar-text',{staticClass:"description-copy",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
          marginLeft: '10px',
          marginRight: '10px',
        }),attrs:{"size":"12px","text":"|"}}):_vm._e(),_vm._v(" "),(_vm.extraDescription)?_c('ar-text',{staticClass:"description-copy",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"12px","text":_vm.extraDescription}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"checkbox-section"},[_c('ar-checkbox',{style:({
        pointerEvents: 'none',
      }),attrs:{"value":_vm.selected,"color":_vm.$arStyle.color.green500,"type":"radio"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }