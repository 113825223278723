<template>
  <section class="email-editor-wrapper">
    <am2-select-events-modal
      :opened="displaySelectEventsModal"
      :default-event-oid="defaultEventOidForSelectEventsModal"
      @confirm="handleSelectEventConfirm"
      @cancel="handleSelectEventCancel"
    />
    <am2-loading-section
      v-if="!displayUnlayerEditor"
      class="loading-section"
    />
    <section
      ref="editor"
      :id="`editor-${unlayerEditorHash}`"
      class="editor"
    />
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { generateHash } from '@/utils/helpers'
import { getCustomCSS, createDefaultTemplate } from './utils/general';
import { generateRegisterInsertEventToolScript, generateInsertEventCss } from './utils/insert-event-utils/index';
import { generateRegisterAddDynamicTagsToolScript } from './utils/add-dynamic-tags-utils';

export default {
  name: 'EmailEditor',

  props: {
    dynamicTags: {
      type: Array,
      default: null,
    },
    showDynamicTagsTool: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      arEnableUnlayerInsertEvent: process.env.arEnableUnlayerInsertEvent,
      design: null,
      unlayerEditor: null,
      unlayerEditorHash: generateHash(),
      displayUnlayerEditor: false,
      insertEventCustomToolLoaded: false,
      displaySelectEventsModal: false,
      defaultEventOidForSelectEventsModal: null,
      unlayerSelectEventCallbackId: null,
    };
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    ...mapGetters({
      getEventImageUrl: 'event/getEventImageUrl',
    }),
    dynamicTagsForUnlayer() {
      if (!this.dynamicTags) {
        return [];
      }
      return this.dynamicTags.map(tag => ({
        name: tag,
        value: `{{${tag}}}`,
      }));
    },
    insertEventCustomToolIsReady() {
      if (!this.arEnableUnlayerInsertEvent) {
        return true;
      }
      return this.insertEventCustomToolLoaded;
    },
    unlayerEditorIsReadyForEditting() {
      return !!this.unlayerEditor && this.insertEventCustomToolIsReady;
    },
  },

  watch: {
    dynamicTagsForUnlayer(val) {
      this.setDynamicTags();
    },
    unlayerEditorIsReadyForEditting(val) {
      if (val) {
        this.handleUnlayerEditorIsReadyForEditting(this.arEnableUnlayerInsertEvent ? 400 : 1800);
      }
    },
  },

  // Must be loaded after mounted, otherwise you couldn't find elementId
  mounted() {
    addEventListener('message', this.handleCustomToolEvents);

    if (!window.unlayer) {
      this.downloadLibrary();
    } else {
      this.initEmailEditor();
    }
  },

  beforeDestroy() {
    removeEventListener('message', this.handleCustomToolEvents);
  },

  methods: {
    setDynamicTags() {
      if (!this.unlayerEditor) {
        return;
      }
      this.unlayerEditor.setMergeTags(this.dynamicTagsForUnlayer);
    },
    downloadLibrary() {
      const js = document.createElement("script");

      js.type = 'text/javascript';
      js.src = 'https://editor.unlayer.com/embed.js';

      js.onload = () => {
        this.initEmailEditor();
      };
      document.body.appendChild(js);
    },
    setDesign(design) {
      this.design = design;
      this.loadUnlayerDesign(design);
    },
    async loadUnlayerDesign(design) {
      if (!this.unlayerEditorIsReadyForEditting) {
        return;
      }

      // Let's start listening to Unlayer template changes
      this.unlayerEditor.addEventListener('design:loaded', this.handleDesignLoad);
      this.unlayerEditor.addEventListener('design:updated', this.handleDesignUpdate);
      this.unlayerEditor.loadDesign(design);
    },
    async initEmailEditor() {
      if (!document.getElementById(`editor-${this.unlayerEditorHash}`)) {
        return;
      }
      this.unlayerEditor = window.unlayer.createEditor({
        id: `editor-${this.unlayerEditorHash}`,
        displayMode: 'email',
        projectId: process.env.arUnlayerProjectId,
        mergeTags: this.dynamicTagsForUnlayer,
        user: {
          // When specifying promoter oid, images upload feature will be enabled.
          id: `${process.env.arEnvironment}_promoter_${this.promoterOid}`,
        },
        translations: {
          'en-US': {
            'tools.text.personalize': 'Insert dynamic tag',
            'editor.placeholder.text': 'Drag & drop blocks from the right to build your email.',
            'labels.merge_tags': 'Personalization',
          }
        },
        features: {
          preview: false,
        },
        tools: {
          menu: {
            enabled: false,
          },
          columns: {
            position: 9,
          },
          text: {
            position: 2,
          },
          heading: {
            position: 3,
          },
          image: {
            position: 4,
          },
          button: {
            position: 5,
            properties: {
              buttonColors: {
                value: {
                  backgroundColor: this.$arStyle.color.purple500,
                  hoverColor: this.$arStyle.color.purple600,
                }
              }
            }
          },
          html: {
            position: 6,
          },
          social: {
            position: 7,
          },
          // Form is missing from Unlayer Editor, not sure why
          form: {
            properties: {
              buttonColors: {
                value: {
                  backgroundColor: this.$arStyle.color.purple500,
                  hoverColor: this.$arStyle.color.purple600,
                }
              }
            }
          }
        },
        customCSS: [
          getCustomCSS(this.$arStyle),
          this.arEnableUnlayerInsertEvent ? generateInsertEventCss() : '/* No insert event tool*/',
        ],
        customJS: [
          this.showDynamicTagsTool ? generateRegisterAddDynamicTagsToolScript() : 'console.log("No dynamic tags tool");',
          this.arEnableUnlayerInsertEvent ? generateRegisterInsertEventToolScript() : 'console.log("No insert event tool");',
        ],
      });

      this.loadUnlayerDesign(this.design || createDefaultTemplate());
    },
    handleUnlayerHtmlExport({ design, html }) {
      this.$emit('outputDesign', design);
      this.$emit('outputHtml', html);
    },
    postMessageToUnlayerIframe(message) {
      document.getElementById(`editor-${this.unlayerEditorHash}`).getElementsByTagName('iframe')[0].contentWindow.postMessage(message, '*');
    },
    serEventInUnalyer(event, callbackId) {
      // Let's reduce the size of event before inserting them to Unlayer
      const tinyEvent = {
        oid: event.oid,
        name: event.name,
        description: event.description,
        location: event.location,
        date: this.$arUtils.event.generateDateForEmail(event),
        imageUrl: this.getEventImageUrl(event),
        purchaseTicketUrl: this.$arUtils.event.getEventPurchaseTicketUrl(event),
      };
      this.postMessageToUnlayerIframe({
        type: 'set-ar-event',
        arEvent: tinyEvent,
        callbackId,
      });
    },
    handleUnlayerEditorIsReadyForEditting(waitFor = 400) {
      setTimeout(() => {
        this.displayUnlayerEditor = true;
      }, waitFor);
    },
    handleDesignLoad() {
      this.unlayerEditor.exportHtml(this.handleUnlayerHtmlExport);
    },
    handleDesignUpdate() {
      this.unlayerEditor.exportHtml(this.handleUnlayerHtmlExport);
    },
    async handleInsertEventCustomToolLoaded() {
      this.insertEventCustomToolLoaded = true;

      this.loadUnlayerDesign(this.design || createDefaultTemplate());
    },
    handleCustomToolEvents(event) {
      if (!event.data) {
        return;
      }
      if (event.data.type === 'add-dynamic-tags-tool-click') {
        this.$emit('addDynamicTagsToolClick');
      }
      if (event.data.type === 'add-dynamic-tags-tool-not-found') {
        this.$emit('addDynamicTagsToolNotFound');
      }
      if (event.data.type === 'select-ar-event') {
        this.handleSelectEventClick(event.data.value, event.data.callbackId);
      }
      if (event.data.type === 'refresh-ar-event') {
        this.handleRefreshEventClick(event.data.value, event.data.callbackId);
      }
      if (event.data.type === 'insert-event-custom-tool-loaded') {
        this.handleInsertEventCustomToolLoaded();
      }
    },
    handleSelectEventClick(event, callbackId) {
      this.displaySelectEventsModal = true;
      this.defaultEventOidForSelectEventsModal = event ? event.oid : null;
      this.unlayerSelectEventCallbackId = callbackId;
    },
    async handleRefreshEventClick(event, callbackId) {
      const latestEvent = await this.$api.event.get(this.promoterOid, event.oid);
      this.serEventInUnalyer(latestEvent, callbackId);
    },
    handleSelectEventConfirm(event) {
      this.displaySelectEventsModal = false;
      this.serEventInUnalyer(event, this.unlayerSelectEventCallbackId);
    },
    handleSelectEventCancel() {
      this.displaySelectEventsModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.email-editor-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .editor {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: $zIndexRegular;

    >>> .blockbuilder-content-tool-icon {
      color: red;
    }
  }

  .loading-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: $zIndexHigh;
  }
}
</style>
