var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"order-details-modal",attrs:{"is-open":_vm.isShow,"header":_vm.title,"width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"order-header"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-icon"},[_c('ar-icon',{attrs:{"name":"eventbrite","color":_vm.$arStyle.color.eventbrite,"height":"15px","width":"15px"}})],1),_vm._v(" "),_c('div',{staticClass:"header-title u-margin-left-3"},[(_vm.orderData)?_c('ar-text',{staticClass:"u-margin-top-1",attrs:{"text":("" + (_vm.orderData.provider[0].toUpperCase()) + (_vm.orderData.provider.substring(1))),"size":"xs","weight":"bold"}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-2 event-title",style:({
                color: '#8492A6'
              }),attrs:{"text":_vm.currentEvent ? _vm.currentEvent.name : '',"size":"xs"}})],1)]),_vm._v(" "),_c('div',{staticClass:"header-right"},[(_vm.orderData)?_c('ar-text',{staticClass:"u-margin-top-1",attrs:{"text":("Order #" + (_vm.orderData.orderId)),"size":"xs"}}):_vm._e(),_vm._v(" "),(_vm.orderData)?_c('ar-text',{staticClass:"u-margin-top-2",style:({
              color: '#8492A6'
            }),attrs:{"text":_vm.formatDate(_vm.orderData.orderDate),"size":"xxs"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
        'modal-content',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[(_vm.orderData)?_c('am2-order-items-table',{style:({ height: 'auto' }),attrs:{"data":_vm.orderData.sourceInfo,"orderId":_vm.orderData.orderId,"is-value-currency":"","column-names":['Item','Price', 'Total'],"empty-text":"No data is available"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"order-data-total-wrapper u-margin-top-4 u-margin-bottom-4"},[_c('ar-text',{attrs:{"size":"xs","text":"Total","weight":"bold"}}),_vm._v(" "),(_vm.orderData)?_c('ar-text',{attrs:{"size":"xs","text":((_vm.orderData.orderCosts.gross.currency) + " " + (_vm.orderData.orderCosts.gross.displayValue)),"weight":"bold"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"order-status-wrapper"},[(_vm.orderData)?_c('div',{class:[
              "order-status",
              _vm.orderData && ("order-" + (_vm.orderData.status))
            ]},[_c('ar-text',{style:({
                color: _vm.getColorBasedOnStatus(_vm.orderData.status)
              }),attrs:{"size":"xxs","text":_vm.formatStatus(_vm.orderData.status),"weight":"bold"}})],1):_vm._e()])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }