<template>
  <ar-simple-select
    :hide-arrow="hideArrow"
    enable-filter
    :enable-clear="enableClear"
    :items="facebookPagesDecorated"
    :default-select-index="selectedPageIndex"
    :placeholder="facebookPagesDecorated.length > 0 ? 'Select your Facebook page' : 'Facebook page'"
    @select="handleSelect"
    @clear="() => { $emit('clear') }"
    :disabled="disabled"
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'FacebookPageSelect',
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    enableClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPageId: this.value,
      selectedPageIndex: -1,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.selectedPageId = newVal;
      this.updateSelectedPageIndex();
    },
    facebookPages(newVal) {
      if (newVal) this.updateSelectedPageIndex();
    },

  },
  computed: {
    ...mapState({
      facebookPages: state => state.facebookMessengerIntegration.facebookPages,
    }),
    facebookPagesDecorated() {
      return this.facebookPages.map(({ puid, name, profileImageUrl, paymentSubscriptionOid }) => {
        return {
          id: puid,
          name,
          imageSrc: profileImageUrl,
          paymentSubscriptionOid
        };
      });
    }
  },
  mounted() {
    this.fetchIntegrations();
  },
  methods: {
    ...mapActions([
      'facebookMessengerIntegration/FETCH_FACEBOOK_PAGES',
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'facebookMessengerIntegration/SET_IS_FETCHING_FACEBOOK_PAGES',
    ]),
    async updateSelectedPageIndex() {
      if (!this.selectedPageId || this.facebookPagesDecorated.length === 0) {
        return;
      }
      this.selectedPageIndex = this.facebookPagesDecorated.findIndex(page => page.id === this.selectedPageId);

      // If the page has been removed, show this error message
      if (this.selectedPageIndex === -1) {
        const ans = await this.SHOW_CONFIRM({
          type: 'error',
          messageHtml: 'The Facebook page you selected has been removed from the current integration, click <b>Go to connect</b> to reconnect your integration.',
          confirmButtonText: 'Go to connect',
          cancelButtonText: 'Do it later',
        });
        if (ans) {
          this.$router.push({
            path: '/settings/integrations',
          });
        }
      } else {
        this.$emit('select', this.facebookPagesDecorated[this.selectedPageIndex], this.selectedPageIndex);
      }
    },

    async fetchIntegrations() {
      this['facebookMessengerIntegration/FETCH_FACEBOOK_PAGES']();
    },

    handleSelect(item) {
      this.selectedPageId = item.id;
      this.$emit('input', this.selectedPageId);
      this.updateSelectedPageIndex();
    },
  },
};
</script>
