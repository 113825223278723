<template>
  <InfoBranch
    v-if="!!item.config && item.config['message-list-oid']"
    :content="item.meta.list"
    :style="{
      marginTop: '2px',
    }"
  />
</template>
<script>
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'ListUnsubscribed',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>