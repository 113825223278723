<template>
  <InfoBranch
    v-if="!!item.config"
    :content="eventTitle"
    :description="eventSubDetail"
    :style="{
      marginTop: '2px',
    }"
  />
</template>
<script>
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'PurchasedTicket',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  computed: {
    eventTitle() {
      if (!this.item.config) {
        return null
      } else if (this.item['config']?.['event-oid']) {
        return this.item.meta.name
      } else {
        return 'Any event'
      }
    },
    eventSubDetail() {
      return this.item['config']?.['event-oid'] ? this.item.meta.location : null
    }
  }
}
</script>