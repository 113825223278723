<template>
  <div class="media-preview">
    <div class="media-preview__thumb">
      <slot name="media-preview__content"/>
    </div>
    <div class="media-preview__body">
      <slot name="media-preview__name"/>
      <slot name="media-preview__size"/>

      <!--         <span class="media-preview__name">beyondthevalley2018-10800.mp4</span>
        <span class="media-preview__size">192.4mb</span> -->
    </div>
    <ar-icon-button
      class="media-preview__discard"
      :icon-props="{
        name: 'discard',
        height: '14px',
      }"
      @click="handleDelete" />
  </div>
</template>
<script>
export default {
  name: 'MediaPreview',
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss">
.media-preview {
  display: flex;
  border: 1px solid $skyBlueGrey500;
  border-radius: 0.25rem;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.media-preview__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.media-preview__thumb {
  display: flex;
  flex: 1;
  min-width: 32.54%;
  border-radius: 0.25rem;
  display: inline-block;
  overflow: hidden;

  img {
    display: block;
  }
}

.media-preview__discard {
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px;
}

.media-preview__name {
  font-weight: bold;
  font-size: 0.9375rem;
}

.media-preview__size,
.media-preview__name {
  display: block;
}

.media-preview__size {
  // color: #9fa8b5;
  color: $blueGrey600;
}

.media-preview__name + .media-preview__size {
  margin-top: 0.6875rem;
}
</style>
