var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper content content--centered"},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Facebook Messenger","description":"Send messages to all contacts on Facebook Messenger","text-size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md',"icon-props":{
          name: 'messenger',
          color: _vm.$arStyle.color.messenger,
        }}})],1),_vm._v(" "),(!_vm.integrationFailed)?_c('div',{staticClass:"title-section-right"},[_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":_vm.settingButtonOptions},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.integrationFailed)?_c('section',{style:({ paddingTop: '55px' })},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Facebook Messenger connection is no longer valid. This means you won't be able to opt-in fans or message your subscribers <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.reconnectIntegration}})],1):_c('section',[_c('ar-divider',{style:({
        margin: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '55px 0 41px' : '24px 0 32px',
      })}),_vm._v(" "),_c('am2-heading',{staticClass:"block-heading",attrs:{"type":"h1","title":"Connected pages","size":"sm"}}),_vm._v(" "),((_vm.isFetchingIntegration || _vm.isPolling) && _vm.facebookPages.length === 0)?_c('div',{staticClass:"loading-section"},[_c('am2-loading-bubble')],1):_c('am2-card-container',{staticClass:"page-cards-block"},_vm._l((_vm.facebookPages),function(page){return _c('FacebookPageCard',{key:page.id,staticClass:"page-card",attrs:{"page-info":page,"page-meta":_vm.facebookPageMetaMap[page.id],"connecting":!_vm.facebookPageMetaMap[page.id] && _vm.isPolling,"card-size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs'}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }