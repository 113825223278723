var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-profile"},[_c('div',{staticClass:"input-groups-section"},[_c('div',{class:['input-group', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('div',{staticClass:"label-section"},[_c('ar-text',{attrs:{"size":"xs","weight":"bold","text":"Email address"}})],1),_vm._v(" "),_c('div',{staticClass:"input-section"},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"input",attrs:{"data-vv-name":"emailAddress","autocomplete":"email","placeholder":"Email address"},model:{value:(_vm.editProfileObject.emailAddress),callback:function ($$v) {_vm.$set(_vm.editProfileObject, "emailAddress", $$v)},expression:"editProfileObject.emailAddress"}}),_vm._v(" "),(_vm.veeErrors.has('emailAddress'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('emailAddress'),"type":"error"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{class:['input-group', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('div',{staticClass:"label-section"},[_c('ar-text',{attrs:{"size":"xs","weight":"bold","text":"Name"}})],1),_vm._v(" "),_c('div',{staticClass:"input-section"},[_c('div',{style:({
            display: 'flex',
          })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],style:({
              flexGrow: '1',
            }),attrs:{"data-vv-name":"firstName","placeholder":"First name","autocomplete":"given-name"},model:{value:(_vm.editProfileObject.firstName),callback:function ($$v) {_vm.$set(_vm.editProfileObject, "firstName", $$v)},expression:"editProfileObject.firstName"}}),_vm._v(" "),_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],style:({
              flexGrow: '1',
              marginLeft: '40px',
            }),attrs:{"data-vv-name":"lastName","placeholder":"Last name","autocomplete":"family-name"},model:{value:(_vm.editProfileObject.lastName),callback:function ($$v) {_vm.$set(_vm.editProfileObject, "lastName", $$v)},expression:"editProfileObject.lastName"}})],1),_vm._v(" "),(_vm.veeErrors.has('firstName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('firstName'),"type":"error"}}):_vm._e(),_vm._v(" "),(_vm.veeErrors.has('lastName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('lastName'),"type":"error"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{class:['input-group', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('div',{staticClass:"label-section"},[_c('ar-text',{attrs:{"size":"xs","weight":"bold","text":"Mobile number"}})],1),_vm._v(" "),_c('div',{staticClass:"input-section"},[_c('ar-mobile-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|mobileNumber'),expression:"'required|mobileNumber'"}],staticClass:"input",attrs:{"data-vv-name":"mobileNumber","autocomplete":"tel-national"},model:{value:(_vm.editProfileObject.mobileNumber),callback:function ($$v) {_vm.$set(_vm.editProfileObject, "mobileNumber", $$v)},expression:"editProfileObject.mobileNumber"}}),_vm._v(" "),(_vm.veeErrors.has('mobileNumber'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('mobileNumber'),"type":"error"}}):_vm._e()],1)])]),_vm._v(" "),_c('ar-divider',{style:({
      margin: '70px 0 0',
    })}),_vm._v(" "),_c('div',{staticClass:"functions-section"},[_c('ar-simple-button',{style:({
        width: '170px',
      }),attrs:{"text":"Save changes"},on:{"click":_vm.handleSaveChangeClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }