<template>
  <section>
    <ar-field
      label="Privacy Portal Settings"
      v-validate:hasAnyRequests="'required:true'"
      data-vv-name="hasAnyRequests"
      :error-messages="[veeErrors.first('hasAnyRequests')]"
    >
      <am2-card-container class="u-padding-x-5 u-padding-y-4">
        <div
          :class="[
            'u-display-flex',
            $arMediaQuery.window.maxWidth('xs') && 'u-flex-flow-column'
          ]"
        >
          <am2-switch
            class="u-flex-shrink-0"
            :value="scratchPrivacyPortal.meta.requestsCompletedBy.fan"
            @input="handleCompletedByFanToggle"
          />
          <div
            :class="[
              'u-flex-grow-1',
              'u-margin-top-2',
              $arMediaQuery.window.minWidth('sm') && 'u-margin-left-6',
            ]"
          >
            <ar-field
              label="Self service data privacy management"
              description="Users can login and manage their data without having to contact you"
            />
          </div>
        </div>
      </am2-card-container>
      <am2-card-container class="u-margin-top-3 u-padding-x-5 u-padding-y-4">
        <div
          :class="[
            'u-display-flex',
            $arMediaQuery.window.maxWidth('xs') && 'u-flex-flow-column'
          ]"
        >
          <am2-switch
            class="u-flex-shrink-0"
            :value="scratchPrivacyPortal.meta.requestsCompletedBy.promoter"
            @input="handleCompletedByPromoterToggle"
          />
          <div
            :class="[
              'u-flex-grow-1',
              'u-margin-top-2',
              $arMediaQuery.window.minWidth('sm') && 'u-margin-left-6',
            ]"
          >
            <ar-field
              label="Manual data privacy request"
              description="Users can submit a manual data privacy request for you to action"
            >
              <div v-if="scratchPrivacyPortal.meta.requestsCompletedBy.promoter">
                <ar-link-button
                  v-if="!scratchPrivacyPortal.meta.requestsCompletedBy.promoter"
                  text="Customize request options"
                  @click="scratchPrivacyPortal.meta.requestsCompletedBy.promoter = true"
                />
                <div
                  v-if="scratchPrivacyPortal.meta.requestsCompletedBy.promoter"
                  class="u-display-flex u-flex-flow-column"
                >
                  <div
                    v-for="(requestOption, requestOptionIndex) of scratchPrivacyPortal.meta.promoterCompletedRequestOptions"
                    :key="requestOptionIndex"
                    class="u-margin-bottom-2 u-display-flex u-align-items-center"
                    :style="{
                      height: '30px',
                    }"
                  >
                    <ar-checkbox
                      :value="requestOption.enabled"
                      @input="handlePromoterCompletedRequestToggle(requestOptionIndex, ...arguments)"
                    />
                    <ar-input
                      v-if="editingManualRequestOptionIdx === requestOptionIndex"
                      :value="requestOption.title"
                      class="u-margin-left-2"
                      placeholder="e.g: Correct my personal data"
                      auto-focus
                      :style="{
                        height: '30px',
                      }"
                      @blur="handleRequestOptionBlurOrEnter(requestOptionIndex)"
                      @enter="handleRequestOptionBlurOrEnter(requestOptionIndex)"
                      @input="handleRequestOptionAdd(requestOptionIndex, ...arguments)"
                    />
                    <ar-text
                      v-else
                      class="u-margin-left-2 u-flex-grow-1"
                      size="xs"
                      :text="requestOption.title"
                      :style="{
                        cursor: 'pointer',
                      }"
                      @click.native="editingManualRequestOptionIdx = requestOptionIndex"
                    />
                    <ar-icon-button
                      v-if="requestOption.type === 'other'"
                      :icon-props="{
                        name: 'discard',
                        height: '16px',
                      }"
                      @click="handleRequestOptionDiscard(requestOptionIndex)"
                    />
                  </div>
                  <ar-link-button
                    v-if="editingManualRequestOptionIdx === -1"
                    text="+ Add option"
                    @click="handleAddOptionClick"
                  />
                </div>
              </div>
            </ar-field>
          </div>
        </div>
      </am2-card-container>
    </ar-field>
    <ar-field
      class="u-margin-top-6"
      label="Send Email Notifications To"
      :error-messages="[veeErrors.first('emailAddress')]"
    >
      <ar-input
        :value="scratchPrivacyPortal.meta.notifications.emailAddress"
        @input="handleEmailAddressInput"
        v-validate="'required|email'"
        data-vv-name="emailAddress"
        data-vv-as="email address"
        placeholder="Your email address"
      />
    </ar-field>
    <ar-field
      class="u-margin-top-6"
      label="Notify me each time there is a"
    >
      <div>
        <ar-checkbox
          label="Self service request"
          :value="scratchPrivacyPortal.meta.notifications.requestsCompletedBy.fan"
          @input="handleFanCompletedNotificationInput"
        />
      </div>
      <div class="u-margin-top-2">
        <ar-checkbox
          label="Manual request"
          disabled
          :value="scratchPrivacyPortal.meta.notifications.requestsCompletedBy.promoter"
          v-tooltip="{
            content: 'Email notifications for manual requests cannot be disabled',
          }"
        />
      </div>
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mergeObjects } from '@/utils/helpers';

export default {
  data() {
    return {
      editingManualRequestOptionIdx: -1,
    };
  },
  computed: {
    ...mapState({
      scratchPrivacyPortal: state => state.privacyPortal.scratchPrivacyPortal,
    }),
    hasAnyRequests() {
      return this.scratchPrivacyPortal.meta.requestsCompletedBy.fan || this.scratchPrivacyPortal.meta.requestsCompletedBy.promoter;
    },
  },
  methods: {
    ...mapMutations([
      'privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY',
    ]),
    removeRequestOption(index) {
      const promoterCompletedRequestOptions =
        this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions.filter((option, optionIndex) => {
          return optionIndex !== index;
        });
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          promoterCompletedRequestOptions,
        },
      });
    },
    handleFanCompletedNotificationInput(fan) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          notifications: {
            requestsCompletedBy: {
              fan,
            },
          },
        },
      });
    },
    handleEmailAddressInput(emailAddress) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          notifications: { emailAddress },
        },
      });
    },
    handleCompletedByFanToggle(fan) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          requestsCompletedBy: { fan },
        },
      });
    },
    handleCompletedByPromoterToggle(promoter) {
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          requestsCompletedBy: { promoter },
        },
      });      
    },
    handlePromoterCompletedRequestToggle(index, enabled) {
      const promoterCompletedRequestOptions =
        this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions.map((option, optionIndex) => {
          if (index === optionIndex) {
            return mergeObjects(option, { enabled });
          } else {
            return option;
          }
        });
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: { promoterCompletedRequestOptions },
      });
    },
    handleRequestOptionDiscard(index) {
      this.removeRequestOption(index);
    },
    handleRequestOptionBlurOrEnter(index) {
      this.editingManualRequestOptionIdx = -1;
      // Delete the option if title is null
      if (!this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions[index].title) {
        this.removeRequestOption(index);
      }
    },
    handleRequestOptionAdd(index, title) {
      const promoterCompletedRequestOptions =
        this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions.map((option, optionIndex) => {
          if (index === optionIndex) {
            return mergeObjects(option, { title });
          } else {
            return option;
          }
        });
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          promoterCompletedRequestOptions,
        },
      });
    },
    handleAddOptionClick() {
      const promoterCompletedRequestOptions =
        this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions
          .concat([{ enabled: true, title: '', type: 'other' }]);
      this['privacyPortal/PATCH_SCRATCH_PRIVACY_POLICY']({
        meta: {
          promoterCompletedRequestOptions,
        },
      });
      this.editingManualRequestOptionIdx = this.scratchPrivacyPortal.meta.promoterCompletedRequestOptions.length - 1;
    },
  },
  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          hasAnyRequests: {
            required: 'You must have self service or manual requests enabled (or both)',
          },
        },
      },
    });
  }
};
</script>
