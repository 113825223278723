<template>
  <PlanFrame
    title="Select Your Plan"
    description="Select a plan that suits your needs."
  >
    <div :class="[
      'plans-page',
      $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
    ]">
      <am2-loading-section
        v-if="isFetchingPaymentPlans || isFetchingPaymentSubscriptions || isFetchingPromoterAudienceCount"
      />
      <div
        v-else
        class="u-display-flex"
      >
        <div
          v-for="(basePlan, idx) of baseYearlyPaymentPlans"
          :key="basePlan.puid"
          :style="{
            position: 'relative',
            top: basePlan.additionalInfo.metaData.presentation.isMostPopular ? '-30px' : '0',
            marginRight: idx !== baseYearlyPaymentPlans.length - 1 ? $arMediaQuery.pageContent.maxWidth('md') ? '12px' : '40px' : '0',
          }"
        >
          <PlanCard
            :payment-plan="basePlan"
            :is-most-popular="basePlan.additionalInfo.metaData.presentation.isMostPopular"
            :payment-subscription="basePlan.paymentSubscriptions[0]"
            :base-payment-subscription="advancedPaymentPlanSubscriptionInformation.basePaymentSubscription"
            :real-contacts-number="promoterAudienceCount"
            :style="{
              width: '380px',
              maxWidth: '88vw',
            }"
            @click="handlePlanSelect(idx)"
          />
        </div>
      </div>
    </div>
  </PlanFrame>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PlanCard from './components/plan-card';
import PlanFrame from './components/plan-frame';

export default {
  name: 'PaymentPlans',

  components: {
    PlanCard,
    PlanFrame,
  },
  layout: 'mono',

  data() {
    return {
      isFetchingPaymentSubscriptions: false,
    };
  },

  computed: {
    ...mapState({
      paymentPlanList: state => state.payment.paymentPlanList,
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      promoterAudienceCount: state => state.audience.promoterAudienceCount,
      isFetchingPromoterAudienceCount: state => state.audience.isFetchingPromoterAudienceCount,
    }),
    ...mapGetters({
      advancedPaymentPlanSubscriptionInformation: 'payment/advancedPaymentPlanSubscriptionInformation',
    }),
    isFetchingPaymentPlans() {
      return this.paymentPlanList.length === 0;
    },
    baseYearlyPaymentPlans() {
      return this.advancedPaymentPlanSubscriptionInformation.basePaymentPlans.filter(({ billingPeriod, clientVisible }) => billingPeriod === 'year' && clientVisible);
    },
  },

  beforeRouteEnter(to, from, next) {
    // If you came from non-plan page, we save the state of previous page, so we can back to there after
    if (from && from.path.indexOf('/plans') !== 0) {
      window.sessionStorage.setItem('_ar_plan_exit_route_', JSON.stringify({
        path: from.path,
        params: from.params,
      }));
    }

    next();
  },

  async created() {
    this['payment/FETCH_PAYMENT_PLANS']({});
    this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']({});
    this['audience/FETCH_PROMOTER_AUDIENCE_COUNT']({});
  },

  methods: {
    ...mapActions([
      'payment/FETCH_PAYMENT_PLANS',
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'audience/FETCH_PROMOTER_AUDIENCE_COUNT',
    ]),
    handlePlanSelect(idx) {
      this.$router.push({
        path: `/plans/${this.baseYearlyPaymentPlans[idx].oid}/subscribe`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.plans-page {
  &.lg-max {
    overflow:scroll;
    padding-top: 32px;
  }
}
</style>
