<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="reviewConditionTitle"
    :readonly-items="readonlyItems"
    :apply-disabled="!isEditConditionComplete"
    :additional-constraints="filterCriteria.constraints"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="openChangeMode"
  >
    <div slot="content" class="wrapper">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <div class="select-section">
          <ar-simple-select
            :items="textTypeItems"
            :default-select-index="textTypeSelectedIndex"
            placeholder="Select your type..."
            class="select"
            @select="handlePredicateSelect"
            data-test-id="filter-predicate-selector"
          />
        </div>
        <div class="input-section">
          <ar-input
            ref="input"
            class="input"
            v-if="editFilterCondition.data.condition !== 'is_known' && editFilterCondition.data.condition !== 'is_unknown' && inputType === 'text'"
            :value="editFilterCondition.data.value"
            :auto-focus="shouldAutoFocus"
            :placeholder="`Enter ${filterCriteria.title.toLowerCase()}`"
            :autocomplete="generateInputAutocomplete(filterCriteria.title)"
            @input="handleInputChange"
            @enter="handleEnterPress"
            data-test-id="filter-text-input"
          />
          <ar-country-select
            v-else-if="inputType === 'country'"
            :value="editFilterCondition.data.value"
            value-type="iso"
            :style="{
              width: '100%',
              height: '42px',
            }"
            @select="handleCountrySelect"
            data-test-id="filter-country-selector"
          />
        </div>
        <AdditionalConstraintRenderer
          v-if="criteriaHasConstraints"
          class="u-margin-top-3"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
        <div
          v-if="!!criteriaPickerColumn && !!criteriaPickerResource"
          class="secondary-query-result">
          <ar-text
            size="12px"
            :text="secondaryQueryResultText"
            weight="normal"
            multiple-lines
            align="right"
            class="u-margin-top-2"
            :max-lines="1"
            :style="{
              color: $arStyle.color.skyBlueGrey600,
            }"
          />
        </div>
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'
import { clone } from '@/utils/helpers/';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint } from '@/utils/filter';
import { debounce } from "debounce";
import { filterCriteriaMandatoryConstraintsFilled } from '~/utils/filter';
import { mapState } from 'vuex'

export default {
  title: 'FreeTextPicker',
  name: 'FreeTextPicker',
  components: {
    RendererFrame,
    AdditionalConstraintRenderer
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isReadOnly: false,
      cachedFilterCondition: clone(this.filterCondition),
      editFilterCondition: clone(this.filterCondition),
      criteriaTargetResourcesMatches: 0,
      debouncing: false,
    };
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    shouldAutoFocus() {
      return !this.disableReadMode
    },
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
    secondaryQueryResultText() {
      if (this.debouncing) return "Counting..."
      if (this.criteriaTargetResourcesMatches === null) return "";
      if (this.criteriaTargetResourcesMatches === 0 && this.editFilterCondition?.data?.value?.length === 0) {
        return "";
      }
      let prettyResourceName = this.criteriaPickerResource;
      let prettyResourceNamePlural = `${this.criteriaPickerResource}s`;
      if (this.criteriaPickerResource === 'event_ticket_class') {
        prettyResourceName = 'ticket class';
        prettyResourceNamePlural = 'ticket classes';
      } else if (this.criteriaPickerResource === 'message_task') {
        prettyResourceName = 'message';
        prettyResourceNamePlural = 'messages';
      }
      if (this.criteriaTargetResourcesMatches === 0) return "None found";
      if (this.criteriaTargetResourcesMatches === 1) return `1 ${prettyResourceName} found`;
      return `${this.criteriaTargetResourcesMatches} ${prettyResourceNamePlural} found`;
    },
    criteriaPickerResource() {
      return this.filterCriteria?.targetResource;
    },
    criteriaPickerColumn() {
      return this.filterCriteria?.targetColumn;
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition)
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    textTypeItems() {
      return this.filterCriteria.data.predicates.map(predicate => ({
        name: predicate.title,
        value: predicate.value,
      }));
    },
    textTypeSelectedIndex() {
      for (let i = 0; i < this.filterCriteria.data.predicates.length; i += 1) {
        if (this.filterCriteria.data.predicates[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    reviewConditionTitle() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      const condition = this.editFilterCondition.data.condition;
      if (condition === 'is_known' || condition === 'is_unknown') {
        return null;
      }

      const clickedPredicate = this.findPredicate(condition);
      return clickedPredicate ? clickedPredicate.title.toLowerCase() : null;
    },
    readonlyItems() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      if (this.editFilterCondition.data.condition === 'is_known' || this.editFilterCondition.data.condition === 'is_unknown') {
        return [{ name: this.findPredicate(this.editFilterCondition.data.condition).title }];
      }
      return [{ name: this.editFilterCondition.data.value }];
    },
    inputType() {
      // TODO - Eventually we'll be adding a generic 'select' type here for a few other hybrid free-text/select filter types
      if (this.editFilterCondition.data.condition === 'is_exactly' && this.filterCriteria?.resource === 'country') {
        return 'country'
      }
      return 'text';
    },
  },

  async created() {
    this.isReadOnly = this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);

    this.debouncedReview = debounce(this.review, 500);
  },

  methods: {
    generateInputAutocomplete(title) {
      return 'filter-' + title.toLowerCase().replace(/ /g, '-');
    },
    findPredicate(condition) {
      return this.filterCriteria.data.predicates.find(
        predicate => predicate.value === condition
      );
    },
    review() {
      this.debouncing = false;
      this.$emit('apply', clone(this.editFilterCondition));
      this.fetchTargetResourceMatches();
    },
    // If this component fetches a secondary resource, do that fetch here
    async fetchTargetResourceMatches() {
      if (!this.criteriaPickerColumn || !this.criteriaPickerResource) return null;
      try {
        let filter = '';
        let valueA = this.editFilterCondition.data.value;

        if (this.editFilterCondition.data.condition === 'contains') {
          filter = `${this.criteriaPickerColumn} ILIKE "%${valueA}%"`
        } else if (this.editFilterCondition.data.condition === 'starts_with') {
          filter = `${this.criteriaPickerColumn} ILIKE "${valueA}%"`
        }
        if (this.criteriaPickerResource === 'tag') {
          filter = `${filter} AND external = FALSE`
        } else if (this.criteriaPickerResource === 'campaign') {
          filter = `${filter} AND type != 'opt-in' AND type !='rsvp'`
        } else if (this.criteriaPickerResource === 'event') {
          filter = `${filter} AND userDefined`
        }

        this.criteriaTargetResourcesMatches = null;
        if (filter.length === 0 || !valueA || valueA.length === 0) {
          this.criteriaTargetResourcesMatches = 0;
          return null;
        }

        if (this.criteriaPickerResource === 'campaign') {
          const res = await this.$api.campaign.fetchCount(this.promoterOid, {filter})
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        } else if (this.criteriaPickerResource === 'event') {
          const res = await this.$api.event.fetchCount(this.promoterOid, {filter})
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        } else if (this.criteriaPickerResource === 'tag') {
          const res = await this.$api.tags.fetchCount(this.promoterOid, {filter})
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        } else if (this.criteriaPickerResource === 'event_ticket_class') {
          const res = await this.$api.ticketClasses.fetchCount(this.promoterOid, {filter})
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        } else if (this.criteriaPickerResource === 'message_task') {
          const res = await this.$api.messages.fetchCount(this.promoterOid, {
            searchString: valueA,
            messageStatus: 'completed'
          })
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        }


      } catch(e) {
        this.$arNotification.push({ type: 'error', message: `Error fetching ${this.criteriaPickerColumn}s matching search conditions` })
        console.error(e)
      }


    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleConfirmClick() {
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
    },
    handleInputChange(val) {
      this.$set(this.editFilterCondition.data, 'value', val);
      this.debouncing = true;
      this.debouncedReview();
    },
    handleEnterPress() {
      if (!this.isEditConditionComplete || this.hideButton) {
        return;
      }
      this.handleConfirmClick();
    },
    handlePredicateSelect(item) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.condition === item.value) {
        return;
      }
      const newCondition = clone(this.editFilterCondition);
      newCondition.data.condition = item.value;

      if (item.value === 'is_known' || item.value === 'is_unknown' || item.value === 'is_exactly') {
        delete newCondition.data.value;
      }

      if (this.editFilterCondition.data.condition === 'is_exactly' && item.value !== 'is_exactly') {
        delete newCondition.data.value;
      }

      this.editFilterCondition = newCondition;

      if (this.$refs.input) {
        this.$refs.input.focus();
      }

      this.review();
    },
    handleCountrySelect(item) {
      this.$set(this.editFilterCondition.data, 'value', item.iso);
      this.debouncedReview();
    },
    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },
    openChangeMode() {
      this.isReadOnly = false;
      this.fetchTargetResourceMatches();
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .select-section {
    .select {
      height: 40px;
    }
  }
  .input-section {
    margin-top: 10px;

    .input {
      height: 40px;
    }
  }
}
</style>
