<template>
  <div class="sms-recipient-dropdown-wrapper">
    <DropdownButton
      data-attr="recipient-dropdown"
      :options="recipientOptions"
      v-model="selected"
    />
    
    <template v-if="selectedRecipientOption.key === 'user-external'">
       <ar-mobile-input
          class="send-sms-mobile-wrapper"
          v-validate="'mobileNumber'"
          data-vv-name="input.name"
          data-vv-as="recipient mobile number"
          v-model="selected.phone"
          autocomplete="tel-national"
          input-font-size="xs"
          enable-clear
          multiple-lines
        />
    </template>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import DropdownButton from '../utils/DropdownButton.vue'
export default {
  name: 'RecipientDropdown',
  components: {
    ArrowHead,
    DropdownButton,
  },
  props: {
    recipientData: {
      type: Object,
      default: null,
    }
  },
  model: {
    prop: 'recipientData',
    event: 'selection'
  },
  data() {
    return {
      dropdownOpen: false,
      recipientOptions: [
        { key: 'user-in-automation', name: 'To user in automation', phone: null },
        { key: 'user-external', name: 'Specify someone', phone: null },
      ],
      selectedRecipientOption: { key: 'user-in-automation', name: 'To user in automation', phone: null },
    }
  },
  watch: {
    recipientData: {
      immediate: true,
      handler() {
        if (this.recipientData) {
          this.selectedRecipientOption = this.recipientData
        }
      }
    },
    'selectedRecipientOption.phone'() {
      this.$emit('selection', this.selectedRecipientOption)
    }
  },
  computed: {
    selected: {
      get: function() {
        return this.selectedRecipientOption
      },
      set: function(val) {
        this.$emit('selection', val)
      }
    }
  },
  methods: {
    handleSelected(option) {
      this.$emit('selection', option)
      this.selectedRecipientOption = option
    },
    toggleOptions() {
      this.dropdownOpen = !this.dropdownOpen
    }
  }
}
</script>
<style lang="scss" scoped>
.sms-recipient-dropdown-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;

  .dropdown-button {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px $blueGrey500;
    background-color: #fff;
    height: 48px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 16px;
    margin-bottom: 4px;;
    cursor: pointer;

    &.active {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;

      .sms-arrowhead {
        transform: rotate(180deg);
      }
    }

    .selected-option-name {
      border: none;
      outline: none;
      cursor: pointer;
      height: 35px;
      width: 258px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      color: $blueGrey800;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .send-sms-mobile-wrapper {
    width: 100%;
    margin-top: 5px;
  }
}
</style>