<template>
<div class="date-range-dropdown-wrapper" v-click-outside="handleClickOutside">
  <div class="dropdown-container">
    <div
      :class="[
        'dropdown-button-wrapper',
        dropdownOpen && 'active',
      ]"
      :data-test-id="`${testingId}-button`"
      @click="handleButtonClick"
    >
      <ar-text
        size="xs"
        class="dropdown-button"
        multiple-lines
        :text="selectedFilter.name"
        weight="normal"
      />
      <ArrowHead
        :class="[
          'dropdown-arrowhead',
          dropdownOpen && 'rotate',
        ]"
        fill="#8e97a6"
        width="10px"
        height="10px"
      />
    </div>
    <div v-if="dropdownOpen" class="dropdown-options-wrapper" :style="{width: calendarOpen ? '330px' : '140px'}">
      <template v-for="option in filterOptions">
        <div :key="option.value" class="option-wrapper">
          <ar-text
            :class="[
              'filter-option',
              option.value === selectedFilter.value && 'selected',
            ]"
            :data-test-id="`${testingId}-option`"
            @click.native="handleOptionClick(option)"
            size="xs"
            multiple-lines
            :text="option.name"
            :weight="option.value === selectedFilter.value ? 'bold' : 'normal'"
          />
          <div v-if="option.value === 'range' && calendarOpen" class="filter-calendar-wrapper">
            <DatePicker
              data-test-id="`events-list-date-filter"
              class="date-picker"
              :filter-criteria="filterMap"
              :filter-condition="filterCondition"
              :hide-button="true"
              :disable-read-mode="true"
              @apply="applyCondition"
              @delete="deleteCondition"
            />
            <ar-simple-button
              class="filter-button"
              text="Filter"
              outlined
              :style="{
                height: '40px',
              }"
              :data-test-id="`${testingId}-filter-button`"
              @click="handleFilter"
              :disabled="filterDisabled"
            />
          </div>
        </div>
      </template>

    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DatePicker from '@/components/filters/DatePicker'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import dayjs from 'dayjs';
export default {
  name: 'DateRangeDropdown',
  components: {
    DatePicker,
    ArrowHead,
  },
  props: {
    testingId: {
      type: String,
      default: 'date-range-filter'
    }
  },
  data() {
    return {
      dropdownOpen: false,
      calendarOpen: false,
      selectedFilter: { name: 'All time', value: 'all' },
      filterOptions: [
        {
          name: 'All time',
          value: 'all',
        },
        {
          name: 'Upcoming',
          value: 'future',
        },
        {
          name: 'Previous',
          value: 'past',
        },
        {
          name: 'Date range',
          value: 'range',
        },
      ],
      filterCondition: {
        data: {
          condition: "is_equal_to",
          values: [],
        },
        name: "registrationTime",
        type: "date",
      },
      defaultFilterData: {
        condition: "is_equal_to",
        values: [],
      },
      filterActive: false,
    }
  },
  watch: {
    dropdownOpen() {
      if (this.dropdownOpen) {
        disableBodyScroll(document.body)
      } else {
        enableBodyScroll(document.body)
      }
    }
  },
  computed: {
    ...mapGetters({
      filterMap:'event/getFilterMap',
    }),
    filterDisabled() {
      return !this.filterCondition?.data?.values.length
    }
  },
  methods: {
    handleFilter() {
      this.$emit('date-filter', this.filterCondition)
      this.calendarOpen = false
      this.dropdownOpen = false
      this.filterActive = true
    },
    handleClickOutside(e) {
      if (!this.dropdownOpen) return
      let clickOutsideClasses = ['events', 'events-controls', 'events-container', 'message-listing-hero-container', 'cards-container', 'events-wrapper']
      if ([...e.target.classList].some(item => clickOutsideClasses.includes(item))) {
        this.dropdownOpen = false
        this.calendarOpen = false
        if (!this.filterActive) {
          this.filterCondition = {
            ...this.filterCondition,
            data: this.defaultFilterData,
          }
          this.selectedFilter = this.filterOptions[0]
        } else {
          this.selectedFilter = this.filterOptions[1]
        }
      }
    },
    handleOptionClick(item) {
      if (this.selectedFilter.value === item.value) return
      if (item.value === 'all') {
        this.$emit('date-filter', null)
        this.selectedFilter = item
        this.calendarOpen = false
        this.dropdownOpen = false
        this.filterActive = false
        this.$nextTick(() => {
          this.filterCondition = {
            ...this.filterCondition,
            data: this.defaultFilterData,
          }
        })
      } else if (item.value === 'past') {
        const dateToUse = dayjs().format("YYYY-MM-DD");
        this.selectedFilter = item
        this.calendarOpen = false
        this.dropdownOpen = false
        this.filterActive = false
        this.$nextTick(() => {
          this.filterCondition = {
            ...this.filterCondition,
            data: {
              condition: 'is_before',
              values: [dateToUse]
            },
          }
          this.$emit('date-filter', this.filterCondition)
        })
      } else if (item.value === 'future') {
        const dateToUse = dayjs().format("YYYY-MM-DD");
        this.selectedFilter = item
        this.calendarOpen = false
        this.dropdownOpen = false
        this.filterActive = false
        this.$nextTick(() => {
          this.filterCondition = {
            ...this.filterCondition,
            data: {
              condition: 'is_after',
              values: [dateToUse]
            },
          }
          this.$emit('date-filter', this.filterCondition)
        })
      } else {
        this.selectedFilter = item
        this.calendarOpen = true
      }
    },
    handleButtonClick() {
      this.dropdownOpen = !this.dropdownOpen
      if (this.selectedFilter.value === 'range') {
        this.calendarOpen = true
      }
    },
    applyCondition(payload) {
      this.filterCondition = {
        ...this.filterCondition,
        data: payload.data,
      }
    },
    deleteCondition() {
      // not being used, but is available and can be extended upon if needed
    },
  }
}
</script>
<style lang="scss">
.date-range-dropdown-wrapper {
  margin-right: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .dropdown-container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;

    .dropdown-button-wrapper {
      width: 140px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 14px 13px 14px 12px;
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      cursor: pointer;
      align-self: flex-end;
      background: white;

      .dropdown-button {
        text-align: left;
      }

      .dropdown-arrowhead {
        color: $blueGrey700;
      }

      &.active {
        box-shadow: 0 0 0 3px $green200;
      }
    }


    .dropdown-options-wrapper {
      background: white;
      align-self: flex-end;
      margin-top: 5px;
      border-radius: 4px;
      border: 1px solid $blueGrey500;
      padding: 8px 0;
      position: absolute;
      z-index: 99;
      top: 50px;
      right: 0;
      box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.07);

      .option-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        .filter-option {
          width: 100%;
          padding: 9px 15px;
          cursor: pointer;

          &.selected {
            color: $purple500;
            background: $purple100;
            font-weight: bold;
          }

          &:hover {
            color: $purple500;
            background: $purple100;
          }
        }

        .filter-calendar-wrapper {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .date-picker, .filter-button {
            width: 100%;
          }

          .date-picker {
            .renderer-frame-right-top-section, .renderer-frame-title-section {
              display: none;
            }
            .renderer-frame-content-wrapper {
              .renderer-frame-content {
                margin-top: 0;
              }
            }
          }

          .filter-button {
            margin-top: 12px;
            margin-bottom: 5px;
            width: 87%;
          }
        }
      }
    }
  }
}
</style>
