import { emailHtmlRenderer, htmlCssTemplate  } from './email-html-renderer';

export const generateInsertEventCss = (rootClassName: string = 'ar-event-card') =>  htmlCssTemplate.replace(/{{rootClassName}}/g, `.${rootClassName}`);

export const generateRegisterInsertEventToolScript = function () {
  return `
    unlayer.registerPropertyEditor({
      name: 'ar_event_select',
      Widget: unlayer.createWidget({
        render(value, updateValue, data) {
          if (!value) {
            return \`
              <div class="blockbuilder-widget-label">
                <label class="blockbuilder-label-primary">
                  Select an event
                </label>
                <div>
                  <button type="button" class="select-ar-event-button btn btn-primary btn-sm">Select Event</button>
                </div>
              </div>
            \`;
          }
          return \`
            <div class="blockbuilder-widget-label" style="padding-bottom: 15px;">
              <label class="blockbuilder-label-primary">
                Want to change event?
              </label>
              <div>
                <button type="button" class="select-ar-event-button btn btn-primary btn-sm">Change Event</button>
              </div>
            </div>
            <div class="blockbuilder-widget-label" style="padding-top: 15px; border-top: 1px solid rgb(225, 228, 231);">
              <label class="blockbuilder-label-primary">
                Refresh event with new data
              </label>
              <div>
                <button type="button" class="refresh-ar-event-button btn btn-primary btn-sm">Refresh Event</button>
              </div>
            </div>
          \`
        },
        mount(node, value, updateValue, data) {
          var selectArEventButton = node.getElementsByClassName('select-ar-event-button')[0];
          if (selectArEventButton) {
            selectArEventButton.onclick = function() {
              var callbackId = Math.random().toString(16).substring(2, 16);
              var handleSelectEventComplete = function (evt) {
                const { type, arEvent, callbackId } = evt.data;
                if (type !== 'set-ar-event' || callbackId !== callbackId) {
                  return;
                }
                updateValue(arEvent);
                window.removeEventListener('message', handleSelectEventComplete);
              };
              window.addEventListener('message', handleSelectEventComplete);
              window.parent.postMessage({
                type: 'select-ar-event',
                value,
                callbackId,
              }, '*');
            };
          }

          var refreshArEventButton = node.getElementsByClassName('refresh-ar-event-button')[0];
          if (refreshArEventButton) {
            refreshArEventButton.onclick = function() {
              refreshArEventButton.innerText = 'loading...';
              var callbackId = Math.random().toString(16).substring(2, 16);
              var handleRefreshEventComplete = function (evt) {
                const { type, arEvent, callbackId } = evt.data;
                if (type !== 'set-ar-event' || callbackId !== callbackId) {
                  return;
                }
                refreshArEventButton.innerText = 'Refresh Event';
                updateValue(arEvent);
                window.removeEventListener('message', handleRefreshEventComplete);
              };
              window.addEventListener('message', handleRefreshEventComplete);
              window.parent.postMessage({
                type: 'refresh-ar-event',
                value,
                callbackId,
              }, '*');
            };
          }
        }
      })
    });
    unlayer.registerTool({
      name: 'insert_event',
      icon: '${process.env.arAudienceManagerUrl}/images/unlayer/insert-events.svg',
      label: 'Insert Events',
      values: {},
      position: 1,
      options: {
        basic: {
          title: "Basics",
          position: 1,
          options: {
            "wrap": {
              "label": "Wrap content?",
              "defaultValue": false,
              "widget": "toggle"
            },
          },
        },
        event: {
          title: "Event",
          position: 2,
          options: {
            "arEvent": {
              "label": "Event",
              "defaultValue": null,
              "widget": "ar_event_select"
            },
          },
        },
        buyTicketsButton: {
          title: "Buy Tickets Button",
          position: 3,
          options: {
            "showBuyTicketsButton": {
              "label": "Show Buy Tickets Button?",
              "defaultValue": true,
              "widget": "toggle"
            },
            "buyTicketsButtonText": {
              "label": "Button Text",
              "defaultValue": "Buy Tickets",
              "widget": "text"
            },
            "buyTicketsButtonLink": {
              "label": "Ticket Purchase URL",
              "defaultValue": "",
              "widget": "text"
            },
            "buyTicketsButtonTextColor": {
              "label": "Text Color",
              "defaultValue": "#FFFFFF",
              "widget": "color_picker"
            },
            "buyTicketsButtonBackgroundColor": {
              "label": "Background Color",
              "defaultValue": "#7344c0",
              "widget": "color_picker"
            }
          }
        },
        image: { // Property Group
          title: "Event Image",
          position: 4,
          options: {
            "showImage": {
              "label": "Show Image?",
              "defaultValue": true,
              "widget": "toggle"
            }
          }
        },
        title: {
          title: "Event Title",
          position: 5,
          options: {
            "showTitle": {
              "label": "Show Title?",
              "defaultValue": true,
              "widget": "toggle"
            },
            "titleTextColor": {
              "label": "Text Color",
              "defaultValue": "#43516B",
              "widget": "color_picker"
            },
            "titleFontSize": {
              "label": "Font Size",
              "defaultValue": "16",
              "widget": "counter"
            }
          }
        },
        venueName: {
          title: "Venue Name",
          position: 6,
          options: {
            "showVenueName": {
              "label": "Show Venue Name?",
              "defaultValue": true,
              "widget": "toggle"
            },
            "venueNameTextColor": {
              "label": "Text Color",
              "defaultValue": "#43516B",
              "widget": "color_picker"
            },
            "venueNameFontSize": {
              "label": "Font Size",
              "defaultValue": "14",
              "widget": "counter"
            }
          }
        },
        date: {
          title: "Event Date",
          position: 7,
          options: {
            "showDate": {
              "label": "Show Date?",
              "defaultValue": true,
              "widget": "toggle"
            },
            "dateTextColor": {
              "label": "Text Color",
              "defaultValue": "#43516B",
              "widget": "color_picker"
            },
            "dateFontSize": {
              "label": "Font Size",
              "defaultValue": "14",
              "widget": "counter"
            }
          }
        },
      },
      transformer: function(values, source) {
        const { name, value, data } = source;
        const newValues = JSON.parse(JSON.stringify(values));
        if (name === 'arEvent') {
          // Populate purchaseTicketUrl if it exists.
          newValues["buyTicketsButtonLink"] = value.purchaseTicketUrl;
        }
        return newValues;
      },
      propertyStates: function (values, a) {
        const disabledOptions = {};
        if (!values.showVenueName) {
          disabledOptions.venueNameTextColor = {
            enabled: false,
          };
          disabledOptions.venueNameFontSize = {
            enabled: false,
          };
        }
        if (!values.showTitle) {
          disabledOptions.titleTextColor = {
            enabled: false,
          };
          disabledOptions.titleFontSize = {
            enabled: false,
          };
        }
        if (!values.showDate) {
          disabledOptions.dateTextColor = {
            enabled: false,
          };
          disabledOptions.dateFontSize = {
            enabled: false,
          };
        }
        if (!values.showBuyTicketsButton) {
          disabledOptions.buyTicketsButtonText = {
            enabled: false,
          };
          disabledOptions.buyTicketsButtonTextColor = {
            enabled: false,
          };
          disabledOptions.buyTicketsButtonBackgroundColor = {
            enabled: false,
          };
          disabledOptions.buyTicketsButtonLink = {
            enabled: false,
          };
        }
        return disabledOptions;
      },
      renderer: {
        Viewer: unlayer.createViewer({
          render: ${emailHtmlRenderer},
        }),
        exporters: {
          web: ${emailHtmlRenderer},
          email: ${emailHtmlRenderer},
        },
        head: {
          css: function (values) {
            return \`${htmlCssTemplate}\`.replace(/{{rootClassName}}/g, values._meta.htmlID);
          },
          js: function(values) {
            return \`\`;
          },
        }
      },
    });
    window.parent.postMessage({
      type: 'insert-event-custom-tool-loaded'
    }, '*');
  `;
};
