var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',{attrs:{"totalStep":3,"currentStep":3}},[_c('section',{staticClass:"billing-details"},[_c('ar-text',{style:({
        marginBottom: '20px',
      }),attrs:{"text":"Enter your billing details","size":"lg","multiple-lines":""}}),_vm._v(" "),_c('p',{staticClass:"description"},[_vm._v("\n      A credit card is required to use some features\n    ")]),_vm._v(" "),_c('am2-check-list',{style:({
        marginTop: '30px',
      }),attrs:{"check-state":"yes","text":"You will not be charged until you select a plan"}}),_vm._v(" "),_c('am2-check-list',{style:({
        marginTop: '14px',
      }),attrs:{"check-state":"yes","text":"No commitments, cancel at any time"}}),_vm._v(" "),_c('am2-stripe-element-form',{style:({
        marginTop: '38px',
      }),attrs:{"loading":_vm.isCreatingPaymentSource},on:{"submit":_vm.handleBillingFormSubmite}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-4",style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xxxs","text":"Your credit card will not be charged","align":"center"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }