<template>
  <div class="multiselect-wrapper">
    <div class="multiselect-container" @click="handleBackgroundClick">
      <div class="multiselect-dropdown-wrapper" @click="toggleOptions" data-test-id="multiselect-toggle">
        <ar-text
          class="multiselect-copy"
          size="14px"
          :text="listText"
          multiple-lines
          :max-lines="1"
          line-height="20px"
        />
        <ArrowHead 
          class="multiselect-dropdown-arrowhead"
          :class="listOpen && 'rotate'"
          height="8px" 
          width="10px" 
          fill="#8492a6"
          :style="{
            minWidth: '8px'  
          }"
        />
      </div>
      <div v-if="listOpen" class="list-search-wrapper">
        <am2-search
          class="multiselect-search"
          v-model="listSearchText"
          :placeholder="`Search ${itemLabel}...`"
          data-test-id="multiselect-search"
        />
        <div
          :class="[
            'multiselect-list-wrapper',
            !filteredList || filteredList.length === 0 && 'empty-list'
          ]"
        >
          <template v-for="list in filteredList">
            <a
              :key="list.value"
              class="multiselect-list-link"
              @click="selectList(list.value)"
              :data-test-id="`multiselect-${itemLabel}-${list.name}`"
            >
              <div class="item-wrapper">
                <ar-checkbox
                  :ref="`item-multiselect-${list.value}`"
                  :ticked-value="list.value"
                  :value="!!list.selected ? list.value : null"
                  @input="(val) => selectCheckbox(val, list.value)"
                  class="list-radio"
                />
                <ar-text
                  :class="[
                    'list-name',
                    list.value === selectedListOid && 'selected'
                  ]"
                  size="xs"
                  v-if="typeof list.name === 'string'"
                  :text="list.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="normal"
                />
              </div>
            </a>
          </template>
          <div
            v-if="!filteredList || filteredList.length === 0"
            class="multiselect-empty"
          >
            <ar-text
              size="xs"
              :text="`No ${itemLabel} found.`"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
              :style="{
                color: $arStyle.color.blueGrey700
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
export default {
  name: 'ItemMultiselect',
  components: {
    ArrowHead,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    defaultValue: {
      type: Array,
      default: null,
    },
    itemLabel: {
      type: String,
      default: 'items',
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedListOid: [],
      filteredList: []
    }
  },
  mounted() {
    this.resetDefaults()
    this.selectedListOid = this.defaultValue || [];
    this.filteredList = this.items;
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.filteredList = this.items
        this.updateSelectedItems()
      }
    },
    listSearchText() {
      this.filterLists(this.listSearchText)
      this.$emit('updateSearchText', this.listSearchText)
    },
    defaultValue(val) {
      this.selectedListOid = val
      this.updateSelectedItems()

    }
  },
  computed: {
    listText() {
      if (!this.selectedListOid || this.selectedListOid.length == 0 || !this.filteredList) return `Select ${this.itemLabel}...`
      return this.filteredList.filter(item => !!item.selected).map((item) => item.name).join(", ")
    },
  },
  methods: {
    handleBackgroundClick(e) {
      if (e.target.className === 'multiselect-container') {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    filterLists(text) {
      let filteredList = []
      let i = 0
      while (i < this.items.length) {
        if (typeof this.items[i].name == "string" && this.$arFuzzySearch(this.items[i].name, text)) {
          filteredList.push(this.items[i])
        }

        i++
      }

      this.filteredList = filteredList
    },
    selectList(oid) {
      this.$refs[`item-multiselect-${oid}`][0].toggle()
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    resetDefaults() {
      this.selectedListOid = []
      this.listOpen = false
      this.listSearchText = ''
    },
    selectCheckbox(checkboxValue, oid) {
      if (!checkboxValue) {
        this.selectedListOid = this.selectedListOid.filter((val) => val != oid)
        const index = this.filteredList.findIndex((listItem) => {
          return listItem.value == oid
        })

        this.$set(this.filteredList[index], 'selected', false)
        this.$emit('select', this.selectedListOid)
      } else {
        if (!this.selectedListOid) {
          this.selectedListOid = []
        }
        this.selectedListOid.push(oid)
        const index = this.filteredList.findIndex((listItem) => {
          return listItem.value == oid
        })

        this.$set(this.filteredList[index], 'selected', true)
        this.$emit('select', this.selectedListOid)
      }
    },
    updateSelectedItems() {
      if (!this.filteredList || !this.selectedListOid) return null;

      this.filteredList = this.filteredList.map(listItem => {
        return {
          ...listItem,
          selected: this.selectedListOid.includes(listItem.value)
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.multiselect-wrapper {
  width: 100%;
  height: 100%;

  .multiselect-heading {
    margin-bottom: 14px;
  }

  .multiselect-container {
    height: 100%;

    .multiselect-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(20) ui-px2em(16) ui-px2em(16);
      height: 50px;
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      background-color: #ffffff;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;

      .multiselect-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }

    .list-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;

      .multiselect-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .multiselect-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: scroll;
        overflow-x: hidden;
        padding-bottom: 10px;

        &.empty-list {
          overflow: hidden;
        }

        .multiselect-empty {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
        }

        .multiselect-list-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
          
            .list-radio {
              pointer-events: none;
              margin-right: 10px;
            }
            
            .list-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
  }
}
</style>