<template>
  <am2-tag-input
    :selectedTags="value"
    @change="handleSelectedTagsChange"
    :available-tags="tags"
    :placeholder="placeholder"
    @inputTextChange="handleInputTextChange"
    :show-tag-count="showTagCount"
    :allowCustomInput="allowCustomInput"
    :check-for-invalid-characters="allowCustomInput"
    data-test-id="fan-tag-input"
  />
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'FanTagInput',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    allowCustomInput: {
      type: Boolean,
      default: true,
    },
    showTagCount: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState({
      tags: state => state.tag.tags,
    }),
  },
  methods: {
    ...mapActions([
      'tag/FETCH_MORE_TAGS',
    ]),
    ...mapMutations([
      'tag/RESET_TAGS',
    ]),
    fetchTags(inputValue) {
      this['tag/FETCH_MORE_TAGS']({
        searchString: inputValue ? inputValue.trim() : null,
        reload: true,
      });
    },
    handleInputTextChange(inputValue) {
      this.fetchTags(inputValue);
    },
    handleSelectedTagsChange(selectedTags) {
      this.$emit('input', selectedTags);
      this.fetchTags();
    },
  },
  mounted() {
    this.fetchTags();
  },
  beforeDestroy() {
    this['tag/RESET_TAGS']();
  },
};
</script>
