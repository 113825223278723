var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-order-details-modal',{attrs:{"title":"Order Details","is-show":_vm.showOrderDetailsModal,"type":"order-details-card","orderData":_vm.order},on:{"close":_vm.closeOrderDetailsClick}}),_vm._v(" "),_c('am2-table',{ref:"table",staticClass:"subscribers-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.body,"loading":_vm.loading,"empty-text":_vm.emptyText,"enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":_vm.hasStickyHeader,"has-control-section":""},on:{"sortBy":_vm.handleSortByChange,"rowClick":_vm.handleRowClick},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var ref_data = ref.data;
var fanAccount = ref_data.fanAccount;
var status = ref_data.status;
var fanOid = ref_data.fanOid;
var active = ref.active;
return _c('div',{staticClass:"cell left"},[_c('ar-avatar',{ref:"avatar",staticClass:"avatar",style:({
          marginRight: '10px',
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
        }),attrs:{"image-path":fanAccount.avatar,"name":_vm.determineName(fanAccount),"color":_vm.audienceSelection.partlySelectedAudienceMap[fanOid] || active ? 'white' : 'grey'}}),_vm._v(" "),_c('ar-text',{staticClass:"name-text",style:({
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
          textDecoration: status == 'deleted' ? "line-through" : '',
          color: status == 'compldeletedeted' ? '#8492A6' : '',
        }),attrs:{"size":"xxs","text":_vm.determineName(fanAccount)}})],1)}},{key:"qty",fn:function(ref){
        var ref_data = ref.data;
        var sourceInfo = ref_data.sourceInfo;
        var status = ref_data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{style:({
          textDecoration: status == 'deleted' ? "line-through" : '',
          color: status == 'deleted' ? '#8492A6' : '',
        }),attrs:{"size":"xxs","width":"18.67px","color":_vm.$arStyle.color.email,"text":_vm.getDisplayValueInQty(sourceInfo)}})],1)}},{key:"orderTotal",fn:function(ref){
        var ref_data = ref.data;
        var cost = ref_data.cost;
        var status = ref_data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{style:({
          textDecoration: status == 'deleted' ? "line-through" : '',
          color: status == 'deleted' ? '#8492A6' : '',
        }),attrs:{"size":"xxs","text":_vm.getDisplayValueInOrder(cost)}})],1)}},{key:"orderId",fn:function(ref){
        var ref_data = ref.data;
        var puid = ref_data.puid;
        var status = ref_data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{style:({
          textDecoration: status == 'deleted' ? "line-through" : '',
          color: status == 'deleted' ? '#8492A6' : '',
        }),attrs:{"size":"xxs","text":puid}})],1)}},{key:"source",fn:function(ref){
        var provider = ref.data.provider;
return _c('div',{staticClass:"cell"},[(provider)?_c('ar-text',{attrs:{"size":"xxs","text":("" + (provider[0].toUpperCase()) + (provider.substring(1)))}}):_vm._e()],1)}},{key:"status",fn:function(ref){
        var status = ref.data.status;
return _c('div',{staticClass:"cell"},[_c('div',{class:['order-status', ("order-" + status)]},[_c('ar-text',{style:({
            color: _vm.getColorBasedOnStatus(status),
          }),attrs:{"size":"xxs","text":_vm.formatStatus(status),"weight":"bold"}})],1)])}},{key:"orderTime",fn:function(ref){
          var ref_data = ref.data;
          var orderDate = ref_data.orderDate;
          var status = ref_data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
          content: _vm.getOrderDateFormatted(orderDate),
        }),expression:"{\n          content: getOrderDateFormatted(orderDate),\n        }",modifiers:{"bottom":true}}],style:({
          textDecoration: status == 'deleted' ? "line-through" : '',
          color: status == 'deleted' ? '#8492A6' : '',
        }),attrs:{"size":"xxs","text":_vm.getOrderTimeFromNow(orderDate)}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }