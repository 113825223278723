<template>
  <div class="reward-prize">
    <div class="u-display-flex u-justify-content-space-between">
      <am2-heading
        type="h4"
        size="xs"
        title="Prize"
        weight="bold"
      />
      <ar-icon-button
        v-if="removable"
        :icon-props="{
          name: 'discard',
          height: '14px',
        }"
        :style="{
          transform: 'translateX(6px) translateY(-6px)',
        }"
        @click="$emit('removePrize')" />
    </div>
    <am2-field
      name="prize-name"
      label="Prize name"
    >

      <ar-input
        v-validate="'required'"
        id="prize-name"
        ref="prize-name"
        :value="details.prizeName"
        v-model="details.prizeName"
        :data-vv-name="`prizeName_${tierIndex}`"
        data-vv-as="prize name"
        class="input--block"
        type="text"
        @input="$emit('input', $data)"
      />
      <ar-state-message
        v-if="veeErrors.has(`prizeName_${tierIndex}`)"
        :style="{ marginTop: '8px' }"
        :text="veeErrors.first(`prizeName_${tierIndex}`)"
        type="error"
      />
    </am2-field>

    <am2-field
      name="prize-value"
      label="Prize value (Optional)"
    >
      <ar-input
        id="prize-value"
        ref="prize-value"
        :value="details.value"
        v-model.number="details.value"
        :data-vv-name="`prizeValue_${tierIndex}`"
        class="input--block"
        type="text"
        @input="$emit('input', $data)"
      />
    </am2-field>

    <am2-field
      name="prize-image"
      label="Prize image (Optional)"
    >
      <div class="row">
        <div class="col-xs">
          <am2-dropzone
            :file-type="['image/jpeg', 'image/jpg', 'image/png']"
            file-type-alias="image"
            :placeholder-icon="{
              name: 'image',
            }"
            :image-options="{
              dimensions: {
                width: 1200,
                height: 628
              }
            }"
            :ratio="0.525"
            feedback-type="image"
            :feedback="prizeImage && prizeImage.url"
            enable-ar-s3-bucket-upload
            :ar-s3-bucket-upload-options-list="[{
              assetType: 'prize-image',
              campaignOid: campaign.oid,
              eventOid: null,
            }]"
            @delete="handleImageDelete"
            @uploadStart="isUploadingPrizeImage = true"
            @uploadEnd="isUploadingPrizeImage = false"
            @upload="handleImageUploaded"
          />
        </div>
        <div class="col-xs image-upload-hint">
          <p>We recommend using an image size of 1200x628px that's no larger than 2MB.</p>
        </div>
      </div>
    </am2-field>


    <am2-field
      name="prize-description"
      label="Prize description"
    >
      <ar-textarea
        v-validate="'required'"
        v-model="details.description"
        :rows="3"
        :data-vv-name="`prizeDescription_${tierIndex}`"
        data-vv-as="prize description"
        @input="$emit('input', $data)"
      />
      <ar-state-message
        v-if="veeErrors.has(`prizeDescription_${tierIndex}`)"
        :style="{ marginTop: '8px' }"
        :text="veeErrors.first(`prizeDescription_${tierIndex}`)"
        type="error"
      />
    </am2-field>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'RewardPrize',

  model: {
    prop: 'tier',
    event: 'change',
  },
  props: {
    tier: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    tierIndex: {
      type: Number,
      default: 0,
    },
    tierItemOrder: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      name: 'Prize',
      type: 'prize',
      insertionOrder: this.tierItemOrder,
      details: {
        prizeName: this.tier.details ? this.tier.details.prizeName : '',
        value: this.tier.details ? this.tier.details.value || '' : '',
        description: this.tier.details ? this.tier.details.description : '',
        resourceOid: this.tier.details ? this.tier.details.resourceOid : null,
      },
      isUploadingPrizeImage: false,
    };
  },

  computed: {
    prizeImage() {
      if (this.campaign.resources)
        return this.campaign.resources.find(resource => resource.oid === this.details.resourceOid);
      return null;
    },
  },

  inject: ['$validator'],
  created() {
    const en = {
      custom: {
        prizeName: {
          required: 'Please enter a prize name',
        },
        prizeValue: {
          required: 'Please enter a prize value',
        },
        prizeDescription: {
          required: 'Please enter a prize description',
        },
      },
    };
    this.$validator.dictionary.merge({ en });
  },

  methods: {
    async handleImageUploaded({ arS3BucketResources }) {
      this.handleImageDelete();

      this.details.resourceOid = arS3BucketResources[0].oid;
      // NOTE/XXX/JNM: This seems stupid, we shouldn't have to emit 2 events for this.
      this.$emit('prizeChange', this.$data);
      this.$emit('imageUploaded', arS3BucketResources[0]);
    },
    async handleImageDelete() {
      const resourceOid = this.details.resourceOid;
      this.details.resourceOid = null;
      this.$emit('imageDeleted', resourceOid);
    },
  },
};
</script>
