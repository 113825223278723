var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"eventRSVP-form-details"},[(_vm.hasHeading)?_c('div',{staticClass:"u-margin-bottom-7"},[_c('ar-text',{style:({
        color: '#9FA8B5',
      }),attrs:{"size":"xxs","text":("STEP 2 OF " + _vm.totalStep),"weight":"bold"}}),_vm._v(" "),_c('am2-heading',{staticClass:"u-margin-top-2",attrs:{"type":"h1","size":"lg","title":"Registration fields","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-2",attrs:{"size":"sm","text":"Confirm the fields you want to capture on registration"}})],1):_vm._e(),_vm._v(" "),_c('am2-card-container',{class:[
      'u-padding-y-6',
      'u-padding-x-6'
    ]},[_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-margin-bottom-4' ]},[_c('ar-text',{staticClass:"u-flex-grow-1",attrs:{"size":"xs","text":"Fields","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
          width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
          marginRight: '30px',
        }),attrs:{"size":"xs","text":"Optional","align":"right","weight":"bold"}})],1),_vm._v(" "),_vm._l((_vm.displayedFields),function(field,idx){return _c('div',{key:idx},[_c('div',{class:[
          'u-display-flex',
          'u-align-items-center' ],style:({
          height: '52px',
        })},[_c('ar-text',{staticClass:"u-flex-grow-1",attrs:{"size":"xs","text":field.name}}),_vm._v(" "),_c('ar-checkbox',{style:({
            width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
            marginRight: _vm.checkFieldIsNotOptional(field) ? '30px' : '0px'
          }),attrs:{"value":field.optional,"disabled":_vm.checkFieldIsNotOptional(field),"align":"right"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleRequiredCheck.apply(void 0, [ field.key ].concat( argsArray ))}}}),_vm._v(" "),(!_vm.checkFieldIsNotOptional(field))?_c('ar-icon',{staticClass:"icon remove-button u-margin-left-5",attrs:{"name":"cross","height":"10px","width":"10px","stroke-width":"4","stroke-linecap":"round","color":_vm.$arStyle.color.blueGrey700},nativeOn:{"click":function($event){return _vm.handleRemoveField(field.key, field.order)}}}):_vm._e()],1),_vm._v(" "),_c('ar-divider')],1)}),_vm._v(" "),_c('div',{staticClass:"u-margin-top-5"},[_c('am2-link-button-dropdown',{attrs:{"placeholder":"+ Add field","items":_vm.unlistedFieldItems,"search-placeholder":"Search fields","has-search":"","no-option-text":"No more fields found","max-height":"300px","dropdown-style":{
          width: '280px',
        },"button-props":{
          hasArrow: false,
          hasUnderline: true,
          textProps: {
            size: 'xs',
          },
        }},on:{"select":_vm.handleFieldSelect}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }