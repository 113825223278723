<template>
   <am2-split-dropdown
      class="top-create-button"
      :items="createCampaignOptions"
      :text="text"
      :disabled="disabled"
      :outlined="outlined"
      :icon-name="iconName"
      :icon-props="iconName ? iconProps : null"
      :icon-distance="iconDistance"
      :classNames="dropdownClassNames.concat(['button-dropdown-large'])"
      :data-test-id="dataTestId || 'create-campaign-button'"
      toggle-dropdown-on-button-click
      @select="handleOptionClick"
    />
</template>
<script>
import { mapGetters } from 'vuex';
import { mergeObjects } from '@/utils/helpers';

const defaultQueryParams = {
  tab: 'campaign',
  filter: 'all',
  showAs: 'list',
  top: 10
};

export default {
  name: 'CreateCampaignButton',
  props: {
    text: {
      type: String,
      default: 'Create Campaign',
    },
    dropdownClassNames: {
      type: Array,
      default: () => [],
    },
    iconDistance: {
      type: String,
      default: '9px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
    },
    iconProps: {
      type: Object,
    },
    dataTestId: {
      type: String,
    },
    campaignArgs: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      queryParams: defaultQueryParams,
      createCampaignOptions:  [
        {
          key: 'free',
          type: 'extended-option',
          title: 'Standard campaign',
          description: 'Try campaigns for free with basic setup and registration options',
          icon: 'flash',
          iconColor: this.$arStyle.color.purple500,
          tag: 'free',
          tagBackground: '#F6F9FC',
          action: this.handleOptionClick,
        },
        { 
          type: 'divider',
          style: {
            margin: 0,
          },
        },
        {
          key: 'premium',
          type: 'extended-option',
          title: 'Gamified campaign',
          description: 'Tailor-made to help you sell more tickets and capture more audience data',
          icon: 'fire',
          iconColor: '#E39425',
          banner: '3x more registrations than standard',
          bannerColor: '#E39425',
          bannerBackground: 'rgba(227, 148, 37, 0.1)',
          tag: 'plus',
          tagColor: '#FFFFFF',
          tagBackground: '#E39425',
          tagIcon: 'logo-transparent',
          action: this.handleOptionClick,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    isPremiumCampaignEnabled() {
      return this.isFeatureEnabled(['campaigns']);
    },
  },

  mounted() {
    const index = this.createCampaignOptions.findIndex(option => option.key === 'premium')
    this.createCampaignOptions[index].closeOnClick = this.isPremiumCampaignEnabled
  },

  methods: {
    handleOptionClick(item) {
      this.goToCampaignSetup(item.key);
    },

    goToCampaignSetup(subscriptionLevel) {
      if (subscriptionLevel === 'premium' && !this.isPremiumCampaignEnabled) {
        return;
      }

      this.$router.push({
        name:'campaignSetup-type',
        query: this.campaignArgs,
        params: {
          subscriptionLevel: subscriptionLevel
        }
      });
    },
  },
}
</script>
<style lang="scss">
.button-dropdown-large {
  width: 450px !important;
  padding: 0 !important;
}
</style>