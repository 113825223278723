var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-heading',{staticClass:"u-margin-top-6 u-margin-bottom-2",attrs:{"type":"h1","size":"lg","title":"Admin Fixes"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-8",attrs:{"multiple-lines":"","size":"xs","text":"Trigger actions to recalculate data and fix broken resources on promoter accounts. Please don't abuse this\n          functionality. Each of these buttons may potentially trigger a lot of work on the server, so we should avoid\n          spamming them."}}),_vm._v(" "),_c('div',{staticClass:"fixes-page-wrapper"},[_c('am2-heading',{style:({ marginBottom: '10px' }),attrs:{"type":"h3","title":"Select account","size":"md"}}),_vm._v(" "),_c('am2-promoter-select',{staticClass:"select u-margin-bottom-4",style:({ width: '500px' }),attrs:{"value":125},on:{"input":_vm.handlePromoterSelect}}),_vm._v(" "),(_vm.selectedPromoterOid)?_c('div',[_c('ar-divider',{staticClass:"u-margin-y-4"}),_vm._v(" "),_c('am2-card-container',{staticClass:"u-padding-5",attrs:{"layout":"soft"}},[_c('div',{style:({ marginBottom: '12px', display: 'flex', width: '100%' })},[_c('ar-icon',{staticClass:"u-margin-right-3",attrs:{"width":"24px","name":"ar-logo-mono"}}),_vm._v(" "),_c('am2-heading',{attrs:{"type":"h3","title":"Fix Dashboard Sales Graph Data","size":"md"}})],1),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-3",attrs:{"multiple-lines":"","size":"xs","text":"If a promoter's dashboard sales chart is incorrect, we can delete and re-create the underlying data to fix it.\n                  While this fix is running, the chart may show incomplete or incorrect data!"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-5",attrs:{"multiple-lines":"","size":"xs","weight":"bold","text":"Running time - approx 1s per day of sales (assume 5 minutes for a year of continuous daily sales)."}}),_vm._v(" "),_c('div',{style:({
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        })},[_c('div',{style:({minWidth: '418px'})},[_c('ar-simple-button',{attrs:{"id":"refresh-sales-stats-snapshots-button","data-test-id":"refresh-sales-stats-snapshots-button","text":(_vm.salesStatsRefreshPrefixWord + "Delete and Recreate Sales Stats Snapshots"),"icon-name":_vm.confirmRefreshSalesSnapshots || _vm.isRefreshingSalesSnapshots ? 'exclamation-mark' : 'refresh',"icon-props":{
                width: _vm.confirmRefreshSalesSnapshots || _vm.isRefreshingSalesSnapshots ? '18px' : '14px',
              },"disabled":_vm.isRefreshingSalesSnapshots,"type":_vm.confirmRefreshSalesSnapshots || _vm.isRefreshingSalesSnapshots ? 'red' : 'purple'},on:{"click":_vm.handleRefreshSalesSnapshotsClick}})],1),_vm._v(" "),(_vm.confirmRefreshSalesSnapshots)?_c('ar-text',{staticClass:"u-margin-left-4",style:({
              alignSelf: 'center',
              color: 'red',
            }),attrs:{"text":"<a>Cancel refresh</a>","size":"xs","allow-html":""},on:{"anchorClick":_vm.handleCancelSalesStatsRefresh}}):_vm._e()],1)],1),_vm._v(" "),_c('ar-divider',{staticClass:"u-margin-y-4"}),_vm._v(" "),_c('am2-card-container',{staticClass:"u-padding-5",attrs:{"layout":"soft"}},[_c('div',{style:({ marginBottom: '12px', display: 'flex', width: '100%' })},[_c('ar-icon',{staticClass:"u-margin-right-3",attrs:{"width":"24px","name":"ar-logo-mono"}}),_vm._v(" "),_c('am2-heading',{attrs:{"type":"h3","title":"Stat refresh","size":"md"}})],1),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-3",attrs:{"multiple-lines":"","size":"xs","text":"If a message list, campaign registration, Event ticket sales stats or tag count are incorrect use this to refresh it."}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-5",attrs:{"multiple-lines":"","size":"xs","weight":"bold","text":"Select what you want to refresh then select the specific entity."}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"u-margin-bottom-5",style:({ height: '40px', width:'500px' }),attrs:{"items":_vm.statRefreshList,"enable-filter":"","data-test-id":"filter-predicate-selector"},on:{"select":_vm.handleStatRefreshSelect}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"u-margin-bottom-5",style:({ height: '40px', width:'500px' }),attrs:{"items":_vm.messageListList,"enable-filter":"","enable-clear":""},on:{"select":_vm.handleEntitySelect}}),_vm._v(" "),_c('div',{style:({minWidth: '418px'})},[_c('ar-simple-button',{attrs:{"id":"refresh-stats-button","data-test-id":"refresh-stats-button","text":_vm.statRefreshType ? (_vm.statRefreshPrefixWord + "Refresh " + (_vm.statRefreshType.name)) : 'Please Select',"icon-name":_vm.confirmStatsRefresh || _vm.isRefreshingStats ? 'exclamation-mark' : 'refresh',"icon-props":{
                width: _vm.confirmStatsRefresh|| _vm.isRefreshingStats ? '18px' : '14px',
              },"disabled":(_vm.statOid == null),"type":_vm.confirmStatsRefresh || _vm.isRefreshingStats ? 'red' : 'purple'},on:{"click":_vm.handleStatRefreshClick}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }