<template>
  <ar-skeleton :class="['tag', shape]" :level="level" />
</template>

<script>
export default {
  name: 'TagSkeleton',
  props: {
    shape: {
      type: String,
      default: 'round',
      validator: (value) =>
        ['round', 'rectangle'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
};
</script>


<style lang="scss" scoped>
.tag {
  display: inline-block;

  &.rectangle {
    border-radius: 4px;
  }

  &.round {
    border-radius: 15px;
    height: 30px;
  }
}
</style>
