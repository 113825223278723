<template>
  <div class="slug-check">
    <ar-text
      class="edit-url"
      size="xs"
      :text="`${baseUrl}${prefix}`"
    />
    <input
      type="text"
      name="slug"
      :disabled="!editable"
      ref="slug"
      v-autowidth="{maxWidth: '960px', minWidth: '50px', comfortZone: 15}"
      :value="currentSlug"
      @keydown.enter="handleSlugEnter"
      @keydown.esc="handleSlugEscape"
      @input="handleSlugChange"
      data-test-id="slug-input-textbox"
    />
    <ar-icon-button
      v-if="!editable"
      class="icon"
      :icon-props="{ name: 'edit', height: '18px' }"
      @click="turnOnEditMode"
    />
    <ar-link-button
      v-if="editable"
      text="Done"
      data-test-id="slug-input-done-button"
      @click="handleUrlSlugDoneClick"
    />
  </div>
</template>


<script>
import { mapActions } from 'vuex';
import { isClickOutside } from '@/utils/html-element/';

export default {
  name: 'SlugInput',

  props: {
    baseUrl: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      editable: false,
      currentSlug: null,
    }
  },

  watch: {
    value(val) {
      this.currentSlug = val;
    },
  },

  created() {
    this.currentSlug = this.value;
    document.addEventListener('click', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  },

  methods: {

    turnOnEditMode() {
      this.editable = true;
      this.$nextTick(() => {
        this.$refs['slug'].focus();
      });
    },

    doTriggerInput() {
      let newValue = this.currentSlug;
      // No special character allowed
      newValue = newValue.toLowerCase().replace(/[^\da-z-]/gi, '-');

      this.$emit('input', newValue);
    },

    handleOutsideClick(e) {
      if (isClickOutside(e.target, this.$el)) {
        this.currentSlug = this.value;
        this.editable = false;
      }
    },

    handleSlugEscape() {
      this.currentSlug = this.value;
      this.editable = false;
    },

    handleSlugEnter() {
      this.doTriggerInput();
      this.editable = false;
    },

    handleSlugChange(e) {
      // No special character allowed
      this.currentSlug = e.target.value;
      this.$nextTick(() => {
        this.currentSlug = this.currentSlug.toLowerCase().replace(/[^\da-z-]/gi, '');
      });
    },

    async handleUrlSlugDoneClick() {
      this.doTriggerInput();
      this.editable = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.slug-check {
  display: inline-flex;
  align-items: center;
  color: $blueGrey700;
  height: 20px;

  .edit-url {
    color: $blueGrey700;
  }

  input {
    color: inherit;
    margin-left: -2px;
    border: none;
    border: 1px solid transparent;
    background-color: transparent;
    height: 18px;
    transform: translateY(-3%);

    &:focus {
      outline: none;
      border: 1px dotted;
    }

    &[aria-invalid="true"] {
      border-color: red;
    }
  }

  .icon {
    position: relative;
    cursor: pointer;
    color: $skyBlueGrey600;
    margin-left: -15px; // Offset the comfort setting of the autowidth
    z-index: $zIndexHigh;
  }
}

</style>
