<template>
  <div class="stats-preview-wrapper">
    <span>{{stats.completions}} completions,&nbsp;</span>
    <span>{{stats.inProgress}} in progress.</span>
  </div>  
</template>
<script>
export default {
  name: 'AutomationStatsPreview',
  props: {
    stats: {
      type: Object,
      default: null,
    }
  }
}
</script>
<style lang="scss" scoped>
.stats-preview-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 12px;

  span {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e97a6;
  }
}
</style>