<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="wrapper"
      header="Custom Date Range"
      hide-footer
      width="628px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
    >
      <div
        :class="[
          'body',
          $arMediaQuery.window.maxWidth('xs') && 'xs-max',
        ]"
        slot="body"
      >
        <div class="left-side">
          <div class="section">
            <ar-text
              text="From"
              weight="bold"
              class="u-margin-bottom-4"
              size="xs"
            />
            <am2-datepicker
              ref="date-picker-start"
              :style="{
                height: '50px',
                maxWidth: $arMediaQuery.pageContent.minWidth('sm') ? '164px' : null,
              }"
              format="MMMM D, YYYY"
              placeholder="YYYY/MM/DD"
              @input="modifyStartDate"
            />
          </div>
          <div class="section">
            <ar-divider
              class="divider"
              :style="{
                width: $arMediaQuery.pageContent.minWidth('sm') ? '40px' : '12px',
              }"
            />
          </div>
          <div class="section">
            <ar-text
              text="To"
              weight="bold"
              class="u-margin-bottom-4"
              size="xs"
            />
            <am2-datepicker
              :style="{
                height: '50px',
                maxWidth: $arMediaQuery.pageContent.minWidth('sm') ? '164px' : null,
              }"
              format="MMMM D, YYYY"
              placeholder="YYYY/MM/DD"
              @input="modifyEndDate"
            />
          </div>

        </div>
        <div
          :class="[
            'right-side',
            $arMediaQuery.window.maxWidth('xs') && 'u-margin-top-3',
          ]"
        >
          <ar-simple-button
            text="Apply"
            :disabled="isApplyButtonDisabled"
            @click="handleApplyClick"
            :style="{
              height: '50px',
              minWidth: '112px',
            }"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  import moment from 'moment';
  export default {
    name: 'DateRangeModal',

    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        startDate: null,
        endDate: null,
      }
    },

    computed: {
      isApplyButtonDisabled() {
        if (!this.startDate) return true;
        if (!this.endDate) return true;
        return this.startDate > this.endDate;
      },
    },

    watch: {
      isShow(val) {
        if (val) {
          this.startDate = null;
          this.endDate = null;
          this.$nextTick(() => {
            if (this.$refs['date-picker-start']) {
              this.$refs['date-picker-start'].open();
            }
          });
        }
      },
    },

    methods: {
      handleApplyClick() {
        const startDays = this.startDate.getDate();
        const startMonth = this.startDate.toLocaleString('default', { month: 'short' });
        const startYear = this.startDate.getFullYear();

        const endDays = this.endDate.getDate();
        const endMonth = this.endDate.toLocaleString('default', { month: 'short' });
        const endYear = this.endDate.getFullYear();

        this.$emit('select', {
          startDate: this.startDate,
          startDateFormatted: `${startDays} ${startMonth} ${startYear}`,
          endDate: this.endDate,
          endDateFormatted: `${endDays} ${endMonth} ${endYear}`,
        });
      },

      modifyStartDate(newDate) {
        this.startDate = moment(newDate, 'YYYY-MM-DD').toDate();
      },

      modifyEndDate(newDate) {
        this.endDate = moment(newDate, 'YYYY-MM-DD').toDate();
      },

      handleModalClose() {
        this.$emit('cancel');
      },
    },
  }
</script>

<style lang="scss" scoped>
.wrapper {

  .body {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 30px;
    align-items: flex-end;

    &.xs-max {
      flex-direction: column;
      justify-content: space-around;

      .left-side {
        width: 100%;
      }
    }

    .left-side {
      display:inline-flex;
      flex-direction:row;
      .section {
        display:inline-flex;
        flex-direction: column;
        justify-content: flex-end;

        .ar-typography {
          margin-bottom: 8px;
        }
      }
      .divider {
        margin-left: 14px;
        margin-right: 14px;
        margin-bottom: 22px;
      }
    }

    .right-side {
      display:inline-flex;
      padding-left: 24px;
    }

  }
}

</style>
