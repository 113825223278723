<template>
  <ar-skeleton
    class="ar-typography-skeleton"
    :level="level"
    :color="color"
    :style="{
      height: textSectionFontSize,
    }"
  />
</template>

<script>
export default {
  name: 'TypographySkeleton',

  props: {
    size: {
      type: String,
      default: 'md',
      required: true,
      validator: (value) =>
        ['xl', 'lg', 'md', 'sm', 'xs', 'xxs', 'xxxs'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
    color: {
      type: String,
      default: 'black',
      validator: (val) => ['white', 'black'].indexOf(val) > -1,
    },
  },

  computed: {
    textSectionFontSize() {
      return this.$arStyle.font.size[this.size];
    },
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
.ar-typography-skeleton {
  border-radius: 5px;
  width: 200px;
}
</style>
