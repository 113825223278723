var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-dropdown-binder',{attrs:{"align":_vm.align,"pixel-shift":5,"button-name":"am2-avatar-button","button-props":_vm.buttonProps,"dropdown-props":{
    items: _vm.items,
    style: _vm.dropdownStyle,
    itemStyle: {
      padding: '10px 15px',
      height: '44px',
    },
    arrowDistance: '19px',
  }},on:{"select":_vm.handleItemSelect}})}
var staticRenderFns = []

export { render, staticRenderFns }