<template>
  <InfoBranch
    v-if="!!item.config && !!item.config.frontend"
    :content="conditionText"
    :style="{
      marginTop: '2px',
    }"
  />
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'IfElse',
  components: {
    InfoBranch,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      getSegmentConditionExpression: 'segment/getSegmentConditionExpression',
    }),
    ...mapState({
      segmentList: state => state.segment.savedSegmentList,
    }),
    conditionText() {
      if (!this.item?.config || !Object.keys(this.item.config).length) return
      if (!this.item.config?.frontend?.segment) {
       return  this.getSegmentConditionExpression(this.item.meta.filter)
      }
      if (!!this.item.config?.frontend?.segment) {
        let selectedSegment = this.segmentList.find(item => item.oid === this.item.config['filter-group-oid'])
        return `Saved segment: ${selectedSegment?.name}`
      }
    }
  },
}
</script>