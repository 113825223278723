var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"campaign-social-action-settings-modal",attrs:{"is-open":_vm.opened,"header":_vm.title,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs'),"data-test-id":"campaign-social-action-settings-modal"},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'campaign-social-action-settings-modal-body' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{ref:"fields"},[(_vm.displayLabel)?_c('ar-field',{attrs:{"label":"Button label","error-messages":[
            _vm.veeErrors.first('label') ]}},[_c('ar-input',{attrs:{"data-test-id":"campaign-settings-button-label","data-vv-name":"label","data-vv-as":"button label","placeholder":_vm.defaultLabel},model:{value:(_vm.editLabel),callback:function ($$v) {_vm.editLabel=$$v},expression:"editLabel"}})],1):_vm._e(),_vm._v(" "),_c('ar-field',{style:({
            marginTop: '25px',
          }),attrs:{"label":"Points","error-messages":[
            _vm.veeErrors.first('points') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              numeric: true,
            }),expression:"{\n              required: true,\n              numeric: true,\n            }"}],attrs:{"data-test-id":"campaign-settings-points","data-vv-name":"points","data-vv-as":"points","placeholder":"Points"},model:{value:(_vm.editPoints),callback:function ($$v) {_vm.editPoints=$$v},expression:"editPoints"}})],1),_vm._v(" "),(_vm.displayDisclaimer)?_c('ar-field',{style:({
            marginTop: '25px',
          }),attrs:{"label":"Custom Disclaimer","error-messages":[
            _vm.veeErrors.first('disclaimer') ]}},[_c('ar-textarea',{attrs:{"data-test-id":"campaign-settings-custom-disclaimer","rows":4,"data-vv-name":"disclaimer","data-vv-as":"disclaimer","placeholder":"Enter disclaimer"},model:{value:(_vm.editDisclaimer),callback:function ($$v) {_vm.editDisclaimer=$$v},expression:"editDisclaimer"}})],1):_vm._e(),_vm._v(" "),(_vm.displayUrl)?_c('ar-field',{style:({
            marginTop: '25px',
          }),attrs:{"label":"URL","error-messages":[
            _vm.veeErrors.first('url') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
            }),expression:"{\n              required: true,\n            }"}],attrs:{"data-test-id":"campaign-settings-custom-url","data-vv-name":"url","data-vv-as":"url","placeholder":"Url"},model:{value:(_vm.editUrl),callback:function ($$v) {_vm.editUrl=$$v},expression:"editUrl"}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
        'campaign-social-action-settings-modal-footer' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Save","data-test-id":"save-campaign-social-action-modal"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }