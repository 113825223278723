var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:[
      'default-layout',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('SidebarExpansion'),_vm._v(" "),(!_vm.isAdminAccount)?_c('SegmentExpansion'):_vm._e(),_vm._v(" "),_c('ModalsExpansion',[_c('portal-target',{attrs:{"name":"modal"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"higher-order-modal"}})],1),_vm._v(" "),_c('div',{class:[
      'left-section' ],style:({
      width: _vm.leftSectionWidth,
    })}),_vm._v(" "),_c('div',{ref:"page-content",class:[
      'page-content',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('NotAccessiblePage',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageAccessible),expression:"!isPageAccessible"}],attrs:{"feature-name":_vm.notAccessibleFeature}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPageAccessible),expression:"isPageAccessible"}]},[_c('nuxt')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }