var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isOpen}},[_c('ar-modal',{staticClass:"custom-field-modal",attrs:{"is-open":_vm.isOpen,"header":((_vm.isEditMode ? 'Edit' : 'Add') + " Custom Field"),"width":"600px","height":"700px","mobile":_vm.$arMediaQuery.window.maxWidth('xs'),"data-test-id":"custom-field-modal"},on:{"close":_vm.handleClose}},[_c('div',{class:['body', _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3'],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"text":"Choose an extisting custom field or create a new one.","size":"xs"}}),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-8",attrs:{"label":"Existing Custom Fields (optional)"}},[_c('ar-select',{staticClass:"field-type-select",attrs:{"items":_vm.promoterCustomFields,"placeholder":"Select existing custom field","hide-arrow":false,"showEmptyState":"","data-test-id":"existing-custom-fields"},on:{"select":_vm.handleExistingCustomFieldSelect},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }),attrs:{"data-test-id":("message-list-item-" + (selectedItemScope.item))}},[(selectedItemScope.item)?_c('ar-text',{style:({
                    width: 'auto',
                    marginLeft: '10px',
                    color: _vm.$arStyle.color.blueGrey800,
                  }),attrs:{"size":"xs","text":selectedItemScope.item.name,"weight":"normal"}}):_vm._e()],1):_c('div',{style:({
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '0 14px',
                })},[_c('ar-text',{style:({
                    color: _vm.$arStyle.color.blueGrey600,
                  }),attrs:{"size":"xs","text":"Select list type"}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{style:({
                  display: 'flex',
                  alignItems: 'center',
                  height: '55px',
                  padding: '0 15px',
                  cursor: 'pointer',
                  background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
                  borderBottom: '1px solid #dcdee4',
                }),attrs:{"data-test-id":("message-list-item-" + (itemScope.item.name))}},[_c('ar-text',{style:({
                    width: 'auto',
                    color: itemScope.active ? '#7344c0' : '#43516b',
                  }),attrs:{"size":"xs","text":itemScope.item.name,"weight":itemScope.selected ? 'bold' : 'normal'}})],1)]}}])})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Field name","error-messages":[_vm.veeErrors.first('field-name')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"autocomplete":"field-name","data-vv-name":"field-name","data-vv-as":"field name","placeholder":"Field name","type":"text","data-test-id":"field-name","value":_vm.fieldName},on:{"input":_vm.handleEventNameInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Field type"}},[_c('ar-select',{staticClass:"field-type-select",attrs:{"items":_vm.fieldTypesAvailable,"placeholder":"Select field type","hide-arrow":false,"showEmptyState":"","data-test-id":"field-type","defaultSelectIndex":_vm.fieldTypeDefaultSelectedIndex},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }),attrs:{"data-test-id":("message-list-item-" + (selectedItemScope.item))}},[(selectedItemScope.item)?_c('ar-text',{style:({
                    width: 'auto',
                    marginLeft: '10px',
                    color: _vm.$arStyle.color.blueGrey800,
                  }),attrs:{"size":"xs","text":selectedItemScope.item.name,"weight":"normal"}}):_vm._e()],1):_c('div',{style:({
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '0 14px',
                })},[_c('ar-text',{style:({
                    color: _vm.$arStyle.color.blueGrey600,
                  }),attrs:{"size":"xs","text":"Select list type"}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{style:({
                  display: 'flex',
                  alignItems: 'center',
                  height: '55px',
                  padding: '0 15px',
                  cursor: 'pointer',
                  background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
                  borderBottom: '1px solid #dcdee4',
                }),attrs:{"data-test-id":("message-list-item-" + (itemScope.item.name))}},[_c('ar-text',{style:({
                    width: 'auto',
                    color: itemScope.active ? '#7344c0' : '#43516b',
                  }),attrs:{"size":"xs","text":itemScope.item.name,"weight":itemScope.selected ? 'bold' : 'normal'}})],1)]}}])}),_vm._v(" "),(_vm.selectFieldTypeNotValid)?_c('ar-text',{staticClass:"field-type-error u-margin-top-2",attrs:{"text":"The field type is required","size":"xs"}}):_vm._e()],1),_vm._v(" "),(_vm.fieldOptions.length > 0)?_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Dropdown options","error-messages":[_vm.veeErrors.first('field-option')]}},[_c('draggable',{model:{value:(_vm.fieldOptions),callback:function ($$v) {_vm.fieldOptions=$$v},expression:"fieldOptions"}},_vm._l((_vm.fieldOptions),function(field,idx){return _c('div',{key:idx},[_c('div',{class:['u-display-flex', 'u-align-items-center'],style:({ height: '52px', marginTop: '5px' })},[_c('div',{staticClass:"field-option-container"},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],staticClass:"field-option",attrs:{"autocomplete":"field-option","data-vv-name":"field-option","data-vv-as":"field option","placeholder":"Field option","type":"text","data-test-id":("field-option-" + idx),"value":field.name},on:{"input":function (val) { return _vm.onFieldInput(val, field, idx); }}}),_vm._v(" "),_c('ar-auto-complete-input',{ref:"input",refInFor:true,staticClass:"add-tag-input",attrs:{"value":_vm.tagSearchStrings[idx],"placeholder":"Add tag (optional)","data-test-id":"tag-list-auto-complete-input","new-suggestion-template":"Create new tag {suggestion}","suggestions":_vm.suggestedTags},on:{"input":function (value) { return _vm.handleTagSearchStringInput(value, idx); },"blur":_vm.handleTagInputBlur,"suggestSelect":function (value) { return _vm.handleSuggestionClick(value, idx); }}})],1),_vm._v(" "),(_vm.fieldOptions.length > 1)?_c('am2-simple-button-dropdown',{staticClass:"dropdown-button custom-field-menu-button",attrs:{"align":"left","pixel-shift":5,"button-props":{
                    type: 'grey',
                    outlined: true,
                    iconName: 'menu',
                    style: {
                      width: '50px',
                    },
                    iconSide: 'left',
                    iconDistance: '0px',
                    iconProps: {
                      color: _vm.$arStyle.color.blueGrey600,
                    },
                  },"data-test-id":("field-option-" + idx + "-menu"),"items":_vm.computedFieldMenuOptions},on:{"select":function (opt) { return _vm.handleMenuOptionSelect(opt, field, idx); }}}):_vm._e()],1)])}),0),_vm._v(" "),_c('ar-link-button',{staticClass:"add-option-button",attrs:{"text":"+ Add option"},on:{"click":_vm.addOption}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{staticClass:"transparent-button",staticStyle:{"padding":"18px 42px","margin-right":"15px"},attrs:{"text":"Cancel","outlined":"","data-test-id":"custom-fields-modal-cancel-button"},on:{"click":_vm.handleClose}}),_vm._v(" "),_c('ar-simple-button',{staticStyle:{"padding":"18px 42px"},attrs:{"text":"Save","data-test-id":"custom-fields-modal-save-button"},on:{"click":_vm.handleSaveClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }