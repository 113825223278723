<template>
  <section
    class="event-orders-container"
  >
    <am2-tabs
      data-test-id="order-tabs"
      :items="[
        { title: 'Ticket Orders' },
        { title: 'POS Orders' },
      ]"
      :selected-tab-index="selectedTabIndex"
      class="tab-options"
      @select="(selected, idx) => selectedTabIndex = idx"
    />
    <TicketOrders
      v-if="selectedTabIndex == 0"
    />
    <POSOrders
      v-if="selectedTabIndex == 1"
    />
  </section>
</template>

<script>

import TicketOrders from './ticket-orders.vue'
import POSOrders from './pos-orders.vue'


export default {
  name: 'EventOrders',

  data() {
    return {
      selectedTabIndex: 0
    };
  },
  mounted() {
    if (localStorage.orderTabSelectedIndex) {
      // the sorted value is string (?)
      this.selectedTabIndex = typeof(localStorage.orderTabSelectedIndex) === 'string' ? parseInt(localStorage.orderTabSelectedIndex):localStorage.orderTabSelectedIndex;
    } else {
      localStorage.orderTabSelectedIndex = 0;
    }
  },
  watch: {
    selectedTabIndex(val) {
      localStorage.orderTabSelectedIndex = val;
    }
  },
  components: {
    TicketOrders,
    POSOrders,
  },
};
</script>

<style lang="scss">
/* LEADERBOARD ------------------------ */
.event-orders-container {
  padding: 0;
  margin-bottom: 30px !important;

  .orders-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .filter-wrapper {
      border-left: 1px solid #DCE0E4;
      height: 30px;
      padding-left: 15px;
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }
}
</style>
