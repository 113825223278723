<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="purchase-sms-credit-modal"
      hide-header
      hide-footer
      width="400px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'purchase-sms-credit-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5',
        ]"
      >
        <div class="sms-icon">
          <ar-icon
            v-bind="{
              name: 'sms',
              color: this.$arStyle.color.sms,
              width: '28px',
              height: '28px',
              wrapperStyle: {
                backgroundColor: '#FFF',
                border: `1px solid ${this.$arStyle.color.skyBlueGrey400}`,
                boxShadow: `0 0 8px  ${this.$arStyle.color.skyBlueGrey400}`,
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            }"
          />
        </div>

        <ar-text
          class="purchase-sms-credit-modal-title u-margin-bottom-5"
          text="Purchase SMS Credit"
          multiple-lines
          weight="bold"
          align="center"
          size="md"
        />

        <ar-text
          class="purchase-sms-credit-modal-message u-margin-bottom-6"
          size="xs"
          text="Add SMS credit to your account. Your total credit automatically renews every year, and can be cancelled at any time."
          multiple-lines
          line-height="25px"
          align="center"
        />

        <ar-input
          v-model="creditValue"
          placeholder="$0"
          label="Credit"
          autocomplete="sms-credit"
          :formatter="inputFormatter"
        />

        <ar-text
          class="purchase-sms-credit-modal-message u-margin-top-6"
          size="xs"
          :text="billedTextValue"
          multiple-lines
          weight="bold"
          line-height="20px"
          align="center"
        />

        <ar-text
          class="purchase-sms-credit-modal-message u-margin-bottom-1"
          size="xs"
          :text="renewalTextValue"
          multiple-lines
          allow-html
          line-height="20px"
          align="center"
        />

        <div class="purchase-button-wrapper">
          <ar-simple-button
            text="Purchase"
            type="gradient-purple"
            class="purchase-button"
            :loading="isProcessingPayment"
            :disabled="disablePurchaseCreditButton"
            @click="handlePurchaseClick"
          />
        </div>

        <am2-payment-source-section
          :payment-source-puid="selectedPaymentSourcePuid"
          @select="handlePaymentMethodSelect"
          add-payment-source-on="expansion"
          class="u-margin-top-6"
          @addPaymentSourceToggle="handlePaymentSourceToggle"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { accounting } from 'accounting';
  import moment from 'moment';
  import { numberInputFormatter } from '@/utils/html-element';

  export default {
    name: 'PurchaseSmsCreditModal',

    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      paymentPlan: {
        type: Object,
        default: () => null,
      },
      paymentSourcePuid: {
        type: String,
        default: null,
      },
      paymentSubscription: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        loading: false,
        creditValue: null,
        existingCredit: 0,
        paymentPlanOid: null,
        selectedPaymentSourcePuid: null,
        selectedPaymentSourceOid: null, // What we send with the payment subscription request
        isProcessingPayment: false,
        displayAddPaymentSourceForm: false,
      }
    },
    computed: {
      billedTextValue() {
        if (!this.creditValue) return ' ';
        return `You will be billed ${this.creditValue} now.`;
      },
      disablePurchaseCreditButton() {
        return this.displayAddPaymentSourceForm || !this.selectedPaymentSourceOid || !this.unformattedInputValue || this.unformattedInputValue <= 0;
      },
      combinedValue() {
        return parseInt(this.existingCredit) + parseInt(this.unformattedValue(this.creditValue));
      },
      unformattedInputValue() {
        return this.unformattedValue(this.creditValue);
      },
      renewalTextValue() {
        if (!this.creditValue) return ' <br/> <br/>';
        const newCredit = this.combinedValue;
        let renewalDate;
        if(this.paymentSubscription) {
          renewalDate = (this.paymentSubscription.additionalInfo && this.paymentSubscription.additionalInfo.currentTermEnd) ? moment(this.paymentSubscription.additionalInfo.currentTermEnd * 1000).format('MMM Do, YYYY') : 'your next renewal';
        } else {
          let startDate = new Date();
          startDate.setFullYear(startDate.getFullYear() + 1);
          renewalDate = moment(startDate).format('MMM Do, YYYY');
        }
        return `Your total SMS Credit subscription will go up to ${accounting.formatMoney(newCredit, {precision: 0})} and will renew on <strong>${renewalDate}</strong>.`;
      }
    },

    watch: {
      isOpen(val) {
        if (val) {
          this.existingCredit = this.paymentSubscription && this.paymentSubscription.quantity ? this.paymentSubscription.quantity : 0;
          this.selectedPaymentSourcePuid = this.paymentSourcePuid;
          this.paymentPlanOid = this.paymentPlan.oid;
          this.creditValue = '$0';
        }
      },
    },

    methods: {
      ...mapActions([
        'CREATE_PAYMENT_SUBSCRIPTION',
        'UPDATE_PAYMENT_SUBSCRIPTION',
      ]),
      unformattedValue(input) {
        if (!input) return 0;
        return input.toString().replace(/,/g, '').replace('$', '');
      },
      handlePaymentMethodSelect(paymentSource) {
        this.selectedPaymentSourcePuid = paymentSource.puid;
        this.selectedPaymentSourceOid = paymentSource.oid;
      },
      handlePaymentSourceToggle(isOpen) {
        this.displayAddPaymentSourceForm = isOpen;
      },
      async handlePurchaseClick() {
        try {
          this.isProcessingPayment = true;
          let payload = {
            planOid: this.paymentPlanOid,
            paymentSourceOid: this.selectedPaymentSourceOid,
            subscriptionOid: this.paymentSubscription ? this.paymentSubscription.oid : null,
          };
          payload.planQuantity = this.combinedValue; // Set planQuantity as combined value, since we're overwriting previous value
          if (this.paymentSubscription) {
            await this.UPDATE_PAYMENT_SUBSCRIPTION(payload);
          } else {
            await this.CREATE_PAYMENT_SUBSCRIPTION(payload);
          }
          this.$arNotification.push({
            type: 'success',
            message: `Successfully added ${this.creditValue} SMS credit to your account.`,
          });
          this.$emit('purchase');
        } catch(e) {
          console.error(e);
          if(e.response && e.response.status === 422) {
            this.$arNotification.push({
              type: 'error',
              message: 'Your selected payment method has been declined. Please update it and try again',
            });
          } else {
            this.$arNotification.push({
              type: 'error',
              message: `Failed to add SMS credit to your account.`,
            });
          }

        } finally {
          this.isProcessingPayment = false;
        }
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      inputFormatter(input) {

        if (accounting.unformat(input.value) > 120000) return {
          value: '$120,000',
          position: null
        };
        if (accounting.unformat(input.value) < 0) return {
          value: '',
          position: null
        };

        return numberInputFormatter({
          input,
          currency: '$',
        });
      },
    }
  };
</script>

<style lang="scss" scoped>

.purchase-sms-credit-modal {
  .purchase-sms-credit-modal-body {
    padding: 0 48px 24px;
  }

  .sms-icon {
    display: flex;
    justify-content: center;
    margin: 46px 0 28px;
  }

  .purchase-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 36px 0 16px;

    .purchase-button {
      width: 100%;
    }

    &.xs-max {
      margin: 16px 0;
    }
  }
}

</style>
