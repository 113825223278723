<template>
  <am2-card-container
    :class="['template-card']"
  >
    <div
      class="email-review-box"
    >
      <ar-skeleton
        class="email-review-box-loading"
        :level="level"
      />
    </div>
    <am2-typography-skeleton
      class="email-template-title"
      size="xs"
      :level="level + 2"
    />
    <am2-typography-skeleton
      class="email-template-description"
      size="xs"
      :level="level + 2"
    />
  </am2-card-container>
</template>

<script>
export default {
  name: 'TemplateCardSkeleton',
  props: {
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.template-card {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 37.5px 67px 32px;

  .email-review-box {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 69%;
    border: 1px solid $blueGrey500;

    .email-review-box-loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .email-template-title {
    margin-top: 26px;
    width: 80%;
  }

  .email-template-description {
    margin-top: 12px;
  }
}
</style>
