<template>
  <am2-field name="registration-fields" class="registration-fields" label="Registration fields">
    <draggable
      :list="value"
    >
      <transition-group name="overlay-fade" tag="div">
        <div
          v-for="(field, idx) of value"
          :key="field.key"
          :data-test-id="`registration-field-${field.key}`"
        >
          <div :class="['u-display-flex', 'u-align-items-center', 'u-justify-content-space-between']" :style="{ height: '52px' }">
          <div :class="['u-display-flex', 'u-align-items-center']">
            <ar-text 
              class="u-flex-grow-1" 
              size="xs" 
              :text="field.name"
              :data-test-id="`registration-field-${idx}-text`"
            />
            <ar-icon 
              v-if="!field.predefined"
              class="icon edit-button"
              name="edit"
              height="10px"
              width="10px"
              stroke-width="4"
              stroke-linecap="round"
              :color="$arStyle.color.blueGrey700"
              @click="handleEditCustomField(field)"
              :data-test-id="`registration-field-${idx}-edit-button`"
            />
          </div>
            <ar-icon
              v-if="!(mandatoryRegistrationFields.indexOf(field.key) > -1)"
              class="icon remove-button"
              name="cross"
              height="10px"
              width="10px"
              stroke-width="4"
              stroke-linecap="round"
              :color="$arStyle.color.blueGrey700"
              @click="handleRemoveField(field)"
              :data-test-id="`registration-field-${idx}-remove-button`"
            />
          </div>
          <ar-divider />
        </div>
      </transition-group>
    </draggable>
    <div class="u-margin-top-5">
      <am2-link-button-dropdown
        placeholder="+ Add field"
        :items="filteredFields"
        search-placeholder="Search fields"
        has-search
        no-option-text="No other fields found"
        max-height="300px"
        @searchStringChange="handleSearchStringChange"
        :dropdown-style="{
          width: '280px',
        }"
        :button-props="{
          hasArrow: false,
          hasUnderline: true,
          textProps: {
            size: 'xs',
          },
        }"
        @select="handleSelectField"
        :hasFixedFooter="!isFreeCampaign"
        @fixedFooterClick="addCustomFieldClick"
        data-test-id="registration-fields-add-field-dropdown"
      />
    </div>
    <CustomFieldsModal
      :is-open="displayCustomFieldsModal"
      :editCustomField="editCustomField"
      @save="handleCustomFieldSave"
      @cancel="handleCustomFieldCancel"
    />
  </am2-field>
</template>
<script>
import { mapActions } from 'vuex';
import CustomFieldsModal from '../custom-fields-modal';
import draggable from 'vuedraggable'
import { clone } from '@/utils/helpers/';

export default {
  name: 'RegistrationFieldsDropdown',
  props: {
    value: {
      type: Array,
    },
    campaignType: {
      type: String,
    },
    // Unselected fields (pre-defined only)
    campaignUnselectedFields: {
      type: Array,
    },
    isFreeCampaign: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CustomFieldsModal,
    draggable,
  },
  data() {
    return {
      searchString: null,
      displayCustomFieldsModal: false,
      editCustomField: null,
    };
  },
  computed: {
    mandatoryRegistrationFields() {
      const mandatoryFields = ['emailAddress'];
      if (this.campaignType === 'preorder') {
        mandatoryFields.push('firstName', 'lastName', 'streetAddress', 'postcode', 'city', 'state', 'country');
      }
      return mandatoryFields;
    },
    filteredFields() {
      if (this.searchString) {
        return this.campaignUnselectedFields.filter((field) => {
          return this.$arFuzzySearch(field.name, this.searchString);
        });
      }
      return this.campaignUnselectedFields;
    },
    campaignOid() {
      return this.$route.params.oid || null;
    },
  },
  methods: {
    ...mapActions([
      'campaign/SET_CAMPAIGN_CUSTOM_FIELDS',
    ]),

    handleSelectField(field) {
      // Move item from unselected field to selected fields
      const unselectedIndex = this.campaignUnselectedFields.findIndex((f) => f.key === field.key)
      const selectedValue = this.campaignUnselectedFields[unselectedIndex];
      selectedValue.enabled = true;
      this.$emit('input', this.value.concat(selectedValue))

      const newUnseletedFields = this.campaignUnselectedFields.slice(0, unselectedIndex).concat(this.campaignUnselectedFields.slice(unselectedIndex + 1));
      this.$emit('updateUnselectedFields', newUnseletedFields)
    },
    handleRemoveField(field) {
      const selectedIndex = this.value.findIndex((f) => f.key === field.key)

      if (!!field.predefined) {
        // Put the removed value into unselectedFields
        const removedValue = this.value[selectedIndex]
        removedValue.enabled = false;
        this.$emit('updateUnselectedFields', this.campaignUnselectedFields.concat(removedValue))
      }

      // Remove index from fields
      const newFields = this.value.slice(0, selectedIndex).concat(this.value.slice(selectedIndex + 1));
      this.$emit('input', newFields)
    },
    handleEditCustomField(field) {
      const index = this.value.findIndex((f) => (f.predefined === false && f.name === field.name));
      this.editCustomField = { field: this.value[index], index: index };
      this.displayCustomFieldsModal = true;
    },
    handleSearchStringChange(value) {
      this.searchString = value;
    },
    addCustomFieldClick() {
      this.displayCustomFieldsModal = true;
    },
    async handleCustomFieldSave(field) {
      let newSelectedFields;
      let selectedIndex;
      const newCustomField = {
        ...field,
        key: field.name,
        predefined: false,
        enabled: true,
      }

      if (this.editCustomField) {
        // Replace existing custom field
        const index = this.editCustomField.index;
        const oldKey = !!this.value ? (this.value[index]?.name || null) : null

        if (oldKey != null) {
          // Find the existing selected field based on the old key
          selectedIndex = this.value.findIndex((f) => f.predefined === false && f.key === oldKey)

          if (selectedIndex != null && selectedIndex != undefined) {
            newSelectedFields = clone(this.value);
            newSelectedFields[selectedIndex] = newCustomField
          }

        }
      } else {
        // Append new custom field to the end of registration fields
        selectedIndex = this.value.length;
        newSelectedFields = this.value.concat(newCustomField)
      }

      // Update or create global custom fields
      const setCustomFieldResponse = await this['campaign/SET_CAMPAIGN_CUSTOM_FIELDS']({
        oid: this.campaignOid,
        action: !!this.editCustomField ? "update" : "create",
        customFieldData: {
          type: typeof newCustomField.type === 'string' ? newCustomField.type : newCustomField.type.key,
          name: newCustomField.name,
          options: newCustomField.options,
        },
      })

      if (selectedIndex != null && selectedIndex != undefined) {
        newSelectedFields[selectedIndex] = {
          ...newSelectedFields[selectedIndex],
          ...setCustomFieldResponse
        }
      }

      this.$emit('input', newSelectedFields);
      this.displayCustomFieldsModal = false;
      this.editCustomField = null;
    },
    handleCustomFieldCancel() {
      this.displayCustomFieldsModal = false;
      this.editCustomField = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.remove-button, .edit-button {
  cursor: pointer;
}
.edit-button {
  margin-left: 10px;
}
</style>
