<template>
  <div>
    <am2-button-group-tabs
      v-if="videodetails.type === ''"
      :active-index="videoGroupButtonsSelectIndex"
      :items="groupButtonsSelectItems"
      :style="{ width: '100%', marginBottom: '25px' }"
      @select="handleGroupButtonsSelect"
    />
    <am2-dropzone
      v-if="showVideoDropzone && videodetails.type === ''"
      :file-type="['video/mp4']"
      :file-size="200000000"
      file-type-alias="video"
      :placeholder-icon="{
        name: 'video',
      }"
      :video-options="{
        duration: 600,
      }"
      :ratio="0.525"
      enable-ar-s3-bucket-upload
      :ar-s3-bucket-upload-options-list="[{
        assetType: 'campaign-video',
        campaignOid: null,
        eventOid: null,
      }]"
      @upload="handleVideoUpload"
    />

    <span v-if="showVideoDropzone && videodetails.type === ''" class="field-info">Formats: .MP4, Max video size: 10 minutes, 200mb</span>

    <am2-video-external-picker v-if="showVideoUrlInput && videodetails.type === ''" @submit="handleVideoUrlSubmit"/>

    <p v-if="error !== null" class="error">{{ error }}</p>
    <p v-if="uploading">Uploading, please do not close this window</p>

    <am2-media-preview
      v-if="videodetails.url.length"
      @delete="handleDelete">
      <span
        slot="media-preview__content">
        <video
          v-if="videodetails.type === 'file'"
          :src="videodetails.url"
          controls
          width="192"/>
        <iframe
          v-if="videodetails.type === 'application/vnd.youtube.vt' || videodetails.type === 'application/vnd.vimeo'"
          :src="videodetails.url"
          width="192"
          height="108"
          class="video-iframe"/>
      </span>

      <span slot="media-preview__name">{{ videodetails.name }}</span>
      <span v-if="videodetails.type === 'file' && videodetails.size > 0" slot="media-preview__size">{{ (videodetails.size / 1000000).toFixed(2) }} MB</span>
    </am2-media-preview>
  </div>
</template>

<script>
export default {
  name: 'VideoUploader',

  props: {
    video: {
      type: Object,
      default: () => {},
    },
    externalVideoMediaType: {
      type: String,
      default: '',
    },
    externalVideoUrl: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    showPreview: true,
    showVideoDropzone: true,
    showVideoUrlInput: false,
    error: null,
    uploading: false,
    videoGroupButtonsSelectIndex: 0,
    videodetails: {
      name: '',
      size: 0,
      preview: '',
      type: '',
      url: '',
      id: '',
      data: '',
    },
    groupButtonsSelectItems: [
      {
        name: 'Upload video',
        iconName: 'upload',
        key: 'uploadVideo',
      },
      {
        name: 'YouTube/Vimeo URL',
        key: 'youtubeVimeoUrl',
      }
    ],
  }),

  created() {
    // this.get_filesize(this.video.url, size => console.log(`size=${size}`));
    if (this.video) {
      this.$set(this, 'videodetails', {
        name: this.video.filename,
        size: 0,
        type: 'file',
        url: this.video.url,
        id: '',
        data: '',
      });
    } else if (this.externalVideoUrl) {
      this.$set(this, 'videodetails', {
        type: this.externalVideoMediaType,
        url: this.externalVideoUrl,
        id: '',
        data: '',
      });
    }
  },

  methods: {
    handleGroupButtonsSelect(item, index) {
      this.videoGroupButtonsSelectIndex = index;
      if (item.key === 'uploadVideo') {
        this.handleUploadVideoButton();
      } else if (item.key === 'youtubeVimeoUrl') {
        this.handleVideoUrlButton();
      }
    },
    // YouTube/Vimeo
    async handleVideoUrlButton() {
      this.error = null;
      this.showVideoUrlInput = true;
      this.showVideoDropzone = false;
    },

    async handleVideoUrlSubmit(video) {
      this.error = null;

      this.handleDelete();

      if (video.type === 'youtube') {
        this.videodetails.name = 'YouTube video selected';
        this.videodetails.type = 'application/vnd.youtube.vt';
      } else if (video.type === 'vimeo') {
        this.videodetails.name = 'Vimeo video selected';
        this.videodetails.type = 'application/vnd.vimeo';
      } else {
        this.error = 'URL is not a valid YouTube or Vimeo link';
        this.handleDelete();
        return;
      }
      this.videodetails.size = '';
      this.videodetails.url = video.url;
      this.videodetails.id = video.videoId;

      this.showVideoUrlInput = false;
      this.showVideoDropzone = false;
      this.$emit('loadedExternal', this.videodetails);
    },

    // Upload
    async handleUploadVideoButton() {
      this.showVideoDropzone = true;
      this.showVideoUrlInput = false;
      this.error = null;
    },
    async emitVideoUpload(data) {
      this.videodetails.name = data.filename;
      // this.videodetails.size = file.size;

      this.videodetails.type = 'file';
      this.error = null;

      this.showVideoDropzone = false;
      this.videodetails.url = data.url;
      this.videodetails.type = 'file';

      this.showVideoUrlInput = false;
      this.showVideoDropzone = false;

      this.$emit('uploaded', data);
    },
    async handleVideoUpload({ arS3BucketResources }) {
      this.emitVideoUpload(arS3BucketResources[0]);
    },

    // Clear
    async handleDelete() {
      this.videodetails.name = '';
      this.videodetails.size = 0;
      this.videodetails.preview = '';
      this.videodetails.type = '';
      this.videodetails.url = '';
      this.videodetails.id = '';

      this.showVideoDropzone = true;

      this.$emit('delete');
    },

    get_filesize(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, true); // Notice "HEAD" instead of "GET",
      //  to get only the header
      xhr.onreadystatechange = () => {
        if (this.readyState === this.DONE) {
          callback(Number(xhr.getResponseHeader('Content-Length')));
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="scss">
.video-iframe {
  text-align: center;
}
.error {
  color: $red500;
}
.upload-video-button,
.external-video-button {
  font-weight: bold;
}

.field-info {
  color: $blueGrey600;
  display: block;
}
</style>
