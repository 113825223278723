var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-heading',{attrs:{"type":"h1","size":"lg","title":"Setup the basics"}}),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"To"}},[_c('div',{staticClass:"u-display-flex u-align-items-center u-justify-content-space-between"},[_c('am2-message-list-select',{class:[
          'message-list-recipients-dropdown',
          'u-flex-grow-1',
          _vm.showFilterButton && 'filter-enabled'
        ],attrs:{"channels":['email'],"value":_vm.scratchEmailMessage.meta.messageListOid,"campaign-oid":_vm.scratchEmailMessageCampaignOid,"event-oid":_vm.scratchEmailMessageEventOid,"data-test-id":"message-list-select"},on:{"select":_vm.handleMessageListChange}}),_vm._v(" "),(_vm.showFilterButton)?_c('div',{staticClass:"circle-hooker item"},[(_vm.filterIsNotEmpty && !!_vm.filterCount && _vm.filterCount > 0)?_c('div',{staticClass:"circle"},[_c('span',[_vm._v(_vm._s(_vm.filterCount))])]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"filter-btn u-margin-left-3",attrs:{"icon-name":"filter-alt","side-length":"40px","icon-props":{
            height: '16px',
          },"text":"Filter","type":_vm.filterIsNotEmpty ? 'purple' : 'grey',"outlined":"","data-test-id":"audience-table-filter-button","disabled":!this.scratchEmailMessage.meta.messageListOid},on:{"click":_vm.switchFilterSidebar}})],1):_vm._e()],1),_vm._v(" "),_c('div',[(_vm.currentMessageListHasNoEmailContacts)?_c('ar-snackbar',{staticClass:"u-width-100-percent",style:({ marginTop: '10px' }),attrs:{"type":"error","message":"There are no valid email addresses in this list."}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessage && _vm.currentMessageListIsForAllCampaignRegistrants)?_c('ar-snackbar',{staticClass:"u-margin-top-5 u-width-100-percent",attrs:{"type":"warning","message":"Changing from all registrants to a tier will disable scheduling."}}):_vm._e(),_vm._v(" "),(_vm.currentCampaignIsActive && _vm.currentMessageListIsForCampaignTier)?_c('ar-state-message',{staticClass:"u-margin-top-5",attrs:{"text-props":{
          weight: 'bold',
        },"has-icon":"","disable-text-color":"","type":"information","text":"Note: It's recommended to only message individual tiers once the campaign is ended."}}):_vm._e()],1)]),_vm._v(" "),(_vm.showAdvancedTargetingModule)?_c('am2-message-list-advanced-targeting-section',{attrs:{"channel":"email","show-toggle":""},on:{"updateAdvancedTargeting":_vm.handleAdvancedTargetingUpdate}}):_vm._e(),_vm._v(" "),(_vm.areFiltersNotEmpty)?_c('div',{staticClass:"recipients-count u-margin-top-4 u-padding-left-4"},[(_vm.isFetchingFilteredRecipientListCount && _vm.currentAdvancedTargetingIsValid)?_c('am2-loading-spinner',{style:({
        width: '1.5em',
        height: '1.5em'
      })}):_c('div',{staticClass:"recipients-count"},[_c('ar-icon',{staticClass:"sales-chart-settings-icon settings-button u-margin-right-3",attrs:{"name":"team","width":"16px","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{staticClass:"recipients-text",attrs:{"size":"xs","data-test-id":"message-targeting-recipient-count","text":_vm.recipientCountText}}),_vm._v(" "),_c('div',{staticClass:"u-margin-left-2"},[_vm._v("·")]),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-left-2",attrs:{"text":"Clear all","has-underline":"","data-test-id":"message-list-clear-all-filters"},on:{"click":_vm.handleClearAllFilters}})],1)],1):_vm._e(),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Message name"}},[_c('ar-input',{attrs:{"autocomplete":"message-name","value":_vm.scratchEmailMessage.customerName,"data-test-id":"message-name-input"},on:{"input":_vm.handleCustomerNameInput}})],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"From"}},[_c('div',[_c('am2-message-sender-select',{ref:"messageSenderSelect",attrs:{"value":_vm.scratchEmailMessage.meta.email && _vm.scratchEmailMessage.meta.email.promoterPropertyOid},on:{"select":_vm.handleSenderSelect,"clear":_vm.handleMessageSenderClear}})],1),_vm._v(" "),(_vm.currentMessageSenderNotVerified)?_c('ar-snackbar',{staticClass:"u-width-100-percent",style:({ marginTop: '10px' }),attrs:{"type":"error","message":"Please verify this email address. <a>Resend verification email</a>."},on:{"anchorClick":_vm.handleResendVerificationEmailAnchorClick}}):_vm._e()],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Subject"}},[_c('ar-input',{attrs:{"value":_vm.scratchEmailMessage.meta.messageBody.subject,"data-test-id":"message-subject"},on:{"input":_vm.handleSubjectInput}})],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Preview line"}},[_c('ar-input',{attrs:{"value":_vm.scratchEmailMessage.meta.messageBody.previewText,"autocomplete":"preview-line","data-test-id":"message-preview-line"},on:{"input":_vm.handlePreviewTextInput}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }