var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'am2-date-time-input',
    'u-display-inline-flex',
    'u-align-items-center' ]},[_c('div',{staticClass:"u-flex-grow-2 u-flex-shrink-0",style:({
      width: _vm.size === 'small' ? '135px' : '178px',
    })},[(_vm.hasLabel)?_c('ar-text',{staticClass:"u-margin-bottom-3",style:({
        color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"size":"xs","text":"Date"}}):_vm._e(),_vm._v(" "),_c('am2-datepicker',{style:({
        height: '50px',
        width: '100%',
      }),attrs:{"value":_vm.dateTimeViewModel.date,"disabled":_vm.disabled,"disabled-to":_vm.disabledToDate,"disabled-from":_vm.disabledFromDate,"format":_vm.size === 'small' ? 'MMM Do, YYYY' : 'MMMM Do, YYYY',"data-test-id":"date-time-input-date-picker"},on:{"input":_vm.handleDateChange}})],1),_vm._v(" "),_c('div',{staticClass:"u-margin-left-2 u-flex-grow-1 u-flex-shrink-0",style:({
      width: '120px',
    })},[(_vm.hasLabel)?_c('ar-text',{staticClass:"u-margin-bottom-3",style:({
        color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"size":"xs","text":"Time"}}):_vm._e(),_vm._v(" "),_c('ar-input',{style:({
        height: '50px',
        width: '100%',
      }),attrs:{"placeholder":"e.g. 12:00pm","value":_vm.dateTimeViewModel.time,"disabled":_vm.disabled,"autocomplete":"time","data-test-id":"date-time-input-time-input"},on:{"input":_vm.handleTimeChange,"blur":_vm.handleTimeInputBlur}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }