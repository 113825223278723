var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-tags-paginator"},[_c('ar-icon-button',{attrs:{"icon-props":{
      name: 'arrow',
      rotate: 90,
    },"data-test-id":"tags-paginator-previous-button"},on:{"click":_vm.handlePreviousClick}}),_vm._v(" "),_c('ar-text',{style:({
      color: '#9FA8B5',
    }),attrs:{"size":"10px","text":_vm.currentPageCopy}}),_vm._v(" "),_c('ar-icon-button',{attrs:{"icon-props":{
      name: 'arrow',
      rotate: -90,
    },"data-test-id":"tags-paginator-next-button"},on:{"click":_vm.handleNextClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }