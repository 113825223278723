var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ar-playground-section"},[_c('ar-text',{style:({
      color: _vm.$arStyle.color.purple500,
    }),attrs:{"size":"xl","text":_vm.component,"weight":"bold"}}),_vm._v(" "),(_vm.description)?_c('ar-text',{style:({
      marginTop: '14px',
    }),attrs:{"size":"sm","text":_vm.description,"multiple-lines":"","allow-html":""}}):_vm._e(),_vm._v(" "),_c('section',{staticClass:"tips-section"},_vm._l((_vm.tips),function(tip,tipIdx){return _c('ar-snackbar',{key:tipIdx,staticClass:"tip",attrs:{"type":"warning","message":tip}})}),1),_vm._v(" "),_c('ar-divider',{style:({
      margin: '10px 0 20px',
    })}),_vm._v(" "),_c('ar-text',{attrs:{"size":"md","text":"Props","weight":"bold"}}),_vm._v(" "),_c('ar-checkbox',{attrs:{"label":"Show props"},model:{value:(_vm.expandAttributeSchema),callback:function ($$v) {_vm.expandAttributeSchema=$$v},expression:"expandAttributeSchema"}}),_vm._v(" "),(_vm.expandAttributeSchema)?_c('am2-card-container',{staticClass:"schema-items-wrapper",style:({
      marginTop: '20px',
    })},_vm._l((_vm.attributeSchema),function(prop,idx){return _c('section',{key:idx},[_c('div',{staticClass:"schema-item"},[_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Name"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",style:({
              color: _vm.$arStyle.color.red500,
            }),attrs:{"size":"xs","text":prop.name,"weight":"bold","allow-html":""}})],1),_vm._v(" "),_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Type"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":prop.type,"weight":"bold"}})],1),_vm._v(" "),_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Description"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":prop.description,"allow-html":""}})],1),_vm._v(" "),_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Required"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",style:({
              color: prop.required ? _vm.$arStyle.color.red500 : null,
            }),attrs:{"size":"xs","text":prop.required ? 'true' : 'false',"allow-html":""}})],1)]),_vm._v(" "),(idx !== _vm.attributeSchema.length - 1)?_c('ar-divider',{staticClass:"u-margin-bottom-5"}):_vm._e()],1)}),0):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
      marginTop: '20px',
    }),attrs:{"size":"md","text":"Events","weight":"bold"}}),_vm._v(" "),_c('ar-checkbox',{attrs:{"label":"Show events"},model:{value:(_vm.expandListenerSchema),callback:function ($$v) {_vm.expandListenerSchema=$$v},expression:"expandListenerSchema"}}),_vm._v(" "),(_vm.expandListenerSchema)?_c('am2-card-container',{style:({
      padding: '30px',
      marginTop: '20px',
    })},[(_vm.listenerSchema.length === 0)?_c('ar-text',{attrs:{"size":"xs","text":"This component has no events"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.listenerSchema),function(listener,idx){return _c('section',{key:idx},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.red500,
        }),attrs:{"size":"md","text":("@" + (listener.name)),"weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
          marginTop: '7px',
        }),attrs:{"size":"xs","text":listener.description,"allow-html":""}}),_vm._v(" "),_c('ar-text',{style:({
          marginTop: '20px',
        }),attrs:{"size":"lg","text":_vm.generateListenerFunctionCopy(listener.parameters),"weight":"bold"}}),_vm._v(" "),_c('am2-card-container',{staticClass:"schema-items-wrapper",style:({
          marginTop: '10px',
        })},[(!listener.parameters)?_c('ar-text',{attrs:{"size":"xs","text":"This event has no params"}}):_vm._e(),_vm._v(" "),_vm._l((listener.parameters),function(parameter,idx){return _c('section',{key:idx},[_c('div',{staticClass:"schema-item"},[_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Name"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",style:({
                  color: _vm.$arStyle.color.red500,
                }),attrs:{"size":"xs","text":parameter.name,"weight":"bold"}})],1),_vm._v(" "),_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Type"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":parameter.type,"weight":"bold"}})],1),_vm._v(" "),_c('section',{staticClass:"schema-item-attr"},[_c('ar-text',{attrs:{"size":"xxxs","text":"Description"}}),_vm._v(" "),_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":parameter.description,"allow-html":""}})],1)]),_vm._v(" "),(idx !== listener.parameters.length - 1)?_c('ar-divider',{staticClass:"u-margin-bottom-5"}):_vm._e()],1)})],2),_vm._v(" "),(idx !== _vm.listenerSchema.length - 1)?_c('ar-divider',{staticClass:"u-margin-bottom-5"}):_vm._e()],1)})],2):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
      marginTop: '30px',
    }),attrs:{"size":"md","text":"Examples","weight":"bold"}}),_vm._v(" "),_vm._l((_vm.demoGroups),function(demoGroup,idx){return _c('div',{key:idx,staticClass:"example-section",style:({
      marginTop: '20px',
    })},[_c('ar-text',{attrs:{"size":"sm","text":demoGroup.title,"allow-html":"","weight":"bold"}}),_vm._v(" "),(demoGroup.description)?_c('ar-text',{style:({
        marginTop: '4px',
      }),attrs:{"size":"xs","text":demoGroup.description,"allow-html":""}}):_vm._e(),_vm._v(" "),(demoGroup.tabs && demoGroup.tabs.length > 1)?_c('am2-card-container',{style:({
        padding: '12px 32px 0',
        marginTop: '24px',
      })},[_c('am2-tabs',{staticClass:"tabs",attrs:{"items":demoGroup.tabs,"selected-tab-index":_vm.selectedTab},on:{"select":_vm.changeSelectedTab}})],1):_vm._e(),_vm._v(" "),_c('am2-card-container',{staticClass:"demo-section",style:({
        marginTop: '10px',
      })},[_c('section',{staticClass:"demo-section-control",on:{"click":function($event){return _vm.handleDisplayExampleClick(idx)}}},[_c('ar-text',{staticClass:"demo-section-control-title",attrs:{"size":"sm","text":"View source code"}}),_vm._v(" "),_c('ar-icon',{staticClass:"demo-section-control-title-icon",attrs:{"name":"search","height":"16px"}})],1),_vm._v(" "),_c('section',{class:['code-section', _vm.exampleDisplayMap[idx] && 'show']},[_c('section',{staticClass:"code"},[_vm._v(_vm._s(_vm.generateCode(demoGroup.demos)))])]),_vm._v(" "),_c('section',{class:['demo', demoGroup.type || 'white'],style:({
          flexFlow: demoGroup.flow === 'column' ? 'column' : 'wrap',
        })},_vm._l((demoGroup.demos),function(demo,demoIdx){return _c(_vm.component,_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: demo.description,
          }),expression:"{\n            content: demo.description,\n          }"}],key:demoIdx,tag:"component",staticClass:"component"},'component',demo.attributes,false),demo.listeners))}),1)])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }