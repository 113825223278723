<template>
  <section class="campaign-edit-connect">
    <am2-edit-privacy-policy-modal
      :opened="displayEditPrivacyPolicyModal"
      :default-company-name="editPrivacyPolicyDefaultCompanyName"
      :default-url="editPrivacyPolicyDefaultUrl"
      @confirm="handleEditPrivacyPolicyConfirm"
      @cancel="handleEditPrivacyPolicyCancel"
    />
    <am2-campaign-social-action-settings-modal
      :opened="displayCampaignSocialActionSettingsModal"
      :title="campaignSocialActionData.title"
      :default-label="campaignSocialActionData.defaultLabel"
      :label="campaignSocialActionData.label"
      :points="campaignSocialActionData.points"
      :disclaimer="campaignSocialActionData.disclaimer"
      :url="campaignSocialActionData.value"
      :display-disclaimer="['sms', 'email'].indexOf(campaignSocialActionData.key) > -1"
      :display-url="['custom'].indexOf(campaignSocialActionData.key) > -1"
      :display-label="['facebook:messenger'].indexOf(campaignSocialActionData.key) === -1"
      @confirm="handleSocialActionSettingsConfirm"
      @cancel="handleSocialActionSettingsCancel"
    />
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 6"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h2"
      size="lg"
      title="Earn more points"
      :style="{marginBottom: '38.5px'}"
    />
    <am2-heading
      type="h3"
      size="md"
      title="Follow"
      :style="{ marginBottom: '12px' }"
    />

    <p class="large">
      Fans earn points by completing additional actions. Choose what actions you want fans to do.
    </p>

    <ar-snackbar
      v-if="duplicatedTargetError"
      class="u-width-100-percent"
      type="warning"
      :message="duplicatedTargetError"
      :style="{ marginTop: '10px' }"
    />

    <div
      v-if="!!editCampaign && !!editCampaign.socialActions && !!editCampaign.socialActions.socialAccounts"
    >
      <ar-field
        v-for="(account, accountIndex) in editCampaign.socialActions.socialAccounts"
        :data-test-id="`account-${accountIndex}`"
        ref="fields"
        :key="`f_${accountIndex}`"
        :name="`social-follow-channels_${accountIndex}`"
        :class="[
          'social-follow-channels',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]"
        :style="{
          marginTop: '30px',
        }"
      >
        <div class="follow-account-label">
          <div class="label-description">
            <div class="heading-section">
              <ar-text
                class="heading"
                size="xs"
                text="Brand name"
                weight="bold"
              />

              <ar-icon-button
                v-if="editCampaign.socialActions.socialAccounts.length > 1"
                class="remove-social-account"
                :icon-props="{
                  name: 'discard',
                  height: '14px',
                }"
                :style="{
                  minHeight: 'unset',
                  
                }"
                @click="handleSocialAccountDiscardClick(accountIndex)"
                :data-test-id="`remove-social-account-${accountIndex}`"
              />
            </div>

            <ar-link-button
              :text="!!account.additionalInfo && !!account.additionalInfo.privacyPolicy ? `Edit privacy policy` : `Add privacy policy`"
              @click="handleEditPrivacyPolicyClick(accountIndex)"
              :data-test-id="`edit-privacy-policy-${accountIndex}`"
            />
          </div>
          <div>
            <span>Name of the event or brand</span>
          </div>
          <br>
        </div>
        
        <am2-brand-select
          class="u-margin-bottom-1"
          :data-test-id="`account-${accountIndex}-brand-select`"
          v-validate="{
            required: !!account && !!account.actions && account.actions.length > 0,
          }"
          :data-vv-name="`accountName-${accountIndex}`"
          :data-vv-as="`brand name`"
          :value="account.name"
          :account-name="account.name"
          :brands="brands"
          :is-new-brand="account.saveNew === true"
          @create="(name) => handleBrandCreateNew(name, account)"
          @select="(brand) => handleBrandSelect(brand, accountIndex)"
          @delete="handleBrandDelete"
          @save="(brand) => handleBrandSave(brand, accountIndex)"
          @input="(name) => debounceHandleSaveAccountName(name, accountIndex)"
        />

        <ar-state-message
          v-if="veeErrors.has(`accountName-${accountIndex}`)"
          type="error"
          :text="veeErrors.first(`accountName-${accountIndex}`)"
          :style="{ marginTop: '8px' }"
        />

        <draggable
          :list="account.actions"
          @change="(event) => handleMove(event, accountIndex)"
          @end="onDragEnd"
          @start="onDragStart"
        >
          <transition-group>
            <div
              v-for="(socialAction, socialActionIdx) in account.actions"
              :key="socialAction.widgetToken"
              :data-test-id="`account-${accountIndex}-action-${socialAction.key}-${socialActionIdx}`"
              class="social-account__item"
            >
              <div :style="{ margin: '5px auto' }">
                <div :class="[
                  'social-account__item-inner',
                  currentFocusFollowOption === `socialInput-${accountIndex}-${socialActionIdx}` && 'social-account__item-inner-focus',
                ]">
                  <ar-icon
                    class="connect-icon"
                    :name="followOptionsObject[socialAction.key] ? followOptionsObject[socialAction.key].iconName : null"
                    :color="followOptionsObject[socialAction.key] ? followOptionsObject[socialAction.key].iconColor : null"
                    height="16px"
                    width="16px"
                  />

                  <ar-text
                    class="social-account__item-label"
                    size="xs"
                    weight="bold"
                    :text="formatName(socialAction.key)"
                  />

                  <am2-message-list-select
                    v-if="socialAction.key === 'email'"
                    class="select"
                    :data-vv-scope="`account-${accountIndex}`"
                    :data-vv-name="`${accountIndex}-email-${socialAction.widgetToken}`"
                    :data-vv-as="followOptionsObject[socialAction.key].name"
                    v-validate="{
                      required: true,
                    }"
                    :value="(socialAction.value != 'enable' && socialAction.value != 'disable') ? socialAction.value : null"
                    @input="(messageListOid) => handleEmailMessageListChange(messageListOid, accountIndex, socialActionIdx)"
                    :channels="['email']"
                    message-list-type="manual"
                    :show-stats="false"
                    :data-test-id="`${accountIndex}-email-${socialActionIdx}-dropdown`"
                  />

                  <am2-message-list-select
                    v-else-if="socialAction.key === 'sms'"
                    :data-vv-scope="`account-${accountIndex}`"
                    class="select"
                    :data-vv-name="`${accountIndex}-sms-${socialAction.widgetToken}`"
                    :data-vv-as="followOptionsObject[socialAction.key].name"
                    v-validate="{
                      required: true,
                    }"
                    :value="(socialAction.value != 'enable' && socialAction.value != 'disable') ? socialAction.value : null"
                    @input="(messageListOid) => handleSmsMessageListChange(messageListOid, accountIndex, socialActionIdx)"
                    :channels="['sms']"
                    message-list-type="manual"
                    :show-stats="false"
                    :data-test-id="`${accountIndex}-sms-${socialActionIdx}-dropdown`"
                  />
                  <ar-input
                    v-else
                    :data-vv-scope="`account-${accountIndex}`"
                    class="input"
                    v-validate="followOptionsObject[socialAction.key] ? {
                      required: true,
                      [`${followOptionsObject[socialAction.key].vValidateRule}`]: true,
                    } : null"
                    :ref="`socialInput-${accountIndex}-${socialActionIdx}`"
                    :value="socialAction.value"
                    @input="(val) => handleSocialActionInput(val, accountIndex, socialActionIdx)"
                    :placeholder="followOptionsObject[socialAction.key] ? followOptionsObject[socialAction.key].placeholder : null"
                    :data-vv-name="followOptionsObject[socialAction.key] ? `${accountIndex}-${followOptionsObject[socialAction.key].vValidateRule}-${socialAction.widgetToken}` : null"
                    :data-vv-as="`${followOptionsObject[socialAction.key] ? followOptionsObject[socialAction.key].placeholder : ''}`"
                    @focus="currentFocusFollowOption = `socialInput-${accountIndex}-${socialActionIdx}`"
                    @enter="currentFocusFollowOption = null"
                    @blur="fieldBlurAction"
                    :style="{
                      background: 'none',
                    }"
                    :data-test-id="`${accountIndex}-input-${socialActionIdx}-${socialAction.key}`"
                  />
                  <am2-icon-button-dropdown
                    :icon-props="{
                      name: 'menu',
                      height: '16px',
                    }"
                    :items="dropdownActions"
                    :dropdown-style="{
                      width: '200px',
                    }"
                    :data-test-id="`action-${accountIndex}-setting-menu-${socialAction.widgetToken}`"
                    @select="(item) => handleSettingOptionSelect(item, accountIndex, socialActionIdx)"
                  />
                </div>
                <ar-state-message
                  v-show="followOptionsObject[socialAction.key] && veeErrors.has(`account-${accountIndex}.${accountIndex}-${followOptionsObject[socialAction.key].vValidateRule}-${socialAction.widgetToken}`)"
                  :text="followOptionsObject[socialAction.key] && veeErrors.first(`account-${accountIndex}.${accountIndex}-${followOptionsObject[socialAction.key].vValidateRule}-${socialAction.widgetToken}`)"
                  :style="{ marginLeft: '8px' }"
                  type="error"
                />
              </div>
            </div>

          </transition-group>
        </draggable>
        <am2-simple-button-dropdown
          class="dropdown-button"
          :items="followOptions"
          :button-props="{
            text: 'Add action',
            type: 'purple',
            style: { height: '40px', width: '100%' },
            iconName: 'circle-plus',
            iconDistance: '9px',
          }"
          :dropdown-max-height="'40vh'"
          :dropdown-style="{
            width: '250px',
          }"
          :style="{
            'width': '100%',
            'marginTop': '20px',
          }"
          align="center"
          @select="(event) => handleAddActionClick(event, accountIndex)"
          :data-test-id="`add-action-button-${accountIndex}`"
        />

      </ar-field>
    </div>
    <ar-link-button
      class="u-margin-top-8 u-margin-left-8"
      text="+ Add another brand"
      @click="handleAddBrandClick"
      :data-test-id="`add-new-account-button`"
    />


    <am2-heading
      type="h3"
      size="md"
      title="Connect"
      :style="{ marginBottom: '12px', marginTop: '50px' }"
    />
    
    <p class="large">
      Fans earn points for connecting their social accounts. Select the social accounts fans can connect and you'll collect data including age, gender, location, number of social media followers and top artists/tracks.
    </p>

    <am2-field
      name="social-connect-channels"
      :class="[
        'social-connect-channels',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-3',
      ]">
      <div
        v-for="(connect, index) in connectChannels"
        :key="connect.key"
      >
        <ar-checkbox
          v-model="connect.selected"
          :icon-name="connect.iconName"
          :icon-props="{ color: connect.iconColor }"
          :label="connect.name"
          align="right"
          :style="{ width: '100%', height: '52px' }"
        />

        <ar-divider
          v-if="index !== connectChannels.length - 1"
        />
      </div>

    </am2-field>

  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { generateHash, clone, mergeObjects, capitalizeFirstLetter } from '@/utils/helpers/';
import { formatProviderName } from '@/utils/campaign';

import { debounce } from 'debounce';
import draggable from 'vuedraggable';


const DEFAULT_POINTS = 5
const SMS_EMAIL_POINTS = 10

const setDragCursor = value => {
  const html = document.getElementsByTagName('html').item(0)
  html.classList.toggle('grabbing', value)
}

export default {
  name: 'EditConnect',

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  components: {
    draggable,
  },

  data() {
    const followOptions =  [
      {
        name: 'Select action to add…',
        type: 'header',
        key: 'header'
      },
      {
        type: 'divider',
        key: 'divider'
      },
      {
        name: 'SOCIAL MEDIA',
        type: 'header',
        key: 'header'
      },
      {
        name: 'Follow on Instagram',
        defaultLabel: 'Follow on Instagram',
        provider: 'instagram',
        vValidateRule: 'instagramUsername',
        placeholder: 'Instagram username',
        iconName: 'instagram',
        iconColor: null,
        blurAction: input => {
          return input.indexOf('@') === 0 ? input.substring(1) : input
        },
      },
      {
        name: 'Follow on TikTok',
        defaultLabel: 'Follow on TikTok',
        provider: 'tiktok',
        vValidateRule: 'tiktokUsername',
        placeholder: 'TikTok username',
        iconName: 'tiktok',
        iconColor: null,
        blurAction: input => {
          return input.indexOf('@') === 0 ? input.substring(1) : input
        },
      },
      {
        name: 'RSVP Facebook Event',
        defaultLabel: 'RSVP Facebook Event',
        provider: 'facebook:event',
        vValidateRule: 'facebookEventUrl',
        placeholder: 'Facebook event URL',
        iconName: 'facebook-event',
        iconColor: null,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
      {
        name: 'Like Facebook Page',
        defaultLabel: 'Like Facebook Page',
        provider: 'facebook',
        vValidateRule: 'facebookUrl',
        placeholder: 'Facebook URL',
        iconName: 'facebook',
        iconColor: this.$arStyle.color.facebook,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
      {
        name: 'Follow on YouTube',
        defaultLabel: 'Follow on YouTube',
        provider: 'youtube',
        vValidateRule: 'youtubeChannelUrl',
        placeholder: 'YouTube URL',
        iconName: 'youtube',
        iconColor: this.$arStyle.color.youtube,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
      {
        name: 'Subscribe on Twitch',
        defaultLabel: 'Subscribe on Twitch',
        provider: 'twitch',
        vValidateRule: 'twitchUrl',
        placeholder: 'Twitch URL',
        iconName: 'twitch',
      },
      {
        name: 'Follow on Twitter',
        defaultLabel: 'Follow on Twitter',
        provider: 'twitter',
        vValidateRule: 'twitterUsername',
        placeholder: 'Twitter username',
        iconName: 'twitter',
        iconColor: this.$arStyle.color.twitter,
      },
      {
        name: 'Follow on LinkedIn',
        defaultLabel: 'Follow on LinkedIn',
        provider: 'linkedin',
        vValidateRule: 'linkedinURL',
        placeholder: 'Linkedin URL',
        iconName: 'linkedin',
        iconColor: this.$arStyle.color.linkedin,
      },
      {
        name: 'SUBSCRIBE',
        type: 'header',
        key: 'header'
      },
      {
        name: 'SMS',
        defaultLabel: 'Subscribe to SMS',
        provider: 'sms',
        vValidateRule: 'sms',
        placeholder: 'SMS',
        iconName: 'sms',
        iconColor: this.$arStyle.color.sms,
        type: 'select',
        items: [
          {
            name: 'Enable',
            value: 'enable',
          },
          {
            name: 'Disable',
            value: undefined,
          },
        ],
        defaultSelectIndex: 1,
      },
      {
        name: 'Email',
        defaultLabel: 'Subscribe to Email',
        provider: 'email',
        vValidateRule: 'email',
        placeholder: 'Email',
        iconName: 'email',
        iconColor: this.$arStyle.color.email,
        type: 'select',
        items: [
          {
            name: 'Enable',
            value: 'enable',
          },
          {
            name: 'Disable',
            value: undefined,
          },
        ],
        defaultSelectIndex: 1,
      },
      {
        name: 'STREAMING',
        type: 'header',
        key: 'header'
      },
      {
        name: 'Follow Spotify Playlist',
        defaultLabel: 'Follow Spotify Playlist',
        provider: 'spotify:playlist',
        vValidateRule: 'spotifyPlaylistUrl',
        placeholder: 'Spotify playlist URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'Follow Spotify Artist',
        defaultLabel: 'Follow Spotify Artist',
        provider: 'spotify:artist',
        vValidateRule: 'spotifyArtistUrl',
        placeholder: 'Spotify artist URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'Save Spotify Track',
        defaultLabel: 'Save Spotify Track',
        provider: 'spotify:track',
        vValidateRule: 'spotifyTrackUrl',
        placeholder: 'Spotify track URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'Save Spotify Album',
        defaultLabel: 'Save Spotify Album',
        provider: 'spotify:album',
        vValidateRule: 'spotifyAlbumUrl',
        placeholder: 'Spotify album URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'Follow Spotify Podcast',
        defaultLabel: 'Follow Spotify Podcast',
        provider: 'spotify:show',
        vValidateRule: 'spotifyPodcasterUrl',
        placeholder: 'Spotify podcast URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'Save Spotify Episode',
        defaultLabel: 'Save Spotify Episode',
        provider: 'spotify:episode',
        vValidateRule: 'spotifyPodcastUrl',
        placeholder: 'Spotify episode URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
      },
      {
        name: 'CUSTOM',
        type: 'header',
        key: 'header'
      },
      {
        name: `Custom`,
        provider: 'custom',
        vValidateRule: 'url',
        placeholder: 'Enter URL',
        iconName: 'link',
      },
    ];

    const followOptionsObject = followOptions.reduce((optionsObject, option, index) => {
      return {
        ...optionsObject,
        [option.provider]: {
          ...option,
        }
      }
    }, {});

    return {
      dropdownActions: [
        {
          name: 'Settings',
          key: 'settings',
          action: this.showSettingsModal,
        },
        {
          name: 'Delete',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
          key: 'handleDeleteActionClick',
          action: this.handleDeleteActionClick,
        }
      ],
      displayCampaignSocialActionSettingsModal: false,
      campaignSocialActionData: {
        title: null,
        defaultLabel: null,
        label: null,
        points: null,
        disclaimer: null,
        value: null,
        key: null,
      },
      campaignSocialAccountIndex: null,
      campaignSocialAccountActionIndex: null,
      displayEditPrivacyPolicyModal: false,
      editPrivacyPolicyAccountIndex: null,
      editPrivacyPolicyDefaultCompanyName: null,
      editPrivacyPolicyDefaultUrl: null,
      currentFocusFollowOption: null,
      connectChannels: [],
      followOptions,
      followOptionsObject,
    };
  },

  computed: {
    ...mapState({
      brands: state => state.brand.brands,
    }),

    accountSelectItems() {
      return this.campaignViewModel.savedAccounts.filter(a => a.name).map(a => a.name);
    },

    // Duplicated targets are overwritten in the db  to respect the uniqueness of the data source.
    // We decided to show a warning message, as in the email form to inform the user 
    // that duplicated action-value are not allowed and they will incur in issues.
    duplicatedTargetError() {
      const socialAccounts = this.editCampaign?.socialActions?.socialAccounts

      if (socialAccounts) {
        let kv = []

        socialAccounts.forEach(({ actions }) => {
          if (actions) {
            actions.forEach(({ key, value }) => kv.push(`${key}@${value}`))
          }
        })

        const countOcc = kv.reduce((result, value) => ({ ...result, [value]: (result[value] || 0) + 1 }), {})
        const duplicated = Object.keys(countOcc).filter((a) => countOcc[a] > 1)

        if (!!duplicated && duplicated.length) {
          const duplicatedKeys = duplicated.map((value) => {
            const key = value.split('@')[0]

            if (key.indexOf(':')) {
              return key.split(':').map(v => capitalizeFirstLetter(v)).join(' ')
            }
            return capitalizeFirstLetter(key)
          })

          return `Every action and value pair should be unique, within the brand and across multiple brands. Please check the values used for the following actions: ${duplicatedKeys.join(', ')}.`
        }
      }
    },
  },

  watch: {
    'editCampaign.socialActions': {
      handler(val) {
        // We have to make sure that this is debounced because social actions is changed whenever an item is typed
        if (this.debounceKeys) {
          this.debounceKeys(clone(val))
        }
      },
      immediate: true,
      deep: true
    },
    connectChannels: {
      handler(val) {
        this.convertConnectChannels(clone(val));
      },
      immediate: true,
      deep: true
    },
  },

  created() {
    this.debounceKeys = debounce(this.fillMissingAccountKeyAndActionWidgetToken, 500);
    this.debouncePatchSocialAction = debounce(this.patchSocialAction, 500)
    this.debounceHandleSaveAccountName = debounce(this.handleSaveAccountName, 500)
    this.initializeCampaignViewModel();
  },

  methods: {
    ...mapActions([
      'brand/FETCH_ALL_BRANDS',
      'brand/CREATE_BRAND',
      'brand/DELETE_BRAND',
      'brand/PATCH_BRAND',
    ]),

    async fillMissingAccountKeyAndActionWidgetToken(newSocialActions) {
      const anyAccountWithoutKey = (newSocialActions.socialAccounts || []).some(account => {
        return !account.key;
      });
      const anyActionWithoutWidgetToken = (newSocialActions.socialAccounts || []).some(account => {
        return (account.actions || []).some(action => !action.widgetToken);
      });
      
      if (!anyAccountWithoutKey && !anyActionWithoutWidgetToken) {
        return;
      }
      
      newSocialActions.socialAccounts.forEach(account => {
        // Add account key
        if (!account.key) {
          account.key = generateHash();
        }

        // Add action widget token
        (account.actions || []).forEach((action) => {
          if (!action.widgetToken) {
            action.widgetToken = generateHash();
          }
        });
      });

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: newSocialActions
        });
      }
    },

    convertConnectChannels(connectChannels) {
      if (!connectChannels) {
        return;
      }

      const connects = connectChannels.reduce((connects, channels) => {
        connects[channels.key] = channels.selected
        return connects
      }, {})

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            connects
          }
        });
      }
    },

    initializeCampaignViewModel() {
      this.fetchSocialAccounts();
      this.fetchConnectChannels();
      this.$validator.validateAll();
    },
    fieldBlurAction() {
      this.currentFocusFollowOption = null
    },
    formatName(followTarget) {
      return followTarget.indexOf(':') > -1 ?
        formatProviderName(followTarget.split(':')[1]) :
        formatProviderName(followTarget);
    },
    extractSocialActionForServer(socialAction) {
      return {
        key: socialAction.key,
        value: socialAction.value,
        points: socialAction.points,
        label: socialAction.label,
        disclaimer: socialAction.disclaimer,
      };
    },
    addSocialAccount(account) {
      const newSocialAccounts = (!!this.editCampaign?.socialActions?.socialAccounts) ? clone(this.editCampaign.socialActions.socialAccounts) : [];
      newSocialAccounts.push(account);
      
      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    patchSocialAccount(accountIndex, changes) {
      let newSocialAccounts = []

      if (!!this.editCampaign?.socialActions) {
        newSocialAccounts = clone(this.editCampaign.socialActions.socialAccounts);
        newSocialAccounts[accountIndex] = mergeObjects(newSocialAccounts[accountIndex], changes);
      }

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    deleteSocialAccount(accountIndex) {
      if (!this.editCampaign?.socialActions?.socialAccounts || accountIndex == null || accountIndex == undefined) {
        return;
      }

      const newSocialAccounts = clone(this.editCampaign.socialActions.socialAccounts);
      newSocialAccounts.splice(accountIndex, 1);


      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    addSocialAction(accountIndex, action) {
      const newSocialAccounts = clone(this.editCampaign.socialActions.socialAccounts) || [];
      if (newSocialAccounts[accountIndex].actions) {
        newSocialAccounts[accountIndex].actions.push(action);
      } else {
        newSocialAccounts[accountIndex].actions = [action];
      }
      
      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    patchSocialAction(accountIndex, actionIndex, changes) {
      const newSocialAccounts = (!!this.editCampaign?.socialActions?.socialAccounts) ? clone(this.editCampaign.socialActions.socialAccounts) : [];
      newSocialAccounts[accountIndex].actions[actionIndex] = mergeObjects(
        newSocialAccounts[accountIndex].actions[actionIndex],
        changes,
      );

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    deleteSocialAction(accountIndex, actionIndex) {
      if (!this.editCampaign?.socialActions?.socialAccounts || accountIndex == null || accountIndex == undefined || actionIndex == null || actionIndex == undefined) {
        return;
      }

      const newSocialAccounts = clone(this.editCampaign.socialActions.socialAccounts);

      if (!!newSocialAccounts[accountIndex]?.actions) {
        newSocialAccounts[accountIndex].actions.splice(actionIndex, 1);
      }

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },
    handleAddActionClick(event, accountIndex) {
      const socialAccount = this.editCampaign.socialActions.socialAccounts[accountIndex];

      const option = this.followOptions.find((option) => {
        return event.provider == option.provider
      });

      let defaultLabel = option.defaultLabel;

      if (option.provider === 'custom') {
        defaultLabel = `Register with ${socialAccount.name}`;
      }

      this.addSocialAction(accountIndex, {
        key: option.provider,
        label: defaultLabel || null,
        value: option.value || null,
        disclaimer: option.disclaimer || null,
        points: option.points || 5,
      });
    },
    handleAddBrandClick() {
      this.addSocialAccount({
        key: generateHash(),
        actions: [],
        additionalInfo: {},
      });
    },
    handleDeleteActionClick(accountIndex, actionIndex) {
      this.deleteSocialAction(accountIndex, actionIndex);
    },
    handleSocialAccountDiscardClick(accountIndex) {
      this.deleteSocialAccount(accountIndex);
    },
    fetchSocialAccounts() {
      this['brand/FETCH_ALL_BRANDS']();
      // If there are no social accounts and an open one
      if (!this.editCampaign?.socialActions?.socialAccounts) {
        this.handleAddBrandClick();
      }
    },
    fetchConnectChannels() {
      if (!this.editCampaign?.socialActions?.connects && !!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            connects: {
              spotify: true,
              twitter: true,
            },
          },
        });
      }

      this.connectChannels = [
        {
          name: 'Twitter',
          key: 'twitter',
          iconName: 'twitter',
          iconColor: this.$arStyle.color.twitter,
          selected: this.editCampaign?.socialActions?.connects?.twitter != null ? this.editCampaign.socialActions.connects.twitter : true, // default true
        },
        {
          name: 'Spotify',
          key: 'spotify',
          iconName: 'spotify',
          iconColor: this.$arStyle.color.spotify,
          selected: this.editCampaign?.socialActions?.connects?.spotify != null ? this.editCampaign.socialActions.connects.spotify : true, // default true
        },
      ]
    },
    handleFacebookMessengerActionInput(accountIndex, actionIndex, facebookPageId) {
      this.patchSocialAction(accountIndex, actionIndex, {
        value: facebookPageId,
      });
    },
    handleSocialActionInput(value, accountIndex, actionIndex) {
      // Debounce patch social action on input because it will run on every letter
      this.debouncePatchSocialAction(accountIndex, actionIndex, {
        value,
      });

      this.veeErrors.remove(`account-${accountIndex}.${accountIndex}-${this.followOptionsObject[this.editCampaign.socialActions.socialAccounts[accountIndex].actions[actionIndex].key].vValidateRule}-${actionIndex}`)
    },  
    handleSocialActionClear(accountIndex, actionIndex) {
      this.patchSocialAction(accountIndex, actionIndex, {
        value: null,
      });
    },
    getActionsFromBrands(brand) {
      let actions = brand.actions || []
      let version = brand.version || 2;

      // brand.actions is not defined or empty, so we should check out brand.accounts instead
      // brand.accounts is where brands store the action keys for the old structure
      if (!brand.actions || brand.actions.length === 0) {
        version = 2;

        if (!!brand.accounts && brand.accounts.length != {}) {
          const newAccountKeys = Object.keys(brand.accounts)

          newAccountKeys.forEach((accountKey) => {
            if (
              accountKey != "name" && accountKey != "key" && // Action Identifiers
              accountKey != "additionalInfo" && // Contains Privacy Policy
              accountKey != "save" && // Saving a brand is now manual, so we no longer support this
              accountKey != "facebook:messenger" && accountKey != "snapchat" // Actions no longer supported
            ) {
              let value = brand.accounts[accountKey]
              let points = DEFAULT_POINTS

              if (accountKey === 'sms' || accountKey === 'email') {
                // If value is actually set to disable, we should skip adding this action.
                if (value === 'disable') {
                  return;
                }

                if (value === 'enable') {
                  value = null
                }
                points = SMS_EMAIL_POINTS
              }

              actions.push({
                key: accountKey,
                value,
                label: null,
                disclaimer: null,
                points,
              })
            }
          })
        }
      } else {
        actions = brand.actions
      }

      return {
        actions,
        version
      }
    },
    handleBrandSelect(brand, accountIndex) {
      const {
        actions,
        version
      } = this.getActionsFromBrands(clone(brand))

      this.patchSocialAccount(accountIndex, {
        version,
        additionalInfo: brand.accounts?.additionalInfo || brand.additionalInfo || null,
        actions: actions,
        name: brand.name,
      });

      this.veeErrors.remove(`accountName-${accountIndex}`)
    },
    async handleSaveAccountName(string, accountIndex) {
      // Make sure that the account name is different to the previous one when patching
      if (!!this.editCampaign?.socialActions?.socialAccounts && this.editCampaign?.socialActions?.socialAccounts[accountIndex].name != string) {
        this.patchSocialAccount(accountIndex, {
          name: string,
        });

        this.veeErrors.remove(`accountName-${accountIndex}`)
      }
    },
    async handleBrandSave(brand, accountIndex) {
      const isValid = await this.$validator.validate(`account-${accountIndex}.*`)
      if (!isValid) {
        this.$arNotification.push({ type: 'error', message: 'Check the errors first before saving into brand' });
        return;
      }

      const {
        actions,
        additionalInfo,
      } = this.editCampaign.socialActions.socialAccounts[accountIndex];

      this.handleAccountSave(brand.oid, brand.name, {
        actions: actions.map(this.extractSocialActionForServer),
        additionalInfo,
      });
    },
    handleBrandDelete(brand) {
      this.deleteBrand(brand.oid, brand.name);
    },
    deleteBrand(brandOid, brandName) {
      this['brand/DELETE_BRAND']({ oid: brandOid, name: brandName });
    },
    async handleAccountSave(brandOid, brandName, { actions, additionalInfo }) {
      this['brand/PATCH_BRAND']({
        oid: brandOid,
        name: brandName,
        changes: {
          actions,
          additionalInfo,
        },
      });
    },
    setEditPrivacyPolicyData(accountIndex, companyName, url) {
      this.editPrivacyPolicyAccountIndex = accountIndex;
      this.editPrivacyPolicyDefaultCompanyName = companyName;
      this.editPrivacyPolicyDefaultUrl = url;
    },
    handleEditPrivacyPolicyClick(accountIndex) {
      const account = this.editCampaign.socialActions.socialAccounts[accountIndex];
      this.setEditPrivacyPolicyData(
        accountIndex,
        account.additionalInfo?.privacyPolicy?.companyName || account.name || '',
        account.additionalInfo?.privacyPolicy?.url || '',
      );
      this.displayEditPrivacyPolicyModal = true;
    },
    handleEditPrivacyPolicyConfirm(companyName, url) {
      const newAdditionalInfo = clone(this.editCampaign.socialActions.socialAccounts[this.editPrivacyPolicyAccountIndex].additionalInfo || {});
      if (!companyName && !url) {
        delete newAdditionalInfo.privacyPolicy;
      } else {
        newAdditionalInfo.privacyPolicy = { companyName, url };
      }
      this.patchSocialAccount(this.editPrivacyPolicyAccountIndex, {
        additionalInfo: newAdditionalInfo,
      })
      this.setEditPrivacyPolicyData(null, null, null);
      this.displayEditPrivacyPolicyModal = false;
    },
    handleEditPrivacyPolicyCancel() {
      this.setEditPrivacyPolicyData(null, null, null);
      this.displayEditPrivacyPolicyModal = false;
    },
    handleMove(event, accountIndex) {
      const {
        newIndex,
        oldIndex
      } = event.moved;
      
      const newSocialAccounts = clone(this.editCampaign.socialActions.socialAccounts);
      const tempElem = newSocialAccounts[accountIndex][newIndex];
      newSocialAccounts[accountIndex][newIndex] = newSocialAccounts[accountIndex][oldIndex];
      newSocialAccounts[accountIndex][newIndex] = tempElem;

      if (!!this.setEditCampaign) {
        this.setEditCampaign({
          socialActions: {
            socialAccounts: newSocialAccounts,
          },
        });
      }
    },

    handleSettingOptionSelect(item, accountIndex, actionIndex) {
      item.action(accountIndex, actionIndex);
    },

    showSettingsModal(accountIndex, actionIndex) {
      if (!this.editCampaign?.socialActions?.socialAccounts || !this.editCampaign?.socialActions?.socialAccounts[accountIndex] ||
          !this.editCampaign?.socialActions?.socialAccounts[accountIndex].actions  || !this.editCampaign?.socialActions?.socialAccounts[accountIndex].actions[actionIndex] ||
          accountIndex == null || accountIndex == undefined || actionIndex == null || accountIndex == undefined) {
        return;
      }

      const socialAccount = this.editCampaign.socialActions.socialAccounts[accountIndex];

      const {
        key,
        label,
        points,
        disclaimer,
        value,
      } = socialAccount.actions[actionIndex];

      if (this.followOptionsObject) {
        const option = this.followOptionsObject[key];

        this.campaignSocialActionData = {
          title: option?.name,
          defaultLabel: option?.defaultLabel,
          label: label,
          points: points,
          disclaimer: disclaimer || null,
          value: value || null,
          key,
        }
        this.campaignSocialAccountIndex = accountIndex
        this.campaignSocialAccountActionIndex = actionIndex
        this.displayCampaignSocialActionSettingsModal = true
      }
    },
    handleSocialActionSettingsCancel() {
      this.campaignSocialAccountIndex = null
      this.campaignSocialAccountActionIndex = null
      this.displayCampaignSocialActionSettingsModal = false;
    },
    handleSocialActionSettingsConfirm(label, points, disclaimer = null, value = null) {
      this.patchSocialAction(this.campaignSocialAccountIndex, this.campaignSocialAccountActionIndex, {
        label,
        points,
        disclaimer,
        value,
      });
      this.displayCampaignSocialActionSettingsModal = false;
    },
    handleBrandCreateNew (name, { actions, additionalInfo }) {
      this['brand/CREATE_BRAND']({
        name,
        actions: actions.map(this.extractSocialActionForServer),
        additionalInfo,
      });
    },
    handleEmailMessageListChange (messageListOid, accountIndex, actionIndex) {
      this.patchSocialAction(accountIndex, actionIndex, {
        value: messageListOid,
      });
    },
    handleSmsMessageListChange (messageListOid, accountIndex, actionIndex) {
      this.patchSocialAction(accountIndex, actionIndex, {
        value: messageListOid,
      });
    },
    onDragStart() {
      setDragCursor(true)
    },
    onDragEnd() {
      setDragCursor(false)
    },

  },
};
</script>

<style lang="scss">
.campaign-edit-connect {
  .add-account {
    margin: 2rem auto 4rem;
    width: 100%;
  }

  .social-connect-channels {
    border-radius: 4px;
    padding: 1.25rem 2rem;
    border: 1px solid $blueGrey500;
  }
  .social-account__item {
    border-bottom: 1px solid $blueGrey500;
    cursor: default;
  }
  .social-account__item-inner {
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-height: 50px;

    .social-account__item-label {
      margin-left: 8px;
      width: 100px;
      cursor: default;
    }

    &:nth-child(3) {
      margin-top: 3px;
    }
    .input--block {
      width: calc(100% - 120px);
    }
    &:hover {
      background-color: #f6f9fc;
    }
    &:hover input {
      background-color: #f6f9fc;
    }
    .select {
      width: 100%;
      border: 1px solid rgba(white, 0);
      overflow:hidden;
      img {
        margin-left: 0;
      }
      &:hover {
        background-color: #f6f9fc;
      }
    }

    .input {
      border: 1px solid rgba($skyBlueGrey500, 0);
      cursor: pointer !important;
    }

    .connect-icon {
      margin-left: 10px;
    }

    &.social-account__item-inner-focus {
      background-color: white;

      .input {
        background-color: white;
      }
    }
  }

  .social-follow-channels {
    border-radius: 4px;
    padding: 1.75rem 2rem;
    border: 1px solid $blueGrey500;

    position: relative;

    .checkbox-section {
      align-self: baseline;
    }

    .social-action-link-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
    }
  }

  .remove-social-account {
    cursor: pointer;
    color: $blueGrey700;
  }

  .follow-account-label {
    .label-description {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;

      .heading-section {
        display: flex;
        flex-direction: row;
      }
    }

    .save-account-label {
      color: $blueGrey600;
    }
  }


  .add-account-icon {
    width: 20px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  .measure {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  .account-select {
    position: relative;
    ul.select__items {
      width: 100%;
    }
  }
  .button:not(.button--text).is-primary {
    max-width: 100%;
  }
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
