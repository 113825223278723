<template>
  <div
    class="timescale-dropdown-wrapper"
    :class="[!hasData ? 'disabled' : '']"
    v-click-outside="handleCloseTimescaleOptions"
    tabindex="0"
    @keydown.esc="handleCloseTimescaleOptions"
  >
    <div class="timescale-button" @click="toggleDropdown" data-test-id="timescale-toggle-button">
      <ar-text
        class="compare-events-text"
        size="xs"
        :text="selectedTimescaleOption.name"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ar-icon
        width="10px"
        name="arrow"
        class="icon-arrow"
        :rotate="timescaleOptionsOpen ? 180 : 0"
      />
    </div>

    <div v-if="timescaleOptionsOpen" class="timescale-dropdown-options-wrapper">
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>
      <template v-for="(option, index) in options">
        <div class="timescale-option-wrapper" :key="index" @click="handleTimescaleSelect(option)" :data-test-id="option.value">
          <ar-text
            class="timescale-option"
            :class="[
              isPlaceholderOption(option.name) ? 'hidden' : '',
              isSelectedTimescale(option.name) ? 'active' : ''
            ]"
            size="xs"
            :text="option.name"
            multiple-lines
            :max-lines="1"
            line-height="20px"
            :weight="isSelectedTimescale(option.name) ? 'bold' : 'normal'"
            :data-value="option.value"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TimescaleDropdown',
  props: {
    hasData: {
      type: Boolean,
      default: false,
    },
    selectedTimescaleOption: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  model: {
    prop: 'selectedTimescaleOption',
    event: 'selection',
  },
  data() {
    return {
      timescaleOptionsOpen: false,
    }
  },
  methods: {
    handleTimescaleSelect(option) {
      this.$emit('selection', option)
      this.timescaleOptionsOpen = false
    },
    toggleDropdown() {
      this.timescaleOptionsOpen = !this.timescaleOptionsOpen
    },
    handleCloseTimescaleOptions() {
      this.timescaleOptionsOpen = false
    },
    isPlaceholderOption(name) {
      return name === "Increments"
    },
    isSelectedTimescale(name) {
      return this.selectedTimescaleOption.name === name ? true : false
    },
  }
}
</script>
<style lang="scss" scoped>
$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: ui-px2em(1) solid $borderColor;
  border-radius: 5px; 
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}

.timescale-dropdown-wrapper {
  position: relative;
  outline: none;

  &.disabled {
    pointer-events: none;

    .timescale-button {
      .compare-events-text {
        color: $disabledColor;
      }

      .icon-arrow {
        color: $disabledColor;
      }
    }
  }

  .timescale-button {
    @include flexRow;
    border: ui-px2em(1) solid $borderColor;
    border-radius: 4px;
    padding: ui-px2em(8) ui-px2em(14);
    background: white;
    margin-right: 7px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 128px;

    .compare-events-text {
      margin-right: 5px;
    }

    .icon-arrow {
      margin-top: 2px;
    }
  }

  .timescale-dropdown-options-wrapper {
    @include dropdownOptionsStyle;
    width: 130px;
    position: absolute;
    top: 50px;
    right: 8px;
    z-index: 20;
    padding: ui-px2em(6) 0;

    .triangle {
      position: absolute;
      width: 10px;
      top: -5px;
      right: 12px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid #d8dde3;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid white;
        transform: translateY(10%);
      }
    }

    .timescale-option-wrapper {
      width: 100%; 
      cursor: pointer;

      .timescale-option {
        padding: ui-px2em(10) ui-px2em(19);

        &:hover {
          background: $purple100;
          color: $purple500;
        }

        &.hidden {
          display: none;
        }

        &.active {
          background: $purple100;
          color: $purple500;
        }
      }
    }
  }
}
</style>