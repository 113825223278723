var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"date-range-dropdown-wrapper"},[_c('div',{staticClass:"dropdown-container"},[_c('div',{class:[
        'dropdown-button-wrapper',
        _vm.dropdownOpen && 'active' ],attrs:{"data-test-id":(_vm.testingId + "-button")},on:{"click":_vm.handleButtonClick}},[_c('ar-text',{staticClass:"dropdown-button",attrs:{"size":"xs","multiple-lines":"","text":_vm.selectedFilter.name,"weight":"normal"}}),_vm._v(" "),_c('ArrowHead',{class:[
          'dropdown-arrowhead',
          _vm.dropdownOpen && 'rotate' ],attrs:{"fill":"#8e97a6","width":"10px","height":"10px"}})],1),_vm._v(" "),(_vm.dropdownOpen)?_c('div',{staticClass:"dropdown-options-wrapper",style:({width: _vm.calendarOpen ? '330px' : '140px'})},[_vm._l((_vm.filterOptions),function(option){return [_c('div',{key:option.value,staticClass:"option-wrapper"},[_c('ar-text',{class:[
              'filter-option',
              option.value === _vm.selectedFilter.value && 'selected' ],attrs:{"data-test-id":(_vm.testingId + "-option"),"size":"xs","multiple-lines":"","text":option.name,"weight":option.value === _vm.selectedFilter.value ? 'bold' : 'normal'},nativeOn:{"click":function($event){return _vm.handleOptionClick(option)}}}),_vm._v(" "),(option.value === 'range' && _vm.calendarOpen)?_c('div',{staticClass:"filter-calendar-wrapper"},[_c('DatePicker',{staticClass:"date-picker",attrs:{"data-test-id":"`events-list-date-filter","filter-criteria":_vm.filterMap,"filter-condition":_vm.filterCondition,"hide-button":true,"disable-read-mode":true},on:{"apply":_vm.applyCondition,"delete":_vm.deleteCondition}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"filter-button",style:({
                height: '40px',
              }),attrs:{"text":"Filter","outlined":"","data-test-id":(_vm.testingId + "-filter-button"),"disabled":_vm.filterDisabled},on:{"click":_vm.handleFilter}})],1):_vm._e()],1)]})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }