<template>
  <div class="zoom-chart-wrapper">
    <div class="zoom-chart-nav-wrapper">
      <div  class="registrations-heading-wrapper">
        <ar-text
          class="compare-events-text"
          size="md"
          text="Zoom Attendance"
        />
      </div>
    </div>

    <div class="zoom-chart-container">
      <div class="apex-zoom-chart-wrapper active">
        <am2-apex-area-chart
          class="apex-chart"
          chart-id="registrationsChart"
          :options="chartOptions"
          :series="series"
          :zoom="zoom"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
  name: 'ZoomAttendanceChartSection',
  data() {
    return {
      chartOptions: null,
      campaignSeries: [],
      zoom: null,
    }
  },
  mounted () {
    this.setCustomChartOptions()
  }, 
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      eventTicketSales: state => state.event.eventTicketSales,
      audience: state => state.audience.audience,
    }),

    noData() {
      return false;
    },
    series() {
      const campaignSeriesData = this.campaignSeries.sort( (a, b) => {
        if(a[0] < b[0]) return -1;
        if(b[0] < a[0]) return 1;
        return 0;
      });

      return [
        {
          data: campaignSeriesData,
          name: "Zoom Attendance"
        }
      ]
    }
  },

  watch: {
    currentEvent: {
      handler(event) {
        if (event) {
           
          this.fetchCampaignData()
        }
      },
      immediate: true,
    },
    audience: {
      handler(audience) {
        if (audience) {
          audience.forEach((entry) => {
            const existingDateIndex = this.findExistingDate(entry.attendedAt);
            if (existingDateIndex > -1) {
              this.campaignSeries[existingDateIndex][1] += 1;
            } else {
              this.campaignSeries.push([entry.attendedAt, 1])
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setInitialChartData: 'event/SET_INITIAL_TICKET_SALES_CHART_DATA',
      setSalesChartDataLoading: 'event/SET_SALES_CHART_DATA_LOADING',
      fetchZoomAttendance: 'audience/FETCH_ZOOM_ATTENDANCE',
    }),

    findExistingDate(date) {
      const isFound = this.campaignSeries.findIndex((value) => {
        return value[0] == date
      })

      return isFound
    },

    setCustomChartOptions() {
      let options = {
        yaxis: {
          labels: {
            formatter: (val) => {
              return val;
            },
          }
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex, w}) => {
            let toolTipData = w.config.series[seriesIndex].data[dataPointIndex]
            let dataDate = moment(toolTipData[0][0]).format('MMMM YYYY hh:mm a')

            return `
              <div class="sales-tooltip-wrapper">
                <span class="tooltip-value-wrapper">
                  <span class="tooltip-value">${toolTipData[1]}</span>
                </span>
                <span class="tooltip-date">Attendees</span>
                <span class="tooltip-tab">${dataDate}</span>
              </div>
            `
          }
        },
        noData: {
          text: "No sales data available for this event.",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: this.$arStyle.color.$blueGrey800,
            fontSize: '14px',
            fontFamily: 'Graphik'
          }
        }
      }

      this.chartOptions = options
    },

    async fetchCampaignData() {
      try {
        await this.fetchZoomAttendance({
          eventOid: this.$route.params.oid,
          selectKeys: this.fanAttributeKeys,
          orderBy: this.orderBy,
          reload: true,
        });
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch campaign registrations',
        });
      }     
    },
  },
}
</script>
<style lang="scss">

$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: 1px solid $borderColor;
  border-radius: 5px; 
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}


.zoom-chart-wrapper {
  grid-column: 1 / span 2;
  background: white;
  border: 1px solid $skyBlueGrey400;
  box-shadow: $blueGrey400 2px 5px 10px;
  border-radius: 4px;
  padding: 30px;

  .zoom-chart-nav-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .registrations-heading-wrapper {
      cursor: pointer;
      position: relative;
      outline: none;

    }
  }

  .zoom-chart-container {
    position: relative;

    .zoom-chart-loading-wrapper {
      position: absolute;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      z-index: 99;
    }

    .apex-zoom-chart-wrapper {
      opacity: 0;
      height: 0px;

      &.active {
        opacity: 1;
        height: 380px;
      }

      .apex-chart {
        height: 100%;
      }
    }
  }  

  .sales-tooltip-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 180px;
    background: white;
    padding: 12px 15px 18px;
    box-sizing: border-box;
    font-family: 'Graphik';
    font-weight: 400;
    color: $blueGrey800;

    .tooltip-date {
      font-size: 14px;
      margin-bottom: 0;
    }

    .tooltip-tab {
      font-size: 14px;
      color: $blueGrey700;
      margin-top: -5px;
    }

    .tooltip-value-wrapper {
      margin-bottom: 10px;

      .tooltip-value {
        font-size: 24px;
      }

      .tooltip-cents {
        font-size: 14px;
      }
    }        
  }
}
</style>