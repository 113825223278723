var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreCampaigns),expression:"loadMoreCampaigns"}],staticClass:"customer-profile-campaigns",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":10}},[_c('div',{class:[
      'campaigns-controls',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
    ]},[_c('am2-heading',{staticClass:"section-heading",attrs:{"type":"h1","size":"md","title":"Campaigns"}}),_vm._v(" "),_c('div',{class:[
      'campaigns-controls__rhs',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
    ]},[_c('am2-search',{staticClass:"search-input u-margin-right-4",attrs:{"placeholder":"Search campaigns"},on:{"enter":_vm.serverSideSearch,"clear":_vm.serverSideSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('sm'))?_c('am2-button-group-tabs',{style:({
            width: '100px',
          }),attrs:{"active-index":_vm.layout === 'list' ? 1 : 0,"items":_vm.groupButtonsSelectItems},on:{"select":_vm.handleGroupButtonSelectClick}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
      show: !_vm.isCustomerProfileFeatureEnabled,
      showUpgradeOn: 'hover',
      iconName: 'audience',
      title: _vm.lockedTitle.campaigns,
      message: 'Unlock customer profiles to see previous campaigns fans have participated in.',
    }),expression:"{\n      show: !isCustomerProfileFeatureEnabled,\n      showUpgradeOn: 'hover',\n      iconName: 'audience',\n      title: lockedTitle.campaigns,\n      message: 'Unlock customer profiles to see previous campaigns fans have participated in.',\n    }"}],class:[
      _vm.computedLayout,
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max'
    ],style:({
      borderRadius: !_vm.isCustomerProfileFeatureEnabled ? '5px' : null,
      overflow: !_vm.isCustomerProfileFeatureEnabled ? 'hidden' : null,
      filter: _vm.isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
      padding: _vm.isCustomerProfileFeatureEnabled ? 0 : '20px 20px 6px 20px',
    })},[_vm._l((_vm.computedCampaigns),function(campaign){return _c('am2-campaign-card',{key:campaign.oid,attrs:{"show-as":_vm.computedLayout,"card-size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs',"campaign":campaign,"points":_vm.campaignPoints(campaign.oid),"referrals":_vm.referrals(campaign.oid),"layout":"customer"}})}),_vm._v(" "),(_vm.customer && _vm.computedCampaigns.length === 0 && !_vm.isFetchingCampaigns)?_c('div',{staticClass:"ar-transition-group-item u-width-100-percent"},[(_vm.searchQuery)?_c('am2-no-content-section',{attrs:{"header":"No campaigns found","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"header":((_vm.customerState.customer ? _vm.customerState.customer.name : '') + " has not participated in any campaigns"),"header-size":"sm"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.isFetchingCampaigns ? 3 : 0),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card",style:({
          height: _vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.layout === 'tiles' ? '370px' : '165px',
          marginBottom: '10px',
        }),attrs:{"type":"campaign","show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.layout : 'tiles',"level":_vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.layout === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }