<template>
  <div class="loyalty-program-constraint">
    <ar-simple-select
      :items="selectItems"
      :default-select-index="selectedItemIndex"
      :placeholder="`Select membership...`"
      class="select"
      @select="handleSelectClick"
      data-test-id="select-membership"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { capitalizeFirstLetter } from '@/utils/helpers';

  export default {
    name: 'LoyaltyProgramConstraint',

    props: {
      constraintCondition: {
        type: String,
        default: 'all',
      },
      filterConditionName: {
        type: String,
        default: null,
      },
      constraintDefaultOptions: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        chosenItemId: null,
        isFetchingItems: false,
      }
    },

    created() {
      this.fetchCandidateItems();
    },

    computed: {
      ...mapState({
        fanLoyaltyPrograms: state => state.customer.fanLoyaltyPrograms,
      }),

      selectedItemIndex() {
        return this.selectItems.findIndex( item => {
          return item.value === this.chosenItemId;
        })
      },

      selectItems() {
        const defaultOptions = this.constraintDefaultOptions.map( item => {
          return {
            value: item.value,
            name: item.title,
          }
        });
        return [...defaultOptions,
          ...this.candidateItems.map( item => {
            const niceProvider = item.provider || "Integration";
            const itemName =
              item?.promoterIntegration?.integration?.user?.attributes?.fullName ||
              item?.promoterIntegration?.integration?.user?.attributes?.email ||
              item.name || item.puid || item.oid || "";
            return {
              value: item.oid,
              name: capitalizeFirstLetter(niceProvider),
              subTitle: itemName,
            }
          })
        ];
      },

      candidateItems () {
        if (this.filterConditionName === "totalLoyaltySales") {
          return this.fanLoyaltyPrograms && this.fanLoyaltyPrograms.filter(item => item.provider !== "memberful");
        } else {
          return this.fanLoyaltyPrograms
        }
      },
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_LOYALTY_PROGRAMS',
      ]),

      fetchCandidateItems() {
        this['customer/FETCH_CUSTOMER_LOYALTY_PROGRAMS']();
      },

      handleSelectClick(item) {
        this.chosenItemId = item.value;
        const data = {
          key: 'true_to_all',
          values: [item.value],
        };
        this.$emit('constraintUpdate', data);
      }
    }

  };
</script>

<style lang="scss" scoped>
  .loyalty-program-constraint {
    .select {
      height: 40px;
    }
  }
</style>
