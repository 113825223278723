<template>
  <ar-select
    class="ar-language-select"
    :items="items"
    :defaultSelectIndex="selectedIndex"
    placeholder="Select language"
    :hide-arrow="false"
    :enable-filter="true"
    @select="handleSelect"
    @clear="$emit('clear')"
  >
    <template slot="selected-item" slot-scope="selectedItemScope">
      <div
        v-if="selectedItemScope.item"
        :style="{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          padding: '0 14px',
        }"
      >
        <ar-flag-icon
          :name="selectedItemScope.item.countryIso"
          :style="{
            marginRight: '10px'
          }"
        />
        <ar-text
          size="sm"
          :text="selectedItemScope.item.name"
        />
      </div>
      <div
        v-else
        :style="{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }"
      >
        <ar-text
          size="xs"
          text="Select language"
        />
      </div>
    </template>
    <template slot="item" slot-scope="itemScope">
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          padding: '12px 15px',
          cursor: 'pointer',
          background: itemScope.active || itemScope.selected ? $arStyle.color.purple100 : null,
        }"
      >
        <ar-flag-icon
          :name="itemScope.item.countryIso"
          :style="{
            marginRight: '10px'
          }"
        />
        <ar-text
          size="xs"
          :text="itemScope.item.name"
          :weight="itemScope.selected ? 'bold' : 'normal'"
        />
      </div>
    </template>
    <template slot="no-item">
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          padding: '12px 15px',
        }"
      >
        <span
          :style="{
            fontSize: '14px',
            lineHeight: '1',
            color: $arStyle.color.$blueGrey800,
          }"
        >No data</span>
      </div>
    </template>
  </ar-select>
</template>

<script>
import { languageList } from "@/utils/languages";

export default {
  name: 'LanguageSelect',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      realValue: null,
      items: languageList,
    };
  },
  computed: {
    selectedIndex() {
      return this.items.findIndex(({ iso }) => iso === this.realValue);
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.realValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleSelect(item) {
      this.$emit('input', item.iso);
      this.$emit('select', item);
    },
    handleClear() {
      this.$emit('input', null);
      this.$emit('select', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-language-select {
  width: 100%;
}
</style>
