<template>
  <div class="campaign-setup-event-page">
    <ar-text
      class="step-heading"
      size="xs"
      text="SETUP"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Select or create an event"
      :style="{marginBottom: '38.5px'}"
    />
    <am2-list-view
      :max-options="10"
      :initial-selected="selected"
      :fetch-data-adapter="fetchAdapter"
      :on-selected="selectEvent"
      :search-settings="{
        placeholder: 'Search your events',
        button: {
          text: 'Create new event',
          click: goToNewEvent,
        }
      }">
      <div class="info" slot="template" slot-scope="option">
        <img
          class="image"
          height="50"
          width="70"
          :src="option.imageSrc">
        <div class="label">
          <p class="title">
            {{ option.name }}
          </p>
          <!-- <span v-if="option.paymentInfo && option.paymentInfo.type==='premium'"
            class="premium">Premium</span> -->
          <p class="subtitle">
            {{option.subTitle}}
          </p>
        </div>
      </div>
    </am2-list-view>
    <am2-create-event-modal
      :is-open="displayCreateEventModal"
      @cancel="displayCreateEventModal = false"
      :type="query.type"
      :campaign-type="subscriptionLevel"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'CampaignSetupEvent',

  data: () => ({
    selected: [],
    displayCreateEventModal: false,
  }),

  props: {
    subscriptionLevel: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState({
      query: state => state.route.query,
      events: state => state.event.events,
    }),

    paymentType() {
      return this.option.paymentInfo && this.option.paymentInfo.type;
    },
  },

  created() {
    if (!this.query.type) {
      this.$router.push({
        path: `/campaigns/setup/type`,
      });
      return;
    }
    if (this.query.eventOid) {
      this.selected = [parseInt(this.query.eventOid)]
    }
  },

  beforeDestroy() {
    this['event/RESET_EVENTS']();
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
    ]),
    ...mapMutations([
      'event/RESET_EVENTS',
    ]),

    async fetchAdapter() {
      await this['event/FETCH_MORE_EVENTS']({
        top: 'all',
        reload: true,
      });

      return this.events.map(event => {
        event.subTitle = this.datePeriod(event) + (event.location ? ' | ' + event.location : '')
        event.imageSrc = this.eventImageSrc(event)
        return event;
      });
    },

    selectEvent(selectedEvents) {
      this.$emit('change', { eventOid: selectedEvents[0] });
    },

    datePeriod(event) {
      let startDate = moment(event.startDate).format('MMM DD, YYYY');
      const endDate = moment(event.endDate).format('MMM DD, YYYY');

      if (startDate === endDate || event.endDate === null) {
        return `${startDate}`;
      } else {
        if (startDate.substring(7) === endDate.substring(7)){
          startDate = startDate.substring(0, 6);
        }

        return `${startDate} - ${endDate}`;
      }
    },

    eventImageSrc(event) {
      const eventImage = event.resources.find(resource => resource.assetType === 'event-image');

      return eventImage ? eventImage.url : '/images/placeholders/image-default.png';
    },

    goToNewEvent() {
      this.displayCreateEventModal = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.campaign-setup-event-page {
  .premium {
    background-color: #d5f3e1;
    color: #3CB36C;
    font-weight: bold;
    padding: 5px;
    margin-left: 10px;
    border-radius: 2px;
  }
  .title {
    display: inline;
  }
}
</style>
