var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"plan-purchase-card",style:({
    border: 'none',
  })},[(_vm.isFetchingCurrentPaymentPlan || _vm.isFetchingPromoterAudienceCount)?_c('am2-loading-section',{staticClass:"loading-section"}):_c('div',{staticClass:"content-section"},[_c('div',{staticClass:"title-section"},[_c('ar-text',{attrs:{"size":"md","text":_vm.planName,"align":"center","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"title-section-subtitle-section"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.fromPriceCopy}}),_vm._v(" "),_c('ar-text',{style:({
            marginLeft: '4px',
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":_vm.changePlanHtml,"allow-html":""},on:{"anchorClick":_vm.handleChangePlanClick}})],1)],1),_vm._v(" "),_c('ar-divider',{staticClass:"first-divider"}),_vm._v(" "),_c('div',{staticClass:"contacts-section"},[_c('div',{staticClass:"contacts-question-section"},[_c('ar-text',{attrs:{"size":"xs","text":"How many contacts do you have?"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: "You will be charged based on the number of contacts you have"
          }),expression:"{\n            content: `You will be charged based on the number of contacts you have`\n          }",modifiers:{"top":true}}],style:({
            marginLeft: '10px',
          }),attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.skyBlueGrey600,"width":"16px"}})],1),_vm._v(" "),_c('am2-slider-input',{style:({
          marginTop: '10px',
        }),attrs:{"min":0,"max":_vm.planTiers.length - 1,"track-color":"purple"},model:{value:(_vm.tierIndex),callback:function ($$v) {_vm.tierIndex=$$v},expression:"tierIndex"}}),_vm._v(" "),_c('div',{staticClass:"contacts-number-display-section"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.contactsNumberCopy}}),_vm._v(" "),_c('div',{style:({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '99px',
            height: '30px',
            borderLeft: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
          })},[_c('ar-text',{attrs:{"size":"xs","text":"Contacts"}})],1)],1)],1),_vm._v(" "),_c('ar-divider',{staticClass:"second-divider"}),_vm._v(" "),_c('div',{staticClass:"purchase-section"},[_c('div',{staticClass:"money-to-pay-section"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.purple500,
          }),attrs:{"size":"lg","text":_vm.finalPriceCopy}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":_vm.finalPriceUnitCopy}})],1),_vm._v(" "),(_vm.displayErrorMessage)?_c('div',{staticClass:"u-margin-top-5"},[(_vm.isTierOverLimitation)?_c('ar-text',{attrs:{"size":"xs","text":_vm.overLimitationCopy,"allow-html":"","multiple-lines":"","align":"center"}}):(_vm.isTierUnderLimitation)?_c('ar-text',{staticClass:"u-margin-top-2",attrs:{"size":"xs","text":_vm.underLimitationCopy,"allow-html":"","multiple-lines":"","align":"center"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.displayContactSalesButton)?_c('ar-simple-button',{staticClass:"u-margin-top-8 u-width-100-percent",attrs:{"type":"gradient-purple","text":"Contact Sales"},on:{"click":_vm.handleContactSalesClick}}):_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.purchasePlanButtonDisabled ? "You must add a credit card to Purchase Plan" : null,
          hideOnTargetClick: false,
        }),expression:"{\n          content: purchasePlanButtonDisabled ? `You must add a credit card to Purchase Plan` : null,\n          hideOnTargetClick: false,\n        }",modifiers:{"top":true}}],staticClass:"u-margin-top-8 u-width-100-percent",attrs:{"type":"gradient-purple","text":_vm.paymentSubscription ? 'Update plan' : 'Purchase Plan',"loading":_vm.isCreatingPaymentSubscription,"disabled":_vm.purchasePlanButtonDisabled},on:{"click":_vm.handlePurchasePlanClick}}),_vm._v(" "),_c('am2-payment-source-section',{staticClass:"u-margin-top-5",attrs:{"payment-source-puid":_vm.selectedPaymentSourcePuid,"loading":!_vm.isPaymentSourceSectionReady},on:{"select":_vm.handlePaymentMethodSelect}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }