<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Setup the basics"
    />
    <ar-field
      label="To"
      :style="{
        marginTop: '40px',
      }"
    >
      <div
      class="u-display-flex u-align-items-center u-justify-content-space-between">
        <am2-message-list-select
          :class="[
            'message-list-recipients-dropdown',
            'u-flex-grow-1',
            showFilterButton && 'filter-enabled'
          ]"
          :channels="['email']"
          :value="scratchEmailMessage.meta.messageListOid"
          :campaign-oid="scratchEmailMessageCampaignOid"
          :event-oid="scratchEmailMessageEventOid"
          @select="handleMessageListChange"
          data-test-id="message-list-select"
        />


        <div class="circle-hooker item"
          v-if="showFilterButton"
        >
          <div v-if="filterIsNotEmpty && !!filterCount && filterCount > 0" class="circle">
            <span>{{ filterCount }}</span>
          </div>
          <ar-simple-button
            icon-name="filter-alt"
            side-length="40px"
            :icon-props="{
              height: '16px',
            }"
            text="Filter"
            :type="filterIsNotEmpty ? 'purple' : 'grey'"
            outlined
            class="filter-btn u-margin-left-3"
            @click="switchFilterSidebar"
            data-test-id="audience-table-filter-button"
            :disabled="!this.scratchEmailMessage.meta.messageListOid"
          />
        </div>
      </div>
      <div>
        <ar-snackbar
          v-if="currentMessageListHasNoEmailContacts"
          class="u-width-100-percent"
          type="error"
          message="There are no valid email addresses in this list."
          :style="{ marginTop: '10px' }"
        />
        <ar-snackbar
          v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
          class="u-margin-top-5 u-width-100-percent"
          type="warning"
          message="Changing from all registrants to a tier will disable scheduling."
        />
        <ar-state-message
          v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
          class="u-margin-top-5"
          :text-props="{
            weight: 'bold',
          }"
          has-icon
          disable-text-color
          type="information"
          text="Note: It's recommended to only message individual tiers once the campaign is ended."
        />

      </div>
    </ar-field>

    <am2-message-list-advanced-targeting-section
      v-if="showAdvancedTargetingModule"
      channel="email"
      show-toggle
      @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
    />

    <div class="recipients-count u-margin-top-4 u-padding-left-4"
      v-if="areFiltersNotEmpty"
    >
      <am2-loading-spinner
        v-if="isFetchingFilteredRecipientListCount && currentAdvancedTargetingIsValid"
        :style="{
          width: '1.5em',
          height: '1.5em'
        }"
      />
      <div
        class="recipients-count"
        v-else>
        <ar-icon
          class="sales-chart-settings-icon settings-button u-margin-right-3"
          name="team"
          width="16px"
          :color="$arStyle.color.blueGrey700"
        />
        <ar-text
          class="recipients-text"
          size="xs"
          data-test-id="message-targeting-recipient-count"
          :text="recipientCountText"
        />
        <div class="u-margin-left-2">·</div>
        <ar-link-button
          class="u-margin-left-2"
          text="Clear all"
          has-underline
          @click="handleClearAllFilters"
          data-test-id="message-list-clear-all-filters"
        />
      </div>
    </div>
    <ar-field
      label="Message name"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-input
        autocomplete="message-name"
        :value="scratchEmailMessage.customerName"
        @input="handleCustomerNameInput"
        data-test-id="message-name-input"
      />
    </ar-field>

    <ar-field
      label="From"
      :style="{
        marginTop: '40px',
      }"
    >
      <div>
        <am2-message-sender-select
          ref="messageSenderSelect"
          :value="scratchEmailMessage.meta.email && scratchEmailMessage.meta.email.promoterPropertyOid"
          @select="handleSenderSelect"
          @clear="handleMessageSenderClear"
        />
      </div>
      <ar-snackbar
        v-if="currentMessageSenderNotVerified"
        class="u-width-100-percent"
        type="error"
        message="Please verify this email address. <a>Resend verification email</a>."
        :style="{ marginTop: '10px' }"
        @anchorClick="handleResendVerificationEmailAnchorClick"
      />
    </ar-field>
    <ar-field
      label="Subject"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-input
        :value="scratchEmailMessage.meta.messageBody.subject"
        @input="handleSubjectInput"
        data-test-id="message-subject"
      />
    </ar-field>
    <ar-field
      label="Preview line"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-input
        :value="scratchEmailMessage.meta.messageBody.previewText"
        @input="handlePreviewTextInput"
        autocomplete="preview-line"
        data-test-id="message-preview-line"
      />
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';

import { clone } from '@/utils/helpers/';

export default {
  name: 'Basic',

  data() {
    return {
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
    }
  },

  watch: {
    prunedScratchSegment(newVal, oldVal) {
      if (!this.isFetchingFilteredRecipientListCount) {
        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
          channel: 'email',
        });
      }
    },
  },

  computed: {
    ...mapState({
      messageOid: (state) => parseInt(state.route.params.oid, 10),
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      useScratchSegment: state => state.audience.audienceSelection.useScratchSegment,
      isFetchingFilteredRecipientListCount: state => state.messageList.isFetchingFilteredRecipientListCount,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      filteredRecipientListCountAccuracy: state => state.messageList.filteredRecipientListCountAccuracy,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
    }),
    ...mapGetters({
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      getSegmentConditionExpression: 'segment/getSegmentConditionExpression',
      partlySelectedAudience: 'audience/partlySelectedAudience',
      currentAdvancedTargetingIsValid: 'messageList/currentAdvancedTargetingIsValid',
    }),

    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.scratchEmailMessageCampaignOid) return false;
      if (this.scratchEmailMessageEventOid) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (!this.scratchEmailMessage) return false;
      if (!this.scratchEmailMessage.meta.messageListOid) return false;
      return true;
    },

    showFilterButton() {
      if (this.scratchEmailMessageCampaignOid) return false;
      if (this.scratchEmailMessageEventOid) return false;
      return true;
    },

    filterIsNotEmpty() {
      return this.prunedScratchSegment && this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    recipientCountText() {
      if (this.currentMessageList && Object.keys(this.currentMessageList.statsSnapshot).length === 0 ) {
        return "Calculating recipients";
      }
      if (this.recipientsCount === 1) return "1 recipient";
      if (this.recipientsCount === 0) return "No matching recipients";
      const estText = this.filteredRecipientListCountAccuracy === 'estimate' ? ' estimated ' : ' ';
      const filterText = this.recipientsCount > 0 ? ` will receive based on your filters ` : '';

      return `${accounting.format(this.recipientsCount)}${estText}recipients${filterText}`
    },

    // The number of recipients in the message list, after advanced targeting has been applied
    recipientsCount() {
      return this.filteredRecipientListCount || 0;
    },

    areFiltersNotEmpty() {
      return this.filterIsNotEmpty || !(!!this.advancedMessageListTargeting.condition && this.advancedMessageListTargeting.type == "all")
    },

    filterCount () {
      if (!this.scratchEmailMessage || !this.scratchEmailMessage.meta) {
        return null;
      }

      return this.prunedScratchSegment.filter && !!this.prunedScratchSegment.filter.conditions ? this.prunedScratchSegment.filter.conditions.length : 0
    }
  },

  methods: {
    ...mapActions([
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'segment/FETCH_SEGMENTS',
    ]),
    ...mapMutations([
      'messageSender/SET_CURRENT_MESSAGE_SENDER',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE',
      'message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE',
      'layout/TOGGLE_SEGMENT_DRAWER',
      'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
      'messageList/SET_FILTERED_RECIPIENT_LIST_COUNT',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
    ]),
    clearScheduleTime() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    handleMessageSenderClear() {
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: null,
          },
        },
      });
    },
    handleSenderSelect(sender) {
      this['messageSender/SET_CURRENT_MESSAGE_SENDER'](sender);
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: sender.oid,
          },
        },
      });
    },
    handleCustomerNameInput(customerName) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        customerName,
      });
    },
    handleSubjectInput(subject) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            subject,
          },
        },
        name: subject,
      });
    },
    handlePreviewTextInput(previewText) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            previewText,
          },
        },
      });
    },
    async handleMessageListChange(messageList) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });

      // The old dynamic tags file was mapped to old list, so we have to clean it up
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE']();
      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      await this.$nextTick();

      // RESET ADVANCED FILTERING
      if (!this.currentMessageListCanBeSchedued) {
        this.clearScheduleTime();
        this.handleAdvancedTargetingUpdate()
      }
    },
    handleResendVerificationEmailAnchorClick(anchorIdx) {
      if (anchorIdx === 0) {
        this['messageSender/RESEND_EMAIL_VERIFICATION'](this.currentMessageSender.oid);
      }
    },

    appendSidebarFiltersToQuickFilters(targetingFilter) {
      if (!targetingFilter || targetingFilter?.conditions?.length === 0) {
        return this.prunedScratchSegment.filter
      }

      if (!!this.prunedScratchSegment.filter.conditions && this.prunedScratchSegment.filter.conditions.length > 0) {
        targetingFilter.conditions = this.prunedScratchSegment.filter.conditions.concat(targetingFilter.conditions)

        if (this.prunedScratchSegment.filter.logic.length > 0) {
          targetingFilter.logic = (['(']).concat(this.prunedScratchSegment.filter.logic).concat([')']).concat(['and']).concat(targetingFilter.logic)
        } else {
          targetingFilter.logic = ['and'].concat(targetingFilter.logic)
        }
      }

      return targetingFilter
    },


    handleAdvancedTargetingUpdate() {
      const targetingFilter = this.appendSidebarFiltersToQuickFilters(clone(this.getCurrentFilterExpression));

      this['message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE'](targetingFilter);

      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'email',
      });
    },
    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']();
    },

    async handleClearAllFilters() {
      this['segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT']();
      await this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
      this.handleAdvancedTargetingUpdate();
    },
  },
};
</script>


<style lang="scss" scoped>
    .message-list-recipients-dropdown.filter-enabled {
      max-width: calc(100% - 104px);
    }

    .circle-hooker {
      position: relative;
      .circle {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background: $purple500;
        transform: translateX(50%) translateY(-50%);
        color: white;
        z-index: $zIndexHigh;

        span {
          position: relative;
          font-size: 10px;
          line-height: 10px;
          top: -1px;
        }
      }
    }

    .recipients-count {
      background-color: $skyBlueGrey300;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      border-radius: 4px;

      .recipients-text {
        color: $blueGrey800;
      }

      .recipients-description {

      color: $blueGrey700;
      }
    }
</style>
