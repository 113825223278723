<template>
  <section class="video-submit-container">
    <ar-input
      id="video-url"
      ref="videoUrl"
      class="video-url-input"
      v-model="videoUrl"
      :placeholder="placeholder"
      type="text"
      autocomplete="url"
      @keydown.13="handleVideoUrlSubmit"
    />
    <ar-simple-button
      class="save-button"
      text="Save"
      @click="handleVideoUrlSubmit"
    />
  </section>
</template>

<script>
export default {
  name: 'VideoExternalPicker',
  data() {
    return {
      videoUrl: '',
      url: '',
      videoType: '',
      videoId: '',
      placeholder: 'Enter YouTube/Vimeo video URL',
    };
  },
  methods: {
    async handleVideoUrlSubmit() {
      const parsedUrl = this.parseVideo(this.videoUrl);
      if (parsedUrl.type === 'youtube') {
        this.url = `https://www.youtube.com/embed/${parsedUrl.id}`;
        this.videoId = parsedUrl.id;
        this.videoType = 'youtube';
      } else if (parsedUrl.type === 'vimeo') {
        this.url = `https://player.vimeo.com/video/${parsedUrl.id}`;
        this.videoId = parsedUrl.id;
        this.videoType = 'vimeo';
      } else {
        this.$emit('change', {
          type: 'error',
          url: '',
          preview: '',
          videoId: '',
        });
        return;
      }

      this.$emit('submit', {
        type: this.videoType,
        url: this.url,
        preview: this.preview,
        videoId: this.videoId,
      });
    },

    // https://gist.github.com/lekkerduidelijk/ff3d25fe00956787a42220533f94511b
    parseVideo(url) {
      // - Supported YouTube URL formats:
      //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
      //   - http://youtu.be/My2FRPA3Gf8
      //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
      // - Supported Vimeo URL formats:
      //   - http://vimeo.com/25451551
      //   - http://player.vimeo.com/video/25451551
      // - Also supports relative URLs:
      //   - //player.vimeo.com/video/25451551

      url.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
      );
      let type = '';
      if (RegExp.$3.indexOf('youtu') > -1) {
        type = 'youtube';
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        type = 'vimeo';
      } else {
        type = 'error';
      }

      return {
        type,
        id: RegExp.$6,
      };
    },
  },
};
</script>

<style lang="scss">
.video-submit-container {
  display: flex;

  .video-url-input {
    width: 100%;
  }

  .save-button {
    position: relative;
    width: 120px;
    z-index: $zIndexHigh;
  }
}
</style>
