var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditableFrame',{staticClass:"delay-by-sidebar-wrapper",attrs:{"title":"Edit Action","subtitle":_vm.item.name,"has-save-button":"","save-button-disabled":_vm.savedButtonDisabled},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.handleSave}},[_c('ar-text',{staticClass:"delay-by-heading",attrs:{"size":"13px","text":"DELAY BY","weight":"bold","multiple-lines":"","max-lines":1,"letter-spacing":"1px"}}),_vm._v(" "),_c('div',{staticClass:"delay-by-wrapper"},[_c('ar-input',{staticClass:"delay-by-input",style:({
        height: '50px',
        width: '163px',
        marginRight: '7px',
      }),attrs:{"type":"number","data-test-id":"delay-by-input"},model:{value:(_vm.delayByVal),callback:function ($$v) {_vm.delayByVal=$$v},expression:"delayByVal"}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"order-dropdown",style:({
        height: '50px',
        width: '100%',
        marginBottom: '20px'
      }),attrs:{"items":_vm.delayByOptions,"default-select-index":_vm.dropdownItemIndex,"dropdown-style":{
        padding: '8px 0',
        maxHeight: '400px',
      },"data-test-id":"delay-by-select"},on:{"select":_vm.handleDropdownItemSelect}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }