<template>
  <EditableFrame
    class="wait-until-sidebar-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="wait-until-heading"
      size="13px"
      text="WAIT UNTIL"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="wait-until-wrapper">
      <am2-date-time-input
        :key="updateKey"
        v-model="waitUntilDate"
        :timeZone="waitUntilTimeZone"
        data-test-id="wait-until-date"
      />
      <am2-timezone-dropdown
        class="wait-until-timezone-dropdown"
        :value="waitUntilTimeZone"
        @input="handleTimeZoneInput"
        data-test-id="wait-until-timezone-link"
      />
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
import { generateRandomString } from '@/utils/helpers'
export default {
  name: 'WaitUntil',
  components: {
    EditableFrame
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      waitUntilTimeZone: null,
      waitUntilDate: null,
      updateKey: '',
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    }
  },
  computed: {
    savedButtonDisabled() {
      return !(!!this.waitUntilTimeZone && !!this.waitUntilDate && !!(this.waitUntilDate !== 'Invalid date'))
    },
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG'
    }),
    handleTimeZoneInput(tz) {
      this.waitUntilTimeZone = tz
    },
    handleSave() {      
      let unixDateTimeSec = dayjs(this.waitUntilDate).unix()
      let contextData = {
        config: {
          'wait-until-unix-time-sec': unixDateTimeSec,
          frontend: {
            timezone: this.waitUntilTimeZone,
          }
        },
      }
      this.updateActionConfig({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    resetDefaults() {
      this.waitUntilDate = null
      this.waitUntilTimeZone = null
    },
    setItemVals() {
      this.resetDefaults()
      if (!this.item.config?.['wait-until-unix-time-sec']) return
      let isoUTC = dayjs(this.item.config['wait-until-unix-time-sec'] * 1000).toISOString()
      this.updateKey = generateRandomString()
      this.$nextTick(() => {
        this.waitUntilDate = isoUTC
        this.waitUntilTimeZone = this.item.config.frontend.timezone
      })
    }
  },
  mounted() {
    this.setItemVals()
  },
}
</script>
<style lang="scss" scoped>
.wait-until-sidebar-wrapper {
  width: 100%;
  height: 100%;

  .wait-until-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;

    .wait-until-timezone-dropdown {
      margin-top: 14px;
    }
  }
}
</style>