<template>
  <article class="leaderboard-container">
    <header class="header">
      <am2-heading
        type="h1"
        size="md"
        :title="title"
      />
    </header>
    <hr class="leaderboard-header-line">

    <am2-fan-table
      :head="tableHeadings"
      :body="rankings"
      tableType="leaderboard"
    />
    <ar-simple-button
      class="go-audience-button"
      text="Go to Audience"
      outlined
      @click="linkto"
      :style="{ width: '100%' }"
    />
  </article>
</template>
<script>

const tableHeadings = [
  {
    name: 'Name',
    key: 'name',
    format: 'name',
  },
  {
    name: 'Tier',
    key: 'tier',
    format: 'text',
  },
  {
    name: 'Referrals',
    key: 'referrals',
    format: 'number',
  },
  {
    name: 'Points',
    key: 'points',
    format: 'points',
  },
];
export default {
  name: 'Leaderboard',

  props: {
    title: {
      type: String,
      default: '',
    },
    rankings: {
      type: Array,
      default: () => [
        {
          points: '0',
          lastName: '-',
          referrals: '-',
          firstName: '-',
        },
        {
          points: '0',
          lastName: '-',
          referrals: '-',
          firstName: '-',
        },
        {
          points: '0',
          lastName: '-',
          referrals: '-',
          firstName: '-',
        },
        {
          points: '0',
          lastName: '-',
          referrals: '-',
          firstName: '-',
        },
        {
          points: '0',
          lastName: '-',
          referrals: '-',
          firstName: '-',
        },
      ],
    },
  },
  data: () => ({
    tableHeadings,
  }),
  methods: {
    linkto() {
      this.$emit('audienceClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.leaderboard-container {
  padding: 33px 38px;
}

.header {
  margin-bottom: 35px;
}
/* LEADERBOARD ------------------------ */
.leaderboard {
  table {
    border: 0;
    td:nth-child(1) {
      // border: 10px solid $skyBlueGrey500;
      width: 60%;
    }
    td {
      border-bottom: 0;
      border-right: 0;
      border-left: 0;
      padding: 9px 15px 9px 0px;
    }
    th {
      background-color: white;
      border: 0;
      padding-left: 20px;
    }
    th:not(:nth-child(1)) {
      text-align: right;
    }
    td:not(:nth-child(1)) {
      text-align: right;
    }
    tr:last-child > td {
      border-bottom: 0;
    }
  }
  .leaderboard-header-line {
    margin-top: 29px;
  }
}
.go-audience-button {
  margin-top: 16px;
}
.button-audience {
  top: 4px;
  height: 50px;
}
</style>
