var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"ar-hero"},[_c('div',{class:['hero-image', _vm.image && 'has-filter'],style:({'backgroundImage': _vm.image ? ("url(" + _vm.image) : null})}),_vm._v(" "),_c('div',{staticClass:"ar-hero__wrapper content content--centered"},[_c('am2-step-link',{staticClass:"back-link",attrs:{"text":_vm.stepBackText},on:{"click":_vm.handleStepLinkClick}}),_vm._v(" "),_c('div',{class:[
        'u-display-flex',
        'event-wrapper'
      ]},[_c('div',{staticClass:"left-container"},[(!_vm.isCustomer)?_c('div',{class:[
            'title-container',
            _vm.isEvents && 'event'
          ]},[_c('div',{staticClass:"page-tag-container"},[(_vm.isTours)?_c('div',{staticClass:"page-tag"},[_c('span',{staticClass:"page-tag-type"},[_vm._v("Tour")])]):(!_vm.isEvents && !_vm.isCustomer)?_c('div',{staticClass:"page-tag"},[_c('span',{staticClass:"page-tag-type"},[_vm._v(_vm._s(_vm.type))]),_c('router-link',{attrs:{"to":("/events/" + _vm.eventOid + "/view/campaigns")}},[_c('button',{staticClass:"page-tag-title"},[_vm._v(_vm._s(_vm.eventName))])])],1):_vm._e()]),_vm._v(" "),_c('am2-heading',{style:({ color: 'white' }),attrs:{"type":"h1","size":"xl","title":_vm.title}})],1):_vm._e(),_vm._v(" "),(_vm.isCustomer)?_c('div',{staticClass:"customer-data-container"},[_c('ar-avatar',{attrs:{"image-path":_vm.avatarImage,"name":_vm.title,"size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '60px' : '80px',"color":"grey","text-size":"lg"}}),_vm._v(" "),_c('div',{staticClass:"customer-data-information"},[_c('div',{staticClass:"u-display-flex u-align-items-center"},[_c('am2-heading',{style:({ color: 'white' }),attrs:{"type":"h1","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'lg' : 'xl',"multiple-lines":"","title":_vm.title}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex",style:({
                  position: 'relative',
                  marginLeft: '30px',
                })},_vm._l((_vm.membershipAvatars),function(membershipAvatar,idx){return _c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                    content: membershipAvatar.tooltip,
                  }),expression:"{\n                    content: membershipAvatar.tooltip,\n                  }",modifiers:{"bottom":true}}],key:idx,style:({
                    marginRight: '10px',
                  }),attrs:{"name":membershipAvatar.iconName,"height":"15px","wrapper-style":{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '29px',
                    height: '29px',
                    borderRadius: '50%',
                    background: 'white',
                    boxShadow: '0 0 13px 1px rgba(255,255,255,0.55)',
                  },"color":membershipAvatar.iconColor || _vm.$arStyle.color.blueGrey800}})}),1)],1),_vm._v(" "),_c('div',{staticClass:"customer-data-information__subheader"},[(_vm.customerAge)?_c('ar-text',{style:({
                  color: 'white',
                  marginRight: '7px',
                }),attrs:{"text":_vm.customerAge,"size":"xs"}}):_vm._e(),_vm._v(" "),(_vm.customerAge && _vm.location)?_c('ar-text',{style:({
                  color: 'white',
                  marginRight: '7px',
                }),attrs:{"text":"•","size":"xxxs"}}):_vm._e(),_vm._v(" "),(_vm.location)?_c('ar-link-button',{attrs:{"text":_vm.location,"color":"#ffffff"},on:{"click":_vm.handleLocationClick}}):_vm._e()],1)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{class:['col-xs-12', 'info', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[(_vm.isEvents && _vm.location)?_c('p',{staticClass:"info-cell location"},[_c('ar-icon',{style:({
                  marginRight: '7px',
                }),attrs:{"height":"16px","name":"location","color":"white"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: 'white',
                }),attrs:{"text":_vm.location,"size":"xs"}})],1):(_vm.isEvents)?_c('p',{staticClass:"info-cell location"},[_c('ar-icon',{style:({
                  marginRight: '7px',
                }),attrs:{"height":"16px","name":"location","color":"white"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: 'white',
                }),attrs:{"text":"Online","size":"xs"}})],1):_vm._e(),_vm._v(" "),(_vm.timeframe && _vm.timeframe)?_c('p',{staticClass:"info-cell timeframe"},[_c('ar-icon',{style:({
                  marginRight: '7px',
                }),attrs:{"height":"16px","name":"clock","color":"white"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: 'white',
                }),attrs:{"text":_vm.timeframe,"size":"xs"}})],1):_vm._e(),_vm._v(" "),(!_vm.isEvents && !_vm.isCustomer)?_c('a',{staticClass:"info-cell url",attrs:{"href":_vm.previewURL(_vm.urlSlug),"target":"_blank"}},[_c('ar-icon',{style:({
                  marginRight: '7px',
                }),attrs:{"height":"16px","name":"link","color":"white"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: 'white',
                }),attrs:{"text":_vm.previewURL(_vm.urlSlug),"allow-html":"","size":"xs"}})],1):_vm._e()])])]),_vm._v(" "),(_vm.isEvents && _vm.urlSlug)?_c('div',{staticClass:"right-container"},[_c('div',[_c('ar-text',{style:({
              color: 'white',
            }),attrs:{"text":"Your Event URL","size":"xxs"}})],1),_vm._v(" "),_c('div',{staticClass:"slug-url-wrapper"},[_c('a',{staticClass:"slug-url",attrs:{"href":_vm.previewURL(_vm.urlSlug),"target":"_blank"}},[_c('ar-icon',{style:({
                marginRight: '7px',
              }),attrs:{"height":"12px","name":"link","color":"white"}}),_vm._v(" "),_c('ar-text',{staticClass:"url",style:({
                color: 'white',
                cursor: 'pointer',
                height: 'default',
              }),attrs:{"text":_vm.previewURL(_vm.urlSlug),"allow-html":"","size":"xxs"}})],1),_vm._v(" "),_c('div',{staticClass:"divider"}),_vm._v(" "),_c('div',{staticClass:"copy-link",on:{"click":_vm.handleInputLabelClick}},[_c('ar-text',{style:({
                color: 'white',
              }),attrs:{"text":_vm.copyLinkLabel,"allow-html":"","size":"xxs","align":"center"}})],1)])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }