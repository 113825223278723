var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-type-chart",attrs:{"data-component":"ticket-type-chart"}},[_c('article',{style:({ height: '100%' })},[(!_vm.hideTitle)?_c('header',{class:['header-section', _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min']},[_c('div',{staticClass:"header-left-section"},[_c('am2-heading',{attrs:{"type":"h1","size":"md","title":_vm.title}}),_vm._v(" "),(_vm.titleDropdownOptions && _vm.titleDropdownOptions.length > 0)?_c('am2-link-button-dropdown',{staticClass:"u-margin-left-1",style:({
            maxHeight: '40px',
          }),attrs:{"item-key":_vm.selectedTitleTopic,"items":_vm.titleDropdownOptions,"item-style":{
            height: '44px',
            padding: '0 20px',
          },"button-props":{
            hasUnderline: false,
            hasArrow: true,
            textProps: {
              size: 'md',
            },
            color: 'black',
            height: '40px',
          }},on:{"select":_vm.handleTitleTopicChange}}):_vm._e(),_vm._v(" "),(_vm.description)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.description,
          }),expression:"{\n            content: description,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-top-1 u-margin-left-2 tooltip-icon",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.blueGrey500,"height":"16px","width":"16px"}}):_vm._e()],1),_vm._v(" "),(_vm.groupButtonsSelectItems && !_vm.hideSwitchButtons)?_c('div',{style:({
            marginTop: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '16px' : '0',
        })},[_c('am2-elegant-tabs',{style:({
            minWidth: _vm.customTabWidth ? _vm.customTabWidth : (_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '244px' : '264px'),
          }),attrs:{"items":_vm.groupButtonsSelectItems,"tab-key":_vm.topicIndex,"layout":"wide"},on:{"select":_vm.handleGroupButtonsSelect}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.hasData)?_c('div',{class:[
        'graph-container',
        _vm.locked && 'blurred' ]},[_vm._t("default")],2):(!_vm.loading && !_vm.hasData)?_c('div',{staticClass:"empty-state-container",style:({
            height: _vm.groupButtonsSelectItems && !_vm.hideSwitchButtons ? 'calc(100% - 80px)' : null,
         })},[_c('ar-state-message',{attrs:{"has-icon":"","disable-text-color":"","type":"general","text":"No data is available"}})],1):_c('div',{staticClass:"loading-section",style:({ height: _vm.hideTitle ? '100%' : 'calc(100% - 80px)' })},[_c('am2-loading-bubble')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }