<template>
  <div class="warning-modal-body">
    <div class="exclamation-mark">
      <ar-icon
        name="alert-exclamation-mark"
        height="70px"
      />
    </div>
    <ar-text
      class="warning-modal-title"
      multiple-lines
      :text="`Contact sales for access to ${featureName}`"
      align="center"
      size="md"
    />
    <ar-text
      class="warning-modal-message"
      :text="`You don't have access to ${featureName} yet. For access please <a target='__blank' href='https://audiencerepublic.com/contact-us?type=organizer'>contact sales</a>`"
      allow-html
      multiple-lines
      align="center"
      size="sm"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NotAccessibleSection',
  layout: 'default',

  props: {
    featureName: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 250px;
  .exclamation-mark {
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
  }
  .warning-modal-title {
    margin-bottom: 16px;
  }
  .warning-modal-message {
    color: $blueGrey800;
  }
}
</style>
