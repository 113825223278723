var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PlanFrame',{attrs:{"title":"Select Your Plan","description":"Select a plan that suits your needs."}},[_c('div',{class:[
    'plans-page',
    _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[(_vm.isFetchingPaymentPlans || _vm.isFetchingPaymentSubscriptions || _vm.isFetchingPromoterAudienceCount)?_c('am2-loading-section'):_c('div',{staticClass:"u-display-flex"},_vm._l((_vm.baseYearlyPaymentPlans),function(basePlan,idx){return _c('div',{key:basePlan.puid,style:({
          position: 'relative',
          top: basePlan.additionalInfo.metaData.presentation.isMostPopular ? '-30px' : '0',
          marginRight: idx !== _vm.baseYearlyPaymentPlans.length - 1 ? _vm.$arMediaQuery.pageContent.maxWidth('md') ? '12px' : '40px' : '0',
        })},[_c('PlanCard',{style:({
            width: '380px',
            maxWidth: '88vw',
          }),attrs:{"payment-plan":basePlan,"is-most-popular":basePlan.additionalInfo.metaData.presentation.isMostPopular,"payment-subscription":basePlan.paymentSubscriptions[0],"base-payment-subscription":_vm.advancedPaymentPlanSubscriptionInformation.basePaymentSubscription,"real-contacts-number":_vm.promoterAudienceCount},on:{"click":function($event){return _vm.handlePlanSelect(idx)}}})],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }