export const CampaignTypeDescriptions = {
    presale: 'Fans register for presale access, and earn points for sharing, inviting their friends to register, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize. When registrations end, those who registered are sent their links to purchase presale tickets.',
    ballot: 'Reward your top supporters by accelerating their place in line to buy tickets. Fans earn points for sharing, inviting their friends to register for the ballot, and following you on social media. The top point scorers are invited to buy tickets first.',
    competition: 'Put up a great prize, with fans earning points for sharing, inviting their friends to enter, and for following you on social media.',
    waitlist: 'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
};

export const FreeCampaignTypeDescriptions = {
    presale: 'Fans register for presale access. When registrations end, those who registered are sent their links to purchase presale tickets.',
    ballot: 'Leverage high demand events to build your audience. Reward your top supporters by accelerating their place in line to buy tickets.',
    competition: 'Make your competitions go viral and build your audience',
    waitlist: 'Fans can join the waiting list for any extra tickets that become available.',
};