var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
  'customer-profile-activity',
  _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"left-side"},[_c('am2-card-container',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
        priority: 1,
        gap: 20,
      }),expression:"{\n        priority: 1,\n        gap: 20,\n      }"}],attrs:{"layout":"soft"}},[_c('am2-interactive-list',{attrs:{"items":_vm.interactiveListItems,"active-index":_vm.interactiveListIndex,"disabled":_vm.customerState.isFetchingActivity}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreActivity),expression:"loadMoreActivity"},{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
      show: !_vm.isCustomerProfileFeatureEnabled,
      showUpgradeOn: 'hover',
      iconName: 'audience',
      title: _vm.lockedTitle.activity,
      message: 'Unlock customer profiles to get access to a timeline for each fan showing previous activity.',
    }),expression:"{\n      show: !isCustomerProfileFeatureEnabled,\n      showUpgradeOn: 'hover',\n      iconName: 'audience',\n      title: lockedTitle.activity,\n      message: 'Unlock customer profiles to get access to a timeline for each fan showing previous activity.',\n    }"}],staticClass:"right-side",style:({
      borderRadius: !_vm.isCustomerProfileFeatureEnabled ? '5px' : null,
      overflow: !_vm.isCustomerProfileFeatureEnabled ? 'hidden' : null,
    }),attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"50"}},[_c('div',{style:({
        filter: _vm.isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
      })},[(_vm.computedActivity.length === 0 && _vm.customerState.isFetchingActivity === true)?_c('div',_vm._l((3),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card",attrs:{"type":"activity"}})}),1):_c('div',[(_vm.computedActivity.length === 0 && _vm.customerState.isFetchingActivity === false)?_c('am2-card-container',{staticClass:"no-content-container ar-transition-group-item",attrs:{"layout":"soft"}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"text":_vm.noContentProps.body,"size":"xs"}})],1):_c('transition-group',{attrs:{"name":"ar-transition-group","tag":"div"}},_vm._l((_vm.computedActivity),function(item){return _c('am2-activity-card',{key:item.oid,staticClass:"ar-transition-group-item u-margin-bottom-3",attrs:{"image-path":item.imagePath,"title":item.title,"subtitle":item.subtitle,"datetime":item.datetime,"text":item.text,"path":item.path,"link":item.link,"icon":item.icon,"children":item.children ? item.children : [],"types":item.type}})}),1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }