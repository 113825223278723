<template>
  <ar-modal
    :is-open="showModal"
    class="multiple-button-modal"
    hide-header
    hide-footer
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleCloseClick"
  >
    <div
      slot="body"
      class="multiple-button-modal-body"
    >
      <div
        v-if="showIcon"
        class="modal-icon"
      >
        <ar-icon
          v-bind="iconPropsComputed"
        />
      </div>
      <ar-text
        v-if="title"
        class="multiple-button-modal-title"
        :text="title"
        multiple-lines
        allow-html
        weight="normal"
        align="center"
        size="md"
      />
      <ar-text
        v-if="messageHtml"
        class="multiple-button-modal-message"
        size="xs"
        :text="messageHtml"
        allow-html
        multiple-lines
        line-height="25px"
        :style="{
          marginTop: '25px',
        }"
        align="center"
      />
      <div
        v-if="buttons && buttons.length"
        :class="[
          'multiple-button-modal-buttons-wrapper',
          $arMediaQuery.pageContent.minWidth('sm') ? buttonFlow : 'column',
        ]"
      >
        <ar-simple-button
          v-for="(button, n) in buttonsComputed"
          :key="n"
          v-bind="button.props"
          @click="() => { handleButtonClick(button.clickHandler) }"
        />
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapGetters } from 'vuex';

// Multiple Button Modal
// ---------------------
// Allows creation of custom modals, rendering custom buttons in the footer.
export default {
  data() {
    return {
      showModal: false, // Is the modal visible?
      showIcon: null, // Do we show an icon at the top of the modal?
      iconProps: {}, // Props for icon which appears at top of modal
      title: null, // String which appears within a header tag
      messageHtml: null, // String which appears under title
      buttons: [], // List of button objects
      buttonFlow: null, // How are buttons laid out on in the modal?
      onClose: () => {},
    };
  },

  computed: {
    iconPropsComputed() {
      return {
        name: 'alert-exclamation-mark',
        height: '70px',
        color: this.$arStyle.color.purple500,
        wrapperStyle: {
          borderRadius: '50%',
          width: '70px',
          height: '70px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ...this.iconProps,
      }
    },
    buttonsComputed() {
      return this.buttons.map( item => {
        return {
          name: item.name || 'am2-simple-button',
          props: {
            ...item.props
          },
          clickHandler: item.clickHandler
        }
      });
    },
  },

  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'SHOW_MULTIPLE_BUTTON_MODAL') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'SHOW_MULTIPLE_BUTTON_MODAL', payload } }));
    },
    startModalAction({
      showIcon,
      iconProps,
      title,
      messageHtml,
      buttons,
      buttonFlow,
      stickyFooter,
    }) {
      this.showIcon = showIcon || true;
      this.iconProps = iconProps || {};
      this.title = title || '';
      this.messageHtml = messageHtml || null;
      this.buttons = buttons || [];
      this.buttonFlow = buttonFlow || 'row';
      this.stickyFooter = stickyFooter || false;

      this.showModal = true;

      this.onClose = (resolveValue) => {
        this.showModal = false;
        this.respondModalAction(resolveValue)
      }
    },
    handleButtonClick(clickHandler) {
      const ret = clickHandler();
      this.onClose(ret);
      //
    },
    handleCloseClick() {
      this.onClose(false);
    },
  },
}
</script>

<style lang="scss" scoped>
.multiple-button-modal {
  .multiple-button-modal-body {
    .modal-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 19px;
      margin-top: 60px;
    }
    .multiple-button-modal-title {
      margin: 0 60px 16px;
    }
    .multiple-button-modal-message {
      margin: 0 60px;
    }
    .multiple-button-modal-buttons-wrapper {
      display: flex;
      align-items: center;
      margin: 35px 0;
      padding: 0 24px;
      &.row {
        flex-direction: row;
        justify-content: center;
        button {
          margin: 0 8px;
        }
      }
      &.column {
        flex-direction: column;
        button {
          margin-top: 12px;
          width: 100%;
        }
      }
    }
  }
}
</style>
