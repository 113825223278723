<template>
  <div class="triggers-wrapper">
    <div v-if="!options.length" class="info-wrapper">
      <InfoIcon class="info-svg" height="20px" width="20px" />
      <ar-text
        class="sidebar-info"
        size="14px"
        text="Unable to find matching Triggers"
        weight="normal"
        multiple-lines
        :max-lines="1"
      />
    </div>
    <template v-else v-for="triggerType in options">
      <template v-for="(vals, key) in triggerType">
        <div class="trigger-context-wrapper" :key="key">
          <div class="trigger-heading-wrapper">
            <div
              v-html="require(`~/assets/icons/${vals.icon}.svg?raw`)"
              :class="`sidebar-icon ${vals.icon}-icon`"
            />
            <ar-text
              class="sidebar-type-heading"
              size="13px"
              :text="`${vals.text.toUpperCase()}`"
              weight="bold"
              multiple-lines
              :max-lines="1"
            />
          </div>
          <draggable
            :group="{name: 'triggersDraggable', pull: 'clone', put: false}"
            :list="vals.children"
            @start="() => setTriggerIsMoving(true)"
            @end="() => setTriggerIsMoving(false)"
            class="draggable-wrapper"
          >
            <template v-for="trigger in vals.children">
              <div class="trigger-item-wrapper sidebar-item" :key="trigger.key" :data-attr="trigger.type" :data-type="trigger.key" :id="addRandomId(trigger)">
                <div class="trigger-draggable">
                  <ar-text
                    class="trigger-item-text"
                    size="14px"
                    :text="trigger.name"
                    weight="normal"
                    multiple-lines
                    :max-lines="1"
                  />
                </div>
                <div class="add-trigger-placeholder">
                  <ar-text
                    class="trigger-placeholder-text"
                    size="14px"
                    text="Drag a new trigger here"
                    weight="normal"
                    multiple-lines
                    :max-lines="1"
                  />
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { generateRandomString } from '@/utils/helpers'
import InfoIcon from '~/assets/icons/info.svg?inline'
import { mapActions } from 'vuex'
export default {
  name: 'AutoTriggers',
  components: {
    InfoIcon,
  },
  props: {
    options: {
      type: Array,
      default: null,
    }
  },  
  methods: {
    ...mapActions({
      setIsTriggerMoving: 'automation/SET_TRIGGER_IS_MOVING',
    }),
    setTriggerIsMoving(bool) {
      this.setIsTriggerMoving(bool)
    },
    addRandomId(trigger) {
      let id = this.randomisedKey(trigger.key)
      trigger['id'] = id
      return id
    },
    randomisedKey(text) {
      return `${text}-${generateRandomString()}`
    }
  },
}
</script>
<style lang="scss" scoped>
$autoItemWidth: 325px;
.triggers-wrapper {
  .info-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 20px;

    .info-svg {
      margin-right: 7px;
      color: $blueGrey800;
    }

    .sidebar-info {
      color: $blueGrey800;
    }
  }
}
.trigger-context-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;

  &:first-child {
    .trigger-heading-wrapper {
      margin-top: 0;
    }
  }  

  .trigger-heading-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 20px;    

    .sidebar-icon {
      margin-right: 8px;

      &.contacts-icon {
        width: 17px;
        height: 17px;
        margin-top: -2px;
      }

      &.other-icon {
        width: 17px;
        margin-top: -5px;
      }
    }
  }

  .draggable-wrapper {
    width: 100%;

    .trigger-item-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 8px;
      border-radius: 3px;
      border: solid 0.2px rgba(194, 194, 194, 0.3);
      background-color: white;
      border-left: 6px solid $purple500;
      padding: 14px 22px;
      cursor: pointer;
      width: 100%;

      .trigger-draggable {
        width: 100%;

        .trigger-item-text {
          pointer-events: none;
        }
      }

      .add-trigger-placeholder {
        width: 100%;
        background: lightblue;
        padding: 9px 11px;
        border-radius: 3px;
        box-shadow: 0 2px 6px 0 $shadow;
        border: solid 0.2px #c2c2c2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.triggers-draggable-wrapper {
  .sortable-ghost {
    padding: 12px 16px;
    border-radius: 3px;
    border: solid 0.2px #c2c2c2;
    width: 325px;
    opacity: 0.3;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    margin-right: 9px;
    height: inherit;

    .trigger-draggable {
      display: none;
    }
  }
}

</style>