var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ar-field',{attrs:{"label":"Your Company Name","error-messages":[_vm.veeErrors.first('companyName')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchPrivacyPortal.companyName,"autocomplete":"organization","data-vv-name":"companyName","data-vv-as":"company name","placeholder":"Your company name"},on:{"input":_vm.handleCompanyNameInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Privacy Portal URL","description":"You’ll provide this link to customers so they can access the privacy portal","error-messages":[_vm.veeErrors.first('subdomain')]}},[_c('div',{staticClass:"u-display-flex u-align-items-center"},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          arPrivacyPortalSubdomain: {
            currentSubdomain: _vm.currentPrivacyPortalSubdomain,
          },
        }),expression:"{\n          required: true,\n          arPrivacyPortalSubdomain: {\n            currentSubdomain: currentPrivacyPortalSubdomain,\n          },\n        }"}],staticClass:"u-flex-grow-1 u-margin-right-3",attrs:{"value":_vm.scratchPrivacyPortal.subdomain,"data-vv-name":"subdomain","data-vv-as":"subdomain","placeholder":"subdomain"},on:{"input":_vm.handleSubdomainInput}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-right-2",attrs:{"size":"xs","text":("." + _vm.privacyPortalDomain)}})],1)]),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-7",attrs:{"label":"Company Logo","description":"Recommended dimensions 300px x 300px","error-messages":[_vm.veeErrors.first('companyLogo'), _vm.veeErrors.first('companyLogoUploading'), _vm.imageUploadErrorMsg]}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"validate",rawName:"v-validate:privacyPortalImageUrl",value:('required:true'),expression:"'required:true'",arg:"privacyPortalImageUrl"}],attrs:{"data-vv-name":"companyLogo","data-vv-as":"company logo"}}),_vm._v(" "),(_vm.isUploadingCompanyLogo)?_c('div',{directives:[{name:"validate",rawName:"v-validate:isUploadingCompanyLogo",value:('uploadingAssets'),expression:"'uploadingAssets'",arg:"isUploadingCompanyLogo"}],attrs:{"data-vv-name":"companyLogoUploading","data-vv-as":"company logo"}}):_vm._e(),_vm._v(" "),_c('am2-dropzone',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"img-dropzone",attrs:{"file-type":['image/jpeg', 'image/jpg', 'image/png'],"file-type-alias":"image","placeholder-icon":{
        name: 'image',
      },"image-options":{
        dimensions: {
          width: 300,
          height: 300
        }
      },"ratio":1,"feedback-type":"image","feedback":_vm.privacyPortalImageUrl,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":[{
        assetType: 'privacy-portal-image',
        campaignOid: null,
        eventOid: null,
      }]},on:{"delete":_vm.handleImageDelete,"uploadStart":function($event){_vm.isUploadingCompanyLogo = true},"uploadEnd":function($event){_vm.isUploadingCompanyLogo = false},"upload":_vm.handleImageUpload,"error":_vm.handleImageUploadError}}),_vm._v(" "),(_vm.isUploadingCompanyLogo)?_c('ar-skeleton',{style:({
        width: '100px',
        height: '100px',
        borderRadius: '5px',
      })}):(_vm.privacyPortalImageUrl)?_c('ar-framed-image',{style:({
        width: '100px',
        height: '100px',
      }),attrs:{"src":_vm.privacyPortalImageUrl}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-top-4"},[(!_vm.privacyPortalImageUrl)?_c('ar-icon',{staticClass:"u-margin-right-2",attrs:{"name":"image","height":"21px","color":_vm.$arStyle.color.blueGrey600}}):_vm._e(),_vm._v(" "),(!_vm.privacyPortalImageUrl)?_c('ar-link-button',{attrs:{"disabled":_vm.isUploadingCompanyLogo,"text":"Upload image"},on:{"click":_vm.handleUploadImageClick}}):_vm._e(),_vm._v(" "),(_vm.privacyPortalImageUrl)?_c('ar-link-button',{attrs:{"disabled":_vm.isUploadingCompanyLogo,"text":"Upload new image"},on:{"click":_vm.handleUploadImageClick}}):_vm._e()],1)],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-7",attrs:{"label":"Colors","description":"Use your own brand colors"}},[_c('am2-color-input',{attrs:{"value":_vm.scratchPrivacyPortal.meta.presentation.color},on:{"input":_vm.handleColorInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-7",attrs:{"label":"Your Privacy Policy (optional)","description":"Add your privacy policy link to the privacy portal","error-messages":[_vm.veeErrors.first('privacyPolicy')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        url: { require_protocol: true },
      }),expression:"{\n        url: { require_protocol: true },\n      }"}],staticClass:"u-width-100-percent",attrs:{"placeholder":"https://","value":_vm.scratchPrivacyPortal.meta.privacyPolicy,"autocomplete":"url","data-vv-name":"privacyPolicy","data-vv-as":"privacyPolicy"},on:{"input":_vm.handlePrivacyPolicyInput}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }