<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Shareable preview link"
      hide-footer
      width="707px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="preview-link-modal"
    >
      <div slot="body" class="body">
        <ar-field label="Copy the link for message preview">
          <div class="u-display-flex">
            <ar-input
              class="u-flex-grow-1"
              type="text"
              :value="resourceUrl"
              ref="link"
              data-test-id="preview-sharable-link"
              readonly
            />
            <ar-simple-button
              class="u-margin-left-3"
              text="Copy to clipboard"
              data-test-id="copy-to-clipboard"
              @click="handleCopyToClipboard"
            />
          </div>
        </ar-field>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ShareableLinkModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    messageOid: {
      type: Number,
      default: null,
    },
  },

  watch: {
    opened: {
      handler: function (val, oldVal) {
        if (val === oldVal) return
        if (!this.messageOid) {
          console.error("Cannot generate hash without message oid!")
          return
        }
        if (val) {
          this['messagePreview/GENERATE_RESOURCE_URL'](this.messageOid)
        } else {
          this['messagePreview/RESET_STATE']()
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      hash: state => state.messagePreview.hash,
    }),

    resourceUrl() {
      if (this.hash) {
        return `${window.location.origin}/message-preview/${encodeURIComponent(this.hash)}`
      } else {
        return null
      }
    },
  },

  methods: {
    ...mapActions([
      'messagePreview/GENERATE_RESOURCE_URL',
    ]),
    ...mapMutations([
      'messagePreview/RESET_STATE',
    ]),

    handleModalClose() {
      this.$emit('close');
    },

    handleCopyToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.resourceUrl);
      } else {
        this.$refs.link.focus();
        this.$refs.link.select();
        document.execCommand('copy');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-link-modal {
  .body {
    padding: 24px 29px 33px;
  }
}
</style>
