var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"onboarding-frame"},[_c('div',{class:[
      'content-section',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'full-width' ]},[_c('div',{staticClass:"right-upper-section"},[(_vm.hasGoBackLink)?_c('am2-step-link',{attrs:{"text":"Go back"},on:{"click":_vm.handleGoBackLinkClick}}):_vm._e(),_vm._v(" "),(_vm.totalStep)?_c('am2-simple-stepper',{style:({
          width: '120px',
          height: '5px',
          borderRadius: '2.5px',
        }),attrs:{"steps-count":_vm.totalStep,"current-step":_vm.currentStep,"has-divider":""}}):_vm._e(),_vm._v(" "),_vm._t("right-upper-section")],2),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),(_vm.hasNextStepPannel)?_c('div',{staticClass:"step-control-section"},[_c('div',{staticClass:"step-control-content"},[_c('ar-link-button',{style:({
          marginRight: '20px',
        }),attrs:{"text":"skip this step","has-underline":false},on:{"click":_vm.handleNextStepClick}}),_vm._v(" "),_c('ar-simple-button',{style:({
          width: '120px',
        }),attrs:{"type":"gradient-purple","shape":"pill","text":"Next"},on:{"click":_vm.handleNextStepClick}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }