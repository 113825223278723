<template>
  <div class="editable-frame" @keydown.esc="handleEscape" tabindex="0" >
    <div class="header-wrapper">
      <div class="heading-close-icon-wrapper">
        <ar-text
          class="heading"
          size="20px"
          :text="title"
          weight="bold"
          multiple-lines
          :max-lines="1"
          line-height="40px"
        />
        <CloseCrossIcon class="close-icon" @click="handleClose" height="14px" width="14px" data-test-id="editable-close-icon" />
      </div>
      <ar-text
        class="subheading"
        size="14px"
        :text="subtitle"
        weight="normal"
        multiple-lines
        :max-lines="1"
        line-height="2.14"
      />
    </div>
    <div class="content-wrapper">
      <slot />
    </div>
    <div
      v-if="hasSaveButton"
      class="footer-wrapper"
    >
      <ar-simple-button
        text="Save"
        :style="{
          height: '40px',
        }"
        :disabled="saveButtonDisabled"
        @click="handleSave"
        data-test-id="editable-save-button"
      />
    </div>
  </div>
</template>
<script>
import CloseCrossIcon from '~/assets/icons/close-cross-icon.svg?inline'
export default {
  name: 'EditableFrame',
  components: {
    CloseCrossIcon,
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    hasSaveButton: {
      type: Boolean,
      default: false,
    },
    saveButtonDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    handleEscape() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      this.$emit('save');
    },
  }
}
</script>
<style lang="scss" scoped>
.editable-frame {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  .header-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 32px 23px 0;

    .heading-close-icon-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .heading {
        pointer-events: none;
      }

      .close-icon {
        color: $skyBlueGrey700;
        cursor: pointer;
      }
   }

    .subheading {
      color: $blueGrey700;
      margin-top: -9px;
      pointer-events: none;
    }
  }

  .content-wrapper {
    flex-grow: 1;
    padding-top: 26px;
    padding: 32px 23px;
    width: 100%;
    overflow: auto;
  }

  .footer-wrapper {
    width: 100%;
    padding: 15px 23px;
    border-top: 1px solid $blueGrey500;
  }
}
</style>