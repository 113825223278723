<template>
    <div>

    </div>
</template>

<script>
  export default {
    name: 'EventConstraint'
  };
</script>

<style lang="scss" scoped>

</style>
