<template>
  <section
    class="ar-overview-tabs"
    ref="tabs-outer-container"
    @mouseover="handleMouseOverSection"
    @mouseleave="handleMouseLeaveSection"
  >
    <!-- Left shadow -->
    <div
      v-if="hasLeftBarShadow"
      ref="left-shadow"
      :class="['bar-shadow', 'left']"
      :style="{
        boxShadow: hasLeftBarShadow ? `inset 15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />

    <!-- Right shadow -->
    <div
      v-if="hasRightBarShadow"
      ref="right-shadow"
      :class="['bar-shadow', 'right']"
      :style="{
        boxShadow: hasRightBarShadow ? `inset -15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />
    <div
      class="ar-overview-tabs-inner"
      ref="tabs-inner-container">
      <section
        v-for="(item, idx) of items"
        :key="idx"
        :class="['item', idx === activeIdx && 'active']"
        :style="getItemStyle(items, item, idx)"
        @click="handleItemSelect(item, idx)"
      >
        <div class="tab-active-strip" />
        <ar-text
          class="value"
          :text="formatNumber(item)"
          size="lg"
        />
        <ar-text
          class="name"
          :text="item.name"
          size="sm"
        />
      </section>
    </div>
  </section>
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'OverviewTabs',

  props: {
    defaultActiveIndex: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemStyle: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      activeIdx: this.defaultActiveIndex,
      isHovered: false,
      hasLeftBarShadow: false,
      hasRightBarShadow: false,
    };
  },

  computed: {
    totalCount() {
      return this.items[0].value;
    },
  },

  watch: {
    defaultActiveIndex(val) {
      this.activeIdx = val;
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.$refs['tabs-inner-container'].addEventListener('scroll', this.horizontalScrollHandler);
  },
  // Remember to clear all listeners
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    this.$refs['tabs-inner-container'].removeEventListener('scroll', this.horizontalScrollHandler);
  },

  methods: {
    formatNumber(item) {
      if (this.isHovered && item.hoverValue) {
        return item.hoverValue;
      } else {
        return accounting.formatNumber(item.value);
      }
    },
    getItemStyle(items, item, idx) {
      return {
        ...this.itemStyle,
        borderRight: idx !== (items.length - 1) ? `1px ${this.$arStyle.color.blueGrey500} solid` : null,
      };
    },
    handleMouseOverSection() {
      this.isHovered = true;
    },
    handleMouseLeaveSection() {
      this.isHovered = false;
    },
    handleItemSelect(item, index) {
      this.activeIdx = index;
      this.$emit('select', item, index);
    },
    handleWindowResize() {
      this.horizontalScrollHandler();
    },
    horizontalScrollHandler() {
      this.hasRightBarShadow = (this.$refs['tabs-inner-container'].scrollLeft + this.$refs['tabs-outer-container'].offsetWidth) < this.$refs['tabs-inner-container'].scrollWidth;
      this.hasLeftBarShadow = this.$refs['tabs-inner-container'].scrollLeft > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-overview-tabs {
  position: relative;

  &-inner {
    display: flex;
    flex-direction: row;
    height: 110px;
    flex-wrap: nowrap;
    overflow: auto;
    border: 1px $blueGrey500 solid;
    border-radius: 5px;
  }

  .bar-shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: $zIndexHighest;

    &.right {
      right: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    
    &.left {
      left: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
    min-width: 140px;

    .tab-active-strip {
      position: absolute;
      bottom: 0;
      background: white;
      height: 3px;
      width: 100%;
    }

    .value {
      color: $blueGrey600;
      height: 27px;
      transition: all 0.3s;

      .text-section .text-section__inner {
        font-size: 27px;
      }
    }

    .name {
      color: $blueGrey500;
      transition: all 0.3s;
      margin-top: 5px;

      .text-section .text-section__inner {
        font-size: 15px;
      }
    }

    &:hover {
      .tab-active-strip {
        background: $purple100;
      }
    }

    &.active {
      .tab-active-strip {
        background: $purple500;
      }

      .value {
        color: $purple500;
      }

      .name {
        color: $blueGrey800;
      }
    }
  }
}
</style>
