<template>
  <am2-tag
    :text="`${valueText}%`"
    :type="valueAsInt >= 0 ? 'green' : 'red'"
    shape="rectangle"
    icon-name="arrow"
    :icon-props="{
      rotate: valueAsInt >= 0 ? 180 : 0,
    }"
  />
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'TendencyTag',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    valueAsInt() {
      return parseInt(this.value);
    },
    valueText() {
      return accounting.formatNumber(this.value);
    },
  }
};
</script>
