<template>
    <div :class="[
      'unlock-prompt',
      size,
    ]">
      <div class="left-hand-side">
        <div class="icon-container">
          <ar-icon
            name="lock"
            color="#FFF"
            width="24px"
          />
        </div>
        <div class="text-container">
          <am2-heading
            :title="title"
            size="md"
            class="u-margin-bottom-2"
            weight="bold"
            :style="{
              color: '#FFF',
            }"
          />
          <ar-text
            :text="subtitle"
            size="xs"
            color="#FFF"
            multiple-lines
            allow-html
            anchor-color="#FFF"
            :style="{
              color: '#FFF',
            }"
          />
        </div>
      </div>
      <div class="right-hand-side">
        <ar-simple-button
          :text="buttonText"
          outlined
          :style="{
            border: 'none',
          }"
          @click="handlePlanClick"
        />
      </div>
    </div>
</template>

<script>
  export default {
    name: 'UnlockPrompt',

    props: {
      title: {
        type: String,
        default: null,
      },
      subtitle: {
        type: String,
        default: null,
      },
      buttonText: {
        type: String,
        default: "Upgrade Plan",
      },
      size: {
        type: String,
        default: 'medium',
        validator: (val) => ['small', 'medium'].indexOf(val) > -1,
      },
    },

    methods: {
      handlePlanClick() {
        this.$emit('click');
      },
    }
  };
</script>

<style lang="scss" scoped>
  .unlock-prompt {
    width: 100%;
    background: $purpleGradient;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    z-index: $zIndexHighest;

    .left-hand-side {
      display: inline-flex;
      align-items: center;

      .icon-container {
        padding-right: 30px;
      }
    }
    .right-hand-side {
      display: inline-flex;
      align-items: center;
      padding-left: 12px;
    }

    &.small {
      padding: 10px;
      .left-hand-side {
        .icon-container {
          padding-right: 12px;
        }
      }
    }

  }
</style>
