var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dashboard"},[(_vm.isFetchingStats)?_c('am2-loading-section',{style:({
      height: '100px',
    })}):_c('div',{class:[
      'content-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('am2-card-container',{staticClass:"cell"},[_c('OverviewBlock',{attrs:{"overall":_vm.registrations.total,"direct":!_vm.isFreeCampaign && _vm.directRegistrations,"viral":!_vm.isFreeCampaign && _vm.viralRegistrations,"progress":_vm.registrations.increase,"new-registrations":_vm.leaderboardStats,"unique-views":_vm.uniqueViews,"conversion-rate":_vm.conversionRate,"overall-title":"Total registrations","direct-title":"Direct","viral-title":"Via Audience Republic"}},[_c('p',{staticClass:"promote",attrs:{"slot":"info"},slot:"info"})])],1),_vm._v(" "),_c('am2-card-container',{staticClass:"registrations-cell",style:({
        position: 'relative',
        padding: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '30px 12px' : '30px',
      })},[_c('div',{style:({
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          top: '30px',
          left: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '12px' : '30px',
          zIndex: '2',
        })},[(_vm.isCampaignActive)?_c('am2-signal-tag',{style:({ marginRight: '12px' }),attrs:{"text":"Active"}}):_vm._e(),_vm._v(" "),(_vm.campaign && _vm.campaign.endDate)?_c('Countdown',{staticClass:"ends-in",attrs:{"date":_vm.campaign.endDate,"time-zone":_vm.campaign.presentation.timeZone},on:{"campaign-ended":function($event){!_vm.isCampaignActive}}}):_vm._e()],1),_vm._v(" "),_c('am2-multiple-column-charts',{style:({
          marginTop: _vm.campaign && _vm.campaign.endDate && _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '32px' : '-9px',
        }),attrs:{"title":"Registrations","hide-switch-buttons":"","hide-title":"","chart-height":420,"topics":_vm.registrationsChartData.topics,"topic-index":0,"chart-datas":_vm.registrationsChartData.chartDatas}})],1),_vm._v(" "),(!_vm.isFreeCampaign)?_c('am2-card-container',{staticClass:"cell"},[_c('ActionBlock',{attrs:{"channels":_vm.shareStats,"default-order":_vm.shareDefaultOrder,"title":"Shares"}})],1):_vm._e(),_vm._v(" "),(!_vm.isFreeCampaign)?_c('am2-card-container',{staticClass:"cell"},[_c('ActionBlock',{attrs:{"channels":_vm.followStats,"default-order":_vm.followDefaultOrder,"title":"Follows & subscribes","help":"Not all actions can be verified so some are estimates"}})],1):_vm._e(),_vm._v(" "),(!_vm.isFreeCampaign)?_c('am2-card-container',{staticClass:"cell"},[_c('ActionBlock',{attrs:{"channels":_vm.connectStats,"default-order":_vm.connectDefaultOrder,"title":"Connections"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }