<template>
  <div class="select-email-template-wrapper">
    <am2-tabs
      id="email-template-tabs"
      class="email-template-tabs"
      :selected-tab-index="selectedTemplateSourceIndex"
      @select="handleTemplateSourceSelect"
      :items="templateSourceItems"
    />
    <ar-divider class="template-tabs-divider" />
    <am2-no-content-section
      v-if="hasNoEmailTemplates"
      id="email-template-no-content"
      class="template-no-content-wrapper"
      header="No templates found"
      header-size="sm"
      :style="{
        marginTop: '22px',
      }"
    />
    <div
      id="email-templates-wrapper"
      :class="[
        'templates-wrapper',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <am2-template-card
        v-for="emailTemplate of emailTemplates"
        ref="template-card"
        :key="emailTemplate.oid"
        v-show="selectedTemplateSource === 'bucket'"
        :id="`email-template-card-${emailTemplate.oid}`"
        :class="[
          'template-card',
          $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
        :data-test-id="`email-template-card-${emailTemplate.oid}`"
        :name="emailTemplate.meta.name"
        :description="emailTemplate.meta.description"
        :template="emailTemplate.meta.template"
        :template-html="getEmailTemplateHtml(emailTemplate)"
        :type="emailTemplate.meta.templateType"
        :has-dropdown="false"
        :disabled="!getEmailTemplateHtml(emailTemplate)"
        @click="handleTemplateCardClick(emailTemplate, getEmailTemplateHtml(emailTemplate))"
      />
      <am2-template-card
        v-for="emailTemplate of unlayerTemplates"
        ref="template-card"
        :key="emailTemplate.id"
        v-show="selectedTemplateSource === 'unlayer'"
        :id="`unilayer-template-card-${emailTemplate.id}`"
        :class="[
          'template-card',
          $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
        :data-test-id="`unilayer-template-card-${emailTemplate.id}`"
        :name="emailTemplate.name"
        :template="emailTemplate.design"
        :template-html="getUnlayerHtml(emailTemplate.design)"
        type="unlayer"
        :has-dropdown="false"
        :disabled="!getUnlayerHtml(emailTemplate.design)"
        @click="handleUnlayerTemplateCardClick(emailTemplate, getUnlayerHtml(emailTemplate.design))"
      />
      <am2-card-skeleton
        v-for="n in skeletonCardNumber"
        :key="n"
        type="email-template"
        :class="[
          'template-card',
          $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'SelectEmailTemplateSection',
  data() {
    return {
      templateSourceItems: [
        {
          title: 'Your templates',
          value: 'bucket',
        },
        {
          title: 'Library',
          value: 'unlayer',
        },
      ],
      selectedTemplateSource: 'bucket',
    }
  },
  computed: {
    ...mapState({
      emailTemplates: state => state.emailTemplate.emailTemplates,
      isFetchingEmailTemplates: state => state.emailTemplate.isFetchingEmailTemplates,
      unlayerTemplates: state => state.emailTemplate.unlayerTemplates,
      isFetchingUnlayerTemplates: state => state.emailTemplate.isFetchingUnlayerTemplates,
    }),
    ...mapGetters({
      getUnlayerHtml: 'emailTemplate/getUnlayerHtml',
    }),
    selectedTemplateSourceIndex() {
      return this.templateSourceItems.findIndex(item => item.value === this.selectedTemplateSource);
    },
    hasNoEmailTemplates() {
      return this.emailTemplates.length === 0
        && !this.isFetchingEmailTemplates
        && this.selectedTemplateSource === 'bucket';
    },
    skeletonCardNumber() {
      if (this.selectedTemplateSource === 'bucket' && !this.isFetchingEmailTemplates) {
        return 0;
      }
      if (this.selectedTemplateSource === 'unlayer' && !this.isFetchingUnlayerTemplates) {
        return 0;
      }
      // Add number of skeleton cards to fill a new row the current row
      if (this.$arMediaQuery.pageContent.maxWidth('xs')) {
        return 3;
      } else if (this.$arMediaQuery.pageContent.maxWidth('md')) {
        return 6 + this.emailTemplates.length % 2;
      } else if (this.$arMediaQuery.pageContent.maxWidth('lg')) {
        return 9 + this.emailTemplates.length % 3;
      } else {
        return 12 + this.emailTemplates.length % 4;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchEmailTemplates: 'emailTemplate/FETCH_ALL_EMAIL_TEMPLATES',
      fetchUnlayerTemplates: 'emailTemplate/FETCH_UNLAYER_TEMPLATES',
    }),
    handleTemplateSourceSelect(templateTypeItem) {
      this.selectedTemplateSource = templateTypeItem.value
    },
    getEmailTemplateHtml(emailTemplate) {
      if (emailTemplate.meta.templateType === 'unlayer') {
        return this.getUnlayerHtml(emailTemplate.meta.template);
      }
      return emailTemplate.meta.template;
    },
    handleTemplateCardClick(emailTemplate, emailHtml) {
      this.$emit(
        'select',
        emailTemplate.meta.template,
        emailTemplate.meta.templateType,
        emailHtml,
      );
    },
    handleUnlayerTemplateCardClick(emailTemplate, emailHtml) {
      this.$emit(
        'select',
        emailTemplate.design,
        'unlayer',
        emailHtml,
      );
    },
  },
  mounted() {
    this.fetchEmailTemplates()
    this.fetchUnlayerTemplates()
  }
}
</script>
<style lang="scss" scoped>
  .select-email-template-wrapper {
    background: #f3f6fa;
    padding: 30px 40px 50px;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .email-template-tabs {
      align-self: flex-end;
    }

    .template-tabs-divider {
      width: 100%;
    }

    .template-no-content-wrapper {
      width: 100%;
    }

    .templates-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 22px;
      margin-right: -19px;
      width: 100%;

      &.sm-max {
        padding: 20px;
      }

      .template-card {
        flex-grow: 0;
        width: calc(25% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        &.lg-max {
          width: calc(33.3% - 20px);
          margin-right: 20px;
        }

        &.md-max {
          width: calc(50% - 20px);
          margin-right: 20px;
        }

        &.xs-max {
          width: 100%;
        }
      }
    }
  }
</style>