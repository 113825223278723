<template>
  <div class="change-timezone-wrapper" @keyup.esc="onEsc" @keydown.space.prevent="onSpace" tabindex="0">
    <div class="change-timezone-header-wrapper">
      <div class="back-wrapper" @click="handleBack">
        <ArrowHead class="back-arrowhead" height="8px" width="8px" fill="#7344c0" />
        <ar-link-button
          class="change-timezone-back-button"
          text="Back"
          :has-underline="false"
          :color="$arStyle.color.$purple500"
          data-test-id="change-timezone-back-button"
        />
      </div>
      <ar-text
        class="change-timezone-copy"
        size="14px"
        text="Graph timezone"
        weight="bold"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
    </div>

    <div class="timezone-dropdown-wrapper" @click="toggleOptions" data-test-id="change-timezone-options-toggle">
      <ar-text
        class="selected-timezone-copy"
        size="14px"
        :text="selectedTimezone.name"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ArrowHead class="timezone-dropdown-arrowhead" height="8px" width="10px" fill="#8492a6" />
    </div>
    
    <div 
      v-show="optionsOpen"
      class="timezone-dropdown-options-wrapper"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp" 
      @keyup.enter="onEnter"
    >
      <div class="timezone-options-container">
        <am2-search
          tabindex="0"
          ref="timzoneSearchInput"
          class="timezone-options-search"
          v-model="timezoneSearchText"
          placeholder="Search timezones..."
          data-test-id="change-timezone-search"
        />

        <div class="timezone-reset-wrapper">
          <ar-link-button
            class="timezone-reset-button"
            :class="!timezoneChanged ? 'disabled' : ''"
            text="Reset"
            :has-underline="true"
            :color="$arStyle.color.$purple500"
            @click="handleTimezoneReset"
            data-test-id="change-timezone-reset"
          />
        </div>

        <div class="timezone-options-list-wrapper" ref="timezoneOptions">
          <template v-for="(timezone, index) in filteredOptions">
            <ar-text
              :key="index"
              class="timezone-option"
              :class="[
                selectedTimezone.name === timezone.name ? 'active' : '',
                arrowCounter === index ? 'hover' : '',
              ]"
              :weight="selectedTimezone.name === timezone.name ? 'bold' : 'normal'"
              size="14px"
              :text="timezone.name"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              @click.native="handleSelect(timezone)"
              :data-test-id="`change-timezone-option-${timezone.name}`"
            />     
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
export default {
  name: 'ChangeTimezoneDropdown',
  components: {
    ArrowHead,
  },
  props: {
    selectedTimezone: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    }
  },
  model: {
    prop: 'selectedTimezone',
    event: 'selection',
  },
  data() {
    return {
      timezoneSearchText: '',
      optionsOpen: false,
      filteredOptions: null,
      arrowCounter: -1,
    }
  },
  watch: {
    timezoneSearchText() {
      this.filterOptions(this.timezoneSearchText)
    },
    filteredOptions() {
      this.arrowCounter = -1
    }
  },
  computed: {
    initialEventTimezone() {
      return this.$store.getters['event/currentEventTimezone']
    },
    timezoneChanged() {
      return this.initialEventTimezone !== this.selectedTimezone.value
    }
  },
  methods: {
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--
        this.scrollOptions()
      }
    },
    onArrowDown() {
      if (this.filteredOptions.length === 0) {
        return
      }

      if (this.arrowCounter < this.filteredOptions.length - 1) {
        this.arrowCounter++
        this.scrollOptions()
      }
    },
    scrollOptions() {
      let optionsContainer = this.$refs.timezoneOptions
      let optionHeight = optionsContainer.children[0].clientHeight
      optionsContainer.scrollTop = optionHeight * (this.arrowCounter - 3)
    },
    onEnter() {
      let selected = this.filteredOptions[this.arrowCounter]
      if (!selected) {
        this.$refs.timzoneSearchInput.$el.children[0].children[1].click()
        return
      }
      this.$emit('selection', selected)
      this.optionsOpen = false
      this.timezoneSearchText = ''
      this.$nextTick(() => {
        let wrapper = document.querySelector('.change-timezone-wrapper')
        wrapper.focus()
      })
    },
    onEsc() {
      if (!this.optionsOpen) {
        this.$emit('close')
        return
      }

      if (!!this.timezoneSearchText.length) {
        this.timezoneSearchText = ''
      } else if (!this.timezoneSearchText.length) {
        this.optionsOpen = false
        this.$nextTick(() => {
          let wrapper = document.querySelector('.change-timezone-wrapper')
          wrapper.focus()
        })
      }
    },
    onSpace() {
      if (!this.optionsOpen) {
        this.optionsOpen = true
        this.$refs.timzoneSearchInput.$el.children[0].children[1].click()
      }
    },
    handleTimezoneReset() {
      if (!this.timezoneChanged) return
      this.$emit('selection', {
        name: this.initialEventTimezone.split("_").join(" "),
        value: this.initialEventTimezone,
      })
      this.optionsOpen = false
    },
    filterOptions(text) {
      this.filteredOptions = this.options.filter(item => {
        return this.$arFuzzySearch(item.name, text)
      })
    },
    handleSelect(timezone) {
      this.$emit('selection', timezone)
      this.optionsOpen = false
      this.timezoneSearchText = ''
    },
    toggleOptions() {
      this.optionsOpen = !this.optionsOpen
      this.optionsOpen && this.$refs.timzoneSearchInput.$el.children[0].children[1].click()
    },
    handleBack() {
      this.timezoneSearchText = ''
      this.$emit('close')
    },
  },
  mounted() {
    this.filteredOptions = this.options
  }
}
</script>
<style lang="scss" scoped>

@mixin flexRow {
  display: flex;
  flex-flow: row nowrap;
}

.change-timezone-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 374px;

  &:focus {
    outline: none;
  }

  .change-timezone-header-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ui-px2em(12) ui-px2em(14) ui-px2em(10);

    .back-wrapper {
      @include flexRow;
      align-items: center;
      justify-content: flex-start;

      .back-arrowhead {
        transform: rotate(90deg);
        margin-right: 8px;
      }

      .change-timezone-back-button {
        pointer-events: none;
      }
    }

    .change-timezone-copy {
      color: $blueGrey800;
    }
  }

  .timezone-dropdown-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
    border-radius: 4px;
    border: solid ui-px2em(1) #dcdee4;
    width: calc(100% - #{ui-px2em(28)});
    margin-bottom: 10px;
  }

  .timezone-dropdown-options-wrapper {
    width: 100%;

    .timezone-options-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: ui-px2em(6);

      .timezone-options-search {
        width: calc(100% - #{ui-px2em(28)});
        margin-bottom: 8px;
      }

      .timezone-reset-wrapper {
        width: calc(100% - #{ui-px2em(28)});
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
      }

      .timezone-options-list-wrapper {
        max-height: 288px;
        overflow: scroll;
        width: 100%;

        .timezone-option {
          padding: ui-px2em(14) ui-px2em(14) ui-px2em(14) ui-px2em(28);

          &:hover {
            background: #f2edff;
          }

          &.active {
            color: $purple500; 
          }
        }
      }
    }
  }
}

.hover {
  background: #f2edff;
}
</style>