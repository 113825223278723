<template>
  <section>
    <InfoBranch
      v-if="senderEmailAddress"
      :content="senderEmailAddress"
      description="Send from"
      :style="{
        marginTop: '2px',
      }"
    />
    <!--

    ***  all the below are commented  ***
    ***  out for upcoming demo only.  ***

    <InfoBranch
      :content="senderName"
      description="Sender name"
      :style="{
        marginTop: '10px',
      }"
    />
    <InfoBranch
      :content="senderBusinessAddress"
      description="Sender business address"
      :style="{
        marginTop: '10px',
      }"
    />
    -->
    <InfoBranch
      v-if="subject"
      :content="subject"
      description="Subject"
      :style="{
        marginTop: '8px',
      }"
    />
    <!-- <InfoBranch
      v-if="previewText"
      :content="previewText"
      description="Preview text"
      :style="{
        marginTop: '10px',
      }"
    /> -->
  </section>
</template>
<script>
import InfoBranch from '../utils/InfoBranch';
export default {
  name: 'SendEmail',
  components: {
    InfoBranch,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    config() {
      return this.item.config;
    },
    senderEmailAddress() {
      return this.item?.meta?.messageSender?.property;
    },
    senderName() {
      return this.item?.meta?.messageSender?.additionalInfo?.senderName;
    },
    senderBusinessAddress() {
      return this.item?.meta?.messageSender?.additionalInfo?.businessAddress;
    },
    subject() {
      return this.item?.config?.subject;
    },
    previewText() {
      return this.item?.config?.['preview-text'];
    },
  },
}
</script>
<style lang="scss" scoped>
.card-sub-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  .card-sub-details-line {
    width: 7px;
    height: 11px;
    border-bottom: 1px solid $blueGrey500;
    border-left: 1px solid $blueGrey500;
    margin-left: 8px;
    margin-right: 10px;
  }
}
</style>