<template>
  <div
    class="settings-billing"
    v-infinite-scroll="fetchMorePaymentInvoices"
    infinite-scroll-disabled="disableLoadMorePaymentInvoices"
    :infinite-scroll-distance="10"
  >
    <am2-add-payment-method-modal
      :is-show="showAddPaymentSourceModal"
      @create="handlePaymentMethodCreate"
      @cancel="showAddPaymentSourceModal = false"
    />
    <div class="details-group">
      <ar-text
        size="md"
        text="Payment Methods"
      />

      <div v-if="hasFetchedPaymentCustomer" class="payment-card-list">
        <am2-payment-method-card
          v-for="(paymentMethod, idx) in paymentSources"
          :payment-method="paymentMethod"
          :payment-subscriptions="paymentSubscriptionList"
          :is-default="paymentMethod.oid === primaryPaymentSourceOid"
          :key="idx"
          :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'xs' : 'regular'"
          @paymentSourceUpdated="handlePaymentSourceChange"
        />
        <div :class="[
            'payment-add-card',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
          ]"
          @click="handleAddCardClick"
        >
          <ar-link-button
            icon-text-distance="32px"
            icon-name="plus"
            :icon-props="{
              width: '20px',
              height: '20px',
              wrapperStyle: {
                display: 'inline-flex',
                borderRadius: '50%',
                border: `3px solid ${$arStyle.color.purple200}`,
                padding: '6px',
              },
            }"
            text="Add a new card"
            :text-props="{
              size: 'sm',
              weight: 'bold',
            }"
            color="purple"
            :has-underline="false"
            :style="{
              pointerEvents: 'none',
            }"
          />
        </div>
      </div>
      <div v-else class="payment-card-list">
        <am2-card-skeleton
          show-as="list"
          :style="{
            height: '81px',
          }"
        />
      </div>

    </div>


    <ar-text
      v-if="paymentInvoices && paymentInvoices.length > 0"
      size="md"
      text="Invoices"
      :style="{
        marginTop: '48px'
      }"
    />
    <div
      v-if="paymentInvoices && paymentInvoices.length > 0"
      class="invoice-section"
    >
      <am2-invoice-card
        v-for="(paymentInvoice, paymentInvoiceIdx) of paymentInvoices"
        :key="paymentInvoiceIdx"
        :payment-invoice="paymentInvoice"
        :show-as="$arMediaQuery.pageContent.maxWidth('xs') ? 'tiles' : 'list'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { countryNameMap } from '@/utils/countries';

export default {

  data() {
    return {
      showAddPaymentSourceModal: false,
      disableLoadmorePaymentInvoices: false,
    };
  },

  computed: {
    ...mapState({
      hasFetchedPaymentCustomer: state => state.payment.hasFetchedPaymentCustomer,
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      paymentSources: state => state.payment.paymentSources,
      paymentInvoices: state => state.payment.paymentInvoices,
      isFetchingPaymentInvoices: state => state.payment.isFetchingPaymentInvoices,
      hasNoMorePaymentInvoices: state => state.payment.hasNoMorePaymentInvoices,
      hasFetchPaymentInvoicesFailed: state => state.payment.hasFetchPaymentInvoicesFailed,
    }),
    ...mapGetters({
      primaryPaymentSource: 'payment/primaryPaymentSource',
    }),
    disableLoadMorePaymentInvoices() {
      return this.isFetchingPaymentInvoices || this.hasNoMorePaymentInvoices || this.hasFetchPaymentInvoicesFailed;
    },
    primaryPaymentSourceOid() {
      return this.primaryPaymentSource ? this.primaryPaymentSource.oid : null;
    },
  },

  async mounted() {
    this['payment/FETCH_PAYMENT_CUSTOMER']();
    this['payment/FETCH_MORE_PAYMENT_INVOICES']({ reload: true });
    this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']({});
  },

  methods: {
    ...mapActions([
      'payment/FETCH_PAYMENT_CUSTOMER',
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'payment/FETCH_MORE_PAYMENT_INVOICES',
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'payment/SET_PAYMENT_CUSTOMER',
    ]),

    async fetchMorePaymentInvoices() {
      await this['payment/FETCH_MORE_PAYMENT_INVOICES']({});
    },
    handlePaymentMethodCreate() {
      this.showAddPaymentSourceModal = false;
      this['payment/FETCH_PAYMENT_CUSTOMER']();
    },

    handlePaymentSourceChange() {
      this['payment/FETCH_PAYMENT_CUSTOMER']();
    },

    async handleAddCardClick() {
      this.showAddPaymentSourceModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-billing {
  max-width: 800px;
  margin: 59px auto 0;

  .details-group {

    .payment-card-list {
      border: 1px solid $skyBlueGrey500;
      border-radius:5px;
      overflow: hidden;
      margin-top: 24px;

      .payment-add-card {
        display: flex;
        background-color: #FFF;
        transition: background-color 0.2s;
        padding: 22px;
        min-height: 85px;
        cursor: pointer;

        &:hover {
          background-color: $purple100;
        }

        &.xs-max {
          padding: 14px;
        }
      }

      .payment-method-card {
        border-bottom: 1px solid $skyBlueGrey500;
      }
    }




    .details-section {
      display: flex;
      margin-top: 28px;

      .details-section-content {
        display: flex;
        width: 50%;
        align-items: flex-start;
        flex-flow: column;

        .details-section-content-info {
          flex-grow: 1;
          margin-top: 24px;
        }

        .details-section-content-button {
          margin-top: 24px;
        }
      }

      &.xs-max {
        flex-direction: column;

        .details-section-content {
          margin-bottom: 24px;
        }
      }
    }
  }

  .invoice-section {
    margin-top: 26px;
    border: 1px solid $skyBlueGrey500;
    border-radius:5px;
    overflow:hidden;

    .invoice-card:not(:last-child) {
      border-bottom: 1px solid $skyBlueGrey500;
    }
  }
}
</style>
