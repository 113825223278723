<template>
  <!-- This page supports infinite scroll -->
  <section
    class="messages-page-wrapper"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="isFetchMoreMessagesDisabled"
    :infinite-scroll-distance="10"
  >

    <div
      class="content"
    >
      <am2-top
        :dropdown="messageTypeDropdown"
        :button="newMessageButton"
        :search="{
          action: serverSideSearch,
          placeholder: 'Search messages',
          value: searchString,
          style: {
            width: searchBoxWidth
          }
        }"
        title="Messaging"
        :class="[
          'top-wrapper',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
      />
      <ar-divider
        v-if="$arMediaQuery.pageContent.minWidth('md')"
        :style="{
          marginTop: '30px',
        }"
      />
      
      <template>
        <am2-message-preview-modal
          v-if="!!displayMessageData"
          data-test-id="message-preview-modal-from-list"
          :opened="displayMessagePreviewModal"
          :message="displayMessageData"
          :message-list="displayMessageData.messageListOid"
          :provider="displayMessageData.provider"
          :email-template="savedEmail"
          :message-body="currentSelectedMessageMessageBody"
          :subject="displayMessageData.meta.messageBody.subject"
          :preview-text="displayMessageData.meta.messageBody.previewText"
          :scheduled-at="displayMessageData.scheduledAt"
          :sender-name="currentMessageSenderName"
          @close="displayMessagePreviewModal = false"
          :facebook-page-name="currentSelectedMessageFacebookPageName"
          :facebook-page-image-src="currentSelectedMessageFacebookPageImageSrc"
        />
      </template>
      <am2-messages-table
        v-if="isFetchingMessages || messages.length > 0"
        :head="tableHeadings"
        :messages="messages"
        :loading="isFetchingMessages && messages.length === 0"
        has-sticky-header
        :style="{
          marginTop: '28px',
        }"
        @cloneMessage="handleMessageAdded"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
      <am2-upgrade-plan-section
        v-if="!isMessageFeatureEnabled"
        class="upgrade-plan-section"
        feature-type="message-center"
        design="column"
        :style="{
          marginTop: '28px',
        }"
        @buttonClick="() => { $router.push('/plans') }"
      />
      <div
        v-else-if="noMessages"
        :style="{
          marginTop: '28px',
        }"
      >
        <am2-no-content-section
          v-if="hasSearchConditions"
          header="No messages found"
          header-size="sm"
        />
        <am2-no-content-section
          v-else
          icon-name="ar-message-in-circle"
          header="Create a message"
          body="Send your first email or SMS message"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Messages',
  layout: 'default',

  data: () => ({
    searchString: '',
    enableEmailSending: process.env.arEnableEmailSending,
    activeFilter: 'all',
    displayMessagePreviewModal: false,
    displayMessageData: null,
    filterOptions: [
      {
        name: 'All messages',
        count: 0,
        key: 'all'
      },
      {
        name: 'Sent',
        count: 0,
        key: 'completed',
      },
      {
        name: 'Sending',
        count: 0,
        key: 'in-progress',
      },
      {
        name: 'Drafts',
        count: 0,
        key: 'draft',
      },
      {
        name: 'Scheduled',
        count: 0,
        key: 'scheduled',
      },
      {
        type: 'divider',
      },
      {
        name: 'Archived',
        count: 0,
        key: 'archived',
      },
    ],
  }),

  computed: {
    ...mapState({
      messages: state => state.message.messages,
      isFetchingMessages: state => state.message.isFetchingMessages,
      isNoMoreMessages: state => state.message.isNoMoreMessages,
      hasFetchMessagesFailed: state => state.message.hasFetchMessagesFailed,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      savedEmail: state => state.message.savedEmail,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    noMessages() {
      return this.messages.length === 0 && this.isNoMoreMessages;
    },

    hasSearchConditions() {
      return this.activeFilter !== 'all' || !!this.searchString;
    },

    isFetchMoreMessagesDisabled() {
      return this.isNoMoreMessages || this.hasFetchMessagesFailed || this.isFetchingMessages;
    },

    searchBoxWidth() {
      if (this.$arMediaQuery.pageContent.minWidth('md')) {
        return '330px';
      } else if (this.$arMediaQuery.pageContent.minWidth('sm')) {
        return 'calc(100% - 160px)'
      } else {
        return 'calc(100% - 80px)'
      }
    },

    isMessageFeatureEnabled() {
      return this.isFeatureEnabled(['messages']);
    },

    newMessageButton() {
      const options = [];

      if (this.enableEmailSending) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleNewEmailMessageClick,
        });
      }

      options.push({
        name: 'SMS',
        icon: {
          name: 'sms',
          color: this.$arStyle.color.sms,
          height: '19px',
          width: '18px',
          wrapperStyle: {
            width: '19px',
          }
        },
        action: this.handleNewSmsMessageClick,
      });

      return {
        text: this.$arMediaQuery.pageContent.minWidth('sm') ? 'New Message' : 'New',
        type: 'split',
        iconDistance: '12px',
        classes: ['top-create-button'],
        iconName: "dialog",
        toggleDropdownOnButtonClick: true,
        options,
        // there's no default action when one clicks 'New Message'
        action: () => null,
      };
    },

    messageTypeDropdown() {
      return {
        activeItemKey: this.activeFilter,
        items: this.filterOptions.map((o, index) => {
          if(o.name) {
            return {
              ...o,
              action: () => this.setMessageFilter(o.key),
              style: {
                fontWeight: this.activeFilter === o.key ? 'bold' : 'normal'
              },
            };
          } else {
            return {...o};
          }
        }),
      };
    },

    tableHeadings() {
      return [{
          name: 'Message',
          key: 'message',
          format: 'message',
        },
        {
          name: 'Opened',
          key: 'opens',
          format: 'opens',
        },
        {
          name: 'Clicked',
          key: 'clicks',
          format: 'clicks',
        },];
    },

    /** 
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentMessageSenderName() {
      if (this.currentMessageSender && this.currentMessageSender.additionalInfo && this.currentMessageSender.additionalInfo.senderName) {
        return this.currentMessageSender.additionalInfo.senderName;
      } else if (this.currentMessageSender && this.currentMessageSender.meta && this.currentMessageSender.meta.email && this.currentMessageSender.meta.email.senderName) {
        return this.currentMessageSender.meta.email.senderName;
      } else if (this.currentMessageList) {
        const messageListPromoterPropertyOid = this.currentMessageList && this.currentMessageList.meta.email ? this.currentMessageList.meta.email.promoterPropertyOid : null;
        const sender = this.messageSenders.find( item => item.oid === messageListPromoterPropertyOid);
        return sender && sender.additionalInfo ? sender.additionalInfo.senderName : '';
      } else {
        return null;
      }
      return null;
    },

    /** 
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageImageSrc() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.imageSrc) {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      }
    },

    /** 
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageMessageBody() {
      if (this.displayMessageData.provider === 'email') {
        return null;
      }
      return this.displayMessageData?.meta.messageBody;
    },

    /** 
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageName() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.pageName) {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      }
    },

    /** 
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageMessageBody() {
      if (this.displayMessageData.provider === 'email') {
        return null;
      }
      return this.displayMessageData?.meta.messageBody;
    },
  },

  created() {
    this['message/RESET_MESSAGES']();
    this.reloadData();
  },

  beforeDestroy() {
    this['message/RESET_MESSAGES']();
    this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
  },

  methods: {
    ...mapActions([
      'message/FETCH_MORE_MESSAGES',
      'SHOW_CONFIRM',
      'message/FETCH_SAVED_EMAIL_TEMPLATE',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
    ]),
    ...mapMutations([
      'message/RESET_MESSAGES',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
    ]),

    setMessageFilter(keyword) {
      this.activeFilter = keyword;
      this.reloadData();
    },

    async handleMessageAdded(message) {
      /**
       * New message can be in any kind, so let's reset filter in case
       * our promoters not see it.
       */
      this.setMessageFilter('all');

      // Let's scroll to top to see the new message
      window.scrollTo(0, 0);
    },

    async handleMessageCancelled(message) {
      this.reloadData();
    },

    async createNewEmail() {
      if(this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push(`/message-center/messages/email/new/basic`);
      }
    },

    createNewMessage(type) {
      if (type === 'sms') {
        this.$router.push(`/message-center/messages/sms/new`);
      }
    },

    async loadMoreData() {
      const status = this.activeFilter === 'all' ? null : this.activeFilter;

      this['message/FETCH_MORE_MESSAGES']({
        status,
        search: this.searchString,
      });
    },

    async reloadData() {
      const status = this.activeFilter === 'all' ? null : this.activeFilter;
      this['message/FETCH_MORE_MESSAGES']({
        status,
        search: this.searchString,
        reload: true,
      });
    },

    async serverSideSearch(search) {
      this.searchString = search;
      this.reloadData();
    },

    handleNewEmailMessageClick() {
      this.createNewEmail();
    },

    handleNewSmsMessageClick() {
      this.createNewMessage('sms');
    },

    handleSearchKeywordChange(keyword) {
      this.searchString = keyword;
      this.serverSideSearch();
    },
    
    openMessagePreviewModal(data) {
      if (data.provider == 'email') {
        this['message/FETCH_SAVED_EMAIL_TEMPLATE'](data.meta.templateResourceOid);
        this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
          oid: data.meta.email.promoterPropertyOid,
          allowDeleted: true,
        });
      }
      this.displayMessageData = data
      this.displayMessagePreviewModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-page-wrapper {
  display: flex;
  .content {
    transition: 0.3s all;
    margin-left: 0;
    width: 100%;
    margin-bottom: 50px;

    .top-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 50px 0 0;

      &.sm-max {
        margin: 35px 0 0;
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
      }
    }
  }
}
</style>
