<template>
  <section class="dialog-select">
    <div
      ref="items-section"
      class="items-section"
      :style="{
        maxHeight: itemsSectionMaxHeight,
      }"
    >
      <template
        v-if="items.length > 0"
      >
        <div
          v-for="(item, idx) of items"
          :key="idx"
          class="tag-option"
          :data-test-id="`tag-option-${item.name}`"
          @click="(event) => handleItemClick(idx, event)"
        >
          <div
            class="name"
          >
            {{ item.name | capitalize }}
          </div>
          <div v-if="showTagCount" class="count">
            {{ item.count | comma-separated }}
          </div>
        </div>
      </template>
      <div
        v-else-if="items.length === 0 && inputValue && allowNewValues"
        class="tag-option add-new"
        @click="handleAddNew"
      >
        <ar-icon name="circle-plus" style="margin-right: 15px"/>
        <ar-text
          text="Create new tag"
          size="sm"
          class="text"
        />
        <ar-text
          v-bind:text="inputValue | capitalize"
          size="sm"
          class="text"
          weight="bold"
        />
        <span class="input"> </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TagOptionsBox',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: String,
      default: '300px',
    },
    inputValue: {
      type: String,
      default: null,
    },
    allowNewValues: {
      type: Boolean,
      default: true,
    },
    showTagCount: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    displayedItemsNumber() {
      return this.items.length;
    },
    itemsSectionMaxHeight() {
      return `calc(${this.maxHeight} - 65px)`;
    },
  },
  methods: {
    resetScroll() {
      this.$refs['items-section'].scrollTop = 0;
    },
    handleItemClick(idx, event) {
      this.$emit('select', this.items[idx].name);
      event.stopPropagation();
    },
    handleAddNew() {
      this.$emit('select', this.inputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-select {
  width: 100%;
  position: relative;
  display: inline-block;
  background: white;
  border: 1px solid $skyBlueGrey500;
  border-radius: 5px;
  overflow: hidden !important;
  /* padding: 10px 0px; */

  .items-section {
    overflow: auto;

    .tag-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;

      &:not(.add-new) {
        height: 40px;
        justify-content: space-between;
      }

      &.add-new {
        height: 60px;
        color: $purple500;

        .text {
          color: $purple500;
          margin-right: 4px;
        }

        .input {
          font-weight: bold;
        }
      }

      .name {
        color: $blueGrey800;
      }

      .count {
        color: $skyBlueGrey700;
      }

      &:hover {
        cursor: pointer;
        background-color: $purple100;

        .name {
          color: $purple500;
        }
      }
    }
  }
}
</style>
