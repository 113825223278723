<template>
  <div class="automations-listing-dropdown-wrapper" v-click-outside="handleDropdownClickOutside">
    <div class="heading-dropdown-button-wrapper" @click="toggleDropdown" data-test-id="automations-listing-heading-dropdown-button">
      <ar-link-button
        class="automations-listing-heading-dropdown-button"
        :text="selectedListType.name"
        :has-underline="false"
        :color="$arStyle.color.$purple500"        
      />
      <ar-icon
        width="10px"
        name="arrow"
        class="icon-arrow"
        :rotate="dropdownOpen ? 180 : 0"
      />
    </div>
    <div v-show="dropdownOpen" class="automations-listing-dropdown-options-wrapper">
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>
      <template v-for="(option, index) in options">
        <ar-text
          :key="index"
          class="heading-option"
          :class="[isSelectedHeading(option.name) ? 'active' : '']"
          :weight="isSelectedHeading(option.name) ? 'bold' : 'normal'"
          size="xs"
          :text="option.name"
          multiple-lines
          :max-lines="1"
          line-height="20px"
          @click.native="handleHeadingTypeSelect(option)"
          :data-test-id="`automations-listing-${option.key}`"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AutomationListingHeadingDropdown',
  props: {
    selectedListType: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    }
  },
  model: {
    prop: 'selectedListType',
    event: 'selection',
  },
  data() {
    return {
      dropdownOpen: false,
    }
  },
  methods: {
    isSelectedHeading(name) {
      return this.selectedListType.name === name ? true : false
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    handleHeadingTypeSelect(option) {
      this.$emit('selection', option)
      this.dropdownOpen = false
    },
    handleDropdownClickOutside() {
      this.dropdownOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.automations-listing-dropdown-wrapper {
  position: relative;

  .heading-dropdown-button-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .automations-listing-heading-dropdown-button {
      pointer-events: none;
      margin-right: 5px;
    }

    .icon-arrow {
      color: $purple500;
      margin-top: 2px;
    }
  }

  .automations-listing-dropdown-options-wrapper {
    position: absolute;
    display: inline-block;
    width: 200px;
    background: white;
    border-radius: 5px;
    padding: 8px 0;
    margin-top: 7px;
    box-shadow: 0 2px 6px 0 $shadow;
    border: solid 1px $blueGrey500;
    z-index: 90;

    .triangle {
      position: absolute;
      width: 10px;
      top: -5px;
      left: 25px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #90979e;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        transform: translateY(10%);
      }
    }

    .heading-option {
      padding: 8px 15px;
      cursor: pointer;

      &:hover {
        background: $purple100;
        color: $purple500;
      }

      &.active {
        background: $purple100;
        color: $purple500;
      }
    }
  }
}
</style>