<template>  
  <EditableFrame
      class="purchased-product-wrapper"
      title="Edit Trigger"
      :subtitle="item.name"
      has-save-button
      :save-button-disabled="savedButtonDisabled"
      @close="$emit('close')"
      @save="handleSave"
      @click.native="handleClickOutside"
    >
      <ar-text
        class="purchased-product-heading"
        size="13px"
        text="PRODUCTS"
        weight="bold"
        multiple-lines
        :max-lines="1"
        letter-spacing="1px"
      />
      <div class="purchased-product-container">
        <div class="purchased-product-dropdown-wrapper" @click="toggleOptions" data-test-id="add-tag-toggle">
          <ar-text
            class="add-tag-copy"
            size="14px"
            :text="messageListText"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ArrowHead 
            class="add-tag-dropdown-arrowhead"
            :class="listOpen && 'rotate'"
            height="8px" 
            width="10px" 
            fill="#8492a6"          
          />
        </div>
        <div v-if="listOpen" class="purchased-product-search-wrapper">
          <am2-search
            class="purchased-product-search"
            v-model="listSearchText"
            placeholder="Search products..."
            data-test-id="purchased-product-search"
          />
          <div class="products-list-wrapper">
            <div v-if="noMatchingProducts" class="product-link no-product-found-wrapper">
              <div class="item-wrapper">
                <ar-text
                  class="item-name"
                  :style="{ marginRight: '4px' }"
                  size="xs"
                  text="No matching product found"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="normal"
                />
              </div>
            </div>
            <div v-for="product in filteredProductsList" :key="product.oid"  class="product-link-wrapper">
              <a
                class="product-link"
                @click="() => setSelectedProduct(product)"
                :data-test-id="`purchased-product-anchor-${product.oid}`"
              >
                <div class="item-wrapper">
                  <ar-text
                    :class="[
                      'item-name',
                      selectedProduct && product.oid === selectedProduct.oid && 'selected'
                    ]"
                    size="xs"
                    :text="product.title"
                    multiple-lines
                    :max-lines="1"
                    line-height="20px"
                    :weight="selectedProduct && product.oid === selectedProduct.oid ? 'bold' : 'normal'"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PurchasedProduct',
  components: {
    EditableFrame,
    ArrowHead
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedProduct: null,
      filteredProductsList: [],
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    products: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal && !!newVal) {
          this.setItemVals()
        }
        this.filteredProductsList = this.products
      }
    },
    listSearchText() {
      this.filterLists(this.listSearchText)
    }
  },
  computed: {
    ...mapGetters({
      products: 'automation/getProducts'
    }),
    noMatchingProducts() {
      return !this.filteredProductsList.length
    },
    messageListText() {
      if (!this.selectedProduct || !this.products.length) return 'Select a product...'

      return this.selectedProduct.title
    },
    savedButtonDisabled() {
      return !this.selectedProduct
    }
  },
  methods: {
    ...mapActions({
      fetchProducts: 'automation/FETCH_PRODUCTS',
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA',
    }),
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    setSelectedProduct(product) {
      this.selectedProduct = { oid: product.oid, title: product.title, vendor: product.vendor }
      this.listOpen = false
      this.listSearchText = ''
    },
    filterLists(text) {
      let filteredProductsList = []
      let i = 0
      while (i < this.products.length) {
        if (this.$arFuzzySearch(this.products[i].title, text)) {
          filteredProductsList.push(this.products[i])
        }

        i++
      }

      this.filteredProductsList = filteredProductsList
    },
    handleSave() {
      let contextData = {
        config: {
          'promoter-product-oid': this.selectedProduct.oid,
          frontend: {
            title: this.selectedProduct.title,
            vendor: this.selectedProduct.vendor,
          }
        }
      }

      this.updateTriggerContextData({
        id: this.item.id,
        data: contextData
      })
      this.$emit('close')
    },
    handleClickOutside(e) {
      if (!this.listOpen) return
      
      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'purchased-product-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    resetDefaults() {
      this.selectedProduct = null
      this.listOpen = false
      this.listSearchText = ''
    },
    setItemVals() {
      this.resetDefaults()
      if (!this.item.config?.['promoter-product-oid'] || !this.products) return
      let product = this.products.find(product => product.oid === this.item.config['promoter-product-oid'])

      this.selectedProduct = product
    }
  },
  fetch() {
    this.fetchProducts()
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.purchased-product-wrapper {
  width: 100%;
  height: 100%;
  
  .purchased-product-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .purchased-product-container {
    
    .purchased-product-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .purchased-product-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;

      .purchased-product-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .products-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        padding-bottom: 10px;

        .product-link-wrapper {
          width: 100%;
        }

        .product-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-product-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;

            .item-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
  }
}
</style>