import {
  Integration,
  FacebookMessengerIntegration,
  FacebookCustomAudiencesIntegration,
  EventbriteIntegration,
  ShopifyIntegration,
  ZoomIntegration,
  PatreonIntegration,
  EventixIntegration,
  MemberfulIntegration,
  UniverseIntegration,
  StripeIntegration,
  TicketekIntegration,
  MoshtixIntegration,
  EventGeniusIntegration,
  DiceIntegration,
} from '@/api/integration/types';

export function getFacebookMessengerIntegrations(integrations: Integration[]): FacebookMessengerIntegration[] {
  const res: FacebookMessengerIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'facebook' && i.app === 'messenger') {
      res.push(i);
    }
  });
  return res;
};

export function getFacebookCustomAudiencesIntegrations(integrations: Integration[]): FacebookCustomAudiencesIntegration[] {
  const res: FacebookCustomAudiencesIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'facebook' && i.app === 'custom-audiences') {
      res.push(i);
    }
  });
  return res;
};

export function getEventbriteIntegrations(integrations: Integration[]): EventbriteIntegration[] {
  const res: EventbriteIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'eventbrite' && i.app === 'eventbrite') {
      res.push(i);
    }
  });
  return res;
};

export function getShopifyIntegrations(integrations: Integration[]): ShopifyIntegration[] {
  const res: ShopifyIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'shopify' && i.app === 'shopify') {
      res.push(i);
    }
  });
  return res;
};

export function getZoomIntegrations(integrations: Integration[]): ZoomIntegration[] {
  const res: ZoomIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'zoom' && i.app === 'zoom') {
      res.push(i);
    }
  });
  return res;
};

export function getPatreonIntegrations(integrations: Integration[]): PatreonIntegration[] {
  const res: PatreonIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'patreon' && i.app === 'patreon') {
      res.push(i);
    }
  });
  return res;
};

export function getEventixIntegrations(integrations: Integration[]): EventixIntegration[] {
  const res: EventixIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'eventix' && i.app === 'eventix') {
      res.push(i);
    }
  });
  return res;
};

export function getDiceIntegrations(integrations: Integration[]): DiceIntegration[] {
  const res: DiceIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'dice' && i.app === 'dice') {
      res.push(i);
    }
  });
  return res;
};

export function getMemberfulIntegrations(integrations: Integration[]): MemberfulIntegration[] {
  const res: MemberfulIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'memberful' && i.app === 'memberful') {
      res.push(i);
    }
  });
  return res;
};

export function getUniverseIntegrations(integrations: Integration[]): UniverseIntegration[] {
  const res: UniverseIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'universe' && i.app === 'universe') {
      res.push(i);
    }
  });
  return res;
};

export function getStripeIntegrations(integrations: Integration[]): StripeIntegration[] {
  const res: StripeIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'stripe' && i.app === 'stripe') {
      res.push(i);
    }
  });
  return res;
};

export function getTicketekIntegrations(integrations: Integration[]): TicketekIntegration[] {
  const res: TicketekIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'ticketek' && i.app === 'ticketek') {
      res.push(i);
    }
  });
  return res;
};

export function getMoshtixIntegrations(integrations: Integration[]): MoshtixIntegration[] {
  const res: MoshtixIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'moshtix' && i.app === 'moshtix') {
      res.push(i);
    }
  });
  return res;
};

export function getEventGeniusIntegrations(integrations: Integration[]): EventGeniusIntegration[] {
  const res: EventGeniusIntegration[] = [];
  integrations.forEach(i => {
    if (i.provider === 'event-genius' && i.app === 'event-genius') {
      res.push(i);
    }
  });
  return res;
};
