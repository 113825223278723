<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="global-widget-modal"
      :is-open="isOpen"
      header="Campaign widgets"
      hide-footer
      width="1000px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div
        slot="body"
        :class="[
          'body',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <am2-tabs
          :items="tabItems"
          :item-style="{
            width: 'unset',
            marginRight: '30px',
          }"
          @select="handleTabClick"
        />
        <ar-divider
          class="u-margin-bottom-6"
        />
        <div class="widget-title-section">
          <span class="label">
            Widget
          </span>
          <ar-link-button
            v-if="!isCustomizeSectionOpen"
            text="Customize"
            :has-underline="false"
            @click="handleCustomizeButtonClick"
          />
        </div>
        <div
          v-if="!isCustomizeSectionOpen"
          ref="widget-demo-section"
          class="widget-display-section"
        >
          <div class="iframe-wrapper"
              :style="{
                border: widgetType === 'banner' ? 'none' : null,
          }">
            <div
              v-if="widgetType === 'banner' || widgetType === 'button'"
              class="ar-register-button iframe"
              :data-campaign-oid="campaignOid"
              :data-widget-type="widgetType"
              :data-widget-color="widgetColor"
              :data-widget-text="widgetText"
              :data-widget-width="widgetWidth"
              :data-widget-align="widgetAlign"
              :data-signup-form="isSignupForm"
            ></div>
            <a
              v-if="widgetType === 'link'"
              class="ar-register-button link"
              :data-campaign-oid="campaignOid"
              :data-widget-type="widgetType"
              :data-widget-color="widgetColor"
            >
              {{ widgetText }}
            </a>
          </div>
          <div class="copy-code-section">
            <span class="label">
              Add the following code snippet to your html body
            </span>
            <div class="code-section">
              <ar-textarea
                ref="html-code-textarea"
                class="code-demonstration"
                :value="finalCode"
                :rows="finalCodeRows"
                disabled
              />
              <button :class="['copy-button', codeCopied && 'copied']" @click="handleFinalCodeCopyCLick">
                <ar-icon class="copy-success-icon" name="notification-success"
                />
                <span>{{ codeCopied ? 'Copied' : 'Copy' }}</span>
              </button>
            </div>
          </div>
          <ar-state-message
            type="general"
            :style="{
              marginTop: '24px',
              justifyContent: 'center',
            }"
            text="<b>Important:</b> If you’ve already added the widget code, you’ll need to replace the code after making any changes"
            has-icon
            disable-text-color
          />
        </div>
        <am2-card-container v-else class="customize-section">
          <span class="customize-section-title">
            Custom
          </span>
          <div class="customize-field" :style="{ marginBottom: '20px' }">
            <label class="label">Button colour</label>
            <am2-color-input
              class="data-input"
              :disable-alpha="true"
              v-model="editingWidgetColor"
            />
          </div>
          <div
            v-if="widgetType === 'banner' || widgetType === 'button'"
            class="customize-field"
            :style="{ marginBottom: '20px' }"
          >
            <label class="label">Call to action copy</label>
            <ar-input
              class="data-input"
              v-model="editingWidgetText"
              :max-length="33"
            />
          </div>
          <div v-if="widgetType === 'button'" class="customize-field" :style="{ marginBottom: '20px' }">
            <label class="label">Button width</label>
            <ar-input
              class="data-input"
              v-model="editingWidgetWidth"
              v-validate="'cssWidth'"
              :data-vv-name="'editingWidgetWidth'"
              placeholder="Button width, e.g. 70%, 180px, 15rem"
            />
            <ar-state-message
              v-if="veeErrors.has(`editingWidgetWidth`)"
              type="error"
              :text="veeErrors.first(`editingWidgetWidth`)"
              :style="{ marginTop: '10px' }"
            />
          </div>
          <div v-if="widgetType === 'button'" class="customize-field" :style="{ marginBottom: '20px' }">
            <label class="label">Button alignment</label>
            <ar-simple-select
              class="data-input"
              :items="alignItems"
              :default-select-index="selectedAlignIndex"
              @select="handleAlignSelect"
            />
          </div>
          <div class="function-buttons">
            <ar-simple-button
              class="function-button"
              text="Cancel"
              outlined
              @click="handleCancelUpdatingWidgetClick"
            />
            <ar-simple-button
              class="function-button"
              text="Update Widget"
              @click="handleUpateWidgetClick"
            />
          </div>
        </am2-card-container>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { clone } from '@/utils/helpers';
import { copyGenerator } from '@/utils/campaign';


const defaultBtnTextDict = {
  presale: 'Register for Presale Access',
  competition: 'Enter the Competition',
  ballot: 'Enter the Ballot',
  waitlist: 'Register for the Waitlist',
  livestream: 'Register for live stream access',
  donate: 'Register to donate',
  preorder: 'Register to pre-order',
};

export default {
  name: 'CreateCampaignWidgetWidgetModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isSignupForm: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      c2CampaignClientCdnDomain: process.env.arCampaignClientCdnDomain,
      sdkUrl: process.env.arSdkUrl,
      isCustomizeSectionOpen: false,
      tabItems: [
        {
          title: 'Banner',
          value: 'banner',
        },
        {
          title: 'Button',
          value: 'button',
        },
        {
          title: 'Link',
          value: 'link',
        },
      ],
      widgetType: 'banner',
      widgetColor: '',
      editingWidgetColor: '',
      widgetText: '',
      editingWidgetText: '',
      widgetWidth: '',
      editingWidgetWidth: '',
      widgetAlign: '',
      editingWidgetAlign: '',
      codeCopied: null,
      alignItems: [
        {
          name: 'Center',
          value: 'center',
        },
        {
          name: 'Left',
          value: 'left',
        },
        {
          name: 'Right',
          value: 'right',
        },
      ],
    };
  },

  computed: {
    campaignOid() {
      return this.campaign ? this.campaign.oid : null;
    },
    defaultWidgetColor() {
      if (!this.campaign) { return ''; }
      return this.campaign.presentation.color || '';
    },
    defaultWidgetText() {
      if (!this.campaign) { return ''; }
      return this.campaign.settings.customCta || copyGenerator('general', this.campaign.type).defaultCallToActionCopy;
    },
    htmlCode() {
      if (this.widgetType === 'button') {
        return `<div\n  class="ar-register-button ar-widget-${this.campaignOid}"\n  data-campaign-oid="${this.campaignOid}"\n  data-widget-type="${this.widgetType}"\n  data-widget-color="${this.widgetColor}"\n  data-widget-text="${this.widgetText}"\n  data-widget-width="${this.widgetWidth}"\n  data-widget-align="${this.widgetAlign}"\n data-signup-form="${this.isSignupForm}"\n></div>\n`;
      } else if (this.widgetType === 'link') {
        return `<a class="ar-register-button ar-widget-${this.campaignOid}" data-campaign-oid="${this.campaignOid}" data-widget-type="${this.widgetType}" data-widget-color="${this.widgetColor}">\n  ${this.widgetText}\n</a>\n`;
      } else {
        // this is the default for widgetType = 'banner'
        return `<div\n  class="ar-register-button ar-widget-${this.campaignOid}"\n  data-campaign-oid="${this.campaignOid}"\n  data-widget-type="${this.widgetType}"\n  data-widget-color="${this.widgetColor}"\n  data-widget-text="${this.widgetText}"\n></div>\n`;
      }
    },
    scriptCode() {
      if (this.widgetType === 'button') {
        return `<script>\n  (function(id){\n  	var widgets = document.getElementsByClassName("ar-widget-${this.campaignOid}") || [];\n	var withButtonClass = Array.from(widgets).filter(item => item.classList.contains('ar-register-button')).length-1;\n    var widget = document.getElementsByClassName("ar-widget-${this.campaignOid}")[withButtonClass];\n    widget.dataset.campaignOid = "${this.campaignOid}";\n    widget.dataset.widgetType = "${this.widgetType}";\n    widget.dataset.widgetColor = "${this.widgetColor}";\n    widget.dataset.widgetText = "${this.widgetText}";\n    widget.dataset.widgetWidth = "${this.widgetWidth}";\n    widget.dataset.widgetAlign = "${this.widgetAlign}";\n  widget.dataset.signupForm = "${this.isSignupForm}";\n  widget.classList.add('ar-register-button');\n    if (document.getElementById(id)) { return; }\n    var script = document.createElement('script');\n    script.id = id;\n    script.src = "${this.sdkUrl}";\n    script.async = true;\n    document.head.appendChild(script);\n  })('ar-jssdk');\n<\/script>\n`;
      } else if (this.widgetType === 'link') {
        return `<script>\n  (function(id){\n    var widgets = document.getElementsByClassName("ar-widget-${this.campaignOid}") || [];\n    var withButtonClass = Array.from(widgets).filter(item => item.classList.contains('ar-register-button')).length-1;\n    var widget = document.getElementsByClassName("ar-widget-${this.campaignOid}")[withButtonClass];\n    widget.dataset.campaignOid = "${this.campaignOid}";\n    widget.dataset.widgetType = "${this.widgetType}";\n    widget.dataset.widgetColor = "${this.widgetColor}";\n    widget.classList.add('ar-register-button');\n    if (document.getElementById(id)) { return; }\n    var script = document.createElement('script');\n    script.id = id;\n    script.src = "${this.sdkUrl}";\n    script.async = true;\n    document.head.appendChild(script);\n  })('ar-jssdk');\n<\/script>\n`;
      } else {
        return `<script>\n  (function(id){\n    var widgets = document.getElementsByClassName("ar-widget-${this.campaignOid}") || [];\n    var withButtonClass = Array.from(widgets).filter(item => item.classList.contains('ar-register-button')).length-1;\n    var widget = document.getElementsByClassName("ar-widget-${this.campaignOid}")[withButtonClass];\n    widget.dataset.campaignOid = "${this.campaignOid}";\n    widget.dataset.widgetType = "${this.widgetType}";\n    widget.dataset.widgetColor = "${this.widgetColor}";\n    widget.dataset.widgetText = "${this.widgetText}";\n    widget.classList.add('ar-register-button');\n    if (document.getElementById(id)) { return; }\n    var script = document.createElement('script');\n    script.id = id;\n    script.src = "${this.sdkUrl}";\n    script.async = true;\n    document.head.appendChild(script);\n  })('ar-jssdk');\n<\/script>\n`;
      }
    },
    finalCode() {
      return `${this.htmlCode}${this.scriptCode}`;
    },
    selectedAlignIndex() {
      return this.alignItems.findIndex((item) => {
        return item.value === this.editingWidgetAlign;
      });
    },
    finalCodeRows() {
      return (this.finalCode.match(/\n/g) || []).length + 1;
    },
  },

  watch: {
    finalCode() {
      this.codeCopied = null;
    },
    defaultWidgetColor: {
      handler: function (val) {
        this.widgetColor = val;
      },
      immediate: true,
    },
    defaultWidgetText: {
      handler: function (val) {
        this.widgetText = val;
      },
      immediate: true,
    },
    isOpen(val) {
      if (val) {
        this.initializeWidgetSettings();
      }
    },
  },

  methods: {
    async initSdk() {
      return new Promise((resolve, reject) => {
        ((id) => {
          if (document.getElementById(id)) { resolve(); }
          var script = document.createElement('script');
          script.id = id;
          script.src = this.sdkUrl;
          script.async = true;
          document.head.appendChild(script);
          script.onload = function () {
            resolve();
          };
          script.onerror = function (error) {
            reject(error);
          };
        })('ar-jssdk');
      })
    },
    async initializeWidgetSettings() {
      this.isCustomizeSectionOpen = false;
      this.widgetType = 'banner';
      this.widgetWidth = '';
      this.widgetAlign = 'center';
      this.signupForm = this.isSignupForm;

      try {
        await this.initSdk();
        this.renderWidgetRegisterButton();
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to load campaign widget sdk',
        });
      }
    },
    renderWidgetRegisterButton() {
      if (!ARSdk) {
        return;
      }
      /**
       * Have to wait a bit, until rerendering is done,
       * if you want to know what ARSdk does here, check
       * https://bitbucket.org/audiencerepublic/widget-sdk/src/master/
       */
      setTimeout(() => {
        ARSdk.renderRegisterButton(this.$refs['widget-demo-section']);
      }, 200);
    },
    handleAlignSelect(item) {
      this.editingWidgetAlign = item.value;
    },
    handleCancelUpdatingWidgetClick() {
      this.editingWidgetColor = this.widgetColor;
      this.editingWidgetText = this.widgetText;
      this.editingWidgetWidth = this.widgetWidth;
      this.editingWidgetAlign = this.widgetAlign;
      this.isCustomizeSectionOpen = false;

      this.renderWidgetRegisterButton();
    },
    async handleUpateWidgetClick() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      this.widgetColor = this.editingWidgetColor;
      this.widgetText = this.editingWidgetText;
      this.widgetWidth = this.editingWidgetWidth;
      this.widgetAlign = this.editingWidgetAlign;
      this.isCustomizeSectionOpen = false;

      this.renderWidgetRegisterButton();
    },
    handleCustomizeButtonClick() {
      this.editingWidgetColor = this.widgetColor;
      this.editingWidgetText = this.widgetText;
      this.editingWidgetWidth = this.widgetWidth;
      this.editingWidgetAlign = this.widgetAlign;
      this.isCustomizeSectionOpen = true;
    },
    handleTabClick(item) {
      this.widgetType = item.value;
      this.renderWidgetRegisterButton();
    },
    handleFinalCodeCopyCLick() {
      navigator.clipboard.writeText(this.finalCode);
      this.codeCopied = true;
    },
    handleClose() {
      this.$emit('cancel');
    },
  },
}
</script>

<style lang="scss" scoped>
.global-widget-modal {
  >>> .modal-body {
    background: #F6F8FA;
  }
  .body {
    background: #F6F8FA;
    padding: 30px 40px;

    .widget-title-section {
      display: flex;
      justify-content: space-between;

      .label {
        line-height: 1;
        font-size: 14px;
        color: $skyBlueGrey800;
      }
    }

    .widget-display-section {
      margin-top: 10px;

      .iframe-wrapper {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid $skyBlueGrey500;
        overflow: hidden;
        min-height: 80px;

        .ar-register-button {
          &.iframe {
            width: 100%;
            line-height: 1;
          }
          &.link {
            color: $skyBlueGrey800;
            line-height: 1;
          }
        }
      }

      .copy-code-section {
        margin-top: 24px;

        .label {
          line-height: 1;
          font-size: 14px;
          color: $skyBlueGrey800;
        }

        .code-section {
          border-radius: 4px;
          border: 1px solid $skyBlueGrey500;
          overflow: hidden;
          margin-top: 12px;

          .code-demonstration {
            border: none;
            font-family: monospace;
          }

          .copy-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 51px;
            border: none;
            border-top: 1px solid $skyBlueGrey500;
            outline: none;
            background-color: #F6F8FA;
            cursor: pointer;

            .copy-success-icon {
              display: none;
              color: $green500;
            }

            span {
              color: $blueGrey700;
              font-size: 14px;
              font-weight: bold;
            }

            &.copied {
              .copy-success-icon {
                display: inline-block;
              }
              span {
                color: $green500;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    .customize-section {
      margin-top: 10px;
      padding: 30px;

      .customize-section-title {
        font-weight: bold;
        color: $skyBlueGrey800;
        font-size: 14px;
        margin-bottom: 18px;
      }

      .customize-field {
        .label {
          line-height: 1;
          font-size: 14px;
          color: $skyBlueGrey800;
        }
        .data-input {
          margin-top: 13px;
          width: 100%;
        }
      }

      .function-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .function-button {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
