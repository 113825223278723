<template>
  <section class="dialog-select" data-test-id="filter-dropdown">
    <div
      class="content"
    >
      <div>
        <ar-text
          size="xs"
          text="Filter Ticket Sales"
          weight="bold"
          class="u-margin-bottom-3"
        />
      </div>
      <div v-if="tabs.length > 0" class="tabs-section">
        <am2-elegant-tabs
          :items="tabs"
          :tab-key="tabKey"
          @select="handleTabSelect"
        />
      </div>

      <ar-field
        :error-messages="[veeErrors.first('criteria')]"
      >
        <ar-simple-select
          name="segment-criteria"
          data-test-id="event-filter-criteria"
          class="criteria-dropdown u-margin-bottom-3"
          :style="{
            width: '100%',
            zIndex: this.$arStyle.zIndex.globalHighest,
            height: '40px',
          }"
          :dropdown-style="{
            padding: '8px 0',
            zIndex: this.$arStyle.zIndex.globalHighest,
          }"
          :items="eventOrderByItems"
          :default-select-index="eventSalesSegmentDropdownIndex"
          @select="handleDropdownSegmentCriteria"
          v-validate:hasEventSalesSegment="'required:true'"
          data-vv-as="criteria"
          data-vv-name="criteria"
        />
      </ar-field>

      <ar-field
        v-if="filterByCriteria != 'segments'"
        :error-messages="[veeErrors.first('conditions')]"
      >
        <ar-simple-select
          v-if="eventSalesSegmentDropdownIndex != null"
          name="segment-filter-type"
          data-test-id="event-filter-condition"
          class="condition-dropdown u-margin-bottom-3"
          :style="{
            width: '100%',
            zIndex: this.$arStyle.zIndex.globalHighest,
            height: '40px',
          }"
          :dropdown-style="{
            padding: '8px 0',
            zIndex: this.$arStyle.zIndex.globalHighest,
          }"
          :items="getFilterConditions"
          :default-select-index="filterConditionSelected && filterConditionSelected.index"
          @select="handleDropdownSegmentCondition"
          v-validate:hasEventSalesSegmentCondition="'required:true'"
          data-vv-as="conditions"
          data-vv-name="conditions"
        />
      </ar-field>
      <div
        class="text-fields"
        v-if="filterConditionSelected && filterByCriteria != 'gender' && filterByCriteria != 'tags' && filterByCriteria != 'segments'"
      >
        <ar-field
          v-if="showFilterText"
          class="field"
          :error-messages="[veeErrors.first('filter-text')]"
        >
          <ar-input
            ref="filterText"
            @input="handleSegmentFilterText"
            data-test-id="event-filter-text"
            data-vv-name="filter-text"
            data-vv-as="text"
            :style="{
              width: '100%',
              height: '40px',
            }"
            v-validate:hasEventSalesSegmentText="'required:true'"
          />
        </ar-field>
        <ar-field
          class="field"
          v-if="this.filterByCriteria == 'country' && filterConditionSelected && filterConditionSelected.key == 'is_exactly'"
          :error-messages="[veeErrors.first('country')]"
        >
          <ar-country-select
            data-test-id="event-filter-country"
            value-type="iso"
            :style="{
              width: '100%',
              height: '40px',
            }"
            @select="handleCountrySelect"
            v-validate="'required'"
            data-vv-as="country"
            data-vv-name="country"
            :value="filterCountry"
          />
        </ar-field>
        <div
          v-if="(this.filterByCriteria == 'tags' && filterConditionSelected == null) || (filterConditionSelected && filterConditionSelected.key == 'is_between')"
          class="between_filter"
        >
          <ar-field
            data-test-id="event-filter-start-text"
            class="field"
            :error-messages="[veeErrors.first('filter-start')]"
            :style="{
              width: 'calc(50% - 30px)',
            }"
          >
            <ar-input
              @input="handleSegmentFilterStart"
              data-vv-name="filter-start"
              data-vv-as="Filter Start"
              :style="{
                height: '40px',
              }"
              :value="filterSegmentStart"
              v-validate="'required|integer|isPositiveInt'"
            />
          </ar-field>
          <div class="betweener-dash">
          </div>
          <ar-field
            class="field"
            :error-messages="[veeErrors.first('filter-end')]"
            :style="{
              width: 'calc(50% - 30px)',
            }"
          >
            <ar-input
              @input="handleSegmentFilterEnd"
              data-test-id="event-filter-end-text"
              data-vv-name="filter-end"
              data-vv-as="Filter End"
              :style="{
                height: '40px',
              }"
              :value="filterSegmentEnd"
              v-validate="`required|integer|isPositiveInt`"
            />
          </ar-field>
        </div>
      </div>


      <div class="function-wrapper"
        v-if="filterConditionSelected && filterByCriteria == 'tags'"
      >
        <am2-search
          placeholder="Search tags"
          auto-focus
          class="search"
          @input="handleTagsSearchStringChange"
          data-test-id="event-filter-tags-search"
        />
        <div
          :style="{ minHeight: isFetchingTags ? '16px' : null }"
          class="options-wrapper"
        >
          <div class="loading-bubble-wrapper">
            <am2-loading-bubble v-if="isFetchingTags" />
          </div>
          <div
            :class="{
              'options-content': true,
              disabled: isFetchingTags,
            }"
          >
            <div
              v-for="(item, index) of displayedCandidateItems"
              :key="item.oid"
              class="checkbox-section"
              :data-test-id="`event-filter-tags-checkboxes`"
            >
              <ar-checkbox
                ref="checkbox"
                class="checkbox"
                v-model="itemTickedMap[item.oid]"
                :value="itemTickedMap && itemTickedMap[item.oid]"
                @change="(val) => handleCheckBoxChange(item, val)"
                :data-test-id="`event-filter-tags-checkbox-item-${item.oid}`"
              />
              <div
                class="checkbox-label-section"
                @click="handleCheckboxLabelClick(index)"
                :data-test-id="`event-filter-tags-checkbox-label-${item.oid}`"
              >
                <div class="checkbox-label-first-line">
                  <ar-text
                    size="xs"
                    v-bind:text="item.name || 'Has been removed'"
                    v-tooltip.top="(item.name || 'Has been removed' | capitalize).length > 26 ? {
                      content: item.name || 'Has been removed' | capitalize
                    } : null"
                    :style="{
                      width: 'calc(100% - 60px)',
                      color: !item.name ? $arStyle.color.red500 : null,
                      margin: '0 8px 12px 0',
                    }"
                  />
                  <ar-text
                    v-if="calculateItemStat(item) !== null"
                    size="xs"
                    :text="calculateItemStat(item)"
                    align="right"
                    :style="{
                      width: '60px',
                      color: $arStyle.color.blueGrey700,
                    }"
                  />
                </div>
                <ar-text
                  size="xxxs"
                  :text="getDateString(item.startDate, item.endDate, item.timeZone)"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                  }"
                />
              </div>
            </div>

            <ar-link-button
              v-if="displaySeeLessLink"
              class="show-all see-less"
              text="See less"
              @click="decrementDisplayedItems"
            />
            
            <ar-link-button
              :data-test-id="`event-filter-tags-show-all`"
              v-if="displayShowMoreLink"
              class="show-all"
              :text="`Show more (${tagsNextIncrement}${tagsNextIncrement < candidateItems.length ? `+` : ``})`"
              @click="incrementDisplayedItems"
            />
          </div>
        </div>
      </div>

      <div
        v-if="filterConditionSelected && filterByCriteria == 'segments'"
        class="function-wrapper"
      >
        <am2-search
          ref="search"
          data-test-id="event-filter-search"
          class="filter"
          placeholder="Search segment"
          :enable-clear="false"
          @input="handleSegmentsSearchStringChange"
        />
        <div class="fader" />

        <div
          ref="items-section"
          class="items-section"
          :style="{
            maxHeight: itemsSectionMaxHeight,
          }"
          @mouseleave="handleMouseLeaveItemsSection"
        >
          <template
            v-if="loading"
          >
            <div
              v-for="n in 3"
              :key="n"
              class="item"
              :style="itemStyle"
            >
              <am2-typography-skeleton size="xs" :level="2 * n - 1" />
            </div>
          </template>
          <template v-else-if="items.length > 0">
            <div
              v-for="(item, idx) of items"
              :key="idx"
              @mouseenter="handleMouseEnterItem(idx)"
              ref="dropdownList"
              class="dropdown-item"
              data-test-id="filter-dropdown-segment-list"
            >
              <div
                class="item text"
                :data-test-id="`filter-dropdown-segment-list-item-${idx}`"
                :class="[
                  arrowCounter === idx && 'hover-style',
                  (selectedSavedSegment && selectedSavedSegment.index == idx) && 'hover-style active'
                ]"
                :style="itemStyle"
                @click="() => { handleItemClick(idx); }"
              >
                <ar-text
                  class="typography"
                  :text="item.name"
                  size="xs"
                  v-bind="item.typography"
                />
              </div>
            </div>
          </template>
          <div
            v-else-if="hasSearch && items.length === 0"
            class="item"
            :style="itemStyle"
          >
            <ar-text
              :text="noOptionText"
              size="xs"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="bottom-fader"></div>
      <div
        class="custom-divider"
        :style="itemStyle"
      >
        <ar-simple-button
          data-test-id="event-filter-apply"
          text="Apply"
          :style="{
            width: '101px',
            height: '36px',
          }"
          @click.native="handleFiltering"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { clone } from '@/utils/helpers/';
import { debounce } from "debounce";

const TAG_MINIMUM_DISPLAY = 5;
const TAG_DISPLAY_INCREMENTS = 25;

export default {
  name: 'DialogSelectBox',
  model: {
    prop: 'arrowCounter',
    event: 'updateArrowCounter'
  },
  props: {
    arrowCounter: {
      type: Number,
      default: -1
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabKey: {
      type: String,
      default: null,
    },
    itemStyle: {
      type: Object,
      default: () => {},
    },
    dividerStyle: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: 'Filter by...',
    },
    arrowAlign: {
      type: String,
      default: 'bottom-right',
    },
    arrowDistance: {
      type: String,
      default: '29px',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '200px',
    },
    noOptionText: {
      type: String,
      default: 'No option was found',
    },
    hasFixedFooter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchString: '',
      hoveredItemIdx: -1,
      selectedSavedSegment: null,
      eventSalesSegmentDropdownIndex: null,
      filterConditionSelected: null,
      filterConditionSelectedIndex: null,
      filterCountry: null,
      filterByCriteria: null,
      filterSegmentStart: null,
      filterSegmentEnd: null,
      isFetchingTags: false,
      itemTickedMap: {},
      chosenItems: [],
      tagsCurrentSize: 0,
      tagsNextIncrement: 0,
      newSegmentData: null,
      isFiltering: false,
      candidateItems: [],
      eventOrderByItems:
        [
          {
            name: 'City',
            key: 'city',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'State',
            key: 'state',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Zip/post code',
            key: 'postcode',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Country',
            key: 'country',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Age',
            key: 'age',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Gender',
            key: 'gender',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Tag',
            key: 'tags',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
          {
            name: 'Segment',
            key: 'segments',
            action: (item, index) => this.setEventsFilterCriteria(item.key, index),
          },
        ],
    };
  },
  computed: {
    ...mapState({
      savedSegmentList: state => state.segment.savedSegmentList,
      scratchSegmentInfo: state => state.segment.scratchSegmentInfo,
      scratchSegment: state => state.segment.scratchSegment,
      segmentCriteriaMap: state => state.segment.segmentCriteriaMap,
      auth: state => state.auth,
    }),
    ...mapGetters({
      activeSavedSegment: 'segment/activeSavedSegment',
      bubbleUpFavoriteSegments: 'segment/bubbleUpFavoriteSegments',
      prunedScratchSegment: 'segment/prunedScratchSegmentFilter',
    }),
    items() {
      const sortedFilterList = this.bubbleUpFavoriteSegments(this.savedSegmentList);

      if (sortedFilterList) {
        return sortedFilterList
          .filter(filter => {
            return filter.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
          }).map(filter => ({
            name: filter.name,
            key: filter.oid,
          }));
      }
    },
    verticalAlign() {
      return this.arrowAlign.indexOf('top') > -1 ? 'align-top' : 'align-bottom';
    },
    arrowAlignStyle() {
      switch (this.arrowAlign) {
        case 'top-left':
          return { bottom: '-5px', right: this.arrowDistance, transform: 'rotate(180deg)' };
        case 'top-right':
          return { bottom: '-5px', left: this.arrowDistance, transform: 'rotate(180deg)' };
        case 'bottom-left':
          return { top: '-5px', right: this.arrowDistance };
        case 'bottom-right':
          return { top: '-5px', left: this.arrowDistance };
        default:
          return { top: '-5px', left: this.arrowDistance };
      }
    },
    itemsSectionMaxHeight() {
      if (!this.hasSearch) {
        return `calc(${this.maxHeight} - 20px)`;
      }
      return `calc(${this.maxHeight} - 65px)`;
    },
    getFilterConditions() {
      if (!this.filterByCriteria) {
        return [];
      }

      let predicates = null
      if (this.filterByCriteria === 'gender') {
        predicates = this.segmentCriteriaMap[this.filterByCriteria].data
      } else {
        predicates = this.segmentCriteriaMap[this.filterByCriteria].data.predicates
      }

      return predicates.map((predicate) => {
        return {
          name: predicate.title,
          key: predicate.value,
        }
      })
    },
    displayedCandidateItems() {
      let candidateItems = this.candidateItems.slice(0, this.tagsCurrentSize);

      // Tasks don't store their name in item.name - we need to check meta and get it from there if it exists
      return candidateItems.map(item => {
        if (item.name === 'fan-message' && item?.meta?.messageBody?.subject) {
          item.name = item.meta.messageBody.subject;
        } else if (item.name === 'fan-message' && item?.meta?.messageBody) {
          item.name = item.meta.messageBody;
        }
        return item;
      });
    },
    showFilterText() {
      return this.filterConditionSelected && !(this.filterByCriteria == 'country' && this.filterConditionSelected.key == 'is_exactly') && this.filterConditionSelected.key != 'is_between' && this.filterConditionSelected.key != 'is_known' && this.filterConditionSelected.key != 'is_unknown'
    },
    hasEventSalesSegment() {
      return this.eventSalesSegmentDropdownIndex != null;
    },
    hasEventSalesSegmentCondition() {
      return this.filterConditionSelectedIndex != null;
    },
    hasEventSalesSegmentText() {
      if (this.newSegmentData && this.newSegmentData.data) {
        return this.newSegmentData.data.value || this.newSegmentData.data.values
      }
      return false;
    },
    displaySeeLessLink() {
      return Object.keys(this.itemTickedMap).length !== this.candidateItems.length && this.candidateItems.length > 5 && this.tagsCurrentSize > 5
    },
    displayShowMoreLink() {
      return this.candidateItems.length > this.displayedCandidateItems.length && this.candidateItems.length > 5 && this.tagsNextIncrement != 0
    },

  },

  async created() {
    this.fetchExpectedItemsDebounce = debounce(this.fetchExpectedItems, 200)

    this.tagsCurrentSize = TAG_MINIMUM_DISPLAY;
    this.tagsNextIncrement = Math.min(TAG_DISPLAY_INCREMENTS, this.candidateItems.length)

    this.$validator.extend('isPositiveInt', {
      getMessage: field => `Value must be a number equal or greater than 0`,
      validate: value => {
        return !isNaN(value) && parseInt(value) >= 0;
      },
    });
  },

  watch: {
    async prunedScratchSegment(val) {
      if (val && val.filter && val.filter.conditions.length == 0) {
        if (!this.isFiltering) {
          this.resetFilterDropdownInput();
        }
      }
    },
    
    async searchString(val) {
      this.fetchExpectedItemsDebounce(val);

      this.tagsCurrentSize = TAG_MINIMUM_DISPLAY;
      this.tagsNextIncrement = Math.min(TAG_DISPLAY_INCREMENTS, this.candidateItems.length)
    },
  },

  methods: {
    ...mapMutations([
      'segment/SET_SCRATCH_SEGMENT_BY_SAVED_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_CONDITION',
      'segment/ADD_SCRATCH_SEGMENT_CONDITION',
      'segment/RESET_SCRATCH_SEGMENT',
    ]),

    incrementCurrentItemsOneStep() {
      return Math.min(this.tagsCurrentSize <= 5 ? TAG_DISPLAY_INCREMENTS : this.tagsCurrentSize + TAG_DISPLAY_INCREMENTS, this.candidateItems.length);
    },

    incrementDisplayedItems() {
      this.tagsCurrentSize = this.incrementCurrentItemsOneStep();
      this.tagsNextIncrement = Math.min(this.tagsCurrentSize + TAG_DISPLAY_INCREMENTS, this.candidateItems.length)
    },

    decrementDisplayedItems() {
      var decrementTo = (Math.ceil(this.tagsCurrentSize / TAG_DISPLAY_INCREMENTS) * TAG_DISPLAY_INCREMENTS) - TAG_DISPLAY_INCREMENTS
      this.tagsCurrentSize = Math.max(decrementTo, TAG_MINIMUM_DISPLAY)
      this.tagsNextIncrement = this.incrementCurrentItemsOneStep();
    },
    
    updateArrowCounter(index) {
      if (this.arrowCounter === index) {
        return
      }

      this.$emit('updateArrowCounter', index)
    },
    getHeaderItemStyle(idx) {
      return {
        ...this.itemStyle,
        marginTop: idx === 0 ? '0' : '11px',
      };
    },
    resetScroll() {
      if (this.$refs['items-section']) {
        this.$refs['items-section'].scrollTop = 0;
      }
    },
    handleMouseEnterItem(itemIdx) {
      this.updateArrowCounter(itemIdx)
    },
    handleMouseLeaveItemsSection() {
      this.updateArrowCounter(-1)
    },
    handleTabSelect(tab) {
      this.$emit('tabSelect', tab);
    },
    handleItemClick(idx) {
      this.selectedSavedSegment = {
        index: idx,
        key: this.items[idx].key,
        filter: this.items[idx].filter,
      }
    },
    handleSegmentsSearchStringChange(val) {
      this.searchString = val;
    },
    handleTagsSearchStringChange(val) {
      this.searchString = val;
    },
    handleControlClick(item, control) {
      if (control.closeOnClick) {
        this.$emit('close');
      }
      this.$emit('controlClick', item, control);
    },
    handleCustomFieldClickManager(item, control) {
      this.$emit('close');
      this.$emit('fixedFooterClick');
    },
    handleDropdownSegmentCriteria(val) {
      this.veeErrors.remove('criteria');
      this.eventSalesSegmentDropdownIndex = this.eventOrderByItems.findIndex(item => item.key === val.key);
      this.filterByCriteria = val.key

      if (val.key === "gender") {
        this.filterConditionSelected = null
        this.filterConditionSelectedIndex = null
      } else {
        let key = 'contains'

        if (val.key === 'country') {
          key = 'is_exactly'
        } else if (val.key === 'age') {
          key = 'is_between'
        } else if (val.key === 'tags') {
          key = 'true_to_all'
        }

        this.filterConditionSelected = {
          index: 0,
          key,
        }
        this.filterConditionSelectedIndex = 0
      }

      if (this.segmentCriteriaMap && this.segmentCriteriaMap[val.key]) {
        this.newSegmentData = {
          name: this.segmentCriteriaMap[val.key].resource,
          data: {},
          type: this.segmentCriteriaMap[val.key].type,
        }
      }
      
      this.$nextTick(() => {
        if (this.$refs.filterText) {
          this.$refs.filterText.focus()
        }
      })
    },
    handleDropdownSegmentCondition(val) {
      this.veeErrors.remove('conditions');
      let predicates = null
      if (this.filterByCriteria === 'gender') {
        predicates = this.segmentCriteriaMap[this.filterByCriteria].data
      } else {
        predicates = this.segmentCriteriaMap[this.filterByCriteria].data.predicates
      }

      const index = predicates.findIndex(item => item.value === val.key);

      this.filterConditionSelected = {
        index,
        key: val.key
      }

      this.filterConditionSelectedIndex = index

      // Need to reset the filter segment range every time the condition is changed
      this.filterSegmentStart = null;
      this.filterSegmentEnd = null;
    },
    handleSegmentFilterText (text) {
      if (!this.filterConditionSelected) {
        return null
      }

      if (text) {
        if (this.newSegmentData.type == 'numeric') {
          this.newSegmentData.data = {
            values: [parseInt(text)],
            condition: this.filterConditionSelected.key,
          }
        } else {
          this.newSegmentData.data = {
            value: text,
            condition: this.filterConditionSelected.key,
          }
        }
      } else {
        this.newSegmentData.data = {
          condition: this.filterConditionSelected.key,
        }
      }
      
    },
    handleCountrySelect (country) {
      this.filterCountry = country.name;
      this.newSegmentData.data = {
        value: country.iso,
        condition: this.filterConditionSelected.key,
      }
    },
    
    setEventsFilterCriteria(key, index) {
      this.filterByCriteria = key
    },

    findPurchaseDateInScratchSegment() {
      if (!this.scratchSegment || !this.scratchSegment.filter || !this.scratchSegment.filter.conditions || this.scratchSegment.filter.conditions.length == 0) {
        return null;
      }

      const dateFilterIndex = this.scratchSegment.filter.conditions.findIndex((condition) => {
        return condition.name == "purchaseDatePerEvent" && condition.data.condition == "is_between"
      })
      
      if (dateFilterIndex > -1) {
        return {
          conditions: clone(this.scratchSegment.filter.conditions[dateFilterIndex]),
          index: dateFilterIndex,
        }
      }

      return null;
    },

    async addCondition (criteria, index, filter) {
      await this['segment/ADD_SCRATCH_SEGMENT_CONDITION']({ segmentCriteriaResource: criteria, logic: ['and'] });
      await this['segment/SET_SCRATCH_SEGMENT_CONDITION']({ conditionIndex: index, filterCondition: filter });
    },

    async handleFiltering() {
      const isValid = await this.$validator.validateAll();
      this.isFiltering = true;

      if (isValid) {
        if (this.filterByCriteria == 'segments') {
          if (this.selectedSavedSegment && this.selectedSavedSegment.key) {
            const dateFilter = this.findPurchaseDateInScratchSegment()
            this['segment/SET_SCRATCH_SEGMENT_BY_SAVED_SEGMENT'](this.selectedSavedSegment.key);

            let dateFilterIndex = null;

            if (dateFilter) {
              dateFilterIndex = this.scratchSegment.filter.conditions.length
              await this.addCondition('purchaseDatePerEvent', dateFilterIndex, dateFilter.conditions)
            }

            this.$emit('submit', dateFilterIndex);

            this.$emit('close');
          }
        }
        else if (
          this.filterConditionSelected && this.filterConditionSelected.key &&
          (this.filterConditionSelected.key != "is_between" || (this.filterConditionSelected.key == 'is_between' &&
          this.filterSegmentStart && this.filterSegmentStart != "" &&
          this.filterSegmentEnd && this.filterSegmentEnd != ""))
        ) {
          if (this.filterByCriteria == 'gender') {
            this.newSegmentData.data = this.filterConditionSelected.key
          } else if (this.newSegmentData.data.condition == null || this.newSegmentData.data.condition == undefined) {
            this.newSegmentData.data = {
              condition: this.filterConditionSelected.key,
            }
          } else {
            this.newSegmentData.data.condition = this.filterConditionSelected.key
          }

          if (this.newSegmentData.data.value == undefined) {
            if (this.filterConditionSelected.key == 'is_between' &&
              this.filterSegmentStart && this.filterSegmentStart != "" &&
              this.filterSegmentEnd && this.filterSegmentEnd != ""
            ) {
              this.newSegmentData.data = {
                values: [parseInt(this.filterSegmentStart), parseInt(this.filterSegmentEnd)],
                condition: this.filterConditionSelected.key,
              }
            }
            if (this.filterByCriteria == 'tags') {
              this.newSegmentData.data = {
                values: this.chosenItems.map((item) => item.oid),
                condition: this.filterConditionSelected.key,
              }
            }
          }

          const dateFilter = this.findPurchaseDateInScratchSegment()

          await this['segment/RESET_SCRATCH_SEGMENT']();
          await this.addCondition(this.filterByCriteria, 0, this.newSegmentData);

          let dateFilterIndex = null;

          if (dateFilter) {
            dateFilterIndex = this.scratchSegment.filter.conditions.length
            await this.addCondition('purchaseDatePerEvent', dateFilterIndex, dateFilter.conditions); //await 
          }

          this.$emit('submit', dateFilterIndex);

          this.$emit('close');
        }
      }

      this.isFiltering = false;
    },

    resetFilterDropdownInput () {
      this.newSegmentData = null;
      this.filterSegmentStart = null;
      this.filterSegmentEnd = null;
      this.filterConditionSelected = null;
      this.filterByCriteria = null;
      this.eventSalesSegmentDropdownIndex = null;
      this.chosenItems = [];
      this.itemTickedMap = {};
      this.filterConditionSelectedIndex = null;
      this.filterCountry = null;
      this.selectedSavedSegment = null;
    },

    async fetchExpectedItems(searchString = '') {
      this.isFetchingItems = true;
      const { promoterOid } = this.auth.account;

      const params = searchString != '' ? {filterString: searchString} : {};

      const rows = await this.$api.tags.get(promoterOid, params);
      rows.map(row => ({
        ...row,
        oid: row.oid,
      }));
      this.isFetchingItems = false;
      this.candidateItems = rows;
    },

    calculateItemStat(item) {
      if (item && item.ticketStats) {
        return (item.ticketStats.attendees) ? item.ticketStats.attendees : 0;
      }

      return null;
    },

    handleCheckBoxChange(item, isChecked) {
      if (!this.chosenItems) {
        return null;
      }

      const itemExists = this.chosenItems.findIndex((chosenItem) => {
        return chosenItem.oid === item.oid
      })

      if (itemExists == -1) {
        this.chosenItems = [...this.chosenItems, item];
      } else if (!isChecked) {
        this.chosenItems = this.chosenItems.filter((chosenItem) => { return chosenItem.oid != item.oid })
      }
    },

    handleCheckboxLabelClick(index) {
      this.$refs.checkbox[index].toggle();
    },

    handleSegmentFilterStart (text) {
      if (text) {
        this.filterSegmentStart = text
      } else {
        this.filterSegmentStart = null;
      }
    },

    handleSegmentFilterEnd (text) {
      if (text) {
        this.filterSegmentEnd = text
      } else {
        this.filterSegmentEnd = null;
      }
    },

    getTickedItemMap(values) {
      const itemTickedMap = {};
      if (values) {
        values.forEach(value => {
          itemTickedMap[value] = true;
        });
      }
      return itemTickedMap;
    },

    getDateString(startDateStr, endDateStr, timeZone) {
      if (!startDateStr && !endDateStr && !timeZone) {
        return null;
      }
      let startDate;
      let endDate;
      if (timeZone) {
        startDate = startDateStr ? moment.utc(startDateStr).tz(timeZone) : moment.utc().tz(timeZone);
        endDate = endDateStr ? moment.utc(endDateStr).tz(timeZone) : startDate;
      } else {
        startDate = startDateStr ? moment.utc(startDateStr) : moment.utc();
        endDate = endDateStr ? moment.utc(endDateStr) : startDate;
      }

      const isMultiDay = startDate.day() !== endDate.day();
      const isMultiYear = startDate.year() !== endDate.year();

      if (isMultiDay && !isMultiYear) {
        return (
          startDate.format('D MMM') +
          " — " +
          endDate.format('D MMM YYYY')
        );
      } else if (isMultiYear) {
        return (
          startDate.format('D MMM YYYY') +
          " — " +
          endDate.format('D MMM YYYY')
        );
      }
      return startDate.format('D MMM YYYY');
    },

  },
};
</script>

<style lang="scss" scoped>
.input-mirror {
  // FONT FAMILY CHANGES THE LINE HEIGHT
  padding-top: 3px;
  font-weight: bold;
}
.dialog-select {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  background: white;
  border: 1px solid $skyBlueGrey500;
  border-radius: 5px;

  .content {
    padding: 17px 13px;

    .function-wrapper {
      margin-top: 8px;

      .search {
        margin-top: 10px;
        height: 40px;
      }

      .options-wrapper {
        margin-top: 10px;
        position: relative;
        height: 100px;
        overflow: auto;

        .loading-bubble-wrapper {
          display: flex;
          justify-content: center;
        }

        .options-content {
          transition: all 0.3s;
          opacity: 1;

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }

          .checkbox-section {
            display: flex;
            align-items: flex-start;

            .checkbox {
              width: 20px;
            }

            .checkbox-label-section {
              cursor: pointer;
              width: calc(100% - 28px);
              margin-left: 8px;
              .checkbox-label-first-line {
                margin-top: 4px;
                display: flex;
                justify-content: space-between;
              }
            }
          }

          .show-all {
            display: inline-block;
            margin-top: 7px;
            font-size: 12px;
            line-height: 1em;
            color: $purple500;
            letter-spacing: 0;
            text-decoration: underline;
            cursor: pointer;
          }

          .see-less {
            margin-right: 10px;
          }
        }
      }
    }

    .text-fields {
      .between_filter {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        .betweener-dash {
          display: inline-block;
          border-bottom: 1px solid $blueGrey600;
          width: 20px;
          height: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .triangle {
    position: absolute;
    width: 10px;

    .triangle-outer {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $skyBlueGrey500;
    }

    .triangle-inner {
      position: absolute;
      top: 1px;
      left: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      transform: translateY(10%);
    }
  }

  .tabs-section {
    padding: 0 9px;
    margin-bottom: 11px;
  }

  .bottom-fader {
    height: 16px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0) 100%);;
    position: relative;
    z-index: $zIndexHigh;
    width: calc(100%);
    margin: -9px 0px 0 0px;
  }

  .items-section {
    overflow: auto;
    margin-top: 10px;

    .item {
      display: flex;
      align-items: center;
      padding: 12px 15px;

      .action-icon {
        line-height: 0;
        padding-right: 12px;
      }

      &.divider {
        padding: 0;
        margin: 12px 0;
        border-bottom: 1px solid $skyBlueGrey500;
      }

      &.text {
        display: flex;
        cursor: pointer;

        .icon-section {
          display: flex;
          align-items: center;
          width: 28px;

          .icon {
            width: 16px;
          }
        }

        .typography {
          width: 100%;
          flex-grow: 1;
        }

        .controls-section {
          display: flex;
          flex-flow: row;

          .control-wrapper {
            width: 20px;
            .control {
              display: none;
            }
          }
        }
      }

      &.profile {
        .avatar {
          flex-shrink: 0;
        }
        .info-section {
          overflow: hidden;
          margin-left: 10px;
          .subtitle {
            color: $skyBlueGrey700;
            margin-top: 4px;
          }
        }
      }

      &.action {
        cursor: pointer;
        span {
          color: $purple500;
        }

        .active {
          background: $purple100;
        }

        &:hover {
          background: $purple100;
        }

        .action-name {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .active {
    font-weight: 700;
  }

  .hover-style {
    background: $purple100;

    .typography, .icon {
      color: $purple500;
    }
    .controls-section {
      .control-wrapper {
        .control {
          display: inline-flex;
        }
      }
    }
  }
}



.custom-divider {
  padding: 37px 13px 37px 18px !important;
  border-top: 1px solid $skyBlueGrey500;
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

</style>
