var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"multi-select-modal",attrs:{"is-open":_vm.isShow,"header":_vm.title,"width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[(_vm.description)?_c('p',{class:[
        'description',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-y-3' ]},[_vm._v("\n        "+_vm._s(_vm.description)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
        'modal-content',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[(_vm.subtitle)?_c('am2-heading',{staticClass:"subtitle",attrs:{"type":"h2","size":"xs","title":_vm.subtitle,"weight":"bold"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"checkbox-section",style:({
            marginTop: !_vm.subtitle && !_vm.description ? '1px' : null,
          })},_vm._l((_vm.items),function(item){return _c('div',{key:item.value,staticClass:"checkbox"},[_c('ar-checkbox',{attrs:{"disabled":item.disabled || false,"label":item.name},model:{value:(_vm.selectValueMap[item.value]),callback:function ($$v) {_vm.$set(_vm.selectValueMap, item.value, $$v)},expression:"selectValueMap[item.value]"}}),_vm._v(" "),(!!item.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: item.error && item.error.userErrorString ? item.error.userErrorString : null
              }),expression:"{\n                content: item.error && item.error.userErrorString ? item.error.userErrorString : null\n              }",modifiers:{"top":true}}],staticClass:"u-margin-left-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e()],1)}),0)],1)]),_vm._v(" "),_c('div',{class:[
      'footer',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ],attrs:{"slot":"footer"},slot:"footer"},[(_vm.enableResetButton && _vm.resetDefaultKeys.length && _vm.showResetButton)?_c('ar-simple-button',{style:({ marginRight: 'auto', border: 'none' }),attrs:{"text":"Reset to default","type":"purple","outlined":true,"icon-name":"refresh"},on:{"click":_vm.handleResetClick}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({ width: '130px' }),attrs:{"text":"Save"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }