<template>
  <article class="stats-list">
    <div :class="['stats-content', $arMediaQuery.pageContent.minWidth('sm')&& 'sm-min']">
      <table :class="!hasRowData ? 'empty' : null">
        <thead>
          <tr>
            <th class="label">{{columnNames[0]}}</th>
            <th class="label" :style="{
              minWidth: '75px'
            }">{{columnNames[1]}}</th>
            <th class="value" :style="{
              minWidth: '75px'
            }">{{columnNames[2]}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="left">
              <ar-text
                size="xs"
                :text="displayItem(data[orderId])"
              />
            </td>
            <td :style="{
              minWidth: '75px'
            }">
              <ar-text
                size="xs"
                :text="data[orderId].totalRevenue.displayValue"
              />
            </td>
            <td class="right" :style="{
              minWidth: '75px'
            }">
              <ar-text
                size="xs"
                :text="data[orderId].totalRevenue.displayValue"
                align="right"
              />
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>

<script>
// Code is based from SmallTable.vue
export default {
  name: 'OrderItemsTable',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    heading: {
      type: String,
      default: "Default heading"
    },
    columnNames: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: String,
      default: null,
    },
  },

  methods: {
    displayItem(row) {
      return `${row.totalTickets}x ${row.ticketClass}`
    },
  }
};
</script>

<style lang="scss" scoped>
.stats-list {
  .stats-content {
    overflow:scroll;

    &.sm-min {
      overflow:visible;
    }
    table {
      border-spacing: 0;
      thead {
        border-bottom: 1px solid $skyBlueGrey400;
        th {
          color: $blueGrey700;
          font-size: 14px;
          line-height: 15px;
          font-weight: bold;
          text-align: left;
          padding: 19px 0;
          border-bottom: 1px solid $skyBlueGrey400;
          color: $blueGrey800;

          &.label {
            position: relative;
            width: 100%;
          }
          &.value {
            white-space: nowrap;
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          &.clickable {
            cursor: pointer;
            &:hover {
              background: $purple100;
            }
          }
          td {
            border-bottom: 1px solid $skyBlueGrey400;
            font-size: 14px;
            line-height: 15px;
            color: $blueGrey800;
            padding: 20px 0;

            &.right {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
