var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.videodetails.type === '')?_c('am2-button-group-tabs',{style:({ width: '100%', marginBottom: '25px' }),attrs:{"active-index":_vm.videoGroupButtonsSelectIndex,"items":_vm.groupButtonsSelectItems},on:{"select":_vm.handleGroupButtonsSelect}}):_vm._e(),_vm._v(" "),(_vm.showVideoDropzone && _vm.videodetails.type === '')?_c('am2-dropzone',{attrs:{"file-type":['video/mp4'],"file-size":200000000,"file-type-alias":"video","placeholder-icon":{
      name: 'video',
    },"video-options":{
      duration: 600,
    },"ratio":0.525,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":[{
      assetType: 'campaign-video',
      campaignOid: null,
      eventOid: null,
    }]},on:{"upload":_vm.handleVideoUpload}}):_vm._e(),_vm._v(" "),(_vm.showVideoDropzone && _vm.videodetails.type === '')?_c('span',{staticClass:"field-info"},[_vm._v("Formats: .MP4, Max video size: 10 minutes, 200mb")]):_vm._e(),_vm._v(" "),(_vm.showVideoUrlInput && _vm.videodetails.type === '')?_c('am2-video-external-picker',{on:{"submit":_vm.handleVideoUrlSubmit}}):_vm._e(),_vm._v(" "),(_vm.error !== null)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_vm._v(" "),(_vm.uploading)?_c('p',[_vm._v("Uploading, please do not close this window")]):_vm._e(),_vm._v(" "),(_vm.videodetails.url.length)?_c('am2-media-preview',{on:{"delete":_vm.handleDelete}},[_c('span',{attrs:{"slot":"media-preview__content"},slot:"media-preview__content"},[(_vm.videodetails.type === 'file')?_c('video',{attrs:{"src":_vm.videodetails.url,"controls":"","width":"192"}}):_vm._e(),_vm._v(" "),(_vm.videodetails.type === 'application/vnd.youtube.vt' || _vm.videodetails.type === 'application/vnd.vimeo')?_c('iframe',{staticClass:"video-iframe",attrs:{"src":_vm.videodetails.url,"width":"192","height":"108"}}):_vm._e()]),_vm._v(" "),_c('span',{attrs:{"slot":"media-preview__name"},slot:"media-preview__name"},[_vm._v(_vm._s(_vm.videodetails.name))]),_vm._v(" "),(_vm.videodetails.type === 'file' && _vm.videodetails.size > 0)?_c('span',{attrs:{"slot":"media-preview__size"},slot:"media-preview__size"},[_vm._v(_vm._s((_vm.videodetails.size / 1000000).toFixed(2))+" MB")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }