import VirtualFanImageOne from '@/assets/images/placeholders/virtual_fan_1.jpg';
import VirtualFanImageTwo from '@/assets/images/placeholders/virtual_fan_2.jpg';
import VirtualFanImageThree from '@/assets/images/placeholders/virtual_fan_3.jpg';
import VirtualFanImageFour from '@/assets/images/placeholders/virtual_fan_4.jpg';
import VirtualFanImageFive from '@/assets/images/placeholders/virtual_fan_5.jpg';

export const virtualFans = [
  {
    avatar: VirtualFanImageOne,
    name: 'John Titor',
    total: 13252,
    href: 'spotify.com',
  },
  {
    avatar: VirtualFanImageTwo,
    name: 'Jason Smith',
    total: 10321,
    href: 'spotify.com',
  },
  {
    avatar: VirtualFanImageThree,
    name: 'Drink Water',
    total: 9482,
    href: 'spotify.com',
  },
  {
    avatar: VirtualFanImageFour,
    name: 'Jump Up',
    total: 7321,
    href: 'spotify.com',
  },
  {
    avatar: VirtualFanImageFive,
    name: 'Looking Forward',
    total: 3041,
    href: 'spotify.com',
  },
];