<template>
  <div class="automation-sidebar-element-wrapper">
    <div class="sidebar-header-wrapper">
      <div class="sidebar-link-buttons">
        <ar-text
          data-test-id="sidebar-link-active-triggers"
          class="sidebar-tab-link"
          :class="{ active : selectedSidebarTab === 'triggers'}"
          size="20px"
          text="Triggers"
          weight="bold"
          multiple-lines
          :max-lines="1"
          line-height="40px"
          @click.native="selectSidebarTab('triggers')"
        />
        <ar-text
          data-test-id="sidebar-link-active-actions"
          class="sidebar-tab-link"
          :class="{ active : selectedSidebarTab === 'actions'}"
          size="20px"
          text="Actions"
          weight="bold"
          multiple-lines
          :max-lines="1"
          line-height="40px"
          @click.native="selectSidebarTab('actions')"
        />
      </div>
      <ar-text
        class="sidebar-header-info"
        size="14px"
        :text="`Drag and drop ${infoText} into the canvas.`"
        weight="normal"
        multiple-lines
        :max-lines="1"
        line-height="2.14"        
      />
      <am2-search
        tabindex="0"
        ref="timzoneSearchInput"
        class="sidebar-search"
        v-model="sidebarSearchText"
        :placeholder="`Search ${selectedSidebarTab}`"
        data-test-id="sidebar-search-text"
      />
    </div>
    <div class="sidebar-draggable-wrapper">
      <component
        :is="selectedSidebarTab"
        :options="filteredSidebarOptions"
      />
    </div>
  </div>
</template>
<script>
import Triggers from './AutoTriggers'
import Actions from './AutoActions'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'SidebarElements',
  components: {
    triggers: Triggers,
    actions: Actions,
  },
  data() {
    return {
      sidebarSearchText: '',
      filteredSidebarOptions: null,
    }
  },
  watch: {
    sidebarSearchText() {
      this.filterSidebarOptions(this.sidebarSearchText)
    },
    selectedSidebarTab: {
      immediate: true,
      handler() {
        this.sidebarSearchText = ''
        if (this.selectedSidebarTab === 'triggers') {
          this.filteredSidebarOptions = this.getFilteredTriggerOptions('')
        } else if (this.selectedSidebarTab === 'actions') {
          this.filteredSidebarOptions = this.getFilteredActionOptions('')
        } else {
          this.filteredSidebarOptions = this.getFilteredActionOptions('')
        }
      }
    }
  },
  computed: {
    ...mapState({
      selectedSidebarTab: state => state.automation.selectedSidebarTab
    }),
    triggerItems() {
      return {
        purchase: {
          icon: 'credit-card',
          text: 'Purchase',
          children: [
            {
              key: 'purchased.ticket-to-event',
              name: 'Purchased ticket',
              icon: 'credit-card',
              type: 'trigger',
              config: null,
              meta: {},
            },
            {
              key: 'purchased.product-from-store',
              name: 'Purchased product',
              icon: 'credit-card',
              type: 'trigger',
              config: null,
              meta: {},
            },
          ]
        },
        campaign: {
          icon: 'target',
          text: 'Campaign',
          children: [
            {
              key: 'campaign.registered',
              name: 'Registers for campaign',
              icon: 'target',
              type: 'trigger',
              config: {},
              meta: {},
            },
          ]
        },
        contact: {
          icon: 'contacts',
          text: 'Contact',
          children: [
            {
              key: 'contact.tag.added',
              name: 'Tag added',
              icon: 'contacts',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'contact.tag.removed',
              name: 'Tag removed',
              icon: 'contacts',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'contact.birthday',
              name: 'Birthday',
              icon: 'birthday',
              type: 'trigger',
              config: {},
              meta: {},
            },
          ]
        },
        messaging: {
          icon: 'message',
          text: 'Messaging',
          children: [
            {
              key: 'messaging.list.subscribed',
              name: 'Subscribes to list',
              icon: 'message',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'messaging.list.unsubscribed',
              name: 'Unsubscribes from list',
              icon: 'message',
              type: 'trigger',
              config: {},
              meta: {},
            },
            // {
            //   key: 'messaging.link.visited',
            //   name: 'Clicks a link',
            //   icon: 'message',
            //   type: 'trigger',
            //   config: {},
            //   meta: {},
            // },
          ]
        },
        other: {
          icon: 'other',
          text: 'Other',
          children: [
            {
              key: 'short-url.visited',
              name: 'Clicks on short URL',
              icon: 'other',
              type: 'trigger',
              config: {},
              meta: {},
            },
            ...(!this.isProduction ? [{
              key: 'webhook',
              name: 'Webhook',
              icon: 'other',                            
              type: 'trigger',
              config: {},
              meta: {},
            }] : []),
          ],
        }
      }
    },
    actionItems() {
      return {
        messaging: {
          icon: 'message',
          text: 'Messaging',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'send.email',
                name: 'Send email',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'send.sms',
                name: 'Send SMS',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'list.add',
                name: 'Add to List',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'list.remove',
                name: 'Remove from List',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
        contact: {
          icon: 'contacts',
          text: 'Contact',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'tag.add',
                name: 'Add a Tag',
                icon: 'contacts',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'tag.remove',
                name: 'Remove a Tag',
                icon: 'contacts',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
        conditions: {
          icon: 'branch',
          text: 'Conditions',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'if-else',
                name: 'If/Else',
                icon: 'branch',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'delay-by',
                name: 'Delay by',
                icon: 'clock',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'wait-until',
                name: 'Wait until',
                icon: 'calendar',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'unique',
                name: 'Unique',
                icon: 'fingerprint',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
      }
    },
    isProduction() {
      let subdomain = window.location.hostname.split('.').slice(0, -2).join('.')
      return subdomain === 'app'
    },
    infoText() {
      return this.selectedSidebarTab === 'triggers' ? 'a trigger' : 'an action'
    },
  },  
  methods: {
    ...mapActions({
      setSidebarTab: 'automation/SET_SIDEBAR_TAB',
    }),
    filterSidebarOptions(val) {
      let filteredOptions
      if (this.selectedSidebarTab === 'triggers') {
        filteredOptions = this.getFilteredTriggerOptions(val)
      } else {
        filteredOptions = this.getFilteredActionOptions(val)
      }
      this.filteredSidebarOptions = filteredOptions
    },
    getFilteredTriggerOptions(val) {
      let filteredSidebarOptionsArr = []
      
      let options = Object.entries(this.triggerItems)
      let i = 0
      while (i < options.length) {
        let filteredSidebarOptions = {}
        let key = options[i][0]
        let { icon, text, children } = options[i][1]

        let matchedActions = []
        let j = 0
        while (j < children.length) {
          if (this.$arFuzzySearch(children[j].name, val)) {
            matchedActions.push(children[j])
          }

          j++
        }

        if (!!matchedActions.length) {
          filteredSidebarOptions[key] = {
            icon,
            text,
            children: matchedActions,
          }
          filteredSidebarOptionsArr.push(filteredSidebarOptions)
        }

        i++
      }
      return filteredSidebarOptionsArr
    },
    getFilteredActionOptions(val) {
      let filteredSidebarOptionsArr = []
      
      let options = Object.entries(this.actionItems)
      let i = 0
      while (i < options.length) {
        let filteredSidebarOptions = {}
        let key = options[i][0]
        let { icon, text, children } = options[i][1]

        let matchedActions = []
        let j = 0
        while (j < children.length) {
          if (this.$arFuzzySearch(children[j].block.name, val)) {
            matchedActions.push(children[j])
          }

          j++
        }

        if (!!matchedActions.length) {
          filteredSidebarOptions[key] = {
            icon,
            text,
            children: matchedActions,
          }
          filteredSidebarOptionsArr.push(filteredSidebarOptions)
        }

        i++
      }
      return filteredSidebarOptionsArr
    },
    selectSidebarTab(type) {
      this.setSidebarTab(type)
    }
  },
}
</script>
<style lang="scss" scoped>
.automation-sidebar-element-wrapper {
  width: 100%;
  background: white;
  padding: 32px 12px 0 23px;
  height: 100%;

  .sidebar-header-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .sidebar-link-buttons {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    
    .sidebar-tab-link {
        margin-right: 14px;
        color: $blueGrey800;
        opacity: 0.5;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }
    }
    
    .sidebar-header-info {
      color: $blueGrey700;
      margin-top: -9px;
      margin-bottom: 14px;
    }

    .sidebar-search {
      width: 312px;
      margin-bottom: 33px;
    }
  }

  .sidebar-draggable-wrapper {
    height: calc(100% - 190px);
    overflow: scroll;
  }
}
</style>