var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'payment-method-card',
    _vm.cardExpiryStatus ]},[_c('am2-update-billing-details-modal',{attrs:{"is-show":_vm.displayUpdateBillingDetailsModal,"payment-source":_vm.paymentMethod},on:{"update":_vm.handleBillingDetailsUpdate,"cancel":_vm.handleUpdateBillingDetailsCancel}}),_vm._v(" "),_c('section',{class:[
      'content-section',
      _vm.size ]},[_c('div',{staticClass:"icon-container"},[_c('ar-icon',{attrs:{"name":_vm.getPaymentIconName,"width":"48px","height":"40px"}})],1),_vm._v(" "),_c('div',{staticClass:"details-container"},[_c('ar-text',{attrs:{"size":"xs","text":("Ending in <b>" + _vm.paymentMethodLast4 + "<b>"),"allow-html":""}}),_vm._v(" "),_c('section',{staticClass:"u-display-flex u-margin-top-1"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":_vm.paymentMethodName}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-x-2",style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":"–"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":_vm.niceExpiry}})],1)],1),_vm._v(" "),_c('div',{staticClass:"top-right-fix"},[_c('div',{staticClass:"tag-container"},[(_vm.isDefault)?_c('am2-tag',{attrs:{"type":"green","shape":"rectangle","text":"Default","text-size":"xxxs"}}):_vm._e(),_vm._v(" "),(_vm.cardExpiryStatus === 'expired')?_c('am2-tag',{style:({
            marginLeft: _vm.isDefault ? '8px' : 0,
          }),attrs:{"type":"red","shape":"rectangle","text":"Expired","text-size":"xxxs"}}):_vm._e(),_vm._v(" "),(_vm.cardExpiryStatus === 'expiring-soon')?_c('am2-tag',{style:({
            marginLeft: _vm.isDefault ? '8px' : 0,
          }),attrs:{"type":"red","shape":"rectangle","text":"Expiring Soon","text-size":"xxxs"}}):_vm._e()],1),_vm._v(" "),_c('am2-icon-button-dropdown',{attrs:{"align":"left","items":[
          {
            name: 'Edit card',
            key: 'editCard',
          },
          {
            name: 'Set as default',
            key: 'setDefault',
          },
          {
            name: 'Remove card',
            typography: {
              style: {
                color: _vm.$arStyle.color.red500,
              },
            },
            key: 'deleteCard',
          }
        ]},on:{"select":_vm.actionDropdownSelect}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }