var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ar-field',{directives:[{name:"validate",rawName:"v-validate:hasAnyRequests",value:('required:true'),expression:"'required:true'",arg:"hasAnyRequests"}],attrs:{"label":"Privacy Portal Settings","data-vv-name":"hasAnyRequests","error-messages":[_vm.veeErrors.first('hasAnyRequests')]}},[_c('am2-card-container',{staticClass:"u-padding-x-5 u-padding-y-4"},[_c('div',{class:[
          'u-display-flex',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-flex-flow-column'
        ]},[_c('am2-switch',{staticClass:"u-flex-shrink-0",attrs:{"value":_vm.scratchPrivacyPortal.meta.requestsCompletedBy.fan},on:{"input":_vm.handleCompletedByFanToggle}}),_vm._v(" "),_c('div',{class:[
            'u-flex-grow-1',
            'u-margin-top-2',
            _vm.$arMediaQuery.window.minWidth('sm') && 'u-margin-left-6' ]},[_c('ar-field',{attrs:{"label":"Self service data privacy management","description":"Users can login and manage their data without having to contact you"}})],1)],1)]),_vm._v(" "),_c('am2-card-container',{staticClass:"u-margin-top-3 u-padding-x-5 u-padding-y-4"},[_c('div',{class:[
          'u-display-flex',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-flex-flow-column'
        ]},[_c('am2-switch',{staticClass:"u-flex-shrink-0",attrs:{"value":_vm.scratchPrivacyPortal.meta.requestsCompletedBy.promoter},on:{"input":_vm.handleCompletedByPromoterToggle}}),_vm._v(" "),_c('div',{class:[
            'u-flex-grow-1',
            'u-margin-top-2',
            _vm.$arMediaQuery.window.minWidth('sm') && 'u-margin-left-6' ]},[_c('ar-field',{attrs:{"label":"Manual data privacy request","description":"Users can submit a manual data privacy request for you to action"}},[(_vm.scratchPrivacyPortal.meta.requestsCompletedBy.promoter)?_c('div',[(!_vm.scratchPrivacyPortal.meta.requestsCompletedBy.promoter)?_c('ar-link-button',{attrs:{"text":"Customize request options"},on:{"click":function($event){_vm.scratchPrivacyPortal.meta.requestsCompletedBy.promoter = true}}}):_vm._e(),_vm._v(" "),(_vm.scratchPrivacyPortal.meta.requestsCompletedBy.promoter)?_c('div',{staticClass:"u-display-flex u-flex-flow-column"},[_vm._l((_vm.scratchPrivacyPortal.meta.promoterCompletedRequestOptions),function(requestOption,requestOptionIndex){return _c('div',{key:requestOptionIndex,staticClass:"u-margin-bottom-2 u-display-flex u-align-items-center",style:({
                    height: '30px',
                  })},[_c('ar-checkbox',{attrs:{"value":requestOption.enabled},on:{"input":function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handlePromoterCompletedRequestToggle.apply(void 0, [ requestOptionIndex ].concat( argsArray ))}}}),_vm._v(" "),(_vm.editingManualRequestOptionIdx === requestOptionIndex)?_c('ar-input',{staticClass:"u-margin-left-2",style:({
                      height: '30px',
                    }),attrs:{"value":requestOption.title,"placeholder":"e.g: Correct my personal data","auto-focus":""},on:{"blur":function($event){return _vm.handleRequestOptionBlurOrEnter(requestOptionIndex)},"enter":function($event){return _vm.handleRequestOptionBlurOrEnter(requestOptionIndex)},"input":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleRequestOptionAdd.apply(void 0, [ requestOptionIndex ].concat( argsArray ))}}}):_c('ar-text',{staticClass:"u-margin-left-2 u-flex-grow-1",style:({
                      cursor: 'pointer',
                    }),attrs:{"size":"xs","text":requestOption.title},nativeOn:{"click":function($event){_vm.editingManualRequestOptionIdx = requestOptionIndex}}}),_vm._v(" "),(requestOption.type === 'other')?_c('ar-icon-button',{attrs:{"icon-props":{
                      name: 'discard',
                      height: '16px',
                    }},on:{"click":function($event){return _vm.handleRequestOptionDiscard(requestOptionIndex)}}}):_vm._e()],1)}),_vm._v(" "),(_vm.editingManualRequestOptionIdx === -1)?_c('ar-link-button',{attrs:{"text":"+ Add option"},on:{"click":_vm.handleAddOptionClick}}):_vm._e()],2):_vm._e()],1):_vm._e()])],1)],1)])],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Send Email Notifications To","error-messages":[_vm.veeErrors.first('emailAddress')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"value":_vm.scratchPrivacyPortal.meta.notifications.emailAddress,"data-vv-name":"emailAddress","data-vv-as":"email address","placeholder":"Your email address"},on:{"input":_vm.handleEmailAddressInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Notify me each time there is a"}},[_c('div',[_c('ar-checkbox',{attrs:{"label":"Self service request","value":_vm.scratchPrivacyPortal.meta.notifications.requestsCompletedBy.fan},on:{"input":_vm.handleFanCompletedNotificationInput}})],1),_vm._v(" "),_c('div',{staticClass:"u-margin-top-2"},[_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Email notifications for manual requests cannot be disabled',
        }),expression:"{\n          content: 'Email notifications for manual requests cannot be disabled',\n        }"}],attrs:{"label":"Manual request","disabled":"","value":_vm.scratchPrivacyPortal.meta.notifications.requestsCompletedBy.promoter}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }