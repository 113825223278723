<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <div :style="{ display: 'none' }">
    </div>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { pause } from '@/utils/helpers';
import accounting from 'accounting';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
    }),
    ...mapGetters({
      prunedScratchSegment: 'segment/prunedScratchSegment',
      totalSelectedAudienceCount: 'audience/totalSelectedAudienceCount',
      partlySelectedAudience: 'audience/partlySelectedAudience',
    }),
    appliedFilter() {
      return this.audienceSelection.useScratchSegment ? this.prunedScratchSegment.filter : { conditions: [], logic: [] }
    },
  },

  watch: {
    editValue() {
      this.hasConfirmedEdit = false;
    },
    isOpen(val) {
      if (val) {
        this.openMassDeleteFans();
      }
    },
  },
  methods: {
    ...mapActions([
      'audience/DELETE_AUDIENCE',
      'SHOW_CONFIRM',
    ]),
    startModalAction({ fanOids, totalAudienceCount }) {
      this.fanOids = fanOids;
    },
    async deleteMassFans() {
      let options;
      if (this.audienceSelection.useScratchSegment) {
        options ={
          filter: this.appliedFilter,
          searchString: this.searchString,
          fansCount: this.totalSelectedAudienceCount,
        };
      } else {
        options = {
          fanOids: this.partlySelectedAudience.map(fan => fan.oid),
          fansCount: this.totalSelectedAudienceCount,
        };
      }

      const succeed = await this['audience/DELETE_AUDIENCE'](options);
      if (succeed) {
        this.$emit('delete');
      } else {
        this.$emit('cancel');
      }
    },
    async openMassDeleteFans() {
      const agreed = await this.SHOW_CONFIRM({
        title: `Permanently delete ${accounting.formatNumber(this.totalSelectedAudienceCount)} contact${this.totalSelectedAudienceCount > 1 ? 's' : ''}?`,
        messageHtml: `You're about to delete ${accounting.formatNumber(this.totalSelectedAudienceCount)} contact${this.totalSelectedAudienceCount > 1 ? 's' : ''}. Use the text field below to confirm the number of contacts you want to delete.`,
        validation: {
          question: 'Number of contacts to delete',
          answer: `${this.totalSelectedAudienceCount}`,
        },
        confirmButtonProps: {
          text: 'Delete',
          "data-test-id": 'delete-mass-fans-confirm-button',
          style: {
            width: '108px',
          },
        },
        cancelButtonProps: {
          text: `Cancel`,
          outlined: true,
          type: 'purple',
          style: {
            width: '108px',
          },
        },
        hasCrossIcon: true,
        asyncCompleteFunction: this.deleteMassFans,
      });
      if (!agreed) {
        this.$emit('cancel');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
