<template>
  <div class="settings-profile">
    <div class="input-groups-section">
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="Email address"
          />
        </div>
        <div class="input-section">
          <ar-input
            v-model="editProfileObject.emailAddress"
            v-validate="'required|email'"
            data-vv-name="emailAddress"
            autocomplete="email"
            class="input"
            placeholder="Email address"
          />
          <ar-state-message
            v-if="veeErrors.has('emailAddress')"
            :text="veeErrors.first('emailAddress')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="Name"
          />
        </div>
        <div class="input-section">
          <div
            :style="{
              display: 'flex',
            }"
          >
            <ar-input
              v-model="editProfileObject.firstName"
              v-validate="'required'"
              data-vv-name="firstName"
              placeholder="First name"
              autocomplete="given-name"
              :style="{
                flexGrow: '1',
              }"
            />
            <ar-input
              v-model="editProfileObject.lastName"
              v-validate="'required'"
              data-vv-name="lastName"
              placeholder="Last name"
              autocomplete="family-name"
              :style="{
                flexGrow: '1',
                marginLeft: '40px',
              }"
            />
          </div>
          <ar-state-message
            v-if="veeErrors.has('firstName')"
            :text="veeErrors.first('firstName')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
          <ar-state-message
            v-if="veeErrors.has('lastName')"
            :text="veeErrors.first('lastName')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
      <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
        <div class="label-section">
          <ar-text
            size="xs"
            weight="bold"
            text="Mobile number"
          />
        </div>
        <div class="input-section">
          <ar-mobile-input
            v-model="editProfileObject.mobileNumber"
            v-validate="'required|mobileNumber'"
            data-vv-name="mobileNumber"
            autocomplete="tel-national"
            class="input"
          />
          <ar-state-message
            v-if="veeErrors.has('mobileNumber')"
            :text="veeErrors.first('mobileNumber')"
            type="error"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </div>
    </div>
    <ar-divider
      :style="{
        margin: '70px 0 0',
      }"
    />
    <div
      class="functions-section"
    >
      <ar-simple-button
        text="Save changes"
        :style="{
          width: '170px',
        }"
        @click="handleSaveChangeClick"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      editProfileObject: {
        emailAddress: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
      },
      isEdittingProfile: false,
    };
  },

  computed: {
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          emailAddress: {
            required: 'Email address is required',
            email: 'Please enter a valid email address',
          },
          firstName: {
            required: 'First name is required',
          },
          lastName: {
            required: 'Last name is required'
          },
          mobileNumber: {
            required: 'Mobile number is required'
          },
        },
      },
    });
  },

  methods: {
    ...mapActions([
      'EDIT_PROFILE',
    ]),

    async handleSaveChangeClick() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      try {
        this.isEdittingProfile = true;
        this.EDIT_PROFILE(this.editProfileObject);
        this.$arNotification.push({
          type: 'success',
          message: 'Successfully editted profile',
        });
      } catch (e) {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to edit profile',
        });
      } finally {
        this.isEdittingProfile = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-profile {
  .input-groups-section {
    max-width: 800px;
    margin: 59px auto 0;
    .input-group {
      display: flex;
      margin-bottom: 30px;

      .label-section {
        flex-shrink: 0;
        flex-basis: 210px;
        display: flex;
        align-items: center;
        height: 50px;
      }
      .input-section {
        flex-grow: 1;

        .input {
          width: 100%;
        }
      }

      &.sm-max {
        flex-direction: column;
        .label-section {
          flex-basis: 32px;
        }
      }

    }
  }

  .functions-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 39px;
  }
}
</style>
