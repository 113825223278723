export const createCustomBlocks = ({
  businessAddress,
}) => [
  {
    category: "Footer",
    tags: ["footer"],
    data: {
      cells: [1],
      columns: [{
        contents: [{
          type: "text",
          values: {
            containerPadding: "10px 10px 0px",
            _meta: {
              htmlClassNames: "u_content_text"
            },
            selectable: true,
            draggable: true,
            deletable: true,
            color: "#dd9cce",
            textAlign: "center",
            lineHeight: "140%",
            text: `<p style=\"font-size: 14px; line-height: 140%;\">${businessAddress}</p>`
          }
        }, {
          type: "html",
          values: {
            containerPadding: "10px",
            _meta: {
              htmlClassNames: "u_content_html"
            },
            selectable: true,
            draggable: true,
            deletable: true,
            html: "<!--\nPlease do not take away \"clicktracking=off\",\nand \'href=\"{{ar_unsubscribe_link}}\"\',\notherwise we cannot track your email properly\nand your audience won't be able to unsubscribe.\n-->\n<a clicktracking=off\n  href=\"{{ar_unsubscribe_link}}\"\n  target=\"__blank\"\n  style=\"display: block; text-align: center; color: #7344c0; text-align: center;\"\n>Click here to unsubscribe</a>"
          }
        }],
        values: {
          _meta: {
            htmlClassNames: "u_column"
          }
        }
      }],
      values: {
        columns: false,
        backgroundColor: "",
        columnsBackgroundColor: "",
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false
        },
        padding: "0px",
        hideMobile: false,
        noStackMobile: false,
        _meta: {
          htmlClassNames: "u_row"
        },
        selectable: true,
        draggable: true,
        deletable: true
      }
    },
    displayMode: "email"
  }
];

export const createDefaultTemplate = () => ({
  counters: {
    u_column: 13,
    u_row: 13,
    u_content_text: 10,
    u_content_form: 1,
    u_content_button: 1,
    u_content_custom_un_subscribe_link: 4,
    u_content_opt_out_link: 4,
    u_content_html: 10
  },
  body: {
    rows: [
      {
        cells: [
          1
        ],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: {
                htmlID: "u_column_3",
                htmlClassNames: "u_column"
              }
            }
          }
        ],
        values: {
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideMobile: false,
          noStackMobile: false,
          _meta: {
            htmlID: "u_row_3",
            htmlClassNames: "u_row"
          },
          selectable: true,
          draggable: true,
          deletable: true
        }
      },
      {
        cells: [
          1
        ],
        columns: [],
        values: {
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideMobile: false,
          noStackMobile: false,
          _meta: {
            htmlID: "u_row_13",
            htmlClassNames: "u_row"
          },
          selectable: true,
          draggable: true,
          deletable: true
        }
      }
    ],
    values: {
      backgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false
      },
      contentWidth: "600px",
      fontFamily: {
        label: "Arial",
        value: "arial,helvetica,sans-serif"
      },
      _meta: {
        htmlID: "u_body",
        htmlClassNames: "u_body"
      }
    }
  }
});

export const getCustomCSS = (arStyle) => {
  return ``
    +
    // The container containing all tool items
    `
      .tab-content {
        background-color: ${arStyle.color.skyBlueGrey300} !important;
      }
    `
    +
    // The sidebar section
    `
      .nav-tabs {
        background-color: ${arStyle.color.blueGrey400} !important;
      }
    `
    +
    // The item of sidebar
    `
      .nav-link {
        background-color: ${arStyle.color.blueGrey400} !important;
      }
      .nav-link .nav-name {
        color: ${arStyle.color.skyBlueGrey700} !important;
      }
      .nav-link .nav-icon {
        color: ${arStyle.color.skyBlueGrey700} !important;
      }
    `
    +
    // The active item of sidebar
    `
      .nav-link.active {
        background-color: ${arStyle.color.skyBlueGrey300} !important;
      }
      .nav-link.active .nav-name {
        color: ${arStyle.color.purple500} !important;
      }
      .nav-link.active .nav-icon {
        color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Tool
    `
      .blockbuilder-content-tool {
        --box-shadow-color:: ${arStyle.color.purple100} !important;
      }
      .blockbuilder-content-tool:hover:not(.disabled) {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px !important;
      }
      .blockbuilder-content-tool-icon {
        color: ${arStyle.color.purple200} !important;
      }
      .blockbuilder-content-tool-name {
        color: ${arStyle.color.blueGrey800} !important;
        text-transform: none !important;
        white-space: unset !important;
      }
    `
    +
    // Block
    `
      .blockbuilder-column-content {
        background-color: #f0f0f0 !important;
      }
      .blockbuilder-column {
        background-color: #f0f0f0 !important;
      }
    `
    +
    // Any primay button
    `
      .btn.btn-primary {
        background-color: ${arStyle.color.purple500} !important;
        border: none;
      }
    `
    +
    // Any link
    `
      .btn.btn-link {
        color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Any more options link
    `
      .card .text-center.more-options .btn-link {
        color: ${arStyle.color.purple500} !important;
      }
      .card .text-center.more-options .btn-link:hover {
        color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Any dropdown headers
    `
      .blockbuilder-options-content .card .card-header {
        background-color: #f0f0f0 !important;
        color: ${arStyle.color.blueGrey800} !important;
      }
      .blockbuilder-options-content .card .card-header .text-right.group-toggle svg {
        color: ${arStyle.color.blueGrey800} !important;
      }
    `
    +
    // The container containing all options
    `
      .blockbuilder-options-panel .blockbuilder-options-content {
        background-color: white !important;
      }
    `
    +
    // Header of options section
    `
      .blockbuilder-options-header .text-right .icon {
        color: ${arStyle.color.purple500} !important;
        border: none !important;
      }
      .blockbuilder-options-header .text-right .icon:hover {
        background-color: ${arStyle.color.purple500} !important;
      }
      .blockbuilder-options-header .blockbuilder-options-title {
        color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Number selector
    `
      .blockbuilder-counter-control .blockbuilder-counter-control-value {
        background-color: white !important;
        color: ${arStyle.color.blueGrey800} !important;
      }
      .blockbuilder-counter-control .blockbuilder-counter-control-btn {
        background-color: #f0f0f0 !important;
        color: ${arStyle.color.blueGrey800} !important;
      }
    `
    +
    // Alignment selector
    `
      .blockbuilder-alignment-widget .text-right span {
        background-color: white !important;
        line-height: unset !important;
      }
    `
    +
    // Toggle
    `
      .react-toggle .react-toggle-track {
        background-color: ${arStyle.color.skyBlueGrey700} !important;
      }
      .react-toggle.react-toggle--checked .react-toggle-track {
        background-color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Dropdwon
    `
      .blockbuilder-widget .dropdown .dropdown-toggle {
        background-color: white !important;
        color: ${arStyle.color.blueGrey800} !important;
      }
      .blockbuilder-widget .dropdown .dropdown-toggle:not(:hover) {
        border-color: rgb(222, 225, 226) !important;
      }
    `
    +
    // Slider
    `
      .rc-slider .rc-slider-track {
        background-color: ${arStyle.color.purple500} !important;
      }
      .rc-slider .rc-slider-handle {
        border-color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Input group
    `
      .input-group .input-group-prepend .input-group-text {
        background-color: #f0f0f0 !important;
        color: ${arStyle.color.blueGrey800} !important;
      }
    `
    +
    // Drag it here indicator
    `
      .blockbuilder-placeholder:before {
        background-color: ${arStyle.color.purple500} !important;
      }
      .blockbuilder-placeholder:after {
        outline: ${arStyle.color.purple500} solid 3px !important;
      }
    `
    +
    // Empty placeholder
    `
      .blockbuilder-placeholder .blockbuilder-placeholder-empty {
        background-color: ${arStyle.color.purple100} !important;
        outline: ${arStyle.color.purple500} dashed 1px !important;
        color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Add content button in empty placeholder
    `
      .blockbuilder-placeholder .blockbuilder-placeholder-empty a {
        background-color: ${arStyle.color.purple500} !important;
      }
    `
    +
    // Layer selector
    `
      .blockbuilder-layer-selector:after {
        outline: ${arStyle.color.purple200} solid 2px !important;
      }
      .blockbuilder-layer.blockbuilder-layer-selected > .blockbuilder-layer-selector:after {
        outline: ${arStyle.color.purple500} solid 2px !important;
      }
      .blockbuilder-layer-drag.blockbuilder-layer-drag-rows {
        background-color: ${arStyle.color.purple200} !important;
      }
      .blockbuilder-layer-drag {
        background-color: ${arStyle.color.purple200} !important;
      }
      .blockbuilder-layer.blockbuilder-layer-selected > .blockbuilder-layer-selector .blockbuilder-layer-drag {
        background-color: ${arStyle.color.purple500} !important;
      }
      .blockbuilder-layer-controls .blockbuilder-layer-control {
        background-color: ${arStyle.color.purple500} !important;
      }
      .blockbuilder-layer-add-row {
        background-color: ${arStyle.color.purple500} !important;
      }
      .blockbuilder-layer-type {
        background-color: ${arStyle.color.purple200} !important;
      }
    `;
};