<template>
  <div :class="['slider-wrapper', trackColor]">
    <div :style="{ display: isValueVisible ? 'block' : 'none' }" align="center">{{ double ? value[0] + ' - ' + value[1] : value }}</div>
    <div class="slider_box">
      <input
        :value="double ? value[0] : value"
        :min="min"
        :max="max"
        class="slider"
        type="range"
        v-on="listeners"
      >
      <div v-if="double" :style="sliderRange" class="slider-range"/>
      <input
        v-if="double"
        :min="min"
        :max="max"
        :value="value[1]"
        class="slider"
        type="range"
        v-on="listeners2">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    value: {
      type: [Number, Array],
      required: true,
    },
    double: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    isValueVisible: {
      type: Boolean,
      default: true,
    },
    trackColor: {
      type: String,
      default: 'grey',
      validator: (val) => ['grey', 'green', 'purple'].indexOf(val) > -1,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => {
          const val = event.target.value;
          this.$emit('input', this.double ? [Math.min(val, this.value[1]), this.value[1]] : val);
        },
      };
    },
    listeners2() {
      return {
        ...this.$listeners,
        input: event => {
          const val = event.target.value;
          this.$emit('input', [this.value[0], Math.max(val, this.value[0])]);
        },
      };
    },
    sliderRange() {
      const [val1, val2] = this.value;
      const interval = this.max - this.min;
      return {
        width: `${((val2 - val1) * 100) / interval}%`,
        left: `${((val1 - this.min) * 100) / interval}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-wrapper {
  $grey: $blueGrey500;
  $green: $green500;
  $purple: $purple500;
  // $track-color: #cdd0db !default;
  $thumb-color: #ffffff !default;

  $thumb-radius: 20px !default;
  $thumb-height: 28px !default;
  $thumb-width: 28px !default;
  $thumb-shadow-size: 2px !default;
  $thumb-shadow-blur: 2px !default;
  $thumb-shadow-color: $skyBlueGrey500 !default;
  $thumb-border-width: 1px !default;
  $thumb-border-color: $skyBlueGrey500 !default;

  $track-width: 100% !default;
  $track-height: 5px !default;
  $track-shadow-size: 0px !default;
  $track-shadow-blur: 0px !default;
  $track-shadow-color: rgba(0, 0, 0, 0.2) !default;
  $track-border-width: 0px !default;
  $track-border-color: $skyBlueGrey500 !default;

  $track-radius: 8px !default;
  $contrast: 5% !default;

  @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
    box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
      0 0 $shadow-size lighten($shadow-color, 5%);
  }

  @mixin track {
    height: $track-height;
    transition: all 0.2s ease;
    width: $track-width;
  }

  @mixin thumb {
    @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
    background: $thumb-color;
    border: $thumb-border-width solid $thumb-border-color;
    border-radius: $thumb-radius;
    cursor: pointer;
    height: $thumb-height;
    width: $thumb-width;
  }

  .slider_box {
    position: relative;
    height: $thumb-height + $thumb-border-width * 2 + $thumb-shadow-size;
  }
  .slider {
    -webkit-appearance: none;
    margin: calc($thumb-height / 2) 0;
    height: $track-height;
    width: $track-width;
    position: absolute;
    left: 0;
    top: 0;
    outline: none;
    pointer-events: none;

    &:focus {
      outline: 0;
    }

    &::-webkit-slider-runnable-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      pointer-events: all;
      position: relative;
      z-index: $zIndexHigh;
      -webkit-appearance: none;
      margin-top: calc($track-height / 2 - $track-border-width - $thumb-height / 2);
    }

    &::-moz-range-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-moz-range-thumb {
      @include thumb;
      pointer-events: all;
      position: relative;
      z-index: $zIndexHigh;
    }

    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      border-width: calc($thumb-height / 2) 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-fill-upper {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-thumb {
      @include thumb;
      margin-top: 0;
      pointer-events: all;
      position: relative;
      z-index: $zIndexHigh;
    }
  }
  .slider-range {
    position: absolute;
    z-index: $zIndexRegular;
    top: 50%;
    transform: translateY(-50%);
    height: $track-height + 2 * $track-border-width;
    background-color: $purple500;
  }
  &.green {
    .slider {
      &:focus {
        &::-webkit-slider-runnable-track {
          background: lighten($green, $contrast);
        }

        &::-ms-fill-lower {
          background: $green;
        }

        &::-ms-fill-upper {
          background: lighten($green, $contrast);
        }
      }
      &::-webkit-slider-runnable-track {
        background: $green;
      }
      &::-moz-range-track {
        background: $green;
      }
      &::-ms-fill-lower {
        background: darken($green, $contrast);
      }

      &::-ms-fill-upper {
        background: $green;
      }
    }
  }

  &.grey {
    .slider {
      &:focus {
        &::-webkit-slider-runnable-track {
          background: lighten($grey, $contrast);
        }

        &::-ms-fill-lower {
          background: $grey;
        }

        &::-ms-fill-upper {
          background: lighten($grey, $contrast);
        }
      }
      &::-webkit-slider-runnable-track {
        background: $grey;
      }
      &::-moz-range-track {
        background: $grey;
      }
      &::-ms-fill-lower {
        background: darken($grey, $contrast);
      }

      &::-ms-fill-upper {
        background: $grey;
      }
    }
  }

  &.purple {
    .slider {
      &:focus {
        &::-webkit-slider-runnable-track {
          background: lighten($purple, $contrast);
        }

        &::-ms-fill-lower {
          background: $purple;
        }

        &::-ms-fill-upper {
          background: lighten($purple, $contrast);
        }
      }
      &::-webkit-slider-runnable-track {
        background: $purple;
      }
      &::-moz-range-track {
        background: $purple;
      }
      &::-ms-fill-lower {
        background: darken($purple, $contrast);
      }

      &::-ms-fill-upper {
        background: $purple;
      }
    }
  }
}
</style>
