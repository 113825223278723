<template>
  <section class="event-rsvp-form-modify-basic">
    <div v-if="hasHeading" class="u-margin-bottom-12">
      <ar-text
        size="xxs"
        :text="`STEP 1 OF ${totalStep}`"
        :style="{
          color: '#9FA8B5',
        }"
        weight="bold"
      />
      <am2-heading
        class="u-margin-top-2"
        type="h1"
        size="lg"
        title="Create RSVP Page"
        multiple-lines
      />
    </div>

    <!-- eventRSVP form description -->
    <ar-field
      label="Event description"
    >
      <ar-textarea
        :value="scratchEventRSVPForm && scratchEventRSVPForm.presentation ? scratchEventRSVPForm.presentation.description : null"
        @input="handleDescriptionInput"
        :rows="4"
        data-test-id="event-rsvp-description"
      />
    </ar-field>

    <ar-field
      class="u-margin-top-12"
      label="Public Event URL"
      :error-messages="[veeErrors.first('url-slug')]"
    >
      <!-- Only for this, we validate it immediately after @input -->
      <am2-new-slug-input
        class="u-margin-top-4"
        v-validate="{
          required: true,
          arCampaignSlug: {
            currentSlug: this.currentUrlSlug,
            prefix: '/r/',
          },
        }"
        data-vv-name="url-slug"
        data-vv-as="Public Event URL"
        data-vv-validate-on="input"
        :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
        :value="scratchEventRSVPForm && scratchEventRSVPForm.urlSlug"
        @input="handleUrlSlugInput"
        :prefix="`${c2ClientDomain}/r/`"
        :enableCopy="isEditEventRSVPForm"
        data-test-id="event-rsvp-url-slug"
      />
    </ar-field>

    <!-- Custom Brand Color -->
    <ar-field
      class="u-margin-top-12"
      label="Custom color"
      :error-messages="[veeErrors.first('customColor')]"
    >
      <am2-color-input
        v-validate="'required'"
        :value="scratchEventRSVPForm && scratchEventRSVPForm.presentation ? scratchEventRSVPForm.presentation.color : '#7344c0'"
        @input="handleCustomColorInput"
        data-vv-name="customColor"
        data-vv-as="customColor"
      />
    </ar-field>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Basic',

  props: {
    hasHeading: {
      type: Boolean,
      default: false,
    },
    totalStep: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      urlSlugManuallyUpdated: false,
    };
  },

  computed: {
    ...mapState({
      currentEventRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      messageListInScratchEventRSVPForm: state => state.eventRSVPForm.messageListInScratchEventRSVPForm,
    }),
    eventRSVPFormImageUrl() {
      const imageResource = this.scratchEventRSVPForm.resources.find(resource => resource.assetType === 'campaign-image');
      return imageResource ? imageResource.url : null;
    },
    urlSlugShouldAutoUpdate() {
      if (this.currentEventRSVPForm && this.currentEventRSVPForm.urlSlug) return false;
      if (this.urlSlugManuallyUpdated) return false;
      return true;
    },
    currentUrlSlug() {
      return this.currentEventRSVPForm?.urlSlug;
    },
    isEditEventRSVPForm() {
      return this.scratchEventRSVPForm && !!this.scratchEventRSVPForm.oid;
    },
  },

  watch: {
    scratchEventRSVPForm: {
      handler(val) {
        if (!this.scratchEventRSVPForm) { return; }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations([
      'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
    ]),

    handleDescriptionInput(newDescription) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        presentation: {
          description: newDescription,
          metaDescription: newDescription,
        },
      });
    },

    handleCustomColorInput(color) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({ presentation: { color } });
    },

    handleUrlSlugInput(urlSlug) {
      this.urlSlugManuallyUpdated = true;
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({ urlSlug });
    },
  },
};
</script>

<style lang="scss" scoped>
.event-rsvp-form-modify-basic {
  .advanced-settings-section {
    padding: 40px;
    margin: 15px 0 0;
  }

  .channels-select-section {
    border-radius: 3px;
    padding: 20px;
    background: $skyBlueGrey300;
  }
}
</style>
