<template>
  <div class="check-list">
    <ar-icon
      :class="['icon', checkState]"
      :name="iconNameMap[checkState]"
      v-bind="iconPropsDecorated"
    />
    <span
      :class="['msg']"
      v-html="text"
      :style="textStyleDecorated"
    />
  </div>
</template>


<script>
export default {
  name: 'CheckListMessage',

  props: {
    checkState: {
      type: String,
      default: 'yes',
      validator: (value) =>
        ['yes', 'no'].indexOf(value) !== -1,
    },
    text: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
      default: () => ({}),
    },
    textStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      iconNameMap: {
        yes: 'check',
        no: 'cross',
      },
    };
  },
  computed: {
    iconPropsDecorated() {
      return {
        height: '16px',
        ...this.iconProps,
      };
    },
    textStyleDecorated() {
      return {
        lineHeight: this.iconPropsDecorated.height,
        ...this.textStyle,
      }; 
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.check-list {
  display: flex;
  align-items: flex-start;

  .icon {
    margin-right: 8px;

    &.yes {
      color: $green500;
    }

    &.no {
      color: $red500
    }
  }

  .msg {
    font-size: 14px;
    color: $blueGrey800;
  }
}
</style>
