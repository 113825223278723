var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreSubscribers),expression:"fetchMoreSubscribers"}],staticClass:"message-lists-contacts-wrapper",attrs:{"infinite-scroll-disabled":"disableFetchMoreSubscribers","infinite-scroll-distance":10}},[(_vm.emailNotVerified)?_c('ar-snackbar',{staticClass:"u-width-100-percent",attrs:{"type":"general","message":"Please verify this email address. <a>Resend verification email</a>."},on:{"anchorClick":_vm.retryEmailVerification}}):_vm._e(),_vm._v(" "),(_vm.noContacts && !_vm.currentMessageList.filterGroupOid && (_vm.currentMessageList.email || _vm.currentMessageList.sms))?_c('am2-no-content-section',{staticClass:"u-margin-top-7",attrs:{"icon-name":"ar-subscriber-in-circle","icon-props":{
      height: '79px',
    },"header":"Import subscribers","body":"To get started add subscribers to your list. You can either <a>select existing contacts</a>, <a>import a csv file</a> or <a>create a signup form.</a>","button-text":"Import CSV file","button-props":{
      iconName: null,
    },"data-test-id":"message-center-lists-contacts-import-csv-file-section"},on:{"buttonClick":_vm.handleImportContactsClick,"bodyLinkClick":_vm.handleNoContactsAnchorClick}}):(_vm.noContacts && !_vm.currentMessageList.filterGroupOid && _vm.currentMessageList.facebookMessenger)?_c('am2-no-content-section',{staticClass:"u-margin-top-7",attrs:{"icon-name":"ar-subscriber-in-circle","icon-props":{
      height: '79px',
    },"header":"Import subscribers","body":"To get started add subscribers to your list. You can either <a>select existing contacts to add</a> or <a>create a signup form.</a>"},on:{"bodyLinkClick":_vm.handleNoContactsForMessengerAnchorClick}}):_c('div',[_c('am2-fans-control-section',{style:({
        margin: '22px 0 32px',
      }),attrs:{"fans-count":_vm.totalSubscribersCount,"order-by":_vm.orderBy,"import-contacts":_vm.importContactsDecorated,"loading":_vm.isFetchingSubscribers && _vm.subscribers.length === 0,"csvExport":{
        action: _vm.handleExportCsvClick,
      }}}),_vm._v(" "),_c('am2-subscribers-table',{attrs:{"head":_vm.headings,"body":_vm.subscribers,"loading":_vm.isFetchingSubscribers && _vm.subscribers.length === 0,"empty-text":_vm.isAutoList ? 'No contacts match applied filter' : 'No data',"has-sticky-header":""},on:{"sortBy":_vm.handleSortChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }