<template>
  <portal
    to="higher-order-modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="wrapper"
      header="Resize image"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div slot="body" :style="{ height: `${this.viewport.height + 75}px` }" class="body">
        <vue-croppie
          ref="croppieRef"
          :viewport="viewport"
          :boundary="boundary"
          :show-zoomer="false"
          :mouse-wheel-zoom="false"
          :enable-resize="false"
        />
      </div>
      <div slot="footer" class="footer">
        <div v-if="!hideScrollBar" class="slider-zone">
          <am2-slider-input
            :value="sliderValue"
            :min="minSliderValue"
            :max="maxSliderValue"
            has-panel
            @input="changeSliderValue"
          >
            <span slot="caption" class="slider-caption">Drag to resize image</span>
          </am2-slider-input>
        </div>
        <ar-simple-button
          class="save-button"
          text="Save"
          @click="saveImage"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'ResizeImageModal',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    targetDimensions: {
      type: Object,
      default: () => ({
        width: 0,
        height: 0,
      }),
    },
    file: {
      type: File,
      default: null,
    },
  },

  data() {
    return {
      viewport: {
        width: 0,
        height: 0,
      },
      boundary: {
        width: 0,
        height: 0,
      },
      imageSrc: null,
      imageName: null,
      imageWidth: null,
      imageHeight: null,
      sliderValue: 0,
      minSliderValue: 0,
      maxSliderValue: 0,
    };
  },

  computed: {
    viewportRatio() {
      return this.targetDimensions.height / this.targetDimensions.width;
    },
    hideScrollBar() {
      // If minimum slide value equals to maximum slide value, hide the scrollBar
      return this.minSliderValue === this.maxSliderValue;
    },
    zoomValue() {
      return this.sliderValue / 100;
    },
  },

  watch: {
    isShow(show) {
      if (show) {
        this.$nextTick(() => {
          this.initializeResizedImage(this.file);
        })
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    saveImage() {
      const croppieElem = this.$refs.croppieRef;

      croppieElem
        .result({
          type: 'blob',
          format: 'jpeg',
          size: {
            width: this.targetDimensions.width,
            height: this.targetDimensions.height,
          },
        })
        .then(blob => {
          const imageFile = new File([blob], this.imageName, { type: 'image/jpeg' });
          this.$emit('resize', imageFile);
        });
    },
    /**
     * Change sliderValue, also trigger setZoom function.
     * This function will also be used when the window size changes, to make sure value won't exceed the slider range.
     * @param {value} value for updating sliderValue
     */
    changeSliderValue(value) {
      let newValue = value;
      if (newValue < this.minSliderValue) {
        newValue = this.minSliderValue;
      } else if (newValue > this.maxSliderValue) {
        newValue = this.maxSliderValue;
      }
      this.sliderValue = value;
      // Rezoom
      this.setZoom();
    },
    setZoom() {
      this.$refs.croppieRef.setZoom(this.zoomValue);
    },
    async calculateViewport() {
      const croppieElemWidth = this.$refs.croppieRef.$el.offsetWidth;
      let viewportWidth;
      if (this.targetDimensions.width <= croppieElemWidth) {
        viewportWidth = this.targetDimensions.width <= 640 ? this.targetDimensions.width : 640;
      } else {
        if (croppieElemWidth < 500) {
          viewportWidth = croppieElemWidth * 0.9;
        } else if (croppieElemWidth < 800) {
          viewportWidth = croppieElemWidth * 0.8;
        } else {
          viewportWidth = 640;
        }
      }
      this.viewport = {
        width: viewportWidth,
        height: viewportWidth * this.viewportRatio,
      };
      this.boundary = {
        width: '100%',
        height: '100%',
      };
      await this.$nextTick();
      this.$refs.croppieRef.refresh();
    },
    /**
     * If source image is small than half size of target dimensions, we hide zooming slider,
     * otherwise the out image quality will be super bad.
     * If source image is bigger than half size of target dimensions, we allow users
     * to zoom in, until the size of expected out image is half size of the target dimentions.
     */
    calculateSliderRange() {
      const minHeightZoom = this.viewport.height / this.imageHeight;
      const minWidthZoom = this.viewport.width / this.imageWidth;
      const dependsOn = minWidthZoom > minHeightZoom ? 'width' : 'height';
      const minZoomValue = dependsOn === 'width' ? minWidthZoom : minHeightZoom;
      let maxZoomValue;
      if (dependsOn === 'width') {
        if (this.targetDimensions.width / 2 > this.imageWidth) {
          maxZoomValue = minZoomValue;
        } else {
          maxZoomValue = this.viewport.width / (this.targetDimensions.width / 2);
        }
      } else if (dependsOn === 'height') {
        if (this.targetDimensions.height / 2 > this.imageHeight) {
          maxZoomValue = minZoomValue;
        } else {
          maxZoomValue = this.viewport.height / (this.targetDimensions.height / 2);;
        }
      }
      this.minSliderValue = parseInt(minZoomValue * 100, 10);
      this.maxSliderValue = parseInt(maxZoomValue * 100, 10);
      this.sliderValue = this.minSliderValue;
    },
    async handleWindowResize() {
      if (!this.isShow || !this.$refs.croppieRef) {
        return;
      }
      await this.calculateViewport();

      this.calculateSliderRange();
      // Call changeSliderValue, make sure value won't exceed the slider range
      this.changeSliderValue(this.sliderValue);
      // Draw Image again, to reset the croppie setting
      this.drawResizedImage();
    },
    initializeResizedImage(file) {
      this.handleWindowResize();
      const image = new Image();
      image.src = window.URL.createObjectURL(file);
      image.onload = () => {
        this.imageSrc = window.URL.createObjectURL(file);
        this.imageName = file.name;
        this.imageWidth = image.width;
        this.imageHeight = image.height;
        this.isShow = true;

        this.checkCroppieRefReady = setInterval(async () => {
          if (!this.$refs.croppieRef) {
            return;
          }
          await this.calculateViewport();
          this.calculateSliderRange();
          this.drawResizedImage();

          clearInterval(this.checkCroppieRefReady);
        }, 20);
      };
    },
    drawResizedImage() {
      this.$refs.croppieRef
        .bind({
          url: this.imageSrc,
        })
        .then(() => {
          this.setZoom();
        });
    },
    handleClose() {
      this.$emit('cancel');
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  .footer {
    position: relative;
    height: 90px;

    .slider-zone {
      position: absolute;
      width: 320px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      .slider-caption {
        font-size: 13px;
        color: $blueGrey800;
        letter-spacing: 0;
        line-height: 25px;
      }

      @media (max-width: 600px) {
        left: 28px;
        width: calc(100% - 170px);
        transform: translateX(0) translateY(-50%);
      }
    }

    .save-button {
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
    }
  }
}
</style>
