<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="ar-subscribe-facebook-pages-modal"
      hide-header
      hide-footer
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <!-- Select list -->
      <div
        slot="body"
        :class="[
          'modal-body',
          'u-padding-y-9',
          $arMediaQuery.pageContent.maxWidth('xs') ? 'u-padding-x-3' : 'u-padding-x-5',
        ]"
      >
        <div class="title-section">
          <ar-icon
            v-bind="iconProps"
          />
          <ar-text
            size="md"
            weight="bold"
            text="Add Facebook Messenger Page"
            class="u-margin-top-7"
          />
          <ar-text
            size="xs"
            text="Add your Facebook Pages to your accounts so that you can send Facebook messages to your audience"
            multiple-lines
            align="center"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
            :class="[
              'u-margin-top-2',
            ]"
          />
        </div>
        <div class="content-body u-margin-top-10">
          <am2-loading-section
            v-if="isFetchingFacebookMessengerIntegration || isFetchingFacebookPages"
          />
          <div
            v-else-if="!facebookMessengerIntegration"
            class="no-integration-section"
          >
            <ar-simple-button
              type="messenger"
              text="Connect Facebook Messenger"
              class="u-width-100-percent"
              :loading="isConnectingFacebookMessenger"
              @click="handleConnectFacebookMessengerClick"
            />
            <div
              :class="[
                'description',
                'u-margin-top-4',
                'u-padding-x-5',
              ]">
              <ar-icon
                name="lock"
                height="16px"
                :color="$arStyle.color.skyBlueGrey700"
              />
              <ar-text
                size="xs"
                text="Your credentials are encrypted & can be removed at any time"
                multiple-lines
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
                class="u-margin-left-2"
              />
            </div>
          </div>
          <am2-no-content-section
            v-else-if="facebookPages.length === 0"
            icon-name="facebook"
            body="Seems like you don't have any Facebook pages yet, please add one on <a>Facebook</a> and click refresh pages"
            @bodyLinkClick="handleNoFacebookPageSectionClick"
          />
          <am2-card-container
            v-else
            class="facebook-page-section-wrapper"
          >
            <div
              v-for="(facebookPage, facebookPageIdx) of facebookPages"
              :key="facebookPageIdx"
            >
              <div
                :class="[
                  'facebook-page-section',
                  facebookPage.hasSubscription && 'has-subscription',
                ]"
                @click="handleFacebookPageSectionClick(facebookPage)"
              >
                <div
                  :class="[
                    'facebook-page-title-and-tags',
                    $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                  ]"
                >
                  <div
                    class="facebook-page-left-section"
                  >
                    <ar-simple-button
                      v-if="facebookPage.notRenewing && !$arMediaQuery.pageContent.maxWidth('xs')"
                      text="Renew"
                      outlined
                      :loading="undoSubscriptionCancellationStatusMap[facebookPage.paymentSubscription.oid]"
                      :style="{
                        height: '40px',
                      }"
                      @click="handleRenewSubscriptionClick(facebookPage.paymentSubscription)"
                    />
                    <ar-checkbox
                      v-else-if="facebookPage.hasSubscription"
                      :value="true"
                      :style="{
                        pointerEvents: 'none',
                      }"
                      :disabled="true"
                    />
                    <ar-checkbox
                      v-else
                      :value="tickedFacebookPageMap[facebookPage.puid]"
                      :style="{
                        pointerEvents: 'none',
                      }"
                    />
                    <ar-avatar
                      :image-path="facebookPage.profileImageUrl"
                      :name="facebookPage.name"
                      class="page-avatar u-margin-left-3"
                      size="30px"
                    />
                    <ar-text
                      size="xs"
                      :text="facebookPage.name"
                      multiple-lines
                      class="u-margin-left-2"
                    />
                  </div>
                  <div
                    class="facebook-page-right-section"
                  >
                    <div
                      v-if="facebookPage.hasSubscription"
                      class="subscribed-tags"
                    >
                      <am2-tag
                        :type="facebookPage.notRenewing ? 'red' : 'green'"
                        shape="rectangle"
                        :text="facebookPage.notRenewing ? facebookPage.endsIn : 'Added'"
                        class="status-tag"
                      />
                    </div>
                    <div
                      v-else
                      class="select-tags"
                    >
                      <am2-tag
                        v-if="tickedFacebookPageMap[facebookPage.puid] && !tickedFreeFacebookPageMap[facebookPage.puid]"
                        type="purple"
                        shape="rectangle"
                        :text="planPriceCopy"
                        :sideText="planPriceTagSideText"
                        class="status-tag"
                      />
                      <am2-tag
                        v-else-if="tickedFacebookPageMap[facebookPage.puid] && tickedFreeFacebookPageMap[facebookPage.puid]"
                        type="purple"
                        shape="rectangle"
                        text="Free"
                        class="status-tag"
                      />
                      <am2-tag
                        v-else
                        type="white"
                        shape="rectangle"
                        :text="planPriceCopy"
                        :sideText="planPriceTagSideText"
                        class="status-tag"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="facebookPage.notRenewing && $arMediaQuery.pageContent.maxWidth('xs')"
                  class="renew-button-section"
                >
                  <ar-simple-button
                    v-if="facebookPage.notRenewing"
                    text="Renew"
                    outlined
                    :loading="undoSubscriptionCancellationStatusMap[facebookPage.paymentSubscription.oid]"
                    :style="{
                      height: '40px',
                    }"
                    @click="handleRenewSubscriptionClick(facebookPage.paymentSubscription)"
                  />
                </div>
              </div>
              <ar-divider
                v-show="facebookPageIdx !== facebookPages.length - 1"
              />
            </div>
          </am2-card-container>
          <ar-link-button
            v-if="facebookMessengerIntegration"
            text="Refresh pages"
            class="u-margin-top-4"
            @click="handleRefreshPagesClick"
          />
        </div>
        <div class="content-footer u-margin-top-10">
          <ar-simple-button
            type="gradient-purple"
            :text="purchaseButtonText"
            :class="[
              'u-width-100-percent',
            ]"
            :loading="isSubscribingToFacebookPages"
            :disabled="disablePurchaseButton"
            @click="handlePurchaseClick"
          />
          <am2-payment-source-section
            :payment-source-puid="paymentSourcePuid"
            add-payment-source-on="expansion"
            class="u-margin-top-6"
            :readonly="hasAnyPaymentSubscription"
            @select="handlePaymentSourceSelect"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { hexToRGBA } from '@/utils/helpers/';
import { accounting } from 'accounting';
import { debounce } from "debounce";
import moment from 'moment';

export default {
  name: 'ManageFacebookPageSubscriptionsModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    paymentPlan: {
      type: Object,
      default: null,
    },
    includedSubscriptionsQuantity: {
      type: Number,
      default: 0,
    },
    defaultPaymentSourcePuid: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      paymentSourcePuid: null,
      paymentSourceOid: null,
      isConnectingFacebookMessenger: false,
      isUpdatingFacebookMessengerIntegration: false,
      isFetchingFacebookMessengerIntegration: false,
      isFetchingFacebookPages: false,
      isSubscribingToFacebookPages: false,
      facebookPagesSource: [],
      facebookPageSubscriptionMap: {},
      facebookMessengerIntegration: null,
      tickedFacebookPageMap: {}, // All ticked pages ready to be purchased
      tickedFreeFacebookPageMap: {}, // All ticked as well as free pages
      numberOfSuccessfulSubscriptions: 0,
    }
  },

  computed: {
    ...mapState({
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      undoSubscriptionCancellationStatusMap: state => state.payment.undoSubscriptionCancellationStatusMap,
    }),
    facebookMessengerSubscriptions() {
      if (!this.paymentPlan) { return []; }
      return this.paymentSubscriptionList.filter(({ paymentPlan: { puid }, meta }) => {
        return puid === this.paymentPlan.puid && !!meta;
      });
    },
    availableFreeQuantity() {
      const numberOfFreeSubscription = this.facebookMessengerSubscriptions.filter(subscription => subscription.additionalInfo.planAmount === 0).length;
      return this.includedSubscriptionsQuantity - numberOfFreeSubscription;
    },
    hasAnyPaymentSubscription() {
      return this.facebookMessengerSubscriptions.length > 0;
    },
    tickedFacebookPageIdList() {
      return Object.keys(this.tickedFacebookPageMap).filter(puid => this.tickedFacebookPageMap[puid]);
    },
    tickedFreeFacebookPageIdList() {
      return Object.keys(this.tickedFreeFacebookPageMap).filter(puid => this.tickedFreeFacebookPageMap[puid]);
    },
    tickedNonFreeFacebookPageIdList() {
      return Object.keys(this.tickedFacebookPageMap).filter(puid => {
        return this.tickedFacebookPageMap[puid] && !this.tickedFreeFacebookPageMap[puid];
      });
    },
    remainingFreeQuantity() {
      return this.availableFreeQuantity - this.tickedFreeFacebookPageIdList.length;
    },
    planPrice() {
      if (!this.paymentPlan) {
        return null;
      }
      return this.paymentPlan.additionalInfo.price / 100;
    },
    planPriceCopy() {
      if (this.remainingFreeQuantity > 0) {
        return 'Free';
      } else {
        return accounting.formatMoney(this.planPrice, null, 0);
      }
    },
    planPriceTagSideText() {
      if (!this.paymentPlan) {
        return null;
      }
      if (this.remainingFreeQuantity > 0) {
        return null;
      }
      return `${this.paymentPlan.currency} /${this.paymentPlan.billingPeriod}`
    },
    iconProps() {
      return {
        name: 'messenger',
        color: this.$arStyle.color.messenger,
        width: '30px',
        height: '30px',
        wrapperStyle: {
          display: 'inline-flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
          border: `1px solid ${this.$arStyle.color.skyBlueGrey400}`,
          boxShadow: `0 0 15px 0 ${hexToRGBA(this.$arStyle.color.blueGrey800, 0.1)}`,
          borderRadius: '50%',
          background: 'white',
        },
      };
    },
    purchaseButtonText() {
      if (this.tickedNonFreeFacebookPageIdList.length === 0) {
        return 'Purchase';
      } else {
        return `${accounting.formatMoney(this.planPrice * this.tickedNonFreeFacebookPageIdList.length, null, 0)} - Purchase`;
      }
    },
    disablePurchaseButton() {
      if (!this.facebookMessengerIntegration) {
        return true;
      }
      if (this.tickedFacebookPageIdList.length === 0) {
        return true;
      }
      return false;
    },
    facebookPages() {
      return this.facebookPagesSource.map((facebookPage, idx) => {
        const addedInfo = {};
        const paymentSubscription = this.facebookPageSubscriptionMap[facebookPage.puid];
        if (paymentSubscription) {
          addedInfo.paymentSubscription = paymentSubscription;
          addedInfo.hasSubscription = true;
          if (paymentSubscription.providerStatus === 'non_renewing') {
            const endTime = moment(paymentSubscription.additionalInfo.currentTermEnd * 1000).local();
            addedInfo.notRenewing = true;
            addedInfo.endsIn = `Ends ${endTime.format('DD/MM/YY')}`;
          }
        }
        return {
          ...facebookPage,
          ...addedInfo,
        }
      }).sort((facebookPageA, facebookPageB) => {
        if (facebookPageA.hasSubscription && !facebookPageB.hasSubscription) {
          // Pages have been subscribed to always come top
          return -1;
        } else {
          return 0;
        }
      });
    },
    facebookPageInfoMap() {
      const res = {};
      this.facebookPages.forEach(facebookPage => {
        res[facebookPage.puid] = facebookPage;
      });
      return res;
    },
  },

  watch: {
    facebookMessengerSubscriptions: {
      handler(items) {
        items.forEach(this.updateFacebookPageRelativeInformation);
      },
      immediate: true,
    },
    defaultPaymentSourcePuid: {
      handler: function (val) {
        this.paymentSourcePuid = val;
      },
      immediate: true,
    },
    isOpen(val) {
      if (val) {
        this.tickedFacebookPageMap = {};
        this.tickedFreeFacebookPageMap = {};
        // Let's update FB page subscription map recursively
        this.fetchMessengerIntegration();
        this.fetchFacebookPages();
      }
    },
  },

  methods: {
    ...mapActions([
      'FETCH_INTEGRATIONS',
      'FETCH_FACEBOOK_PAGES',
      'CREATE_PAYMENT_SUBSCRIPTION',
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'payment/UNDO_PAYMENT_SUBSCRIPTION_CANCELLATIONS',
      'CONNECT_TO_INTEGRATION',
      'UPDATE_INTEGRATION',
    ]),
    updateFacebookPageRelativeInformation(paymentSubscription) {
      this.$set(this.facebookPageSubscriptionMap, paymentSubscription.meta.id, paymentSubscription);
    },
    async fetchFacebookPages() {
      try {
        this.isFetchingFacebookPages = true;

        this.facebookPagesSource = await this.FETCH_FACEBOOK_PAGES();
      } catch (error) {
        console.error(error);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch Facebook pages' });
      } finally {
        this.isFetchingFacebookPages = false;
      }
    },
    async connectIntegration() {
      try {
        this.isConnectingFacebookMessenger = true;
        const res = await this.CONNECT_TO_INTEGRATION({
          provider: 'facebook',
          app: 'messenger',
        });
        this.fetchMessengerIntegration();
        this.fetchFacebookPages();
        this.$arNotification.push({ type: 'success', message: 'Facebook Messenger account successfully connected!' });
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: `Failed to connect account: ${e.message}` });
      } finally {
        this.isConnectingFacebookMessenger = false;
      }
    },
    async refreshIntegration() {
      try {
        this.isUpdatingFacebookMessengerIntegration = true;
        await this.UPDATE_INTEGRATION({
          oid: this.facebookMessengerIntegration.oid,
          data: {
            meta: {},
          },
        });
        this.fetchFacebookPages();
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to refresh Facebook pages',
        });
      } finally {
        this.isUpdatingFacebookMessengerIntegration = false;
      }
    },
    async fetchMessengerIntegration() {
      try {
        this.isFetchingFacebookMessengerIntegration = true;
        const facebookMessengerIntegrations = await this.FETCH_INTEGRATIONS({
          top: 1,
          filters: {
            expressions: [{
              key: 'provider',
              value: 'facebook',
              operator: '=',
            }, {
              key: 'app',
              value: 'messenger',
              operator: '=',
            }],
            logicalOperators: ['AND'],
          },
          orderby: "sysMtime desc",
        });

        this.facebookMessengerIntegration = facebookMessengerIntegrations[0];
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to get Facebook Messenger integration' });
      } finally {
        this.isFetchingFacebookMessengerIntegration = false;
      }
    },
    async subscribeToFacebookPage(facebookPageId) {
      const isFree = this.tickedFreeFacebookPageMap[facebookPageId];
      const options = {
        paymentSourceOid: this.paymentSourceOid,
        planOid: this.paymentPlan.oid,
        additionalInfo: {
          pageId: facebookPageId,
        },
        planUnitPrice: isFree ? 0 : null,
      };
      try {
        await this.CREATE_PAYMENT_SUBSCRIPTION(options);
        this.numberOfSuccessfulSubscriptions++;
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: `Failed to subscribe to Facebook page: ${this.facebookPageInfoMap[facebookPageId].name}`
        });
      }
    },
    handleNoFacebookPageSectionClick() {
      window.open('https://www.facebook.com/bookmarks/pages', '_blank');
    },
    async handleRenewSubscriptionClick(paymentSubscription) {
      const succeed = await this['payment/UNDO_PAYMENT_SUBSCRIPTION_CANCELLATIONS']([paymentSubscription.oid]);
      if (succeed) {
        this.$emit('paymentSubscriptionsUpdate');
      }
    },
    handleRefreshPagesClick() {
      this.refreshIntegration();
    },
    handleConnectFacebookMessengerClick() {
      this.connectIntegration();
    },
    handleCloseClick() {
      this.$emit('close');
    },
    handlePaymentSourceSelect({ puid, oid }) {
      this.paymentSourcePuid = puid;
      this.paymentSourceOid = oid;
    },
    handleFacebookPageSectionClick(facebookPage) {
      // If you have purhcased this plan, you shouldn't be able to purchase it again
      if (facebookPage.hasSubscription) {
        return;
      }
      const isTicked = !this.tickedFacebookPageMap[facebookPage.puid];

      if (isTicked) {
        // If it is a free one, we put that into the free page map
        const isFree = this.availableFreeQuantity > this.tickedFreeFacebookPageIdList.length;
        this.$set(this.tickedFacebookPageMap, facebookPage.puid, true);
        this.$set(this.tickedFreeFacebookPageMap, facebookPage.puid, isFree);
      } else {
        const wasThisFacebookPageFree = this.tickedFreeFacebookPageMap[facebookPage.puid];
        // Easy, remove the page from both maps
        this.$set(this.tickedFacebookPageMap, facebookPage.puid, false);
        this.$set(this.tickedFreeFacebookPageMap, facebookPage.puid, false);

        // If you unticked a free page, let's make first non free one free
        if (wasThisFacebookPageFree) {
          this.$nextTick(() => {
            const firstNonFreeTickedFacebookPageId = this.tickedNonFreeFacebookPageIdList[0];
            if (firstNonFreeTickedFacebookPageId) {
              this.$set(this.tickedFreeFacebookPageMap, firstNonFreeTickedFacebookPageId, true);
            }
          });
        }
      }

    },
    async handlePurchaseClick() {
      this.isSubscribingToFacebookPages = true;

      const createPaymentSubscriptionPromises = this.tickedFacebookPageIdList.map(facebookPageId => {
        return this.subscribeToFacebookPage(facebookPageId);
      });
      try {
        this.numberOfSuccessfulSubscriptions = 0;
        await Promise.all(createPaymentSubscriptionPromises);

        const isAllSucceed = this.tickedFacebookPageIdList.length === this.numberOfSuccessfulSubscriptions;
        if (!isAllSucceed) {
          this.$arNotification.push({
            type: 'error',
            message: 'Sorry we failed to subscribe to some of your Facebook pages, please try again',
          });
        }
        if (this.numberOfSuccessfulSubscriptions > 0) {
          this.$emit('paymentSubscriptionsUpdate');
        }
        this.$emit('close');
      } catch (error) {
        // Capture other unexpected errors
        console.error(error);
      } finally {
        this.isSubscribingToFacebookPages = false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ar-subscribe-facebook-pages-modal {
  .modal-body {
    .title-section {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .content-body {
      .no-integration-section {
        .description {
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
      }

      .facebook-page-section-wrapper {
        max-height: 400px;
        overflow: auto;

        .facebook-page-section {
          cursor: pointer;
          padding: 12px 16px;

          &:hover {
            background: $purple100;
          }
          &.has-subscription {
            cursor: unset;
            &:hover {
              background: unset;
            }
          }

          .facebook-page-title-and-tags {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .facebook-page-left-section {
              display: flex;
              align-items: center;
              overflow: hidden;

              .page-avatar {
                flex-shrink: 0;
              }
            }

            .facebook-page-right-section {
              display: flex;
              flex-flow: column;

              .subscribed-tags, .select-tags {
                display: flex;
                flex-flow: row;
                .status-tag {
                  height: 25px;
                  flex-grow: 0;
                }
              }
            }

            // In a small device
            &.xs-max {
              flex-flow: column;
              align-items: flex-end;

              .facebook-page-left-section {
                width: 100%;
              }

              .facebook-page-right-section {
                justify-content: flex-end;
              }
            }
          }
          .renew-button-section {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }

    .content-footer {

    }
  }
}
</style>
