import { Plugin } from 'prosemirror-state';

/**
 * The nativa input rules plugin is not good, it only helps match the string
 * when your new input is at the end of the match, but our one, we match the string
 * in any cases.
 */
export const createInputRulesPlugin = function ({
  rules,
}) {
  return new Plugin({
    props: {
      handleTextInput: (view, from, to, text) => {
        if (view.composing) return false;
        let $from = view.state.doc.resolve(from);
        const fromParent = $from.parent;
        let textFrom;
        if ($from.index() === fromParent.childCount) {
          if ($from.nodeBefore && $from.nodeBefore.type.name === 'text') {
            $from = view.state.doc.resolve(from - 1);
            textFrom = $from.pos - $from.textOffset;
          } else {
            textFrom = from;
          }
        } else {
          if ($from.nodeBefore && $from.nodeBefore.type.name === 'text') {
            $from = view.state.doc.resolve(from - 1);
          }
          textFrom = $from.pos - $from.textOffset;
        }

        let $to = view.state.doc.resolve(to);
        const toParent = $to.parent;
        let textTo;
        if ($to.index() === toParent.childCount) {
          if ($to.nodeBefore && $to.nodeBefore.type.name === 'text') {
            $to = view.state.doc.resolve(to - 1);
            textTo = $to.pos - $to.textOffset + $to.parent.child($to.index()).nodeSize;
          } else {
            textTo = to;
          }
        } else {
          if ($to.nodeBefore && $to.nodeBefore.type.name === 'text') {
            $to = view.state.doc.resolve(to - 1);
          }
          textTo = $to.pos - $to.textOffset + $to.parent.child($to.index()).nodeSize;
        }
        const newText = view.state.doc.textBetween(textFrom, from) + text + view.state.doc.textBetween(to, textTo);
        if ($from.parent.type.spec.code) return false;
        for (let i = 0; i < rules.length; i++) {
          const match = rules[i].match.exec(newText);
          if (!match) return false;
          const matchFrom = textFrom + match.index;
          const lengthToMatchEnd = match.index + match[0].length;
          let matchTo = textTo - (newText.length - lengthToMatchEnd);
          const tr = rules[i].handler(view, match, matchFrom, matchTo);
          if (!tr) continue;
          view.dispatch(tr);
          return true;
        }
        return false;
      },
    },
  });
}
