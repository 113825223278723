var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],staticClass:"messages-page-wrapper",attrs:{"infinite-scroll-disabled":"isFetchMoreMessagesDisabled","infinite-scroll-distance":10}},[_c('div',{staticClass:"content"},[_c('am2-top',{class:[
        'top-wrapper',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"dropdown":_vm.messageTypeDropdown,"button":_vm.newMessageButton,"search":{
        action: _vm.serverSideSearch,
        placeholder: 'Search messages',
        value: _vm.searchString,
        style: {
          width: _vm.searchBoxWidth
        }
      },"title":"Messaging"}}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('md'))?_c('ar-divider',{style:({
        marginTop: '30px',
      })}):_vm._e(),_vm._v(" "),[(!!_vm.displayMessageData)?_c('am2-message-preview-modal',{attrs:{"data-test-id":"message-preview-modal-from-list","opened":_vm.displayMessagePreviewModal,"message":_vm.displayMessageData,"message-list":_vm.displayMessageData.messageListOid,"provider":_vm.displayMessageData.provider,"email-template":_vm.savedEmail,"message-body":_vm.currentSelectedMessageMessageBody,"subject":_vm.displayMessageData.meta.messageBody.subject,"preview-text":_vm.displayMessageData.meta.messageBody.previewText,"scheduled-at":_vm.displayMessageData.scheduledAt,"sender-name":_vm.currentMessageSenderName,"facebook-page-name":_vm.currentSelectedMessageFacebookPageName,"facebook-page-image-src":_vm.currentSelectedMessageFacebookPageImageSrc},on:{"close":function($event){_vm.displayMessagePreviewModal = false}}}):_vm._e()],_vm._v(" "),(_vm.isFetchingMessages || _vm.messages.length > 0)?_c('am2-messages-table',{style:({
        marginTop: '28px',
      }),attrs:{"head":_vm.tableHeadings,"messages":_vm.messages,"loading":_vm.isFetchingMessages && _vm.messages.length === 0,"has-sticky-header":""},on:{"cloneMessage":_vm.handleMessageAdded,"cancelMessage":_vm.handleMessageCancelled,"openMessagePreviewModal":_vm.openMessagePreviewModal}}):_vm._e(),_vm._v(" "),(!_vm.isMessageFeatureEnabled)?_c('am2-upgrade-plan-section',{staticClass:"upgrade-plan-section",style:({
        marginTop: '28px',
      }),attrs:{"feature-type":"message-center","design":"column"},on:{"buttonClick":function () { _vm.$router.push('/plans') }}}):(_vm.noMessages)?_c('div',{style:({
        marginTop: '28px',
      })},[(_vm.hasSearchConditions)?_c('am2-no-content-section',{attrs:{"header":"No messages found","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"icon-name":"ar-message-in-circle","header":"Create a message","body":"Send your first email or SMS message"}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }