<template>
  <section :class="[
    'plan-frame',
    $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
  ]">
    <div
      :class="[
        'fix-top-section',
        hasBackArrow ? 'u-justify-content-space-between' : 'u-justify-content-flex-end',
      ]"
    >
      <ar-icon-button
        v-if="hasBackArrow"
        :icon-props="{
          name: 'arrow-head',
          width: '20px',
        }"
        @click="handleBackArrowClick"
      />
      <ar-icon-button
        :icon-props="{
          name: 'cross-thick',
          width: '16px',
        }"
        @click="handleEsc"
        v-ar-hotkey="{
          general: ['Escape'],
          mac: ['Escape'],
          disabled: false,
          action: handleEsc,
        }"
      />
    </div>
    <div
      class="title-section"
    >
      <ar-text
        size="lg"
        :text="title"
        weight="bold"
        align="center"
        multiple-lines
      />
      <ar-text
        size="sm"
        :text="description"
        class="description"
      />
    </div>
    <div
      class="content"
    >
      <slot />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlanFrame',

  props: {
    hasBackArrow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },

  computed: {
  },

  data() {
    return {
      exitRoute: null,
    };
  },

  created() {
    if (window.sessionStorage.getItem('_ar_plan_exit_route_')) {
      this.exitRoute = JSON.parse(window.sessionStorage.getItem('_ar_plan_exit_route_'));
    }
  },

  methods: {
    handleBackArrowClick() {
      this.$router.push({
        path: '/plans',
      });
    },
    handleEsc() {
      this.$router.push(this.exitRoute || {
        path: '/settings/subscriptions',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-frame {
  position: relative;
  padding: 46px 0 0;

  .fix-top-section {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 24px 24px 0;
  }

  .title-section {
    display: flex;
    align-items: center;
    flex-flow: column;

    .description {
      color: $skyBlueGrey700;
      margin-top: 14px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }


  &.lg-max {
    .fix-top-section {
      padding: 16px 16px 0;
    }
    .content {
      padding: 0;
      margin-top: 40px;
    }
  }
}
</style>
