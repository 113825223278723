<template>
  <section>
    <am2-top-create
      :title="isNewTour ? 'Set as tour' : 'Edit Tour'"
      exit-path="/campaigns"
      :loading="isFetchingTour"
    />
    <div :class="[
      'wrapper',
      $arMediaQuery.window.maxWidth('sm') && 'sm-max'
    ]">
      <div class="left">
        <am2-loading-section
          v-if="isFetchingTour"
          :style="{
            height: '100%',
          }"
        />
        <div
          v-else
          class="left-top-section"
        >
          <div class="left-top-section-content">
            <template v-if="currentStep === 'details'">
              <am2-heading
                type="h1"
                size="lg"
                :title="isNewTour ? 'Create Tour' : 'Edit Tour'"
                :style="{marginBottom: '38.5px'}" />

              <p class="sub-heading">Create a tour so fans can easily select their chosen location when registering for a campaign.</p>

              <am2-field
                name="tour-name"
                label="Tour name">
                <div>
                  <ar-input
                    v-validate="'required'"
                    id="campaign-headline"
                    ref="campaign-headline"
                    v-model="tourViewModel.name"
                    autocomplete="campaign-headline"
                    name="headline"
                    class="input--block"
                    type="text"
                    @blur="maybeUpdateUrlSlug" />
                  <ar-state-message
                    v-if="veeErrors.has('headline')"
                    type="error"
                    :text="veeErrors.first('headline')"
                    :style="{ marginTop: '8px' }"
                  />

                  <div>
                    <!-- Only for this, we validate it immediately after @input -->
                    <am2-slug-input
                      class="u-margin-top-4"
                      v-validate="{
                        required: true,
                        arCampaignSlug: {
                          currentSlug: this.originalTourSlugUrl,
                          prefix: tourPrefix,
                        },
                      }"
                      data-vv-name="url-slug"
                      data-vv-as="URL"
                      data-vv-validate-on="input"
                      :base-url="c2ClientDomain"
                      :prefix="tourPrefix"
                      :value="tourViewModel.urlSlug"
                      @input="handleUrlSlugChange"
                    />
                  </div>
                  <ar-state-message
                    v-if="veeErrors.has('url-slug')"
                    type="error"
                    :text="veeErrors.first('url-slug')"
                    :style="{ marginTop: '8px' }"
                  />

                </div>
              </am2-field>
              <!-- Tour image -->
              <am2-field
                name="campaign-image"
                label="Image"
              >
                <div
                  v-if="isUploadingImage"
                  v-validate:isUploadingImage="'uploadingAssets'"
                  data-vv-name="tourImageUploading"
                  data-vv-as="tour image"
                />
                <am2-dropzone
                  v-validate:tourImage="'required'"
                  data-vv-name="image"
                  data-vv-as="tour image"
                  :file-type="['image/jpeg', 'image/jpg', 'image/png']"
                  file-type-alias="image"
                  :placeholder-icon="{
                    name: 'image',
                  }"
                  :image-options="{
                    dimensions: {
                      width: 1200,
                      height: 628
                    }
                  }"
                  :ratio="0.525"
                  feedback-type="image"
                  :feedback="tourImage && tourImage.url"
                  enable-ar-s3-bucket-upload
                  :ar-s3-bucket-upload-options-list="[{
                    assetType: 'campaign-image',
                    campaignOid: null,
                    eventOid: null,
                  }]"
                  @delete="handleImageDelete"
                  @uploadStart="isUploadingImage = true"
                  @uploadEnd="isUploadingImage = false"
                  @upload="handleImageUpload"
                />

                <ar-state-message
                  v-show="veeErrors.has('image')"
                  type="error"
                  :text="veeErrors.first('image')"
                  :style="{ marginTop: '8px' }"
                />
                <ar-state-message
                  v-show="veeErrors.has('tourImageUploading')"
                  type="error"
                  :text="veeErrors.first('tourImageUploading')"
                  :style="{ marginTop: '8px' }"
                />

                <p class="image-requirements-text">Dimensions: 1200x628 pixels. Format: JPG or PNG. Max file size: 1MB</p>
              </am2-field>

              <!-- Tour colours -->
              <am2-field
                name="tour-colours"
                label="Custom colours"
                description="Customize the page with your brand colours."
              >
                <div style="position: relative">
                  <am2-color-input
                    v-model="tourViewModel.presentation.colorData.hex"
                    :style="{ width: '250px' }"
                  />
                </div>
              </am2-field>

              <!-- Custom HTML -->
              <am2-field
                name="custom-html"
                label="Custom HTML"
                class="custom-html">

                <ar-link-button
                  v-if="!isHTMLSnippetsVisible"
                  text="Add HTML Snippets"
                  @click="isHTMLSnippetsVisible = !isHTMLSnippetsVisible"
                />

                <transition name="overlay-fade">
                  <div v-if="isHTMLSnippetsVisible">
                    <am2-field name="html-head" label="HTML Head" class="grey-text">
                      <ar-textarea id="html-head" v-model="tourViewModel.presentation.headHtml" :rows="5" />
                    </am2-field>
                    <br >
                    <am2-field name="html-body" label="HTML Body" class="grey-text">
                      <ar-textarea id="html-body" v-model="tourViewModel.presentation.bodyHtml" :rows="5" />
                    </am2-field>

                    <ar-link-button
                      text="Hide HTML Snippets"
                      @click="resetHTMLSnippets"
                      :style="{
                        marginTop: '12px',
                      }"
                    />
                  </div>
                </transition>
              </am2-field>
            </template>
            <template v-else-if="currentStep === 'campaigns'">
              <am2-heading
                type="h1"
                size="lg"
                title="Select your campaigns"
                :style="{marginBottom: '38.5px'}" />
              <am2-list-view
                :max-options="10"
                :fetch-data-adapter="fetchAdapter"
                :initial-selected="tourViewModel.campaignOids"
                :multiple="true"
                :on-selected="(selected) => tourViewModel.campaignOids = selected"
                :search-settings="{
                  placeholder: 'Search your campaigns'
                }">
                <div class="info" slot="template" slot-scope="option">
                  <img
                    class="image"
                    height="50"
                    width="70"
                    :src="option.imageSrc">
                  <div class="label">
                    <p class="title">
                      {{ option.name }}
                    </p>
                    <p class="subtitle">
                      {{option.subTitle}}
                    </p>
                  </div>
                </div>

              </am2-list-view>

            </template>
          </div>
        </div>
        <nav
          class="navigation"
          :style="{
            justifyContent: isNewTour || (!isNewTour && currentStep === 'campaigns') ? 'space-between' : 'flex-end',
          }"
        >
          <ar-simple-button
            v-if="currentStep === 'campaigns'"
            text="Back"
            outlined
            @click="handlePreviousStep"
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }" />
          <div class="u-display-flex">
            <ar-simple-button
              v-if="$arMediaQuery.window.maxWidth('sm')"
              :text="previewIsOpen ? 'Edit' : 'Preview'"
              class="u-margin-right-3"
              @click="handlePreviewClick"
              outlined
              :icon-name="(previewIsOpen || $arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview'"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              icon-distance="10px"
              :style="{
                marginLeft: currentStep === 'campaigns' ? '12px' : 0,
                width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px'
              }"
            />
            <ar-simple-button
              :disabled="currentStep === 'campaigns' && tourViewModel.campaignOids.length === 0"
              text="Next"
              @click="handleNextStep"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: currentStep === 'campaigns' && tourViewModel.campaignOids.length === 0,
                action: handleNextStep,
              }"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }"
            />
          </div>
        </nav>
      </div>
      <div :class="[
        'right',
        $arMediaQuery.window.maxWidth('sm') && previewIsOpen && 'is-visible'
      ]">
        <am2-tour-preview
          ref="tour-preview"
          type="tour"
          :has-account="false"
          :has-fan="false"
          preview-page="/"
          :tour="previewTour"
          size="fullHeight"
          class="preview"
        />
        </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { clone, arraysEqual } from '@/utils/helpers';

const createTourDTO = (tourViewModel) => {

  // "clone" the tourViewModel to produce our DTO for storing server side; this is the "best way"
  // to clone an object in Javascript - yes it is horrific.
  let DTO = clone(tourViewModel);

  if (tourViewModel.presentation.colorData) {
    const color = tourViewModel.presentation.colorData.hex;

    delete DTO.presentation.colorData;
    DTO.presentation.color = color;
  }

  if (DTO.resources) {
    DTO.resourceOids = DTO.resources.reduce((accum, resource) => accum.push(resource.oid) && accum, [])
    delete DTO.resources;
  }

  return DTO;
};

const createPreviewTour = (tourViewModel) => {
  let DTO = createTourDTO(tourViewModel);

  // Promoter will more than likely insert tracking pixels into the Tour header HTML so
  // we have to remove headHTML and bodyHTML.
  delete DTO.presentation.headHTML;
  delete DTO.presentation.bodyHTML;

  return DTO;
}

const defaultTourViewModel = {
  oid: null,
  promoterOid: null,
  name: null,
  campaignOids: null,
  resources: [],
  urlSlug: null,
  presentation: {},
};

export default {
  name: 'TourSetup',
  layout: 'edit',

  data: () => ({
    isFetchingTour: false,
    c2ClientDomain: process.env.arCampaignClientDomain,
    isHTMLSnippetsVisible: false,
    originalTourSlugUrl: null,
    tourPrefix: '/t/',
    currentStep: null,
    isNewTour: null,
    initialTourViewModel: null,
    tourViewModel: defaultTourViewModel,
    previewIsOpen: false, // Mobile-only - when true, shows the preview instead of the editor
    steps: ['details', 'campaigns'],
    isUploadingImage: false,
  }),

  watch: {
    tourViewModel: {
      handler(val) {
        sessionStorage.setItem('_ar_promoter_new_tour', JSON.stringify(val));
      },
      deep: true
    },
  },

  computed: {
    ...mapGetters({
      userPromoterOid: 'auth/userPromoterOid',
    }),
    nextButtonWidth() {
      if(this.currentStep === 'campaigns') {
        return this.$arMediaQuery.window.minWidth('sm') ? '120px' : '90px'
      } else {
        return this.$arMediaQuery.window.minWidth('md') ? '100%' : 'calc(100% - 140px)';
      }
    },
    tourImage() {
      return this.tourViewModel.resources.find(resource => resource.assetType === 'campaign-image');
    },
    previewURL() {
      return this.c2ClientDomain + '/t/' + this.tourViewModel.urlSlug
    },
    previewTour() {
      return createPreviewTour(this.tourViewModel)
    },
    hasTourChanged() {
      if(this.isNewTour) {
        return true;
      }

      let hasChanged = false;
      const changeableTourOptions = ['name', 'urlSlug', 'campaignOids'];
      const oldTour = this.initialTourViewModel;

      changeableTourOptions.forEach(key => {
        hasChanged = hasChanged || (JSON.stringify(oldTour[key]) !== JSON.stringify(this.tourViewModel[key]));
      });

      // initial tour object uses resourceOids, but the new tourViewModel uses resources
      const newResources = this.tourViewModel.resources.map(r => r.oid);
      hasChanged = hasChanged || !arraysEqual(newResources, oldTour.resourceOids);

      return hasChanged;
    }
  },

  async mounted() {
    const query = this.$route.query;

    const campaignOid = parseInt(query.campaignOid);
    const tourOid = query.oid;

    const storedTourJSONString = sessionStorage.getItem('_ar_promoter_new_tour');
    const storedTourViewModel = storedTourJSONString ? JSON.parse(storedTourJSONString) : null;

    if (tourOid) {
      let data;
      try {
        this.isFetchingTour = true;
        data = await this.FETCH_TOUR_BY_OID({
          oid: tourOid
        });

        const { bodyHtml, headHtml, color } = data.presentation;
        const colorData = { hex: color || '#7344c0' }

        this.tourViewModel = {
          oid: data.oid,
          promoterOid: data.promoterOid,
          name: data.name,
          campaignOids: data.campaignOids,
          resources: data.resources,
          urlSlug: data.urlSlug,
          presentation: { bodyHtml, headHtml, colorData }
        };

        // Save originalTourSlugUrl
        this.originalTourSlugUrl = data.urlSlug;

        this.isNewTour = false;
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch tour' });
      } finally {
        this.isFetchingTour = false;
      }

    } else {
      if (storedTourViewModel) {
        this.tourViewModel = storedTourViewModel;
      } else {

        this.tourViewModel = {
          name: '',
          campaignOids: campaignOid ? [campaignOid] : [],
          resources: [],
          resourceOids: [],
          urlSlug: '',
          presentation: { colorData: { hex: '#7344c0' }},
          promoterOid: this.userPromoterOid,
        };

        sessionStorage.setItem('_ar_promoter_new_tour', JSON.stringify(this.tourViewModel));
      }

      this.isNewTour = true;
    }
    this.currentStep = query.step || "details";
    this.initialTourViewModel = createTourDTO(this.tourViewModel);

    this.$nextTick(() => {
      this.$refs['tour-preview']?.setupInitialPreviewData();
    });
  },

  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    const routeRegex = /\/tours\/\d+\/view\/campaigns/;
    const isFinishedPath = to.path.match(routeRegex);

    if(!isFinishedPath && this.hasTourChanged) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if(response) {
        sessionStorage.removeItem('_ar_promoter_new_tour');
        next();
      } else {
        next(false)
      }
    } else {
      sessionStorage.removeItem('_ar_promoter_new_tour');
      next();
    }
  },

  methods: {
    ...mapActions([
      'FETCH_CAMPAIGNS',
      'FETCH_TOUR_BY_OID',
      'SHOW_CONFIRM',
      'CREATE_TOUR',
      'UPDATE_TOUR',
    ]),

    datePeriod(option) {
      if (!option.startDate || !option.endDate) {
        return;
      }

      const utcStartDate = this.$arUtils.date.addZToTimestamp(option.startDate);
      const utcEndDate = this.$arUtils.date.addZToTimestamp(option.endDate);

      let startDate = moment(utcStartDate).format('DD MMM YYYY');
      const endDate = moment(utcEndDate).format('DD MMM YYYY');

      if (startDate === endDate) {
        return `${startDate}`;
      } else {
        if (startDate.substring(7) === endDate.substring(7)){
          startDate = startDate.substring(0, 6);
        }

        return `${startDate} - ${endDate}`;
      }
    },

    campaignImageSrc(option) {
      const optionImage = option.resources.find(resource => resource.assetType === 'campaign-image');

      return optionImage ? optionImage.url : '/images/placeholders/image-default.png';
    },

    maybeUpdateUrlSlug() {
      if (
        this.isNewTour &&
        this.tourViewModel.name &&
        !this.tourViewModel.urlSlug
      ) {
        this.handleUrlSlugChange(this.tourViewModel.name
          .replace(/\s/gi, '-')
          .replace(/[^\da-z-\s]/gi, '')
          .toLowerCase()
        );
      }
    },

    resetHTMLSnippets() {
      this.tourViewModel.presentation.headHtml = '';
      this.tourViewModel.presentation.bodyHtml = '';
      this.isHTMLSnippetsVisible = false;
    },

    async fetchAdapter() {
      const { rows } = await this.FETCH_CAMPAIGNS({ top: 'all' });

      return rows.map(campaign => {
        campaign.subTitle = this.datePeriod(campaign) + (campaign.event.location ? ' | ' + campaign.event.location : '')
        campaign.imageSrc = this.campaignImageSrc(campaign)
        return campaign;

      });
    },

    async handleNextStep() {
      const isValid = await this.$validator.validateAll();

      if(isValid) {
        if(this.currentStep === "details") {
          this.currentStep = 'campaigns'
          let newParams = {
            ...this.$router.currentRoute.query,
            step: 'campaigns',
          };
          this.$router.push({ query: newParams });

        } else {
          let res;
          if(this.tourViewModel.campaignOids.length === 0) return;
          if (this.isNewTour) {
            res = await this.CREATE_TOUR(createTourDTO(this.tourViewModel));
          } else {
            res = await this.UPDATE_TOUR(createTourDTO(this.tourViewModel));
          }
          this.$router.push({
            path: `/tours/${res.oid}/view/campaigns`
          });
        }

      }
    },

    handleUrlSlugChange(urlSlug) {
      this.tourViewModel.urlSlug = urlSlug;
    },

    handlePreviousStep() {
      this.previewIsOpen = false;
      this.currentStep = 'details'
      let newParams = {
        ...this.$router.currentRoute.query,
        step: 'details',
      };

      this.$router.push({ query: newParams });
    },

    handlePreviewClick() {
      this.previewIsOpen = !this.previewIsOpen;
    },

    async handleImageUpload({ arS3BucketResources }) {
      this.handleImageDelete();
      this.tourViewModel.resources.push(arS3BucketResources[0]);
    },

    async handleImageDelete() {
      this.$set(this.tourViewModel, 'resources', this.tourViewModel.resources.filter(
        resource => resource.assetType !== 'campaign-image',
      ));
    },

  }
};
</script>
<style lang="scss" scoped>

.wrapper {
  display: flex;
  padding: 90px 0 0 0;
  height: 100vh;
  > .left {
    display: flex;
    flex-direction: column;
    max-width: $lg-min;
    min-width: $md-min;
    width: 100%;
    height: 100%;
    background: #fff;

    .left-top-section {
      flex-grow: 1;
      padding: 0 80px;
      height: calc(100% - 110px);
      overflow-y: auto;
      .left-top-section-content {
        margin: 50px auto;
        .grey-text {
            color: $blueGrey700;
          }
          .ar-field {
            p {
              margin-bottom: 15px;
            }
          }
        .image-requirements-text {
          margin-top: 14px;
          color: $blueGrey600;
        }
        .image-icon {
          color: $blueGrey700;
        }
      }
    }
    .navigation {
      position: relative;
      width: 100%;
      z-index: $zIndexHighest;
      height: 110px;
      padding: 30px 95px 30px 80px;
      border-top: 1px solid $skyBlueGrey500;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
  .right {
    max-width: $md-min;
    padding: 50px;

    .preview {
      max-height: 780px;
    }
  }

  &.sm-max {
    padding: 0;
    position: relative;
    display:block;

    .navigation {
      height: 76px;
      min-height: 76px;
      padding: 10px 12px;
      position: fixed;
      bottom: 0;
    }

    > .left {
      max-width: 100vw;
      min-width: 100vw;
      position: absolute;
      top: 66px;
      height:auto;
      .inner {
        // padding: 30px 12px 90px;
        min-height: 0;
        overflow:scroll;
        height:auto;
        z-index: $zIndexRegular;
      }

    }
    .right {
      padding: 20px 12px 92px;
      justify-content: center;
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100vh - 72px);
      background-color:#FFF;
      opacity:0;
      z-index: $zIndexLow;
      transition: opacity 0.2s;
      &.is-visible {
        opacity: 1;
        z-index: $zIndexHigh;
      }
    }
  }
}

</style>
