<template>
  <EditableFrame
    class="trigger-webhook-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
   <ar-text
      class="trigger-webhook-heading"
      size="13px"
      text="WEBHOOK"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <ar-input
      ref="triggerWebhook"
      class="trigger-webhook-input"
      :style="{
        height: '40px'
      }"
      v-model="inputText"
      @enter="handleSave"
      placeholder="Please enter webhook..."
      auto-focus
    />
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'triggerWebhook',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      inputText: ''
    }
  },
  components: {
    EditableFrame,
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
  },
  computed: {
    savedButtonDisabled() {
      return !(!!this.inputText)
    }
  },
  methods: {
    ...mapActions({
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleSave() {
      let contextData = {
        config: {
          'shared-secret': this.inputText,
        },
      }
      
      this.updateTriggerContextData({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    resetDefaults() {
      this.inputText = ''
    },
    setItemVals() {
      this.resetDefaults()
      if (this.item.config?.['shared-secret']) {
        this.inputText = this.item.config['shared-secret']
        this.$refs.triggerWebhook.$el.children[1].blur()
      }
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.trigger-webhook-wrapper {
  width: 100%;
  height: 100%;

  .trigger-webhook-heading {
    margin-bottom: 14px;
  }
}
</style>