var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',[_c('template',{slot:"right-upper-section"},[(_vm.arEnableOnboardingProcess && _vm.$arMediaQuery.window.maxWidth('xs'))?_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"xxxs","text":"Need an account?"}}),_vm._v(" "),_c('ar-link-button',{style:({
          marginLeft: '4px',
        }),attrs:{"text":"Create account here","text-props":{
          size: 'xxxs',
        }},on:{"click":_vm.handleCreateAccountLinkClick}})],1):_vm._e()]),_vm._v(" "),_c('ar-text',{style:({
      marginBottom: '20px',
    }),attrs:{"text":_vm.isMasqueradeLoginPage ? 'Masquerade Login' : 'Login',"size":"lg","multiple-lines":""}}),_vm._v(" "),_c('form',{style:({
      marginTop: '40px',
    }),on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-lpignore":"true","name":"email","id":"email","autocomplete":"email","data-vv-name":"email","type":"email","auto-focus":"","placeholder":"Email address","has-error":_vm.veeErrors.has('email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.veeErrors.has('email')),expression:"veeErrors.has('email')"}],style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('email')}})],1),_vm._v(" "),_c('div',{style:({
        marginTop: '20px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:256'),expression:"'required|max:256'"}],staticClass:"ar-form-control",class:{'is-danger': _vm.veeErrors.has('password')},attrs:{"data-lpignore":"true","type":"password","name":"password","id":"password","data-vv-name":"password","placeholder":"Password","has-error":_vm.veeErrors.has('password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.veeErrors.has('password')),expression:"veeErrors.has('password')"}],style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('password')}})],1),_vm._v(" "),(_vm.isMasqueradeLoginPage)?_c('div',{style:({
        marginTop: '20px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-lpignore":"true","name":"target-account-email","id":"target-account-email","data-vv-name":"targetAccountEmailAddress","type":"email","auto-focus":"","placeholder":"Target account email address","has-error":_vm.veeErrors.has('targetAccountEmailAddress')},model:{value:(_vm.targetAccountEmailAddress),callback:function ($$v) {_vm.targetAccountEmailAddress=$$v},expression:"targetAccountEmailAddress"}}),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.veeErrors.has('targetAccountEmailAddress')),expression:"veeErrors.has('targetAccountEmailAddress')"}],style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('targetAccountEmailAddress')}})],1):_vm._e(),_vm._v(" "),(!_vm.isMasqueradeLoginPage && _vm.arEnableOnboardingProcess)?_c('div',{style:({
        display: 'flex',
        justifyContent: 'flex-end',
      })},[_c('ar-link-button',{style:({
          marginTop: '18px',
        }),attrs:{"text":"Forgot password?","text-props":{
          size: 'xxxs',
        }},on:{"click":_vm.handleForgotPasswordLinkClick}})],1):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({
        marginTop: '28px',
        width: '100%',
      }),attrs:{"loading":_vm.isDoingLogin,"type":"gradient-purple","shape":"pill","text":_vm.loginButtonText,"disabled":_vm.nextLoginRetryCountdown > 0}})],1),_vm._v(" "),(_vm.isMasqueradeLoginPage)?_c('div',{style:({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px',
    })},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xxxs","text":"This is masquerade login page, you can go back to general "}}),_vm._v(" "),_c('ar-link-button',{style:({
        marginLeft: '4px',
      }),attrs:{"size":"xxxs","text":"login page"},on:{"click":_vm.handleGoToLoginPageClick}})],1):(_vm.arEnableOnboardingProcess && _vm.$arMediaQuery.window.minWidth('sm'))?_c('div',{style:({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px',
    })},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xxxs","text":"Need an account?"}}),_vm._v(" "),_c('ar-link-button',{style:({
        marginLeft: '4px',
      }),attrs:{"text":"Create account here","text-props":{
        size: 'xxxs',
      }},on:{"click":_vm.handleCreateAccountLinkClick}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }