var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-field',{staticClass:"registration-fields",attrs:{"name":"registration-fields","label":"Registration fields"}},[_c('draggable',{attrs:{"list":_vm.value}},[_c('transition-group',{attrs:{"name":"overlay-fade","tag":"div"}},_vm._l((_vm.value),function(field,idx){return _c('div',{key:field.key,attrs:{"data-test-id":("registration-field-" + (field.key))}},[_c('div',{class:['u-display-flex', 'u-align-items-center', 'u-justify-content-space-between'],style:({ height: '52px' })},[_c('div',{class:['u-display-flex', 'u-align-items-center']},[_c('ar-text',{staticClass:"u-flex-grow-1",attrs:{"size":"xs","text":field.name,"data-test-id":("registration-field-" + idx + "-text")}}),_vm._v(" "),(!field.predefined)?_c('ar-icon',{staticClass:"icon edit-button",attrs:{"name":"edit","height":"10px","width":"10px","stroke-width":"4","stroke-linecap":"round","color":_vm.$arStyle.color.blueGrey700,"data-test-id":("registration-field-" + idx + "-edit-button")},on:{"click":function($event){return _vm.handleEditCustomField(field)}}}):_vm._e()],1),_vm._v(" "),(!(_vm.mandatoryRegistrationFields.indexOf(field.key) > -1))?_c('ar-icon',{staticClass:"icon remove-button",attrs:{"name":"cross","height":"10px","width":"10px","stroke-width":"4","stroke-linecap":"round","color":_vm.$arStyle.color.blueGrey700,"data-test-id":("registration-field-" + idx + "-remove-button")},on:{"click":function($event){return _vm.handleRemoveField(field)}}}):_vm._e()],1),_vm._v(" "),_c('ar-divider')],1)}),0)],1),_vm._v(" "),_c('div',{staticClass:"u-margin-top-5"},[_c('am2-link-button-dropdown',{attrs:{"placeholder":"+ Add field","items":_vm.filteredFields,"search-placeholder":"Search fields","has-search":"","no-option-text":"No other fields found","max-height":"300px","dropdown-style":{
        width: '280px',
      },"button-props":{
        hasArrow: false,
        hasUnderline: true,
        textProps: {
          size: 'xs',
        },
      },"hasFixedFooter":!_vm.isFreeCampaign,"data-test-id":"registration-fields-add-field-dropdown"},on:{"searchStringChange":_vm.handleSearchStringChange,"select":_vm.handleSelectField,"fixedFooterClick":_vm.addCustomFieldClick}})],1),_vm._v(" "),_c('CustomFieldsModal',{attrs:{"is-open":_vm.displayCustomFieldsModal,"editCustomField":_vm.editCustomField},on:{"save":_vm.handleCustomFieldSave,"cancel":_vm.handleCustomFieldCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }