<template>
  <section
    class="pseudo-input"
    ref="tag-input-container"
    @click="pseudoInputFocus"
    :style="{
      fontSize: inputFontSizeInPixel,
    }"
  >
    <ul
      class="saved-tags"
      ref="tag-list"
    >
      <li class="tag"
        :key="tag"
        v-for="(tag, index) in selectedTags"
      >
        <ar-text
          size="sm"
          v-bind:text="tag | capitalize"
          class="name"
        />
        <div
          class="delete-btn"
          @click.stop.prevent="() => removeTag(index)"
        >
          <ar-icon
            class="icon"
            name="cross"
            height="10px"
            width="10px"
            stroke-width="4"
            stroke-linecap="round"
          />
        </div>
      </li>
      <input
        ref="input"
        class="input"
        v-model="currentInput"
        autocomplete="tag"
        :placeholder="placeholder"
        @keydown.enter="handleEnteredInput"
        @keydown.backspace="deleteLastTag"
        @focus="openTagOptions"
        @input="handleInput"
      >
    </ul>
    <div
      ref="invisible-toggle-box"
      :style="{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: this.$arStyle.zIndex.globalHighest,
        display: showOptionsWhenAvailable ? 'block' : 'none',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      }"
      @click.stop.prevent="closeTagOptions"
    />
    <div
      ref="tag-options-box-container"
      :style="{
        display: 'inline-block',
        position: 'fixed',
        top: dialogBoxPosition.top,
        left: dialogBoxPosition.left,
        right: dialogBoxPosition.right,
        bottom: dialogBoxPosition.bottom,
        width: dialogBoxPosition.width,
        height: showOptionsWhenAvailable ? dialogBoxPosition.height : '0',
        opacity: showOptionsWhenAvailable ? '1' : '0.7',
        overflow: 'hidden',
        transition: 'height 0.2s, opacity 0.2s',
        zIndex: this.$arStyle.zIndex.globalHighest + 1,
      }"
    >
        <!-- width: tagOptionsWidth, -->
      <TagOptionsBox
        ref="tag-option-box"
        :items="availableTags"
        :style="tagOptionBoxStyling"
        @select="handleSelect"
        @close="closeTagOptions"
        :show-tag-count="showTagCount"
        :input-value="currentInput"
        :allow-new-values="allowCustomInput"
      />
    </div>
  </section>
</template>

<script>
import TagOptionsBox from './TagOptionsBox';
import { debounce } from "debounce";
import { mapGetters } from 'vuex';

export default {
  name: 'TagInput',
  components: {
    TagOptionsBox,
  },
  model: {
    prop: 'selectedTags',
    event: 'change'
  },
  props: {
    selectedTags: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableTags: {
      type: Array,
      default: () => [],
    },
    allowCustomInput: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Add a tag',
    },
    showTagCount: {
      type: Boolean,
      default: true,
    },
    inputFontSize: {
      type: String,
      default: 'xs',
      validator: (val) => ['xxs', 'xs', 'sm', 'md', 'lg'].indexOf(val) > -1,
    },
    checkForInvalidCharacters: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentInput: null,
      showTagOptions: false,
      dialogBoxPosition: {},
      handleInput: debounce((event) => {
        this.handleInputChange(event);
      }, 250),
    };
  },
  watch: {
    availableTags() {
      // This might change the size of tag options box
      this.$nextTick(() => {
        this.updateTagOptionsPosition();
      });
    },
    currentInput() {
      // This might change the size of tag options box
      this.$nextTick(() => {
        this.updateTagOptionsPosition();
      });
    },
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    this.removeTagOptionsBoxToBody();
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.attachTagOptionsBoxToBody();
  },
  methods: {
    attachTagOptionsBoxToBody() {
      document.body.appendChild(this.$refs['invisible-toggle-box']);
      document.body.appendChild(this.$refs['tag-options-box-container']);
    },
    removeTagOptionsBoxToBody() {
      document.body.removeChild(this.$refs['invisible-toggle-box']);
      document.body.removeChild(this.$refs['tag-options-box-container']);
    },
    pseudoInputFocus() {
      this.$refs['input'].focus();
    },
    handleInputChange(event) {
      let inputValue = event.target.value;
      if (inputValue.charAt(inputValue.length - 1) === ',') {
        this.addNewTag(inputValue.substring(0, inputValue.length - 1))
      }

      this.$emit('inputTextChange', inputValue);
    },
    handleSelect(itemName) {
      this.addNewTag(itemName);
    },
    handleEnteredInput(event) {
      if (this.allowCustomInput) {
        if (this.currentInput && this.currentInput.trim()) {
          this.addNewTag(this.currentInput.trim());
        }
      } else if (this.currentInput && this.currentInput.trim()) {
        const tagNames = this.availableTags.map(tag => tag.name);
        if (tagNames.indexOf(this.currentInput.trim()) >= 0) {
          this.addNewTag(this.currentInput.trim());
        }
      }
    },
    deleteLastTag() {
      if (!this.currentInput && this.selectedTags.length > 0) {
        this.selectedTags.splice(this.selectedTags.length - 1, 1);
        this.$emit('change', this.selectedTags);
      }
    },
    invalidCharactersInTagName(newTagName) {
      let bannedCharacters = /[()<>=\\"]+/g;
      return newTagName.match(bannedCharacters) || [];
    },
    addNewTag(newTagName) {
      const invalidChars = this.invalidCharactersInTagName(newTagName);
      if (this.checkForInvalidCharacters && invalidChars.length > 0) {
        this.$arNotification.push({ type: 'error', message: `Your tag contains invalid characters - ${invalidChars.join(" ")}` });
        this.currentInput = null;
        return;
      }
      if (this.selectedTags.indexOf(newTagName) === -1) {
        this.selectedTags.push(newTagName);
        this.$emit('change', this.selectedTags);
      }

      this.currentInput = null;
      this.closeTagOptions();
    },
    removeTag(index) {
      this.selectedTags.splice(index, 1);
      this.$emit('change', this.selectedTags);
    },
    handleScroll(event) {
      event.preventDefault();
      window.scrollTo(window.scrollX, this.lockScrollY);
      event.stopPropagation();
    },
    openTagOptions() {
      if (!this.showTagOptions) {
        this.showTagOptions = true;
        this.updateTagOptionsPosition();

        // local scroll
        this.lockScrollY = window.scrollY;
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    closeTagOptions(event) {
      if (this.showTagOptions) {
        window.removeEventListener('scroll', this.handleScroll)
        this.showTagOptions = false;
      }
    },
    handleWindowResize() {
      this.updateTagOptionsPosition();
    },
    updateTagOptionsPosition() {
      const selectBoxHt = this.$refs['tag-option-box'].$el.offsetHeight;
      const elementHt = this.$el.offsetHeight;
      const elementWd = this.$el.offsetWidth;
      const { top, left, bottom } = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const padding = 5;
      if (selectBoxHt + top + elementHt > windowHeight) {
        this.dialogBoxPosition = {
          bottom: `${windowHeight - top + 5 - padding}px`,
          left: `${left - 1 - padding}px`,
          width: `${elementWd + 2 + padding * 2}px`,
          height: `${selectBoxHt + padding * 2}px`,
          padding: `${padding}px`
        };
      } else {
        this.dialogBoxPosition = {
          top: `${top + elementHt + 5 - padding}px`,
          left: `${left - 1 - padding}px`,
          width: `${elementWd + 2 + padding * 2}px`,
          height: `${selectBoxHt + padding * 2}px`,
          padding: `${padding}px`
        };
      }
    },
  },
  computed: {
    showOptionsWhenAvailable() {
      return this.showTagOptions && (this.currentInput || this.availableTags.length > 0)
    },
    tagOptionsWidth() {
      if (this.$refs['tag-input-container']) {
        return `${this.$refs['tag-input-container'].clientWidth}px`;
      } else {
        return '100%';
      }
    },
    tagOptionBoxStyling() {
      return {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '5px',
        background: 'white',
        border: '1px solid #dcdee4',
        minWidth: '80px',
        overflow: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        width: `calc(100% - ${this.dialogBoxPosition.padding} - ${this.dialogBoxPosition.padding})`,
        margin: this.dialogBoxPosition.padding,
        maxHeight: '200px',
        boxShadow: '1px 2px 8px 1px rgba(0, 0, 0, .07)',
      };
    },
    inputFontSizeInPixel() {
      // Mobile font-sizes MUST use 16px font minimum for inputs. Any less and browsers will helpfully zoom the user's browser in when an input goes into focus.
      // Unfortunately, they don't always end up zooming out after.
      const filteredOutFontSizes = ['xxs', 'xs'];
      const newInputFontSize = this.$arMediaQuery.window.maxWidth('xs') && filteredOutFontSizes.indexOf(this.inputFontSize) > -1 ? 'sm' : this.inputFontSize;
      return this.$arStyle.font.size[newInputFontSize];
    },
  }
};
</script>

<style lang="scss" scoped>
.pseudo-input {
  width: 100%;
  border: 1px solid $skyBlueGrey500;
  border-radius: 4px;
  padding: 10px;

  // Remember, the fake input isn't *technically* focused, just the child input
  // we can use the :focu-within pseudo-selector to check if .input is focused
  &:focus-within {
    border: 1px solid $green500;
    box-shadow: 0 0 0 3px $green200;
  }

  .saved-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 0; // chrome styling seems to want to put a 40px left-padding for some reason?

    .tag {
      // layout
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: white;
      background-color: $purple500;
      border-radius: 100px;
      height: 30px;
      padding: 0 6px 0 15px;
      margin: 5px 5px 5px 0;

      .name {
        max-width: 250px;
        color: white;
        margin-right: 10px;
      }

      .delete-btn {
        background-color: $purple400;
        border-radius: 50%;
        height: 20px;
        width: 20px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: $purple600;
        }

        .cross {
          color: white;
        }
      }
    }

    .add-new-tag-action {
      color: $purple500;
    }
  }


  .input {
    border: none;
    outline: none;
    margin: 5px 0;
    width: 100%;

    // Be aware of it, our font has different alignment on Firefox
    // potentially on other browsers as well.
    -moz-transform: translateY(5.5%);
  }
}
</style>
