<template>
  <AuthenticateFrame>
    <ar-text
      text="Reset password"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '40px',
      }"
    />
    <form @submit.prevent="doResetPassword">
      <ar-input
        v-validate="'required|arPasswordLength'"
        v-model="password"
        data-vv-name="password"
        placeholder="New password"
        autocomplete="new-password"
        type="password"
        :has-error="veeErrors.has('password')"
      />
      <ar-state-message
        v-show="veeErrors.has('password')"
        type="error"
        :text="veeErrors.first('password')"
        :style="{ marginTop: '8px' }"
      />
      <ar-input
        v-validate="{
          required: true,
          matchPassword: password,
          arPasswordLength: true,
        }"
        v-model="confirmPassword"
        data-vv-name="confirmPassword"
        placeholder="Confirm password"
        autocomplete="new-password"
        :style="{ marginTop: '10px' }"
        type="password"
        :has-error="veeErrors.has('confirmPassword')"
      />
      <ar-state-message
        v-show="veeErrors.has('confirmPassword')"
        type="error"
        :text="veeErrors.first('confirmPassword')"
        :style="{ marginTop: '8px' }"
      />
      <ar-simple-button
        :loading="isResettingPassword"
        type="gradient-purple"
        shape="pill"
        text="Reset Password"
        :style="{
          marginTop: '40px',
          width: '100%',
        }"
      />
    </form>
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';

import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'reset-password',
  layout: 'split',
  components: {
    AuthenticateFrame,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapState({
      verifyToken: state => state.route.query.verifyToken,
      expiresAt: state => state.route.query.expiresAt,
      emailAddress: state => state.route.query.emailAddress,
      isResettingPassword: state => state.auth.isResettingPassword,
    }),
  },
  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);
    this.checkExpiration();

    this.$validator.dictionary.merge({
      en: {
        custom: {
          password: {
            required: 'This new password is required',
          },
          confirmPassword: {
            required: 'This confirm password is required',
          },
        },
      },
    });
  },
  methods: {
    ...mapActions([
      'auth/RESET_FORGOTTEN_PASSWORD',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    checkExpiration() {
      if (moment(this.expiresAt).isBefore(moment())) {
        this.$arNotification.push({
          type: 'error',
          message: 'Your reset password URL has expired. Generate a new one below',
          timeout: 5000,
        });

        this.$router.push({
          path: '/authenticate/forgot-password',
          query: { emailAddress: this.emailAddress },
        });
      }
    },
    async doResetPassword() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this['auth/RESET_FORGOTTEN_PASSWORD']({
        password: this.password,
        verifyToken: this.verifyToken,
        emailAddress: this.emailAddress,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.authenticate-signup {
}
</style>
