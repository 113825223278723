<template>
  <div id="automation-planner" class="planner-wrapper" @keydown.esc="handleEsc" tabindex="0" @scroll="handleScroll" @click="checkIfClickAway">
    <div v-if="loading" class="automation-loading-wrapper">
      <am2-loading-spinner size="48px" />
    </div>
    <div id="actions-tree-container" class="actions-tree-container" :style="`transform: scale(${zoomVal})`">
      <DraggableTriggers @rendered="setTriggersRendered" />
      <template v-if="triggersRendered">
        <div class="actions-row-wrapper" v-for="(actionRow, i) in actions" :key="i">
          <div class="actions-row-container">
            <template v-for="(box, index) in actionRow">
              <ActionBox
                :key="setKey(index)"
                :box="box"
                :class="[
                  'action-box',
                  isSubPlaceholder(box),
                  setBoxWidthClass(actionRow, i, box, index)
                ]"
              />
              <template v-if="i === actions.length - 1 && index === actionRow.length - 1">
                <pre :key="`${setPositionDetails(index)}`" style="display: none;"></pre>
              </template>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import DraggableTriggers from '../DraggableTriggers'
import ActionBox from '../ActionBox'
import { generateRandomString } from '@/utils/helpers'
export default {
  name: 'AutomationPlanner',
  components: {
    DraggableTriggers,
    ActionBox,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      scrollWidth: null,
      isScrolling: null,
      triggersRendered: false,
      updateKey: '',
      triggersLengthLocal: 0,
      loading: true,
    }
  },
  watch: {
    loaded() {
      if (this.loaded) {
        setTimeout(()=> {
          this.loading = false
        }, 500)
      }
    },
    actions() {
      if (!!this.actions.length) {
        this.updateKey = generateRandomString()
        this.$nextTick(() => {
          this.adjustLeaderLineText()
        })
      }
    },
    actionConfigHasUpdated() {
      this.updateKey = generateRandomString()
      this.$nextTick(() => {
        this.adjustLeaderLineText()  
      })
    },
    zoomVal(newVal, oldVal) {
      if (newVal <= 0.8 && oldVal > 0.8) {
        this.adjustLeaderLineFontSize('10px')
      } else if (newVal > 0.8 && oldVal <= 0.8) {
        this.adjustLeaderLineFontSize('12px')
      } else if (newVal <= 0.5) {
        this.adjustLeaderLineFontSize('9px')
      }
    }
  },
  computed: {
    ...mapState({
      triggers: state => state.automation.triggers.items,
      actions: state => state.automation.actions,
      isEditing: state => !!state.automation.editableItem,
      editableItem: state => state.automation.editableItem,
      zoomVal: state => state.automation.zoomVal,
      scrollVals: state => state.automation.scrollVals,
      actionConfigHasUpdated: state => state.automation.actionConfigHasUpdated,
      sidebarActive: state => !!state.automation.editableItem,
    }),
    ...mapGetters({
      editableId: 'automation/getEditableId',
    }),
    triggersLength() {
      return this.triggers.length
    },
    actionsList() {
      return this.actions
    },
    resetToTab() {
      if (this.editableItem.type === 'action' || this.editableItem.type === 'control') {
        return 'actions'
      }
      return 'triggers'
    },
  },
  destroyed() {
    let lines = document.getElementsByClassName('leader-line')
    let i = lines.length
    while (i--) {
      lines[i].remove()
    }
  },
  methods: {
    ...mapActions({
      clearEditable: 'automation/CLEAR_EDITABLE',
      setIsScrolling: 'automation/SET_IS_SCROLLING',
    }),
    checkIfClickAway(e) {
      let clickedElClass = e.target.classList[0]
      if (clickedElClass === 'action-item-wrapper' || clickedElClass === 'trigger-item-wrapper' || !this.sidebarActive) return
      this.clearEditable(this.resetToTab)
    },
    adjustLeaderLineText() {
      this.adjustLeaderLineTextPosition()
      if (this.zoomVal <= 0.5) {
        return this.adjustLeaderLineFontSize('9px')
      } else if (this.zoomVal <= 0.8) {
        return this.adjustLeaderLineFontSize('10px')
      } else {
        return this.adjustLeaderLineFontSize('12px')
      }
    },
    adjustLeaderLineTextPosition() {
      let lines = document.getElementsByClassName('leader-line')
      let i = lines.length
      while (i--) {
        if (!lines[i].childNodes[2]) continue
        lines[i].childNodes[2].style.transform = 'translateY(-10px)'
      }
    },
    adjustLeaderLineFontSize(fontSize) {
      let lines = document.getElementsByClassName('leader-line')
      let i = lines.length
      while (i--) {
        if (!lines[i].childNodes[2]) continue
        lines[i].childNodes[2].style.fontSize = fontSize
      }
    },
    setKey(index) {
      return `${this.updateKey}-${index}`
    },
    setTriggersRendered() {
      this.triggersRendered = true
      if (this.triggersLength !== this.triggersLengthLocal) {
        this.triggersLengthLocal = this.triggersLength
        
        this.updateKey = generateRandomString()
        this.$nextTick(() => {
          this.adjustLeaderLineTextPosition()
        })
      }
    },
    handleScroll() {
      this.setIsScrolling({})
      window.clearTimeout(this.isScrolling)

      this.isScrolling = setTimeout(() => {
        this.setIsScrolling(null)
        this.isScrolling = null
      }, 60)
    },
    setPositionDetails() {
      this.$nextTick(() => {
        this.loading = false
        if (this.scrollWidth) return
        let parentScrollDiv = document.getElementById('automation-planner')
        let currentScrollWidth = parentScrollDiv.scrollWidth
        parentScrollDiv.scrollLeft = (currentScrollWidth - parentScrollDiv.clientWidth)/2
        this.scrollWidth = currentScrollWidth

        this.adjustLeaderLineTextPosition()
      })
    },
    handleEsc() {
      if (!this.isEditing) return
      this.clearEditable(this.resetToTab)
    },
    setBoxWidthClass(actionRow, i, box, index){
      if (box?.block?.key !== 'if-else') {
        if (i !== 0 && i + 1 <= this.actions.length) {
          // if not an if/else block BUT it's child is an if/else block,
          // take up 700px width. This was added as adjacent actions were
          // 'swerving' in and then swerving back out on the next row.
          // By taking up 700px width at this level it stopped the swerving
          // of adjacent blocks and kept the flow straight.
          if (!!this.actions[i+1] && this.actions[i + 1][index]?.block?.key === 'if-else' && !!this.actions[i + 1][index + 1] && (this.actions[i + 1][index + 1]?.type !== 'action-prompt' && this.actions[i + 1][index + 1]?.type !== 'placeholder')) {
            if (!!this.actions[i][index + 1] && this.actions[i][index + 1]?.branch) {
              return 'w-700'
            }
          }
        }

        // it's not an if/else block, set normal width class
        return 'w-325'
      } else{
        if (index - 1 >= 0 && actionRow[index - 1]?.type === 'action-prompt') {
          return 'w-325'
        }
        if (index - 1 >= 0 && actionRow[index - 1]?.type === 'placeholder') {
          return 'w-325'
        }
        if (index + 1 === actionRow.length) {
          // it's the last item in the row, set max width
          return 'w-700'
        }
        if (actionRow[index + 1]?.type === 'action-prompt') {
          // next item in row is just a placeholder, so won't have anything below it
          // that needs space - set normal width class
          return 'w-325'
        }
        if (actionRow[index + 1]?.type === 'placeholder') {
          // next item in row is just a placeholder, so won't have anything below it
          // that needs space - set normal width class
          return 'w-325'
        }
        if (index === 0 && actionRow[index+1]?.block?.key === 'if-else') {
          return 'w-700'
        }
        // set standard, 2x width for if/else block
        return 'w-700'
      }
    },
    isIfElseBlock(box) {
      return box?.block?.key === 'if-else'
    },
    isSubPlaceholder(block) {
      if (block?.type === 'placeholder') return 'hidden'
    },
    handleTriggerAdd(event) {
      let item = event.item._underlying_vm_
      this.addTrigger(item)
    },
  }
}
</script>
<style lang="scss" scoped>
$autoItemWidth: 325px;

@mixin autoItemDefaults {
  padding: 9px 11px;
  border-radius: 3px;
  border: solid 0.2px rgba(194, 194, 194, 0.3);
}
@mixin flexRow {
  display: flex;
  flex-flow: row nowrap;
}
@mixin flexCol {
  display: flex;
  flex-flow: column nowrap;
}

.planner-wrapper {
  width: 100%;
  overflow: scroll;
  @include flexCol;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 168px);
  padding: 28px 25px 200px 35px;
  transform-origin: top center;
  position: relative;
  z-index: $zIndexHigh;

  &:focus {
    outline: none;
  }

  .automation-loading-wrapper {
    background: rgba(237, 242, 249, 0.9);
    width: 180%;
    position: absolute;
    top: 0;
    left: -40%;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: $zIndexGlobalRegular;
  }

  .actions-tree-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    transform-origin: top;
    align-self: flex-start;
    min-width: 100%;
    padding-top: 20px;

    .actions-row-wrapper {
      @include flexRow;
      align-items: center;
      justify-content: center;
      width: 100%;

      .actions-row-container {
        width: 100%;
        @include flexRow;
        align-items: flex-start;
        justify-content: center;

        .action-box {
          @include flexCol;
          align-items: center;
          justify-content: center;
          margin: 0 25px;
          width: $autoItemWidth;

          &.hidden {
            opacity: 0;
          }

          &.w-325 {
            width: $autoItemWidth;
          }

          &.w-700 {
            width: 700px;
          }
        }
      }
    }
  }
}
</style>