var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"stats-list"},[_c('div',{class:['stats-content', _vm.$arMediaQuery.pageContent.minWidth('sm')&& 'sm-min']},[_c('table',{class:!_vm.hasRowData ? 'empty' : null},[_c('thead',[_c('tr',[_c('th',{staticClass:"label"},[_vm._v(_vm._s(_vm.columnNames[0]))]),_vm._v(" "),_c('th',{staticClass:"label",style:({
            minWidth: '75px'
          })},[_vm._v(_vm._s(_vm.columnNames[1]))]),_vm._v(" "),_c('th',{staticClass:"value",style:({
            minWidth: '75px'
          })},[_vm._v(_vm._s(_vm.columnNames[2]))])])]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',{staticClass:"left"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.displayItem(_vm.data[_vm.orderId])}})],1),_vm._v(" "),_c('td',{style:({
            minWidth: '75px'
          })},[_c('ar-text',{attrs:{"size":"xs","text":_vm.data[_vm.orderId].totalRevenue.displayValue}})],1),_vm._v(" "),_c('td',{staticClass:"right",style:({
            minWidth: '75px'
          })},[_c('ar-text',{attrs:{"size":"xs","text":_vm.data[_vm.orderId].totalRevenue.displayValue,"align":"right"}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }