export const generateRegisterAddDynamicTagsToolScript = function () {
  return `
    window.unlayer.registerTool({
      name: 'add_dynamic_tags',
      icon: '${process.env.arAudienceManagerUrl}/images/unlayer/dynamic-tags.svg',
      label: 'Dynamic Tags',
      values: {},
      options: {},
      renderer: {
        Viewer: unlayer.createViewer({
          render(values) {
            return "<div>Add Dynamic Tags Tool is not for rendering contents purpose, please delete this if by any reasons you see this.</div>"
          }
        }),
        exporters: {
          web: function(values) {
            return "<div>Add Dynamic Tags Tool is not for rendering contents purpose, please delete this if by any reasons you see this.</div>"
          },
          email: function(values) {
            return "<div>Add Dynamic Tags Tool is not for rendering contents purpose, please delete this if by any reasons you see this.</div>"
          }
        }
      },
    });
    // Customise Click Event of Dynamic Tags Tool
    setTimeout(function () {
      var allTools = document.getElementsByClassName('blockbuilder-content-tool');
      var addDynamicTagsTool = null;
      for (var i = 0; i < allTools.length; i += 1) {
        if (allTools[i].textContent === 'Dynamic Tags') {
          addDynamicTagsTool = allTools[i];
        }
      }

      // If you can't find dynamic tags tool in Unlayer, we hide it.
      // Then we let our component know it's not found
      if (!addDynamicTagsTool) {
        window.parent.postMessage({
          type: 'add-dynamic-tags-tool-not-found'
        }, '*');
        return;
      }

      addDynamicTagsTool.setAttribute('draggable', false);
      addDynamicTagsTool.style.cursor = 'pointer';
      addDynamicTagsTool.addEventListener('click', function () {
        window.parent.postMessage({
          type: 'add-dynamic-tags-tool-click'
        }, '*');
      }, false);
    }, 500);
  `;
};