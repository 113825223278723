<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="edit-fan-tags-modal"
      :header="headerTitle"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body"
          :class="[
            'edit-fan-tags-modal-body',
            $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
          ]">
        <div :style="{ marginBottom: '14px' }">
          <label class="label">Tags</label>
          <am2-fan-tag-input
            :placeholder="placeholder"
            v-model="selectedTags"
            :allowCustomInput="!(this.action === 'remove')"
          />
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'edit-fan-tags-modal-footer',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]">
        <ar-simple-button
          text="Save"
          :style="{ width: '108px' }"
          :disabled="selectedTags.length === 0"
          @click="handleSaveClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { formatInteger, capitalizeFirstLetter } from '@/utils/helpers';

export default {
  name: 'EditFanTagsModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: null,
      validator: (val) => ['add', 'remove'].indexOf(val) > -1,
    },
    searchString: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedTags: [],
    }
  },

  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
    }),
    ...mapGetters({
      prunedScratchSegment: 'segment/prunedScratchSegment',
      totalSelectedAudienceCount: 'audience/totalSelectedAudienceCount',
      partlySelectedAudience: 'audience/partlySelectedAudience',
    }),
    appliedFilter() {
      return this.audienceSelection.useScratchSegment ? this.prunedScratchSegment.filter : { conditions: [], logic: [] }
    },
    formattedFanCount() {
      return formatInteger(this.totalSelectedAudienceCount);
    },
    headerTitle() {
      return "Enter a tag";
    },
    placeholder() {
      return capitalizeFirstLetter(`${this.action} a tag`);
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.selectedTags = [];
      }
    },
  },

  methods: {
    ...mapActions([
      'MASS_EDIT_FAN',
    ]),
    async handleSaveClick() {
      try {
        const payload = {
          fanOids: this.partlySelectedAudience.length > 0 ? this.partlySelectedAudience.map(fan => fan.oid) : null,
          filter: this.appliedFilter,
          searchString: this.searchString,
          tags: {
            [this.action]: this.selectedTags,
          },
        };

        const response = await this.MASS_EDIT_FAN(payload);

        const successMessage = this.action === 'add' ?
          `Successfully added tags to ${this.formattedFanCount} contacts` :
          `Successfully removed tags from ${this.formattedFanCount} contacts`;

        this.$arNotification.push({
          type: 'success',
          message: successMessage
        });

        this.$emit('edit');
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: typeof(e) === 'string' ? e : "There was a problem processing your request" });
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-fan-tags-modal {
  .edit-fan-tags-modal-body {
    padding: 20px 30px 30px;
    .label {
      display: block;
      font-size: 14px;
      color: $blueGrey800;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 4px;
      font-weight: bold;
    }
  }
  .edit-fan-tags-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;

    &.xs-max {
      padding: 12px;
      height: 76px;
    }
  }
}
</style>
