<template>
  <ar-modal
    :is-open="showModal"
    class="cancellation-modal"
    width="590px"
    hide-header
    hide-footer
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleCloseClick"
  >
    <div
      slot="body"
      :class="[
        'cancellation-modal-body',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5',
      ]"
    >
      <div class="exclamation-mark">
        <ar-icon
          v-bind="{
            name: 'exclamation-mark',
            color: this.$arStyle.color.red500,
            width: '34px',
            height: '34px',
            wrapperStyle: {
              backgroundColor: this.$arStyle.color.red100,
              borderRadius: '50%',
              width: '70px',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          }"
        />
      </div>
      <ar-text
        v-if="title"
        class="cancellation-modal-title"
        :text="title"
        multiple-lines
        weight="bold"
        align="center"
        size="md"
      />
      <ar-text
        v-if="messageHtmlBefore"
        class="cancellation-modal-message u-margin-top-5"
        size="xs"
        :text="messageHtmlBefore"
        allow-html
        multiple-lines
        line-height="25px"
        align="center"
      />

      <div class="feature-list-container u-margin-top-4" v-if="featureList && featureList.length > 0">
        <div
          class="feature-to-be-cancelled"
          v-for="(feature, idx) in featureList"
          :key="idx">
          <div class="feature-name">
            <!-- <ar-avatar
              :image-path="feature.image"
              size="30px"
            /> -->
            <ar-text
              class="feature-name"
              size="xs"
              :text="feature.name"
              multiple-lines
              line-height="25px"
            />
          </div>
          <div class="feature-expiry">
            <ar-text
              v-if="feature.expiry"
              class="expiry-date"
              size="xs"
              :text="`Ends <strong>${feature.expiry}</strong>`"
              multiple-lines
              allow-html
              line-height="25px"
              align="right"
            />
            <ar-text
              v-else-if="feature.subscribers"
              class="expiry-date"
              size="xs"
              :text="`<strong>${feature.subscribers}</strong> subscribers`"
              multiple-lines
              allow-html
              line-height="25px"
              align="right"
            />
          </div>
        </div>
      </div>

      <div class="feature-selection-container u-margin-top-4" v-if="featureSelection && featureSelection.length > 0">
        <div
          class="cancellable-feature"
          v-for="(feature, idx) in featureSelection"
          :key="idx">
          <div class="name-and-checkbox">
            <div class="feature-checkbox">
              <ar-checkbox
                type="tick"
                v-model="feature.checkValue"
                color="red"
              />
            </div>
            <ar-avatar
              v-if="feature.image"
              :image-path="feature.image"
              :name="feature.name"
              size="30px"
            />
            <div class="feature-name">
              <ar-text
                class="feature-name"
                size="xs"
                :text="feature.name"
                multiple-lines
                line-height="25px"
              />
            </div>
          </div>
          <div class="feature-expiry">
            <ar-text
              v-if="feature.expiry"
              class="expiry-date"
              size="xs"
              :text="`Ends <strong>${feature.expiry}</strong>`"
              multiple-lines
              allow-html
              line-height="25px"
              align="right"
              :style="{
                color: feature.checkValue ? $arStyle.color.red500 : null,
              }"
            />
            <ar-text
              v-else-if="feature.subscribers"
              class="expiry-date"
              size="xs"
              :text="`<strong>${feature.subscribers}</strong> subscribers`"
              multiple-lines
              allow-html
              line-height="25px"
              align="right"
              :style="{
                color: feature.checkValue ? $arStyle.color.red500 : null,
              }"
            />
          </div>
        </div>
      </div>

      <ar-text
        v-if="messageHtmlAfter"
        class="cancellation-modal-message u-margin-y-4"
        size="xs"
        :text="messageHtmlAfter"
        allow-html
        multiple-lines
        line-height="25px"
        align="center"
      />

      <ar-textarea
        v-if="showCancellationReasonTextarea"
        class="cancellation-reason-textarea u-margin-top-6"
        v-model="cancellationReason"
        v-bind="cancellationTextareaPropsComputed"
      />

      <div
        :class="['cancellation-modal-button-wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
        :style="{
          flexDirection: cancelType === 'link' ? 'column' : 'row',
          alignItems: cancelType === 'link' ? 'center' : null,
        }"
      >
        <ar-simple-button
          :loading="loading"
          @click="handleConfirmClick"
          v-bind="confirmButtonPropsComputed"
        />
        <ar-simple-button
          v-if="cancelType === 'button'"
          :loading="loading"
          :class="$arMediaQuery.pageContent.minWidth('sm') ? 'u-margin-left-2' : 'u-margin-top-3'"
          @click="handleCloseClick"
          v-bind="cancelButtonPropsComputed"
        />
        <ar-link-button
          v-else-if="cancelType === 'link'"
          :disabled="loading"
          class="u-margin-top-5"
          @click="handleCloseClick"
          v-bind="cancelButtonPropsComputed"
        />
      </div>
    </div>
  </ar-modal>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CancellationModal',

    data() {
      return {
        loading: false,
        showModal: false,
        messageHtmlBefore: null,
        messageHtmlAfter: null,
        title: null,
        featureList: [], // Non-interactive list of features which will be cancelled.
        featureSelection: [], // Selectable list of features to cancel.
        cancellationReason: null, // Model for storing cancellation reason, if textarea is enabled
        showCancellationReasonTextarea: false,
        cancelType: null,
        cancelButtonProps: {},
        confirmButtonProps: {},
        cancellationTextareaProps: {},
        onConfirm: () => {},
        onClose: () => {}
      }
    },

    computed: {
      cancellationTextareaPropsComputed() {
        return {
          rows: 4,
          debounce: true,
          placeholder: this.cancellationReasonPlaceholder,
          ...this.cancellationTextareaProps,
        }
      },
      confirmButtonPropsComputed() {
        const selectedFeaturesCount = this.featureSelection ? this.featureSelection.reduce((sum, item) => {
          return sum + (item.checkValue === true ? 1 : 0);
        }, 0) : 0;
        let cancellationText = "Proceed with cancellation";
        if (this.confirmButtonFormatter && selectedFeaturesCount > 0) {
          cancellationText = this.confirmButtonFormatter(selectedFeaturesCount);
        }

        return {
          type: "red",
          text: cancellationText,
          style: {
            width: '250px',
            borderRadius: '5px',
          },
          ...this.confirmButtonProps,
        }
      },
      cancelButtonPropsComputed() {
        let cancellationText = "Don't cancel";

        return {
          type: "grey",
          text: cancellationText,
          style: {
            width: '250px',
            borderRadius: '7px',
          },
          ...this.cancelButtonProps,
        }
      },
    },

    created() {
      window.addEventListener('arModalOpen', this.handleModalOpen);
    },

    beforeDestroy() {
      window.removeEventListener('arModalOpen', this.handleModalOpen);
    },

    methods: {
      handleModalOpen({ detail: { action, payload } }) {
        if (action !== 'OPEN_CANCELLATION_MODAL') return;

        this.startModalAction(payload);
      },
      respondModalAction(payload) {
        window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'OPEN_CANCELLATION_MODAL', payload } }));
      },
      startModalAction({
        messageHtmlBefore, // Message to appear before featureList/featureSelection
        messageHtmlAfter, // Message to appear after featureList/featureSelection
        confirmButtonProps,
        confirmButtonFormatter,
        title,
        featureList,
        featureSelection,
        cancelType,
        cancelButtonProps,
        showCancellationReasonTextarea,
        cancellationTextareaProps,
        cancellationReasonPlaceholder,
        cancelText,
        // If you need some action to be completed before we close the modal
        asyncCompleteFunction,
      }) {
        this.messageHtmlBefore = messageHtmlBefore;
        this.messageHtmlAfter = messageHtmlAfter;
        this.title = title;
        this.featureList = featureList;
        this.featureSelection = featureSelection ? featureSelection.map(item => {
          return {
            checkValue: false,
            ...item
          }
        }) : null;
        this.showCancellationReasonTextarea = showCancellationReasonTextarea;
        if (cancellationReasonPlaceholder) this.cancellationReasonPlaceholder = cancellationReasonPlaceholder;
        if (cancelText) this.cancelText= cancelText;
        this.confirmButtonProps = confirmButtonProps || {};
        this.confirmButtonFormatter = confirmButtonFormatter || null;
        this.cancellationTextareaProps = cancellationTextareaProps || {};

        this.cancelType = cancelType || null;
        this.cancelButtonProps = cancelButtonProps || {};

        this.showModal = true;

        this.onConfirm = async () => {
          const cancellationReason = this.showCancellationReasonTextarea ? this.cancellationReason : null;

          let itemsToCancel = null;
          if (this.featureSelection && this.featureSelection.length > 0) {
            itemsToCancel = this.featureSelection.filter( item => item.checkValue === true);
          }

          if (asyncCompleteFunction) {
            try {
              this.loading = true;
              await asyncCompleteFunction({ reason: cancellationReason, items: itemsToCancel });
            } finally {
              this.loading = false;
            }
          }

          this.respondModalAction({
            reason: cancellationReason,
            items: itemsToCancel,
          });

          this.cancellationReason = null;
          this.showModal = false;
        };
        this.onClose = () => {
          this.respondModalAction(false);
          this.cancellationReason = null;
          this.showModal = false;
        }
      },
      handleConfirmClick() {
        this.onConfirm();
      },
      handleCloseClick() {
        this.onClose();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cancellation-modal {
    .cancellation-modal-body {
      padding: 0 48px 24px;
    }
    .exclamation-mark {
      display: flex;
      justify-content: center;
      margin: 46px 0 36px;
    }


    .feature-list-container {
      border: 1px solid $skyBlueGrey500;
      border-radius: 5px;
      overflow: hidden;
      .feature-to-be-cancelled {
        padding: 12px 18px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid $skyBlueGrey500;
        }

        .feature-name {
          display:inline-flex;
        }
        .expiry-date {
          display:inline-flex;
        }
      }
    }

    .feature-selection-container {
      border: 1px solid $skyBlueGrey500;
      .cancellable-feature {
        padding: 12px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid $skyBlueGrey500;
        }

        .name-and-checkbox {
          display:inline-flex;
          .feature-checkbox {
            display:inline-flex;
            margin-right:12px;
          }
          .feature-name {
            display:inline-flex;
            margin-left:8px;
            align-items: center;
          }
        }

        .feature-expiry {
          display:inline-flex;
          align-items: center;
        }
      }
    }

    .cancellation-modal-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 36px 0 16px;

      &.xs-max {
        margin: 16px 0;
        flex-direction: column;
      }
    }
  }

</style>
