var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',{attrs:{"totalStep":null,"currentStep":1}},[_c('form',{staticClass:"authenticate-signup",on:{"submit":function($event){$event.preventDefault();return _vm.handleCreateAccountSubmit.apply(null, arguments)}}},[_c('ar-text',{style:({
        marginBottom: '14px',
      }),attrs:{"text":"Create your free account","size":"lg","multiple-lines":""}}),_vm._v(" "),_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        marginBottom: '28px',
      })},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
          marginTop: '1px',
        }),attrs:{"size":"xxxs","text":"Already have an account?"}}),_vm._v(" "),_c('ar-link-button',{style:({
          marginLeft: '4px',
        }),attrs:{"text":"Login here","text-props":{
          size: 'xxxs',
        }},on:{"click":_vm.handleLoginLinkClick}})],1),_vm._v(" "),_c('div',{style:({
        marginBottom: '20px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"autocomplete":"email","data-vv-name":"emailAddress","data-vv-as":"email address","placeholder":"Email address","has-error":_vm.veeErrors.has('emailAddress')},model:{value:(_vm.registerData.emailAddress),callback:function ($$v) {_vm.$set(_vm.registerData, "emailAddress", $$v)},expression:"registerData.emailAddress"}}),_vm._v(" "),(_vm.veeErrors.has('emailAddress'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('emailAddress'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
        marginBottom: '20px',
      })},[_c('div',{style:({
          display: 'flex',
          justifyContent: 'space-between',
        })},[_c('div',{style:({
            width: 'calc(50% - 7px)'
          })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"firstName","autocomplete":"given-name","data-vv-as":"first name","placeholder":"First name","has-error":_vm.veeErrors.has('firstName')},model:{value:(_vm.registerData.firstName),callback:function ($$v) {_vm.$set(_vm.registerData, "firstName", $$v)},expression:"registerData.firstName"}}),_vm._v(" "),(_vm.veeErrors.has('firstName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('firstName'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
            width: 'calc(50% - 7px)'
          })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"lastName","autocomplete":"family-name","data-vv-as":"last name","placeholder":"Last name","has-error":_vm.veeErrors.has('lastName')},model:{value:(_vm.registerData.lastName),callback:function ($$v) {_vm.$set(_vm.registerData, "lastName", $$v)},expression:"registerData.lastName"}}),_vm._v(" "),(_vm.veeErrors.has('lastName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('lastName'),"type":"error"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{style:({
        marginBottom: '20px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"company","data-vv-as":"company","autocomplete":"organization","has-error":_vm.veeErrors.has('company'),"placeholder":"Company"},model:{value:(_vm.registerData.company),callback:function ($$v) {_vm.$set(_vm.registerData, "company", $$v)},expression:"registerData.company"}}),_vm._v(" "),(_vm.veeErrors.has('company'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('company'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
      marginBottom: '20px'
    })},[_c('ar-simple-select',{directives:[{name:"validate",rawName:"v-validate:hasCompanyTypeSelected",value:('required:true'),expression:"'required:true'",arg:"hasCompanyTypeSelected"}],attrs:{"data-vv-name":"companyCategory","data-vv-as":"company type","autocomplete":"companyCategory","has-error":_vm.veeErrors.has('companyCategory'),"placeholder":"Company type","items":_vm.companyCategoryDropdownItems,"default-select-index":_vm.companyCategorySelectedIndex,"data-test-id":"company-category-selector"},on:{"select":_vm.handleCompanyTypeDropdownSelect}}),_vm._v(" "),(_vm.veeErrors.has('companyCategory'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('companyCategory'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
        marginBottom: '20px',
      })},[_c('ar-mobile-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|mobileNumber'),expression:"'required|mobileNumber'"}],style:({
          width: '100%',
        }),attrs:{"data-vv-name":"mobileNumber","data-vv-as":"mobile number","autocomplete":"tel-national","has-error":_vm.veeErrors.has('mobileNumber')},on:{"select":_vm.handleMobileNumberInputCountrySelect},model:{value:(_vm.registerData.mobileNumber),callback:function ($$v) {_vm.$set(_vm.registerData, "mobileNumber", $$v)},expression:"registerData.mobileNumber"}}),_vm._v(" "),(_vm.veeErrors.has('mobileNumber'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('mobileNumber'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
        marginBottom: '40px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|arPasswordLength|max:256'),expression:"'required|arPasswordLength|max:256'"}],attrs:{"data-vv-name":"password","data-vv-as":"password","placeholder":"Create a password","autocomplete":"new-password","has-error":_vm.veeErrors.has('password'),"text-secure":"","type":"password"},model:{value:(_vm.registerData.password),callback:function ($$v) {_vm.$set(_vm.registerData, "password", $$v)},expression:"registerData.password"}}),_vm._v(" "),(_vm.veeErrors.has('password'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('password'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('ar-simple-button',{style:({
        width: '100%',
        marginBottom: '20px',
      }),attrs:{"type":"gradient-purple","shape":"pill","text":"Create account","loading":_vm.isDoingSignup}}),_vm._v(" "),_c('ar-text',{staticClass:"comment",attrs:{"size":"xxxs","align":"center","text":_vm.commentCopy,"allow-html":"","multiple-lines":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }