<template>
  <div :class="['split-dropdown']" >
    <ar-simple-button
      class="left-button"
      :type="type"
      :text="text"
      :disabled="disabled"
      :outlined="outlined"
      :style="{
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      }"
      :icon-name="iconName"
      :icon-side="iconName ? 'left' : null"
      :icon-props="iconName ? iconProps : null"
      :icon-distance="iconDistance"
      @click="handleSimpleButtonClick"
    />
    <am2-dropdown-binder
      ref="dropdown"
      button-name="ar-simple-button"
      :classNames="classNames"
      :button-props="{
        type,
        iconName: 'chevron',
        iconProps: {
          height: '6px',
          rotate: dialogOpen ? 180 : 0,
          color: outlined ? $arStyle.color.purple600 : null,
        },
        style: {
          width: '35px',
          border: outlined ? `1px solid ${$arStyle.color.purple600}`: 'none',
          borderLeft: buttonBorderLeft,
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          backgroundColor: outlined ? 'transparent' : $arStyle.color.purple500,
        },
      }"
      :dropdown-props="{
        arrowDistance: '20px',
        items: items,
        style: {
          boxShadow: '1px 2px 8px 1px rgba(0,0,0,.07)',
        },
        itemStyle: {
          height: '43px',
        },
      }"
      :disabled="disabled"
      align="left"
      @toggle="(val) => dialogOpen = val"
      @select="(item) => $emit('select', item)"
    />
  </div>
</template>

<script>
  export default {
    name: "SplitDropdown",
    props: {
      classNames: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: 'purple',
      },
      text: {
        type: String,
        default: null,
      },
      items: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      toggleDropdownOnButtonClick: {
        type: Boolean,
        default: false,
      },
      iconProps: {
        type: Object,
        default: () => {},
      },
      iconName: {
        type: String,
        default: null,
      },
      iconDistance: {
        type: String,
        default: '5px',
      },
    },
    data() {
      return { dialogOpen: false };
    },
    computed: {
      buttonBorderLeft() {
        return (this.disabled || this.outlined) ? null : 
          `1px solid ${this.$arStyle.color.purple600}`;
      },
    },

    methods: {
      handleSimpleButtonClick() {
        if (this.toggleDropdownOnButtonClick) {
          this.$refs.dropdown.toggleDropdown(true);
        } else {
          this.$emit('buttonClick');
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
// the dropdown btn width should be
// explicitly stated to ensure
// the 'main' btn fills the
// expected area.
$dropdown-btn-width: 35px;

.split-dropdown {
  padding: 0px !important;
  display: flex;

  > .left-button {
    width: calc(100% - #{$dropdown-btn-width});
    padding: 0 7px;
  }
}
</style>
