<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Admin Dashboard"
      class="u-margin-top-6 u-margin-bottom-8"
    />

    <div>
      <am2-card-container layout="soft" class="cell">
        <am2-overview-bar
          :values="overviewValues"
          title="Messages Overview"
          layout="box-layout"
        >
        </am2-overview-bar>
      </am2-card-container>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'AdminDashboard',
    layout: 'default',

    computed: {
      ...mapState({
        adminStats: state => state.admin.adminStats,
        isFetchingAdminStats: state => state.admin.isFetchingAdminStats,
      }),

      overviewValues() {
        return [
          {
            label: 'Sending',
            type: 'NUMBER',
            showDecimal: false,
            amount: this.adminStats && this.adminStats.sendingMessageCount,
            tooltip: `The number of message tasks across all promoters which are currently 'in-progress' and sending to fans.`
          },
          {
            label: 'Scheduled',
            type: 'NUMBER',
            amount: this.adminStats && this.adminStats.scheduledMessageCount,
            tooltip: `The number of message tasks across all promoters which are scheduled to send some time in the future.`
          },
          {
            label: 'Failed',
            type: 'NUMBER',
            amount: this.adminStats && this.adminStats.failedMessageCount,
            tooltip: `The number of message tasks across all promoters which have a status of failed.`
          },
        ]
      }
    },

    mounted() {
      this['admin/FETCH_ADMIN_STATS']();
    },

    methods: {
      ...mapActions(['admin/FETCH_ADMIN_STATS']),
      
    }
  };
</script>

<style lang="scss" scoped>

</style>
