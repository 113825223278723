<template>
  <div
    ref="html-preview"
    class="am2-html-preview"
  >
    <div
      class="email-iframe-wrapper"
      :style="scaleStyle"
    >
      <iframe
        :src="localhtmlUrl"
        loading="lazy"
        class="email-iframe"
        width="100%"
        height="100%"
        frameborder="0"
        :scrolling="enableScroll ? 'yes' : 'no'"
        :style="{
          opacity: hasLoadedHtml ? '1' : '0',
        }"
        @load="handleIframeLoad"
      />
    </div>
  </div>
</template>

<script>
import { createBlob } from '@/utils/html-element'

export default {
  name: 'HtmlPreview',
  props: {
    html: {
      type: String,
      default: '',
    },
    enableScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasLoadedHtml: false,
      scale: 0.3,
    };
  },
  computed: {
    scaleStyle() {
      return {
        width: `${100 / this.scale}%`,
        height: `${100 / this.scale}%`,
        transform: `scale(${this.scale})`,
        transformOrigin: 'top left',
      };
    },
    displayedActionItems() {
      return this.actionItems.filter(item => this.allowedActions.indexOf(item.value) > -1);
    },
    localhtmlUrl() {
      if (!this.html) {
        return null;
      }
      return URL.createObjectURL(createBlob({ text: this.html, type: 'text/html' }));
    },
  },
  methods: {
    updateScale() {
      if (!this.$refs['html-preview']) {
        return;
      }
      const { width } = this.$refs['html-preview'].getBoundingClientRect();
      this.scale = width / 395;
    },
    handleIframeLoad() {
      this.hasLoadedHtml = true
    },
  },
  mounted() {
    this.templateResizeObserver = new ResizeObserver(() => {
      this.updateScale();
    });
    this.templateResizeObserver.observe(this.$refs['html-preview']);
  },
  beforeDestroy() {
    this.templateResizeObserver.unobserve(this.$refs['html-preview']);
  },
};
</script>

<style lang="scss" scoped>
.am2-html-preview {
  position: relative;
  border-radius: 3px;
  border: 1px solid $blueGrey500;

  .email-iframe-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .email-iframe {
      pointer-events: none;
    }
  }
}
</style>
