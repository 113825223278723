<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <am2-ask-shopify-access-token-modal
      :is-show="showAskShopifyAccessTokenModal"
      :loading="isCreatingShopifyIntegration"
      @confirm="handleShopifyAccessTokenConfirm"
      @cancel="showAskShopifyAccessTokenModal = false"
    />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Shopify"
          description="Automatically sync your sales & customers from Shopify"
          :icon-props="{
            name: 'shopify',
            color: null,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingShopifyIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingShopifyIntegration && shopifyIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Shopify',
              value: 'goToShopifySite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>

    <section>
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your Shopify connection is no longer valid. This means we can't sync your orders or customer data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>

      <section v-else>
        <am2-card-container
          class="sync-block-container"
        >
          <div v-if="latestTaskStatus === 'in-progress'" class="sync-block">
            <am2-loading-bubble
              class="sync-animation"
            />
            <div class="text-block">
              <am2-heading
                class="header"
                size="md"
                type="h2"
                :title="latestTaskStatus === 'in-progress' ? taskInProgressCopy : taskNotInProgressCopy" />
              <div class="button-block">
                <ar-simple-button
                  text="Stop sync"
                  outlined
                  class="button sync-btn"
                  @click="handleStopSync" />
              </div>
            </div>
          </div>
          <div v-else class="sync-block">
            <div class="tick-container">
              <ar-icon
                name="check"
                class="tick-icon"
                width="18"
                height="17"
              />
            </div>
            <div class="text-block u-margin-top-8">
              <am2-heading
                class="header"
                size="md"
                type="h2"
                :title="latestTaskStatus === 'in-progress' ? taskInProgressCopy : taskNotInProgressCopy" />
              <div class="button-block u-margin-top-9">
                <ar-simple-button
                  text="Sync now"
                  :loading="latestTaskStatus === 'in-progress'"
                  class="button sync-btn"
                  @click="handleStartSync" />
              </div>
              <div v-if="hasSyncedBefore" class="last-sync-time">
                <ar-text
                  :text="`Last synced ${timeSinceLastSync}`"
                  size="xxs"
                  :style="{
                    color: '#8E97A7',
                  }"
                />
              </div>
            </div>
          </div>
        </am2-card-container>
      </section>
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'ShopifyIntegration',
  layout: 'default',

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showAskShopifyAccessTokenModal: false,
  }),

  computed: {
    ...mapState({
      shopifyIntegration: state => state.shopifyIntegration.integration,
      isFetchingShopifyIntegration: state => state.shopifyIntegration.isFetchingIntegration,
      hasFetchedShopifyIntegration: state => state.shopifyIntegration.hasFetchedIntegration,
      isAddingShopifyIntegration: state => state.shopifyIntegration.isAddingIntegration,
      isDeletingShopifyIntegration: state => state.shopifyIntegration.isDeletingIntegration,
      isReconnectingShopifyIntegration: state => state.shopifyIntegration.isReconnectingIntegration,
      isFetchingTasks: state => state.shopifyIntegration.isFetchingTasks,
      tasks: state => state.shopifyIntegration.tasks,
      latestTaskStatus: state => state.shopifyIntegration.latestTaskStatus,
    }),

    shopifyIntegrationEnabled() {
      return process.env.arEnableShopifyIntegration;
    },

    latestTask() {
      if (!this.tasks || this.tasks.length < 1) return null;
      return this.tasks[0];
    },

    integrationsSummary() {
      if (!this.shopifyIntegration) return null;
      return this.shopifyIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.shopName,
          email: i.integration?.shopName,
          imageSrc: null,
        }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.shopifyIntegration || this.shopifyIntegration.length < 1 || !this.shopifyIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.shopifyIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },

    isSyncing() {
      if (!this.currentIntegration || !this.hasExistingTask) {
        return false;
      }

      return this.latestTaskStatus === 'in-progress';
    },

    hasExistingTask() {
      return this.latestTask !== null;
    },

    hasSyncedBefore() {
      return (
        this.latestTask && this.latestTask.status === 'completed'
      );
    },

    timeSinceLastSync() {
      let sinceNow = "";

      if (this.hasSyncedBefore) {
        sinceNow = moment.utc(
          this.latestTask.sysMtime
        ).fromNow();
      }

      return sinceNow;
    },

    hasAnyOrders() {
      return this.latestTask.statusDetails.totalOrders && this.latestTask.statusDetails.totalOrders > 0;
    },
    taskInProgressCopy() {
      return this.hasAnyOrders ? `Syncing ${this.latestTask.statusDetails.completedOrdersCount} of ${this.latestTask.statusDetails.totalOrders} orders from Shopify` : `Syncing orders from Shopify`;
    },
    taskNotInProgressCopy() {
      return 'Your Shopify store is currently connected';
    },
  },

  async mounted() {
    if (!this.shopifyIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchInitialData();
  },

  beforeDestroy() {
    this['shopifyIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'shopifyIntegration/FETCH_INTEGRATION',
      'shopifyIntegration/ADD_INTEGRATION',
      'shopifyIntegration/DELETE_INTEGRATION',
      'shopifyIntegration/RECONNECT_INTEGRATION',
      'shopifyIntegration/SYNC_START',
      'shopifyIntegration/SYNC_STOP',
      'shopifyIntegration/FETCH_TASKS',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'shopifyIntegration/RESET_INTEGRATION',
    ]),

    async fetchInitialData() {
      if (this.isFetchingShopifyIntegration || this.hasFetchedIntegration) return;
      await this['shopifyIntegration/FETCH_INTEGRATION']();

      if (this.currentIntegration?.oid && !this.isFetchingTasks) {
        this['shopifyIntegration/FETCH_TASKS']({
          oid: this.currentIntegration.oid,
          orderby: "sysMtime desc",
          filter: {
            name: "event-data-synch",
            promoterIntegrationOid: this.currentIntegration.oid,
          }
        });
      }
    },

    async updateData() {
      await this['shopifyIntegration/FETCH_TASKS']({
        orderby: "sysMtime desc",
        top: 1,
        oid: this.currentIntegration.oid,
      });

      if (this.currentIntegration && this.hasExistingTask && this.latestTaskStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    async handleShopifyAccessTokenConfirm(shopPrefix, accessToken) {
      const succeed = await this['shopifyIntegration/ADD_INTEGRATION']({
        shopPrefix,
        accessToken
      });
      if (succeed) {
        await this.fetchInitialData();
        this.showAskShopifyAccessTokenModal = false;

        if (this.shopifyIntegration.length > 1) {
          this.currentlySelectedIntegrationIndex = this.shopifyIntegration.length - 1;
        }
      }
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
    },

    handleAddNewIntegration() {
      this.showAskShopifyAccessTokenModal = true;

      // BORG-341 (01-2023): Replacing OAUth2 auth flow with simple access token. Leaving this here in case we need to revert to OAUth2.
      // /**
      //  * Redirect user to Shopify App Store to initiate integration process.
      //  *
      //  * Once Shopify approved app for listing, the redirect link can be change
      //  * to the app specific link.
      //  */
      // const shopifyAppStoreBaseUrl = 'https://apps.shopify.com/search';
      // const searchParams = new URLSearchParams({ q: 'audience republic' });
      //
      // window.location.href = `${shopifyAppStoreBaseUrl}?${searchParams.toString()}`;
    },

    async handleReconnectIntegration() {
      // BORG-341 (01-2023): Replacing OAUth2 auth flow with simple access token. Leaving this here in case we need to revert to OAUth2.
      // this['shopifyIntegration/RECONNECT_INTEGRATION']({oid: this.currentIntegration.oid});
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the Shopify account <strong>${this.currentIntegration.integration?.user?.shop?.name}</strong> before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['shopifyIntegration/DELETE_INTEGRATION']({oid: this.currentIntegration.oid});
      if (!succeed) {
        return;
      }

      this.handleAddNewIntegration();
    },


    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['shopifyIntegration/SYNC_START']({oid: this.currentIntegration.oid});
      this.syncStatusPoller = setInterval(this.updateData, 3000);
    },

    handleStopSync() {
      this['shopifyIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      window.clearInterval(this.syncStatusPoller);
      this.syncStatusPoller = null;
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Shopify integration will stop syncing your orders and customer data from Shopify.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['shopifyIntegration/DELETE_INTEGRATION']({oid: this.currentIntegration.oid});
            await this['shopifyIntegration/FETCH_INTEGRATION']();
            if (this.shopifyIntegration.length > 1) {
              this.currentlySelectedIntegrationIndex = 0;
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        await this.handleReconnectIntegration();
      } else if (item.value === 'goToShopifySite') {
        if (!this.currentIntegration) return;
        const shopifyUrl = this.currentIntegration.integration?.user?.shop?.domain;
        if (!shopifyUrl) return;
        const win = window.open(`https://${shopifyUrl}`, '_blank');
        win.focus();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }


  .sync-block-container {
    background-color: #FFF;
    margin-top:64px;

    .sync-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 85px;
      padding-bottom: 70px;
      flex-direction: column;

      .tick-container {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        background-color: $green500;
        display: flex;
        justify-content: center;
        align-items: center;
        .tick-icon {
          color: #FFF;
        }
      }

      .sync-animation {
        color: $purple500;
        margin-bottom: 46px;
        margin-top: 20px;
      }

      .button-block {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 0 12px;
        margin-top: 58px;

        .button {
          margin-top: 10px;
          width: 300px;
          max-width:100%;
        }
      }

      .last-sync-time {
        margin-top: 12px;
        font-size: 14px;
        color: #8E97A7;
        line-height: 25px;
      }

      .text-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;

        .header {
          color: $blueGrey800;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
