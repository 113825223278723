var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"insights-page-wrapper"},[(!_vm.isInsightsEnabled)?_c('am2-unlock-prompt',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],attrs:{"title":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Unlock Insights" : "Insights","subtitle":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Powerful insights about your audience, to help you increase ticket sales. <a href='https://audiencerepublic.com/insights' target='_blank' style='color: #FFF;'>Learn more</a>" : "Powerful insights about your audience. <a href='https://audiencerepublic.com/insights' target='_blank' style='color: #FFF;'>Learn more</a>","button-text":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Upgrade Plan" : "Upgrade","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'},on:{"click":_vm.goToPlans}}):_vm._e(),_vm._v(" "),_c('div',{class:( _obj = {
      content: true
    }, _obj['insights-page-content'] = true, _obj )},[_c('div',{class:[
      'insights-page-top-wrapper' ]},[_c('div',{staticClass:"insights-page-top-left-section"},[_c('ar-text',{attrs:{"size":"lg","text":"Insights"}}),_vm._v(" "),_c('am2-segment-dropdown',{style:({
            marginTop: '8px',
          }),attrs:{"size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'large'}})],1),_vm._v(" "),_c('ar-divider',{style:({
          marginTop: '29px',
          marginBottom: '20px',
        })}),_vm._v(" "),(_vm.showFilterButton)?_c('div',{staticClass:"insights-page-filter-button-section"},[_c('div',{staticClass:"insights-page-circle-hooker"},[(_vm.numberOfFilterConditions)?_c('div',{staticClass:"insights-page-circle"},[_c('span',[_vm._v(_vm._s(_vm.prunedScratchSegment.filter.conditions.length))])]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"filter-btn",attrs:{"icon-props":{
              height: '16px',
            },"text":"Filter","icon-name":"filter-alt","type":(_vm.displaySegmentDrawer || _vm.numberOfFilterConditions) ? 'purple' : 'grey',"outlined":""},on:{"click":_vm.switchFilterSidebar}})],1)]):_vm._e()],1),_vm._v(" "),_c('am2-insights-section',{staticClass:"u-margin-top-5 u-margin-bottom-7",attrs:{"feature-type":"audience-insights","insight-type":"filter","enable-see-all-table-route-change":""},on:{"seeAllToggle":_vm.handleSeeAllToggle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }