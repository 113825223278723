<template>
  <section class="campaign-edit-details">
    <am2-import-dynamic-tags-modal
      :is-show="showImportDynamicTagsModal"
      :message-list-oid="scratchSimpleMessage.meta.messageListOid"
      channel="sms"
      @import="handleDynamicTagsImport"
      @cancel="showImportDynamicTagsModal = false"
    />

    <ar-field label="Message name">
      <ar-input
        :value="scratchSimpleMessage.customerName"
        autocomplete="message-name"
        @input="handleCustomerNameInput"
        data-test-id="message-name-input"
      />
    </ar-field>

    <!-- Message To -->
    <ar-field
      :style="{ marginTop: '40px' }"
    >
      <div
        class="u-display-flex u-justify-content-space-between u-align-items-center"
        :style="{
          marginBottom: '8px',
        }"
      >
        <ar-text
          class="label"
          size="14px"
          text="To"
          weight="bold"
          multiple-lines
          :max-lines="1"
        />
      </div>
      <div>
        <div class="u-display-flex u-align-items-center u-justify-content-space-between">
          <am2-message-list-select
            ref="message-list-select"
            :class="[
              'message-list-recipients-dropdown',
              'u-flex-grow-1',
              showFilterButton && 'filter-enabled'
            ]"
            :value="scratchSimpleMessage.meta.messageListOid"
            :campaign-oid="scratchSimpleMessageCampaignOid"
            :event-oid="scratchSimpleMessageEventOid"
            @select="handleMessageListSelect"
            :channels="['sms']"
            v-tooltip.top="{
              content: dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null
            }"
            data-test-id="message-list-select"
          />

          <div
            class="circle-hooker item"
            v-if="showFilterButton"
          >
            <div v-if="filterIsNotEmpty && !!filterCount" class="circle">
              <span>{{ filterCount }}</span>
            </div>
            <ar-simple-button
              icon-name="filter-alt"
              side-length="40px"
              :icon-props="{
                height: '16px',
              }"
              text="Filter"
              :type="filterIsNotEmpty ? 'purple' : 'grey'"
              outlined
              class="filter-btn u-margin-left-3"
              @click="switchFilterSidebar"
              data-test-id="audience-table-filter-button"
              :disabled="!this.scratchSimpleMessage.meta.messageListOid"
            />
          </div>
        </div>
        <ar-snackbar
          v-if="noContacts"
          type="error"
          message="There are no valid mobile phone numbers in this list"
          :style="{ marginTop: '10px' }"
        />

        <ar-snackbar
          v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
          class="u-margin-top-5"
          type="warning"
          message="Changing from All Recipients to a Tier will disable scheduling."
        />
        <ar-state-message
          v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
          class="u-margin-top-5"
          :text-props="{
            weight: 'bold',
          }"
          has-icon
          disable-text-color
          type="information"
          text="Note: It's recommended to only message individual tiers once the campaign is ended."
        />
      </div>
    </ar-field>

    <am2-message-list-advanced-targeting-section
      v-if="showAdvancedTargetingModule"
      class="u-margin-padding-top-1"
      channel="sms"
      show-toggle
      @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
    />

    <div class="recipients-count u-margin-top-4 u-padding-left-4"
      v-if="areFiltersNotEmpty"
    >
      <am2-loading-spinner
        v-if="isFetchingFilteredRecipientListCount && currentAdvancedTargetingIsValid"
        :style="{
          width: '1.5em',
          height: '1.5em'
        }"
      />
      <div
        class="recipients-count"
        v-else>
        <ar-icon
          class="sales-chart-settings-icon settings-button u-margin-right-3"
          name="team"
          width="16px"
          :color="$arStyle.color.blueGrey700"
        />
        <ar-text
          class="recipients-text"
          size="xs"
          data-test-id="message-targeting-recipient-count"
          :text="recipientCountText"
        />
        <div class="u-margin-left-2">·</div>
        <ar-link-button
          class="u-margin-left-2"
          text="Clear all"
          has-underline
          @click="handleClearAllFilters"
          data-test-id="message-list-clear-all-filters"
        />
      </div>
    </div>


    <!-- Message Body Field Wrapper -->
    <div
      class="u-position-relative"
      :style="{
        marginTop: '40px',
      }"
    >
      <div
        class="u-position-absolute u-display-flex u-justify-content-flex-end"
        :style="{ top: 0, right: 0 }"
      >
        <am2-link-button-dropdown
          :items="dynamicTagSelectItems"
          :button-props="{
            hasArrow: false,
            hasUnderline: true,
          }"
          :item-style="{
            height: '44px',
            padding: '0 20px',
          }"
          :divider-style="{
            margin: '0',
          }"
          :disabled="availableDynamicTagsInSimpleMessage.length === 0"
          :align="$arMediaQuery.window.maxWidth('xs') ? 'left' : 'right'"
          placeholder="Insert dynamic tag"
          :dropdown-style="{
            padding: '0',
          }"
          @select="handleInsertDynamicTagsSelect"
          data-test-id="insert-dynamic-tag-link"
        />
      </div>
      <ar-field
        name="message-body"
        label="Message"
        :style="{
          marginTop: '40px',
        }"
        :error-messages="[veeErrors.first('messageBody')]"
      >
        <SimpleMessageEditor
          ref="simple-message-editor"
          :dynamic-tags="availableDynamicTagsInSimpleMessage"
          :value="scratchSimpleMessage.meta.messageBody"
          @input="handleMessageBodyInput"
          v-validate="{
            required: true,
            max: scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft,
          }"
          data-vv-name="messageBody"
        />
        <ar-snackbar
          v-if="nonExistingTagsInSimpleMessageErrorCopy"
          type="error"
          :message="nonExistingTagsInSimpleMessageErrorCopy"
          :style="{
            marginTop: '10px',
          }"
        />
        <am2-characters-left-copy-section
          :current-length="scratchSimpleMessage.meta.messageBody.length"
          tooltip="The character count below excludes the opt out message, which gets automatically appended to your SMS."
          :max-length="scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft"
          :template="`${smsMessagePreviewLength} characters / ${smsMessagePreviewSegmentsCount} SMS`"
          :style="{
            marginTop: '10px',
          }"
        />
      </ar-field>
    </div>

    <!-- Fallback Dynamic Tags -->
    <ar-field
      v-if="fallbackDynamicTagsForScratchSimpleMessage.length !== 0"
      label="Fallback dynamic tags"
      class="u-margin-top-5"
    >
      <div v-if="displayFallbackDynamicTagsField">
        <ar-field
          v-for="tag of fallbackDynamicTagsForScratchSimpleMessage"
          :key="tag"
          :label="tag"
          class="u-margin-bottom-3"
          :error-messages="[veeErrors.first(tag)]"
        >
          <ar-input
            :placeholder="`Enter fallback value of ${tag}`"
            :value="scratchSimpleMessage.meta.fallbackDynamicTags[convertToCamelCase(tag)]"
            @input="handleFallbackDynamicTagsInput(convertToCamelCase(tag), ...arguments)"
            v-validate="{
              required: true,
            }"
            :data-vv-name="tag"
            :data-vv-as="`${tag} fallback tag`"
            :data-test-id="`fallback-dynamic-tag-${tag}`"
          />
        </ar-field>
      </div>
      <ar-link-button
        :text="displayFallbackDynamicTagsField ? 'Hide all fields' : 'Show all fields'"
        :disabled="!isFallbackDynamicTagsCompleteInSimpleMessage"
        @click="displayFallbackDynamicTagsField = !displayFallbackDynamicTagsField"
        :data-test-id="displayFallbackDynamicTagsField ? 'hide-dynamic-fields-button' : 'display-dynamic-fields-button'"
      />
    </ar-field>

    <!-- Schefuling -->
    <ar-field
      label="Schedule?"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-checkbox
        :value="enableSchedule"
        @input="handleEnableScheduleToggle"
        label="Schedule a time to send this message"
        :disabled="currentSelectedMessageUiStatus === 'scheduled' || !currentMessageListCanBeSchedued"
        v-tooltip.top="{
          content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,
        }"
        data-test-id="toggle-enable-schedule-checkbox"
      />
      <div
        v-if="enableSchedule"
        :class="[
          'schedule-input-group',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
        :style="{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: '20px',
        }"
      >
        <ar-field
          label="Start time"
          :class="[
            'u-color-blueGrey700',
            'u-flex-shrink-0',
          ]"
          :style="{
            margin: '0',
          }"
          :error-messages="[veeErrors.first('scheduledAt')]"
        >
          <am2-date-time-input
            :value="scratchSimpleMessage.scheduledAt"
            @input="handleScheduledAtInput"
            :time-zone="scratchSimpleMessage.meta.presentation.timeZone"
            v-validate="'required|tzFormat|afterNow|after5MinFromNow'"
            data-vv-name="scheduledAt"
            data-vv-as="scheduled time"
            data-test-id="message-scheduled-at"
          />
        </ar-field>
        <ar-divider
          v-if="$arMediaQuery.pageContent.minWidth('md')"
          :style="{
            position: 'relative',
            top: '38px',
            width: '50px',
            margin: '0 10px',
          }"
        />
        <ar-field
          class="time-zone"
          label="Timezone"
          :class="[
            'u-flex-shrink-0',
          ]"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
          :error-messages="[veeErrors.first('timeZone')]"
        >
          <am2-timezone-select
            :value="scratchSimpleMessage.meta.presentation.timeZone"
            @input="handleTimezoneInput"
            v-validate="'required'"
            data-vv-name="timeZone"
            data-test-id="message-timezone-select"
          />
        </ar-field>
      </div>
    </ar-field>

    <!-- Cost Preview -->
    <div
      :class="[
        'cost-container',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
      :style="{
        marginTop: '70px',
        marginLeft: $arMediaQuery.pageContent.maxWidth('sm') ? '0' : '-12px',
      }"
    >
      <ar-checkbox
        :value="costAccepted"
        label="I agree to charge my credit card"
        :disabled="smsCostFailedToFetch"
        @change="emitCostDecisionChangeEvent"
        data-test-id="accecpt-cost-checkbox"
      />
      <div class="cost">
        <ar-text
          v-if="smsCostFailedToFetch"
          class="value"
          size="sm"
          weight="bold"
          allow-html
          :text="`<a>Error - Try again</a>`"
          v-tooltip.top="{
            content: 'There was a problem while calculating the cost of your SMS. Please try again.'
          }"
          @anchorClick="handleTryFetchingCostAgain"
        />
        <ar-text
          v-else-if="!isFetchingSmsCost"
          class="value"
          size="sm"
          weight="bold"
          :text="`${smsMessagePreview.cost.currency} ${formatCurrency(smsMessagePreview.cost.total)}`"
        />
        <am2-loading-spinner
          v-if="isFetchingSmsCost"
          :style="{
            width: '1.5rem',
            height: '1.5rem',
            marginRight: '1.25rem'
          }"
        />

        <ar-icon
          v-if="!smsCostFailedToFetch"
          v-tooltip.top="{
            content: 'This is the total cost for sending this message to all recipients'
          }"
          name="tooltip-question-mark"
          class="tooltip"
          height="20px"
          width="20px"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import accounting from 'accounting';
import {
  formatCurrency,
  convertToCamelCase,
  clone,
} from '@/utils/helpers/';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import SimpleMessageEditor from '@/components/editors/simple-message-editor';
import { recipientFilterToSidebar } from '~/store/modules/message/utils';

export default {
  name: 'SMS',

  mixins: [
    checkoutPageAccessibility({ featureKeys: ['messages', 'sms'], featureName: 'SMS' }),
  ],

  components: {
    SimpleMessageEditor,
  },

  props: {
    messageList: {
      type: Object,
      default: null,
    },
    costAccepted: {
      type: Boolean,
      default: false,
    },
    noContacts: {
      type: Boolean,
      default: false,
    },
    recipientCountMismatch: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      displayFallbackDynamicTagsField: false,
      showImportDynamicTagsModal: false,
      enableSchedule: false,
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
    };
  },

  computed: {
    ...mapState({
      messageOid: (state) => parseInt(state.route.params.oid, 10),
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      smsMessagePreview: state => state.message.smsMessagePreview,
      scratchSimpleMessage: state => state.message.scratchSimpleMessage,
      fallbackDynamicTagsForScratchSimpleMessage: state => state.message.fallbackDynamicTagsForScratchSimpleMessage,
      currentMessageList: state => state.messageList.currentMessageList,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      isFetchingSmsCost: state => state.message.isFetchingSmsCost,
      smsCostFailedToFetch: state => state.message.smsCostFailedToFetch,
      isFetchingFilteredRecipientListCount: state => state.messageList.isFetchingFilteredRecipientListCount,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      filteredRecipientListCountAccuracy: state => state.messageList.filteredRecipientListCountAccuracy,
    }),
    ...mapGetters({
      availableDynamicTagsInSimpleMessage: 'message/availableDynamicTagsInSimpleMessage',
      isFallbackDynamicTagsCompleteInSimpleMessage: 'message/isFallbackDynamicTagsCompleteInSimpleMessage',
      scratchSimpleMessageCampaignOid: 'message/scratchSimpleMessageCampaignOid',
      scratchSimpleMessageEventOid: 'message/scratchSimpleMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      nonExistingTagsInSimpleMessageErrorCopy: 'message/nonExistingTagsInSimpleMessageErrorCopy',
      smsMessagePreviewSegmentsCount: 'message/smsMessagePreviewSegmentsCount',
      smsMessagePreviewLength: 'message/smsMessagePreviewLength',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
      currentAdvancedTargetingIsValid: 'messageList/currentAdvancedTargetingIsValid',
      prunedScratchSegment: 'segment/prunedScratchSegment',
    }),
    // Because this is a long message, we're just putting the whole string into a computed prop.
    cantMessageContactsTooltipMessage() {
      return `Contacts within a list must be opted in to receive SMS messages. You are able to mass opt in contacts to
      SMS on the audience tab. Also ensure contacts you have added have valid mobile numbers.`;
    },
    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.currentMessageListIsForCampaignTier) return false;
      if (this.currentMessageListIsForAllCampaignRegistrants) return false;
      if (this.scratchSimpleMessageCampaignOid) return false;
      if (this.scratchSimpleMessageEventOid) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (this.isFetchingMessage) return false;
      if (!this.scratchSimpleMessage) return false;
      if (!this.scratchSimpleMessage.meta.messageListOid) return false;
      return true;
    },

    showFilterButton() {
      if (this.scratchSimpleMessageCampaignOid) return false;
      if (this.scratchSimpleMessageEventOid) return false;
      return true;
    },

    dynamicTagSelectItems() {
      if (this.availableDynamicTagsInSimpleMessage.length === 0) {
        return [
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      } else {
        return [
          ...this.availableDynamicTagsInSimpleMessage.map(tag => ({
            typography: {
              weight: 'bold',
            },
            name: tag,
            value: tag,
          })),
          {
            type: 'divider',
          },
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      }
    },
    dynamicTagsExist() {
      return this.scratchSimpleMessage.meta.dynamicTagHeaders && this.scratchSimpleMessage.meta.dynamicTagHeaders.length > 0;
    },
    isListInvalid() {
      return (this.noContacts && !!this.selectedFacebookMessengerPageId && !this.isFetchingFacebookPages && this.hasFetchedInitialFacebookPages) || this.currentMessageList?.statsSnapshot?.total === 0
    },

    areFiltersNotEmpty() {
      return this.filterIsNotEmpty || !(!!this.advancedMessageListTargeting.condition && this.advancedMessageListTargeting.type == "all")
    },

    // The number of recipients in the message list, after advanced targeting has been applied
    recipientsCount() {
      return this.filteredRecipientListCount || 0;
    },

    recipientCountText() {
      if (this.isFetchingFilteredRecipientListCount) return 'Calculating recipients';
      if (this.currentMessageList && Object.keys(this.currentMessageList.statsSnapshot).length === 0 ) {
        return "Calculating recipients";
      }
      if (this.recipientsCount === 1) return "1 recipient";
      if (this.recipientsCount === 0) return "No matching recipients";
      let estText = this.filteredRecipientListCountAccuracy === 'estimate' ? ' estimated ' : ' ';
      const filterText = this.recipientsCount > 0 ? ` will receive based on your filters ` : '';

      return `${accounting.format(this.recipientsCount)}${estText}recipients${filterText}`
    },

    filterIsNotEmpty() {
      return this.prunedScratchSegment && this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    filterCount () {
      if (!this.scratchSimpleMessage || !this.scratchSimpleMessage.meta) {
        return null;
      }

      return !!this.prunedScratchSegment.filter && !!this.prunedScratchSegment.filter.conditions ? this.prunedScratchSegment.filter.conditions.length : 0
    }

  },

  watch: {
    scratchSimpleMessage: {
      handler(val, oldVal) {
        if (val.meta.presentation.timeZone || val.scheduledAt) {
          this.enableSchedule = true;
        }

        // Whenever message changes, we need to update available fallback dynamic tags for this message.
        if (val.meta.messageBody && val.meta.messageBody !== oldVal?.meta?.messageBody) {
          this['message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_SIMPLE_MESSAGE'](val.meta.messageBody);
        }
      },
      immediate: true,
    },
    messageList: {
      handler(val) {
        if (val) {
          this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
            meta: {
              messageListOid: val.oid,
            }
          });
        }
      },
    },
    isFallbackDynamicTagsCompleteInSimpleMessage: {
      handler(val) {
        if (!val && this.displayFallbackDynamicTagsField === false) {
          this.displayFallbackDynamicTagsField = true;
        }
      },
      immediate: true,
    },

    prunedScratchSegment() {
      if (!this.isFetchingFilteredRecipientListCount) {
        const useStatsSnapshot = !this.advancedMessageListTargeting || (this.advancedMessageListTargeting.type === 'all' ||
          !this.advancedMessageListTargeting.type ||
          !this.advancedMessageListTargeting.condition ||
          !this.advancedMessageListTargeting.values ||
          this.advancedMessageListTargeting.values.length === 0);

        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
          channel: 'sms',
          skipStatsSnapshot: !useStatsSnapshot
        });
      }
    },
  },

  async created() {
    this['segment/RESET_SCRATCH_SEGMENT']();
    
    this.$validator.dictionary.merge({
      en: {
        custom: {
          messageListoid: {
            required: 'Please select a list.',
          },
        },
      },
    });

    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      },
    });
    this.$validator.extend('after5MinFromNow', {
      getMessage: field => `Scheduled time is invalid - must be more than 5 minutes from now`,
      validate: value => {
        return moment(value).isAfter(moment().add(5, 'minutes'));
      },
    });

    this.$validator.localize('en', {
      custom: {
        scheduledAt: {
          required: () => 'Please enter scheduled time',
        },
        timeZone: {
          required: () => 'Please select a timezone',
        },
        messageBody: {
          max: () => 'Your message exceeds the maximum allowed character limit',
        },
      },
    });
  },



  async mounted() {
    if (!!this.currentSelectedMessage && !!this.currentSelectedMessage.meta) {
      await this['segment/FETCH_SEGMENTS']();
      const recipientFilterClear = recipientFilterToSidebar(this.currentSelectedMessage.meta.recipientFilter)
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({messageSegmentOid: this.messageOid, filter: recipientFilterClear});
    } else {
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({messageSegmentOid: null, filter: {conditions: [], logic: []}});
    }
  },

  beforeDestroy() {
    this['segment/RESET_SCRATCH_SEGMENT']();
  },

  methods: {
    ...mapActions([
      'message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_SIMPLE_MESSAGE',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'SHOW_CONFIRM',
      'segment/FETCH_SEGMENTS',
    ]),
    ...mapMutations([
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE',
      'message/PUT_SCRATCH_SIMPLE_MESSAGE',
      'message/SET_CSV_PREVIEW_CONTACTS',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'segment/RESET_SCRATCH_SEGMENT',
      'segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
      'layout/TOGGLE_SEGMENT_DRAWER',
    ]),
    convertToCamelCase(val) {
      return convertToCamelCase(val);
    },
    formatCurrency(val) {
      return formatCurrency(val);
    },
    emitCostDecisionChangeEvent(val) {
      this.$emit('cost-decision-change', val);
    },
    clearScheduleTime() {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    handleFallbackDynamicTagsInput(tagName, val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          fallbackDynamicTags: {
            [tagName]: val,
          },
        },
      });
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    handleMessageBodyInput(val) {
      this.emitCostDecisionChangeEvent(false);
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: { messageBody: val },
      });
    },
    handleScheduledAtInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        scheduledAt: val,
      });
    },
    handleTimezoneInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: val,
          },
        },
      });
    },
    handleCustomerNameInput(customerName) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        customerName,
      });
    },
    async handleMessageListSelect(messageList) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE']();
      await this.$nextTick();

      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      if (!this.currentMessageListCanBeSchedued) {
        this.enableSchedule = false;
        this.clearScheduleTime();
      }
    },
    async handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      this['message/SET_CSV_PREVIEW_CONTACTS']({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows,
      });
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap,
        },
      });
      await this.$nextTick();

      this.showImportDynamicTagsModal = false;
    },
    async handleInsertDynamicTagsSelect(item) {
      if (item.value === 'addDynamicTags') {
        if (this.isListInvalid) {
          this.SHOW_CONFIRM({
            messageHtml: 'When you select a list with valid SMS subscribers you’ll be able to import dynamic tags.',
          });
          return;
        }
        this.showImportDynamicTagsModal = true;
      } else {
        this.$refs['simple-message-editor'].insertDynamicTag(item.value);
      }
    },

    appendSidebarFiltersToQuickFilters(targetingFilter) {
      if (!targetingFilter) {
        return this.prunedScratchSegment.filter
      }

      if (!targetingFilter.conditions) {
        targetingFilter.conditions = []
      }
      if (!targetingFilter.logic) {
        targetingFilter.logic = []
      }

      if (!!this.prunedScratchSegment.filter.conditions && this.prunedScratchSegment.filter.conditions.length > 0) {
        targetingFilter.conditions = this.prunedScratchSegment.filter.conditions.concat(targetingFilter.conditions)

        if (this.prunedScratchSegment.filter.logic.length > 0) {
          targetingFilter.logic = (['(']).concat(this.prunedScratchSegment.filter.logic).concat([')']).concat(['and']).concat(targetingFilter.logic)
        } else {
          targetingFilter.logic = ['and'].concat(targetingFilter.logic)
        }
      }

      return targetingFilter
    },

    handleAdvancedTargetingUpdate() {
      const targetingFilter = this.appendSidebarFiltersToQuickFilters(clone(this.getCurrentFilterExpression));
      const useStatsSnapshot = this.advancedMessageListTargeting.type === 'all' ||
        !this.advancedMessageListTargeting.type ||
        !this.advancedMessageListTargeting.condition ||
        !this.advancedMessageListTargeting.values ||
        this.advancedMessageListTargeting.values.length === 0;

      this['message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE'](targetingFilter);

      if (useStatsSnapshot) {
        this.$emit('forceCostRecount');
      }

      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'sms',
        skipStatsSnapshot: !useStatsSnapshot
      });
    },

    handleTryFetchingCostAgain() {
      this.$emit('forceCostRecount');
    },

    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']();
    },

    async handleClearAllFilters() {
      this['segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT']();
      await this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
      this.handleAdvancedTargetingUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
  .message-list-recipients-dropdown.filter-enabled {
    max-width: calc(100% - 104px);
  }

  .cost-container {
    padding: 15px;
    border-radius: 4px;
    background-color: #F1EDFE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .cost {
      display: flex;
      flex-direction: row;
      align-items: center;

      .value {
        margin-right: 1em;
      }

      .tooltip {
        color: $purple500;
      }
    }

    &.sm-max {
      flex-direction: column;

      .cost {
        margin-top: 8px;
      }
    }
  }

  .schedule-input-group {
    .time-zone {
      width: calc(100% - 340px);
      margin-top: 0;
    }
    .date-time {
      width: 270px;
    }
    &.sm-max {
      flex-direction: column;

      .ar-field {
        width: 100%;
      }
      .time-zone {
        margin-top: 16px;
        margin-bottom: 0;
      }


      .divider {
        display:none;
      }
    }
  }

  .recipients-count {
    background-color: $skyBlueGrey300;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    border-radius: 4px;

    .recipients-text {
      color: $blueGrey800;
    }

    .recipients-description {

    color: $blueGrey700;
    }
  }

  .circle-hooker {
    position: relative;
    .circle {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      background: $purple500;
      transform: translateX(50%) translateY(-50%);
      color: white;
      z-index: $zIndexHigh;

      span {
        position: relative;
        font-size: 10px;
        line-height: 10px;
        top: -1px;
      }
    }
  }

</style>
