<template>
  <a class="step-link" @click="$emit('click')">
    <ar-icon
      name="arrow"
      :rotate="90"
    />
    <span class="text" :style="{ top: isFirefox ? '0' : '-1px' }">{{ text }}</span>
  </a>
</template>

<script>
export default {
  name: 'StepLink',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFirefox: false, // Need it to fix annoying cross browser issus
    };
  },
  created() {
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      this.isFirefox = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.step-link {
  line-height: 1;
  display: inline-flex;
  align-items: center;
  color: $purple500;
  cursor: pointer;

  .text {
    position: relative;
    line-height: 1;
    margin-left: 9px;
  }
}
</style>
