var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reward-prize"},[_c('div',{staticClass:"u-display-flex u-justify-content-space-between"},[_c('am2-heading',{attrs:{"type":"h4","size":"xs","title":"Prize","weight":"bold"}}),_vm._v(" "),(_vm.removable)?_c('ar-icon-button',{style:({
        transform: 'translateX(6px) translateY(-6px)',
      }),attrs:{"icon-props":{
        name: 'discard',
        height: '14px',
      }},on:{"click":function($event){return _vm.$emit('removePrize')}}}):_vm._e()],1),_vm._v(" "),_c('am2-field',{attrs:{"name":"prize-name","label":"Prize name"}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"prize-name",staticClass:"input--block",attrs:{"id":"prize-name","value":_vm.details.prizeName,"data-vv-name":("prizeName_" + _vm.tierIndex),"data-vv-as":"prize name","type":"text"},on:{"input":function($event){return _vm.$emit('input', _vm.$data)}},model:{value:(_vm.details.prizeName),callback:function ($$v) {_vm.$set(_vm.details, "prizeName", $$v)},expression:"details.prizeName"}}),_vm._v(" "),(_vm.veeErrors.has(("prizeName_" + _vm.tierIndex)))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first(("prizeName_" + _vm.tierIndex)),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('am2-field',{attrs:{"name":"prize-value","label":"Prize value (Optional)"}},[_c('ar-input',{ref:"prize-value",staticClass:"input--block",attrs:{"id":"prize-value","value":_vm.details.value,"data-vv-name":("prizeValue_" + _vm.tierIndex),"type":"text"},on:{"input":function($event){return _vm.$emit('input', _vm.$data)}},model:{value:(_vm.details.value),callback:function ($$v) {_vm.$set(_vm.details, "value", _vm._n($$v))},expression:"details.value"}})],1),_vm._v(" "),_c('am2-field',{attrs:{"name":"prize-image","label":"Prize image (Optional)"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs"},[_c('am2-dropzone',{attrs:{"file-type":['image/jpeg', 'image/jpg', 'image/png'],"file-type-alias":"image","placeholder-icon":{
            name: 'image',
          },"image-options":{
            dimensions: {
              width: 1200,
              height: 628
            }
          },"ratio":0.525,"feedback-type":"image","feedback":_vm.prizeImage && _vm.prizeImage.url,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":[{
            assetType: 'prize-image',
            campaignOid: _vm.campaign.oid,
            eventOid: null,
          }]},on:{"delete":_vm.handleImageDelete,"uploadStart":function($event){_vm.isUploadingPrizeImage = true},"uploadEnd":function($event){_vm.isUploadingPrizeImage = false},"upload":_vm.handleImageUploaded}})],1),_vm._v(" "),_c('div',{staticClass:"col-xs image-upload-hint"},[_c('p',[_vm._v("We recommend using an image size of 1200x628px that's no larger than 2MB.")])])])]),_vm._v(" "),_c('am2-field',{attrs:{"name":"prize-description","label":"Prize description"}},[_c('ar-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"rows":3,"data-vv-name":("prizeDescription_" + _vm.tierIndex),"data-vv-as":"prize description"},on:{"input":function($event){return _vm.$emit('input', _vm.$data)}},model:{value:(_vm.details.description),callback:function ($$v) {_vm.$set(_vm.details, "description", $$v)},expression:"details.description"}}),_vm._v(" "),(_vm.veeErrors.has(("prizeDescription_" + _vm.tierIndex)))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first(("prizeDescription_" + _vm.tierIndex)),"type":"error"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }