<template>
  <div :class="['import-contacts-box', size]">
    <div :class="['left-section', size]">
      <ar-icon
        name="ar-audience-in-circle"
        height="49px"
      />
    </div>
    <div :class="['center-section', size]">
      <ar-text
        size="md"
        text="Import contacts"
        :align="size === 'small' ? 'center' : 'left'"
      />
      <ar-text
        size="xs"
        text="To get started, import a csv file or connect integrations to import your contacts."
        multiple-lines
        :align="size === 'small' ? 'center' : 'left'"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
          marginTop: '8px',
        }"
      />
    </div>
    <div :class="['right-section', size]">
      <ar-simple-button
        class="connect-integrations-button"
        text="Connect integrations"
        outlined
        @click="handleConnectIntegrationsClick"
      />
      <ar-simple-button
        class="import-csv-button"
        text="Import CSV File"
        @click="handleImportCsvClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportContactsBox',

  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (val) => ['small', 'medium'].indexOf(val) > -1,
    },
  },

  methods: {
    handleConnectIntegrationsClick() {
      this.$emit('connectIntegrations');
    },
    handleImportCsvClick() {
      this.$emit('importCsv');
    },
  },
};
</script>

<style lang="scss" scoped>
.import-contacts-box {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 2px solid white;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 5px 10px 0 rgba(67,81,107,0.05);

  &.small {
    flex-direction: column;
    align-items: center;
    padding: 27px 10px;
  }

  .left-section {
    padding-top: 27px;
    padding-left: 24px;
    padding-bottom: 28px;

    &.small {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  .center-section {
    flex: 1;
    margin-left: 19px;
    margin-right: 19px;
    padding-top: 29px;
    padding-bottom: 10px;

    &.small {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;
    }
  }

  .right-section {
    display: flex;
    padding-top: 30px;
    padding-right: 23px;

    &.small {
      padding-right: 0;
      flex-flow: column;
      .connect-integrations-button {
        width: 100%;
      }

      .import-csv-button {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .connect-integrations-button {
      width: 176px;
    }

    .import-csv-button {
      width: 144px;
      margin-left: 10px;
    }
  }
}
</style>
