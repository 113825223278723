<template>
  <div 
    class="planner-controls"
    :style="`right: ${isFullScreen ? '40px' : '400px'}`"
  >
    <div class="control-wrapper control-zoom-out" @click="zoomOut"  data-test-id="automation-zoom-out">
      <ZoomOutIcon class="control-icon" :color="`${$arStyle.color.skyBlueGrey700}`" />
    </div>
    <div class="control-wrapper control-zoom-in" @click="zoomIn" data-test-id="automation-zoom-in">
      <ZoomInIcon class="control-icon" :color="`${$arStyle.color.skyBlueGrey700}`" />
    </div>
    <div class="control-wrapper control-full-screen" @click="handleFullScreen" data-test-id="automation-fullscreen-toggle">
      <FullscreenIcon class="control-icon" :color="`${$arStyle.color.skyBlueGrey700}`" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ZoomOutIcon from '~/assets/icons/zoom-out-icon.svg?inline'
import ZoomInIcon from '~/assets/icons/zoom-in-icon.svg?inline'
import FullscreenIcon from '~/assets/icons/full-screen-icon.svg?inline'
export default {
  name: 'PlannerControls',
  components: {
    ZoomOutIcon,
    ZoomInIcon,
    FullscreenIcon,
  },
  computed: {
    ...mapState({
      zoomVal: state => state.automation.zoomVal,
      isFullScreen: state => state.automation.isFullScreen,
    })
  },
  methods: {
    ...mapActions({
      setZoom: 'automation/SET_ZOOM',
      toggleFullScreen: 'automation/TOGGLE_FULL_SCREEN',
    }),
    zoomIn() {
      if (this.zoomVal < 2) {
        this.setZoom(parseFloat((this.zoomVal + 0.1).toFixed(1)))
      }
    },
    zoomOut() {
      if (this.zoomVal > 0.5) {
        this.setZoom(parseFloat((this.zoomVal - 0.1).toFixed(1)))
      }
    },
    handleFullScreen() {
      this.toggleFullScreen()
    }
  }
}
</script>
<style lang="scss" scoped>
.planner-controls {
  position: fixed;
  bottom: 20px;
  right: 400px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(6px);
  z-index: 99;
  border-radius: 5px;

  .control-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: $skyBlueGrey400;
    border-radius: 3px;
    margin-left: 8px;
    padding: 8px 7px;
    cursor: pointer;

    &.control-zoom-out {
      padding: 14px 7px;
    }
  }
}
</style>