var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:['loading-bubble', _vm.isShow && 'show'],style:({
    width: _vm.size,
    height: _vm.size,
  })},[_c('span',{class:"blob left",style:({
      width: _vm.size,
      height: _vm.size,
    })}),_vm._v(" "),_c('span',{class:"blob right",style:({
      width: _vm.size,
      height: _vm.size,
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }