<template>
  <am2-dropdown-binder
    :align="align"
    :pixel-shift="10"
    button-name="am2-expand-icon-button"
    :button-props="buttonProps"
    :dropdown-props="{
      items,
    }"
    @select="handleItemSelect"
  />
</template>
<script>
export default {
  name: 'ExpandButtonDropdown',
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].indexOf(val) > -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    buttonProps: {
      type: Object,
      default: () => ({
        iconName: 'settings',
      }),
    },
  },
  methods: {
    handleItemSelect(item) {
      this.$emit('select', item);
    },
  },
}
</script>

