<template>
  <EditableFrame
    class="remove-from-list-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="remove-from-list-heading"
      size="13px"
      text="LISTS"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="remove-from-list-container">
      <div class="remove-from-list-dropdown-wrapper" @click="toggleOptions" data-test-id="remove-from-list-toggle">
        <ar-text
          class="remove-from-list-copy"
          size="14px"
          :text="messageListText"
          multiple-lines
          :max-lines="1"
          line-height="20px"
        />
        <ArrowHead 
          class="remove-from-list-dropdown-arrowhead"
          :class="listOpen && 'rotate'"
          height="8px" 
          width="10px" 
          fill="#8492a6"          
        />
      </div>
      <div v-if="listOpen" class="list-search-wrapper">
        <am2-search
          class="remove-from-list-search"
          v-model="listSearchText"
          placeholder="Search lists..."
          data-test-id="remove-from-list-search"
        />
        <div class="message-list-wrapper">
          <div v-if="noMatchingLists" class="message-list-link no-list-found-wrapper">
            <div class="item-wrapper">
              <ar-text
                class="item-name"
                :style="{ marginRight: '4px' }"
                size="xs"
                text="No matching list found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
          <template v-for="list in filteredMessageList">
            <a
              :key="list.oid"
              class="message-list-link"
              @click="() => setSelectedListOid(list.oid)"
              :data-test-id="`remove-from-list-anchor-${list.oid}`"
            >
              <div class="item-wrapper">
                <ar-text
                  :class="[
                    'list-name',
                    list.oid === selectedListOid && 'selected'
                  ]"
                  size="xs"
                  :text="list.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  :weight="list.oid === selectedListOid ? 'bold' : 'normal'"
                />
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ListRemove',
  components: {
    EditableFrame,
    ArrowHead,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedListOid: null,
      filteredMessageList: [],
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    messageLists: {
      immediate: true,
      handler() {
        this.filteredMessageList = this.messageLists
      }
    },
    listSearchText() {
      this.filterLists(this.listSearchText)
    }
  },
  computed: {
    ...mapGetters({
      messageLists: 'messageList/userDefinedMessageLists'
    }),
    noMatchingLists() {
      return !this.filteredMessageList.length
    },
    messageListText() {
      if (!this.selectedListOid || !this.messageLists.length) return 'Select message list...'
      return this.messageLists.find(item => item.oid === this.selectedListOid).name
    },
    savedButtonDisabled() {
      return !this.selectedListOid
    }
  },
  methods: {
    ...mapActions({
      fetchMessageLists: 'automation/FETCH_MESSAGE_LISTS',
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG'
    }),
    handleClickOutside(e) {
      if (!this.listOpen) return

      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'remove-from-list-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    setSelectedListOid(oid) {
      this.selectedListOid = oid
      this.listOpen = false
    },
    filterLists(text) {
      let filteredMessageList = []
      let i = 0
      while (i < this.messageLists.length) {
        if (this.$arFuzzySearch(this.messageLists[i].name, text)) {
          filteredMessageList.push(this.messageLists[i])
        }

        i++
      }

      this.filteredMessageList = filteredMessageList
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    handleSave() {
      let list = this.messageLists.find(item => item.oid === this.selectedListOid)

      const contextData = {
        config: {
          "message-list-oid": this.selectedListOid
        },
        meta: {
          list: list.name
        }
      }

      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      })
      this.$emit('close')
    },
    resetDefaults() {
      this.selectedListOid = null
      this.listOpen = false
      this.listSearchText = ''
    },
    setItemVals() {
      this.resetDefaults()
      if (this.item.config?.['message-list-oid']) {
        this.selectedListOid = this.item.config['message-list-oid']
      }
    }
  },
  fetch() {
    this.fetchMessageLists()
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.remove-from-list-wrapper {
  width: 100%;
  height: 100%;

  .remove-from-list-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .remove-from-list-container {
    
    .remove-from-list-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;

      .remove-from-list-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .list-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;

      .remove-from-list-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .message-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        padding-bottom: 10px;

        .message-list-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-list-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;

            .list-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
  }
}
</style>