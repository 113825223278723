<template>
  <section>
    <am2-top-create
      ref="campaign-top"
      title="Create a campaign"
      :exit-path="sanitizedReferringPage" />

    <div :class="[
      'wrapper',
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      $arMediaQuery.pageContent.minWidth('lg') && 'lg-min',
    ]">
      <div class="left">
        <div class="inner">
          <nuxt-child
            @change="handleKeySelection"
            @new-event="handleCreateNewEvent"
          />
        </div>
        <nav class="navigation">
          <ar-simple-button
            v-if="previousPage"
            outlined
            data-test-id="back-button"
            text="Back"
            @click="handleBackStep"
            :style="{ width: '120px' }"
          />
          <ar-simple-button
            v-if="skipEventSelection"
            :disabled="!(selectedCampaignType && selectedEventOid)"
            text="Setup the campaign"
            data-test-id="setup-campaign-button"
            @click="handleNextStep"
            :style="{ width: '100%' }"
          />
          <ar-simple-button
            v-else-if="hasNextPage"
            :disabled="!selectedCampaignType"
            text="Continue"
            data-test-id="continue-button"
            @click="handleNextStep"
            :style="{ width: '100%' }"
          />
          <ar-simple-button
            v-else
            :disabled="!selectedEventOid"
            text="Setup the campaign"
            data-test-id="setup-campaign-2-button"
            @click="handleNextStep"
          />
        </nav>
      </div>
      <div
        class="right"
        :style="{
          'minWidth': sidebarWidth
        }"
      >
        <div v-if="!selectedCampaignType" :class="[
          'get-started',
        ]">
          <am2-heading
            type="h1"
            size="lg"
            title="First, let's get you set up."
            :style="{ marginBottom: '6px' }"
          />
          <am2-heading
            type="h2"
            size="sm"
            title="Pick a campaign template to get you started"
          />
        </div>
        <div v-else class="campaign-preview">
          <div class="campaign-preview__header" v-if="!!campaignTypeObject.name && !!campaignTypeObject.subtitle">
            <p class="campaign-preview__title">{{ campaignTypeObject.name }}</p>
            <p v-if="!freeCampaign" class="campaign-preview__subtitle">{{ campaignTypeObject.subtitle }}</p>
          </div>

          <p>{{ campaignTypeObject.description }}</p>
          <br>
          <div
            v-if="!freeCampaign"
            v-for="(feature, index) in campaignTypeObject.features"
            :key="index"
            class="campaign-preview__feature"
          >
            <ar-icon
              class="tick"
              name="check"
              height="12px"
            />
            <span>
              {{ feature }}
            </span>
          </div>

          <hr v-if="!freeCampaign && !!campaignTypeObject.link" />
          <a 
            v-if="!freeCampaign && !!campaignTypeObject.link" 
            :href="campaignTypeObject.link" 
            data-test-id="learn-more-button"
          >
          Learn more about the {{ campaignTypeObject.name }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import moment from 'moment';
import { CampaignTypeDescriptions, FreeCampaignTypeDescriptions } from './copies';

export default {
  name: 'CampaignSetup',
  layout: 'edit',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['campaigns'], featureName: 'Campaigns' }),
  ],

  data: () => ({
    steps: ['type', 'event'],
    detailsPath: '/campaigns/new/details',
    selectedCampaignType: null,
    selectedEventOid: null,
    skipEventSelection: null,
    campaignTypes: [
      {
        name: 'Presale Registration',
        key: 'presale',
        title: 'Presale campaign',
        subtitle: 'Make your presale go viral',
        link: 'https://audiencerepublic.com/campaigns/presale/',
        features: [
          'Get up to 263% more presale registrations',
          'Sell more tickets through building your audience',
          'Collect valuable data & insights'
        ]
      }, {
        name: 'Ballot',
        key: 'ballot',
        title: 'Ballot campaign',
        subtitle: 'Leverage high demand events to build your audience',
        link: 'https://audiencerepublic.com/campaigns/ballot/',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      }, {
        name: 'Competition',
        key: 'competition',
        title: 'Competition campaign',
        subtitle: 'Make your competitions go viral and build your audience',
        link: 'https://audiencerepublic.com/campaigns/competition/',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      },
      {
        name: 'Wait List',
        key: 'waitlist',
        title: 'Waiting list campaign',
        subtitle: 'Fans can join the waiting list for any extra tickets that become available',
        link: 'https://audiencerepublic.com/campaigns/waiting-list/',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      },
      {
        name: 'Livestream',
        key: 'livestream',
        title: 'Live stream campaign',
        subtitle: '',
        link: null,
        features: []
      },
      {
        name: 'Donate',
        key: 'donate',
        title: 'Donate campaign',
        subtitle: '',
        link: null,
        features: []
      },
      {
        name: 'Pre-Order',
        key: 'preorder',
        title: 'Pre-Order campaign',
        subtitle: '',
        link: null,
        features: []
      }
    ],
    sidebarWidth: '',
    subscriptionLevelData: null,
  }),

  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    const sessionCampaign = sessionStorage.getItem('_ar_promoter_new_campaign');
    if(sessionCampaign && to.path !== this.detailsPath) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if(response) {
        sessionStorage.removeItem('_ar_promoter_new_campaign');
        next();
      } else {
        next(false)
      }
    } else {
      next();
    }


    window.removeEventListener("resize", this.resizeListener);
  },

  destroy () {
    window.removeEventListener("resize", this.resizeListener);
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
    }),
    ...mapState({
      query: state => state.route.query,
      path: state => state.route.path,
    }),
    isPremiumCampaignEnabled() {
      return this.isFeatureEnabled(['campaigns']);
    },
    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/campaigns');
    },
    currentPage() {
      const routeParts = this.$route.path.split('/');
      return routeParts[routeParts.length - 1];
    },
    currentPageIndex() {
      return this.steps.indexOf(this.currentPage);
    },
    hasNextPage(){
      return !!this.nextPage;
    },
    previousPage() {
      return this.steps[this.currentPageIndex - 1];
    },
    nextPage() {
      return this.steps[this.currentPageIndex + 1];
    },
    freeCampaign() {
      return !(this.subscriptionLevelData === 'premium' && this.isPremiumCampaignEnabled);
    },
    campaignTypeObject(){
      if (this.selectedCampaignType) {
        const campaign = this.campaignTypes.find(c => c.key === this.selectedCampaignType);
        campaign.description = this.getCampaignTypeDesctiption(this.selectedCampaignType);
        return campaign;
      } else {
        return null;
      }
    },
  },

  created() {
    this.selectedCampaignType = this.query.type;
    this.selectedEventOid = this.query.eventOid;
    this.skipEventSelection = this.query.skipEventSelection;
    window.addEventListener("resize", this.resizeListener);
    this.checkSidebarWidth()
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),

    async handleNextStep() {
      if (this.skipEventSelection && this.selectedCampaignType && this.selectedEventOid) {
        this.$router.push({
          name: 'campaigns-new-details',
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
          params: {
            subscriptionLevel: this.subscriptionLevelData
          }
        });
      } else if (this.path === '/campaigns/setup/event') {
        this.$router.push({
          name: 'campaigns-new-details',
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
          params: {
            subscriptionLevel: this.subscriptionLevelData
          }
        });
      } else if (this.path === '/campaigns/setup/type') {
        this.$router.push({
          name: `campaignSetup-${this.nextPage}`,
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
          params: {
            subscriptionLevel: this.subscriptionLevelData
          }
        });
      }
    },

    handleBackStep() {
      if (this.previousPage) {
        this.$router.push({
          path: this.previousPage,
          query: this.query,
        });
      }
    },

    getCampaignTypeDesctiption(type) {
      if (this.freeCampaign) {
        return FreeCampaignTypeDescriptions[type];
      } else {
        return CampaignTypeDescriptions[type];
      }
    },

    handleKeySelection(theChange) {
      if (theChange.type) {
        this.selectedCampaignType = theChange.type;
        this.$router.replace({
          path: this.path,
          query: {
            ...this.query,
            type: this.selectedCampaignType,
          },
        });
      }

      if (theChange.eventOid) {
        this.selectedEventOid = theChange.eventOid;
        this.$router.replace({
          path: this.path,
          query: {
            ...this.query,
            eventOid: this.selectedEventOid,
          },
        });
      }

      if (theChange.subscriptionLevel) {
        this.subscriptionLevelData = `${theChange.subscriptionLevel}`;
      }
    },

    handleCreateNewEvent() {
      if (this.query.type) {
        this.$router.replace({
          path: `/events/new`,
          query: {
            campaignType: this.query.type
          },
        });
      }
    },

    checkSidebarWidth() {
      if (window.innerWidth > 990 && window.innerWidth < 1350) {
        this.sidebarWidth = `${(window.innerWidth - 768)}px`
      } else if (window.innerWidth >= 1350) {
        this.sidebarWidth = `582px`
      }
    },
    resizeListener(e) {
      this.checkSidebarWidth()
    }

  }
};
</script>
<style lang="scss" scoped>
$campaign-md-max: 860px;

.wrapper {

  display: flex;
  padding: 90px 0 0 0;
  height: 100vh;

  .left {

    display: flex;
    flex-direction: column;
    max-width: $lg-min;
    min-width: $md-min;
    width: 100%;
    height: 100%;
    background: #fff;

    .inner {

      flex-grow: 1;
      padding: 50px 80px;
      height: calc(100% - 110px);
      overflow-y: auto;

    }

    .navigation {
      position: relative;
      width: 100%;
      z-index: $zIndexHighest;
      height: 110px;
      padding: 30px 80px;
      border-top: 1px solid $skyBlueGrey500;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  .right {
    max-width: $md-min;
    min-width: 582px;
    padding: 50px;
  }

  &.md-max {
    padding: 68px 0 0 0;
    flex-direction: column;
    .left {
      max-width: 100vw;
      min-width: 100vw;
      min-height: 100%;
      .inner {
        padding: 30px 12px;
        height:100%;
      }
      .navigation {
        height: 76px;
        padding: 10px 12px;
        position:fixed;
        bottom:0;
      }
    }
    .right {
      padding: 30px 12px 106px;
      max-height: none;
      min-width: unset;
    }
  }

}

.campaign-preview {
  max-width: $md-min;
  hr {
    margin: 24px 0px;
    border-top: 1px solid $skyBlueGrey500;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__title {
    color: $blueGrey800;
    font-weight: bold;
    font-size: 20px;
  }

  &__subtitle {
    color: $blueGrey700;
    font-size: 16px;
  }

  &__feature {
    .tick {
      color: $green500;
      margin-right: 8px;
    }
  }
}


</style>
