var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"plan-card",style:({
    border: 'none',
  })},[(_vm.isMostPopular)?_c('div',{staticClass:"header-section"},[_c('ar-text',{style:({
        color: 'white',
      }),attrs:{"size":"xs","text":"MOST POPULAR","align":"center","weight":"bold"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-section"},[_c('div',{staticClass:"info-section"},[_c('ar-text',{attrs:{"size":"md","text":_vm.name,"align":"center","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-10",style:({
          color: _vm.$arStyle.color.purple500,
        }),attrs:{"size":"lg","text":_vm.priceCopy}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"xs","text":_vm.periodAndUnit}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-2",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"xs","text":_vm.numberOfContactsCopy}})],1),_vm._v(" "),(_vm.hasCustomBaseSubscription)?_c('ar-simple-button',{staticClass:"confirm-button",attrs:{"text":"Contact Sales","type":"gradient-purple"},on:{"click":_vm.handleContactSalesClick}}):_c('ar-simple-button',{staticClass:"confirm-button",attrs:{"text":_vm.buttonText,"type":_vm.buttonType,"outlined":""},on:{"click":_vm.handleButtonClick}}),_vm._v(" "),_c('div',{staticClass:"feature-list u-margin-top-12"},_vm._l((_vm.features),function(feature,featureIdx){return _c('div',{key:feature.key},[_c('div',{staticClass:"feature-section"},[(_vm.paymentPlan.featureIds.indexOf(feature.key) > -1)?_c('ar-icon',{attrs:{"name":"notification-success","color":_vm.$arStyle.color.purple500,"width":"20px"}}):_c('div',{staticClass:"empty-circle"}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: feature.description,
            }),expression:"{\n              content: feature.description,\n            }",modifiers:{"top":true}}],style:({
              marginLeft: '10px',
            }),attrs:{"size":"xs","text":feature.name}})],1),_vm._v(" "),(featureIdx !== _vm.features.length - 1)?_c('ar-divider',{style:({
            background: _vm.$arStyle.color.skyBlueGrey400,
            margin: '14px 0 15px',
          })}):_vm._e()],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }