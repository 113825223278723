<template>
  <main class="tour-view">
      <am2-hero-skeleton
        v-if="isFetchingTour"
        type="hero"
        :back-path="toursListPath"
        is-tours
      />
      <am2-hero
        v-else
        :image="tourImage"
        :title="tour.name"
        :event-name="tour.name"
        :url-slug="tour.urlSlug"
        :back-path="toursListPath"
        is-tours
      />

      <am2-navbar :menu="menu" class="navigation-events">
        <am2-loading-section
          v-if="isFetchingTour"
          slot="controls"
          class="buttons-section"
          :style="{
            width: '200px',
            height: '50px',
          }"
        />
        <div
          v-else
          slot="controls"
          class="buttons-section"
        >
          <ar-simple-button
            text="View Tour"
            icon-name="preview"
            icon-distance="13px"
            :icon-props="{
              color: $arStyle.color.blueGrey700,
            }"
            type="grey"
            @click="viewTour"
          />
          <am2-tour-dropdown
            class="expand-button"
            kind="expand-icon-button"
            :tour="tour"
            @delete="handleTourDelete"
          />
        </div>
      </am2-navbar>

      <nuxt-child class="content content--centered"
        :tour="tour"
        @on-campaign-delete="campaignDeleteHandler"
      />

  </main>
</template>

<script>
import { mapActions } from 'vuex';
import { campaignBaseUri, findFirstError, campaignImage } from '@/utils/campaign';
import { tourUri } from '@/utils/tour'
import moment from 'moment';

const defaultTour = {
  name: '',
  campaignOids: [],
  resources: [],
  resourceOids: [],
  urlSlug: '',
  presentation: { colorData: { hex: '#7344c0' }},
};

export default {
  layout: 'default',

  scrollToTop: true,
  data() {
    return {
      isFetchingTour: false,
      tour: defaultTour,
    };
  },
  computed: {
    toursListPath() {
      return '/campaigns?tab=tour&filter=all&showAs=list';
    },

    tourImage() {
      return campaignImage(this.tour);
    },

    menu() {
      return {
        campaigns: {
          name: 'Campaigns',
          to: `/tours/${this.tour.oid}/view/campaigns`,
          featureKeys: null,
        },
      };
    },

    // campaignTimeframe() {
    //   return `${moment(this.campaign.startDate).format('ddd Do MMM - ')} ${moment(this.campaign.endDate).format(
    //     'ddd Do MMM, YYYY'
    //   )}`;
    // }
  },

  async mounted() {
    try {
      this.isFetchingTour = true;
      this.tour = await this.FETCH_TOUR_BY_OID({
        oid: this.$route.params.oid,
      });
    } catch(e) {
      console.error(e);
    } finally {
      this.isFetchingTour = false;
    }
    if(!this.tour) {
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch tour' });
      this.$router.push({
        path: `/campaigns`
      });
    }
  },

  methods: {
    ...mapActions([
      'FETCH_TOUR_BY_OID',
    ]),

    async refetchTour() {
      this.tour = await this.FETCH_TOUR_BY_OID({oid: this.tour.oid});
    },

    campaignDeleteHandler() {
      if (this.tour.campaignOids.length === 1) {
        this.$router.push({
          path: this.toursListPath
        })
      } else {
        this.refetchTour();
      }
    },

    viewTour() {
      const destination = tourUri(this.tour.urlSlug);
      window.open(destination, '_blank');
    },

    handleTourDelete() {
      this.$router.push({
        path: `/campaigns?tab=tour&filter=all&showAs=list`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tour-view {
  .buttons-section {
    display: inline-flex;
    align-items: center;
    .dropdown-button {
      margin-left: 7px;
    }
  }
  .button-shit {
    background: #edf1f5;
    color: #546573;
    &:hover {
      background: $skyBlueGrey500;
    }
  }
  .content {
    padding-top: $content-gutter;
  }
  .navigation-events {
    &__controls-container {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
