import VirtualFanImageOne from '@/assets/images/placeholders/virtual_fan_1.jpg';
import VirtualFanImageTwo from '@/assets/images/placeholders/virtual_fan_2.jpg';
import VirtualFanImageThree from '@/assets/images/placeholders/virtual_fan_3.jpg';
import VirtualFanImageFour from '@/assets/images/placeholders/virtual_fan_4.jpg';
import VirtualFanImageFive from '@/assets/images/placeholders/virtual_fan_5.jpg';

export const virtualFans = [
  {
    avatar: VirtualFanImageOne,
    firstName: 'John',
    lastName: 'Titor',
    emailAddress: 'john@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageTwo,
    firstName: 'Aron',
    lastName: 'Josh',
    emailAddress: 'aron_josh@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageThree,
    firstName: 'Derek',
    lastName: 'Jeter',
    emailAddress: 'derek_jeter@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageFour,
    firstName: 'Steve',
    lastName: 'Kerr',
    emailAddress: 'stever_kerr@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageFive,
    firstName: 'Jay',
    lastName: 'Chou',
    emailAddress: 'jay_chou@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageOne,
    firstName: 'Yamamoto',
    lastName: 'Suzuki',
    emailAddress: 'suzuki_y@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageOne,
    firstName: 'John',
    lastName: 'Titor',
    emailAddress: 'john@gmail.com',
    mobileNumber: '+61111222333',
  },
  {
    avatar: VirtualFanImageTwo,
    firstName: 'Aron',
    lastName: 'Josh',
    emailAddress: 'aron_josh@gmail.com',
    mobileNumber: '+61111222333',
  },
];