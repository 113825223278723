<template>
  <section class="campaign-edit-share">
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 4"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      class="title"
      type="h1"
      size="lg"
      title="Social: Share"
      :style="{marginBottom: '38.5px'}"
    />

    <p class="large">
      {{copy}}
    </p>
    
    <am2-field
      v-if="eventPaymentInfo === 'premium'"
      name="social-media-channels"
      :class="[
        'social-media-channels',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
      ]"
      label="Social media channels"
    >
      <!-- Cuz we only block 'Next' button action by vee-validate,
      so we need to put invisible input with v-validate to check if we have any channel ticked -->
      <input
        v-if="!!shareChannels"
        :value="JSON.stringify(shareChannels)"
        :style="{ display: 'none' }"
        data-vv-name="shareChannels"
        data-test-id="has-share-channel"
      >
      <ar-state-message
        v-if="veeErrors.has('shareChannels')"
        :text="veeErrors.first('shareChannels')"
        type="error"
      />
      <p class="hint-text">Choose how your audience can share across social media:</p>

      <draggable
        v-if="!!shareChannels"
        :list="campaignViewModel.socialActions.shares"
        data-test-id="draggable-social-channels"
      >
        <transition-group name="overlay-fade" tag="div">
          <div
            v-for="(channel) in campaignViewModel.socialActions.shares"
            :key="channel.key"
            :data-test-id="`share-channel-${channel.key}`"
          >
            <ar-checkbox
              v-if="channel.selected || (!hideExtraFields)"
              v-model="channel.selected"
              :icon-name="channel.key"
              :icon-props="{ color: channel.iconColor }"
              :label="channel.name"
              align="right"
              :style="{ height: '52px', width: '100%' }"
              :data-test-id="`share-channel-checkbox-${channel.key}`"
            />
            <ar-divider
              v-if="(channel.selected || (!hideExtraFields)) && !checkLastVisibleChannel(channel.key)"
            />
          </div>
        </transition-group>
      </draggable>
      

      <a
        v-if="showToggleFieldsButton"
        class="toggle-fields"
        @click="hideExtraFields = !hideExtraFields"
        data-test-id="toggle-extra-fields"
      >
        {{ hideExtraFields ? 'Show all platforms' : 'Show fewer platforms' }}
      </a>

    </am2-field>

    <div
      v-if="eventPaymentInfo === 'free'"
      :class="[
        'campaign-edit-share__preview',
        'locked',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]"
    >
      Allow fans to earn points by inviting their friends
      <ar-simple-button
        icon-name="circle-plus"
        text="Upgrade now"
        @click="chooseFree"
      />

    </div>
    <div
      v-if="eventPaymentInfo === 'premium'"
      :class="[
        'campaign-edit-share__preview',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]"
    >
      <div>
        <div class="campaign-edit-share__link">
          <div
            class="circle"
          />

          <div class="title-section">
            <ar-text
              :text="`<b>You</b> shared a <b>link.</b>`"
              allow-html
              size="xs"
              class="share-top-heading"
            />
            <p class="share-top-time-text">3 mins</p>
          </div>

          <ar-icon
            name="menu"
            class="menu-icon"
            :rotate="90"
          />
        </div>

        <div class="campaign-edit-share__card">
          <img :src="campaignImage">
          <div class="campaign-edit-share__content">
            <!-- #464950 is from Facebook UI -->
            <ar-text
              size="xxxs"
              :text="campaignDomain"
              :style="{
                color: '#464950',
                marginBottom: '2px',
              }"
            />
            <!-- #1c2028 is from Facebook UI -->
            <ar-text
              class="campaign-edit-share__content-title"
              :text="shareTitle"
              size="xs"
              multiple-lines
              :max-lines="2"
              line-height="18px"
              weight="bold"
              :style="{
                color: '#1c2028',
                marginBottom: '2px',
              }"
            />
            <!-- #464950 is from Facebook UI -->
            <ar-text
              :text="shareDescription"
              size="xxxs"
              :style="{
                color: '#464950',
              }"
            />
          </div>
        </div>

        <div class="campaign-edit-share__bottom">
          <div class="share-bottom__group">
            <ar-icon
              name="like"
              :class="[
                'share-bottom-icon',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              ]"
            />
            <span class="share-bottom-text">Like</span>
          </div>
          <div class="share-bottom__group">
            <ar-icon
              name="dialog"
              :class="[
                'share-bottom-icon',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              ]"
            />
            <span class="share-bottom-text">Comment</span>
          </div>
          <div class="share-bottom__group">
            <ar-icon
              name="share"
              :class="[
                'share-bottom-icon',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              ]"
            />
            <span class="share-bottom-text">Share</span>
          </div>
        </div>
      </div>

      <hr>

      <am2-field
        name="share-media-title"
        class="share-media-title"
        label="Share title"
        description="Write a call to action that will get people to click."
      >
        <ar-input
          v-validate="'required'"
          v-model="campaignViewModel.socialActions.meta.title"
          autocomplete="campaign-headline"
          data-vv-name="shareMediaTitle"
          class="input--block"
          type="text"/>
        <ar-state-message
          v-if="veeErrors.has('shareMediaTitle')"
          type="error"
          :style="{ marginTop: '8px' }"
          :text="veeErrors.first('shareMediaTitle')"
        />
      </am2-field>

      <am2-field
        name="share-media-description"
        class="share-media-description"
        label="Share description"
        description="Tell people why they should register, include the incentives and create urgency."
      >
        <ar-textarea
          v-validate="'required'"
          v-model="campaignViewModel.socialActions.meta.description"
          :rows="3"
          data-vv-name="shareMediaDescription"
        />
        <ar-state-message
          v-if="veeErrors.has('shareMediaDescription')"
          type="error"
          :style="{ marginTop: '8px' }"
          :text="veeErrors.first('shareMediaDescription')"
        />
      </am2-field>
    </div>
  </section>
</template>

<script>
import {
  validatorDictionary,
  campaignImage,
  copyGenerator
} from '@/utils/campaign';
import draggable from 'vuedraggable'
import { mapState } from 'vuex'
import { clone } from '@/utils/helpers/';

export default {
  name: 'EditShare',

  components: {
    draggable,
  },

  data: () => ({
    campaignViewModel: {
      socialActions: {
        meta: { title: '', description: '' }
      }
    },
    hideExtraFields: true,
    campaignDomain: new URL(process.env.arCampaignClientDomain).hostname.toUpperCase(),
  }),

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign.currentCampaign,
    }),
    shareTitle() {
      return this.campaignViewModel?.socialActions?.meta?.title
    },
    shareDescription() {
      return this.campaignViewModel?.socialActions?.meta?.description
    },
    shareChannels() {
      return this.campaignViewModel?.socialActions?.shares || null
    },
    eventPaymentInfo() {
      return 'premium';
    },
    showToggleFieldsButton() {
      return this.campaignViewModel?.socialActions?.shares?.some(field => {
        return !field.selected;
      })
    },
    copy() {
      return copyGenerator('share', this.editCampaign.type);
    },
    campaignImage() {
      return campaignImage(this.editCampaign);
    },
    visibleShareChannels() {
      if (this.hideExtraFields) {
        return this.campaignViewModel.socialActions.shares
          .filter(channel => {
            return channel.selected
          })
      }

      return this.campaignViewModel.socialActions.shares
    }
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        const { socialActions } = value

        const shareMap = socialActions.shares.reduce((accum, item, index) => {
          accum[item.key] = {
            enabled: item.selected,
            order: index,
          }
          return accum
        }, {})

        let campaignShareData = {
          socialActions: {
            ...socialActions,
            shares: shareMap
          }
        }

        this.setEditCampaign(campaignShareData)
      },
      deep: true
    },
  },

  created() {
    this.$validator.extend('hasShareChannel', {
      getMessage: () => 'Need to select at least one share channel',
      validate: channels => {
        return JSON.parse(channels).filter(share => share.selected === true).length > 0;
      },
    });
  },

  mounted() {
    this.$validator.dictionary.merge(validatorDictionary());
    this.initializeCampaignViewModel()
  },

  methods: {
    initializeCampaignViewModel() {
      const { socialActions, type } = this.editCampaign
      const shares = socialActions ? socialActions.shares || {} : {}
      const isSharesOldVersion = !!socialActions?.shares?.facebook ? typeof socialActions?.shares?.facebook === "boolean" : true

      const shareChannels = [
        {
          name: 'Facebook',
          key: 'facebook',
          iconColor: this.$arStyle.color.facebook,
          selected: isSharesOldVersion ? shares?.facebook !== false || true : shares.facebook.enabled !== false, // default true
          order: parseInt(isSharesOldVersion ? 0 : shares.facebook.order),
        },
        {
          name: 'Twitter',
          key: 'twitter',
          iconColor: this.$arStyle.color.twitter,
          selected: isSharesOldVersion ? shares.twitter !== false || true : shares.twitter.enabled !== false, // default true
          order: parseInt(isSharesOldVersion ? 1 : shares.twitter.order),
        },
        {
          name: 'LinkedIn',
          key: 'linkedin',
          iconColor: this.$arStyle.color.linkedin,
          selected: shares.linkedin ? (isSharesOldVersion ? shares.linkedin || false : shares.linkedin.enabled ) : false,
          order: parseInt(isSharesOldVersion ? 2 : shares.linkedin.order),
        },
      ].sort((a, b) =>{
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0
      })

      let socActions = !!socialActions ? socialActions : {}
      if (!socActions?.meta) {
        socActions['meta'] = {title: '', description: ''}
      }

      let socialActionsInit = {
        ...socActions,
        shares: shareChannels,
      }

      let campaignViewModel = {
        socialActions: {
          ...socialActionsInit,
        },
        type,
      }

      this.campaignViewModel = clone(campaignViewModel)
    },

    checkLastVisibleChannel(key) {
      if (key != null && !!this.visibleShareChannels) {
        return false;
      }

      return this.visibleShareChannels[this.visibleShareChannels.length - 1]?.key == key
    }
  },
};
</script>

<style lang="scss">
.campaign-edit-share {
  &__preview {
    margin-top: 2.5rem;
    background-color: rgba(237, 241, 245, 0.5);
    padding: 2.5rem 2rem;
    border-radius: 4px;

    &.xs-max  {
      padding: 18px 12px;
    }

    hr {
      margin: 2rem auto;
      border-top: 1px solid $blueGrey500;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }
  }

  &__link {
    max-width: 470px;
    margin: 0 auto 15px;
    padding: 0 10px;
    display: flex;
    position: relative;

    p {
      font-size: 12px;
      color: #9fa8b5;
    }

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      margin-right: 10px;
      margin-top: 3px;
      background: $blueGrey500;
    }

    .title-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 10px;
      width: calc(100% - 100px);

      .share-top-heading {
        width: 100%;
      }
      .share-top-time-text {
        margin-top: -5px;
      }
    }

    .menu-icon {
      position: absolute;
      top: 12px;
      right: 18px;
    }
  }

  &__card {
    max-width: 470px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 4px 0 $blueGrey500;
    position: relative;
    border-radius: 2px;
    margin-bottom: 50px;

    img {
      max-width: 100%;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }

  &__actions {
    max-width: 470px;
    margin: 0 auto;
  }

  &__bottom {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    color: #c1c6cf;
    width:364px;
    max-width: calc(100% - 30px);
    margin: -30px auto auto;
    .share-bottom__group {
      display:inline-flex;
      width: 100%;
      &:nth-child(1) {
        justify-content:flex-start;
      }
      &:nth-child(2) {
        justify-content:center;
      }
      &:nth-child(3) {
        justify-content:flex-end;
      }
    }
  }
  .share-bottom-text {
    // #5e5e5e is from Facebook UI
    color: #5e5e5e;
    line-height: 1;
    /*margin-right: 70px;*/
  }
  .share-bottom-icon {
    // #5e5e5e is from Facebook UI
    color: #5e5e5e;
    height: 18px;
    margin-right: 10px;
    &.xs-max {
      margin-right:6px;
    }
    &:first-child {
      //margin-left: 60px;
    }
  }
  &__content {
    padding: 1rem 2rem;

    .campaign-edit-share__content-title {
      font-size: 18px;
      font-family: -apple-system, sans-serif;
    }
  }

  .social-media-channels {
    border-radius: 4px;
    padding: 2rem 3rem;
    border: 1px solid $blueGrey500;
    &.xs-max {
      padding: 18px 12px;
    }
  }
  .toggle-fields {
    cursor: pointer;
    display: block;
    text-decoration: underline;
    margin-top: 1rem;
  }
  .hint-text {
    color: $blueGrey700;
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 13px;
  }

  .ar-field .ar-field__label {
    margin-bottom: 5px;
  }

  label {
    img {
      vertical-align: text-bottom;
      margin-right: 12px;
    }
  }
  .locked {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EAF9F0;
  }
  .button.is-primary.is-paid {
    color: white;
    background-color: $green500;
    margin-left: 200px;
  }
}
</style>
