<template>
  <div class="message-sender-select">
    <am2-message-sender-modal
      v-if="!emitEdit"
      :is-show="showMessageSenderModal"
      :message-sender="messageSenderForModal"
      :mode="creatingNewSender ? 'create' : 'edit'"
      @create="handleSenderCreate"
      @delete="handleSenderDelete"
      @update="showMessageSenderModal = false"
      @cancel="handleMessageSenderClose"
    />
    <ar-select
      ref="select"
      :items="selectItems"
      :default-select-index="selectedMessageSenderIndex"
      placeholder="Select sender..."
      show-add-new
      :style="{
        height: '100%',
      }"
      @select="handleSelect"
      @actionClick="handleActionClick"
      @close="() => { $emit('close') }"
    >

      <template
        slot="selected-item"
        slot-scope="selectedItemScope"
      >
        <div
          v-if="selectedItemScope.item"
          :style="{
            display: 'flex',
            alignItems: 'center',
            height: '55px',
            padding: '0 15px',
            cursor: 'pointer',
            borderBottom: `1px solid ${$arStyle.color.blueGrey500}`,
          }"
          :data-test-id="`message-list-sender-${selectedItemScope.item.additionalInfo.senderName}`"
        >
          <ar-text
            size="xs"
            :text="selectedItemScope.item.additionalInfo.senderName"
            :style="{
              width: 'auto',
              color: $arStyle.color.blueGrey800,
            }"
            weight="normal"
          />
          <ar-text
            size="xs"
            :text="`(${selectedItemScope.item.property})`"
            :style="{
              width: 'auto',
              color: $arStyle.color.blueGrey700,
              marginLeft: '8px',
            }"
            weight="normal"
          />
        </div>
        <div
          v-else
          :style="{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 14px',
          }"
        >
          <ar-text
            size="xs"
            :text="creatingNewSender ? 'Creating new sender' : 'Select sender...'"
            :style="{
              color: creatingNewSender ? $arStyle.color.blueGrey800 : $arStyle.color.blueGrey600,
            }"
          />
        </div>
      </template>

      <template
        slot="item"
        slot-scope="itemScope"
      >
        <!-- Action Item -->
        <div
          v-if="itemScope.item.isAction"
          :style="{
            display: 'flex',
            alignItems: 'center',
            height: '55px',
            padding: '0 15px',
            cursor: 'pointer',
            borderBottom: `1px solid ${$arStyle.color.blueGrey500}`,
            background: itemScope.active ? $arStyle.color.purple100 : null,
          }"
        >
          <ar-icon
            class="plus-icon"
            name="circle-plus"
            :color="$arStyle.color.purple500"
            height="16px"
            width="16px"
          />
          <ar-text
            size="xs"
            :text="itemScope.item.name"
            :style="{
              width: 'auto',
              color: $arStyle.color.purple500,
              marginLeft: '8px',
            }"
            weight="bold"
          />
        </div>
        <!-- Normal Item -->
        <div
          v-else
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '55px',
            padding: '0 15px',
            cursor: 'pointer',
            borderBottom: `1px solid ${$arStyle.color.blueGrey500}`,
            background: itemScope.active ? $arStyle.color.purple100 : null,
          }"
        >
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              flexGrow: '1',
              overflow: 'hidden',
              justifyContent: 'flex-start',
            }"
          >
            <ar-text
              size="xs"
              :text="itemScope.item.additionalInfo.senderName"
              :style="{
                width: 'auto',
                color: itemScope.active ? $arStyle.color.purple500 : $arStyle.color.blueGrey800,
              }"
              :weight="itemScope.selected ? 'bold' : 'normal'"
            />
            <div
              :style="{
                overflow:'hidden',
                paddingBottom: '2px',
              }"
            >
              <ar-text
                size="xs"
                :text="`(${itemScope.item.property})`"
                :style="{
                  width: 'auto',
                  color: $arStyle.color.blueGrey700,
                  marginLeft: '8px',
                }"
                :weight="itemScope.selected ? 'bold' : 'normal'"
              />
            </div>
          </div>
          <ar-link-button
            text="edit"
            @click="editButtonPressed(itemScope.item)"
          />
        </div>
      </template>
    </ar-select>
  </div>
</template>

<script>
  /*
   * Shows a list of Email senders or FB Pages, based on which channel is provided
   * Used to tell the parent what the source of a message should be.
   */
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'MessageSenderSelect',
    $_veeValidate: {
      // fetch the current value from the innerValue defined in the component data.
      value () {
        return this.selectedSenderOid;
      }
    },

    props: {
      value: { // Value equals to message-list-oid
        type: [ String, Number ],
        default: null,
      },
      emitEdit: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        selectedSenderOid: null,
        showMessageSenderModal: false,
        creatingNewSender: false,
        messageSenderForModal: null,
      };
    },

    watch: {
      value: {
        handler(val) {
          this.selectedSenderOid = val;
        },
        immediate: true,
      },
    },

    computed: {
      ...mapState({
        messageSenders: state => state.messageSender.messageSenders,
      }),

      selectItems() {
        return [
          ...this.messageSenders,
          {
            isAction: true,
            name: 'Add new email',
            key: 'add_new_email',
          },
        ];
      },

      selectedMessageSenderIndex() {
        return this.messageSenders.findIndex( sender => sender.oid === this.selectedSenderOid);
      },
    },

    mounted() {
      this['messageSender/FETCH_MESSAGE_SENDERS']({
        type: ['email'],
      });
    },

    methods: {
      ...mapActions(['messageSender/FETCH_MESSAGE_SENDERS']),

      // For external uses, don't delete it
      openDropdown() {
        this.$refs.select.toggleDropdown(true);
      },

      editButtonPressed(item) {
        this.$refs.select.toggleDropdown(false);
        if (this.emitEdit) {
          this.$emit('editClick', item);
        } else {
          this.messageSenderForModal = item;
          this.showMessageSenderModal = true;
        }
      },

      handleSelect(item) {
        this.selectedSenderOid = item.oid;
        this.$emit('select', item);
      },

      handleActionClick(action) {
        if (action.key === 'add_new_email') {
          this.creatingNewSender = true;
          this.messageSenderForModal = null;
          if (this.emitEdit) {
            this.$emit('createClick');
          } else {
            this.showMessageSenderModal = true;
          }
        }
      },

      handleMessageSenderClose() {
        this.messageSenderForModal = null;
        this.showMessageSenderModal = false;
        this.creatingNewSender = false;
      },

      handleSenderCreate(data) {
        this.showMessageSenderModal = false;
        this.selectedSenderOid = data.oid;
        this.$emit('select', data);
      },

      handleSenderDelete() {
        this.showMessageSenderModal = false
        this.$emit('clear');
      },
    }
  };
</script>

<style lang="scss" scoped>
.message-sender-select {
  height: 50px;
}
</style>
