<template>
  <EditableFrame
    class="unique-block-wrapper"
    title="Edit Action"
    :subtitle="item.name"
  >
    <ar-text
      class="unique-block-heading"
      size="13px"
      text="UNIQUE ACTION"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="unique-block-copy-wrapper">
      <ar-text
      class="unique-block-copy"
      size="13px"
      text="When added to an automation, a unique block ensures that 
            fans will only pass though the steps beneath the block once. 
            Should the fan meet the trigger criteria again, they will 
            not pass through this branch of the automation a second time."
      weight="normal"
      multiple-lines
      letter-spacing="1px"
    />
    </div>
    </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
export default {
  name: 'Unique',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.unique-block-wrapper {
  .unique-block-copy-wrapper {
    .unique-block-copy {
      margin-top: 14px;
    }
  }
}
</style>