var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"admin-message-tasks"},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreData),expression:"fetchMoreData"}],staticClass:"u-margin-top-6",style:({
      width: '100%',
    }),attrs:{"infinite-scroll-disabled":"disableFetchMoreMessages","infinite-scroll-distance":10}},[_c('am2-top',{class:[
        'top-wrapper',
        'u-margin-bottom-8',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"dropdown":_vm.messageTypeDropdown,"search":{
        action: _vm.serverSideSearch,
        placeholder: 'Search Task Oid or Promoter Oid',
        value: _vm.searchString,
        style: {
          width: _vm.searchBoxWidth
        }
      },"title":"Message Tasks Admin Panel"}}),_vm._v(" "),_c('am2-table',{staticClass:"message-table",attrs:{"heads":_vm.tableHeadings,"rows":_vm.rows,"loading":_vm.adminState.isFetchingFanMessageTasks,"has-sticky-header":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell",style:({
          padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
        })},[_c('div',{staticClass:"type-container"},[_c('ar-icon',{staticClass:"icon",attrs:{"name":_vm.getIcon(data.provider).name,"color":_vm.getIcon(data.provider).color,"width":"19px"}})],1),_vm._v(" "),_c('div',{staticClass:"body-container"},[(data.provider === 'email')?_c('ar-text',{class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":data.meta.messageBody.subject,"weight":"bold"}}):_c('ar-text',{class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":data.meta.messageBody,"weight":"bold"}}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
            }),expression:"{\n              content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null\n            }",modifiers:{"top":true}}],staticClass:"subtitle",attrs:{"size":"xxxs","text":data.lastActionTimeText}})],1),_vm._v(" "),_c('div',{staticClass:"status-action-container"},[_c('div',{staticClass:"status"},[(data.uiStatus === 'in-progress' && data.statusDetails)?_c('div',{staticClass:"progress"},[_vm._v("\n              "+_vm._s(_vm.sentPercentage(data.statusDetails))+"%\n              "),_c('am2-gauge',{staticClass:"gauge",attrs:{"fill-color":"#7344c0","value":_vm.numSent(data.statusDetails),"max":data.statusDetails.totalMessages}})],1):_vm._e(),_vm._v(" "),_c('div',[_c('am2-tag',{style:({
              border: '1px solid white'
            }),attrs:{"text":_vm.generateStatusCopy(data.status),"type":_vm.statusToTagColor(data.status)}})],1)]),_vm._v(" "),(_vm.messageOptions(data).length > 0)?_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
              name: 'menu',
            },"items":_vm.messageOptions(data)},on:{"select":function (item) { return _vm.handleMessageOptionSelect(item.eventName, data); }}}):_vm._e()],1)])}},{key:"promoter",fn:function(ref){
            var data = ref.data;
return _c('div',{staticClass:"promoter",style:({
          padding: '12px',
        })},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: ((data.promoterAccount.name) + " (" + (data.promoterAccount.promoterOid) + ")<br/>" + (data.promoterAccount.emailAddress)),
            }),expression:"{\n              content: `${data.promoterAccount.name} (${data.promoterAccount.promoterOid})<br/>${data.promoterAccount.emailAddress}`,\n            }",modifiers:{"top":true}}],staticClass:"stats-container"},[_c('ar-text',{staticClass:"u-margin-bottom-2",attrs:{"size":"xs","text":((data.promoterAccount.name) + " (" + (data.promoterAccount.promoterOid) + ")"),"weight":"normal"}}),_vm._v(" "),_c('ar-text',{style:({
              color: _vm.$arStyle.color.skyBlueGrey700,
            }),attrs:{"size":"xxs","text":("" + (data.promoterAccount.emailAddress)),"weight":"normal"}})],1)])}},{key:"task-oid",fn:function(ref){
            var data = ref.data;
return _c('div',{staticClass:"task-oid",style:({
          padding: '12px',
        })},[_c('div',{staticClass:"stats-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.oid,"weight":"normal"}})],1)])}},{key:"stats",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"stats",style:({
          padding: '12px',
        })},[(data.status === 'completed' || data.status === 'in-progress' || data.status === 'failed')?_c('div',{staticClass:"stats-container"},[(data.statusDetails)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: ("Total:  " + (data.statusDetails.totalMessages) + "<br/>\n                        Sent:  " + (_vm.numSent(data.statusDetails)) + "<br/>\n                        Delivered:  " + (data.statusDetails.delivered || 0) + "<br/>\n                        Sending:  " + (data.statusDetails.inProgress || 0) + "<br/>\n                        Opened:  " + (data.statusDetails.opened || 0) + "<br/>\n                        Clicks:  " + (data.statusDetails.clicked || 0) + "<br/>\n                        Failed:  " + (data.statusDetails.failed || 0) + "<br/>\n                        Bounced:  " + (data.statusDetails.bounced || 0) + "<br/>\n                        Deferred:  " + (data.statusDetails.deferred || 0) + "<br/>\n                        Spam:  " + (data.statusDetails.markedSpam || 0) + "<br/>\n                        Unsubscribed:  " + (data.statusDetails.unsubscribed || 0) + "<br/>"),
              classes: 'align-left',
            }),expression:"{\n              content: `Total:  ${data.statusDetails.totalMessages}<br/>\n                        Sent:  ${numSent(data.statusDetails)}<br/>\n                        Delivered:  ${data.statusDetails.delivered || 0}<br/>\n                        Sending:  ${data.statusDetails.inProgress || 0}<br/>\n                        Opened:  ${data.statusDetails.opened || 0}<br/>\n                        Clicks:  ${data.statusDetails.clicked || 0}<br/>\n                        Failed:  ${data.statusDetails.failed || 0}<br/>\n                        Bounced:  ${data.statusDetails.bounced || 0}<br/>\n                        Deferred:  ${data.statusDetails.deferred || 0}<br/>\n                        Spam:  ${data.statusDetails.markedSpam || 0}<br/>\n                        Unsubscribed:  ${data.statusDetails.unsubscribed || 0}<br/>`,\n              classes: 'align-left',\n            }",modifiers:{"top":true}}]},[_c('div',{staticClass:"stat"},[_c('ar-text',{attrs:{"size":"xxs","allow-html":"","text":"Sent:","weight":"normal"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: _vm.numSent(data.statusDetails) ? _vm.$arStyle.color.green500 : null,
                }),attrs:{"size":"xxs","allow-html":"","text":("" + (_vm.numSent(data.statusDetails) || '-')),"weight":"bold"}})],1),_vm._v(" "),(data.statusDetails && data.statusDetails.failed !== null)?_c('div',{staticClass:"stat"},[_c('ar-text',{attrs:{"size":"xxs","allow-html":"","text":"Failed:","weight":"normal"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: data.statusDetails.failed ? _vm.$arStyle.color.red500 : null,
                }),attrs:{"size":"xxs","allow-html":"","text":("" + (data.statusDetails.failed || '-')),"weight":"bold"}})],1):_vm._e(),_vm._v(" "),(data.statusDetails && data.statusDetails.inProgress !== null)?_c('div',{staticClass:"stat"},[_c('ar-text',{attrs:{"size":"xxs","allow-html":"","text":"Sending:","weight":"normal"}}),_vm._v(" "),_c('ar-text',{style:({
                  color: data.statusDetails.inProgress ? _vm.$arStyle.color.purple500 : null,
                }),attrs:{"size":"xxs","allow-html":"","text":("" + (data.statusDetails.inProgress || '-')),"weight":"bold"}})],1):_vm._e()]):_vm._e()]):_vm._e()])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }