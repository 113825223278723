var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreEvents),expression:"loadMoreEvents"}],staticClass:"customer-profile-events",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":10}},[_c('div',{class:[
    'events-controls',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
  ]},[_c('am2-heading',{staticClass:"section-heading",attrs:{"type":"h1","size":"md","title":"Events"}}),_vm._v(" "),_c('div',{class:[
      'events-controls__rhs',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
    ]},[_c('am2-search',{staticClass:"search-input u-margin-right-4",attrs:{"placeholder":"Search events"},on:{"enter":_vm.serverSideSearch,"clear":_vm.serverSideSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('md'))?_c('am2-button-group-tabs',{style:({
          width: '100px',
        }),attrs:{"active-index":_vm.layout === 'list' ? 1 : 0,"items":_vm.groupButtonsSelectItems},on:{"select":_vm.handleGroupButtonSelectClick}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
      show: !_vm.isCustomerProfileFeatureEnabled,
      showUpgradeOn: 'hover',
      iconName: 'audience',
      title: _vm.lockedTitle.events,
      message: 'Unlock customer profiles to see previous event attendance for each fan.',
    }),expression:"{\n      show: !isCustomerProfileFeatureEnabled,\n      showUpgradeOn: 'hover',\n      iconName: 'audience',\n      title: lockedTitle.events,\n      message: 'Unlock customer profiles to see previous event attendance for each fan.',\n    }"}],class:[
      _vm.computedLayout,
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],style:({
      borderRadius: !_vm.isCustomerProfileFeatureEnabled ? '5px' : null,
      overflow: !_vm.isCustomerProfileFeatureEnabled ? 'hidden' : null,
    })},[_c('div',{staticClass:"events-container"},[_c('div',{staticClass:"customer-event-card-wrapper",class:[
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ],style:({
          filter: _vm.isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
          padding: _vm.isCustomerProfileFeatureEnabled ? 0 : '20px 20px 6px 20px',
        })},[_vm._l((_vm.computedEvents),function(event){return _c('am2-event-card',{key:event.oid,staticClass:"customer-event-card",attrs:{"event":event,"show-as":_vm.computedLayout,"layout":"customer","integration-is-possible":false,"stats-tooltip":((_vm.customerState.customer ? _vm.customerState.customer.name : 'Customer') + " has purchased " + (event.ticketStats.totalTicketsSoldFormatted) + " " + (_vm.getEventTotalTicketSold(event) > 1 ? 'tickets' : 'ticket') + " for " + (event.ticketStats.totalTicketSalesFormatted)),"on-ticket-sales-delete":function () {}}})}),_vm._v(" "),(_vm.customer && !_vm.isFetchingEvents && _vm.computedEvents.length < 1)?_c('div',{staticClass:"ar-transition-group-item u-width-100-percent"},[(_vm.searchQuery)?_c('am2-no-content-section',{attrs:{"header":"No events found matching your criteria","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"header":((_vm.customerState.customer ? _vm.customerState.customer.name : 'Customer') + " has not attended any events"),"header-size":"sm"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.isFetchingEvents ? 3 : 0),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card",style:({
            height: _vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.layout === 'tiles' ? '370px' : '163px',
            marginBottom: '10px',
          }),attrs:{"type":"campaign","show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.layout : 'tiles',"level":_vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.layout === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }