var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-sender-select"},[(!_vm.emitEdit)?_c('am2-message-sender-modal',{attrs:{"is-show":_vm.showMessageSenderModal,"message-sender":_vm.messageSenderForModal,"mode":_vm.creatingNewSender ? 'create' : 'edit'},on:{"create":_vm.handleSenderCreate,"delete":_vm.handleSenderDelete,"update":function($event){_vm.showMessageSenderModal = false},"cancel":_vm.handleMessageSenderClose}}):_vm._e(),_vm._v(" "),_c('ar-select',{ref:"select",style:({
      height: '100%',
    }),attrs:{"items":_vm.selectItems,"default-select-index":_vm.selectedMessageSenderIndex,"placeholder":"Select sender...","show-add-new":""},on:{"select":_vm.handleSelect,"actionClick":_vm.handleActionClick,"close":function () { _vm.$emit('close') }},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          borderBottom: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
        }),attrs:{"data-test-id":("message-list-sender-" + (selectedItemScope.item.additionalInfo.senderName))}},[_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":selectedItemScope.item.additionalInfo.senderName,"weight":"normal"}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.blueGrey700,
            marginLeft: '8px',
          }),attrs:{"size":"xs","text":("(" + (selectedItemScope.item.property) + ")"),"weight":"normal"}})],1):_c('div',{style:({
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        })},[_c('ar-text',{style:({
            color: _vm.creatingNewSender ? _vm.$arStyle.color.blueGrey800 : _vm.$arStyle.color.blueGrey600,
          }),attrs:{"size":"xs","text":_vm.creatingNewSender ? 'Creating new sender' : 'Select sender...'}})],1)]}},{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          borderBottom: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
          background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
        })},[_c('ar-icon',{staticClass:"plus-icon",attrs:{"name":"circle-plus","color":_vm.$arStyle.color.purple500,"height":"16px","width":"16px"}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.purple500,
            marginLeft: '8px',
          }),attrs:{"size":"xs","text":itemScope.item.name,"weight":"bold"}})],1):_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          borderBottom: ("1px solid " + (_vm.$arStyle.color.blueGrey500)),
          background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
        })},[_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
            flexGrow: '1',
            overflow: 'hidden',
            justifyContent: 'flex-start',
          })},[_c('ar-text',{style:({
              width: 'auto',
              color: itemScope.active ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.blueGrey800,
            }),attrs:{"size":"xs","text":itemScope.item.additionalInfo.senderName,"weight":itemScope.selected ? 'bold' : 'normal'}}),_vm._v(" "),_c('div',{style:({
              overflow:'hidden',
              paddingBottom: '2px',
            })},[_c('ar-text',{style:({
                width: 'auto',
                color: _vm.$arStyle.color.blueGrey700,
                marginLeft: '8px',
              }),attrs:{"size":"xs","text":("(" + (itemScope.item.property) + ")"),"weight":itemScope.selected ? 'bold' : 'normal'}})],1)],1),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"edit"},on:{"click":function($event){return _vm.editButtonPressed(itemScope.item)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }