var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"custom-field-modal",attrs:{"is-open":_vm.isShow,"header":_vm.title,"width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{class:[
        'modal-content',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[_c('ar-field',{attrs:{"label":"Custom field label","error-messages":[_vm.veeErrors.first('custom-field-label')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"autocomplete":"custom-field-label","data-vv-name":"custom-field-label","data-vv-as":"custom field label","placeholder":"Custom field label","type":"text","has-error":_vm.veeErrors.has('custom-field-label'),"value":_vm.fieldInputLabel},on:{"input":_vm.handleFieldLabelInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Field type","error-messages":[_vm.veeErrors.first('field-type')]}},[_c('am2-field-type-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"selector",attrs:{"placeholder":"Select field type...","data-vv-name":"field-type","data-vv-as":"field type","value":_vm.fieldType},on:{"input":_vm.handleFieldTypeInput}})],1),_vm._v(" "),(_vm.enableOptions)?_c('div',[_c('ar-field',{staticClass:"u-margin-top-6",attrs:{"label":"Options"}}),_vm._v(" "),_c('draggable',{staticClass:"list-group",attrs:{"tag":"div","list":_vm.options,"handle":".handle"},on:{"change":_vm.handleMove}},_vm._l((_vm.options),function(element,idx){return _c('div',{key:element.name},[_c('ar-field',{attrs:{"error-messages":[_vm.veeErrors.first(("option-field-label-" + idx))]}},[_c('div',{staticClass:"list-group-item"},[_c('ar-icon',{class:[
                  'u-margin-right-3',
                  'handle' ],attrs:{"name":"menu","color":_vm.$arStyle.color.skyBlueGrey600}}),_vm._v(" "),_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"value":element.name,"autocomplete":("option-field-label-" + idx),"data-vv-name":("option-field-label-" + idx),"data-vv-as":"text in this option","type":"text"},on:{"input":function($event){
                var i = arguments.length, argsArray = Array(i);
                while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleInputOption.apply(void 0, [ idx ].concat( argsArray ))},"blur":_vm.handleBlurOption}})],1)])],1)}),0),_vm._v(" "),_c('ar-link-button',{class:[
              'u-margin-left-4',
              'u-margin-top-2' ],attrs:{"text":"+ Add option"},on:{"click":_vm.handleAddOptionClick}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
      'footer',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '160px' }),attrs:{"text":"Add custom field"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }