var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-list__wrapper"},[_vm._l((_vm.events),function(event){return _c('div',{key:event.oid,class:[
        'event-list__item-wrapper',
        _vm.$arMediaQuery.pageContent.minWidth('sm') && 'u-padding-y-4 u-padding-x-6' ],on:{"click":function($event){return _vm.handleItemClick(event)}}},[_c('div',{class:[
          'event-list__item-image',
          _vm.$arMediaQuery.pageContent.minWidth('sm') && 'u-margin-right-5' ]},[_c('img',{attrs:{"src":_vm.eventImage(event)}})]),_vm._v(" "),_c('div',{class:[
        'event-list__info-wrapper' ]},[_c('p',{staticClass:"event-list__item-name"},[_vm._v(_vm._s(event.name))]),_vm._v(" "),_c('p',{staticClass:"event-list__item-date"},[_vm._v(_vm._s(_vm.dateRange(event)))])]),_vm._v(" "),_c('div',{class:[
          'event-list__right-button',
          _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[(event.ticked)?_c('ar-icon',{attrs:{"name":"notification-success","width":"20px","height":"20px","color":_vm.$arStyle.color.green500}}):_c('div',{staticClass:"event-list__button"})],1)])}),_vm._v(" "),(!_vm.events || _vm.events.length <= 0)?_c('p',{staticClass:"event-list__hint"},[_vm._v("No events found.")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }