<template>
  <PlanFrame
    title="Let’s finish powering you up!"
    description="Choose your contact list size"
    has-back-arrow
  >
    <div class="plan-subscribe-page">
      <PlanPurchaseCard
        :payment-plan-oid="paymentPlanOid"
        class="plan-card"
      />
    </div>
  </PlanFrame>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PlanPurchaseCard from './components/plan-purchase-card';
import PlanFrame from '../../components/plan-frame';

export default {
  name: 'PaymentPlans',

  components: {
    PlanPurchaseCard,
    PlanFrame,
  },
  layout: 'mono',

  computed: {
    ...mapState({
      paymentPlanOid: state => Number.parseInt(state.route.params.oid, 10),
    }),
  },

  beforeRouteEnter(to, from, next) {
    // If you came from non-plan page, we save the state of previous page, so we can back to there after
    if (from && from.path.indexOf('/plans') !== 0) {
      window.sessionStorage.setItem('_ar_plan_exit_route_', JSON.stringify({
        path: from.path,
        params: from.params,
      }));
    }

    next();
  },

};
</script>

<style lang="scss" scoped>
.plan-subscribe-page {
  display: flex;
  justify-content: center;
  width: 100%;
  .plan-card {
    width: 100%;
    max-width: 380px;
  }
}
</style>
