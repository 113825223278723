<template>
  <section class="event-details">
    <ar-field
      label="Event name"
      :error-messages="[veeErrors.first('event-name')]"
    >
      <ar-input
        v-validate="'required|max:150'"
        :value="scratchEvent ? scratchEvent.name : ``"
        @input="handleEventNameInput"
        autocomplete="event-name"
        data-vv-name="event-name"
        data-vv-as="event name"
        placeholder="Event name"
        type="text"
        data-test-id="event-name"
      />
    </ar-field>
    <!-- If it's a Zoom Event, don't display location tabs -->
    <ar-field
      v-if="scratchEventIsZoomEvent"
      label="Location"
      class="u-margin-top-10"
    >
      <am2-new-slug-input
        :value="zoomUrlFromCurrentEvent"
        readonly
        enable-copy
      />
    </ar-field>
    <div
      v-else
    >
      <ar-field
        label="Location"
        class="u-margin-top-10"
        :error-messages="[
          veeErrors.first('live-stream-url'),
          veeErrors.first('location'),
        ]"
      >
        <am2-elegant-tabs
          :items="locationTabs"
          :tab-key="selectedLocationKey"
          @select="handleLocationSelect"
          layout="wide"
          data-test-id="location-tabs"
        />
        <ar-input
          v-if="selectedLocationKey === 'physical'"
          class="u-margin-top-2"
          :value="scratchEvent ? scratchEvent.location : ``"
          @input="handleEventLocationInput"
          autocomplete="event-location"
          v-validate="'required'"
          data-vv-name="location"
          data-vv-as="location"
          placeholder="Include a venue, place or address"
          data-test-id="event-location"
        />
        <ar-input
          v-if="selectedLocationKey === 'online' && !isEventRSVPEnabled && !currentEventRSVPForm"
          class="u-margin-top-2"
          v-validate="'required|url'"
          :value="scratchEvent.presentation.liveStreamUrl"
          @input="handleEventLiveStreamUrlInput"
          autocomplete="url"
          data-vv-name="live-stream-url"
          data-vv-as="live stream URL"
          placeholder="Enter live stream URL"
        />
      </ar-field>
      <ar-field
        v-if="selectedLocationKey === 'physical'"
        label="Event capacity"
        :error-messages="[veeErrors.first('event-capacity')]"
        class="u-margin-top-10"
      >
        <ar-input
          v-validate="'required|integer|isPositiveInt'"
          :value="scratchEvent ? scratchEvent.capacity : ``"
          @input="handleEventCapacityInput"
          autocomplete="event-capacity"
          data-vv-name="event-capacity"
          data-vv-as="event capacity"
          placeholder="Enter event capacity"
          type="number"
          data-test-id="event-capacity"
        />
      </ar-field>
    </div>
    <div
      :class="[
        'u-display-flex',
        'u-margin-top-10',
        $arMediaQuery.window.maxWidth('md') && 'u-flex-flow-column',
        'u-justify-content-space-between'
      ]"
    >
      <ar-field
        label="Event starts"
        :error-messages="[
          veeErrors.first('startDate'),
          veeErrors.first('timezone'),
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            'u-align-items-center',
          ]"
        >
          <am2-date-time-input
            v-validate="startDateValidate"
            :time-zone="scratchEvent ? scratchEvent.timeZone : null"
            :value="scratchEvent? scratchEvent.startDate : null"
            @input="handleEventStartDateInput"
            :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
            data-vv-name="startDate"
            data-vv-as="event start time"
            data-test-id="event-start-time"
          />
          <ar-link-button
            v-if="!displayEndTime && !$arMediaQuery.window.maxWidth('xs')"
            class="u-margin-left-3"
            text="Add end time"
            @click="handleAddEndTimeClick"
            data-test-id="add-event-end-time-link"
          />
        </div>
        <div class="u-margin-top-2 u-display-flex u-flex-flow-column" :style="{ marginBottom: '5px' }">
          <am2-timezone-dropdown
            v-validate="'required'"
            data-vv-name="timezone"
            :value="scratchEvent ? scratchEvent.timeZone : null"
            @input="handleEventTimeZoneInput"
            data-test-id="event-timezone-link"
          />
          <ar-link-button
            v-if="!displayEndTime && $arMediaQuery.window.maxWidth('xs')"
            class="u-margin-top-2"
            text="Add end time"
            @click="handleAddEndTimeClick"
            data-test-id="add-event-end-time-link"
          />
        </div>
      </ar-field>
      <!-- Divider -->
      <ar-divider
        v-if="$arMediaQuery.window.minWidth('lg') && displayEndTime"
        :style="{
          position: 'relative',
          top: '57px',
          width: 'calc(100% - 652px)',
          margin: '0 18px',
        }"
      />
      <ar-field
        v-if="displayEndTime"
        label="Event ends"
        :error-messages="[veeErrors.first('endDate')]"
        :class="[
          'u-display-flex',
          'u-flex-flow-column',
          $arMediaQuery.window.maxWidth('md') ? 'u-margin-top-10' : null,
        ]"
      >
        <am2-date-time-input
          v-validate="endDateValidate"
          :time-zone="scratchEvent ? scratchEvent.timeZone : null"
          :value="scratchEvent ? scratchEvent.endDate : null"
          :disabled-until="scratchEvent.startDate"
          @input="handleEventEndDateInput"
          :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
          data-vv-name="endDate"
          data-vv-as="event end time"
          data-test-id="event-end-time"
        />
        <ar-link-button
          text="Remove end time"
          @click="handleRemoveEndTimeClick"
          :style="{
            marginTop: '8px',
          }"
          data-test-id="remove-event-end-time-link"
        />
      </ar-field>
    </div>
    <ar-field
      :label="currentEventRSVPForm ? 'Event image' : 'Event image (optional)'"
      class="u-margin-top-10"
      :error-messages="[
        veeErrors.first('eventImage'),
        veeErrors.first('eventImageUploading')
      ]"
    >
      <div
        :style="{
          height: (scratchEventImageUrl || isUploadingEventImage) ? null : '0',
          overflow: 'hidden',
        }"
      >
        <div
          v-if="isUploadingEventImage"
          v-validate:isUploadingEventImage="'uploadingAssets'"
          data-vv-name="eventImageUploading"
          data-vv-as="event image"
        />
        <!-- Event image is necessary if it has RSVP Event -->
        <div
          v-if="currentEventRSVPForm"
          v-validate:scratchEventImageUrl="'required'"
          data-vv-name="eventImage"
          data-vv-as="event image"
        />
        <am2-dropzone
          ref="img-dropzone"
          :file-type="['image/jpeg', 'image/jpg', 'image/png']"
          file-type-alias="image"
          :placeholder-icon="{
            name: 'image',
          }"
          :image-options="{
            dimensions: {
              width: 1200,
              height: 628
            }
          }"
          :ratio="0.525"
          feedback-type="image"
          :feedback="scratchEventImageUrl"
          enable-ar-s3-bucket-upload
          :ar-s3-bucket-upload-options-list="arS3BucketUploadOptionsList"
          @delete="handleImageDelete"
          @uploadStart="isUploadingEventImage = true"
          @uploadEnd="isUploadingEventImage = false"
          @upload="handleImageUpload"
        />
      </div>
      <div
        v-if="!scratchEventImageUrl && !isUploadingEventImage"
        class="u-display-flex"
      >
        <ar-icon
          class="u-margin-right-2"
          name="image"
          height="21px"
          :color="$arStyle.color.blueGrey600"
        />
        <ar-link-button
          text="Upload image"
          @click="handleUploadImageClick"
          data-test-id="upload-image-link"
        />
      </div>
    </ar-field>
  </section>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'EventDetails',
  props: {
    updateSelectedLocation: {
      type: Function,
      default: null,
    },
    selectedLocationKey: {
      type: String,
      default: "physical",
    },
  },
  data() {
    return {
      displayEndTime: false,
      reasonHtmlCopy: 'Event detail updates that you have made on Audience Republic <u><b>will not</b></u> update the event details on Eventbrite. Eventbrite will need to be updated separately.',
      dontShowAgain: false,
      isUploadingEventImage: false,
      isEventRSVPEnabled: process.env.arEnableEventRSVP,
    };
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      connectZoomProviderInfo: state => state.event.connectZoomProviderInfo,
      scratchEvent: state => state.event.scratchEvent,
      isFetchingCurrentEventRSVPForm: state => state.eventRSVPForm.isFetchingCurrentEventRSVPForm,
      currentEventRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
    }),
    ...mapGetters({
      scratchEventImageUrl: 'event/scratchEventImageUrl',
      scratchEventIsZoomEvent: 'event/scratchEventIsZoomEvent',
      zoomUrlFromCurrentEvent: 'event/zoomUrlFromCurrentEvent',
    }),
    startDateValidate() {
      let timeMustBeInFuture = false;
      // Make sure the event time is in the future if
      // 1. You're intending to connect the event with Zoom
      if (!!this.connectZoomProviderInfo) {
        timeMustBeInFuture = true;
      }
      const validate = {
        required: true,
        tzFormat: true,
        futureTime: timeMustBeInFuture,
      };
      return validate;
    },
    endDateValidate() {
      const validate = {
        tzFormat: true,
        notBeforeTime: {
          compareTime: this.scratchEvent.startDate,
          compareTimeName: 'event start time',
        },
      };
      return validate;
    },
    locationTabs() {
      return [
        {
          name: 'Physical Event',
          key: 'physical',
        },
        {
          name: 'Online Event',
          key: 'online',
        },
      ];
    },
    arS3BucketUploadOptionsList() {
      const list = [
        {
          assetType: 'event-image',
          campaignOid: null,
          eventOid: this.scratchEvent ? this.scratchEvent.oid : null,
        },
      ];
      if (this.currentEventRSVPForm) {
        // If it also has Event RSVP, let's also upload campaign image
        list.push({
          assetType: 'campaign-image',
          campaignOid: this.currentEventRSVPForm.oid,
          eventOid: null,
        });
      }
      return list;
    },
  },
  watch: {
    scratchEvent() {
      if (this.scratchEvent.endDate && !this.displayEndTime) {
        this.displayEndTime = true;
      }
    },
  },
  methods: {
    // ...mapActions([
    // ]),

    ...mapMutations([
      'event/PATCH_SCRATCH_EVENT',
      'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
    ]),
    deleteEventImage() {
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.filter(resource => resource.assetType !== 'event-image'),
      });
    },
    deleteRSVPEventImage() {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        resources: this.scratchEventRSVPForm.resources.filter(resource => resource.assetType !== 'campaign-image'),
      });
    },
    handleImageUpload({ arS3BucketResources }) {
      this.deleteEventImage();
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.concat([arS3BucketResources[0]]),
      });

      if (this.currentEventRSVPForm) {
        // It also has RSVP Event, let's also update scratch event rsvp form
        this.deleteRSVPEventImage();
        this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
          resources: this.scratchEventRSVPForm.resources.concat([arS3BucketResources[1]]),
        });
      }
    },
    handleImageDelete() {
      this.deleteEventImage();
      this.deleteRSVPEventImage();
    },
    handleEventNameInput(name) {
      this['event/PATCH_SCRATCH_EVENT']({ name });
    },
    handleEventLocationInput(location) {
      this['event/PATCH_SCRATCH_EVENT']({ location });
    },
    handleEventCapacityInput(capacity) {
      const newCapacity = capacity ? parseInt(capacity, 10) : null;
      this['event/PATCH_SCRATCH_EVENT']({ capacity: newCapacity });
    },
    handleEventStartDateInput(startDate) {
      this['event/PATCH_SCRATCH_EVENT']({ startDate });
    },
    handleEventEndDateInput(endDate) {
      this['event/PATCH_SCRATCH_EVENT']({ endDate });
    },
    handleEventTimeZoneInput(timeZone) {
      this['event/PATCH_SCRATCH_EVENT']({ timeZone });
    },
    handleEventLiveStreamUrlInput(liveStreamUrl) {
      this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl } });
    },

    handleLocationSelect(tab) {
      // this.selectedLocationKey = tab.key;
      this.updateSelectedLocation(tab.key);

      if (tab.key === 'physical') {
        this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl: null } });

        if (this.currentEventRSVPForm) {
          this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
            settings: {
              platform: null,
            },
          });
        }
      } else if (tab.key === 'online') {
        this['event/PATCH_SCRATCH_EVENT']({ location: null, capacity: null });
      }
    },

    handleAddEndTimeClick() {
      this.displayEndTime = true;
      if (this.scratchEvent.startDate && this.scratchEvent.startDate !== 'Invalid date') {
        const newEndDateFormat = moment.tz(this.scratchEvent.startDate, this.scratchEvent.timeZone)
          .add(2, 'hours')
          .utc()
          .format();
        this['event/PATCH_SCRATCH_EVENT']({
          endDate: newEndDateFormat,
        });
      }
    },

    handleRemoveEndTimeClick() {
      this.displayEndTime = false;
      this['event/PATCH_SCRATCH_EVENT']({ endDate: null });
    },

    handleUploadImageClick() {
      this.$refs['img-dropzone'].selectFile();
    },
  },
};
</script>

<style lang="scss" scoped>
.save-event-modal {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    .link-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 24px;
      padding-bottom: 10px;
    }
  }
}
</style>
