var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"email-edit-page"},[_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displaySaveCurrentEmailTemplateModal,"loading":_vm.isCreatingEmailTemplate},on:{"confirm":_vm.handleSaveCurrentEmailTemplateConfirm,"cancel":_vm.handleSaveCurrentEmailTemplateCancel}}),_vm._v(" "),_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displayCreateEmailTemplateModal,"loading":_vm.isCreatingEmailTemplate},on:{"confirm":_vm.handleCreateEmailTemplateConfirm,"cancel":_vm.handleCreateEmailTemplateCancel}}),_vm._v(" "),_c('am2-select-email-template-modal',{attrs:{"opened":_vm.displaySelectEmailTemplate},on:{"select":_vm.handleSelectEmailTemplateSelect,"cancel":_vm.handleSelectEmailTemplateCancel}}),_vm._v(" "),_c('am2-send-test-email-modal',{attrs:{"opened":_vm.displaySendTestEmailModal},on:{"cancel":_vm.handleSendTestEmailCancel}}),_vm._v(" "),_c('am2-email-preview-modal',{attrs:{"opened":_vm.displayEmailPreviewModal,"has-mode-tabs":_vm.hasModeTabsOnEmailPreviewModal,"subject":_vm.scratchSubject,"preview-text":_vm.scratchPreviewText,"scheduled-at":_vm.scratchScheduledAt,"sender-name":_vm.currentSendername,"email-html":_vm.scratchEmailWithInfo},on:{"close":_vm.handleEmailPreviewClose}}),_vm._v(" "),_c('am2-shareable-link-modal',{attrs:{"opened":_vm.displaySharablePreviewLinkModal,"messageOid":_vm.messageOid},on:{"close":_vm.handleSharablePreviewLinkModalClose}}),_vm._v(" "),_c('div',{class:[
      'header-section',
      _vm.displayRightSidePreview && 'not-visible' ]},[(_vm.displayUnlockPrompt)?_c('am2-unlock-prompt',{attrs:{"title":"Unlock Email Marketing","subtitle":"Upgrade to a paid plan now to start sending emails today!","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'},on:{"click":_vm.handleGoToPlansClick}}):_vm._e(),_vm._v(" "),_c('am2-top-create',{style:({
        position: 'relative',
      }),attrs:{"title":"Send an email","total-steps":_vm.totalStep,"current-step":_vm.currentStep,"exit-path":_vm.exitPath,"title-icon":{
        name: 'email',
        color: _vm.$arStyle.color.email,
        width: '18px',
      }}},[_c('div',{staticClass:"top-create-content",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"top-create-left-content"},[_c('am2-heading',{attrs:{"type":"h1","size":"sm","title":_vm.stepTitle,"weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
              marginTop: '11px',
              color: '#9FA8B5',
            }),attrs:{"size":"xs","text":("Step " + _vm.currentStep + " of " + _vm.totalStep)}})],1),_vm._v(" "),_c('div',{staticClass:"top-create-right-content"},[(_vm.step === 'template')?_c('am2-link-button-dropdown',{staticClass:"u-margin-right-9",attrs:{"placeholder":"Template","items":_vm.templateDropdownItems,"dropdown-style":{
              padding: '5px 0',
              width: '207px',
            },"item-style":{
              height: '40px',
              padding: '0 20px',
            },"align":"left"},on:{"select":_vm.handleTemplateItemSelect}}):_vm._e(),_vm._v(" "),(_vm.step === 'template')?_c('am2-link-button-dropdown',{attrs:{"placeholder":"Preview","items":_vm.previewDropdownItems,"dropdown-style":{
              padding: '5px 0',
              width: '207px',
            },"item-style":{
              height: '40px',
              padding: '0 20px',
            },"align":"left"},on:{"select":_vm.handlePreviewItemSelect}}):_vm._e(),_vm._v(" "),(_vm.hasImportDynamicTagsLink && _vm.step === 'template')?_c('ar-link-button',{attrs:{"text":"Import dynamic tags","data-test-id":"import-dynamic-tags-button"},on:{"click":_vm.handleImportDynamicTagsLinkClick}}):_vm._e()],1)])])],1),_vm._v(" "),_c('div',{staticClass:"body-section"},[_c('div',{class:[
        'edit-message-section',
        (_vm.hasNoSpaceForRightSidePreview || _vm.fullScreenMode) && 'full-size' ]},[_c('div',{class:[
          'edit-message-form-section',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
          _vm.fullScreenMode && 'no-padding' ]},[_c('div',{class:[
            'page-content',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
            _vm.fullScreenMode && 'full-size' ]},[_c('nuxt-child',{ref:"modify-email-page",attrs:{"message-initialization-is-complete":_vm.messageInitializationIsCompleted},on:{"saveDraftAndRedirectToSettings":_vm.handleSaveAndRedirectToSettings,"showImportDynamicTagsLink":function($event){_vm.hasImportDynamicTagsLink = true}}})],1)]),_vm._v(" "),_c('div',{class:[
          'edit-message-navigation-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[(_vm.currentStep === 1)?_c('ar-simple-button',{style:({
            width: '100px',
          }),attrs:{"outlined":"","text":"Cancel","data-test-id":"cancel-button"},on:{"click":_vm.handleCancelClick}}):(_vm.currentStep > 1)?_c('ar-simple-button',{style:({
            width: '100px',
          }),attrs:{"text":"Back","data-test-id":"back-button","outlined":""},on:{"click":_vm.handlePreviousStepClick}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"navigation-right-section"},[(_vm.isAutoSaveEnabled)?_c('ar-text',{staticClass:"u-margin-right-4",style:({
              color: _vm.$arStyle.color.blueGrey700,
            }),attrs:{"text":_vm.autoSaveCopy,"size":"14px"}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessageScheduleCountdownCopy)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.currentSelectedMessageScheduleCountdownCopy,
            }),expression:"{\n              content: currentSelectedMessageScheduleCountdownCopy,\n            }"}],staticClass:"u-margin-right-2",attrs:{"name":"notification-warning","color":_vm.$arStyle.color.purple500}}):_vm._e(),_vm._v(" "),(_vm.isNewEmailMessage)?_c('ar-link-button',{staticClass:"save-link",attrs:{"text":"Save as draft","data-test-id":"save-draft-button","disabled":_vm.isSaveAsADraftButtonDisabled},on:{"click":_vm.handleSaveAsDraftClick}}):_vm._e(),_vm._v(" "),(_vm.hasNoSpaceForRightSidePreview && !_vm.fullScreenMode)?_c('ar-simple-button',{style:({
              marginRight: '12px',
              width: '120px',
            }),attrs:{"text":_vm.rightSidePreviewIsToggledUp ? 'Edit' : 'Preview',"outlined":"","data-test-id":"mobile-preview-button","icon-name":_vm.rightSidePreviewIsToggledUp ? null : 'mobile-preview',"icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            },"icon-distance":"10px"},on:{"click":_vm.handlePreviewClick}}):_vm._e(),_vm._v(" "),(_vm.currentStep < _vm.totalStep)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              action: _vm.handleNextStepClick,
              disabled: _vm.isNextButtonDisabled,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              action: handleNextStepClick,\n              disabled: isNextButtonDisabled,\n            }"}],style:({
              width: '120px',
            }),attrs:{"text":"Next","disabled":_vm.isNextButtonDisabled,"data-test-id":"next-step-button"},on:{"click":_vm.handleNextStepClick}}):_vm._e(),_vm._v(" "),(_vm.currentStep === _vm.totalStep && _vm.isEmailFeatureEnabled)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: _vm.sendButtonDisabled,
              action: _vm.handleEmailSendClick,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: sendButtonDisabled,\n              action: handleEmailSendClick,\n            }"}],style:({
              width: '120px',
            }),attrs:{"text":_vm.sendMessageButtonCopy,"loading":_vm.isSendingOutMessage || _vm.isUpdatingEmail,"disabled":_vm.sendButtonDisabled,"data-test-id":"send-email-button"},on:{"click":_vm.handleEmailSendClick}}):_vm._e(),_vm._v(" "),(_vm.currentStep === _vm.totalStep && !_vm.isEmailFeatureEnabled)?_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'A <strong>Basic</strong> or <strong>Pro</strong> plan (with the Email Marketing Add-On) or a <strong>Plus</strong> plan is required before you can send emails.'
            }),expression:"{\n              content: 'A <strong>Basic</strong> or <strong>Pro</strong> plan (with the Email Marketing Add-On) or a <strong>Plus</strong> plan is required before you can send emails.'\n            }",modifiers:{"top":true}}],style:({
              width: '120px',
            }),attrs:{"text":_vm.isScheduledMessage ? 'Schedule' : 'Send now',"icon-name":"lock","disabled":"","outlined":"","data-test-id":"send-email-button"},on:{"click":_vm.handleGoToPlansClick}}):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{class:[
        'preview-section',
        (_vm.hasNoSpaceForRightSidePreview || _vm.fullScreenMode) && 'go-on-top-of-edit-section',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        _vm.displayRightSidePreview && 'is-visible' ]},[_c('div',{staticClass:"preview-wrapper"},[_c('div',{staticClass:"preview"},[_c('MessageMobileContainer',{attrs:{"display-paginator":_vm.displayCsvPreviewPaginator,"contacts-count":_vm.csvPreviewContacts ? _vm.csvPreviewContacts.length : 0,"contact-index":_vm.selectedCsvPreviewContactIndex},on:{"contactChange":_vm.handleCsvPreviewContactsIndexChange}},[_c('am2-email-preview',{attrs:{"opened":_vm.step !== 'basic',"sender-name":_vm.currentSendername,"subject":_vm.scratchSubject,"preview-text":_vm.scratchPreviewText,"scheduled-at":_vm.scratchScheduledAt,"email-template":_vm.scratchEmailWithInfo,"preview-contact":_vm.selectedCsvPreviewContact}})],1)],1),_vm._v(" "),(_vm.currentStep !== 1)?_c('div',{staticClass:"preview-bottom-link-section"},[(_vm.csvPreviewContacts)?_c('ar-link-button',{attrs:{"text":_vm.displayCsvPreviewContacts ? 'Hide live dynamic tags' : 'Preview dynamic tags',"data-test-id":"toogle-dynamic-tags-preview-link"},on:{"click":_vm.handleDisplayCsvPreviewContactsToggle}}):_c('ar-link-button',{attrs:{"text":"View desktop preview","data-test-id":"view-desktop-preview-button"},on:{"click":_vm.handleViewDesktopPreviewClick}})],1):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }