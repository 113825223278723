<template>
  <portal to="modal" :disabled="!isOpen" :style="{ position: 'absolute' }">
    <ar-modal
      class="edit-invitation-method-modal"
      :is-open="isOpen"
      :header="`Settings`"
      width="600px"
      height="330px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
      data-test-id="edit-invitation-method-modal"
    >
      <div slot="body" :class="['body', $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3']">
        <div class="body-container">
          <ar-field label="Button copy">
            <ar-input
              v-validate="'required|max:150'"
              autocomplete="button-copy"
              data-vv-name="button-copy"
              data-vv-as="button copy"
              placeholder="Button copy"
              type="text"
              data-test-id="button-copy-input"
              :value="buttonCopy"
              @input="handleButtonCopyChange"
            />
          </ar-field>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
      ]">
        <ar-link-button
          text="Cancel"
          style="padding: 18px 42px; margin-right: 15px"
          class="transparent-button"
          data-test-id="edit-invitation-method-modal-cancel-button"
          @click="handleClose"
        />
        <ar-simple-button
          text="Save"
          style="padding: 18px 42px"
          @click="handleSaveClick"
          data-test-id="edit-invitation-method-modal-save-button" />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  export default {
    name: 'EditInvitationMethodModal',
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      invitationMethod: {
        type: String,
        default: null,
      },
      currentButtonCopy: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        buttonCopy: null,
      }
    },
    watch: {
      isOpen(val) {
        if (!val) return;
        this.resetData();
      }
    },
    methods: {
      resetData() {
        this.buttonCopy = this.currentButtonCopy;
      },
      handleSaveClick() {
        const newData = {
          invitationMethod: this.invitationMethod,
          buttonCopy: this.buttonCopy,
        }
        this.$emit('save', newData)
      },
      handleClose() {
        this.$emit('cancel');
      },

      handleButtonCopyChange(newVal) {
        this.buttonCopy = newVal;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .edit-invitation-method-modal {
    .body {
      padding: 30px;
    }
    .footer {
      display: flex;
      height: 50px;
      box-shadow: 0 3px 10px;
      padding: 34px 8px;
      align-items: center;
      justify-content: flex-end;
      z-index: $zIndexRegular;
      position: relative;
      &.sm-min {
        padding: 50px;
      }
    }
  }
</style>
