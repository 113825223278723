var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-dropdown-binder',{attrs:{"align":_vm.align,"button-name":"ar-simple-button","button-props":_vm.buttonProps,"dropdown-props":{
    items: _vm.items,
    itemStyle: { padding: '9px 15px' },
    hasSearch: _vm.hasSearch,
    maxHeight: _vm.dropdownMaxHeight,
    style: _vm.dropdownStyle,
    placeholder: _vm.searchPlaceholder,
  }},on:{"select":_vm.handleItemSelect,"searchStringChange":_vm.handleSearchStringChange,"button-click":function($event){return _vm.$emit('button-click', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }