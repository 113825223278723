<template>
  <div class="am2-characters-left-copy">
    <ar-text
      v-if="charactersLeftInTemplate"
      size="xs"
      :class="[
        'characters-left-copy',
        noCharactersLeft && 'warning',
      ]"
      v-tooltip.top="{
        content: tooltip,
      }"
      :text="charactersString"
      align="right"
    />
  </div>
</template>

<script>
export default {
  props: {
    currentLength: {
      type: Number,
      default: 0,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    template: {
      type: String,
      default: '{charactersRemaining} characters left',
    },
  },
  computed: {
    charactersLeftInTemplate() {
      if (typeof this.maxLength !== 'number') {
        return null;
      }
      return (this.maxLength - this.currentLength).toString();
    },
    charactersString() {
      if (typeof this.maxLength !== 'number') {
        return null;
      }
      const charactersUsed = this.currentLength;
      const charactersRemaining = (this.maxLength - charactersUsed).toString();
      return this.template.replace('{charactersUsed}', charactersUsed).replace('{charactersRemaining}', charactersRemaining);
    },
    noCharactersLeft() {
      return (this.maxLength - this.currentLength) < 1;
    },
  },
}
</script>

<style lang="scss">
.am2-characters-left-copy {
  display: flex;
  justify-content: flex-end;
  .characters-left-copy {
    color: $blueGrey600;

    &.warning {
      color: $red500;
    }
  }
}
</style>
