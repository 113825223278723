var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'tag',
    _vm.type,
    _vm.shape,
    _vm.isClickable && 'action'
  ],on:{"click":_vm.handleTagClick}},[(_vm.iconName)?_c('ar-icon',_vm._b({class:['icon', _vm.iconAnimation],attrs:{"name":_vm.iconName}},'ar-icon',_vm.iconPropsDecorated,false)):_vm._e(),_vm._v(" "),_c('div',{class:[
      'text-section',
      _vm.hasCross && 'has-cross'
    ]},[_c('ar-text',{staticClass:"text",attrs:{"size":_vm.textSize,"text":_vm.text,"weight":_vm.textWeight}}),_vm._v(" "),(_vm.sideText)?_c('ar-text',{staticClass:"side-text u-margin-left-1",attrs:{"size":"xxxs","text":_vm.sideText}}):_vm._e()],1),_vm._v(" "),(_vm.hasCross)?_c('div',{staticClass:"remove-button",style:(Object.assign({}, _vm.removeButtonStyle)),on:{"click":_vm.handleCrossClick}},[_c('ar-icon',{attrs:{"name":"cross","width":"10px","stroke-width":"4","color":_vm.removeIconColor}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }