<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="reviewConditionTitle"
    :readonly-items="readonlyItems"
    :apply-disabled="!isEditConditionComplete"
    :additional-constraints="filterCriteria.constraints"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="openChangeMode"
  >
    <div slot="content" class="date-picker">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <div class="date-picker-options-wrapper">
          <ar-simple-select
            :items="selectDateTypeItems"
            :default-select-index="dateTypeSelectedIndex"
            placeholder="Select date type..."
            class="date-picker-select"
            @select="handlePredicateSelect"
            data-test-id="filter-predicate-selector"
          />
        </div>
        <div
          v-if="editFilterCondition.data.condition === 'is'"
          class="date-picker-options-wrapper"
        >
          <ar-simple-select
            :style="{ marginTop: '10px' }"
            :items="dateExpressionActions"
            :default-select-index="dateExpressionSelectedIndex"
            placeholder="Select date type..."
            class="date-picker-select"
            @select="handleDateExpressionSelect"
            data-test-id="filter-date-type-selector"
          />
        </div>
        <div
          v-else-if="editFilterCondition.data.condition === 'is_between'"
          class="date-picker-date-section with-dash"
        >
          <div class="date-picker-small-datepicker">
            <am2-datepicker
              ref="datepicker-1"
              :value="editFilterCondition.data.values[0]"
              name="datepicker"
              placeholder="Date"
              :style="{
              width: '100%',
              height: '40px',
            }"
              @input="handleDateSelect(0, ...arguments)"
              data-test-id="filter-date-input-1"
            />
          </div>
          <div class="date-picker-small-datepicker">
            <am2-datepicker
              ref="datepicker-2"
              :value="editFilterCondition.data.values[1]"
              name="datepicker"
              placeholder="Date"
              :style="{
              width: '100%',
              height: '40px',
            }"
              @input="handleDateSelect(1, ...arguments)"
              data-test-id="filter-date-input-2"
            />
          </div>
        </div>
        <div
          v-else-if="editFilterCondition.data.condition !== 'is_known' && editFilterCondition.data.condition !== 'is_unknown'"
          class="date-picker-date-section"
        >
          <am2-datepicker
            ref="datepicker-1"
            :value="editFilterCondition.data.values[0]"
            class="date-picker-date"
            name="datepicker"
            has-arrow
            :style="{
            width: '100%',
            height: '40px',
          }"
            @input="handleDateSelect(0, ...arguments)"
            data-test-id="filter-date-input"
          />
        </div>
        <AdditionalConstraintRenderer
          class="optional-constraints"
          v-if="criteriaHasConstraints"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
        <div
          v-if="!!criteriaPickerColumn && !!criteriaPickerResource"
          class="secondary-query-result">
          <ar-text
            size="12px"
            :text="secondaryQueryResultText"
            weight="normal"
            multiple-lines
            align="right"
            class="u-margin-top-2"
            :max-lines="1"
            :style="{
              color: $arStyle.color.skyBlueGrey600,
            }"
          />
        </div>
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import moment from 'moment';
import { clone } from '@/utils/helpers/';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint } from '@/utils/filter';
import { filterCriteriaMandatoryConstraintsFilled } from '~/utils/filter';
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'
import { mapState } from 'vuex'

export default {
  title: 'RadioPicker',
  components: {
    RendererFrame,
    AdditionalConstraintRenderer,
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isReadOnly: false,
      data: null,
      cachedFilterCondition: clone(this.filterCondition),
      editFilterCondition: clone(this.filterCondition),
      criteriaTargetResourcesMatches: 0,
    };
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
    secondaryQueryResultText() {
      if (this.criteriaTargetResourcesMatches === null) return "";
      if (this.criteriaTargetResourcesMatches === 0 && !this.editFilterCondition?.data?.values[0]) {
        return "";
      }
      if (this.editFilterCondition.data.condition === 'is_between' &&
        this.criteriaTargetResourcesMatches === 0 &&
        !this.editFilterCondition?.data?.values[0] &&
        !this.editFilterCondition?.data?.values[1]) {
        return "";
      }
      if (this.criteriaTargetResourcesMatches === 0) return "None found";
      if (this.criteriaTargetResourcesMatches === 1) return `1 ${this.criteriaPickerResource} found`;
      return `${this.criteriaTargetResourcesMatches} ${this.criteriaPickerResource}s found`;
    },
    criteriaPickerResource() {
      return this.filterCriteria?.targetResource;
    },
    criteriaPickerColumn() {
      return this.filterCriteria?.targetColumn;
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition);
    },
    selectDateTypeItems() {
      return this.filterCriteria.data.predicates.map(predicate => ({
        name: predicate.title,
        value: predicate.value,
      }));
    },
    dateTypeSelectedIndex() {
      for (let i = 0; i < this.filterCriteria.data.predicates.length; i += 1) {
        if (this.filterCriteria.data.predicates[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    dateExpressionActions() {
      const predicateWithDateExpression = this.filterCriteria.data.predicates.find(
        predicate => predicate.value === 'is'
      );
      return predicateWithDateExpression.options.map(option => ({
        name: option.title,
        value: option.value,
      }));
    },
    dateExpressionSelectedIndex() {
      for (let i = 0; i < this.dateExpressionActions.length; i += 1) {
        if (this.dateExpressionActions[i].value === this.editFilterCondition.data.values[0]) {
          return i;
        }
      }
      return -1;
    },
    reviewConditionTitle() {
      if (!this.editFilterCondition.data) {
        return null;
      }

      const condition = this.editFilterCondition.data.condition;
      if (condition === 'is_known' || condition === 'is_unknown') {
        return null;
      }

      const clickedPredicate = this.filterCriteria.data.predicates.find(
        predicate => predicate.value === this.editFilterCondition.data.condition
      );
      return clickedPredicate ? clickedPredicate.title.toLowerCase() : null;
    },
    readonlyItems() {
      if (!this.editFilterCondition.data) {
        return null;
      }

      const { condition, values } = this.editFilterCondition.data;
      const startDateString = values && values[0] ? moment(values[0]).format('YYYY/MM/DD') : null;
      const endDateString = values && values[1] ? moment(values[1]).format('YYYY/MM/DD') : null;

      if (condition === 'is_between') {
        if (!startDateString || !endDateString) {
          return null;
        }
        return [{ name: `
          ${startDateString}
           -
          ${endDateString}
        `}];
      } else if (condition === 'is') {
        const dateExpression = this.dateExpressionActions.find(
          action => action.value === startDateString
        );
        return [{ name: dateExpression?.name }];
      } else if (condition === 'is_known' || condition === 'is_unknown') {
        return [{ name: this.findPredicateByCondition(condition).title }];
      } else if (condition) {
        if (!startDateString) {
          return null;
        }
        return [{ name: startDateString }];;
      } {
        return null;
      }
    },
  },

  async created() {
    this.isReadOnly = this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
  },

  methods: {
    findPredicateByCondition(condition) {
      return this.filterCriteria.data.predicates.find(
        predicate => predicate.value === condition
      );
    },
    review() {
      this.$emit('apply', clone(this.editFilterCondition));
      this.fetchTargetResourceMatches();
    },
    // If this component fetches a secondary resource, do that fetch here
    async fetchTargetResourceMatches() {
      if (!this.criteriaPickerColumn || !this.criteriaPickerResource) return null;
      try {
        let filter = '';
        let valueA = this.editFilterCondition.data.values[0] ? moment(this.editFilterCondition.data.values[0]).startOf('day').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss.sss') : null;
        let valueB = this.editFilterCondition.data.values[1] ? moment(this.editFilterCondition.data.values[1]).startOf('day').add(1, 'second').format('YYYY-MM-DD HH:mm:ss.sss') : null;
        if (this.editFilterCondition.data.condition === 'is_before' && !!valueA) {
          filter = `userDefined AND ${this.criteriaPickerColumn} < "${valueA}"`
        } else if (this.editFilterCondition.data.condition === 'is_after' && !!valueA) {
          filter = `userDefined AND ${this.criteriaPickerColumn} > "${valueA}"`
        } else if (this.editFilterCondition.data.condition === 'is_between' && !!valueA && !!valueB) {
          filter = `userDefined AND ${this.criteriaPickerColumn} > "${valueA}" AND ${this.criteriaPickerColumn} < "${valueB}"`
        } else if (this.editFilterCondition.data.condition === 'is_equal_to' && !!valueA) {
          let valueC = moment(this.editFilterCondition.data.values[0]).add(1, 'day').format('YYYY/MM/DD');
          filter = `userDefined AND ${this.criteriaPickerColumn} > "${valueA}" AND ${this.criteriaPickerColumn} < "${valueC}"`
        }

        this.criteriaTargetResourcesMatches = null;
        if (filter.length === 0) {
          this.criteriaTargetResourcesMatches = 0;
          return null;
        }

        if (this.criteriaPickerResource === 'event') {
          const res = await this.$api.event.fetchCount(this.promoterOid, {filter})
          this.criteriaTargetResourcesMatches = res && res.count ? res.count : 0;
        }
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: `Error fetching ${this.criteriaPickerColumn}s matching search conditions` })
        console.error(e)
      }

    },
    handleConfirmClick() {
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    openChangeMode() {
      this.isReadOnly = false;
      if (this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition)) {
        this.editFilterCondition = clone(this.editFilterCondition);
      } else {
        this.editFilterCondition = this.getNewValueByCondition(this.editFilterCondition.data.predicates[0].value);
      }
      this.fetchTargetResourceMatches();
    },
    openDatepicker(index) {
      setTimeout(() => {
        const datePicker = this.$refs[`datepicker-${index}`];
        if (datePicker) {
          datePicker.open();
        }
      }, 50);
    },
    handleDateSelect(index, date) {
      this.$set(this.editFilterCondition.data.values, index, date);
      this.review();

      if (index === 0 && this.editFilterCondition.data.condition === 'is_between' && !this.editFilterCondition.data.values[1]) {
        this.openDatepicker(2);
      }
    },
    handlePredicateSelect(select) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.condition === select.value) {
        return;
      }
      this.editFilterCondition = this.getNewValueByCondition(select.value);
      this.review();
    },
    handleDateExpressionSelect(select) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.values[0] === select.value) {
        return;
      }
      this.$set(this.editFilterCondition.data.values, 0, select.value);
      this.$delete(this.editFilterCondition.data.values, 1);
      this.review();
    },
    getNewValueByCondition(condition) {
      const newCondition = clone(this.editFilterCondition);
      newCondition.data.condition = condition;

      // Also initialize the values
      if (!newCondition.data.condition) {
      } else if (newCondition.data.condition === 'is_between') {
        newCondition.data.values = [];
        this.openDatepicker(1);
      } else if (newCondition.data.condition === 'is') {
        newCondition.data.values = [this.dateExpressionActions[0].value];
      } else if (newCondition.data.condition === 'is_known' || newCondition.data.condition === 'is_unknown') {
        delete newCondition.data.values;
      } else {
        newCondition.data.values = [];
        this.openDatepicker(1);
      }
      return newCondition;
    },
    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  .date-picker-options-wrapper {
    .date-picker-select {
      height: 40px;
    }
    .date-picker-option {
      margin-bottom: 8px;
    }
  }

  .date-picker-date-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &.with-dash {
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        border-bottom: 1px solid $blueGrey600;
        width: 10px;
        height: 1px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .date-picker-small-datepicker {
      display: inline-block;
      width: 132px;
    }

    .date-picker-line {
      width: 10px;
      border-top: 1px solid $skyBlueGrey500;
    }
  }
}
</style>
