<template>
  <article class="actionblock-container">
    <header class="actionblock-header">
      <div class="actionblock-title-container">
        <am2-heading type="h1" size="md" :title="title" />
        <ar-icon
          v-if="help"
          name="tooltip-question-mark"
          class="u-margin-top-1 u-margin-left-2 tooltip-icon"
          :color="$arStyle.color.blueGrey500"
          height="16px"
          width="16px"
          :data-test-id="`action-block-title-icon-${titleInKebabCase}`"
          v-tooltip.top="{
            content: help,
          }"
        />
      </div>
      <ar-text class="h4-actionvalue" size="md" :text="formatNumber(channels.total) || 0" />
    </header>
    <ul class="actions-list">
      <li v-for="(item, key) in orderedChannels()" :key="key">
        <ActionField
          :name="item.name"
          :target="item.target"
          :identifier="item.identifier"
          :amount="formatNumber(item.total)"
          :link="item.link"
          :class="{ 'has-identifier': item.identifier !== undefined }"
        />
      </li>
    </ul>
    <ar-modal
      :is-open="showFullChannelListModal"
      :header="title"
      hide-footer
      @close="showFullChannelListModal = false"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
    >
      <div slot="body">
        <ul class="actions-list modal">
          <li v-for="(item, key) in orderedChannels(true)" :key="key">
            <ActionField
              :name="item.name"
              :target="item.target"
              :identifier="item.identifier"
              :amount="formatNumber(item.total)"
              :link="item.link"
            />
          </li>
        </ul>
      </div>
    </ar-modal>
    <div v-if="showSeeAllOption">
      <ar-divider
        :style="{
          margin: '39px 0 8px 0',
        }"
      />
      <ar-link-button
        text="See all platforms"
        :style="{
          marginTop: '4px',
        }"
        @click="showFullChannelList"
      />
    </div>
  </article>
</template>

<script>
import accounting from 'accounting';
import ActionField from '@/components/ui/molecules/field/actionfield/ActionField';
import { isURL } from '@/utils/helpers';

export default {
  name: 'ActionBlock',
  components: {
    ActionField,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: null,
    },
    channels: {
      type: Object,
      default: () => ({
        channels: {},
      }),
    },
    defaultOrder: {
      type: Array,
      default: () => [],
    },
    showItemsCutOffThreshold: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      showFullChannelListModal: false,
    };
  },

  computed: {
    showSeeAllOption() {
      return this.channels.targets ? this.channels.targets.length > this.showItemsCutOffThreshold : false;
    },
    titleInKebabCase() {
      if (!this.title) {
        return '';
      }
      return this.$arUtils.general.generateDataTestPrettyName(this.title);
    },
  },

  methods: {
    formatNumber(number) {
      return accounting.formatNumber(number);
    },
    orderedChannels(showAll) {
      const priorityList = this.defaultOrder;
      const resultsList = [];

      if (this.channels.targets) {
        priorityList.forEach((channel) => {
          const alreadyAdded = resultsList.some((r) => r.name.includes(channel));

          if (alreadyAdded) {
            return;
          }

          const channelNodes = this.channels.targets.filter((t) => t.channel === channel);

          if (channelNodes.length >= 1) {
            channelNodes.forEach((channelNode) => {
              let differentiator;
              const targetsSeparated = channelNode.target.split(':');
              let identifier = targetsSeparated[targetsSeparated.length - 1];
              let maybeUrl = decodeURIComponent(identifier);
              let name = channelNode.channel;

              if (channelNode.channel === 'facebook') {
                const type = targetsSeparated[2];

                // sometimes its a url,
                // sometimes its an id
                // (fb events always url)
                if (type === 'event') {
                  identifier = type;

                  // Facebook Events must be presented as "Facebook event" not as just "Facebook"
                  // Add an exceptoin for this, it's a bit barbaric this code and will probably need
                  // to be corrected in the back-end at some point. Some kind of UI presentation feature.
                  name = 'facebook event';
                  identifier = channelNode.additionalInfo.title || 'Link to event';
                } else if (type === 'messenger') {
                  name = 'messenger';

                  // JNM: This is really ugly, but the idea for messenger is the server will put a href
                  // field into the additional info JSON blob which we can use as URL target for the facebook action.
                  if (channelNode.additionalInfo) {
                    identifier = channelNode.additionalInfo.title || targetsSeparated[3];
                    maybeUrl = channelNode.additionalInfo.href;
                  } else {
                    identifier = targetsSeparated[3];
                    maybeUrl = `https://www.facebook.com/${identifier}`;
                  }
                } else {
                  if (isURL(maybeUrl)) {
                    // strip out everything, except for
                    // whats after the last '/' (greedy)
                    identifier = maybeUrl.replace(/^.*\//, '');
                  } else {
                    identifier = maybeUrl;
                  }
                }
              } else if (channelNode.channel === 'spotify') {
                // spotify url doesn't contain presentable
                // identifiers, so just differentiate by type
                identifier = (channelNode.additionalInfo && channelNode.additionalInfo.title) || targetsSeparated[2];
              } else {
                if (isURL(maybeUrl) && decodeURIComponent(identifier) === maybeUrl) {
                  identifier = 'Link to user';
                } else {
                  // assume url that fits template string {channel-name}.com/{identifier}
                  maybeUrl =
                    channelNode.channel === 'youtube'
                      ? `https://${channelNode.channel}.com/channel/${identifier}`
                      : `https://${channelNode.channel}.com/${identifier}`;
                }
              }

              resultsList.push({
                name: name,
                identifier: this.channels.type === 'follow' ? identifier : null,
                target: channelNode.target,
                total: channelNode.total || 0,
                link: isURL(maybeUrl) ? maybeUrl : null,
              });
            });
          }
        });

        // Check results names AND targets to see if we need to add any additional channels to the view
        const remainingTargets = this.channels.targets.filter(
          (channel) => !resultsList.find((r) => r.name.includes(channel.channel) || r.target.includes(channel.target))
        );

        remainingTargets.forEach((channel) => {
          resultsList.push({
            name: channel.channel,
            target: channel.target,
            total: channel.total,
          });
        });
      } else {
        priorityList.forEach((channel) => {
          resultsList.push({
            name: channel,
            target: channel.target,
            total: 0,
          });
        });
      }

      if (showAll) {
        return resultsList;
      } else {
        return resultsList.slice(0, this.showItemsCutOffThreshold);
      }
    },
    showFullChannelList() {
      this.showFullChannelListModal = true;
    },
  },
};
</script>

<style lang="scss">
.actionblock-container {
  padding: 30px 30px 10px 30px;
  .actions-list {
    list-style: none;
    // border: 1px solid red;
    padding: 0;
    // text-align: left;
    li {
      & > div {
        margin: 0 0 19px;
      }
    }
  }
  .actionblock-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    .h4-actionvalue {
      color: $purple500;
    }
  }
  .actionblock-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .modal {
    padding: 30px;
  }
}
</style>
