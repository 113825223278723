<template>
  <section
    class="insights-wrapper"
    v-infinite-scroll="fetchMoreFans"
    infinite-scroll-disabled="disableFetchMoreFans"
    infinite-scroll-distance="100"
  >
    <div v-if="seeAllTableDataKey" class="see-all-table">
      <header class="header">
        <am2-step-link
          text="Audience Insights"
          @click="handleInsightLinkClick"
          :style="{
            marginBottom: '20px',
          }"
        />
        <div :class="[
          'title',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
        ]">
          <am2-heading
            type="h1"
            :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'md' : 'lg'"
            :title="seeAllTableTitle"
          />
        </div>
      </header>
      <am2-fan-table
        v-if="seeAllTableHeadings"
        :head="seeAllTableHeadings"
        :body="seeAllTableDataForFanTable"
        :loading="(isPollingCurrentInsights || isFetchingAudience) && seeAllTableDataForFanTable.length === 0"
        has-index
        empty-text="No audience data"
        tableType="audience"
        ref="see-all-insights-table"
        class="see-all-table"
        rowKeyName="puid"
        :enable-row-click="customerProfilesEnabled"
        @rowClick="handleRowClick"
      />
      <am2-loading-section
        v-if="isPollingCurrentInsights || isFetchingAudience"
        :style="{
          marginTop: '30px',
        }"
      />
    </div>

    <div
      v-if="!seeAllTableDataKey"
      :class="[
        'chart-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]"
    >

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock the highest value customers',
          message: 'See who is spending the most on tickets',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="highestValueCustomerDatas"
          is-value-currency
          heading="Top spending ticket buyers"
          tooltip="Your customers that have spent the most on tickets across all your events"
          :column-names="['NAME','TOTAL TICKET SALES']"
          empty-text="No data is available"
          @seeAll="handleSeeAllClick('highestValueCustomers')"
        />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock the most loyal customers',
          message: 'Identify & reward your biggest supporters.',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="mostLoyalCustomerDatas"
          heading="Repeat ticket buyers"
          tooltip="Customers that have purchased tickets to the most events you’ve done"
          :column-names="['NAME','# EVENTS']"
          empty-text="No data is available"
          @seeAll="handleSeeAllClick('mostLoyalCustomers')"
        />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        class="chart-container full-width"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock location insights',
          message: 'See where your customers are located',
        }"
      >
        <am2-multiple-bar-charts
          ref="locationChart"
          class="location-insights-chart"
          :style="{ height: `${locationChartMaxHeight + 85}px` }"
          :locked="!isInsightsEnabled"
          :loading="isPollingCurrentInsights"
          title="Location"
          :topics="locationChartData.topics"
          :chart-datas="locationChartData.chartDatas"
          :topic-index="locationChartIndex"
          :chart-height="locationChartMaxHeight"
          @topicChange="(topic) => { changeLocationGraphTopic(topic) }"
        />
        <ar-text
          v-if="showSeeMoreOnLocationChart && !isLoadingSeeMore"
          :text="`<a style='text-decoration:underline;'>See more</a>`"
          allow-html
          size="xs"
          align="center"
          @anchorClick="increaseMaxSizeOfLocationGraph"
        />
        <div
          v-else-if="showSeeMoreOnLocationChart && isLoadingSeeMore"
          :style="{textAlign: 'center'}"
        >
          <am2-loading-bubble />
        </div>
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock age insights',
          message: 'See the age breakdown for your audience',
        }"
      >
        <am2-multiple-column-charts
          :style="{ height: '462px' }"
          :loading="isPollingCurrentInsights"
          hide-switch-buttons
          title="Age"
          :topics="ageChartData.topics"
          :chartDatas="ageChartData.chartDatas"
          :chart-height="467"
          :topic-index="0"
          :locked="!isInsightsEnabled"
        />
        <am2-tag
          v-if="averageAge"
          :text="`Average: ${averageAge}`"
          type="purple"
          shape="rectangle"
          :style="{ position: 'absolute', top: '30px', right: '30px', height: '32px', padding: '0 9px',  }"
        />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock gender insights',
          message: 'See the gender breakdown for your audience',
        }"
      >
        <am2-multiple-pie-charts
          :style="{ height: '462px' }"
          :locked="!isInsightsEnabled"
          title="Gender"
          hide-switch-buttons
          :loading="isPollingCurrentInsights"
          :topics="genderChartData.topics"
          :chart-data="genderChartData.chartData"
          :units="{
            singular: 'person',
            plural: 'people',
          }"
          :topic-index="0"
          :chart-height="467" />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock top referrers',
          message: 'Uncover key influencers to engage as ticket sellers',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="topReferrerDatas"
          heading="Top referrers"
          tooltip="People who have referred the most friends to register for campaigns"
          :columnNames="['NAME','REFERRALS']"
          emptyText="No referrals are available"
          @seeAll="handleSeeAllClick('topReferrers')"
        />
      </am2-card-container>

      <!-- <am2-card-container>
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="currentInsights && currentInsights.topInfluencers.data"
          heading="Top influencers"
          :columnNames="['NAME','INSTAGRAM FOLLOWERS']"
          @seeAll="handleSeeAllClick('topInfluencers')">
          <div slot="if-empty">
            <p>No influencers are available</p>
          </div>
        </am2-small-table>
      </am2-card-container> -->

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock top artists',
          message: 'Discover the artists your audiences are listening to most',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="topArtistDatas"
          heading="Top artists"
          tooltip="The artists that are most popular within your audience"
          :column-names="['NAME','LISTENERS']"
          empty-text="No artists are available"
          @seeAll="handleSeeAllClick('topArtists')"
        />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock top tracks',
          message: 'Discover the tracks your audiences are listening to most.',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="topTrackDatas"
          heading="Top tracks"
          tooltip="The tracks that are most popular within your audience"
          :column-names="['NAME','LISTENERS']"
          empty-text="No tracks are available"
          @seeAll="handleSeeAllClick('topTracks')"
        />
      </am2-card-container>

      <am2-card-container
        layout="soft"
        :class="[
          'chart-container',
          $arMediaQuery.pageContent.maxWidth('md') && 'full-width',
        ]"
        v-ar-feature-mask="{
          show: !isInsightsEnabled,
          title: 'Unlock Top podcast shows',
          message: 'Discover the podcast shows your audiences are listening to most.',
        }"
      >
        <am2-small-table
          :style="{ height: 'auto' }"
          :loading="isPollingCurrentInsights"
          :locked="!isInsightsEnabled"
          :error="hasFetchInsightsFailed"
          :data="topPodcastsDatas"
          heading="Top podcast shows"
          tooltip="The podcast shows that are most popular within your audience"
          :column-names="['NAME','LISTENERS']"
          empty-text="No podcast shows are available"
          @seeAll="handleSeeAllClick('topPodcasts')"
        />
      </am2-card-container>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
  import { virtualFans } from './virtual_insights_data';
  import { shadeHexColor } from '@/utils/helpers';
  import { countryNameMap } from '@/utils/countries';
  import accounting from 'accounting';

  const dataKeyTitleMap = {
    highestValueCustomers: 'Top Spending Ticket Buyers',
    mostLoyalCustomers: 'Repeat Ticket Buyers',
    topReferrers: 'Top Referrers',
    topInfluencers: 'Top Influencers',
    topArtists: 'Top Artists',
    topTracks: 'Top Tracks',
    topPodcasts: 'Top Podcast Shows',
  };

  const chartPageSize = 10;

  export default {
  name: 'Insights',

  props: {
    filter: {
      type: Object,
      default: null,
    },
    featureType: {
      type: String,
      default: 'audience-insights',
    },
    enableSeeAllTableRouteChange: {
      type: Boolean,
      default: false,
    },
    eventOid: {
      type: String,
      default: null,
    },
    campaignOid: {
      type: String,
      default: null,
    },
    insightType: {
      type: String,
      default: 'filter',
      validator: (val) => ['filter', 'campaign', 'event'].indexOf(val) > -1,
    },
  },

  data() {
    return {
      insightsTopFans: [],
      seeAllTableDataKey: null,
      locationChartTopic: 'City',
      locationChartMaxSize: chartPageSize,
      isLoadingSeeMore: false,
    };
  },

  watch: {
    currentInsights(val) {
      if (val && this.seeAllTableDataKey) {
        this.fetchSeeAllTableData(this.seeAllTableDataKey);
      }
      if (val && (!val.city || !val.city.data)) {
        if (!!val.state && !!val.state.data) {
          this.changeLocationGraphTopic({name: "State"});
        } else if (!!val.country && !!val.country.data) {
          this.changeLocationGraphTopic({name: "Country"});
        }
      }
    },
    prunedScratchSegment: {
      handler(val) {
        if (val && this.insightType === 'filter') {
          this.fetchInsight();
        }
      },
      immediate: true,
    },
    campaignOid: {
      handler: function () {
        if (this.insightType === 'campaign') {
          this.fetchInsight();
        }
      },
      immediate: true,
    },
    eventOid: {
      handler: function () {
        if (this.insightType === 'event') {
          this.fetchInsight();
        }
      },
      immediate: true,
    },
    $route: {
      handler: function (newVal) {
        const allowedKeys = Object.keys(dataKeyTitleMap);
        if (this.enableSeeAllTableRouteChange) {
          if (newVal.query && newVal.query.seeAll && allowedKeys.indexOf(newVal.query.seeAll) > -1) {
            this.seeAllTableDataKey = newVal.query.seeAll;
            this.fetchSeeAllTableData(newVal.query.seeAll);
            this.$emit('seeAllToggle', true);

          } else if (newVal.query && newVal.query.seeAll) {
            this.$router.push({
              path: newVal.path
            });

          } else {
            this.seeAllTableDataKey = null;
          }
        }
      },
      immediate: true,
    }
  },

  computed: {
    ...mapState({
      audience: state => state.audience.audience,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      isFetchingAudience: state => state.audience.isFetchingAudience,
      isNoMoreAudience: state => state.audience.isNoMoreAudience,
      scratchSegment: state => state.segment.scratchSegment,
      scratchSegmentInfo: state => state.segment.scratchSegmentInfo,
      currentInsights: state => state.insight.currentInsights,
      isPollingCurrentInsights: state => state.insight.isPollingCurrentInsights,
      hasFetchInsightsFailed: state => state.insight.hasFetchInsightsFailed,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      prunedScratchSegment: 'segment/prunedScratchSegment',
    }),

    seeAllTableData() {
      if (this.useInsightsSnapshot) {
        return this.insightsTopFans;
      } else {
        return this.audienceRowToInsightsRow(this.audience);
      }
    },
    seeAllTableDataCount() {
      if (this.useInsightsSnapshot) {
        return accounting.formatNumber(this.insightsTopFans.length);
      } else {
        return accounting.formatNumber(this.totalAudienceCount || 0);
      }
    },
    disableFetchMoreFans() {
      return !this.seeAllTableDataKey ||
        this.useInsightsSnapshot ||
        this.isFetchingAudience ||
        this.isNoMoreAudience;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    isInsightsEnabled() {
      return this.isFeatureEnabled(['insights']);
    },

    customFilter() {
      if (this.insightType === 'filter' && this.prunedScratchSegment) {
        return this.prunedScratchSegment.filter;
      } else if (this.insightType === 'event') {
        return {
          logic: [],
          conditions: [
            {
              name: 'events',
              type: 'condition-search-picker',
              data: {
                condition: 'true_to_all',
                values: [ parseInt(this.eventOid) ],
              },
            },
          ],
        };
      } else if (this.insightType === 'campaign') {
        return {
          logic: [],
          conditions: [
            {
              name: 'campaigns',
              type: 'condition-search-picker',
              data: {
                condition: 'true_to_all',
                values: [ parseInt(this.campaignOid) ],
              },
            },
          ],
        };
      } else {
        return null;
      }
    },

    seeAllTableDataForFanTable() {
      return this.seeAllTableData.map(row => {
        if (this.isSpotifyUser(row)) {
          return row;
        } else {
          return {
            ...row,
            avatar: null,
            internalHref: this.customerProfilesEnabled ? `/audience/${row.key}/view/overview/` : null,
            oid: row.key,
            name: row.name && row.name !== " " ?  row.name : row.emailAddress,
          };
        }
      });
    },

    highestValueCustomerDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights) {
        return [];
      } else if(this.currentInsights.highestValueCustomers.data && this.customerProfilesEnabled) {
        return this.currentInsights.highestValueCustomers.data.map( obj => ({
          ...obj,
          internalHref: `/audience/${obj.key}/view/overview`,
          name: obj.name && obj.name !== " " ?  obj.name : obj.emailAddress
        }));
      } else if(this.currentInsights.highestValueCustomers.data && !this.customerProfilesEnabled) {
        return this.currentInsights.highestValueCustomers.data;
      } else {
        return null;
      }
    },

    mostLoyalCustomerDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights) {
        return [];
      } else if (this.currentInsights.mostLoyalCustomers.data && this.customerProfilesEnabled) {
        return this.currentInsights.mostLoyalCustomers.data.map( obj => ({
          ...obj,
          internalHref: `/audience/${obj.key}/view/overview`,
          name: obj.name && obj.name !== " " ?  obj.name : obj.emailAddress
        }));
      } else if (this.currentInsights.mostLoyalCustomers.data && !this.customerProfilesEnabled) {
        return this.currentInsights.mostLoyalCustomers.data;
      } else {
        return null;
      }
    },

    topReferrerDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights) {
        return [];
      } else if (this.currentInsights.topReferrers.data && this.customerProfilesEnabled) {
        return this.currentInsights.topReferrers.data.map( obj => ({
          ...obj,
          internalHref: `/audience/${obj.key}/view/overview`,
          name: obj.name && obj.name !== " " ?  obj.name : obj.emailAddress
        }));
      } else if (this.currentInsights.topReferrers.data && !this.customerProfilesEnabled) {
        return this.currentInsights.topReferrers.data;
      } else {
        return null;
      }
    },

    topArtistDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights) {
        return [];
      } else {
        return this.currentInsights.topArtists.data;
      }
    },

    topTrackDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights) {
        return [];
      } else {
        return this.currentInsights.topTracks.data;
      }
    },

    topPodcastsDatas() {
      if (!this.isInsightsEnabled) {
        return virtualFans;
      } else if (!this.currentInsights || !this.currentInsights.topPodcasts) {
        return [];
      } else {
        return this.currentInsights.topPodcasts.data;
      }
    },

    genderChartData() {
      let chartData;
      let total;

      if (!this.isInsightsEnabled) {
        total = 21;
        chartData = {
          Male: 6,
          Female: 10,
          Other: 1,
        };
      } else if (!this.currentInsights) {
        total = 1;
        chartData = {
          Male: 0,
          Female: 0,
          Other: 0,
        };
      } else {
        total = this.currentInsights.gender.data.reduce((accumulator, {count}) => {
          return accumulator + count
        }, 0);

        chartData = {};
        this.currentInsights.gender.data.map((item) => {
          chartData[item.gender] = item.count;
        });
      }

      const topics = [
        {
          name: 'Gender',
          valueGetters: [
            {
              name: 'Male',
              getter: ({ Male }) => Male,
            },
            {
              name: 'Female',
              getter: ({ Female }) => Female,
            },
            {
              name: 'Other',
              getter: ({ Other }) => Other,
            },
          ],
          format: '{value}',
        },
      ];

      return {
        topics,
        chartData,
      };
    },

    averageAge() {
      if (!this.currentInsights || !this.currentInsights.age) {
        return 0;
      }
      return parseInt(this.currentInsights.age.average, 10);
    },

    locationChartIndex() {
      if (this.locationChartTopic.toLowerCase() === 'city') {
        return 0;
      } else if (this.locationChartTopic.toLowerCase() === 'state') {
        return 1;
      }
      return 2;
    },
    locationChartColors() {
      const locationData = this.slicedLocationData;
      const selectedTopic = this.locationChartTopic.toLowerCase();
      const numberOfRows = Math.min(locationData[selectedTopic].length, this.locationChartMaxSize);
      const startColor = this.$arStyle.color.purple500;
      const endLightness = 40;
      const interval = (endLightness / numberOfRows) / 100;
      const colors = [];
      for (let i = 0; i < numberOfRows; i++) {
        colors.push(shadeHexColor( startColor, interval * i ))
      }
      return colors;
    },
    locationChartData() {
      const locationData = this.slicedLocationData;
      const topics = [];
      const maxBarWidth = 50;
      const minBarWidth = 25;

      const percentageData = {
        city: [],
        state: [],
        country: [],
      };

      if (locationData.city) {
        const totalCount = locationData.city.reduce( (acc, curr) => acc + curr.count, 0);
        percentageData.city = locationData.city.map( item => {
          return {
            data: item.data,
            count: totalCount ? (item.count / totalCount) * 100 : 0,
            rawValue: item.count,
          }
        });
        let barWidth = maxBarWidth;
        if (locationData.city.length >= chartPageSize * 0.3 && locationData.city.length <= chartPageSize * 0.8) {
          const sizePerUnit = maxBarWidth / chartPageSize;
          barWidth = sizePerUnit * (chartPageSize - locationData.city.length);
          if (barWidth < minBarWidth) barWidth = minBarWidth;
        } else if (locationData.city.length > chartPageSize * 0.8) {
          barWidth = minBarWidth;
        }

        topics.push({
          name: 'City',
          valueGetters: [
            {
              name: 'Default',
              getter: ({ data, count, color }) => [ data, count, color ],
            },
          ],
          format: '{value}',
          tooltipValueFormatter({ count, rawValue }) {
            if (rawValue === 1) return `${accounting.formatNumber(rawValue)} person`;
            return `${accounting.formatNumber(rawValue)} people`;
          },
          tooltipPercentageFormatter({ count, rawValue }) {
            let cleanCount = parseFloat(count);
            if (cleanCount > 0 && cleanCount < 1) {
              return "<1%";
            } else if (cleanCount > 99 && cleanCount !== 100) {
              return ">99%";
            } else {
              return `${Math.round(cleanCount)}%`;
            }
          },
          tooltipLabelFormatter({ data }, idx) {
            return `City: ${data}`;
          },
          barWidth,
          hasTooltip: true,
          sharedTooltip: false,
          hasYLabel: true,
          yAxisMinRange: 2,
          yAxisOpposite: false,
          yAxisMax: 100,
          colors: this.locationChartColors,
          units: {
            plural: 'people',
            singular: 'person',
          },
          clickEvent: (event) => {
            if (event.point.name === 'Other') {
              this.increaseMaxSizeOfLocationGraph();
            }
          },
          yAxisLabelFormatter(value) {
            if (value < 10) {
              const fixedValString = value.toFixed(1).toString();
              const endsInZero = fixedValString.substring(fixedValString.length, 1) === ".0";
              return endsInZero ? `${Math.round(value)}%` : `${value.toFixed(1)}%`
            } else  {
              return `${Math.round(value)}%`
            }
          }
        })
      }
      if (locationData.state) {
        const totalCount = locationData.state.reduce( (acc, curr) => acc + curr.count, 0);
        percentageData.state = locationData.state.map( item => {
          return {
            data: item.data,
            count: totalCount ? (item.count / totalCount) * 100 : 0,
            rawValue: item.count,
          }
        });
        let barWidth = maxBarWidth;
        if (locationData.state.length >= chartPageSize * 0.3 && locationData.state.length <= chartPageSize * 0.8) {
          const sizePerUnit = maxBarWidth / chartPageSize;
          barWidth = sizePerUnit * (chartPageSize - locationData.state.length);
          if (barWidth < minBarWidth) barWidth = minBarWidth;
        } else if (locationData.state.length > chartPageSize * 0.8) {
          barWidth = minBarWidth;
        }

        topics.push({
          name: 'State',
          valueGetters: [
            {
              name: 'Default',
              getter: ({ data, count }) => [ data, count ],
            },
          ],
          format: '{value}',
          tooltipValueFormatter({ count, rawValue }) {
            if (rawValue === 1) return `${accounting.formatNumber(rawValue)} person`;
            return `${accounting.formatNumber(rawValue)} people`;
          },
          tooltipPercentageFormatter({ count, rawValue }) {
            let cleanCount = parseFloat(count);
            if (cleanCount < 1) {
              return "<1%";
            } else if (cleanCount > 99 && cleanCount !== 100) {
              return ">99%";
            } else {
              return `${Math.round(cleanCount)}%`;
            }
          },
          tooltipLabelFormatter({ data }, idx) {
            return `State: ${data}`;
          },
          barWidth,
          hasTooltip: true,
          sharedTooltip: false,
          hasYLabel: true,
          yAxisMinRange: 2,
          yAxisOpposite: false,
          yAxisMax: 100,
          colors: this.locationChartColors,
          units: {
            plural: 'people',
            singular: 'person',
          },
          yAxisLabelFormatter(value) {
            if (value < 10) {
              const fixedValString = value.toFixed(1).toString();
              const endsInZero = fixedValString.substring(fixedValString.length, 1) === ".0";
              return endsInZero ? `${Math.round(value)}%` : `${value.toFixed(1)}%`
            } else  {
              return `${Math.round(value)}%`
            }
          }
        })
      }
      if (locationData.country) {
        const totalCount = locationData.country.reduce( (acc, curr) => acc + curr.count, 0);
        percentageData.country = locationData.country.map( item => {
          return {
            data: item.data,
            count: totalCount ? (item.count / totalCount) * 100 : 0,
            rawValue: item.count,
          }
        });
        let barWidth = maxBarWidth;
        if (locationData.country.length >= chartPageSize * 0.3 && locationData.country.length <= chartPageSize * 0.8) {
          const sizePerUnit = maxBarWidth / chartPageSize;
          barWidth = sizePerUnit * (chartPageSize - locationData.country.length);
          if (barWidth < minBarWidth) barWidth = minBarWidth;
        } else if (locationData.country.length > chartPageSize * 0.8) {
          barWidth = minBarWidth;
        }
        topics.push({
          name: 'Country',
          valueGetters: [
            {
              name: 'Default',
              getter: ({ data, count }) => [ data, count ],
            },
          ],
          format: '{value}',
          tooltipValueFormatter({ count, rawValue }) {
            if (rawValue === 1) return `${accounting.formatNumber(rawValue)} person`;
            return `${accounting.formatNumber(rawValue)} people`;
          },
          tooltipPercentageFormatter({ count, rawValue }) {
            let cleanCount = parseFloat(count);
            if (cleanCount < 1) {
              return "<1%";
            } else if (cleanCount > 99 && cleanCount !== 100) {
              return ">99%";
            } else {
              return `${Math.round(cleanCount)}%`;
            }
          },
          tooltipLabelFormatter({ data }, idx) {
            return `Country: ${data}`;
          },
          barWidth,
          hasTooltip: true,
          sharedTooltip: false,
          hasYLabel: true,
          yAxisMinRange: 2,
          yAxisOpposite: false,
          yAxisMax: 100,
          colors: this.locationChartColors,
          units: {
            plural: 'people',
            singular: 'person',
          },
          yAxisLabelFormatter(value) {
            if (value < 10) {
              const fixedValString = value.toFixed(1).toString();
              const endsInZero = fixedValString.substring(fixedValString.length, 1) === ".0";
              return endsInZero ? `${Math.round(value)}%` : `${value.toFixed(1)}%`
            } else  {
              return `${Math.round(value)}%`
            }
          }
        })
      }



      const selectedTopic = this.locationChartTopic.toLowerCase();
      if (locationData && percentageData[selectedTopic]) {
        return {
          topics,
          chartDatas: percentageData[selectedTopic],
        };
      } else {
        return {
          topics,
          chartDatas: [],
        };
      }
    },

    locationChartMaxHeight() {
      if (this.slicedLocationData.city.length === 0 && this.slicedLocationData.state.length === 0 && this.slicedLocationData.country.length === 0) {
        return 309;
      }
      const factor = this.locationChartMaxSize / chartPageSize;
      return Math.floor(407 * factor) - (70 * (Math.ceil(factor - 1)));
    },

    // Returns Location Data, but with top X results (with the rest condensed into 'Other')
    slicedLocationData() {
      // Filter out each grouping of location, and remove 'Other' if it exists (in case someone has input that value)
      let splitData = {
        'city': this.currentInsights && this.currentInsights.city && this.currentInsights.city.data ? this.currentInsights.city.data.filter( item => item.data !== 'Other') : [],
        'state': this.currentInsights && this.currentInsights.state && this.currentInsights.state.data ? this.currentInsights.state.data.filter( item => item.data !== 'Other') : [],
        'country': this.currentInsights && this.currentInsights.country && this.currentInsights.country.data ? this.currentInsights.country.data.filter( item => item.data !== 'Other') : [],
      };

      if (!this.isInsightsEnabled) {
        splitData = {
          'city': [
            {data : "Sydney", count: 1950},
            {data : "Melbourne", count: 1722},
            {data : "Brisbane", count: 1686},
            {data : "Auckland", count: 1594},
            {data : "Adelaide", count: 1499},
            {data : "Perth", count: 1451},
            {data : "Hobart", count: 1239},
            {data : "Newcastle", count: 853},
            {data : "Canberra", count: 655},
          ],
          'state': [
            {data : "New South Wales", count: 4572},
            {data : "Victoria", count: 1722},
            {data : "Queensland", count: 1686},
            {data : "South Australia", count: 1499},
            {data : "Western Australia", count: 1451},
            {data : "ACT", count: 655},
          ],
          'country': [
            {data : "Australia", count: 9843},
            {data : "United Kingdom", count: 5422},
            {data : "New Zealand", count: 1499},
          ],
        };
      }

      // Convert ISO codes into country names. Will probably need to combine the two
      // Possible for ISO codes to be saved in caps or in lowercase, so make sure to to account for that too!
      const filteredCountryData = [];
      splitData.country
        .filter(item => !!countryNameMap[item.data.toLowerCase()])
        .map( country => {
          return {
            data: countryNameMap[country.data.toLowerCase()],
            count: country.count,
          }
        }).forEach(country => {
          const existingItem = filteredCountryData.findIndex(filteredCountry => filteredCountry.data === country.data);
          if (existingItem > -1) {
            filteredCountryData[existingItem].count += country.count;
          } else {
            filteredCountryData.push(country);
          }
        });
      // Re-iterate over the server data to filter by countries which dont have matching iso mappings. Add them in too
      splitData.country
        .filter(item => !countryNameMap[item.data.toLowerCase()])
        .forEach( country => {
          const existingItem = filteredCountryData.findIndex(filteredCountry => filteredCountry.data === country.data);
          if (existingItem > -1) {
            filteredCountryData[existingItem].count += country.count;
          } else {
            filteredCountryData.push(country);
          }
        });
      splitData.country = filteredCountryData;

      // Normalise state data by stripping trailing/preceding whitepsace and grouping items under a common capitalized spelling
      // Should avoid situations in which Auckland and auckland are treated as different items.
      const filteredStateData = [];
      splitData.state
        .sort( (a, b) => {
          const aCount = a?.count || 0;
          const bCount = b?.count || 0;
          if (aCount > bCount) {
            return - 1
          } else if (aCount < bCount) {
            return 1;
          }
          return 0;
        })
        .forEach( state => {
          const stateName = state.data ? state.data.trim() : '';
          const existingItem = filteredStateData.findIndex(filteredState => filteredState.data.toLowerCase()=== stateName.toLowerCase());
          if (existingItem > -1) {
            filteredStateData[existingItem].count += (state.count || 0);
          } else {
            filteredStateData.push(state);
          }
        });
      splitData.state = filteredStateData;

      // And do the same for cities too
      const filteredCityData = [];
      splitData.city
        .sort( (a, b) => {
          const aCount = a?.count || 0;
          const bCount = b?.count || 0;
          if (aCount > bCount) {
            return - 1
          } else if (aCount < bCount) {
            return 1;
          }
          return 0;
        })
        .forEach( city => {
          const cityName = city.data ? city.data.trim() : '';
          const existingItem = filteredCityData.findIndex(filteredCity => filteredCity.data.toLowerCase() === cityName.toLowerCase());
          if (existingItem > -1) {
            filteredCityData[existingItem].count += (city.count || 0);
          } else {
            filteredCityData.push(city);
          }
        });
      splitData.city = filteredCityData;

      const splitDataKeys = Object.keys(splitData);
      splitDataKeys.forEach( splitKey => {
        if (splitData[splitKey].length > this.locationChartMaxSize) {
          const other = {
            data: "Other",
            type: splitKey,
            count: 0,
            aggregatedEntries: 0,
          };
          for(let i = this.locationChartMaxSize - 1; i < splitData[splitKey].length; i++) {
            other.count += splitData[splitKey][i].count;
            other.aggregatedEntries++;
          }

          splitData[splitKey] = splitData[splitKey].slice(0, this.locationChartMaxSize - 1);
          splitData[splitKey].push(other);
        }
      });

      return splitData;
    },

    showSeeMoreOnLocationChart() {
      const selectedTopic = this.locationChartTopic.toLowerCase();
      const data = this.slicedLocationData[selectedTopic];
      if (!data) return false;
      const other = data.find(item => item.data === 'Other');
      return !!other;
    },

    ageChartData() {

      let fakeData = [
        {
          age: "18-24",
          percentage: 13,
          count: 13,
        },
        {
          age: "25-30",
          percentage: 43,
          count: 43,
        },
        {
          age: "31-35",
          percentage: 32,
          count: 32,
        },
        {
          age: "36-40",
          percentage: 6,
          count: 6,
        },
        {
          age: "41-45",
          percentage: 4,
          count: 4,
        },
        {
          age: "46-50",
          percentage: 1,
          count: 1,
        },
        {
          age: "50+",
          percentage: 1,
          count: 1,
        },
      ];

      let emptyData = [
        {
          age: "18-24",
          percentage: 0,
          count: 0,
        },
        {
          age: "25-30",
          percentage: 0,
          count: 0,
        },
        {
          age: "31-35",
          percentage: 0,
          count: 0,
        },
        {
          age: "36-40",
          percentage: 0,
          count: 0,
        },
        {
          age: "41-45",
          percentage: 0,
          count: 0,
        },
        {
          age: "46-50",
          percentage: 0,
          count: 0,
        },
        {
          age: "50+",
          percentage: 0,
          count: 0,
        },
      ];

      const topics = [
        {
          name: 'Age',
          valueGetters: [
            {
              name: 'Default',
              getter: ({ age, percentage }) => [age, percentage],
            },
          ],
          format: '{value}%',
          tooltipValueFormatter({ count }) {
            if (count === 1) return `${accounting.formatNumber(count)} person`;
            return `${accounting.formatNumber(count)} people`;
          },
          tooltipPercentageFormatter({ percentage }) {
            let cleanCount = parseFloat(percentage);
            if (cleanCount > 0 && cleanCount < 1) {
              return "<1%";
            } else if (cleanCount > 99 && cleanCount !== 100) {
              return ">99%";
            } else {
              return `${Math.round(cleanCount)}%`;
            }
          },
          tooltipLabelFormatter({ age }) {
            return `Age: ${age}`;
          },
          hasTooltip: true,
          sharedTooltip: false,
          hasYLabel: true,
          // hasLegend: true,
        }
      ];

      if (!this.isInsightsEnabled) {
        return {
          topics,
          chartDatas: fakeData,
        };
      } else if (this.currentInsights) {
        // Age data returned as absolute values, but we need to convert them to percentages.
        const total = this.currentInsights.age.data.reduce((accumulator, { count }) => {
          return accumulator + count
        }, 0);

        if (total === 0) {
          return {
            topics,
            chartDatas: emptyData,
          };
        }

        const chartDatas = this.currentInsights.age.data.map(({ count, age }) => {
          const percentage = Math.round((count / total) * 100)
          return {
            age,
            count,
            percentage,
          };
        });

        return {
          topics,
          chartDatas,
        };
      } else {
        return {
          topics,
          chartDatas: emptyData,
        };
      }
    },
    seeAllTableHeadings() {
      return [
        {
          name: 'Name',
          key: 'name',
          format: 'name',
        },
        {
          name: 'Total',
          key: 'total',
          format: this.seeAllTableDataKey === 'highestValueCustomers' ? 'currency' : 'number',
        },
      ];
    },
    seeAllTableTitle() {
      return dataKeyTitleMap[this.seeAllTableDataKey];
    },
    useInsightsSnapshot() {
      return [
        'topInfluencers',
        'topArtists',
        'topTracks',
        'topPodcasts',
      ].findIndex(s => s === this.seeAllTableDataKey) >= 0;
    }
  },

  created() {
    if (this.enableSeeAllTableRouteChange) {
      const allowedKeys = Object.keys(dataKeyTitleMap);
      const seeAllVal = this.$route.query.seeAll;
      if (seeAllVal && allowedKeys.indexOf(seeAllVal) > -1) {
        this.seeAllTableDataKey = seeAllVal;
      }
    }
  },

  mounted() {
    this.scrollTo()
  },

  beforeDestroy() {
    window.localStorage.removeItem('_ar_insights_scroll_pos_');
    this['insight/STOP_POLLING_CURRENT_INSIGHTS']();
    this['insight/RESET_CURRENT_INSIGHTS']();
    this['audience/RESET_AUDIENCE']();
  },

  // Explicitly map the individual small-tables we need --- the idea was that we would
  // dynamically generate the individual small-tables we needed but it was overly complicated
  // at this point as the insights have not changed much.

  methods: {
    ...mapActions([
      'insight/STOP_POLLING_CURRENT_INSIGHTS',
      'insight/START_POLLING_CURRENT_INSIGHTS',
      'audience/FETCH_MORE_AUDIENCE',
    ]),
    ...mapMutations([
      'insight/RESET_CURRENT_INSIGHTS',
      'audience/RESET_AUDIENCE',
    ]),
    isSpotifyUser(row) {
      return row.href && row.href.indexOf('spotify.com') > -1;
    },

    // Increases the max size of the location graph by 10 (or less if the number of items in Other is less than 10)
    increaseMaxSizeOfLocationGraph() {
      this.isLoadingSeeMore = true;
      const locationData = this.slicedLocationData;
      const selectedTopic = this.locationChartTopic.toLowerCase();
      if (!locationData[selectedTopic]) return;
      const other = locationData[selectedTopic].find(item => item.data === 'Other');
      if (!other) return;

      if (other.aggregatedEntries >= chartPageSize) {
        this.locationChartMaxSize += chartPageSize;
      } else {
        this.locationChartMaxSize += other.aggregatedEntries;
      }
      this.isLoadingSeeMore = false;
    },
    changeLocationGraphTopic(newTopic) {
      if (!newTopic) return;
      if (this.locationChartTopic === newTopic.name) return;
      if (this.locationChartMaxSize !== chartPageSize) {
        this.locationChartMaxSize = chartPageSize;
      }
      this.locationChartTopic = newTopic.name;
    },

    fetchSeeAllTableData(seeAllTableDataKey) {
      // Reset fan table data
      this['audience/RESET_AUDIENCE']();
      this.insightsTopFans = [];

      if (this.useInsightsSnapshot) {
        this.insightsTopFans = this.currentInsights ? this.currentInsights[seeAllTableDataKey].data : [];
      } else {
        this.fetchMoreFans();
      }

      // Don't forget to scroll it to top
      window.scrollTo(0, 0);
    },

    handleRowClick(row) {
      if (row.internalHref) {
        this.$router.push({
          path: row.internalHref
        });
        return;
      }
      if (!row.href) {
        return;
      }
      window.open(row.href, '__blank');
    },

    handleSeeAllClick(dataKey) {
      window.localStorage.setItem('_ar_insights_scroll_pos_', window.scrollY);

      if (this.enableSeeAllTableRouteChange) {
        this.$router.push({ path: this.$route.path, query: { seeAll: dataKey } });
      } else {
        this.seeAllTableDataKey = dataKey;
        this.fetchSeeAllTableData(dataKey);
      }
      this.$emit('seeAllToggle', true);
    },
    handleInsightLinkClick() {
      if (this.enableSeeAllTableRouteChange) {
        this.$router.push({ path: this.$route.path });
        setTimeout(() => {
          this.scrollTo()
        }, 50)
      } else {
        this.seeAllTableDataKey = null;
      }
      this.$emit('seeAllToggle', false);
    },
    seeAllKeyToColumn() {
      return {
        highestValueCustomers: 'totalTicketSales',
        mostLoyalCustomers: 'totalEventCount',
        topReferrers: 'totalReferrals',
      }[this.seeAllTableDataKey];
    },
    audienceRowToInsightsRow(audienceRows) {
      return audienceRows.map(fan => {
        return {
          key: fan.oid,
          name: fan.name,
          // avatar: a.profileImageUrl, // don't show user image currently, because some FB terms update
          total: fan[this.seeAllKeyToColumn()],
          additionalInfo: fan.additionalInfo,
        };
      });
    },

    async fetchInsight() {
      if (!this.isInsightsEnabled) {
        return;
      }
      let succeed;
      if (this.insightType === 'filter') {
        let filterOidForInsights;
        if (this.scratchSegmentInfo.source === 'saved-audience-filter' || this.scratchSegmentInfo.source === 'internal-audience-filter') {
          if (this.scratchSegmentInfo.changed) {
            filterOidForInsights = this.scratchSegment.oid;
          } else {
            filterOidForInsights = this.scratchSegmentInfo.sourceOid;
          }
        } else {
          filterOidForInsights = this.scratchSegment.oid;
        }
        this['insight/START_POLLING_CURRENT_INSIGHTS']({
          type: 'segment',
          oid: filterOidForInsights,
        });
      } else if (this.insightType === 'event') {
        this['insight/START_POLLING_CURRENT_INSIGHTS']({
          type: 'event',
          oid: this.eventOid,
        });
      } else if (this.insightType === 'campaign') {
        this['insight/START_POLLING_CURRENT_INSIGHTS']({
          type: 'campaign',
          oid: this.campaignOid,
        });
      }
    },
    fetchMoreFans() {
      this['audience/FETCH_MORE_AUDIENCE']({
        top: 50,
        filter: this.customFilter,
        orderBy: {
          key: this.seeAllKeyToColumn(),
          order: 'desc'
        },
        selectKeys: [
          'oid',
          'emailAddress',
          'firstName',
          'lastName',
          'profileImageUrl',
          'additionalInfo',
          `${this.seeAllKeyToColumn()}`,
        ]
      });
    },
    scrollTo() {
      const scrollPos = window.localStorage.getItem("_ar_insights_scroll_pos_")
      if (scrollPos !== null) {
        window.scrollTo(0, scrollPos)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insights-wrapper {
  .upgrade-plan-section {
    margin-bottom: 40px;
  }
  .see-all-table {
    overflow: none;
    margin-bottom: 20px;
    .header {
      display: flex;
      margin: 0 0 20px;
      align-items: flex-start;
      flex-direction: column;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        flex-direction:row;
        .title-highlight {
          color: $purple500;
          margin-left: 10px;
        }

        &.xs-max {
          flex-direction:column;
          align-items:flex-start;
          overflow-x: hidden;
          .title-highlight {
            margin-left:0;
            margin-top:8px;
          }
        }
      }
    }
  }
  .insights-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .location-insights-chart {
    transition: height 0.2s;
  }

  .chart-section {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-gap: 30px;

    .chart-container {
      padding: 30px;
      overflow: hidden;

      &.full-width {
        grid-column: span 2;
      }
    }

    &.md-max {
      .chart-container {
        padding: 24px 12px;
      }
    }
  }
}
</style>
