<template>

  <ar-modal
    :is-open="isVisible"
    class="failed-import-modal"
    hide-header
    hide-footer
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
  >

    <!-- BODY -->
    <div
      slot="body"
      class="failed-import-modal__body"
    >
      <div class="warning-symbol">
        <span>!</span>
      </div>
      <am2-heading
        type="h1"
        size="md"
        title="Mass import was only partially complete"
        :style="{ margin: '10px 0' }"
      />
      <p>
        Your CSV file was imported, but only some of the data could be imported. You can download the rows that weren’t imported.
      </p>
      <ar-simple-button
        text="Download CSV"
        @click="beginDownload"
      />
    </div>
  </ar-modal>
</template>

<script>
import { magicDownload } from '@/utils/html-element/';

export default {
  name: 'FailedImportModal',

  data() {
    return {
      isVisible: false,
      loading: false,
      serverDownloadURL: '',
      failedRows: [],
      onConfirm: () => {},
      onClose: () => {},
    };
  },

  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_FAILED_IMPORT_MODAL') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'OPEN_FAILED_IMPORT_MODAL', payload } }));
    },
    startModalAction(rows) {
      this.failedRows = rows;
      this.isVisible = true;

      this.onConfirm = () => {
        this.isVisible = false;

        this.respondModalAction(true);
      };

      this.onClose = () => {
        this.loading = false;
        this.serverDownloadURL = '';
        this.isVisible = false;

        this.respondModalAction(false);
      };
    },
    beginDownload() {
      let rowsAsString = '';
      this.failedRows.forEach(row => {
        rowsAsString = rowsAsString.concat(row.join(","), "\n");
      });

      magicDownload(rowsAsString, 'failed-rows.csv');

      // Remember to close it 
      this.isVisible = false;

      this.onConfirm();
    },
  },
};
</script>

<style lang="scss">
.failed-import-modal {
  .modal-container {
    max-width: 600px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    > * {
      margin: 10px 0px;
    }

    > .warning-symbol {
      height: 70px;
      width: 70px;
      text-align: center;
      border-radius: 50%;
      background-color: $orange-lite;
      display: grid;

      > span {
        color: $orange500;
        font-size: 32px;
        margin: auto;
      }
    }

    > p {
      text-align: center;
    }
  }
}
</style>
