import { arDynamicTagsRegexGlobal } from '@/utils/regex'

export function beautifyDynamicTagsSyntax(message) {
  // The code right below is what Jared wants, but it will cause misleading,
  // because user might think they can use [xxx], but actually it's {{xxx}}
  // return message.replace(arDynamicTagsRegexGlobal, `[$1]`);
  return message.replace(arDynamicTagsRegexGlobal, `{{$1}}`);
};

export function injectPreviewContact(message, previewContact) {
  let finalMsg = message;
  Object.keys(previewContact).forEach(key => {
    let data = previewContact[key];
    if (key === 'mobile_number' && data.indexOf('+') !== 0) {
      data = `+${data}`;
    }
    finalMsg = finalMsg.replace(new RegExp(`{{${key}}}`, 'g'), data);
  });
  return finalMsg;
};
