<template>
  <span
    :class="['loading-bubble', isShow && 'show']"
    :style="{
      width: size,
      height: size,
    }"
  >
    <span
      :class="`blob left`"
      :style="{
        width: size,
        height: size,
      }"
    />
    <span
      :class="`blob right`"
      :style="{
        width: size,
        height: size,
      }"
    />
  </span>
</template>

<script>
export default {
  name: 'LoadingBubble',
  props: {
    size: {
      type: String,
      default: '10px',
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>

<style lang="scss">
.loading-bubble {
  position: relative;
  display: inline-block;
  color: $purple500;
}

.blob {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: currentColor;
}

.blob.left {
  animation: blob-left-anim cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
}

.blob.right {
  animation: blob-right-anim cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
}

@keyframes blob-left-anim {
  0% {
    transform: scale(1) translate(0, 0);
  }
  33% {
    transform: scale(1) translate(20px, 0);
  }
  66% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes blob-right-anim {
  0% {
    transform: scale(1) translate(0, 0);
  }
  33% {
    transform: scale(1) translate(-20px, 0);
  }
  66% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
</style>
