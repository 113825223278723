<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-multi-select-modal
      v-if="showOrganizationsSelectorModal"
      title="Select your organizations"
      subtitle="Eventbrite Organizations"
      description="Select the Eventbrite organizations you'd like to sync data from"
      :is-show="showOrganizationsSelectorModal"
      :items="availableOrganizationsForModal"
      :value="newConnectedOrganizationIds"
      @input="handleOrganizationsSelection"
      @close="handleOrgSelectClose"
    />
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Eventbrite"
          description="Automatically sync your ticket sales, events & attendees from Eventbrite"
          :icon-props="{
            name: 'eventbrite',
            color: null,
            height: '40px',
            width: '41px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!loadingOrganizations && integrationsSummary.length > 0">
        <IntegrationAccountSelect
          v-if="integrations && integrationOid"
          :default-selected-integration-oid="integrationOid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Eventbrite',
              value: 'goToEventbriteSite',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>
    <section v-if="isConnectingAnotherAccount || loadingOrganizations" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <section>
        <am2-tabs
          :items="[
            { title: 'Organizations' },
            { title: 'Status' },
          ]"
          :selected-tab-index="selectedTabIndex"
          class="tab-options"
          @select="(selected, idx) => selectedTabIndex = idx"
        />
        <div
          v-if="selectedTabIndex === 0"
          class="organizations-container"
        >
          <div class="integration-errors-container">
            <ar-snackbar
              v-for="(error, idx) in integrationErrors"
              :key="idx"
              type="error"
              :message="error.userErrorString"
              :style="{ width: '100%' }"
              class="u-margin-bottom-6"
            />
          </div>
          <ar-text
            text="Connected organizations"
            size="xs"
            weight="bold"
            class="header"
          />

          <!-- Connected Organizations -->
          <am2-card-container
            v-if="newConnectedOrganizationIds.length === 0"
            class="no-connected-orgs"
          >
            <am2-heading
              class="header"
              :size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
              type="h2"
              title="No organizations have been selected"
            />
            <p class="subtitle">
              Select the Eventbrite organizations you'd like to sync events & orders from
            </p>
          </am2-card-container>
          <div v-else>
            <div
              :class="[
                'org-list-item',
                $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              ]"
              v-for="org in newConnectedOrganizations"
              :key="org.id"
            >
              <div class="name-container">
                <ar-avatar
                  :name="org.name"
                  :image-path="org.image ? org.image.url : null"
                  :style="{
                    minWidth: '40px',
                  }"
                />

                <ar-text
                  class="label"
                  :text="org.name"
                  size="xs"
                  multiple-lines
                />
              </div>

              <div>
                <am2-tag
                  v-if="!!org.error"
                  type="red"
                  text="Error"
                  v-tooltip.top="{
                    content: org.error.userErrorString ? org.error.userErrorString : null
                  }"
                  text-size="xxxs"
                  class="u-margin-right-3"
                />
                <ar-simple-button
                  text="Remove"
                  outlined
                  style="height: 40px;"
                  @click="() => handleOrganizationRemove(org.id)"
                />
              </div>

            </div>
          </div>

          <!-- Unconnected organizations -->
          <div v-if="unconnectedOrganizations.length > 0" style="margin-top: 30px;">
            <ar-text
              text="Eventbrite organizations"
              size="xs"
              weight="bold"
              class="header"
            />

            <div>
              <div
                class="org-list-item"
                v-for="org in unconnectedOrganizations"
                :key="org.id"
              >
                <div class="name-container">
                  <ar-avatar
                    :name="org.name"
                    :image-path="org.image ? org.image.url : null"
                    :style="{
                      minWidth: '40px',
                    }"
                  />

                  <ar-text
                    class="label"
                    :text="org.name"
                    size="xs"
                    multiple-lines
                  />
                </div>
                <div>
                  <am2-tag
                    v-if="!!org.error"
                    type="red"
                    text="Error"
                    v-tooltip.top="{
                      content: org.error.userErrorString ? org.error.userErrorString : null
                    }"
                    text-size="xxxs"
                    class="u-margin-right-3"
                  />
                  <ar-simple-button
                    text="Add organization"
                    style="height: 40px;"
                    @click="() => handleOrganizationAdd(org.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else>
          <div class="integration-errors-container">
            <ar-snackbar
              v-for="(error, idx) in integrationErrors"
              :key="idx"
              type="error"
              :message="error.userErrorString"
              :style="{ width: '100%' }"
              class="u-margin-bottom-3"
            />
          </div>
          <am2-card-container
            class="sync-block-container"
          >
            <am2-loading-section
              v-if="isFetchingIntegrationTask"
              :style="{
              height: '475px',
            }"
            />
            <div v-else-if="isSyncing" class="sync-block">
              <am2-loading-bubble
                class="sync-animation"
              />
              <div class="text-block">
                <am2-heading
                  class="header"
                  :size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
                  type="h2"
                  :title="blockTitle"/>
                <p class="subtitle">
                  Syncing the events & orders from Eventbrite may take some time.
                </p>
                <div class="button-block">
                  <ar-simple-button
                    text="Go to events"
                    class="button"
                    @click="() => { $router.push({ path: '/events' }) }" />
                  <ar-simple-button
                    text="Stop sync"
                    outlined
                    class="button sync-btn"
                    @click="() => syncAction('stop')" />
                </div>
              </div>
            </div>
            <div v-else class="sync-block">
              <ar-icon name="event-sync-confirmed" class="eventbrite-tick-icon" />
              <div class="text-block">
                <am2-heading
                  class="header"
                  :size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
                  type="h2"
                  title="Sync has been completed"/>
                <p class="subtitle">
                  Your events and attendees have been synced from Eventbrite
                </p>
                <div class="button-block">
                  <ar-simple-button
                    text="Go to events"
                    class="button"
                    @click="() => { $router.push({ path: '/events' }) }" />
                  <ar-simple-button
                    text="Sync now"
                    outlined
                    :loading="actionLoading"
                    :disabled="integrationFailed"
                    class="button sync-btn"
                    :style="{
                      backgroundColor: integrationFailed ? $arStyle.color.skyBlueGrey400 : '#fff0',
                    }"
                    @click="() => syncAction('start')" />
                </div>
                <div v-if="hasSyncedBefore" class="last-sync-time">
                  Last synced {{ timeSinceLastSync }}
                </div>
              </div>
            </div>
          </am2-card-container>
        </div>

      </section>
    </section>

  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import IntegrationAccountSelect from './components/integration-account-select';
import { clearTimeout } from 'timers';

export default {
  name: 'EventbriteIntegration',
  layout: 'default',

  components: {
    IntegrationAccountSelect
  },

  data: () => ({
    isFetchingIntegrationTask: false,
    isConnectingAnotherAccount: false,
    actionLoading: false,
    loadingOrganizations: true,
    showOrganizationsSelectorModal: false,
    latestTask: null,
    syncStatusPoller: null,
    organizationFetchTimeout: null,
    integrations: [],
    integration: null,
    newConnectedOrganizationIds: [],
    selectedTabIndex: 1,
  }),

  computed: {
    integrationsSummary() {
      return this.integrations
        .filter(i => i.status !== 'failed')
        .map(i => ({
        oid: i.oid,
        name: i.integration.user.name,
        email: i.integration.user.emails[0].email,
        imageSrc: i.integration.user.image ? i.integration.user.image.url : null,
      }));
    },

    integrationFailed() {
      return this.integration.status === 'failed';
    },

    integrationErrors() {
      return this.integration?.integration?.errors || [];
    },

    availableOrganizationsForModal() {
      if (this.integration) {
        const organizations = this.integration.integration.organizations;
        return organizations.map(o => {
          const error = this.integrationErrors.find( item => item.organizationId === o.id) || null;
          return {
            name: o.name,
            value: o.id,
            disabled: !!error,
            error: error,
          }
        });
      }

      return [];
    },

    unconnectedOrganizations() {
      return this.integration.integration.organizations.filter(o =>
        this.newConnectedOrganizations.findIndex(co => co.id === o.id) === -1
      ).map( org => {
        const correspondingError = this.integrationErrors.find( i => {
          return i.organizationId === org.id;
        });
        return {
          ...org,
          error: correspondingError,
        }
      });
    },

    newConnectedOrganizations() {
      return this.integration.integration.organizations.filter(o =>
        this.newConnectedOrganizationIds.includes(o.id)
      ).map( org => {
        const correspondingError = this.integrationErrors.find( i => {
          return i.organizationId === org.id;
        });
        return {
          ...org,
          error: correspondingError,
        }
      });
    },

    integrationOid() {
      return this.integration ? this.integration.oid : null;
    },

    isSyncing() {
      if (!this.integration || !this.hasExistingTask) {
        return false;
      }

      return this.latestTask.status === 'in-progress';
    },

    hasExistingTask() {
      return this.latestTask !== null;
    },

    totalSyncEvents() {
      if(this.hasExistingTask) {
        return this.latestTask.statusDetails.totalEvents;
      }

      return 0;
    },

    currentSyncEvent() {
      if(this.hasExistingTask) {
        return this.latestTask.statusDetails.completedEventsCount + 1;
      }

      return 0;
    },

    hasSyncedBefore() {
      return (
        this.latestTask && this.latestTask.status === 'completed'
      );
    },

    timeSinceLastSync() {
      let sinceNow = "";

      if (this.hasSyncedBefore) {
        sinceNow = moment.utc(
          this.latestTask.sysMtime
        ).fromNow();
      }

      return sinceNow;
    },

    blockTitle() {
      return this.isSyncing ? `Syncing ${this.currentSyncEvent} of ${this.totalSyncEvents} events` : 'Sync has been completed';
    }
  },

  async mounted() {
    this.fetchIntegrations();
  },

  beforeDestroy() {
    if(this.syncStatusPoller !== null) {
      clearInterval(this.syncStatusPoller);
    }

    if(this.organizationFetchTimeout !== null) {
      clearInterval(this.organizationFetchTimeout);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'FETCH_INTEGRATIONS',
      'FETCH_EVENT_SYNC_TASKS',
      'FETCH_INTEGRATION',
      'DELETE_INTEGRATION',
      'UPDATE_INTEGRATION',
      'START_INTEGRATION_SYNC',
      'STOP_INTEGRATION_SYNC',
      'CONNECT_TO_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),


    async fetchIntegrations() {
      try {
        this.isFetchingIntegrationTask = true;

        const eventbriteIntegrations = await this.FETCH_INTEGRATIONS({
          filters: {
            expressions: [{
              key: 'provider',
              value: 'eventbrite',
              operator: '=',
            }],
            logicalOperators: [],
          },
          orderby: "sysMtime desc",
        });

        this.integrations = eventbriteIntegrations;
        this.integration = this.integrations[0];

        let currentTasks = null;
        if (this.integration !== null) {
          // EB integration status in-progress means we haven't pulled the
          // user's organizations yet.
          if (this.integration.status === 'in-progress') {
            this.organizationFetchTimeout = setTimeout(this.fetchIntegrations, 500);
            return;
          } else {
            this.organizationFetchTimeout = null;
            this.loadingOrganizations = false;
          }

          this.showOrganizationsSelectorModal = this.integration.status === 'completed' && this.integration.integration.organizations.length > 0 && this.integration.meta.connectedOrganizationIds === undefined;

          // If we're asking them to connect their ogranizations for the first time,
          // we should 'select' all options to encourage the promoter to
          // sync all their organizations with us.
          if (this.showOrganizationsSelectorModal) {
            this.newConnectedOrganizationIds = this.integration.integration.organizations.map(o => o.id);
          } else {
            this.newConnectedOrganizationIds = this.integration.meta.connectedOrganizationIds;
          }

          currentTasks = await this.FETCH_EVENT_SYNC_TASKS(
            {
              promoterIntegrationOid: this.integration.oid,
              filter: {
                name: "event-data-synch",
                promoterIntegrationOid: this.integration.oid,
              },
              top: 1,
              orderby: "sysMtime desc"
            }
          );

        }

        this.latestTask = currentTasks[0] || null;
      } catch(e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch Eventbrite integration' });
      } finally {
        this.isFetchingIntegrationTask = false;
      }

      if (this.isSyncing) {
        this.syncStatusPoller = setInterval(this.updateCurrentTask, 3000);
      }
    },

    syncAction(action) {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      switch (action) {
        case 'start':
          this.startSync();
          break;
        case 'stop':
          this.stopSync();
          break
        default:
          console.error('Invalid sync action: ', action);
          break;
      }
    },

    async updateCurrentTask() {

      const tasks = await this.FETCH_EVENT_SYNC_TASKS({
        promoterIntegrationOid: this.integrationOid,
        top: 1,
        orderby: "sysMtime desc"
      });

      this.latestTask = tasks[0] || null;

      if (!this.isSyncing) {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
      }
    },

    async startSync() {
      this.actionLoading = true;
      const result = await this.START_INTEGRATION_SYNC(this.integration.oid);
      this.actionLoading = false;
      if (result.accepted) {
        this.latestTask = result.newIntegrationTask;
        this.syncStatusPoller = setInterval(this.updateCurrentTask, 3000);

        // XXX/RK/2019-08-36
        //
        // This is /odd/ behaviour as we're doing things the user isn't aware
        // of in the background, but it's the desired behaviour:
        // When a promoter hits "Sync Now", what they're doing is syncing ALL
        // their integrations at once. Since this isn't instantly visible
        // to the user, we don't really care about the result. If it fails
        // because a task is already running, then we don't really care
        // since we've achieved our objective

        this.integrations.forEach(i => {
          if (i.oid !== this.integration.oid) {
            this.START_INTEGRATION_SYNC(i.oid);
          }
        });
      } else {
        this.$arNotification.push({
          type: 'error',
          message: result.message,
        })
      }
    },

    async stopSync() {
      const result = await this.STOP_INTEGRATION_SYNC(this.integration.oid);

      if (result.accepted) {
        this.latestTask = result.data;
        this.$arNotification.push({
          type: 'success',
          message: 'Eventbrite sync has been stopped',
        });
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
      }
    },

    handleOrganizationSelect(item) {
      this.selectedAccountId = item.value;
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Eventbrite integration will stop syncing your event and attendee data from Eventbrite.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this.DELETE_INTEGRATION(this.integration.oid);
            if (this.integrations.length > 1) {
              this.fetchIntegrations();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'goToEventbriteSite') {
        var win = window.open('https://www.eventbrite.com', '_blank');
        win.focus();
      }
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleOrgSelectClose() {
      this.showOrganizationsSelectorModal = false;
    },

    handleOrganizationAdd(orgId) {
      const orgAlreadyAdded = this.newConnectedOrganizationIds.findIndex(
        o => o.id === orgId
      ) >= 0;

      if (!orgAlreadyAdded) {
        this.newConnectedOrganizationIds.push(orgId);
        this.handleOrganizationsSelection(this.newConnectedOrganizationIds, 'added');
      }
    },

    async handleOrganizationRemove(orgId) {
      // Business decision - 28-08-2019
      //
      // Always confirm organization removal before updating
      // the PromoterIntegration settings.
      const answer = await this.SHOW_CONFIRM({
        messageHtml: 'Removing this organization will prevent future ticket sales and attendee data from being pulled from Eventbrite.',
        confirmButtonText: 'Remove integration',
        iconName: 'alert-question-mark',
        title: 'Are you sure?',
      });

      if (answer) {
        const removeAtIndex = this.newConnectedOrganizationIds.findIndex(o => o === orgId);

        if (removeAtIndex >= 0) {
          this.newConnectedOrganizationIds.splice(removeAtIndex, 1);
          this.handleOrganizationsSelection(this.newConnectedOrganizationIds, 'removed');
        }
      }
    },

    async handleOrganizationsSelection(organizationIds, verb = undefined) {

      try {
        await this.UPDATE_INTEGRATION({
          oid: this.integration.oid,
          data:{
            meta: {
              connectedOrganizationIds: organizationIds,
            },
            sysMtime: moment().utc().format(),
          },
        });

        this.integration = await this.FETCH_INTEGRATION(this.integration.oid);
        const integrationIndex = this.integrations.findIndex(i => i.oid === this.integration.oid);

        this.integrations.splice(integrationIndex, 1, this.integration);
        if (verb !== undefined) {
          this.$arNotification.push({ type: 'success', message: `Organization successfully ${verb}!` });
        }
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Updating connected organizations failed' });
      }

      this.showOrganizationsSelectorModal = false;
    },

    async reconnectIntegration() {
      await this.DELETE_INTEGRATION(this.integration.oid);
      this.handleAddNewIntegration();
    },

    async handleAddNewIntegration() {
      this.isConnectingAnotherAccount = true;
      try {
        const res = await this.CONNECT_TO_INTEGRATION({
          provider: 'eventbrite',
          app: 'eventbrite',
        });

        const eventbriteIntegrations = await this.FETCH_INTEGRATIONS({
          filters: {
            expressions: [{
              key: 'provider',
              value: 'eventbrite',
              operator: '=',
            }],
            logicalOperators: [],
          },
          orderby: "sysMtime desc",
        });

        this.integrations = eventbriteIntegrations;
        this.integration = this.integrations[0];
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }
        this.$arNotification.push({ type: 'error', message: `Failed to connect to Eventbrite: ${e.message}` });
        throw e;
      } finally {
        this.isConnectingAnotherAccount = false;
      }
    },

    handleIntegrationChange(integration, index) {
      this.integration = this.integrations[index];
      this.newConnectedOrganizationIds = this.integration.meta.connectedOrganizationIds;

      this.updateCurrentTask();
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .integration-failed-message {
    padding-top: 55px;
  }

  .tab-options {
    margin: 60px 0 20px 0;
    border-bottom: 1px $skyBlueGrey500 solid;
  }

  .organizations-container {
    margin-top: 40px;

    .header {
      margin-bottom: 14px;
    }

    .no-connected-orgs {
      background-color: $skyBlueGrey400;
      padding: 35px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .subtitle {
        // only instance of this colour in any of the designs.
        // will add to _variables.scss and ar-style.js IFF
        // the colour is found elsewhere.
        color: #8092A8;
      }
    }

    .org-list-item {
      background: white;
      height: 70px;
      padding: 15px;
      border-color: $skyBlueGrey500;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &.sm-max {
        padding: 8px;
      }

      .name-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
          margin-left: 15px;
        }
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-width: 1px;
      }
    }
  }

  .sync-block-container {
    .sync-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 85px;
      padding-bottom: 70px;
      flex-direction: column;

      .eventbrite-tick-icon {
        color: $eventbrite-orange;
      }

      .sync-animation {
        color: $eventbrite-orange;
      }

      .button-block {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 0 12px;

        .button {
          margin-top: 10px;
          width: 300px;
          max-width:100%;

          &.sync-btn {
            background-color: #fff0;
          }
        }
      }

      .last-sync-time {
        margin-top: 7px;
        font-size: 14px;
        color: #8E97A7;
        line-height: 25px;
      }

      .text-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        max-width: 100%;

        .header {
          color: $blueGrey800;
          margin-bottom: 3px;
        }

        .subtitle {
          font-size: 16px;
          color: #8E97A7;
          padding: 0 12px;
        }
      }
    }
  }
  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }
}
</style>
