var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isOpen}},[_c('ar-modal',{staticClass:"purchase-sms-credit-modal",attrs:{"is-open":_vm.isOpen,"hide-header":"","hide-footer":"","width":"400px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'purchase-sms-credit-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"sms-icon"},[_c('ar-icon',_vm._b({},'ar-icon',{
            name: 'sms',
            color: this.$arStyle.color.sms,
            width: '28px',
            height: '28px',
            wrapperStyle: {
              backgroundColor: '#FFF',
              border: ("1px solid " + (this.$arStyle.color.skyBlueGrey400)),
              boxShadow: ("0 0 8px  " + (this.$arStyle.color.skyBlueGrey400)),
              borderRadius: '50%',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          },false))],1),_vm._v(" "),_c('ar-text',{staticClass:"purchase-sms-credit-modal-title u-margin-bottom-5",attrs:{"text":"Purchase SMS Credit","multiple-lines":"","weight":"bold","align":"center","size":"md"}}),_vm._v(" "),_c('ar-text',{staticClass:"purchase-sms-credit-modal-message u-margin-bottom-6",attrs:{"size":"xs","text":"Add SMS credit to your account. Your total credit automatically renews every year, and can be cancelled at any time.","multiple-lines":"","line-height":"25px","align":"center"}}),_vm._v(" "),_c('ar-input',{attrs:{"placeholder":"$0","label":"Credit","autocomplete":"sms-credit","formatter":_vm.inputFormatter},model:{value:(_vm.creditValue),callback:function ($$v) {_vm.creditValue=$$v},expression:"creditValue"}}),_vm._v(" "),_c('ar-text',{staticClass:"purchase-sms-credit-modal-message u-margin-top-6",attrs:{"size":"xs","text":_vm.billedTextValue,"multiple-lines":"","weight":"bold","line-height":"20px","align":"center"}}),_vm._v(" "),_c('ar-text',{staticClass:"purchase-sms-credit-modal-message u-margin-bottom-1",attrs:{"size":"xs","text":_vm.renewalTextValue,"multiple-lines":"","allow-html":"","line-height":"20px","align":"center"}}),_vm._v(" "),_c('div',{staticClass:"purchase-button-wrapper"},[_c('ar-simple-button',{staticClass:"purchase-button",attrs:{"text":"Purchase","type":"gradient-purple","loading":_vm.isProcessingPayment,"disabled":_vm.disablePurchaseCreditButton},on:{"click":_vm.handlePurchaseClick}})],1),_vm._v(" "),_c('am2-payment-source-section',{staticClass:"u-margin-top-6",attrs:{"payment-source-puid":_vm.selectedPaymentSourcePuid,"add-payment-source-on":"expansion"},on:{"select":_vm.handlePaymentMethodSelect,"addPaymentSourceToggle":_vm.handlePaymentSourceToggle}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }