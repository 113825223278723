var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.withoutToolbar)?_c('div',{staticClass:"toolbar-wrapper"},[_c('div',{staticClass:"toolbar-icon-wrapper"},[_c('am2-apex-toolbar-icon',{staticClass:"zoom-in-toolbar-icon",attrs:{"fill":_vm.getFill('zoom-in'),"icon":"zoom-in","width":"17px","styles":{
        marginLeft: '5px',
      },"data-test-id":"zoom-in-toolbar-icon"},on:{"click":_vm.handleIconClick}}),_vm._v(" "),_c('am2-apex-toolbar-icon',{staticClass:"zoom-out-toolbar-icon",attrs:{"fill":_vm.getFill('zoom-out'),"icon":"zoom-out","width":"17px","styles":{
        marginLeft: '5px',
      },"data-test-id":"zoom-out-toolbar-icon"},on:{"click":_vm.handleIconClick}}),_vm._v(" "),_c('am2-apex-toolbar-icon',{staticClass:"select-toolbar-icon",attrs:{"fill":_vm.getFill('select'),"icon":"select","width":"20px","height":"15px","styles":{
        marginLeft: '5px',
      },"data-test-id":"select-toolbar-icon"},on:{"click":_vm.handleIconClick}}),_vm._v(" "),_c('am2-apex-toolbar-icon',{staticClass:"pan-toolbar-icon",class:_vm.isCategoryType ? 'disabled' : '',attrs:{"fill":_vm.getFill('pan'),"icon":"pan","width":"18px","styles":{
        marginLeft: '5px',
      },"data-test-id":"pan-toolbar-icon"},on:{"click":_vm.handleIconClick}}),_vm._v(" "),_c('am2-apex-toolbar-icon',{staticClass:"undo-toolbar-icon",attrs:{"fill":_vm.getFill('undo'),"icon":"undo","width":"20px","height":"15px","styles":{
        marginLeft: '5px',
      },"data-test-id":"undo-toolbar-icon"},on:{"click":_vm.handleIconClick}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }