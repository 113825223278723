<template>
    <div class="message-constraint">
      <div class="message-dropdown-container">
        <am2-link-button-dropdown
          :item-key="constraintCondition"
          :items="predicates"
          @select="(item) => handleConstraintDropdownSelect(item)"
        />
      </div>
      <div v-if="showItems"
           class="options-wrapper">
        <div class="loading-bubble-wrapper">
          <am2-loading-bubble v-if="isFetchingItems" />
        </div>
        <div class="options-content">
          <div
            v-for="(item, index) of displayedCandidateItems"
            :key="item.oid"
            class="checkbox-section"
          >
            <ar-checkbox
              ref="checkbox"
              class="checkbox"
              v-model="itemTickedMap[item.oid]"
              @change="handleCheckBoxChange(item.oid, item)"
            />
            <div class="checkbox-label-section" @click="handleCheckboxLabelClick(index)">
              <div class="checkbox-label-section">
                <ar-text
                  size="xs"
                  v-bind:text="item.name || 'Has been removed'"
                  v-tooltip.top="(item.name || 'Has been removed' | capitalize).length > 26 ? {
                    content: item.name || 'Has been removed' | capitalize
                  } : null"
                  :style="{
                    width: 'calc(100% - 60px)',
                    color: !item.name ? $arStyle.color.red500 : null,
                    margin: '0 8px 12px 0',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MessageConstraint',

    props: {
      constraintCondition: {
        type: String,
        default: 'all',
      },
      filterConditionName: {
        type: String,
        default: null,
      },
      constraintPredicates: {
        type: Array,
        default: () => [],
      },
      constraintValues: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        candidateItems: [],
        itemTickedMap: {},
        chosenItems: [],
        showItems: false,
        isFetchingItems: false,
      }
    },

    computed: {
      ...mapState({
        auth: state => state.auth,
      }),
      predicates() {
        return (this.constraintPredicates || []).map(item => {
          return {
            name: item.title,
            key: item.value,
          }
        });
      },

      displayedCandidateItems() {
        let candidateItems;
        if (this.isShowAll) {
          candidateItems = this.candidateItems;
        } else {
          candidateItems = this.candidateItems.slice(
            0,
            this.showLessCount,
          );
        }
        // Tasks don't store their name in item.name - we need to check meta and get it from there if it exists
        return candidateItems.map(item => {
          if (item.name === 'fan-message' && item?.meta?.messageBody?.subject) {
            item.name = item.meta.messageBody.subject;
          } else if (item.name === 'fan-message' && item?.meta?.messageBody) {
            item.name = item.meta.messageBody;
          }
          return item;
        });
      },

      fetchUrl() {
        let url = null;
        const { promoterOid } = this.auth.account;
        const resource = this.filterConditionName;

        switch (resource) {
          case "messagesMarkedAsSpam":
          case "messagesBounced":
            url = `/promoter/${promoterOid}/fan-message-search?$top=all&$skip=0&$provider=email`;
            break;
          case "messagesOpened":
          case "messagesNotOpened":
          case "messagesSent":
          case "messagesClicked":
          case "messageLinksClicked":
          case "messagesUndelivered":
            url = `/promoter/${promoterOid}/fan-message-search?$top=all&$skip=0`;
            break;
        }
        return url;
      },

      getTickedItemValues() {
        return Object.keys(this.itemTickedMap).map(value => parseInt(value, 10));
      },
      getTickedItems() {
        const tickedItemValues = this.getTickedItemValues;
        return [...this.candidateItems].filter(item => {
          return tickedItemValues.includes(item[this.valueKey]);
        });
      },
    },


    created() {
      this.fetchCandidateItems();
    },

    methods: {
      handleConstraintDropdownSelect(item) {
        const data = {
          key: item.key,
          values: item.key === 'all' ? [] : this.constraintValues,
        };
        this.showItems = item.key !== 'all';
        this.$emit('constraintUpdate', data);
      },

      handleCheckBoxChange(checkValue, item) {
        if (!this.itemTickedMap[checkValue]) {
          delete this.itemTickedMap[checkValue];
          this.chosenItems = this.chosenItems.filter(item => item.oid !== checkValue);
        } else {
          this.chosenItems = [...this.chosenItems, item];
        }
        const data = {
          key: this.constraintCondition,
          values: this.chosenItems.map(item => item.oid),
        };

        this.$emit('constraintUpdate', data);
      },

      handleCheckboxLabelClick(index) {
        this.$refs.checkbox[index].toggle();
      },

      async doAxiosGet(url) {
        return await this.$axios.get(url);
      },

      async fetchCandidateItems() {
        let items = [];
        const url = this.fetchUrl;

        if (!!url) {
          const { data: rows } = await this.doAxiosGet(url);
          items = rows;
        }

        const itemTickedMapOids = this.getTickedItemValues;
        const comingDatas = items.filter(item => {
          const valueInInteger = item.oid;
          if (!itemTickedMapOids.includes(valueInInteger)) {
            return item;
          }
          return null;
        });

        const tickedItems = this.getTickedItems;
        this.candidateItems = [...tickedItems, ...comingDatas];

      },
    }

  };
</script>

<style lang="scss" scoped>
  .message-constraint {
    width: 100%;
    display: flex;
    flex-direction: column;

    .message-dropdown-container {
      justify-content: flex-end;
      display: flex;
    }


    .options-wrapper {
      margin-top: 24px;
      position: relative;

      .loading-bubble-wrapper {
        display: flex;
        justify-content: center;
      }

      .options-content {
        transition: all 0.3s;
        opacity: 1;

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        .checkbox-section {
          display: flex;
          margin-bottom: 9px;
          align-items: flex-start;

          .checkbox {
            width: 20px;
          }

          .checkbox-label-section {
            cursor: pointer;
            width: calc(100% - 28px);
            margin-left: 8px;
            .checkbox-label-first-line {
              margin-top: 4px;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .show-all {
          display: inline-block;
          margin-top: 7px;
          font-size: 12px;
          line-height: 1em;
          color: $purple500;
          letter-spacing: 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
</style>
