<template>
  <section class="loading-section">
    <am2-loading-bubble
      v-if="type === 'bubble'"
      :style="iconStyle"
    />
    <am2-loading-spinner
      v-if="type === 'spinner'"
      :style="iconStyle"
    />
  </section>
</template>

<script>
export default {
  name: 'LoadingSection',

  props: {
    type: {
      type: String,
      default: 'bubble',
      validator: (value) =>
        ['bubble', 'spinner'].indexOf(value) !== -1,
    },
    iconStyle: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
