<template>
  <section
    class="ar-playground-section"
  >
    <ar-text
      size="xl"
      :text="component"
      weight="bold"
      :style="{
        color: $arStyle.color.purple500,
      }"
    />
    <ar-text
      v-if="description"
      size="sm"
      :text="description"
      multiple-lines
      allow-html
      :style="{
        marginTop: '14px',
      }"
    />
    <section class="tips-section">
      <ar-snackbar
        v-for="(tip, tipIdx) of tips"
        :key="tipIdx"
        type="warning"
        :message="tip"
        class="tip"
      />
    </section>
    <ar-divider
      :style="{
        margin: '10px 0 20px',
      }"
    />
    <!-- Attributes Schema -->
    <ar-text
      size="md"
      text="Props"
      weight="bold"
    />
    <ar-checkbox
      v-model="expandAttributeSchema"
      label="Show props"
    />
    <am2-card-container
      v-if="expandAttributeSchema"
      class="schema-items-wrapper"
      :style="{
        marginTop: '20px',
      }"
    >
      <section
        v-for="(prop, idx) of attributeSchema"
        :key="idx"
      >
        <div class="schema-item">
          <section class="schema-item-attr">
            <ar-text
              size="xxxs"
              text="Name"
            />
            <ar-text
              class="value"
              size="xs"
              :text="prop.name"
              weight="bold"
              allow-html
              :style="{
                color: $arStyle.color.red500,
              }"
            />
          </section>
          <section class="schema-item-attr">
            <ar-text
              size="xxxs"
              text="Type"
            />
            <ar-text
              class="value"
              size="xs"
              :text="prop.type"
              weight="bold"
            />
          </section>
          <section class="schema-item-attr">
            <ar-text
              size="xxxs"
              text="Description"
            />
            <ar-text
              class="value"
              size="xs"
              :text="prop.description"
              allow-html
            />
          </section>
          <section class="schema-item-attr">
            <ar-text
              size="xxxs"
              text="Required"
            />
            <ar-text
              class="value"
              size="xs"
              :text="prop.required ? 'true' : 'false'"
              allow-html
              :style="{
                color: prop.required ? $arStyle.color.red500 : null,
              }"
            />
          </section>
        </div>
        <ar-divider
          v-if="idx !== attributeSchema.length - 1"
          class="u-margin-bottom-5"
        />
      </section>
    </am2-card-container>
    <!-- Listeners Schema -->
    <ar-text
      size="md"
      text="Events"
      weight="bold"
      :style="{
        marginTop: '20px',
      }"
    />
    <ar-checkbox
      v-model="expandListenerSchema"
      label="Show events"
    />
    <am2-card-container
      v-if="expandListenerSchema"
      :style="{
        padding: '30px',
        marginTop: '20px',
      }"
    >
      <ar-text
        v-if="listenerSchema.length === 0"
        size="xs"
        text="This component has no events"
      />
      <section
        v-for="(listener, idx) of listenerSchema"
        :key="idx"
      >
        <ar-text
          size="md"
          :text="`@${listener.name}`"
          weight="bold"
          :style="{
            color: $arStyle.color.red500,
          }"
        />
        <ar-text
          size="xs"
          :text="listener.description"
          allow-html
          :style="{
            marginTop: '7px',
          }"
        />
        <ar-text
          size="lg"
          :text="generateListenerFunctionCopy(listener.parameters)"
          weight="bold"
          :style="{
            marginTop: '20px',
          }"
        />
        <am2-card-container
          class="schema-items-wrapper"
          :style="{
            marginTop: '10px',
          }"
        >
          <ar-text
            v-if="!listener.parameters"
            size="xs"
            text="This event has no params"
          />
          <section
            v-for="(parameter, idx) of listener.parameters"
            :key="idx"
          >
            <div class="schema-item">
              <section class="schema-item-attr">
                <ar-text
                  size="xxxs"
                  text="Name"
                />
                <ar-text
                  class="value"
                  size="xs"
                  :text="parameter.name"
                  weight="bold"
                  :style="{
                    color: $arStyle.color.red500,
                  }"
                />
              </section>
              <section class="schema-item-attr">
                <ar-text
                  size="xxxs"
                  text="Type"
                />
                <ar-text
                  class="value"
                  size="xs"
                  :text="parameter.type"
                  weight="bold"
                />
              </section>
              <section class="schema-item-attr">
                <ar-text
                  size="xxxs"
                  text="Description"
                />
                <ar-text
                  class="value"
                  size="xs"
                  :text="parameter.description"
                  allow-html
                />
              </section>
            </div>
            <ar-divider
              v-if="idx !== listener.parameters.length - 1"
              class="u-margin-bottom-5"
            />
          </section>
        </am2-card-container>
        <ar-divider
          v-if="idx !== listenerSchema.length - 1"
          class="u-margin-bottom-5"
        />
      </section>
    </am2-card-container>
    <ar-text
      size="md"
      text="Examples"
      weight="bold"
      :style="{
        marginTop: '30px',
      }"
    />
    <div
      v-for="(demoGroup, idx) of demoGroups"
      :key="idx"
      class="example-section"
      :style="{
        marginTop: '20px',
      }"
    >
      <ar-text
        size="sm"
        :text="demoGroup.title"
        allow-html
        weight="bold"
      />
      <ar-text
        v-if="demoGroup.description"
        size="xs"
        :text="demoGroup.description"
        allow-html
        :style="{
          marginTop: '4px',
        }"
      />
      <!-- Demo Section -->
      <am2-card-container
        v-if="demoGroup.tabs && demoGroup.tabs.length > 1"
        :style="{
          padding: '12px 32px 0',
          marginTop: '24px',
        }"
      >
        <am2-tabs
          class="tabs"
          :items="demoGroup.tabs"
          :selected-tab-index="selectedTab"
          @select="changeSelectedTab"
        />
      </am2-card-container>
      <am2-card-container
        :style="{
          marginTop: '10px',
        }"
        class="demo-section"
      >
        <!-- Demo Control Panel -->
        <section
          class="demo-section-control"
          @click="handleDisplayExampleClick(idx)"
        >
          <ar-text
            size="sm"
            text="View source code"
            class="demo-section-control-title"
          />
          <ar-icon
            name="search"
            height="16px"
            class="demo-section-control-title-icon"
          />
        </section>
        <section
          :class="['code-section', exampleDisplayMap[idx] && 'show']"
        >
          <section class="code">{{ generateCode(demoGroup.demos) }}</section>
        </section>
        <!-- List of demos -->
        <section
          :class="['demo', demoGroup.type || 'white']"
          :style="{
            flexFlow: demoGroup.flow === 'column' ? 'column' : 'wrap',
          }"
        >
          <component
            v-for="(demo, demoIdx) of demoGroup.demos"
            :key="demoIdx"
            class="component"
            :is="component"
            v-bind="demo.attributes"
            v-on="demo.listeners"
            v-tooltip="{
              content: demo.description,
            }"
          />
        </section>
      </am2-card-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PlaygroundSection',

  props: {
    component: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    tips: {
      type: Array,
      default: () => [],
    },
    attributeSchema: {
      type: Array,
      default: () => [],
    },
    listenerSchema: {
      type: Array,
      default: () => [],
    },
    demoGroups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      exampleDisplayMap: {},
      expandAttributeSchema: false,
      expandListenerSchema: false,
      selectedTab: null,
    };
  },

  methods: {
    generateJsonDataCopy(object, indentDistance) {
      const placeholder = '____PLACEHOLDER____';
      const fns = [];
      let json = JSON.stringify(
        object,
        (key, value) => {
          if (typeof value === 'function') {
            fns.push(value);
            return placeholder;
          }
          return value;
        },
        2,
      );
      json = json.replace(new RegExp(`"${placeholder}"`, 'g'), function(_) {
        return fns.shift();
      });
      return json.replace(/\n/g, `\n${' '.repeat(indentDistance)}`);
    },
    generateListenerFunctionCopy(parameters) {
      let paramsString = '';
      if (parameters) {
        parameters.forEach(({ name }, idx) => {
          paramsString += `${name}, `;
        });
      }
      return `(${paramsString}) => { ... }`;
    },
    generateCode(demos) {
      let code = '';
      demos.forEach((demo, demoIdx) => {
        if (demo.description) {
          code += `// ${demo.description}\n`;
        }
        code +=`<${this.component}\n`;

        Object.keys(demo.attributes).forEach(key => {
          const value = demo.attributes[key];
          if (typeof value === 'object') {
            code += `  ${key}="${this.generateJsonDataCopy(value, 2)}"\n`;
          } else {
            code += `  ${key}="${value}"\n`;
          }
        });
        if (demo.listeners) {
          Object.keys(demo.listeners).forEach(key => {
            const listener = demo.listeners[key];
            code += `  @${key}="${listener.toString()}"\n`;
          });
        }
        code += '/>\n'

        if (demoIdx !== demos.length -1) {
          code += '\n';
        }
      });
      return code;
    },
    handleDisplayExampleClick(idx) {
      this.$set(this.exampleDisplayMap, idx, !this.exampleDisplayMap[idx]);
    },
    changeSelectedTab(tab, index) {
      this.selectedTab = index;
      this.$emit('tabChange', tab, index)
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-playground-section {
  border-left: 2px solid $purple500;
  padding-left: 20px;
  margin-bottom: 100px;

  .description {
    margin-top: 10px;
  }

  .tips-section {
    display: flex;
    flex-flow: column;

    .tip {
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
    }
  }

  .schema-items-wrapper {
    padding: 30px 30px 10px;

    .schema-item {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;

      .schema-item-attr {
        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        margin-bottom: 20px;

        .value {
          margin-top: 7px;
        }
      }
    }
  }

  .example-section {
    .demo-section {
      .demo-section-control {
        display: flex;
        justify-content: center;
        padding: 15px 30px;
        background: $blueGrey600;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: $purple500;
        }

        .demo-section-control-title {
          color: white;
          margin-right: 10px;
        }

        .demo-section-control-title-icon {
          color: white;
        }
      }

      .code-section {
        max-height: 0;
        transition: max-height 0.3s;
        overflow: hidden;

        &.show {
          max-height: 500px;
          overflow: auto;
        }

        .code {
          background: $blueGrey800;
          color: white;
          white-space: pre;
          overflow: auto;
          padding: 30px;
          font-family: Menlo, Monaco, 'Courier New', monospace;
        }
      }

      .demo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        &.white {
          background: white;
        }

        &.green {
          background: $green200;
        }

        &.black {
          background: black;
        }

        .component {
          margin: 10px;
        }
      }
    }
  }
}
</style>
