<template>
  <section
    :class="[
      'campaign-edit-registrations',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    ]"
  >
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 2"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Registration details"
      :style="{marginBottom: '38.5px'}"
    />

    <p class="large">
      {{copy}}
    </p>

    <RegistrationFieldsDropdown
      v-model="campaignViewModel.registrationFields"
      :campaignType="editCampaign.type"
      :campaignUnselectedFields = "campaignViewModel.unselectedFields"
      :isFreeCampaign="isFreeCampaign"
      @updateUnselectedFields="updateUnselectedFields"
    />

    <am2-field
      name="advanced-options"
      class="advanced-options"
      label="Advanced options"
    >
      <transition-group name="overlay-fade" tag="div">
        <div
          v-for="(provider, key) in campaignViewModel.providers"
          :key="`provider-${key}`"
        >
          <ar-checkbox
            v-model="campaignViewModel.providers[key]"
            :icon-name="key"
            :icon-props="{ color: colorMap[key] }"
            :comment="campaignViewModel.mandatoryProviders[key] ? 'Mandatory' : null"
            :disabled="campaignViewModel.mandatoryProviders[key]"
            :label="`Enable register with ${key}`"
            align="right"
            :style="{ width: '100%', height: '52px' }"
          />
          <ar-divider
            v-if="key !== 'spotify'"
          />
        </div>
      </transition-group>
    </am2-field>

    <am2-field
      name="payment-options"
      class="payment-options"
      label="Session Validation"
      v-if="!!campaignTypeSupportsGating"
      :style="{
          paddingBottom: payment.required ? '1rem' : null
      }"
    >
      <div>
        <ar-text
          size="xs"
          multiple-lines
          :text="`Session validation prevents users from sharing login details to your ${editCampaign.type} campaign. It regularly checks to ensure that each logged-in user is unique and requires that they login using a unique login link. `"
        />
        <ar-checkbox
          v-model="campaignViewModel.gatedSessions"
          icon-name="lock"
          label="Enable Session Validation"
          align="right"
          :style="{
            width: '100%',
            height: '52px',
          }"
        />
      </div>
    </am2-field>


    <am2-field
      name="payment-options"
      class="payment-options"
      label="Payment Options"
      v-if="!!campaignTypePaymentRequired"
      :style="{
          paddingBottom: payment.required ? '2rem' : null
      }"
    >
      <div>
        <ar-checkbox
          v-model="payment.required"
          icon-name="ticket"
          label="Require payment to access"
          align="right"
          :disabled="campaignTypePaymentRequired === 'required'"
          :disble-untick="campaignTypePaymentRequired === 'required'"
          :style="{
            width: '100%',
            height: '52px',
          }"
        />


        <!-- If multiple products are to be shown, then show this section -->
        <div class="payment-products" v-if="payment.required && allowMultipleProducts">
          <am2-field
            name="product-currencies"
            class="u-margin-top-6"
            label="Currency:">
            <ar-simple-select
              enable-filter
              class="selector"
              :items="currencyOptions"
              placeholder="Currency"
              data-vv-name="priceCurrency"
              data-vv-as="currency"
              :default-select-index="currencyCodeIndex"
              @select="selectCurrency"
            />
          </am2-field>
          <am2-field
            name="products"
            class="u-margin-top-6"
            label="Products:">
            <div
              v-for="(product, index) in payment.pricingModel.products"
              :key="`${product.key}_${index + 1}`"
              :id="`tier_${index+1}`"
              class="payment-products__product"
            >
              <am2-field
                :name="`product_${index}`"
                :label="`Product ${index + 1}`">
                <ar-input
                  v-validate="{
                    required: true,
                  }"
                  v-model="product.name"
                  :data-vv-name="`productName${index}`"
                  data-vv-as="name"
                  label="Name"
                  autocomplete="product-name"
                  class="input--block u-margin-y-2"
                  type="text"
                />
                <ar-state-message
                  v-if="veeErrors.has(`productName${index}`)"
                  :style="{ marginBottom: '8px' }"
                  type="error"
                  :text="veeErrors.first(`productName${index}`)"
                />
                <ar-input
                  v-validate="{
                    required: true,
                  }"
                  v-model="product.description"
                  :data-vv-name="`productDescription${index}`"
                  data-vv-as="description"
                  label="Description"
                  autocomplete="product-description"
                  class="input--block u-margin-y-2"
                  type="text"
                />
                <ar-state-message
                  v-if="veeErrors.has(`productDescription${index}`)"
                  :style="{ marginBottom: '8px' }"
                  type="error"
                  :text="veeErrors.first(`productDescription${index}`)"
                />
                <ar-input
                  v-validate="{
                    required: true,
                    decimal: currencyDigitsAllowed > 0 ? currencyDigitsAllowed : false,
                    integer: currencyDigitsAllowed === 0,
                    min_value: minimumRequiredPrice,
                    max_value: 99999999,
                  }"
                  v-model="product.amount"
                  :data-vv-name="`productAmount${index}`"
                  data-vv-as="price"
                  label="Price"
                  :step="currencyInputStep"
                  class="input--block u-margin-y-2"
                  type="number"
                />
                <ar-state-message
                  v-if="veeErrors.has(`productAmount${index}`)"
                  :style="{ marginBottom: '8px' }"
                  type="error"
                  :text="veeErrors.first(`productAmount${index}`)"
                />

                <ar-input
                  v-validate="{
                    required: true,
                    decimal: false,
                    integer: true,
                    min_value: 1,
                    max_value: 99,
                  }"
                  v-model="product.maxQuantity"
                  :data-vv-name="`productQuantity${index}`"
                  data-vv-as="max quantity"
                  label="Max Quantity"
                  class="input--block u-margin-y-2"
                  type="number"
                />
                <ar-state-message
                  v-if="veeErrors.has(`productQuantity${index}`)"
                  :style="{ marginBottom: '8px' }"
                  type="error"
                  :text="veeErrors.first(`productQuantity${index}`)"
                />

                <ar-simple-button
                  v-if="payment.pricingModel.products.length > 0"
                  text="Remove product"
                  class="add-product u-margin-top-1 u-margin-bottom-2"
                  type="grey"
                  icon-name="cross"
                  icon-distance="20px"
                  @click.native="removeProduct(index)"
                />
              </am2-field>
            </div>
            <ar-simple-button
              v-if="payment.pricingModel.products.length > 0"
              text="Add another product"
              class="add-product u-margin-top-3 u-margin-bottom-2"
              outlined
              icon-name="circle-plus"
              icon-distance="20px"
              @click.native="addProduct()"
            />
            <ar-simple-button
              v-else
              text="Add your first product"
              class="add-product u-margin-bottom-2"
              outlined
              icon-name="circle-plus"
              icon-distance="20px"
              @click.native="addProduct()"
            />
          </am2-field>

        </div>

        <!-- Only show the selector if the campaign type allows both variable and static prices -->
        <div class="payment-type" v-if="payment.required && allowVariablePrices && allowFixedPrices">
          <am2-field
            name="payment-type"
            v-if="payment.pricingModel.type"
            label="Payment Type:"
            class="u-margin-y-6">
            <div class="u-display-flex">
              <ar-checkbox
                :value="payment.pricingModel.type"
                ticked-value="fixed"
                label="Fixed Price"
                class="u-display-block u-margin-right-6"
                v-tooltip.top="{
                content: 'Pick the price which fans need to pay'
              }"
                :disable-untick="true"
                @input="(value) => { payment.pricingModel.type = value }"
              />
              <ar-checkbox
                :value="payment.pricingModel.type"
                ticked-value="variable"
                label="Variable Price"
                class="u-display-block"
                v-tooltip.top="{
                content: 'Allow fans to pay what they like, within your minimum and maximum bounds'
              }"
                :disable-untick="true"
                @input="(value) => { payment.pricingModel.type = value }"
              />
            </div>
          </am2-field>
        </div>

        <!-- If price is static and there's only one product, then show this section -->
        <div class="payment-amount-and-currency-static" v-if="payment.required && payment.pricingModel.type === 'fixed' && !allowMultipleProducts">
          <ar-simple-select
            enable-filter
            class="selector"
            :items="currencyOptions"
            placeholder="Currency"
            data-vv-name="priceCurrency"
            data-vv-as="currency"
            :default-select-index="currencyCodeIndex"
            @select="selectCurrency"
          />
          <ar-input
            id="payment-options-amount"
            v-validate="{
              required: !!payment.required && payment.pricingModel.type === 'fixed',
              decimal: currencyDigitsAllowed > 0 ? currencyDigitsAllowed : false,
              integer: currencyDigitsAllowed === 0,
              min_value: minimumRequiredPrice,
              max_value: 99999999,
            }"
            v-model="payment.pricingModel.amount"
            data-vv-name="priceAmount"
            data-vv-as="ticket price"
            label="Price"
            :step="currencyInputStep"
            class="input--block"
            type="number"
          />
        </div>

        <!-- If price is variable and there's only one product, then show this section -->
        <div class="payment-amount-and-currency-variable" v-if="payment.required && payment.pricingModel.type === 'variable' && !allowMultipleProducts">
          <ar-simple-select
            enable-filter
            class="selector"
            :items="currencyOptions"
            placeholder="Currency"
            data-vv-name="priceCurrency"
            data-vv-as="currency"
            :default-select-index="currencyCodeIndex"
            @select="selectCurrency"
          />
          <ar-input
            id="payment-options-amount-min"
            v-validate="{
              required: !!payment.required && payment.pricingModel.type === 'variable',
              decimal: currencyDigitsAllowed > 0 ? currencyDigitsAllowed : false,
              integer: currencyDigitsAllowed === 0,
              min_value: minimumRequiredPrice,
              max_value: 99999999,
            }"
            v-model="payment.pricingModel.minAmount"
            data-vv-name="priceAmountMin"
            data-vv-as="minimum price"
            label="Min"
            :step="currencyInputStep"
            class="input--block u-margin-right-1"
            type="number"
          />


        </div>

        <ar-state-message
          v-if="veeErrors.has('priceCurrency')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('priceCurrency')"
        />
        <ar-state-message
          v-if="veeErrors.has('priceAmount')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('priceAmount')"
        />
        <ar-state-message
          v-if="veeErrors.has('priceAmountMin')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('priceAmountMin')"
        />

        <!-- Select where the payment section will live in the flow -->
        <div class="payment-position" v-if="payment.required">
          <am2-field
            name="payment-position"
            v-if="paymentPositionValue"
            label="Request payment:"
            class="u-margin-y-6">

            <div class="u-display-flex">
              <ar-checkbox
                :value="paymentPositionValue"
                ticked-value="registration"
                label="After registration page"
                class="u-display-block u-margin-right-6"
                :disable-untick="true"
                @input="(value) => { paymentPositionValue = value }"
              />
              <ar-checkbox
                :value="paymentPositionValue"
                ticked-value="success"
                label="On the last page"
                class="u-display-block"
                :disable-untick="true"
                @input="(value) => { paymentPositionValue = value }"
              />
            </div>
          </am2-field>
        </div>
      </div>
    </am2-field>
  </section>
</template>

<script>
import merge from 'deepmerge';

import RegistrationFieldsDropdown from './components/registration-fields-dropdown';
import { copyGenerator, campaignTypePaymentRequired, campaignTypePaymentType, campaignTypeSupportsGating } from '@/utils/campaign';
import { generateHash } from '@/utils/helpers'
import { CURRENCY_CODES } from '~/utils/constants';

export default {
  name: 'EditRegistrations',
  components: {
    RegistrationFieldsDropdown,
  },
  props: {
    editCampaign: {
      type: Object
    },
    editSteps: {
      type: Array,
      default: []
    },
    setEditCampaign: {
      type: Function
    }
  },
  data() {
    return {
      campaignViewModel: {
        registrationFields: [],
        unselectedFields: [], // Internal
        providers: {
          email: true,
          // facebook: true,
          spotify: true,
          // messenger: false
        },
        mandatoryProviders: {
          email: true,
          // facebook: false,
          spotify: false,
          // messenger: false,
        },
        hideExtraFields: true,
        presentation: {
          flow : []
        },
        gatedSessions: false,
      },
      payment: {
        required: false,
        currencyCode: CURRENCY_CODES[0].code,
        pricingModel: {},
      },
      paymentPositionValue: 'registration',
      currencyOptions: CURRENCY_CODES.map(c => ({
        name: c.code,
        symbol_native:
        c.symbol_native,
        symbol: c.symbol,
        decimal_digits: c.decimal_digits,
        minimum: c.minimum,
      })),
      colorMap: {
        email: this.$arStyle.color.email,
        facebook: this.$arStyle.color.facebook,
        spotify: this.$arStyle.color.spotify,
        messenger: this.$arStyle.color.messenger,
      }
    };
  },

  computed: {
    isFreeCampaign() {
      return this.editSteps.length === 2
    },
    campaignTypePaymentRequired() {
      return campaignTypePaymentRequired(this.editCampaign.type);
    },
    campaignTypeSupportsGating() {
      return campaignTypeSupportsGating(this.editCampaign.type);
    },
    copy() {
      return copyGenerator('registrations', this.editCampaign.type);
    },
    currencyCodeIndex() {
      if (this.payment.currencyCode === null) { return 0; }
      const currencyCode = this.payment.currencyCode;
      const index = this.currencyOptions.findIndex(c => c.name === currencyCode);
      return index === -1 ? null : index;
    },
    currencyDigitsAllowed() {
      if (this.payment.currencyCode === null) { return 0; }
      const currencyCode = this.payment.currencyCode;
      const currencyObject = this.currencyOptions.find(c => c.name === currencyCode);
      return currencyObject ? currencyObject.decimal_digits : 2;
    },
    currencyInputStep() {
      if (this.payment.currencyCode === null) { return 0; }
      const currencyCode = this.payment.currencyCode;
      const currencyObject = this.currencyOptions.find(c => c.name === currencyCode);
      let stepSize = 1;
      if (currencyObject && currencyObject.decimal_digits) {
        stepSize = stepSize / Math.pow(10, currencyObject.decimal_digits);
      }
      return stepSize;
    },
    minimumRequiredPrice() {
      if (this.payment.currencyCode === null) { return 0; }
      const currencyCode = this.payment.currencyCode;
      const currencyObject = this.currencyOptions.find(c => c.name === currencyCode);
      return currencyObject && currencyObject.minimum ? currencyObject.minimum : 1;
    },
    allowVariablePrices() {
      return campaignTypePaymentType(this.editCampaign.type).some( item => item === 'variable');
    },
    allowMultipleProducts() {
      return campaignTypePaymentType(this.editCampaign.type).some( item => item === 'cart');
    },
    allowFixedPrices() {
      return campaignTypePaymentType(this.editCampaign.type).some( item => item === 'fixed');
    },
  },

  mounted() {
    this.initPaymentModel();
    this.initializeCampaignViewModel();
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        const { registrationFields, providers, presentation, gatedSessions } = value

        const registrations = {
          fields: registrationFields,
          providers: providers,
        }

        const settings = {
          gatedSessions,
        }

        this.setEditCampaign({ registrations, presentation, settings });
      },
      deep: true
    },
    paymentPositionValue: {
      handler(value) {
        this.togglePaymentInFlow(value);
      },
      immediate: true,
    },
    payment: {
      handler(value) {
        let { required, currencyCode, pricingModel } = value;

        const newPricingModel = {};

        // First, lets determine whether this currency is decimal-based
        if (!currencyCode) currencyCode = 'USD';
        const currencyObject = this.currencyOptions.find(c => c.name === currencyCode);
        const decimalPlaces = currencyObject ? currencyObject.decimal_digits : 2;

        // We need to clean to data from the user.
        // They input data as either INTs or FLOATs (eg $15 or $15.00)
        // The server stores the values as INTs
        // We need to convert all INTs in decimal-denoted currencies into their cent equivalent
        // We need to convert all FLOATs into INTs
        // And we need to account for the space cadets who like to write $15.50 as $15.5

        // Get a list of products, if defined
        const products = pricingModel && pricingModel.products ? pricingModel.products.map( product => {
          return {
            name: product.name,
            description: product.description,
            amount: product.amount,
            maxQuantity: parseInt(product.maxQuantity),
            key: product.key,
          }
        }) : [];

        // Internal function which normalizes the payment data so that its always in the correct format
        const normalizeAmount = (amount) => {
          let fixedAmount = 0;
          const amountHasDecimal = amount && amount.toString().indexOf('.') > -1;
          // Case 1 - currency is decimal, but no decimal included in price/amount
          if(decimalPlaces > 0 && !amountHasDecimal) {
            const factor = Math.pow(10, decimalPlaces);
            fixedAmount = parseInt(amount) * factor;
          }
          // Case 2 - currency is decimal, and decimal has been included
          if(decimalPlaces > 0 && amountHasDecimal) {
            const parsedAmount = parseFloat(amount).toFixed(decimalPlaces);
            fixedAmount = parseInt(parsedAmount.toString().replace('.', ''));
          }
          // Case 3 - currency is not decimal, and should have decimals removed
          if(decimalPlaces === 0) {
            fixedAmount = parseFloat(amount).toFixed(0);
          }
          return fixedAmount;
        };

        let defaultPaymentType = null;

        // Run the amounts through the normalizer and set the default payment type
        if (pricingModel && this.allowFixedPrices) {
          newPricingModel.amount = normalizeAmount(pricingModel.amount);
          defaultPaymentType = 'fixed';
        }
        if (pricingModel && this.allowVariablePrices) {
          newPricingModel.minAmount = normalizeAmount(pricingModel.minAmount);
          defaultPaymentType = 'variable';
        }
        if (pricingModel && this.allowMultipleProducts) {
          defaultPaymentType = 'cart';
          products.forEach( (product, idx) => {
            products[idx].amount = normalizeAmount(product.amount);
          });
          newPricingModel.products = products;
        }


        // Finally, if its been disabled, then make sure to remove payment from the flow!
        const flow = this.editCampaign.presentation.flow || [];
        if(!required) {
          this.togglePaymentInFlow(null);
        } else if (!flow.includes('payment')) { // Otherwise, if it hasn't been added to flow yet, add it in
          this.togglePaymentInFlow(this.paymentPositionValue);
        }


        const settings = {
          payment: {
            required,
            currencyCode,
            pricingModel: {
              type: pricingModel.type || defaultPaymentType,
              ...newPricingModel,
            }
        }};

        this.setEditCampaign({ settings });
      },
      deep: true
    }
  },

  methods: {
    initPaymentModel() {
      const paymentFixed = {};

      const payment = this.editCampaign.settings.payment || {};
      const currencyCode = payment.currencyCode || 'USD';
      const currencyObject = currencyCode ? this.currencyOptions.find(c => c.name === currencyCode) : null;

      paymentFixed.required = this.campaignTypePaymentRequired === 'required' ? true : payment.required;
      paymentFixed.currencyCode = currencyCode;
      paymentFixed.pricingModel = {};

      const fixValue = (value) => {
        if (currencyObject && currencyObject.decimal_digits && currencyObject.decimal_digits > 0) {
          return (value / Math.pow(10, currencyObject.decimal_digits)).toFixed(currencyObject.decimal_digits);
        } else {
          return value;
        }
      };

      const pricingModel = payment.pricingModel || {};

      if(this.allowFixedPrices) {
        paymentFixed.pricingModel.type = pricingModel.type || 'fixed';
        paymentFixed.pricingModel.amount = fixValue(pricingModel.amount) || 0;
      }

      if(this.allowVariablePrices) {
        paymentFixed.pricingModel.type = pricingModel.type || 'variable';
        paymentFixed.pricingModel.minAmount = fixValue(pricingModel.minAmount) || 0;
      }

      if(this.allowMultipleProducts) {
        paymentFixed.pricingModel.type = pricingModel.type || 'cart';
        paymentFixed.pricingModel.products = pricingModel.products || [];

        paymentFixed.pricingModel.products.forEach( (product, idx) => {
          pricingModel.products[idx].amount = fixValue(product.amount);
        });
      }

      this.payment = merge(this.payment, paymentFixed);
    },

    getCustomFields() {
      const customRegistrationOptions = this.editCampaign.registrations.customRegistrationOptions;
      const customFields = this.editCampaign.registrations.customFields || [];

      // A.R. 2022-07-08
      // customFields and customRegistrationOptions have already been removed inside registrations but
      // for backwards compatibility we are still loading them
      if (Array.isArray(customRegistrationOptions))
        // if the promoter has modified the custom fields, use them
        return customRegistrationOptions;
      else
        // otherwise, on the initial load use the custom fields already associated with the campaign
        return customFields;
    },

    getPredefinedRegistrationFields(fields, isFieldsObject) {
      const requiresAddress =  this.editCampaign.type === 'preorder'; // TODO - Lets find a better place to put this
      return [
        {
          name: 'Email address',
          key: 'emailAddress',
          enabled: true,
          predefined: true,
        },
        {
          name: 'First name',
          key: 'firstName',
          // default true, if fields.firstName === true then selected is true, if fields === false then selected is false
          // https://stackoverflow.com/questions/15464169/how-to-set-default-boolean-values-in-javascript
          enabled: (isFieldsObject ? fields.firstName !== false || requiresAddress : true) || true, // default true
          predefined: true,
        },
        {
          name: 'Last name',
          key: 'lastName',
          enabled: (isFieldsObject ? fields.lastName !== false || requiresAddress : true) || true, // default true
          predefined: true,
        },
        {
          name: 'Mobile number',
          key: 'mobileNumber',
          enabled: (isFieldsObject ? fields.mobileNumber !== false : true) || false, // default true
          predefined: true,
        },
        {
          name: 'Date of Birth',
          key: 'dob',
          enabled: (isFieldsObject && (fields.dob ? fields.dob : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          enabled: (isFieldsObject && (fields.gender ? fields.gender : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'Street Address',
          key: 'streetAddress',
          enabled: (isFieldsObject && (fields.streetAddress || requiresAddress ? fields.streetAddress || requiresAddress : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'Zip/post code',
          key: 'postcode',
          enabled: (isFieldsObject && (fields.postcode || requiresAddress ? fields.postcode || requiresAddress : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'City',
          key: 'city',
          enabled: (isFieldsObject && (fields.city || requiresAddress ? fields.city || requiresAddress : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'State',
          key: 'state',
          enabled: (isFieldsObject && (fields.state || requiresAddress ? fields.state || requiresAddress : false)) || false, // default false
          predefined: true,
        },
        {
          name: 'Country',
          key: 'country',
          enabled: (isFieldsObject && (fields.country || requiresAddress ? fields.country || requiresAddress : false)) || false, // default false
          predefined: true,
        },
      ]
    },

    setCondensedRegistrationAndCustomFields() {
      const fields = this.editCampaign.registrations.fields || [];
      const isFieldsObject = !Array.isArray(fields)

      const predefinedRegistrationFields = this.getPredefinedRegistrationFields(fields, isFieldsObject)

      // Fields as array is not defined yet
      if (isFieldsObject || (!isFieldsObject && fields.length === 0)) {
        this.setRegistrationFields(predefinedRegistrationFields)
      } else {
        this.campaignViewModel.registrationFields = fields;

        // Filter out unselected fields based from the pre-defined registration fields
        this.campaignViewModel.unselectedFields = predefinedRegistrationFields.filter((registrationField) => {
          const selectedField = this.campaignViewModel.registrationFields.find((selectedField) => {
            return selectedField.key === registrationField.key && !!registrationField.predefined
          })

          return !selectedField?.enabled
        })
      }
    },

    setRegistrationFields(predefinedRegistrationFields) {
      this.campaignViewModel.registrationFields = predefinedRegistrationFields.filter((registrationField) => {
        return registrationField.enabled
      })

      this.campaignViewModel.unselectedFields = predefinedRegistrationFields.filter((registrationField) => {
        return !registrationField.enabled && !!registrationField.predefined
      })

      const finalCustomFields = this.getCustomFields()

      if (finalCustomFields?.length > 0) {
        this.campaignViewModel.registrationFields = this.campaignViewModel.registrationFields.concat(finalCustomFields.map(customField => {
          return {
            ...customField,
            predefined: false,
            key: customField.name,
          }
        }))
      }
    },

    setPaymentFlow() {
      const flow = this.editCampaign.presentation.flow || [];
      const gatedSessions = this.editCampaign.settings.gatedSessions || false;

      this.campaignViewModel.presentation.flow = flow;
      this.campaignViewModel.gatedSessions = gatedSessions;

      // If payment is enabled
          // If the last item is 'payment', then set paymentPositionValue to 'success'
          // Else set the position to 'register'
      if (!this.payment.required) return;
      let paymentPositionValue = null;
      if (flow.length > 0 && flow[flow.length - 1] === 'payment') {
        paymentPositionValue = 'success';
      } else {
        paymentPositionValue = 'registration';
      }

      const cleanFlow = (flow || []).filter( item => item !== 'payment' && item !== 'donate' && item !== 'product');
      let confirmationPage = null;
      if (this.editCampaign.type === 'donate') {
        confirmationPage = 'donate';
      } else if (this.editCampaign.type === 'preorder') {
        confirmationPage = 'product';
      }
      if(paymentPositionValue === 'success') {
        if (confirmationPage) cleanFlow.push(confirmationPage);
        cleanFlow.push('payment');
      } else {
        if(confirmationPage) {
          cleanFlow.splice(1, 0, confirmationPage, 'payment');
        } else {
          cleanFlow.splice(1, 0, 'payment');
        }
      }

      this.campaignViewModel.presentation.flow = cleanFlow;
      this.paymentPositionValue = paymentPositionValue;

    },

    setProviders() {
      const providers = this.editCampaign.registrations.providers || {};

      // Temporarily remove facebook since facebook updated it's terms that we're not allowed to collect secret info
      delete providers.facebook;

      this.campaignViewModel.providers = merge(this.campaignViewModel.providers, providers);
    },

    initializeCampaignViewModel() {
      this.setCondensedRegistrationAndCustomFields()
      this.setProviders()
      this.setPaymentFlow()
    },

    togglePaymentInFlow(position) {
      if (!this.campaignViewModel) return;

      const cleanFlow = (this.campaignViewModel.presentation.flow || []).filter( item => item !== 'payment' && item !== 'donate' && item !== 'product');

      // Firstly, lets make sure payment is removed from the flow if flow is unpopulated, if payment is not required, or if position is not set
      if (cleanFlow.length < 1) return;
      if (!this.payment.required || !position) {
        this.campaignViewModel.presentation.flow = cleanFlow;
        return;
      }

      let confirmationPage = null;
      if (this.editCampaign.type === 'donate') {
        confirmationPage = 'donate';
      } else if (this.editCampaign.type === 'preorder') {
        confirmationPage = 'product';
      }
      if(position === 'success') {
        if (confirmationPage) cleanFlow.push(confirmationPage);
        cleanFlow.push('payment');
      } else {
        if(confirmationPage) {
          cleanFlow.splice(1, 0, confirmationPage, 'payment');
        } else {
          cleanFlow.splice(1, 0, 'payment');
        }
      }
      this.campaignViewModel.presentation.flow = cleanFlow;
    },

    selectCurrency(val) {
      this.payment.currencyCode = val.name;
    },

    addProduct() {
      const products = this.payment.pricingModel.products;

      let generatedHash = generateHash();

      // Keep hashes unique
      while(products.some( item => item.key === generatedHash)) {
        generatedHash = generateHash();
      }

      products.push({
        name: null,
        description: null,
        amount: null,
        maxQuantity: null,
        key: generatedHash,
      });
      this.payment.pricingModel.products = products;
    },

    removeProduct(index) {
      const products = this.payment.pricingModel.products;
      if (!products[index]) return;
      products.splice(index, 1)
    },

    updateUnselectedFields(fields) {
      this.$set(this.campaignViewModel, 'unselectedFields', fields);
    }
  },
};
</script>

<style lang="scss">
.campaign-edit-registrations {
  .registration-fields,
  .advanced-options,
  .payment-options {
    border-radius: 4px;
    padding: 2rem 3rem;
    border: 1px solid $blueGrey500;
  }
  .advanced-options,
  .payment-options {
    padding-bottom: 1rem;
  }
  .payment-options {
    .payment-amount-and-currency-static,
    .payment-products,
    .payment-amount-and-currency-variable {
      display:flex;

      .selector {
        min-width: 108px;
        width: 50%;
        max-width: 181px;
        margin-right: 8px;
      }
      .payment-options-amount {
        min-width: 220px;
        width: 50%;
      }
    }
    .payment-amount-and-currency-static,
    .payment-amount-and-currency-variable {
      flex-direction: row;
    }
    .payment-products {
      flex-direction: column;
    }
  }
  .toggle-fields {
    cursor: pointer;
    display: block;
    text-decoration: underline;
    margin-top: 1rem;
  }

  &.sm-max {
    .registration-fields,
    .advanced-options {
      padding: 20px 12px;
      overflow: hidden;
    }
  }
}
</style>
