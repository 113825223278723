<template>
  <am2-expand-button-dropdown
    v-if="kind==='expand-icon-button'"
    align="left"
    :button-props="{ text: 'Settings', iconName: 'settings' }"
    :items="actions"
    :style="{ marginLeft: '8px' }"
    @select="handleSettingOptionSelect"
  />
  <am2-icon-button-dropdown
    v-else
    :icon-props="{
      name: 'menu',
    }"
    :color="menuIconColor"
    :hovered-color="menuIconColor === 'white' ? $arStyle.color.blueGrey700 : $arStyle.color.purple500"
    :items="actions"
    :style="{ marginBottom: '10px' }"
    @select="handleSettingOptionSelect"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { campaignBaseUri } from '@/utils/campaign';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'TourDropdown',

  props: {
    kind: {
      type: String,
      required: true
    },
    menuStyle: {
      type: String
    },
    menuIconColor: {
      type: String,
      default: null,
    },
    tour: {
      type: Object,
      required: true
    },
    onDelete: {
      type: Function,
      default: null
    },
    onDuplicate: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      actions: [
        {
          name: 'Edit',
          action: this.openTourEdit
        },
        {
          name: 'View tour',
          action: this.openTourPreview
        },
        {
          name: 'Delete tour',
          action: this.deleteTour
        }
      ]
    }
  },

  methods: {
    ...mapActions(['DELETE_TOUR', 'SHOW_CONFIRM']),

    handleSettingOptionSelect(item) {
      item.action();
    },

    openTourEdit() {
      return this.$router.push({ path: `/tours/setup?oid=${this.tour.oid}` });
    },

    openTourPreview() {
      return window.open(this.previewURL(), '_blank');
    },

    openViewResults() {
      return this.$router.push(`/tours/${this.tour.oid}/view/dashboard`);
    },

    createTour() {
      return this.$router.push({ path: `/tours/setup?tourOid=${this.tour.oid}` })
    },


    previewURL() {
      return `${process.env.arCampaignClientDomain}/t/${this.tour.urlSlug}`;
    },

    async deleteTour() {


      const res = await this.SHOW_CONFIRM({ messageHtml: `Are you sure you want to delete the tour<br /><b>${sanitizeHtmlChild(this.tour.name)}</b>?` })
      if(res) {
        await this.DELETE_TOUR(this.tour.oid);
        this.$arNotification.push({ type: 'success', message: `Tour "${this.tour.name}" deleted` });

        this.$emit('delete');
      }

    },

  },
};
</script>




