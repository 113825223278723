var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
  'plan-frame',
  _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max' ]},[_c('div',{class:[
      'fix-top-section',
      _vm.hasBackArrow ? 'u-justify-content-space-between' : 'u-justify-content-flex-end' ]},[(_vm.hasBackArrow)?_c('ar-icon-button',{attrs:{"icon-props":{
        name: 'arrow-head',
        width: '20px',
      }},on:{"click":_vm.handleBackArrowClick}}):_vm._e(),_vm._v(" "),_c('ar-icon-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
        general: ['Escape'],
        mac: ['Escape'],
        disabled: false,
        action: _vm.handleEsc,
      }),expression:"{\n        general: ['Escape'],\n        mac: ['Escape'],\n        disabled: false,\n        action: handleEsc,\n      }"}],attrs:{"icon-props":{
        name: 'cross-thick',
        width: '16px',
      }},on:{"click":_vm.handleEsc}})],1),_vm._v(" "),_c('div',{staticClass:"title-section"},[_c('ar-text',{attrs:{"size":"lg","text":_vm.title,"weight":"bold","align":"center","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{staticClass:"description",attrs:{"size":"sm","text":_vm.description}})],1),_vm._v(" "),_c('div',{staticClass:"content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }