<template>
  <article class="block-container">
    <header class="header-block">
      <am2-heading
        type="h1"
        size="md"
        :title="overallTitle"
      />
      <ar-text
        class="caption"
        size="lg"
        :text="overall"
      />
    </header>
    <div class="overview-wide-block">
      <div
        v-if="!!direct || !!viral"
        class="overview-highlight"
      >
        <div v-if="!!direct" class="overview-line">
          <am2-heading
            type="h2"
            size="sm"
            :title="directTitle"
          />
          <ar-text
            class="h5value"
            size="md"
            :text="direct"
          />
        </div>
        <div v-if="!!viral" class="overview-line">
          <am2-heading
            type="h2"
            size="sm"
            :title="viralTitle"
          />
          <span class="progress">
            <am2-tendency-tag
              v-if="progress > 0"
              :value="progress"
            />
          </span>
          <ar-text
            class="h5value light"
            size="md"
            :text="viral"
          />
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="overview-block">
        <div class="overview-line">
          <slot name="info" />
        </div>
      </div>
      <ar-divider
        :style="{ margin: '48px 0 0' }"
      />
      <div class="overview-block conversion-block">
        <div class="overview-line" :style="{ marginBottom: '22px' }">
          <am2-heading
            type="h2"
            size="sm"
            title="Unique page views"
            class="data-title"
            :style="{ lineHeight: '20px' }"
          />
          <ar-text
            class="h5value"
            size="md"
            :text="uniqueViews"
          />
        </div>
        <div class="overview-line" :style="{ marginBottom: '0' }">
          <am2-heading
            type="h2"
            size="sm"
            title="Conversion rate"
            class="data-title"
            :style="{ lineHeight: '20px' }"
          />
          <ar-text
            class="h5value"
            size="md"
            :text="conversionRate"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'OverviewBlock',

  props: {
    overallTitle: {
      type: String,
      default: '',
    },
    directTitle: {
      type: String,
      default: '',
    },
    viralTitle: {
      type: String,
      default: '',
    },
    overall: {
      type: String,
      default: '',
    },
    uniqueViews: {
      type: String,
      default: '',
    },
    conversionRate: {
      type: String,
      default: '',
    },
    direct: {
      type: [String, Boolean],
      default: '',
    },
    viral: {
      type: [String, Boolean],
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.block-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 30px;

    .caption {
      color: #7344c0;
      margin-right: -5px;
    }
  }
  .lower-block {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .overview-block {
      padding: 33px 30px;
    }
    .conversion-block {
      padding: 22px 30px;
      .data-title {
        color: $blueGrey800;
      }
    }
  }
  .overview-wide-block {
    padding: 0.75rem 0.75rem;
  }
  .overview-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .overview-highlight {
    background-color: $pink200;
    padding: 19px 17px 13px 17px;
    border-radius: 5px;
    margin-top: -16px;
    & .overview-line:nth-child(1) {
      margin-bottom: 21px;
    }
    & .progress {
      flex: 2 0 auto;
      display: flex;
      justify-content: space-around;
    }
  }

  .h5value {
    color: $purple500;

    &.light {
      color: $pink500;
    }
  }

  .promote {
    font-size: 16px;
    margin-top: -22px;
  }
}
</style>
