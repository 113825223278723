var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('div',{class:['sidebar-mask', _vm.isMenuCollapsed && 'hide', _vm.$arMediaQuery.window.maxWidth('sm') && 'window-sm-max'],style:({
      width: _vm.isMenuCollapsed ? null : '100vw',
      transition: _vm.isMenuCollapsed ? '0.3s all' : '0.01s all',
    }),on:{"click":_vm.toggleNavbarVisibility}}),_vm._v(" "),(_vm.$arMediaQuery.window.maxWidth('sm'))?_c('div',{staticClass:"sidebar__mobile-header"},[_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
        priority: 10,
      }),expression:"{\n        priority: 10,\n      }"}],staticClass:"sidebar__mobile-header-content"},[_c('ar-icon-button',{staticClass:"hamburger",attrs:{"size":"large","icon-props":{
          height: '16px',
          name: _vm.isMenuCollapsed ? 'filter' : 'cross',
        },"data-test-id":"navbar-hamburger-button"},on:{"click":_vm.toggleNavbarVisibility}}),_vm._v(" "),_c('div',{staticClass:"logo-container"},[_c('ar-icon',{staticClass:"logo",attrs:{"name":"ar-logo","width":"25px","data-test-id":"sidebar-top-logo"},on:{"click":_vm.handleLogoClick}})],1)],1)]):_vm._e(),_vm._v(" "),_c('nav',{class:[
      'sidebar-panel',
      _vm.isMenuCollapsed && 'sidebar-panel--collapsed',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'window-xs-max',
      _vm.$arMediaQuery.window.only('sm') && 'window-sm' ]},[(_vm.$arMediaQuery.window.minWidth('md'))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
        content: _vm.dashboardEnabledForUser ? 'Dashboard' : null,
        offset: 5,
        delay: 1,
      }),expression:"{\n        content: dashboardEnabledForUser ? 'Dashboard' : null,\n        offset: 5,\n        delay: 1,\n      }",modifiers:{"right":true}}],staticClass:"logo-container",attrs:{"data-test-id":"sidebar-logo-link"},on:{"click":_vm.handleLogoClick}},[_c('ar-icon',{staticClass:"logo",attrs:{"name":"ar-logo","width":"35px"}})],1):_vm._e(),_vm._v(" "),_c('ul',{class:[
        'sidebar__nav',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'window-xs-max',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'window-sm-max' ]},_vm._l((_vm.menu),function(item){return _c('li',{key:item.key},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
            content: _vm.$arMediaQuery.window.minWidth('md') ? ("" + (item.name)) : null,
            offset: 5,
            delay: 1,
          }),expression:"{\n            content: $arMediaQuery.window.minWidth('md') ? `${item.name}` : null,\n            offset: 5,\n            delay: 1,\n          }",modifiers:{"right":true}},{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
            mode: 'transparent',
            show: !_vm.activationStateEnabled && !_vm.isFeatureEnabled(item.featureKeys),
            title: _vm.generateFeatureLockerTitle(item.name),
            message: _vm.generateFeatureLockerMessage(item.name),
          }),expression:"{\n            mode: 'transparent',\n            show: !activationStateEnabled && !isFeatureEnabled(item.featureKeys),\n            title: generateFeatureLockerTitle(item.name),\n            message: generateFeatureLockerMessage(item.name),\n          }"}],class:[_vm.checkActiveResource(item.key) && 'active', "sidebar-item"],attrs:{"aria-label":("" + (item.name)),"data-test-id":("sidebar-" + (item.key) + "-link")},on:{"click":function($event){return _vm.handleSidebarLinkClick(item)}}},[_c('ar-icon',{class:['icon', !_vm.activationStateEnabled && !_vm.isFeatureEnabled(item.featureKeys) && 'locked'],attrs:{"name":item.iconName}}),_vm._v(" "),_c('div',{staticClass:"sidebar-nav-text"},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])],1)])}),0),_vm._v(" "),_c('div',{class:['sidebar__profile', _vm.$arMediaQuery.window.maxWidth('sm') && 'window-sm-max']},[_c('div',{staticClass:"u-display-flex pending-task-wrapper"},[_c('div',{staticClass:"u-margin-bottom-4 pending-task-icon-button-wrapper"},[_c('am2-icon-button-dropdown',{class:{'rotation': _vm.isRunningTaskAnimation || _vm.formattedPendingTasks && _vm.formattedPendingTasks.length},attrs:{"align":"right","icon-props":{
              name: 'refresh',
              width: '22px',
              height: '22px',
            },"items":_vm.pendingTaskItems,"dropdown-style":{
              width: _vm.$arMediaQuery.window.minWidth('sm') ? '420px' : '300px',
            },"dropdown-item-style":{
              height: '41px',
              padding: '0 20px',
            },"data-test-id":"pending-task-sidebar-dropdown"},on:{"select":_vm.handlePendingTaskItemSelect,"toggle":_vm.handlePendingTaskDropdownToggle}})],1),_vm._v(" "),(_vm.$arMediaQuery.window.maxWidth('sm'))?_c('ar-text',{staticClass:"side-text",style:({
            height: '40px', // Overriding height of ar-text which can't be overrided by the class
          }),attrs:{"size":"sm","weight":"bold","text":"Currently running tasks"}}):_vm._e()],1),_vm._v(" "),(!!_vm.arEnableFreshdeskButton && !!_vm.arFreshdeskWidgetID && !!_vm.arFreshdeskWidgetUrl)?_c('div',{staticClass:"u-margin-bottom-4",attrs:{"data-test-id":"open-freshdesk-button-wrapper"}},[_c('am2-avatar-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
            content: 'Help',
          }),expression:"{\n            content: 'Help',\n          }",modifiers:{"right":true}}],attrs:{"data-test-id":"open-freshdesk-button","avatar-props":{
            name: '?',
            textSize: 'md',
          },"side-text":{
            text: _vm.$arMediaQuery.window.maxWidth('sm') ? 'Help' : null,
            style: {
              display: 'flex',
              alignItems: 'center',
              lineHeight: '40px',
              height: '40px',
              marginLeft: '10px',
              color: _vm.$arStyle.color.blueGrey700,
            },
          }},on:{"click":_vm.openFreshdesk}})],1):_vm._e(),_vm._v(" "),_c('am2-avatar-button-dropdown',{attrs:{"align":"right","button-props":{
          sideText: _vm.sidebarNameText,
          avatarProps: {
            name: (_vm.accountFirstName + " " + _vm.accountLastName),
          },
          buttonProps: {
            customStyleAttributes: {
              maxWidth: '100%',
              overflow: 'hidden',
            },
          },
        },"items":_vm.dropdown,"dropdown-style":{
          width: _vm.activationStateEnabled ? '275px' : '170px',
        },"data-test-id":"sidebar-profile-avatar"},on:{"select":_vm.handleActionSelect}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }