<template>
  <div class="ar-payment-source-section">
    <am2-add-payment-method-modal
      :is-show="showAddPaymentSourceModal"
      @create="handlePaymentMethodCreate"
      @cancel="showAddPaymentSourceModal = false"
    />
    <div
      v-if="!displayAddPaymentSourceForm"
      class="payment-source-display-section"
    >
      <am2-loading-section
        v-if="isFetchingPaymentSources || loading"
      />
      <div
        v-else-if="!displayCardList && selectedPaymentSource"
        class="change-card-copy"
      >
        <ar-text
          size="xs"
          text="Charge to"
        />
        <ar-icon
          :name="paymentBrandIconMap[selectedPaymentSource.additionalInfo.card.brand]"
          height="17px"
          class="u-margin-left-1 u-margin-right-1"
        />
        <ar-text
          size="xs"
          :text="`ending in <b>${selectedPaymentSource.additionalInfo.card.last4}</b>`"
          allow-html
        />
        <ar-text
          v-if="!readonly"
          size="xs"
          :text="changeCardHtml"
          allow-html
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
          class="u-margin-left-1"
          @anchorClick="handleChangeCardClick"
        />
      </div>
      <!-- Start to select card -->
      <div
        v-else-if="displayCardList"
        class="payment-sources-list"
      >
        <ar-text
          size="xs"
          text="Select payment method"
          weight="bold"
          class="u-margin-bottom-4"
        />
        <div
          v-for="(paymentSource, idx) of paymentSources"
          :key="idx"
          class="payment-source-section"
        >
          <ar-divider
            class="u-background-color-skyBlueGrey400"
          />
          <div
            class="payment-source-section-content"
          >
            <ar-checkbox
              align="right"
              :icon-name="paymentBrandIconMap[paymentSource.additionalInfo.card.brand]"
              :icon-props="{
                height: '17px',
              }"
              type="round-tick"
              :label="generatePaymentSourceCopy(paymentSource)"
              :style="{
                width: '100%',
              }"
              disable-untick
              :ticked-value="paymentSource.puid"
              :value="selectedPaymentSourcePuid"
              @input="handlePaymentMethodSelect"
            />
          </div>
          <ar-divider
            v-if="idx === paymentSources.length - 1"
            class="u-background-color-skyBlueGrey400"
          />
        </div>
      </div>
    </div>
    <div
      v-if="displayAddNewCardButton && !readonly"
      :class="[
        'add-new-card-section',
        displayCardList && 'u-margin-top-4',
      ]"
      @click="handleAddPaymentSourceClick"
    >
      <ar-link-button
        :has-underline="false"
        icon-name="plus"
        :icon-props="{
          width: '10px',
          height: '10px',
          wrapperStyle: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            border: `2px solid ${$arStyle.color.purple200}`,
          },
        }"
        text="Add new card"
        :text-props="{
          size: 'xs',
          weight: 'normal',
        }"
        class="add-card-button"
      />
    </div>
    <div v-if="displayAddPaymentSourceForm" class="stripe-element-form-section">
      <ar-text
        size="xs"
        text="Add Payment Method"
        weight="bold"
        class="u-width-100-percent u-margin-bottom-6"
      />
      <am2-stripe-element-form
        :loading="isCreatingPaymentSource"
        submit-button-text="Add Payment Method"
        class="u-width-100-percent"
        @submit="handlePaymentMethodSubmit"
      />
      <ar-link-button
        text="Cancel"
        class="u-margin-top-2"
        @click="displayAddPaymentSourceForm = false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PaymentSourceSection',

  props: {
    paymentSourcePuid: {
      type: String,
      default: null,
    },
    keepCardListOpen: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addPaymentSourceOn: {
      type: String,
      default: 'popup',
      validator: (val) => ['popup', 'expansion'].indexOf(val) > -1,
    },
  },

  data() {
    return {
      showAddPaymentSourceModal: false,
      selectedPaymentSourcePuid: this.paymentSourcePuid,
      changeCardHtml: `(<a style="color: ${this.$arStyle.color.blueGrey700};">Change</a>)`,
      displayCardList: this.keepCardListOpen && !this.readonly,
      displayAddPaymentSourceForm: false,
      paymentBrandIconMap: {
        visa: 'payment-visa',
        mastercard: 'payment-mastercard',
        american_express: 'payment-american-express',
      },
    };
  },

  computed: {
    ...mapState({
      paymentSources: state => state.payment.paymentSources,
      isFetchingPaymentSources: state => state.payment.isFetchingPaymentSources,
      isCreatingPaymentSource: state => state.payment.isCreatingPaymentSource,
    }),
    displayAddNewCardButton() {
      if (this.displayAddPaymentSourceForm) {
        return false;
      }

      return this.displayCardList || this.hasNoPaymentSource ? true : false;
    },
    selectedPaymentSource() {
      return this.paymentSources.filter(item => item.puid === this.selectedPaymentSourcePuid)[0];
    },
    hasNoPaymentSource() {
      return this.paymentSources.length === 0;
    },
  },

  watch: {
    loading: {
      handler: function (val) {
        // If it stops loading and you got no "paymentSourcePuid", let's display card list
        if (!val && !this.paymentSourcePuid) {
          this.toggleDisplayCardList(true);
        }
      },
      immediate: true,
    },
    displayAddPaymentSourceForm(val) {
      this.$emit('addPaymentSourceToggle', val);
    },
    paymentSourcePuid(newPaymentSourcePuid) {
      this.updateSelectedPaymentSourcePuid(newPaymentSourcePuid);
    },
    selectedPaymentSource(val) {
      if (val) {
        this.$emit('select', val);
      }
    },
  },

  created() {
    this.fetchPaymentSources();
  },

  methods: {
    ...mapActions([
      'payment/FETCH_PAYMENT_SOURCES',
      'payment/CREATE_PAYMENT_SOURCE',
    ]),
    toggleDisplayCardList(val) {
      this.displayCardList = this.keepCardListOpen ? true : val;
    },
    generatePaymentSourceCopy(paymentSource) {
      let copy = `Ending in ${paymentSource.additionalInfo.card.last4}`;
      let monthCopy = paymentSource.additionalInfo.card.expiryMonth >= 10 ? paymentSource.additionalInfo.card.expiryMonth : `0${paymentSource.additionalInfo.card.expiryMonth}`;
      let yearCopy = paymentSource.additionalInfo.card.expiryYear % 100;
      return `Ending in <b>${paymentSource.additionalInfo.card.last4}</b> (${monthCopy}/${yearCopy})`;
    },

    emitSelectEvent(paymentSourcePuid) {
      const paymentSource = this.paymentSources.filter(item => item.puid === paymentSourcePuid)[0];
      if (paymentSource) {
        this.$emit('select', paymentSource);
      }
    },

    updateSelectedPaymentSourcePuid(paymentSourcePuid) {
      this.selectedPaymentSourcePuid = paymentSourcePuid;
    },

    addPaymentSource(newPaymentSource) {
      this.toggleDisplayCardList(false);
      this.updateSelectedPaymentSourcePuid(newPaymentSource.puid);
    },

    fetchPaymentSources() {
      this['payment/FETCH_PAYMENT_SOURCES']();
    },
    handlePaymentMethodCreate(newPaymentSource) {
      this.showAddPaymentSourceModal = false;
      this.addPaymentSource(newPaymentSource);
    },
    async handleAddPaymentSourceClick() {
      if (this.addPaymentSourceOn === 'popup') {
        this.showAddPaymentSourceModal = true;
      } else if (this.addPaymentSourceOn === 'expansion') {
        this.displayAddPaymentSourceForm = true;
      }
    },

    handlePaymentMethodSelect(paymentSourcePuid) {
      this.toggleDisplayCardList(false);
      this.updateSelectedPaymentSourcePuid(paymentSourcePuid);
    },

    async handlePaymentMethodSubmit(paymentSource) {
      const newPaymentSource = await this['payment/CREATE_PAYMENT_SOURCE'](paymentSource);
      if (newPaymentSource) {
        this.addPaymentSource(newPaymentSource);
        this.displayAddPaymentSourceForm = false;
      }
    },
    handleChangeCardClick(idx) {
      if (idx !== 0) {
        return;
      }
      this.toggleDisplayCardList(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-payment-source-section {
  .payment-source-display-section {
    .change-card-copy {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .payment-sources-list {
      .payment-source-section {
        .payment-source-section-content {
          display: flex;
          height: 39px;
        }
      }
    }
  }
  .add-new-card-section {
    cursor: pointer;

    .add-card-button {
      pointer-events: none;
    }
  }
  .stripe-element-form-section {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
</style>
