<template>
  <section class="ar-simple-stepper">
    <div
      v-if="hasDivider"
      :style="{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
      }"
    >
      <div
        v-for="step of steps"
        :key="step"
        :style="{
          position: 'absolute',
          top: '0',
          left: `${step * stepBlockWidthPercentage}%`,
          width: '1px',
          height: '100%',
          background: 'white',
        }"
      />
    </div>
    <div
      :style="{
        width: filledBarWidth,
        height: '100%',
        background: $arStyle.color.purple500,
        transition: 'width 0.25s',
      }"
    />
  </section>
</template>

<script>
export default {
  name: 'SimpleStepper',
  props: {
    stepsCount: {
      type: Number,
      default: 1,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    steps() {
      const res = [];
      for (let i = 0; i < this.stepsCount; i += 1) {
        res.push(i + 1);
      }
      return res;
    },
    stepBlockWidthPercentage() {
      return 100 / this.stepsCount;
    },
    filledBarWidth() {
      return `${this.currentStep / this.stepsCount * 100}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-simple-stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: $skyBlueGrey400;
  width: 100%;
  height: 2px;
}
</style>
