var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ar-text',{style:({
      color: '#9FA8B5',
    }),attrs:{"size":"xxs","text":"STEP 2","weight":"bold"}}),_vm._v(" "),_c('am2-heading',{staticClass:"u-margin-top-2",attrs:{"type":"h1","size":"lg","title":"Form fields","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-12",attrs:{"size":"md","text":"Select fields to display"}}),_vm._v(" "),_c('am2-card-container',{class:[
      'u-margin-top-7',
      'u-padding-y-6',
      'u-padding-x-6'
    ]},[_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-margin-bottom-4' ]},[_c('ar-text',{staticClass:"u-flex-grow-1",attrs:{"size":"xs","text":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'Fields' : 'Registration fields',"weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
          width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
        }),attrs:{"size":"xs","text":"Optional","align":"left","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({ width: '62px' }),attrs:{"size":"xs","text":"Enabled","align":"right","weight":"bold"}})],1),_vm._v(" "),_vm._l((_vm.formFields),function(field,idx){return _c('div',{key:idx},[_c('div',{class:[
          'u-display-flex',
          'u-align-items-center' ],style:({
          height: '52px',
        })},[_c('ar-text',{staticClass:"u-flex-grow-1",attrs:{"size":"xs","text":field.name}}),_vm._v(" "),_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.scratchSignupForm.registrations.fields[field.key]),expression:"scratchSignupForm.registrations.fields[field.key]"}],style:({
            width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
          }),attrs:{"value":!_vm.scratchSignupForm.registrations.requiredFields[field.key],"disabled":field.isMandatory || !field.enabled,"align":"left"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleRequiredCheck.apply(void 0, [ field.key ].concat( argsArray ))}}}),_vm._v(" "),_c('am2-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: field.isMandatory && field.mandatoryTooltip,
          }),expression:"{\n            content: field.isMandatory && field.mandatoryTooltip,\n          }"}],attrs:{"value":_vm.scratchSignupForm.registrations.fields[field.key],"disabled":field.isMandatory || !field.enabled},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleShowCheck.apply(void 0, [ field.key ].concat( argsArray ))}}})],1),_vm._v(" "),_c('ar-divider')],1)}),_vm._v(" "),_c('div',{staticClass:"u-margin-top-5"},[_c('ar-link-button',{attrs:{"text":_vm.showMoreFields ? 'See fewer fields' : 'See all'},on:{"click":_vm.handleSeeMoreClick}})],1)],2),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-12",attrs:{"size":"md","text":"Privacy"}}),_vm._v(" "),_c('am2-switch-field-card',{class:[
      'u-margin-top-5' ],style:({
      padding: '16px 24px',
    }),attrs:{"title":"Enable checkboxes for GDPR/CCPA/TCPA","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal',"value":_vm.tcEnabled,"display-field":_vm.tcEnabled},on:{"input":_vm.handleSubscriptionPreferenceSettingsEnableChange}},[_c('div',{staticClass:"disclaimer-wrapper"},[(_vm.displayCustomDisclaimerField && !!_vm.tcEnabled)?_c('ar-field',{attrs:{"label":"Disclaimer"}},[_c('ar-input',{attrs:{"placeholder":"I would like to receive marketing communications by","value":_vm.tcDisclaimer},on:{"input":_vm.handleSubscriptionPreferenceSettingsDisclaimerInput}})],1):_vm._e(),_vm._v(" "),(!_vm.displayCustomDisclaimerField && !!_vm.tcEnabled)?_c('ar-link-button',{attrs:{"text":"Add custom disclaimer"},on:{"click":_vm.toggleShowCustomDisclaimer}}):_vm._e()],1)]),_vm._v(" "),_c('am2-switch-field-card',{class:[
      'u-margin-top-5' ],style:({
      padding: '16px 24px',
    }),attrs:{"title":"Add your Privacy Policy link","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal',"value":_vm.showPrivacyPolicy,"display-field":_vm.showPrivacyPolicy},on:{"input":_vm.toggleShowPrivacyPolicy}},[_c('ar-field',{attrs:{"error-messages":[
        _vm.veeErrors.first('privacyPolicyCompanyName'),
        _vm.veeErrors.first('privacyPolicyUrl') ]}},[_c('div',{class:[
          'u-display-flex',
          'u-justify-content-space-between',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-flex-flow-column',
          'u-margin-top-3'
        ]},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|privacyPolicyUrlAlsoRequired'),expression:"'required|privacyPolicyUrlAlsoRequired'"}],style:({
            width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '100%' : '30%',
          }),attrs:{"placeholder":"Company","value":_vm.privacyPolicyCompanyName,"autocomplete":"organization","data-vv-name":"privacyPolicyCompanyName","data-vv-as":"company name","type":"text"},on:{"input":_vm.handlePrivacyPolicyCompanyNameInput}}),_vm._v(" "),_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            privacyPolicyCompanyNameAlsoRequired: true,
            url: {
              require_protocol: true,
            },
          }),expression:"{\n            required: true,\n            privacyPolicyCompanyNameAlsoRequired: true,\n            url: {\n              require_protocol: true,\n            },\n          }"}],class:[
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-margin-top-2' ],style:({
            width: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '100%' : 'calc(70% - 10px)',
          }),attrs:{"placeholder":"Privacy Policy URL","value":_vm.privacyPolicyUrl,"autocomplete":"url","data-vv-name":"privacyPolicyUrl","data-vv-as":"url","type":"text"},on:{"input":_vm.handlePrivacyPolicyUrlInput}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }