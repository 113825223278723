<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="edit-privacy-policy-modal"
      header="Edit Privacy Policy"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'edit-privacy-policy-modal-body',
        ]"
      >
        <div ref="fields">
          <ar-field
            label="Company"
            :error-messages="[
              veeErrors.first('companyName'),
            ]"
          >
            <ar-input
              v-model="companyName"
              v-validate="{
                required: !!url,
              }"
              data-vv-name="companyName"
              data-vv-as="Company name"
              placeholder="Company name"
            />
          </ar-field>
          <ar-field
            label="Privacy Policy URL"
            :style="{
              marginTop: '25px',
            }"
            :error-messages="[
              veeErrors.first('url'),
            ]"
          >
            <ar-input
              v-model="url"
              v-validate="{
                required: !!companyName,
                url: { require_protocol: true },
              }"
              data-vv-name="url"
              data-vv-as="Privacy Policy URL"
              placeholder="Privacy Policy URL"
            />
          </ar-field>
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'edit-privacy-policy-modal-footer',
        ]">
        <ar-simple-button
          text="Save"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'EditPrivacyPolicy',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    defaultCompanyName: {
      type: String,
      default: null,
    },
    defaultUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      companyName: null,
      url: null,
    };
  },

  watch: {
    opened(val) {
      if (val) {
        this.companyName = this.defaultCompanyName;
        this.url = this.defaultUrl;
      }
    },
  },

  methods: {
    async validateFields() {
      return await this.$validator.validateAll();
    },
    async handleConfirmClick() {
      const valid = await this.validateFields();
      if (!valid) {
        return;
      }
      this.$emit('confirm', this.companyName, this.url);
      this.companyName = null;
      this.url = null;
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.companyName = null;
      this.url = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-privacy-policy-modal {
  .edit-privacy-policy-modal-body {
    padding: 24px 30px 46px;
  }
  .edit-privacy-policy-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 28px 25px 23px;
  }
}
</style>
