var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'column-chooser-tabs',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && _vm.items.length > 3 && 'is-scrollable' ]},_vm._l((_vm.items),function(option,index){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(option.disabled && option.disabledTooltip ? {
      content: option.disabledTooltip,
    } : null),expression:"option.disabled && option.disabledTooltip ? {\n      content: option.disabledTooltip,\n    } : null",modifiers:{"top":true}}],key:option.id,class:{
      'option': true,
      active: _vm.checkIfItemActive(index),
    },style:(Object.assign({}, _vm.itemStyle,
      {cursor: option.disabled ? 'not-allowed' : null})),attrs:{"data-test-id":("tab-option-" + (_vm.convertNameToKebabCase(option.title)))},on:{"click":function($event){return _vm.selectTab(index)}}},[(option.iconName)?_c('ar-icon',{attrs:{"height":"16px","name":option.iconName}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"span",style:({ marginLeft: option.iconName ? '8px' : null }),attrs:{"size":"xs","text":option.title,"weight":_vm.checkIfItemActive(index) ? 'bold' : 'normal'}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }