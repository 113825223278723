<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="select-events-modal"
      header="Select Events"
      width="630px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <am2-search
          placeholder="Select your events..."
          :value="searchString"
          @input="handleSearchInput"
        />
        <div
          class="event-items-section"
        >
          <SelectBox
            v-if="!!selectedEvent && selectedEventNotInSourceEvents && !isFetchingSourceEvents"
            :img-src="getEventImageUrl(selectedEvent)"
            :title="selectedEvent.name"
            :description="formatEventDateRang(selectedEvent)"
            :extra-description="selectedEvent.location"
            selected
            @click="handleEventBoxSelect(event)"
            :style="{
              marginBottom: '10px',
            }"
          />
          <template
            v-if="displayEventsSection"
          >
            <SelectBox
              v-for="event of sourceEvents"
              :key="event.oid"
              :img-src="getEventImageUrl(event)"
              :title="event.name"
              :description="formatEventDateRang(event)"
              :extra-description="event.location"
              :selected="selectedEvent ? selectedEvent.oid === event.oid : false"
              @click="handleEventBoxSelect(event)"
              :style="{
                marginBottom: '10px',
              }"
            />
          </template>
          <div
            v-if="displayNoEventsSection"
            class="no-events-section"
          >
            <ar-text
              size="14px"
              text="No events found"
            />
          </div>
          <div
            v-if="displayLoadMoreEventsSection"
            class="load-more-events-section"
          >
            <ar-link-button
              text="Load more events"
              @click="handleLoadMoreEventsClick"
            />
          </div>
          <am2-loading-section
            v-if="displayLoadingSection"
            class="loading-section"
          />
        </div>
      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          class="add-button"
          text="Insert"
          :disabled="!selectedEvent"
          @click="handleAddClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { debounce } from 'debounce';
import SelectBox from './SelectBox';

export default {
  name: 'SelectEventsModal',
  components: {
    SelectBox,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    defaultEventOid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isFetchingDefaultSelectedEvent: false,
      searchString: null,
      selectedEvent: null,
    };
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      sourceEvents: state => state.event.events,
      isFetchingSourceEvents: state => state.event.isFetchingEvents,
      hasNoMoreEvents: state => state.event.hasNoMoreEvents,
    }),
    ...mapGetters({
      getEventImageUrl: 'event/getEventImageUrl',
    }),
    selectedEventNotInSourceEvents() {
      if (!this.selectedEvent) {
        return false;
      }
      return !this.sourceEvents.filter(event => {
        return event.oid === this.selectedEvent.oid;
      })[0];
    },
    displayLoadingSection() {
      return this.isFetchingSourceEvents || this.isFetchingDefaultSelectedEvent;
    },
    displayEventsSection() {
      return !this.displayLoadingSection || this.sourceEvents.length > 0;
    },
    displayLoadMoreEventsSection() {
      return !this.hasNoMoreEvents && !this.displayLoadingSection;
    },
    displayNoEventsSection() {
      return this.sourceEvents.length === 0 && !this.selectedEvent && !this.displayLoadingSection;
    },
  },

  watch: {
    opened(val) {
      if (val) {
        this.initEventsData();
      }
    },
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
    ]),
    handleSearchInput(searchString) {
      this.fetchEventsDebounce(searchString, true);
    },
    async initEventsData() {
      this.selectedEvent = null;
      this.searchString = null;
      this.fetchDefaultSelectedEvent(this.defaultEventOid);
      this.fetchEvents(this.searchString, true);
    },
    async fetchDefaultSelectedEvent(eventOid) {
      if (!eventOid) { return; }
      try {
        this.isFetchingDefaultSelectedEvent = true;
        this.selectedEvent = await this.$api.event.get(this.promoterOid, eventOid);
      } catch (e) {
        console.error(e);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch selected event',
        });
      } finally {
        this.isFetchingDefaultSelectedEvent = false;
      }
    },
    async fetchEvents(searchString, reload) {
      await this['event/FETCH_MORE_EVENTS']({
        searchString,
        top: 10,
        reload,
        orderBy: 'startDate desc',
      });
    },
    formatEventDateRang(event) {
      return this.$arUtils.event.generateDateRangeForEventsModal(event);
    },
    handleLoadMoreEventsClick() {
      this.fetchEvents(this.searchString, false);
    },
    handleEventBoxSelect(event) {
      this.selectedEvent = event;
    },
    async handleAddClick() {
      this.$emit('confirm', this.selectedEvent)
    },
    async handleCloseClick() {
      this.$emit('cancel');
    },
  },

  created() {
    this.fetchEventsDebounce = debounce(this.fetchEvents, 300);
    this.initEventsData();
  },
};
</script>

<style lang="scss" scoped>
.select-events-modal {
  .body {
    padding: 26px 29px;

    .event-items-section {
      margin-top: 13px;
    }

    .loading-section {
      height: 40px;
    }

    .no-events-section {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
    }

    .load-more-events-section {
      display: flex;
      justify-content: center;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 26px 29px;

    .add-button {
      width: 140px;
    }
  }
}
</style>
