<template>
  <section class="eventRSVP-form-details">
    <div v-if="hasHeading" class="u-margin-bottom-12">
      <ar-text
        size="xxs"
        text="STEP 3 OF 3"
        :style="{
          color: '#9FA8B5',
        }"
        weight="bold"
      />
      <am2-heading
        class="u-margin-top-2"
        type="h1"
        size="lg"
        title="What platform are you using?"
        multiple-lines
      />
    </div>
    <ar-text
      :class="[`u-margin-bottom-3`]"
      size="xs"
      weight="bold"
      text="Select platform"
    />
    <am2-elegant-tabs
      class="tabs"
      :items="platformTabs"
      :tab-key="platformType || 'zoom'"
      @select="handleModeSelect"
    />
    <div
      v-if="platformType && platformType !== 'zoom'"
    >
      <ar-field
        class="u-margin-top-2"
        :error-messages="[veeErrors.first('platform-url')]"
      >
        <ar-input
          :value="platformUrl"
          :placeholder="placeholderMap[platformType]"
          v-validate="platformUrlValidation"
          autocomplete="platform-url"
          data-vv-name="platform-url"
          data-vv-as="Platform url"
          @input="handlePlatformUrlInput"
        />
      </ar-field>
    </div>

    <ar-field
      v-if="platformType === 'zoom' || !platformType"
      :class="[`u-margin-top-6`]"
      :error-messages="[veeErrors.first('zoomAccount')]"
    >
      <!-- We need to check "accountId" if zoomSelectMode is 'auto' -->
      <div
        v-if="zoomSelectMode === 'auto'"
        v-validate:zoomAccountId="'required'"
        data-vv-name="zoomAccount"
        data-vv-as="Zoom"
      />
      <!-- Displaying loading bubble if zoom integrations is being fetched -->
      <div v-if="isFetchingZoomIntegration">
        <am2-loading-section />
      </div>
      <div v-else>
        <!-- Allow promoters to select 'auto' or 'manual' connection -->
        <div
          v-if="displayZoomModeSelect"
        >
          <div
            :class="[
              'u-margin-bottom-4',
            ]"
          >
            <ar-checkbox
              align="left"
              type="round-tick"
              label="Create a new Zoom meeting automatically"
              :label-typography-props="{
                size: 'sm',
              }"
              :value="zoomSelectMode"
              ticked-value="auto"
              @input="handleZoomModeSelect"
            />

            <div
              v-if="zoomSelectMode === 'auto'"
              :class="[
                `u-display-flex`,
                `u-margin-top-2`,
              ]"
              :style="{
                marginLeft: '30px',
              }"
            >
              <ar-text
                text="Account:"
                size="xxs"
                :style="{
                  color: $arStyle.color.skyBlueGrey700
                }"
                class="u-margin-right-1"
              />
              <am2-link-button-dropdown
                :items="zoomAccountItems"
                :item-key="zoomAccountId"
                placeholder="Select Zoom accounts"
                search-placeholder="Search Zoom accounts"
                has-search
                no-option-text="No Zoom accounts found"
                max-height="300px"
                :dropdown-style="{
                  width: '280px',
                }"
                :button-props="{
                  hasArrow: true,
                  hasUnderline: false,
                  textProps: {
                    size: 'xxs',
                  },
                }"
                @select="handleZoomAccountSelect"
              />
            </div>
          </div>
          <div>
            <ar-checkbox
              align="left"
              type="round-tick"
              label="Enter Zoom details manually"
              :value="zoomSelectMode"
              ticked-value="manual"
              @input="handleZoomModeSelect"
              :label-typography-props="{
                size: 'sm',
              }"
            />
          </div>
        </div>
        <!-- Display Manual Inputs For Zoom -->
        <div
          v-if="zoomSelectMode === 'manual'"
          :class="[
            `u-margin-bottom-6`,
            displayZoomModeSelect && 'u-margin-top-6',
          ]"
        >
          <ar-field
            class="u-margin-top-2"
            label="Zoom Meeting URL"
            :error-messages="[veeErrors.first('zoom meeting url')]"
          >
            <ar-input
              :value="scratchEventRSVPForm.settings.platform.url"
              v-validate="'required|zoomUrl'"
              autocomplete="zoom-meeting-url"
              data-vv-name="zoom meeting url"
              data-vv-as="Zoom Meeting url"
              placeholder="https://zoom.us/j/0123456789"
              @input="handleZoomManualUrl"
            />
          </ar-field>
          <div
            :class="[
              `u-display-flex`,
              `u-justify-content-space-between`
            ]"
          >

            <ar-field
              :class="[
                `u-margin-top-5`,
              ]"
              :style="{
                width: 'calc(50% - 6px)',
              }"
              label="Zoom Meeting ID"
              :error-messages="[veeErrors.first('zoom meeting id')]"
            >
              <ar-input
                :value="scratchEventRSVPForm.settings.platform.id"
                v-validate="'required'"
                autocomplete="zoom-meeting-id"
                data-vv-name="zoom meeting id"
                data-vv-as="Zoom Meeting id"
                placeholder="123456789"
                @input="handleZoomManualID"
              />
            </ar-field>
            <ar-field
              :class="[
                `u-margin-top-5`,
              ]"
              :style="{
                width: 'calc(50% - 6px)',
              }"
              label="Zoom Meeting Password"
            >
              <ar-input
                :value="scratchEventRSVPForm.settings.platform.password"
                autocomplete="zoom-meeting-password"
                placeholder="000000"
                @input="handleZoomManualPassword"
              />
            </ar-field>
          </div>
        </div>
        <!-- Ask Promoters to Connect to Zoom -->
        <div
          v-if="displayConnectZoomSection"
        >
          <ar-text
            v-if="zoomSelectMode === 'auto'"
            size="xs"
            text="Connect your Zoom account, and we’ll automatically generate Zoom meetings for you."
            multiple-lines
          />
          <ar-text
            v-if="zoomSelectMode === 'manual'"
            :class="[`u-margin-top-6`]"
            size="xs"
            text="Or connect your Zoom account, and we'll automatically generate Zoom meetings for you."
            multiple-lines
          />

          <div
            :class="[
              `u-margin-top-3`,
            ]"
          >
            <ar-simple-button
              :text="'Connect Zoom'"
              :style="{
                width: '160px',
                height: '39px',
              }"
              :icon-name="'zoom-outline'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: 'white',
              }"
              type="#4A8CFF"
              @click="handleConnectToZoom"
            />
          </div>

          <ar-link-button
            v-if="zoomSelectMode === 'auto'"
            class="u-margin-top-6"
            text="Want to enter details manually instead?"
            :text-props="{
              size: 'xs',
            }"
            @click="handleZoomModeSelect('manual')"
          />
        </div>
      </div>
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters, } from 'vuex';

export default {
  name: 'Details',
  props: {
    hasHeading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showMoreFields: false,
      showPrivacyPolicy: false,
      showCustomDisclaimer: false,
      platformTabs: [
        {
          name: 'Zoom',
          key: 'zoom',
          icon: 'zoom-badge',
          iconHeight: '24px',
          iconColor: this.$arStyle.color.zoom,
        },
        {
          name: 'Clubhouse',
          key: 'clubhouse',
          icon: 'clubhouse',
          iconHeight: '16px',
        },
        {
          name: 'Vimeo',
          key: 'vimeo',
          icon: 'vimeo',
          iconHeight: '13px',
          iconColor: this.$arStyle.color.vimeo,
        },
        {
          name: 'YouTube',
          key: 'youtube',
          icon: 'youtube',
          iconHeight: '12px',
          iconColor: this.$arStyle.color.youtube,
        },
        {
          name: 'Other',
          key: 'other',
        },
      ],
      validationMap: {
        youtube: ['youtubeUrl', true],
        vimeo: ['vimeoUrl', true],
        other: ['url', {require_protocol: true }],
      },
      placeholderMap: {
        clubhouse: 'https://joinclubhouse.com/event/AE12345Qa',
        vimeo: 'https://vimeo.com/123456789',
        youtube: 'https://www.youtube.com/watch?v=01abCdeFghIj2',
        other: 'e.g. https://www.twitch.tv/123456789',
      },
      cachedValues: {
        zoom: {
          url: null,
          id: null,
          password: null,
        },
        clubhouse: null,
        vimeo: null,
        youtube: null,
        other: null,
      },
      zoomSelectMode: 'auto',
    };
  },

  computed: {
    ...mapState({
      scratchEvent: state => state.event.scratchEvent,
      connectZoomProviderInfo: state => state.event.connectZoomProviderInfo,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      zoomIntegration: state => state.zoomIntegration.integration,
      isFetchingZoomIntegration: state => state.zoomIntegration.isFetchingIntegration,
    }),
    ...mapGetters({
      zoomIntegrationDisconnected: 'zoomIntegration/integrationDisconnected',
      currentEventHasProvider: 'event/currentEventHasProvider',
    }),
    zoomIntegrationIsAvailable() {
      return this.zoomIntegrationEnabled && this.zoomIntegration && !this.zoomIntegrationDisconnected;
    },
    canConnectToZoom() {
      return !this.currentEventHasProvider && this.zoomIntegrationIsAvailable;
    },
    displayConnectZoomSection() {
      return !this.currentEventHasProvider && !this.zoomIntegrationIsAvailable;
    },
    displayZoomModeSelect() {
      return !this.currentEventHasProvider && this.zoomIntegrationIsAvailable;
    },
    platformType() {
      return this.scratchEventRSVPForm.settings.platform?.type;
    },
    platformUrl() {
      return this.scratchEventRSVPForm.settings.platform?.url || null;
    },
    platformUrlValidation() {
      const res = {
        required: true,
      };
      if (this.validationMap[this.platformType]) {
        const [key, value] = this.validationMap[this.platformType];
        res[key] = value;
      }
      return res;
    },
    zoomAccountId() {
      return this.connectZoomProviderInfo?.accountId;
    },
    zoomAccountItems() {
      if (!this.zoomIntegration) {
        return null
      } else {
        const zoomItems = this.zoomIntegration
          .filter(i => i.status !== 'failed')
          .map(i => ({
            key: i.integration?.user?.accountId,
            name: i.integration?.user?.email,
            accountId: i.integration?.user?.accountId,
            integrationOid: i.oid,
          }));

        return zoomItems

      }
    },

    zoomIntegrationEnabled() {
      return process.env.arEnableZoomIntegration;
    },

  },

  watch: {
    scratchEventRSVPForm: {
      handler() {
        // You only be allowed to put manual event if event already has a provider
        if (this.scratchEventRSVPForm.settings?.platform?.type === 'zoom') {
          if (this.scratchEventRSVPForm.settings.platform.url && this.zoomSelectMode !== 'manual') {
            this.changeZoomMode('manual');
          }
        }
      },
      immediate: true,
    },
    currentEventHasProvider: {
      handler(hasProvier) {
        // You only be allowed to put manual event if event already has a provider
        if (hasProvier && this.zoomSelectMode !== 'manual') {
          this.changeZoomMode('manual');
        }
      },
      immediate: true,
    },
  },

  created () {
    this.fetchIntegration();

    this.$validator.dictionary.merge({
      en: {
        custom: {
          zoomAccount: {
            required: 'Please select a Zoom account',
          },
        },
      },
    });
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'zoomIntegration/FETCH_INTEGRATION',
      'CONNECT_TO_INTEGRATION',
    ]),
    ...mapMutations([
      'event/SET_CONNECT_ZOOM_PROVIDER_INFO',
      'event/RESET_CONNECT_ZOOM_PROVIDER_INFO',
      'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
    ]),
    async fetchIntegration() {
      await this['zoomIntegration/FETCH_INTEGRATION']();
    },

    removePlatform() {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: null,
        },
      });
    },

    initializeZoomPlatform() {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: {
            type: 'zoom',
            url: this.cachedValues['zoom'].url,
            id: this.cachedValues['zoom'].id,
            password: this.cachedValues['zoom'].password,
          }
        },
      });
    },

    handleModeSelect(tabItem) {
      // Cache previous URL input
      if (this.platformType == 'zoom') {
        this.cachedValues[this.platformType] = {
          url: this.scratchEventRSVPForm.settings.platform?.url,
          id: this.scratchEventRSVPForm.settings.platform?.id,
          password: this.scratchEventRSVPForm.settings.platform?.url,
        };
      } else {
        this.cachedValues[this.platformType] = this.scratchEventRSVPForm.settings.platform?.url;
      }

      if (tabItem.key === 'zoom') {
        if (this.canConnectToZoom) {
          this.zoomSelectMode = 'auto';
          this.selectZoomAccount(this.zoomAccountItems[0]);
          this.removePlatform();
        } else {
          this.zoomSelectMode = 'manual';
          this.initializeZoomPlatform();
        }
      } else {
        this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
          settings: {
            platform: {
              type: tabItem.key,
              url: this.cachedValues[tabItem.key],
            }
          },
        });
        this['event/RESET_CONNECT_ZOOM_PROVIDER_INFO']();
      }
    },

    changeZoomMode(mode) {
      this.zoomSelectMode = mode;
      if (mode === 'manual') {
        if (!this.scratchEventRSVPForm.settings.platform) {
          this.initializeZoomPlatform();
        }
        this['event/RESET_CONNECT_ZOOM_PROVIDER_INFO']();
      } else if (mode === 'auto') {
        // Okay, you're going to connect Event with Zoom
        // Let's get rid of platform
        this.removePlatform();
        this.selectZoomAccount(this.zoomAccountItems[0]);
      }
    },

    selectZoomAccount(zoomAccountItem) {
      this['event/SET_CONNECT_ZOOM_PROVIDER_INFO']({
        integrationOid: zoomAccountItem.integrationOid,
        accountId: zoomAccountItem.accountId,
      });
    },

    handleZoomModeSelect(mode) {
      this.changeZoomMode(mode);
    },

    handlePlatformUrlInput(url) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: {
            url,
          },
        },
      });
    },

    handleZoomManualUrl(url) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: {
            url,
          },
        },
      });
    },

    handleZoomManualID(id) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: {
            id,
          },
        },
      });
    },

    handleZoomManualPassword(password) {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        settings: {
          platform: {
            password,
          },
        },
      });
    },

    async handleConnectToZoom() {
      try {
        const res = await this.CONNECT_TO_INTEGRATION({
          provider: 'zoom',
          app: 'zoom'
        });

        this['zoomIntegration/FETCH_INTEGRATION']();
        this.changeZoomMode('auto');
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }

        this.$arNotification.push({ type: 'error', message: `Failed to connect to Zoom: ${e.message}` });
        throw e;
      }
    },

    handleZoomAccountSelect(zoomAccountItem) {
      this.selectZoomAccount(zoomAccountItem);
    }
  },
};
</script>

<style lang="scss" scoped>
.eventRSVP-form-details {
}
</style>
