<template>
  <div :style="{width:getSize,height:getSize}" class="loader"/>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: [Number, String],
      default: '3rem',
    },
  },
  computed: {
    getSize() {
      return this.size + (typeof this.size === 'number' ? 'px' : '');
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  color: $purple500;
  $loader-border: 0.25rem;
  border-bottom: $loader-border solid rgba(0, 0, 0, 0.1);
  border-left: $loader-border solid rgba(0, 0, 0, 0.1);
  border-right: $loader-border solid rgba(0, 0, 0, 0.1);
  border-top: $loader-border solid currentColor;
  border-radius: 100%;
  animation: loadrot 1.1s infinite linear;
}
@keyframes loadrot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
