var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csv-review-table-wrapper",style:({
    background: _vm.bgColor,
  })},[_c('div',{class:( _obj = {}, _obj['table-shadow'] = true, _obj['right'] =  _vm.displayRightTableShadow, _obj )}),_vm._v(" "),_c('div',{class:( _obj$1 = {}, _obj$1['table-shadow'] = true, _obj$1['left'] =  _vm.displayLeftTableShadow, _obj$1 )}),_vm._v(" "),_c('div',{ref:"table-content",staticClass:"table-wrapper"},[_c('table',{ref:"table",staticClass:"csv-review-table",style:({
        marginBottom: _vm.remaningLines ? '30px' : null,
      })},[_c('thead',[_c('tr',_vm._l((_vm.head),function(heading,headIdx){return _c('th',{key:headIdx,attrs:{"align":"left"}},[_c('ar-text',{attrs:{"size":"xs","text":heading,"weight":"bold"}})],1)}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.displayedRows),function(row,rowIdx){return _c('tr',{key:rowIdx},_vm._l((_vm.displayedRows[rowIdx]),function(cell,colIdx){return _c('td',{key:colIdx,attrs:{"align":"left"}},[_c('ar-text',{attrs:{"size":"xs","text":cell}})],1)}),0)}),0)]),_vm._v(" "),(_vm.remaningLines)?_c('ar-text',{staticClass:"remain-lines",style:({
        color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"size":"xs","text":("+ " + _vm.remaningLines + " lines")}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }