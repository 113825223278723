<template>
  <header class="ar-hero-skeleton">
    <div class="hero-image" />
    <div class="ar-hero-skeleton__wrapper content content--centered">
      <!-- Back to, navigation link -->
      <am2-step-link
        class="back-link"
        :text="backToText"
        @click="handleStepLinkClick"
      />
      <div class="title-container">

        <div class="page-tag-container">
          <div v-if="isTours" class="page-tag">
            <span class="page-tag-type page-tag-type--tour"> </span>
          </div>
          <div v-else-if="!isEvents" class="page-tag">
            <div class="page-tag-type"> </div>
            <div class="page-tag-title"> </div>
          </div>
        </div>

        <am2-typography-skeleton
          class="header-skeleton"
          size="xl"
          color="white"
        />
      </div>

      <div class="row">
        <!-- Timeframe & Link -->
        <div :class="['col-xs-12', 'info', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
          <p v-if="isEvents" class="info-cell location">
            <ar-icon
              height="16px"
              name="location"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <am2-typography-skeleton
              class="location-skeleton"
              size="xs"
              color="white"
            />
          </p>
          <p class="info-cell timeframe">
            <ar-icon
              height="16px"
              name="clock"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <am2-typography-skeleton
              class="time-skeleton"
              size="xs"
              color="white"
            />
          </p>
          <div v-if="!isEvents" class="info-cell url">
            <ar-icon
              height="16px"
              name="link"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <am2-typography-skeleton
              class="link-skeleton"
              size="xs"
              color="white"
            />
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import { backToText} from '@/utils/route';

export default {
  name: 'HeroSkeleton',
  props: {
    backPath: {
      type: String,
      default: ''
    },
    isEvents: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCampaigns: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTours: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    backToText() {
      return backToText(this.backPath);
    },
  },
  methods: {
    handleStepLinkClick() {
      const path = this.backPath || this.$route.from.fullPath;
      this.$router.pop(path);
    },
  },
};


</script>

<style lang="scss" scoped>
  .ar-hero-skeleton {
    position: relative;
    height: 320px;
    overflow: hidden;

    .back-link {
      color: white;
      position: relative;
      margin-top: 25px;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 177px;

      .header-skeleton {
        height: 52px;
        width: 72%;
        margin-top: 4px;
      }
    }

    .page-tag {
      display: inline-block;
      border-radius: 5px;
      border: 1px solid #ffffff33;
      line-height: 24px;
      margin-bottom: 10px;
      overflow: hidden;
      height: 30px;
    }
    .page-tag-type {
      display: inline-block;
      padding: 3px 13px 1px 13px;
      background-color: #ffffff33;
      min-width: 90px;
      height: 100%;
      &--tour {
        min-width: 56px;
      }
    }
    .page-tag-title {
      padding: 0 13px 0 13px;
      text-overflow: ellipsis;
      background: transparent;
      border: none;
      cursor: pointer;
      min-width: 165px;
      &:focus { outline: 0; }
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 19px;
      padding-bottom: 19px;
    }

    .info {
      position: relative;
      display: flex;
      margin-bottom: 1rem;
      &.sm-max {
        flex-direction:column;

        .info-cell {
          margin-bottom:12px;
        }
      }
      .info-cell {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 30px;
        }
        &.location, &.url {
          min-width: 0;
          flex-shrink: 1;
        }
        &.timeframe {
          flex-shrink: 0;
          line-height: 20px;
        }
      }
    }
  }

  .hero-image {
    margin: -15px 0px 0px -15px;
    width: calc(100% + 30px);
    background: $purple800;
    height: 430px;
    position: absolute;
  }
</style>
