var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['split-dropdown']},[_c('ar-simple-button',{staticClass:"left-button",style:({
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    }),attrs:{"type":_vm.type,"text":_vm.text,"disabled":_vm.disabled,"outlined":_vm.outlined,"icon-name":_vm.iconName,"icon-side":_vm.iconName ? 'left' : null,"icon-props":_vm.iconName ? _vm.iconProps : null,"icon-distance":_vm.iconDistance},on:{"click":_vm.handleSimpleButtonClick}}),_vm._v(" "),_c('am2-dropdown-binder',{ref:"dropdown",attrs:{"button-name":"ar-simple-button","classNames":_vm.classNames,"button-props":{
      type: _vm.type,
      iconName: 'chevron',
      iconProps: {
        height: '6px',
        rotate: _vm.dialogOpen ? 180 : 0,
        color: _vm.outlined ? _vm.$arStyle.color.purple600 : null,
      },
      style: {
        width: '35px',
        border: _vm.outlined ? ("1px solid " + (_vm.$arStyle.color.purple600)): 'none',
        borderLeft: _vm.buttonBorderLeft,
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        backgroundColor: _vm.outlined ? 'transparent' : _vm.$arStyle.color.purple500,
      },
    },"dropdown-props":{
      arrowDistance: '20px',
      items: _vm.items,
      style: {
        boxShadow: '1px 2px 8px 1px rgba(0,0,0,.07)',
      },
      itemStyle: {
        height: '43px',
      },
    },"disabled":_vm.disabled,"align":"left"},on:{"toggle":function (val) { return _vm.dialogOpen = val; },"select":function (item) { return _vm.$emit('select', item); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }