<template>
  <section class="ar-group-buttons-select">
    <button
      v-for="(item, idx) of items"
      :key="idx"
      :class="['group-button', idx === activeIndex && 'active']"
      :style="getItemStyle(items, item, idx)"
      :name="item.iconName"
      @click="handleItemSelect(item, idx)"
    >
      <ar-icon
        v-if="item.iconName"
        :name="item.iconName"
        :style="{ marginRight: item.name ? '10px' : null }"
      />
      <span v-if="item.name">{{ item.name }}</span>
    </button>
  </section>
</template>

<script>
export default {
  name: 'ButtonGroupSelect',

  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemStyle: {
      type: Object,
      default: () => {}
    },
  },

  methods: {
    getItemStyle(items, item, idx) {
      return {
        ...this.itemStyle,
        borderLeft: this.activeIndex !== (items.length - 1) && idx === this.activeIndex + 1 ? `0px` : null,
      };
    },
    handleItemSelect(item, index) {
      this.$emit('select', item, index);
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-group-buttons-select {
  display: inline-flex;
  flex-direction: row;
  height: 50px;
  overflow: hidden;
  .group-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    color: $blueGrey700;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    border: 1px $blueGrey500 solid;
    border-right: 0px;

    &.active {
      background-color: $purple100;
      color: $purple500;
      border: 1px $purple500 solid !important;
    }

    &:hover {
      background-color: $purple100;
      color: $purple500;
    }
  }

  .group-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .group-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px $blueGrey500 solid;
  }

}
</style>
