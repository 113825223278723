<template>
    <div class="period-constraint">
      <div class="period-dropdown-container">
        <am2-link-button-dropdown
          :item-key="constraintCondition"
          :items="predicates"
          @select="(item) => handleConstraintDropdownSelect(item)"
        />
      </div>
      <div class="period-date-picker-container with-dash" v-if="constraintCondition === 'custom'">
        <div class="date-picker-small-datepicker">
          <am2-datepicker
            ref="datepicker-1"
            :value="constraintValues[0]"
            name="datepicker"
            has-arrow
            placeholder="Date"
            :style="{
              width: '100%',
              height: '40px',
            }"
            @input="handleConstraintDateChange(0, ...arguments)"
          />
        </div>
        <div class="date-picker-small-datepicker">
          <am2-datepicker
            ref="datepicker-2"
            :value="constraintValues[1]"
            name="datepicker"
            has-arrow
            placeholder="Date"
            :style="{
              width: '100%',
              height: '40px',
            }"
            @input="handleConstraintDateChange(1, ...arguments)"
          />
        </div>
      </div>
    </div>
</template>

<script>
  import { clone } from '@/utils/helpers';

  export default {
    name: 'PeriodConstraint',

    props: {
      constraintCondition: {
        type: String,
        default: 'all',
      },
      constraintPredicates: {
        type: Array,
        default: () => [],
      },
      constraintValues: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      predicates() {
        return (this.constraintPredicates || []).map(item => {
          return {
            name: item.title,
            key: item.value,
          }
        });
      },
    },

    methods: {
      handleConstraintDropdownSelect(item) {
        const data = {
          key: item.key,
          values: this.constraintValues,
        };
        this.$emit('constraintUpdate', data);
      },

      handleConstraintDateChange(index, value) {
        const newValues = clone(this.constraintValues);
        newValues[index] = value;

        const data = {
          key: this.constraintCondition,
          values: newValues,
        };
        this.$emit('constraintUpdate', data);
      }
    }

  };
</script>

<style lang="scss" scoped>
  .period-constraint {
    width: 100%;
    display: flex;
    flex-direction: column;

    .period-dropdown-container {
      justify-content: flex-end;
      display: flex;
    }

    .period-date-picker-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding-bottom: 18px;

      &.with-dash {
        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          border-bottom: 1px solid $blueGrey600;
          width: 10px;
          height: 1px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .date-picker-small-datepicker {
        display: inline-block;
        width: 125px;
      }

      .date-picker-line {
        width: 10px;
        border-top: 1px solid $skyBlueGrey500;
      }
    }
  }
</style>
