var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['ar-field', _vm.asRow && 'as-row']},[(!!_vm.label || !!_vm.help)?_c('div',{staticClass:"inline-help"},[_c('label',{class:['ar-field__label', _vm.asRow && 'col-lg-3 col-md-12'],attrs:{"for":_vm.name}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),(_vm.help)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.help,
      }),expression:"{\n        content: help,\n      }",modifiers:{"top":true}}],staticClass:"u-margin-left-2 tooltip-icon",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.blueGrey500,"height":"16px","width":"16px"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{class:['ar-field__body', _vm.asRow && 'col-lg-9 col-md-12']},[(_vm.collapsible)?_c('ar-link-button',{style:({
        margin: '0 0 15px',
        userSelect: 'none',
      }),attrs:{"text":_vm.description ? _vm.description : 'Show/Hide',"active":!_vm.collapsed},on:{"click":_vm.handleCollapseToggle}}):(_vm.description)?_c('ar-text',{style:({
        margin: '0 0 15px',
      }),attrs:{"size":"xs","multiple-lines":"","text":_vm.description}}):_vm._e(),_vm._v(" "),(!_vm.collapsed)?_vm._t("default"):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }