<template>
  <div class="automation-users-wrapper">
    <template v-for="(user, index) in users.slice(0, 3)">
      <div
        :key="index"
        class="user-circle"
        :style="{
          backgroundImage: `url(${user.src})`,
          width: '24px',
          height: '24px',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          zIndex: `${4 - index}`,
        }"
      />
    </template>
    <div
      v-if="usersCircleCount"
      class="users-count-circle"
    >
    <span>{{usersCircleCount}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AutomationUsers',
  props: {
    users: {
      type: Array,
      default: [],
    }
  },
  computed: {
    usersCircleCount() {
      return this.users.length - 3 > 0 ? this.users.length - 3 : null
    }
  }
}
</script>
<style lang="scss" scoped>
.automation-users-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  
  .user-circle {
    position: relative;
    border-radius: 16px;
    border: solid 2px #f6f9fc;
    margin-left: -10px;
  }

  .user-circle:first-child {
    margin-left: unset;
  }

  .users-count-circle {
    width: 24px;
    height: 24px;
    border-radius: 16px;
    border: solid 2px #f6f9fc;
    background: #e4e5e7;
    margin-left: -9px;
    z-index: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    span {
      width: 100%;
      font-size: 8px;
      margin-left: 7px;
      color: #43516b;
      opacity: 0.5;
    }
  }
}
</style>