var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"automation-users-wrapper"},[_vm._l((_vm.users.slice(0, 3)),function(user,index){return [_c('div',{key:index,staticClass:"user-circle",style:({
        backgroundImage: ("url(" + (user.src) + ")"),
        width: '24px',
        height: '24px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: ("" + (4 - index)),
      })})]}),_vm._v(" "),(_vm.usersCircleCount)?_c('div',{staticClass:"users-count-circle"},[_c('span',[_vm._v(_vm._s(_vm.usersCircleCount))])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }