<template>
  <div class="sales-chart-section">
    <div class="sales-chart-wrapper">
      <am2-select-date-range-modal
        :is-show="showSelectDateRangeModal"
        @select="handleDateRangeSelect"
        @cancel="handleDateRangeCancel"
      />
      <div :class="[
        'sales-chart-nav-wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'xs-max'
        ]">
        <div class="left-hand-side">
          <SalesChartHeadingDropdown
            :has-data="hasData"
            v-model="selectedHeading"
            :options="headingOptions"
          />
          <div class="vertical-divider u-margin-x-4"></div>
          <am2-link-button-dropdown
            data-test-id="dashboard-sales-chart-types"
            :items="salesTypeDropdownOptions"
            :item-key="currentSalesType"
            @select="handleSalesTypeSelect"
            :button-props="{
              hasUnderline: false,
              hasArrow: true,
              textProps: {
                size: 'sm',
                weight: 'normal',
              },
            }"
          />
          <ar-icon-button
            :class="[
              'u-margin-left-4',
              refreshTimer && 'rotation',
            ]"
            :icon-props="{
              name: 'refresh',
              width: '20px',
            }"
            v-tooltip.top="{
              content: refreshTimer ? 'Refreshing stats. Please wait - this might take a few minutes.' : 'Stats automatically update every 30 min. Click to refresh now.'
            }"
            data-test-id="dashboard-chart-refresh-btn"
            @click="handleRefreshClick"
          />
        </div>
        <ar-simple-select
          class="time-period-selector"
          :items="timePeriodOptions"
          :default-select-index="selectedTimePeriodIndex"
          :style="{
              width: '200px',
            }"
          :dropdown-style="{
              padding: '8px 0'
            }"
          @select="handleTimePeriodSelect"
        />
      </div>

      <div class="sales-chart-container">
        <div
          class="apex-sales-chart-wrapper"
          :style="{
          height: chartWrapperHeight
        }"
        >
          <am2-apex-utility-chart
            class="apex-chart"
            chart-id="dashboardSalesChart"
            :series="series"
            :options="chartOptions"
            :loading="waitingForData"
            without-toolbar
            tooltip-placement="vertical">
          </am2-apex-utility-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import accounting from 'accounting'
import { amountInDollars, centsRemaining, clone } from '@/utils/helpers'

import SalesChartHeadingDropdown from '../events/view/sales/components/SalesChartHeadingDropdown';

export default {
  name: 'SalesChartSection',

  components: {
    SalesChartHeadingDropdown,
  },

  data() {
    return {
      chartOptions: null,
      selectedHeading: {
        value: 'salesByMonth',
        name: 'Sales by month',
      },
      series: [],
      cats: [],
      catsFullString: [],
      selectedTimePeriodIndex: 4,
      showSelectDateRangeModal: false,
      customStartDate: null,
      customEndDate: null,
      previousSelectedTimePeriodIndex: null, // Used when selecting and then cancelling custom date range
      previousCustomStartDate: null,
      previousCustomEndDate: null,
      currentSalesType: 'all',
      refreshTimer: null,
    }
  },

  watch: {
    isSmMedia: {
      immediate: true,
      handler() {
        this.chartOptions = {...this.chartOptions}
      }
    },
    salesChartData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (this.currentSalesType === 'all' && newVal && !Object.is(newVal, oldVal)) {
          this.computeSeries();
        }
      }
    },
    salesChartDataTicket: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.currentSalesType === 'ticket' && newVal && !Object.is(newVal, oldVal)) {
          this.computeSeries();
        }
      }
    },
    salesChartDataLoyalty: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.currentSalesType === 'loyalty' && newVal && !Object.is(newVal, oldVal)) {
          this.computeSeries();
        }
      }
    },
    salesChartDataEcommerce: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.currentSalesType === 'ecommerce' && newVal && !Object.is(newVal, oldVal)) {
          this.computeSeries();
        }
      }
    },
    selectedHeading: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.computeSeries();
        }
      }
    },
    currentSalesType: {
      handler(newVal, oldVal) {
        if (newVal && !Object.is(newVal, oldVal)) {
          this.computeSeries();
        }
      }
    }
  },

  computed: {
    ...mapState({
      salesStats: state => state.dashboard.salesStats,
      isFetchingSalesStats: state => state.dashboard.isFetchingSalesStats,
      chartTimezone: state => state.dashboard.chartTimezone,
      salesChartData: state => state.dashboard.salesChartData,
      salesChartDataTicket: state => state.dashboard.salesChartDataTicket,
      salesChartDataLoyalty: state => state.dashboard.salesChartDataLoyalty,
      salesChartDataEcommerce: state => state.dashboard.salesChartDataEcommerce,
    }),
    salesTypeDropdownOptions() {
      return [{
        name: 'All sales',
        key: 'all',
      },{
        name: 'Ticket sales',
        key: 'ticket',
      },{
        name: 'Ecommerce',
        key: 'ecommerce',
      },{
        name: 'Memberships',
        key: 'loyalty',
      }];
    },
    timePeriodOptions() {
      return [
        {
          name: 'Last 7 days',
          value: 'days-seven',
        },
        {
          name: 'Last 14 days',
          value: 'days-fourteen',
        },
        {
          name: 'Last 30 days',
          value: 'days-thirty',
        },
        {
          name: 'Last 6 months',
          value: 'months-six',
        },
        {
          name: 'Last 12 months',
          value: 'months-twelve',
        },
        {
          name: 'This year',
          value: 'this-year',
        },
        {
          name: 'All time',
          value: 'all-time',
        },
        {
          name: 'Custom date range',
          value: 'custom',
        },
      ];
    },
    headingOptions() {
      return [
        {
          value: 'salesByDay',
          name: 'Sales by day',
        },
        {
          value: 'salesByMonth',
          name: 'Sales by month',
        },
        {
          value: 'salesByYear',
          name: 'Sales by year',
        },
      ]
    },
    hasData() {
      return this.series && this.series[0] && this.series[0].data.length > 0;
    },
    waitingForData() {
      if (!this.series) return true;
      if (this.series.length === 0) return true;
      if (this.isFetchingSalesStats) return true;
      return false;
    },
    chartWrapperHeight() {
      return this.setResponsiveChartHeight()
    },
  },

  mounted() {
    this.initialization();
  },

  methods: {
    ...mapActions({
      updateChartTimezone: 'dashboard/UPDATE_CHART_TIMEZONE',
      fetchSalesStats: 'dashboard/FETCH_SALES_STATS',
      refreshSalesStats: 'dashboard/REFRESH_SALES_STATS',
    }),

    async initialization() {
      this.fetchSalesStats({timePeriod: this.timePeriodOptions[this.selectedTimePeriodIndex].value});
      this.setChartOptions();
      await this.updateChartTimezone();
    },

    computeSeries() {
      const currentZoom = this.selectedHeading.value;

      let allData = [];
      switch(this.currentSalesType) {
        case "all":
          allData = this.salesChartData;
          break;
        case "ticket":
          allData = this.salesChartDataTicket;
          break;
        case "loyalty":
          allData = this.salesChartDataLoyalty;
          break;
        case "ecommerce":
          allData = this.salesChartDataEcommerce;
          break;
      }

      if (!allData || Object.keys(allData).length < 1) {
        this.cats = [];
        this.catsFullString = [];
        this.series = [{
          data: [],
          sales: "No sales data available",
        }]
      } else if (currentZoom === 'salesByDay') {
        this.cats = allData.daily.cats.map (item => item.category);
        this.catsFullString = allData.daily.cats.map (item => item.dateString);
        this.series = [{
          data: clone(allData.daily?.vals) || [],
          name: 'Sales by day',
        }];
      } else if (currentZoom === 'salesByMonth') {
        this.cats = allData.monthly.cats.map (item => item.category);
        this.catsFullString = allData.monthly.cats.map (item => item.dateString);
        this.series = [{
          data: clone(allData.monthly?.vals) || [],
          name: 'Sales by month',
        }];
      } else if (currentZoom === 'salesByYear') {
        this.cats = allData.yearly.cats.map (item => item.category);
        this.catsFullString = allData.yearly.cats.map (item => item.dateString);
        this.series = [{
          data: clone(allData.yearly?.vals) || [],
          name: 'Sales by year',
        }];
      } else {
        this.cats = [];
        this.catsFullString = []
        this.series = [];
      }

      this.setChartOptions();
    },

    setChartOptions() {
      let barWidth = '75%';
      let barWidthCalc = 20;
      for (let x = 1; x < this.cats.length; x++) {
        barWidthCalc = barWidthCalc + (barWidthCalc / 3);
        if (barWidthCalc > 85) {
          barWidthCalc = 85;
          x = this.cats.length;
        }
      }
      barWidth = `${barWidthCalc}%`;

      const options = {
        type: 'bar',
        fill: 'solid',
        yaxis: {
          labels: {
            minWidth: 5,
            maxWidth: "auto",
            formatter: (val) => {
              return this.cats.length ? this.yAxisSalesFormatter(val) : "";
            },
          }
        },
        xaxis: {
          type: 'category',
          categories: this.cats,
          tickAmount: 10,
          labels: {
            formatter: (val) => {
              return this.cats.length > 0 ? val : "";
            }
          },
        },
        plotOptions: {
          bar: {
            columnWidth: barWidth,
          },
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex, w}) => {
            let toolTipData = w.config.series[seriesIndex].data[dataPointIndex]
            let catToolTipDate = this.catsFullString[dataPointIndex]
            const timePeriodValue = this.selectedHeading.value;

            let getIncrementsMarkerCopy = '';
            if (timePeriodValue === 'salesByDay') {
              getIncrementsMarkerCopy = 'Daily sales';
            } else if (timePeriodValue === 'salesByMonth') {
              getIncrementsMarkerCopy = 'Monthly sales';
            } else if (timePeriodValue === 'salesByYear') {
              getIncrementsMarkerCopy = 'Yearly sales';
            }

            return `
              <div class="sales-tooltip-wrapper">
                <span class="tooltip-date">${catToolTipDate}</span>
                <span class="tooltip-tab">${getIncrementsMarkerCopy}</span>
                <span class="tooltip-value-wrapper">
                  <span class="tooltip-value">${this.getDollars(toolTipData)}</span>
                  <span class="tooltip-cents">${this.getCents(toolTipData)}</span>
                </span>
              </div>
            `
          }
        },
        noData: {
          text: "No sales data available",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: this.$arStyle.color.$blueGrey800,
            fontSize: '14px',
            fontFamily: 'Graphik'
          }
        }
      };
      this.chartOptions = options;
    },

    setResponsiveChartHeight() {
      if (this.chartOptions) {
        if (this.isSmMedia) {
          this.chartOptions['chart'] = {
            'height': 260
          }
          return '260px'
        } else {
          this.chartOptions['chart'] = {
            'height': 380
          }
          return '380px'
        }
      } else {
        return '0px'
      }
    },

    yAxisQuantityFormatter(value) {
      return accounting.formatNumber(value);
    },

    yAxisSalesFormatter(value) {
      if (value < 100) { // Might get small value if it's not in unit of cent
        return `${accounting.formatMoney(value, { precision: 0 })}`;
      }
      let dollars = value / 100;
      if (dollars >= 1000000) {
        return `${accounting.formatMoney(dollars / 1000000, { precision: dollars % 1000000 === 0 ? 0 : 1 })}m`;
      } else if (dollars >= 1000) {
        return `${accounting.formatMoney(dollars / 1000, { precision: dollars % 1000 === 0 ? 0 : 1  })}k`;
      }
      return accounting.formatMoney(dollars, { precision: 0 })
    },

    getDollars(val) {
      return `$${amountInDollars(val)}`
    },

    getCents(val) {
      return `${centsRemaining(val)}`
    },
    handleTimePeriodSelect(val) {
      const previousSelectedTimePeriodIndex = this.selectedTimePeriodIndex;
      if (this.previousSelectedTimePeriodIndex !== previousSelectedTimePeriodIndex) {
        this.previousSelectedTimePeriodIndex = previousSelectedTimePeriodIndex
      }
      this.selectedTimePeriodIndex = this.timePeriodOptions.findIndex(item => item.value === val.value);
      if (val.value === 'custom') {
        this.showSelectDateRangeModal = true;
      } else {
        // Just make sure to reset custom date ranges when not used
        this.customStartDate = null;
        this.customEndDate = null;
        this.fetchSalesStats({timePeriod: val.value});
      }
    },
    handleDateRangeSelect(val) {
      this.customStartDate = val.startDate;
      this.customEndDate = val.endDate;
      this.showSelectDateRangeModal = false;
      this.fetchSalesStats({
        timePeriod: 'custom',
        startDate: this.customStartDate,
        endDate: this.customEndDate
      });
    },
    handleDateRangeCancel(val) {
      if (this.customStartDate && this.customEndDate) {
        // Do nothing, keep the old date ranges and dropdown value
      } else {
        this.customStartDate = null;
        this.customEndDate = null;
        this.showSelectDateRangeModal = false;
        this.selectedTimePeriodIndex = this.previousSelectedTimePeriodIndex;
      }
    },
    handleSalesTypeSelect(item) {
      this.currentSalesType = item.key;
    },
    handleRefreshClick() {
      if (!this.refreshTimer) {
        this.refreshSalesStats();
        this.refreshTimer = true;
        setTimeout(() => {
          this.refreshTimer = null;
        }, 300000) // 1000 * 60 * 5 (5 minutes)
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.sales-chart-section {

  $borderColor: $blueGrey500;
  $disabledColor: $blueGrey700;

  @mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
  }

  @mixin dropdownOptionsStyle {
    background: white;
    border: ui-px2em(1) solid $borderColor;
    border-radius: 5px;
    box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
  }

  .vertical-divider {
    width: 1px;
    height: 36px;
    background-color: $skyBlueGrey400;
  }

  .sales-chart-wrapper {
    grid-column: 1 / span 2;
    background: white;
    border: ui-px2em(1) solid $skyBlueGrey400;
    box-shadow: $blueGrey400 2px 5px 10px;
    border-radius: 4px;
    padding: ui-px2em(30);

    .sales-chart-nav-wrapper {
      @include flexRow;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      padding: 0 ui-px2em(5);

      .sales-chart-nav-items-wrapper {
        @include flexRow;
        align-items: center;
        justify-content: flex-end;
        height: 36px;

        .timescale-reset-button {
          margin-right: 10px;
        }
      }

      .left-hand-side {
        display: flex;
        align-items: center;
      }

      &.xs-max {
        flex-direction: column;
        align-items: start;

        .time-period-selector {
          align-self: flex-end;
          margin-top: 12px;
        }
      }
    }

    .comparing-to-wrapper {
      @include flexRow;
      align-items: center;
      justify-content: flex-end;
      margin: 14px 0 12px;
      padding: 0 ui-px2em(5);

      .comparing-to-copy, .comparing-to-item {
        margin-right: 5px;
        color: $blueGrey700;
      }
    }

    .sales-chart-container {
      position: relative;

      .apex-sales-chart-wrapper {
        opacity: 1;
        visibility: visible;
        height: 380px;

        .apex-chart {
          height: 100%;
        }
      }
    }

    .sales-tooltip-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      width: 180px;
      background: white;
      padding: ui-px2em(12) ui-px2em(15) ui-px2em(18);
      box-sizing: border-box;
      font-family: 'Graphik';
      font-weight: 400;
      color: $blueGrey800;

      .tooltip-date {
        font-size: 14px;
        margin-bottom: 0;
      }

      .tooltip-tab {
        font-size: 14px;
        color: $blueGrey700;
        margin-top: -5px;
        margin-bottom: 10px;
      }

      .tooltip-value-wrapper {
        @include flexRow;
        align-items: baseline;
        justify-content: flex-start;

        .tooltip-value {
          font-size: 24px;
        }

        .tooltip-cents {
          font-size: 14px;
        }
      }
    }
  }

  .rotation {
    animation: rotator 3s infinite linear;
  }
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}
</style>
