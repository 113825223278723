var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"event-edit-page",staticClass:"event-edit-page"},[_c('SaveEventModal',{attrs:{"is-save-event-modal-visible":_vm.isSaveEventModalVisible,"eventbrite-url":_vm.currentEventEventbriteUrl},on:{"confirmSave":_vm.handleSaveConfirm,"close":function($event){_vm.isSaveEventModalVisible = false}}}),_vm._v(" "),_c('am2-advanced-settings-modal',{attrs:{"title":"Advanced Settings","is-show":_vm.showAdvancedSettingsModal,"hasRSVPEvent":_vm.hasRSVPEvent},on:{"close":_vm.closeAdvancedSettingsClick,"input":_vm.handleAdvancedSettingsModalConfirm}}),_vm._v(" "),_c('div',{staticClass:"event-edit-page-header"},[_c('div',{class:[
        'event-edit-page-header-top',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ],style:({
        backgroundColor: _vm.isNewEvent ? 'white' : _vm.$arStyle.color.purple500 ,
      })},[(_vm.isNewEvent)?_c('ar-text',{attrs:{"size":"xs","weight":"bold","text":"Create new event"}}):_c('div',[_c('ar-text',{style:({
            color:  _vm.isNewEvent ? _vm.$arStyle.color.purple500 : 'white',
          }),attrs:{"size":"xs","weight":"bold","text":"Edit Event"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
            color:  _vm.isNewEvent ? _vm.$arStyle.color.purple500 : 'white',
          }),attrs:{"size":"xxs","text":_vm.scratchEvent.name}})],1),_vm._v(" "),_c('div',[(_vm.isEventRSVPEnabled)?_c('ar-link-button',{style:({
            color: _vm.isNewEvent ? _vm.$arStyle.color.purple500 : 'white',
            marginRight: '30px',
          }),attrs:{"text":"Advanced Settings"},on:{"click":_vm.handleAdvancedSettingsClick}}):_vm._e(),_vm._v(" "),_c('ar-link-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
            general: ['Escape'],
            mac: ['Escape'],
            action: _vm.handleExitClick,
          }),expression:"{\n            general: ['Escape'],\n            mac: ['Escape'],\n            action: handleExitClick,\n          }"}],style:({
            color: _vm.isNewEvent ? _vm.$arStyle.color.purple500 : 'white',
          }),attrs:{"text":"Exit"},on:{"click":_vm.handleExitClick}})],1)],1),_vm._v(" "),(_vm.isEventRSVPEnabled && _vm.hasRSVPEvent)?_c('div',{class:[
        'event-edit-page-header-bottom',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('am2-tabs',{attrs:{"items":_vm.tabItems},on:{"select":_vm.handleMenuSelect}})],1):_vm._e()]),_vm._v(" "),_c('div',{class:[
      'body-section' ]},[_c('div',{class:[
        'edit-section',
        _vm.isEditSectionFullSize && 'full-size',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[_c('div',{ref:"content",class:[
          'edit-top-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[_c('div',{staticClass:"page-content"},[_c('EventDetails',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentEditTab == 'details'),expression:"currentEditTab == 'details'"}],ref:"details-section",attrs:{"updateSelectedLocation":_vm.updateSelectedLocation,"selectedLocationKey":_vm.selectedLocationKey}}),_vm._v(" "),(_vm.isEventRSVPEnabled)?_c('div',[_c('EventRsvpBasic',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentEditTab === 'rsvp'),expression:"currentEditTab === 'rsvp'"}],ref:"rsvp-section"}),_vm._v(" "),_c('EventRsvpRegister',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentEditTab === 'registration'),expression:"currentEditTab === 'registration'"}],ref:"registration-section"}),_vm._v(" "),(_vm.hasPlatformTab)?[_c('EventRsvpPlatform',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentEditTab === 'platforms'),expression:"currentEditTab === 'platforms'"}],ref:"platforms-section"})]:_vm._e()],2):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
          'edit-navigation-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('ar-simple-button',{style:({ width: _vm.$arMediaQuery.window.maxWidth('sm') ? '90px' : '120px' }),attrs:{"text":"Cancel","outlined":"","data-test-id":"cancel-button"},on:{"click":_vm.handleCancelClick}}),_vm._v(" "),_c('div',{staticClass:"right-section",style:({
            maxWidth: '300px',
            marginLeft: _vm.$arMediaQuery.window.maxWidth('sm') ? '6px' : null,
          })},[(_vm.isEventRSVPEnabled && (_vm.isNewEvent || _vm.currentEventHasNoRsvpEvent))?_c('ar-simple-button',{staticClass:"u-margin-right-2",style:({ width: _vm.$arMediaQuery.window.maxWidth('sm') ? '120px' : '200px' }),attrs:{"text":_vm.$arMediaQuery.window.maxWidth('sm') ? "Create RSVP" : "Create RSVP Page","outlined":"","icon-name":_vm.$arMediaQuery.window.maxWidth('sm') ? null : 'mobile-preview',"icon-distance":"10px","icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            },"loading":_vm.isCreatingEvent || _vm.isUpdatingEvent,"data-test-id":"create-rsvp-page-button"},on:{"click":_vm.handleRSVPClick}}):_vm._e(),_vm._v(" "),(_vm.displayPreviewButton)?_c('ar-simple-button',{staticClass:"u-margin-right-2",style:({ width: '130px' }),attrs:{"text":_vm.rightPreviewIsToggledUp ? 'Edit' : 'Preview',"outlined":"","icon-name":(_vm.rightPreviewIsToggledUp || _vm.$arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview',"icon-distance":"10px","icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            }},on:{"click":_vm.handlePreviewClick}}):_vm._e(),_vm._v(" "),(_vm.isNewEvent)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: false,
              action: _vm.handleCreateEventClick,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: false,\n              action: handleCreateEventClick,\n            }"}],style:({ width: _vm.$arMediaQuery.window.maxWidth('sm') ? '120px' : '170px' }),attrs:{"text":"Create event","loading":_vm.isCreatingEvent,"data-test-id":"create-event-button"},on:{"click":_vm.handleCreateEventClick}}):_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: false,
              action: _vm.handleSaveEventClick,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: false,\n              action: handleSaveEventClick,\n            }"}],style:({ width: '120px' }),attrs:{"text":"Save event","loading":_vm.isUpdatingEvent,"data-test-id":"save-event-button"},on:{"click":_vm.handleSaveEventClick}})],1)],1)]),_vm._v(" "),(_vm.isEventRSVPEnabled && _vm.hasRSVPEvent)?_c('div',{class:[
        'right-preview-section',
        _vm.hasNoSpaceForRightPreview && 'go-on-top-of-edit-section',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        _vm.displayMobilePreview && 'is-visible'
      ]},[_c('am2-campaign-preview',{ref:"campaign-preview",attrs:{"has-account":false,"has-fan":false,"has-footer":!_vm.hasNoSpaceForRightPreview,"preview-page":_vm.previewPage,"size":"fullHeight","campaign":_vm.prunedScratchEventRSVPFormForPreview}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }