<template>
  <section class="insights">
    <am2-insights-section
      enable-see-all-table-route-change
      :campaign-oid="campaignOid"
      insight-type="campaign"
      feature-type="campaign-insights"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';

export default {
  name: 'CampaignInsights',

  mixins: [
    checkoutPageAccessibility({ featureKeys: ['insights'], featureName: 'Insights', skip: process.env.arEnableActivationState }),
  ],

  computed: {
    ...mapState({
      campaignOid: state => state.route.params.oid,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
