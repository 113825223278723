<template>
  <div class="paid-presale-ticket">
    <div class="u-display-flex u-justify-content-space-between">
      <am2-heading
        type="h4"
        size="xs"
        title="Paid ticket"
        weight="bold"
      />
      <ar-icon-button
        :icon-props="{
          name: 'discard',
          height: '14px',
        }"
        :style="{
          transform: 'translateX(6px) translateY(-6px)',
        }"
        @click="$emit('removePrize')" />
    </div>

    <div class="row">

      <am2-field
        :class="details.ticketTypes.length === 0 ? 'col-xs-8' : 'col-xs-12'"
        label="Ticket name"
        name="ticket-name"
        class="col-xs-8 label-text field-font-weight-400"
      >
        <ar-input
          v-validate="'required'"
          v-model="details.ticketName"
          :data-vv-name="`paidTicketName_${tierIndex}`"
          data-vv-as="ticket name"
          class="input--block"
          @input="$emit('input', $data)" />
        <ar-state-message
          :style="{ marginTop: '8px' }"
          v-if="veeErrors.has(`paidTicketName_${tierIndex}`)"
          :text="veeErrors.first(`paidTicketName_${tierIndex}`)"
          type="error"
        />
      </am2-field>

      <am2-field
        v-if="details.ticketTypes.length === 0"
        label="Ticket price"
        name="ticket-main-value"
        class="col-xs-4 label-text field-font-weight-400"
      >
        <ar-input
          v-validate="'required'"
          :value="formatMoney(details.ticketValue)"
          v-model.number="details.ticketValue"
          :data-vv-name="`paidTicketValue_${tierIndex}`"
          data-vv-as="ticket price"
          class="input--block"
          @input="$emit('input', $data)" />

        <ar-state-message
          :style="{ marginTop: '8px' }"
          v-if="veeErrors.has(`paidTicketValue_${tierIndex}`)"
          :text="veeErrors.first(`paidTicketValue_${tierIndex}`)"
          type="error"
        />
      </am2-field>

    </div>

    <ar-checkbox
      v-model="details.includesBookingFee"
      label="Includes fees"
      @change="$emit('input', $data)"
    />

    <ar-divider
      :style="{ margin: '19px 0' }"
    />

    <div v-for="(ticket, index) in details.ticketTypes" :key="`p_${index}`" class="row ticket-row">
      <am2-field
        :label="index === 0 ? 'Ticket type' : ''"
        name="ticket-type"
        class="ticket-type label-text field-font-weight-400"
      >
        <ar-input
          v-validate="'required'"
          v-model="ticket.ticketName"
          :data-vv-name="`paidTicketName_${tierIndex}_${index}`"
          data-vv-as="ticket type"
          class="input--block"
          @input="$emit('input', $data)" />

        <ar-state-message
          :style="{ marginTop: '8px' }"
          v-if="veeErrors.has(`paidTicketName_${tierIndex}_${index}`)"
          :text="veeErrors.first(`paidTicketName_${tierIndex}_${index}`)"
          type="error"
        />

      </am2-field>

      <am2-field
        :label="index === 0 ? 'Ticket price' : ''"
        name="ticket-value"
        class="ticket-value label-text field-font-weight-400"
      >
        <ar-input
          v-validate="'required'"
          :value="formatMoney(ticket.ticketValue)"
          v-model.number="ticket.ticketValue"
          :data-vv-name="`paidTicketValue_${tierIndex}_${index}`"
          data-vv-as="ticket value"
          class="input--block"
          @input="$emit('input', $data)"
        />

        <ar-state-message
          :style="{ marginTop: '8px' }"
          v-if="veeErrors.has(`paidTicketValue_${tierIndex}_${index}`)"
          :text="veeErrors.first(`paidTicketValue_${tierIndex}_${index}`)"
          type="error"
        />
      </am2-field>

      <div class="delete-ticket">
        <!-- Remove -->
        <ar-icon-button
          :icon-props="{
            name: 'discard',
            height: '14px',
          }"
          :style="{
          transform: 'translateX(6px) translateY(-6px)',
          }"
          @click="removeTicketType(index)" />
      </div>
    </div>

    <ar-link-button
      :has-underline="false"
      icon-name="circle-plus"
      text="Add ticket type"
      :text-props="{
        weight: 'bold',
      }"
      @click="addTicketType"
    />
  </div>
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'RewardPaid',

  props: {
    tier: {
      type: Object,
      required: true,
    },
    tierIndex: {
      type: Number,
      default: 0,
    },
    tierItemOrder: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      name: 'Paid presale ticket',
      type: 'paid',
      insertionOrder: this.tierItemOrder,
      details: {
        includesBookingFee: this.tier.details ? this.tier.details.includesBookingFee : false,
        ticketValue: this.tier.details ? this.tier.details.ticketValue || '' : '',
        ticketName: this.tier.details ? this.tier.details.ticketName : '',
        ticketTypes: this.tier.details ? this.tier.details.ticketTypes : [],
        initialTicketValue: null,
      },
    };
  },

  inject: ['$validator'],

  watch: {
    details: {
      handler: async function watchTiers(details) {
        if (details.ticketTypes.length > 0) {
          if (!this.details.initialTicketValue && this.details.ticketValue) {
            this.details.initialTicketValue = this.details.ticketValue;
          }

          this.details.ticketValue = null;
        } else if (this.details.initialTicketValue) {
          this.details.ticketValue = this.details.initialTicketValue;
          this.details.initialTicketValue = null;
        }
      },
      deep: true,
    },
  },

  methods: {
    addTicketType() {
      this.details.ticketTypes.push({
        ticketName: null,
        ticketValue: null,
      });
      // Custom validator error messages for each ticket
      const en = { custom: {} };
      en.custom[`ticketName${this.details.ticketTypes.length - 1}`] = {
        required: 'Please enter a ticket name',
      };
      en.custom[`ticketValue${this.details.ticketTypes.length - 1}`] = {
        required: 'Please enter a ticket value',
      };
      this.$validator.dictionary.merge({ en });
    },

    formatMoney(value) {
      return value && accounting.formatMoney(value, { precision: 0 });
    },

    removeTicketType(index) {
      this.details.ticketTypes.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-presale-ticket {
  .delete-ticket {
    align-self: center;
    text-align: right;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 7px;
    >>> svg {
      cursor: pointer;

      g {
        fill: $skyBlueGrey600;
      }
    }
  }
  .ticket-row:nth-child(5) {
    .delete-ticket {
      margin-top: 50px;
    }
  }

  .field-font-weight-400 > .ar-field__label {
    font-weight: 400;
  }

  .ticket-row {
    margin-top: -20px;
  }
  .ticket-type {
    flex: 4;
    margin-left: 11px;
    margin-right: 11px;
  }
  .ticket-value {
    flex: 1;
    margin-left: 11px;
    margin-right: 11px;
  }
  .ar-field__label {
    margin-bottom: 8px !important;
  }
  .include-booking-fees {
    margin-top: 0.1rem;
    margin-bottom: -0.75rem;
  }
}
</style>
