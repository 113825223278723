<template>
  <section class="interactive-list">
    <ul>
      <li
        v-for="item in items"
        @click="listAction(item)"
        :class="[
          activeIndex === item.key ? 'active' : 'inactive',
          disabled && 'disabled',
        ]"
        :data-test-id="`interactive-list-item-${item.title}`"
        :key="item.key">
        <ar-text
          size="xs"
          :text="item.title"
          :class="activeIndex === item.key ? 'u-color-purple500' : 'u-color-blueGrey700'"
        />
      </li>
    </ul>
  </section>
</template>

<script>
  /*
      A list of items, in which each item is expected to fire a function of some kind
   */
  export default {
    name: 'InteractiveList',

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      activeIndex: {
        type: String,
        default: 'important',
      },
      disabled: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      listAction(item) {
        if (this.disabled) return;
        item.action();
      },
    }


  };
</script>

<style lang="scss" scoped>
  .interactive-list {
    padding: 20px;
    ul {
      list-style: none;
      padding: 0;

      li {
        height:50px;
        width:100%;
        padding: 0 20px;
        display:flex;
        align-items:center;
        cursor:pointer;
        transition: background-color 0.3s;

        &.active {
          background-color: $purple100;
          color: $purple500;
        }

        &.inactive {
          &:hover {
            background-color: $skyBlueGrey300;
          }
          &.disabled:hover {
            background-color: #FFF;
          }
        }
      }
    }

  }
</style>
