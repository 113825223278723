var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-dropdown-binder',{attrs:{"align":_vm.align,"pixel-shift":5,"vertical-pixel-shift":0,"button-name":"ar-icon-button","button-props":{
    iconProps: _vm.iconProps,
    color: _vm.color,
    hoveredColor: _vm.hoveredColor,
  },"dropdown-props":{
    items: _vm.items,
    arrowDistance: '16px',
    style: _vm.decoratedDropdownStyle,
    itemStyle: _vm.dropdownItemStyle,
  },"divider-style":_vm.dividerStyle},on:{"select":_vm.handleItemSelect,"toggle":_vm.handleDropdownToggle}})}
var staticRenderFns = []

export { render, staticRenderFns }