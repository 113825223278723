var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"tag-input-container",staticClass:"pseudo-input",style:({
    fontSize: _vm.inputFontSizeInPixel,
  }),on:{"click":_vm.pseudoInputFocus}},[_c('ul',{ref:"tag-list",staticClass:"saved-tags"},[_vm._l((_vm.selectedTags),function(tag,index){return _c('li',{key:tag,staticClass:"tag"},[_c('ar-text',{staticClass:"name",attrs:{"size":"sm","text":_vm._f("capitalize")(tag)}}),_vm._v(" "),_c('div',{staticClass:"delete-btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.removeTag(index); }).apply(null, arguments)}}},[_c('ar-icon',{staticClass:"icon",attrs:{"name":"cross","height":"10px","width":"10px","stroke-width":"4","stroke-linecap":"round"}})],1)],1)}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentInput),expression:"currentInput"}],ref:"input",staticClass:"input",attrs:{"autocomplete":"tag","placeholder":_vm.placeholder},domProps:{"value":(_vm.currentInput)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnteredInput.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined)){ return null; }return _vm.deleteLastTag.apply(null, arguments)}],"focus":_vm.openTagOptions,"input":[function($event){if($event.target.composing){ return; }_vm.currentInput=$event.target.value},_vm.handleInput]}})],2),_vm._v(" "),_c('div',{ref:"invisible-toggle-box",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: this.$arStyle.zIndex.globalHighest,
      display: _vm.showOptionsWhenAvailable ? 'block' : 'none',
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.closeTagOptions.apply(null, arguments)}}}),_vm._v(" "),_c('div',{ref:"tag-options-box-container",style:({
      display: 'inline-block',
      position: 'fixed',
      top: _vm.dialogBoxPosition.top,
      left: _vm.dialogBoxPosition.left,
      right: _vm.dialogBoxPosition.right,
      bottom: _vm.dialogBoxPosition.bottom,
      width: _vm.dialogBoxPosition.width,
      height: _vm.showOptionsWhenAvailable ? _vm.dialogBoxPosition.height : '0',
      opacity: _vm.showOptionsWhenAvailable ? '1' : '0.7',
      overflow: 'hidden',
      transition: 'height 0.2s, opacity 0.2s',
      zIndex: this.$arStyle.zIndex.globalHighest + 1,
    })},[_c('TagOptionsBox',{ref:"tag-option-box",style:(_vm.tagOptionBoxStyling),attrs:{"items":_vm.availableTags,"show-tag-count":_vm.showTagCount,"input-value":_vm.currentInput,"allow-new-values":_vm.allowCustomInput},on:{"select":_vm.handleSelect,"close":_vm.closeTagOptions}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }