<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="mass-edit-modal"
      :header="title"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body"
        :class="[
        'mass-edit-modal-body',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Select property to edit"
        >
          <ar-simple-select
            placeholder="Please select..."
            enable-filter
            :items="editItems"
            :default-select-index="selectedEditItemIndex"
            @select="handleEditItemSelect"
            data-test-id="fan-property-edit"
          />
        </ar-field>
        <div
          v-if="editItem"
          class="u-margin-top-3"
        >
          <ar-field
            :label="editItem.name"
            :error-messages="[veeErrors.first('editValue')]"
          >
            <ar-input
              v-if="editItem.editOptions.type === 'text'"
              v-model="editValue"
              v-validate="editItem.editOptions.validate"
              data-vv-name="editValue"
              :autocomplete="editItem.name.toLowerCase().replace(/ /g, '-')"
              :placeholder="editItem.editOptions.placeholder || `Enter ${editItem.name.toLowerCase()} here...`"
              auto-focus
            />
            <div v-else-if="editItem.editOptions.type === 'radio'">
              <ar-checkbox
                v-for="item of editItem.editOptions.items"
                type="radio"
                :label="item.name"
                :key="item.value"
                :style="{ marginRight: '10px' }"
                v-model="editValue"
                :ticked-value="item.value"
                disable-untick
              />
            </div>
            <div v-else-if="editItem.editOptions.type === 'mobileNumber'">
              <ar-mobile-input
                v-model="editValue"
                v-validate="editItem.editOptions.validate"
                data-vv-name="editValue"
                :style="{
                  width: '100%',
                }"
                autocomplete="tel-national"
                :multiple-lines="$arMediaQuery.window.maxWidth('xs')"
              />
            </div>
            <div v-else-if="editItem.editOptions.type === 'countryIsoCode'">
              <ar-country-select
                v-model="editValue"
                value-type="iso"
                :style="{
                  width: '100%',
                }"
              />
            </div>
          </ar-field>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              marginTop: '25px',
            }"
          >
            <ar-checkbox
              v-model="hasConfirmedEdit"
              :label="`Yes I want to apply this change to <b>all ${formatNumber(totalSelectedAudienceCount)} contacts</b>`"
            />
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'mass-edit-modal-footer',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
      ]">
        <ar-simple-button
          text="Update"
          :loading="loading"
          :disabled="!hasConfirmedEdit"
          :style="{ width: '108px' }"
          @click="handleUpdateClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';
import Field from '~/components/fields/field/Field.vue';

export default {
  components: { Field },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      hasConfirmedEdit: false,
      filter: null,
      loading: false,
      editItem: null,
      editValue: '',
      editItems: [
        {
          name: 'First name',
          key: 'firstName',
          editOptions: {
            type: 'text',
          },
        },
        {
          name: 'Last name',
          key: 'lastName',
          editOptions: {
            type: 'text',
          },
        },
        {
          name: 'Mobile Number',
          key: 'mobileNumber',
          editOptions: {
            type: 'mobileNumber',
            validate: 'mobileNumber',
          },
        },
        {
          name: 'City',
          key: 'city',
          editOptions: {
            type: 'text',
          },
        },
        {
          name: 'State',
          key: 'state',
          editOptions: {
            type: 'text',
          },
        },
        {
          name: 'Zip/post code',
          key: 'postcode',
          editOptions: {
            type: 'text',
          },
        },
        {
          name: 'Country',
          key: 'country',
          editOptions: {
            type: 'countryIsoCode',
          },
        },
        // {
        //   name: 'Email address',
        //   key: 'emailAdress',
        //   editOptions: {
        //     type: 'text',
        //     validate: 'email',
        //   },
        // },
        {
          name: 'Gender',
          key: 'gender',
          editOptions: {
            type: 'radio',
            items: [
              {
                name: 'Male',
                value: 'Male',
              },
              {
                name: 'Female',
                value: 'Female',
              },
              {
                name: 'Other',
                value: 'Other',
              },
              {
                name: 'Unknown',
                value: null,
              },
            ]
          },
        },
        {
          name: 'Date of birth',
          key: 'dob',
          editOptions: {
            type: 'text',
            validate: 'date_format:DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
          },
        },
      ],
    }
  },

  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
    }),
    ...mapGetters({
      prunedScratchSegment: 'segment/prunedScratchSegment',
      totalSelectedAudienceCount: 'audience/totalSelectedAudienceCount',
      partlySelectedAudience: 'audience/partlySelectedAudience',
    }),
    selectedEditItemIndex() {
      return this.editItems.findIndex(item => item.key === this.editItem?.key);
    },
    appliedFilter() {
      return this.audienceSelection.useScratchSegment ? this.prunedScratchSegment.filter : { conditions: [], logic: [] }
    },
    title() {
      if (this.audienceSelection.useScratchSegment) {
        return `Edit all contacts`;
      } else {
        return `Edit ${accounting.formatNumber(this.totalSelectedAudienceCount)} contact${this.totalSelectedAudienceCount > 1 ? 's' : ''}`;
      }
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.editValue = false;
        this.editItem = null;
        this.hasConfirmedEdit = false;
      }
    },
    editValue() {
      this.hasConfirmedEdit = false;
    },
  },

  created() {
    this.$validator.localize('en', {
      custom: {
        editValue: {
          date_format: () => `Please enter a valid ${this.editItem.name.toLowerCase()}`,
          email: () => `Please enter a valid email`,
        }
      },
    });
  },

  methods: {
    ...mapActions([
      'MASS_EDIT_FAN',
    ]),
    formatNumber(number) {
      return accounting.formatNumber(number);
    },
    handleEditItemSelect(editItem) {
      // remember to clear errors
      this.veeErrors.remove('editValue');

      this.editItem = editItem;

      if (this.editItem.editOptions.type === 'radio') {
        this.editValue = this.editItem.editOptions.items[0].value;
      } else {
        this.editValue = '';
      }
    },
    async handleUpdateClick() {
      let isValid;
      if (this.editItem.editOptions.validate) {
        isValid = await this.$validator.validate('editValue');
      } else {
        isValid = true;
      }

      if (!isValid) {
        return;
      }

      try {
        await this.MASS_EDIT_FAN({
          filter: this.appliedFilter,
          fanOids: this.partlySelectedAudience.length > 0 ? this.partlySelectedAudience.map(fan => fan.oid) : null,
          searchString: this.searchString,
          editData: {
            [this.editItem.key]: this.editValue,
          },
        });
        this.$arNotification.push({ type: 'success', message: `Successfully updated ${this.totalSelectedAudienceCount} contacts` });
        this.$emit('edit');
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: `Failed to ${this.totalSelectedAudienceCount} contacts` });
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
}
</script>

<style lang="scss" scoped>
.mass-edit-modal {
  .mass-edit-modal-body {
    padding: 20px 30px 30px;
  }
  .mass-edit-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;

    &.xs-max {
      padding: 12px;
      height: 76px;
    }
  }
}
</style>
