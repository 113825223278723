<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="put-email-template-name-modal"
      :header="header"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        class="body-section"
      >
        <ar-field
          label="Name"
        >
          <ar-input
            v-model="name"
            autocomplete="email-template-name"
            auto-focus
          />
        </ar-field>
        <ar-field
          label="Description"
          :style="{
            marginTop: '24px',
          }"
        >
          <ar-input
            v-model="description"
            autocomplete="email-template-description"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        class="footer-section"
      >
        <ar-simple-button
          class="next-button"
          :text="buttonCopy"
          :disabled="!confirmButtonIsReady"
          :loading="loading"
          @click="handleConfirmButtonClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'CreateEmailTemplateModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'New Email Template',
    },
    buttonCopy: {
      type: String,
      default: 'Create',
    },
    defaultName: {
      type: String,
      default: null,
    },
    defaultDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },

  computed: {
    ...mapState({}),
    confirmButtonIsReady() {
      return !!this.name && !!this.description;
    },
  },

  watch: {
    opened(isOpened) {
      if (isOpened) {
        this.name = this.defaultName || '';
        this.description = this.defaultDescription || '';
      }
    },
  },

  mounted() {},

  methods: {
    handleConfirmButtonClick() {
      this.$emit('confirm', {
        name: this.name,
        description: this.description,
      })
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.put-email-template-name-modal {
  .body-section {
    padding: 40px 25px 58px;
  }
  .footer-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
    padding: 0 30px;

    .next-button {
      width: 140px;
    }
  }
}
</style>
