<template>
  <nav class="tabs-nav-section">
    <div class="tabs-nav-wrapper">
      <slot name="tabs">
        <!-- default display of tabbed menu items -->
        <div class="menu-tab">
          <am2-tabs
            :items="menuItems"
            :selected-tab-index="selectedMenuIndex"
            :item-style="{
              height: '48px',
            }"
            @select="handleMenuSelect"
          />
        </div>
      </slot>

      <slot name="buttons" />
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MessageNav',
  props: {
    menu: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    menuItems() {
      return Object.keys(this.menu).map(menuKey => {
        let { to: value, name: title, featureKeys } = this.menu[menuKey]
        
        return {
          title,
          value,
          featureKeys
        }
      }).filter(menuItem => {
        if ((!menuItem.featureKeys || process.env.arEnableActivationState) || this.isFeatureEnabled(menuItem.featureKeys)) {
          return true
        } else {
          return false
        }
      })
    },
    selectedMenuIndex() {
      return this.menuItems.findIndex(menuItem => {
        return menuItem.value === this.$route.path
      });
    }
  },
  methods: {
    handleMenuSelect(item) {
      if (this.$route.path === item.value) { return }
      this.$router.push({
        path: item.value
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs-nav-section {
  background-color: white;
  border-bottom: 1px solid $skyBlueGrey500;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 81px;

  .tabs-nav-wrapper {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .menu-tab {
      display: flex;
      padding-top: 32px;
      align-self: flex-start;
      max-width: 100%;
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }
    }
  }
}
</style>