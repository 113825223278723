var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"eventRSVP-form-details"},[(_vm.hasHeading)?_c('div',{staticClass:"u-margin-bottom-12"},[_c('ar-text',{style:({
        color: '#9FA8B5',
      }),attrs:{"size":"xxs","text":"STEP 3 OF 3","weight":"bold"}}),_vm._v(" "),_c('am2-heading',{staticClass:"u-margin-top-2",attrs:{"type":"h1","size":"lg","title":"What platform are you using?","multiple-lines":""}})],1):_vm._e(),_vm._v(" "),_c('ar-text',{class:["u-margin-bottom-3"],attrs:{"size":"xs","weight":"bold","text":"Select platform"}}),_vm._v(" "),_c('am2-elegant-tabs',{staticClass:"tabs",attrs:{"items":_vm.platformTabs,"tab-key":_vm.platformType || 'zoom'},on:{"select":_vm.handleModeSelect}}),_vm._v(" "),(_vm.platformType && _vm.platformType !== 'zoom')?_c('div',[_c('ar-field',{staticClass:"u-margin-top-2",attrs:{"error-messages":[_vm.veeErrors.first('platform-url')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.platformUrlValidation),expression:"platformUrlValidation"}],attrs:{"value":_vm.platformUrl,"placeholder":_vm.placeholderMap[_vm.platformType],"autocomplete":"platform-url","data-vv-name":"platform-url","data-vv-as":"Platform url"},on:{"input":_vm.handlePlatformUrlInput}})],1)],1):_vm._e(),_vm._v(" "),(_vm.platformType === 'zoom' || !_vm.platformType)?_c('ar-field',{class:["u-margin-top-6"],attrs:{"error-messages":[_vm.veeErrors.first('zoomAccount')]}},[(_vm.zoomSelectMode === 'auto')?_c('div',{directives:[{name:"validate",rawName:"v-validate:zoomAccountId",value:('required'),expression:"'required'",arg:"zoomAccountId"}],attrs:{"data-vv-name":"zoomAccount","data-vv-as":"Zoom"}}):_vm._e(),_vm._v(" "),(_vm.isFetchingZoomIntegration)?_c('div',[_c('am2-loading-section')],1):_c('div',[(_vm.displayZoomModeSelect)?_c('div',[_c('div',{class:[
            'u-margin-bottom-4' ]},[_c('ar-checkbox',{attrs:{"align":"left","type":"round-tick","label":"Create a new Zoom meeting automatically","label-typography-props":{
              size: 'sm',
            },"value":_vm.zoomSelectMode,"ticked-value":"auto"},on:{"input":_vm.handleZoomModeSelect}}),_vm._v(" "),(_vm.zoomSelectMode === 'auto')?_c('div',{class:[
              "u-display-flex",
              "u-margin-top-2" ],style:({
              marginLeft: '30px',
            })},[_c('ar-text',{staticClass:"u-margin-right-1",style:({
                color: _vm.$arStyle.color.skyBlueGrey700
              }),attrs:{"text":"Account:","size":"xxs"}}),_vm._v(" "),_c('am2-link-button-dropdown',{attrs:{"items":_vm.zoomAccountItems,"item-key":_vm.zoomAccountId,"placeholder":"Select Zoom accounts","search-placeholder":"Search Zoom accounts","has-search":"","no-option-text":"No Zoom accounts found","max-height":"300px","dropdown-style":{
                width: '280px',
              },"button-props":{
                hasArrow: true,
                hasUnderline: false,
                textProps: {
                  size: 'xxs',
                },
              }},on:{"select":_vm.handleZoomAccountSelect}})],1):_vm._e()],1),_vm._v(" "),_c('div',[_c('ar-checkbox',{attrs:{"align":"left","type":"round-tick","label":"Enter Zoom details manually","value":_vm.zoomSelectMode,"ticked-value":"manual","label-typography-props":{
              size: 'sm',
            }},on:{"input":_vm.handleZoomModeSelect}})],1)]):_vm._e(),_vm._v(" "),(_vm.zoomSelectMode === 'manual')?_c('div',{class:[
          "u-margin-bottom-6",
          _vm.displayZoomModeSelect && 'u-margin-top-6' ]},[_c('ar-field',{staticClass:"u-margin-top-2",attrs:{"label":"Zoom Meeting URL","error-messages":[_vm.veeErrors.first('zoom meeting url')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|zoomUrl'),expression:"'required|zoomUrl'"}],attrs:{"value":_vm.scratchEventRSVPForm.settings.platform.url,"autocomplete":"zoom-meeting-url","data-vv-name":"zoom meeting url","data-vv-as":"Zoom Meeting url","placeholder":"https://zoom.us/j/0123456789"},on:{"input":_vm.handleZoomManualUrl}})],1),_vm._v(" "),_c('div',{class:[
            "u-display-flex",
            "u-justify-content-space-between"
          ]},[_c('ar-field',{class:[
              "u-margin-top-5" ],style:({
              width: 'calc(50% - 6px)',
            }),attrs:{"label":"Zoom Meeting ID","error-messages":[_vm.veeErrors.first('zoom meeting id')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchEventRSVPForm.settings.platform.id,"autocomplete":"zoom-meeting-id","data-vv-name":"zoom meeting id","data-vv-as":"Zoom Meeting id","placeholder":"123456789"},on:{"input":_vm.handleZoomManualID}})],1),_vm._v(" "),_c('ar-field',{class:[
              "u-margin-top-5" ],style:({
              width: 'calc(50% - 6px)',
            }),attrs:{"label":"Zoom Meeting Password"}},[_c('ar-input',{attrs:{"value":_vm.scratchEventRSVPForm.settings.platform.password,"autocomplete":"zoom-meeting-password","placeholder":"000000"},on:{"input":_vm.handleZoomManualPassword}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.displayConnectZoomSection)?_c('div',[(_vm.zoomSelectMode === 'auto')?_c('ar-text',{attrs:{"size":"xs","text":"Connect your Zoom account, and we’ll automatically generate Zoom meetings for you.","multiple-lines":""}}):_vm._e(),_vm._v(" "),(_vm.zoomSelectMode === 'manual')?_c('ar-text',{class:["u-margin-top-6"],attrs:{"size":"xs","text":"Or connect your Zoom account, and we'll automatically generate Zoom meetings for you.","multiple-lines":""}}):_vm._e(),_vm._v(" "),_c('div',{class:[
            "u-margin-top-3" ]},[_c('ar-simple-button',{style:({
              width: '160px',
              height: '39px',
            }),attrs:{"text":'Connect Zoom',"icon-name":'zoom-outline',"icon-distance":"10px","icon-props":{
              height: '26px',
              color: 'white',
            },"type":"#4A8CFF"},on:{"click":_vm.handleConnectToZoom}})],1),_vm._v(" "),(_vm.zoomSelectMode === 'auto')?_c('ar-link-button',{staticClass:"u-margin-top-6",attrs:{"text":"Want to enter details manually instead?","text-props":{
            size: 'xs',
          }},on:{"click":function($event){return _vm.handleZoomModeSelect('manual')}}}):_vm._e()],1):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }