<template>
  <AuthenticateFrame>
    <ar-text
      text="Verifying your email"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '40px',
      }"
    />

    <am2-loading-bubble
      v-if="isVerifyingEmailAddress"
      :style="{
        color: $arStyle.color.purple500,
        marginLeft: '20px'
      }"
    />


  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthenticateFrame from './authenticate-frame';

export default {
  name: 'email-verify',
  
  layout: 'split',
  components: {
    AuthenticateFrame,
  },
  computed: {
    ...mapState({
      verifyToken: state => state.route.query.verifyToken,
      emailAddress: state => state.route.query.emailAddress,
      isVerifyingEmailAddress: state => state.auth.isVerifyingEmailAddress,
    }),
  },
  mounted() {
    // Update layout options
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);

    this.beginVerification();
  },
  methods: {
    ...mapActions([
      'auth/VERIFY_EMAIL_ADDRESS',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    async beginVerification() {
      this['auth/VERIFY_EMAIL_ADDRESS']({ emailAddress: this.emailAddress, verifyToken: this.verifyToken });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
