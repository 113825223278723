var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'unlock-prompt',
  _vm.size ]},[_c('div',{staticClass:"left-hand-side"},[_c('div',{staticClass:"icon-container"},[_c('ar-icon',{attrs:{"name":"lock","color":"#FFF","width":"24px"}})],1),_vm._v(" "),_c('div',{staticClass:"text-container"},[_c('am2-heading',{staticClass:"u-margin-bottom-2",style:({
          color: '#FFF',
        }),attrs:{"title":_vm.title,"size":"md","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
          color: '#FFF',
        }),attrs:{"text":_vm.subtitle,"size":"xs","color":"#FFF","multiple-lines":"","allow-html":"","anchor-color":"#FFF"}})],1)]),_vm._v(" "),_c('div',{staticClass:"right-hand-side"},[_c('ar-simple-button',{style:({
        border: 'none',
      }),attrs:{"text":_vm.buttonText,"outlined":""},on:{"click":_vm.handlePlanClick}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }