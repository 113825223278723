<template>
  <am2-item-multiselect
    to="card-sub-details"
    :items="events"
    @select="handleSelect"
    :defaultValue="defaultValue"
    :data-test-id="dataTestId"
    item-label="events"
    @updateSearchText="updateSearchText"
  />
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'EventMultiselect',

    props: {
      defaultValue: { // Value equals to eventOid
        type: Array,
        default: null,
      },
      dataTestId: {
        type: String,
        default: null,
      }
    },

    data() {
      return {
        events: [],
        searchString: null,
      };
    },

    computed: {
      ...mapState({
        auth: state => state.auth,
      }),
    },

    mounted() {
      this.loadEvents();
    },

    watch: {
      searchString() {
        this.loadEvents()
      }
    },

    methods: {
      handleSelect(item) {
        this.$emit('select', item);
      },

      updateSearchText(text) {
        this.searchString = text
      },

      async loadEvents() {
        const { promoterOid } = this.auth.account;
        try {
          const data = await this.$api.event.search(promoterOid, {
            top: 50,
            skip: 0,
            orderby: 'startDate desc',
            searchString: this.searchString,
          });
          this.events = data.map( item => {
            return {
              value: item.oid,
              name: item.name,
            }
          });
        } catch (error) {
          this.$arNotification.push({ type: 'error', message: `Something went wrong when searching for the event.` })
          console.error('Failed EVENT search, with error:', error);
        }
      },
    }

  };
</script>
