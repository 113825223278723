<template>
  <section class="connect-card">
    <div class="icon-section">
      <ar-icon
        :name="iconName"
        height="25px"
      />
    </div>
    <div class="content-section">
      <am2-heading
        class="title"
        type="h1"
        :title="title"
        size="sm"
      />
      <div class="right-section">
        <am2-signal-tag
          v-if="isConnected"
          type="green"
          text="Connected"
        />
        <ar-simple-button
          v-else
          type="grey"
          outlined
          shape="pill"
          class="connect-button"
          text="Connect"
          :loading="connecting"
          @click="handleButtonClick"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ConnectCard',
  props: {
    iconName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    connecting: {
      type: Boolean,
      default: false,
    },
    isConnected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('connect');
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-card {
  display: flex;
  height: 70px;
  background: white;
  box-shadow: 0 2px 15px 0 rgba(67,81,107,0.15);

  .icon-section {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    background: rgba(#F6F8FA, 0.5);
  }
  .content-section {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 15px 0 30px;

    .title {
      flex-grow: 1;
    }

    .right-section {
      display: flex;
      align-items: center;
      padding-right: 15px;
      .connect-button {
        width: 100px;
        height: 40px;
      }
    }
  }
}
</style>
