<template>
  <am2-card-container
    :class="[
      'ar-upgrade-plan-section-wrapper',
      design
    ]">
    <ar-icon
      :name="featureData.iconName"
      :color="$arStyle.color.purple500"
      :wrapper-style="{
        display: 'inline-flex',
        alignItem: 'center',
        justifyContent: 'center',
        width: design === 'row' ? '50px' : '60px',
        height: design === 'row' ? '50px' : '60px',
        boxShadow: design === 'column' ? `0 0 8px 0 ${$arStyle.color.blueGrey500}` : null,
        borderRadius: '50%',
        background: design === 'row' ? $arStyle.color.skyBlueGrey300 : '#FFF',
      }"
    />
    <div class="text-section">
      <ar-text
        :class="[
          'title',
          design === 'column' && 'u-margin-top-5 u-margin-bottom-3',
        ]"
        size="md"
        multiple-lines
        :text="featureData.title"
        :align="design === 'row' ? 'left' : 'center'"
        weight="bold"
      />
      <ar-text
        :class="[
          'description',
          design === 'column' && 'u-margin-bottom-4',
        ]"
        size="xs"
        :text="featureData.description"
        :align="design === 'row' ? 'left' : 'center'"
        multiple-lines
        allow-html
        line-height="25px"
        :style="{
          color: design === 'column' ? $arStyle.color.skyBlueGrey800 : null,
        }"
      />
    </div>
    <div class="button-section">
      <ar-simple-button
        data-test-id="upgrade-plan-section-button"
        type="green"
        :icon-name="featureData.buttonIconName"
        :icon-props="{
          width: featureData.buttonIconName ? '14px' : null,
        }"
        :text="featureData.buttonText"
        @click="handleUpgradeButtonClick"
      />
    </div>
  </am2-card-container>
</template>

<script>

const featureDataMap = {
  'audience-insights': {
    buttonText: 'Upgrade now',
    buttonIconName: 'lock',
    iconName: 'insights-graph',
    title: 'Unlock powerful audience insights',
    description: 'Unlock powerful insights about your entire audience that can be used to drive ticket sales, <br>improve your marketing and help secure valuable sponsorship dollars. <a target="__blank" href="https://audiencerepublic.com/insights">Learn more about insights</a>',
  },
  'event-insights': {
    buttonText: 'Upgrade now',
    buttonIconName: 'lock',
    iconName: 'insights-graph',
    title: 'Unlock powerful event insights',
    description: 'Unlock powerful event and audience insights that can be used to drive ticket sales, <br>improve your marketing and help secure valuable sponsorship dollars. <a>Learn more about events</a>',
  },
  'campaign-insights': {
    buttonText: 'Upgrade now',
    buttonIconName: 'lock',
    iconName: 'insights-graph',
    title: 'Unlock powerful campaign insights',
    description: 'Unlock powerful campaign and audience insights that can be used to drive ticket sales, <br>improve your marketing and help secure valuable sponsorship dollars. <a>Learn more about events</a>',
  },
  'message-center': {
    buttonText: 'Upgrade now',
    buttonIconName: 'lock',
    iconName: 'message-center',
    title: 'Want to message your audience by Email or SMS?',
    description: 'Upgrade now to get access to the messaging platform, or click New Message to give it a test drive.',
  },
  'automation': {
    buttonText: 'Contact sales',
    iconName: 'sync',
    title: 'Want to create automations and email sequences?',
    description: 'Contact sales to unlock powerful automation tools to engage with your existing audiences.',
  },
};

export default {
  name: 'UpgradePlanSection',

  props: {
    featureType: {
      type: String,
      validator: (val) => ['audience-insights', 'event-insights', 'campaign-insights', 'message-center', 'automation'].indexOf(val) > -1,
    },
    design: {
      type: String,
      default: 'row',
      validator: (val) => ['row', 'column'].indexOf(val) > -1,
    },
  },

  computed: {
    featureData() {
      return featureDataMap[this.featureType];
    },
  },

  methods: {
    handleUpgradeButtonClick() {
      this.$emit('buttonClick')
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-upgrade-plan-section-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &.row {
    flex-direction: row;
    padding: 40px 40px 26px 40px;
  }
  &.column {
    flex-direction: column;
    align-items: center;
    padding: 60px 40px 50px 40px;
  }

  .text-section {
    max-width:100%;
    flex-grow: 1;
    margin-bottom:12px;


    .title {
      margin-top: 10px;
    }

    .description {
      margin-top: 14px;
    }
  }
  .button-section {
    flex-shrink: 0;
    margin-top: 0;
    margin-bottom:12px;
  }

}
</style>
