var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],class:['campaigns-wrapper',
    _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},[_c('div',{staticClass:"campaigns"},[_c('div',{class:[
        'campaigns-page-top',
        _vm.$arMediaQuery.window.minWidth('md') && 'window-md-min' ]},[_c('am2-top',{attrs:{"dropdown":_vm.dropdown,"search":{ action: _vm.serverSideSearch, value: _vm.queryParams.searchString, placeholder: _vm.searchPlaceholderText },"title":"Campaigns"},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.queryParams.tab === 'tour')?_c('ar-simple-button',{style:({
              width: '180px'
            }),attrs:{"text":_vm.createButtonText,"data-test-id":"create-tour-button-campaign-top"},on:{"click":_vm.goToTourSetup}}):_c('am2-create-campaign-button',{attrs:{"text":_vm.createButtonText,"data-test-id":"create-campaign-button-campaign-top"}})]},proxy:true}])})],1),_vm._v(" "),(_vm.displayCampaignControls)?_c('div',{staticClass:"campaigns-controls",style:({
        marginTop: '18px',
      })},[_c('div',{staticClass:"campaigns-controls__subcontrol"},[_c('am2-button-group-tabs',{style:({
            width: '100px',
            maxHeight: _vm.$arMediaQuery.pageContent.minWidth('sm') ? null : 0,
            marginBottom: _vm.$arMediaQuery.pageContent.minWidth('sm') ? 'auto' : '50px',
            border: _vm.$arMediaQuery.pageContent.minWidth('sm') ? null : 0,
          }),attrs:{"active-index":_vm.queryParams.showAs === 'list' ? 1 : 0,"items":_vm.groupButtonsSelectItems,"data-test-id":"list-type-tabs"},on:{"select":_vm.handleGroupButtonSelectClick}})],1)]):_vm._e(),_vm._v(" "),_c('div',{style:({
        marginTop: _vm.displayCampaignControls ? (_vm.promoterHasTours ? '-13px' : '17px') : (_vm.$arMediaQuery.window.minWidth('md') ? '30px' : '22px'),
        marginBottom: '22px',
      })},[(_vm.promoterHasTours)?_c('am2-tabs',{staticClass:"tabs",attrs:{"selected-tab-index":_vm.selectedTabIndex,"items":_vm.tabs},on:{"select":_vm.changeTab}}):_vm._e(),_vm._v(" "),_c('ar-divider')],1),_vm._v(" "),(_vm.queryParams.tab === 'campaign')?_c('div',{staticClass:"cards-container-wrapper",class:[
        _vm.queryParams.showAs === 'tiles' && 'tiles',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max tiles' ],style:({
        marginTop: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '28px' : '0',
      })},[(_vm.hasNoData)?[(_vm.hasSearchConditions)?_c('am2-no-content-section',{staticClass:"campaigns-no-content-wrapper",attrs:{"header":"No campaigns found","header-size":"sm"}}):_c('am2-no-content-section',{staticClass:"campaigns-no-content-wrapper",attrs:{"icon-name":"ar-campaign-in-circle","header":_vm.campaignNoContentText.header,"body":_vm.campaignNoContentText.body},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('am2-create-campaign-button',{style:({
                marginTop: '30px',
              }),attrs:{"text":_vm.createButtonText,"data-test-id":"create-campaign-button-campaign-no-content"}})]},proxy:true}],null,false,3584879995)})]:_c('div',{class:[
          'cards-container',
          _vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_vm._l((_vm.sortedCampaignsOid),function(campaignKey){return _c('am2-campaign-card',{key:campaignKey,staticClass:"item-card",class:[
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',"card-size":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'xs' : 'regular',"campaign":_vm.rows[campaignKey]},on:{"delete":_vm.handleCampaignDelete}})}),_vm._v(" "),_vm._l((_vm.isFetchingData ? 6 : 0),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card--campaigns",class:[
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"type":"campaign","show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',"level":_vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})})],2)],2):_vm._e(),_vm._v(" "),(_vm.queryParams.tab === 'tour')?_c('div',{staticClass:"cards-container-wrapper",class:[
        _vm.queryParams.showAs === 'tiles' && 'tiles',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],style:({
        marginTop: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '28px' : '0',
      })},[(_vm.hasNoData)?[(_vm.hasSearchConditions)?_c('am2-no-content-section',{staticClass:"tours-no-content-wrapper",attrs:{"header":"No tours found","header-size":"sm"}}):_c('am2-no-content-section',{staticClass:"tours-no-content-wrapper",attrs:{"header":"Create a tour","button-text":"Create tour"},on:{"buttonClick":_vm.goToTourSetup}})]:_c('div',{class:[
          'cards-container',
          _vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_vm._l((_vm.isFetchingData ? 6 : 0),function(n){return _c('am2-card-skeleton',{key:n,staticClass:"base-plan-card--tours",class:[
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"type":"campaign","show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',"level":_vm.$arMediaQuery.pageContent.maxWidth('xs') || _vm.queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)}})}),_vm._v(" "),_vm._l((_vm.rows),function(tour){return _c('am2-tour-card',{key:tour.oid,staticClass:"item-card",attrs:{"show-as":_vm.$arMediaQuery.pageContent.minWidth('sm') ? _vm.queryParams.showAs : 'tiles',"card-size":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'xs' : 'regular',"tour":tour},on:{"delete":_vm.handleTourDelete}})})],2)],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }