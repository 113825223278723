<template>
  <am2-tag
    :text="text"
    :type="type"
    shape="round"
    :icon-name="hideDot ? null : 'dot'"
    :icon-animation="'blink'"
    :icon-props="{
      height: '10px',
    }"
    :style="{
      padding: `8px 15px 8px 15px`,
    }"
  />
</template>

<script>
export default {
  name: 'SignalTag',
  props: {
    text: {
      type: String,
      required: true,
    },
    hideDot: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      default: 'green',
    }
  },
};
</script>
