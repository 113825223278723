var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"email-edit-page"},[_c('am2-advanced-settings-modal',{attrs:{"title":"Advanced Settings","is-show":_vm.showAdvancedSettingsModal,"type":"signup-form"},on:{"close":_vm.closeAdvancedSettingsClick,"input":_vm.handleAdvancedSettingsModalConfirm}}),_vm._v(" "),_c('div',{class:[
      'header-section',
      _vm.displayMobilePreview && 'now-visible' ]},[_c('am2-top-create',{style:({
        position: 'relative',
      }),attrs:{"type":_vm.isEditSignupForm ? "purple" : "white","total-steps":_vm.isEditSignupForm ? null : _vm.totalStep,"current-step":_vm.currentStep,"hasAdvancedSettings":true,"handleAdvancedSettingsClick":_vm.handleAdvancedSettingsClick,"exit-path":"/message-center/forms","title":_vm.pageTitle}})],1),_vm._v(" "),_c('div',{staticClass:"body-section"},[_c('div',{class:[
        'edit-section',
        _vm.hasNoSpaceForRightPreview && 'full-size',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[_c('div',{ref:"content",class:[
          'edit-top-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[_c('div',{staticClass:"page-content"},[_c('nuxt-child',{ref:"modify-signup-form-page"})],1)]),_vm._v(" "),_c('div',{class:[
          'edit-navigation-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size' ]},[(_vm.currentStep === 1)?_c('ar-simple-button',{style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }),attrs:{"text":"Cancel","outlined":""},on:{"click":_vm.handleCancelClick}}):(_vm.currentStep > 1)?_c('ar-simple-button',{style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }),attrs:{"text":"Back","outlined":""},on:{"click":_vm.handlePreviousStepClick}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-display-flex"},[(_vm.hasNoSpaceForRightPreview)?_c('ar-simple-button',{staticClass:"u-margin-right-3",style:({
              width: _vm.$arMediaQuery.window.minWidth('sm') ? '135px' : '90px',
            }),attrs:{"text":_vm.rightPreviewIsToggledUp ? 'Edit' : 'Preview',"outlined":"","icon-name":(_vm.rightPreviewIsToggledUp || _vm.$arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview',"icon-distance":"10px","icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            }},on:{"click":_vm.handlePreviewClick}}):_vm._e(),_vm._v(" "),(_vm.currentStep < _vm.totalStep)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: false,
              action: _vm.handleNextStepClick,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: false,\n              action: handleNextStepClick,\n            }"}],style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }),attrs:{"text":"Next"},on:{"click":_vm.handleNextStepClick}}):(_vm.currentStep === _vm.totalStep)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: false,
              action: _vm.handleSaveOptInFormClick,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: false,\n              action: handleSaveOptInFormClick,\n            }"}],style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }),attrs:{"text":'Finish',"loading":_vm.isPatchingSignupForm || _vm.isCreatingSignupForm},on:{"click":_vm.handleSaveOptInFormClick}}):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{class:[
      'right-preview-section',
      _vm.hasNoSpaceForRightPreview && 'go-on-top-of-edit-section',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'small-window-size',
      _vm.displayMobilePreview && 'is-visible'
    ]},[_c('am2-campaign-preview',{ref:"campaign-preview",attrs:{"has-account":false,"has-fan":false,"has-footer":!_vm.hasNoSpaceForRightPreview,"preview-page":'/',"campaign":_vm.prunedScratchSignupFormForPreview,"size":"fullHeight"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }