<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      ref="modal"
      :is-open="isShow"
      class="edit-privacy-portal-modal"
      :header="modalHeader"
      :has-back-link="step > 1"
      width="550px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
      @back="handleBackClick"
    >
      <div
        slot="body"
        class="modal-body"
      >
        <StepOne
          v-if="step === 1"
          ref="step-1"
        />
        <StepTwo
          v-if="step === 2"
          ref="step-2"
        />
      </div>
      <div
        slot="footer"
        class="modal-footer"
      >
        <ar-simple-button
          v-if="step !== 2"
          text="Next"
          :loading="isValidating"
          :style="{
            width: '100%',
          }"
          @click="handleNextClick"
        />
        <ar-simple-button
          v-if="step === 2"
          text="Done"
          :style="{
            width: '100%',
          }"
          :loading="isValidating || isCreatingPrivacyPortal || isUpdatingPrivacyPortal"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

export default {
  name: 'EditPrivacyPortalModal',
  components: {
    StepOne,
    StepTwo,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    privacyPortal: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      step: 1,
      isValidating: false,
    };
  },
  computed: {
    ...mapState({
      account: state => state.auth.account,
      scratchPrivacyPortal: state => state.privacyPortal.scratchPrivacyPortal,
      isCreatingPrivacyPortal: state => state.privacyPortal.isCreatingPrivacyPortal,
      isUpdatingPrivacyPortal: state => state.privacyPortal.isUpdatingPrivacyPortal,
    }),
    isNewPrivacyPortal() {
      return !this.privacyPortal?.oid;
    },
    modalHeader() {
      return this.isNewPrivacyPortal ? 'Setup Privacy Portal' : 'Privacy Portal Settings';
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.initializeModalSettings();
      }
    },
    step() {
      if (this.$refs.modal) {
        this.$refs.modal.scrollToTop();
      }
    },
  },

  methods: {
    ...mapActions([
      'privacyPortal/CREATE_PRIVACY_PORTAL',
      'privacyPortal/UPDATE_PRIVACY_PORTAL',
    ]),
    ...mapMutations([
      'privacyPortal/SET_SCRATCH_PRIVACY_POLICY',
      'privacyPortal/RESET_SCRATCH_PRIVACY_POLICY',
    ]),
    initializeModalSettings() {
      this.step = 1;
      if (this.privacyPortal) { // Has PrivacyPortal
        this['privacyPortal/SET_SCRATCH_PRIVACY_POLICY'](this.privacyPortal);
      } else { // Has no PrivacyPortal
        this['privacyPortal/RESET_SCRATCH_PRIVACY_POLICY']({
          emailAddress: this.account.emailAddress,
        });
      }
    },
    async validateFields() {
      this.isValidating = true;
      // Have to do "reset", otherwise it causes weird infinite loop
      await this.$refs[`step-${this.step}`].$validator.reset();
      const isValid = await this.$refs[`step-${this.step}`].$validator.validateAll();
      this.isValidating = false;
      return isValid;
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    async handleNextClick() {
      const valid = await this.validateFields();
      if (!valid) {
        return;
      }
      this.step += 1;
    },
    handleBackClick() {
      this.step -= 1;
    },
    async handleDoneClick() {
      const valid = await this.validateFields();
      if (!valid) {
        return;
      }

      if (this.isNewPrivacyPortal) {
        const succeed = await this['privacyPortal/CREATE_PRIVACY_PORTAL'](this.scratchPrivacyPortal);
        if (succeed) {
          this.$emit('create');
        }
      } else {
        const succeed = await this['privacyPortal/UPDATE_PRIVACY_PORTAL']({
          oid: this.scratchPrivacyPortal.oid,
          changes: this.scratchPrivacyPortal,
        });
        if (succeed) {
          this.$emit('update');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-privacy-portal-modal {

  .modal-body {
    padding: 31px 32px;
  }

  .modal-footer {
    padding: 23px 31px 29px;
  }
}
</style>

