<template>
  <div class="am2-icon-wrapper" @click="handleClick(icon)">
    <template v-if="icon === 'zoom-in'">
      <ZoomIn 
        :fill="fill" 
        :class="`am2-icon-${icon}`"
        :height="height"
        :width="width"
        :style="{...styles}"
      />
    </template>
    <template v-if="icon === 'zoom-out'">
      <ZoomOut
        :fill="fill" 
        :class="`am2-icon-${icon}`"
        :height="height"
        :width="width"
        :style="{...styles}"
      />
    </template>
    <template v-if="icon === 'select'">
      <SelectIcon
        :fill="fill" 
        :class="`am2-icon-${icon}`"
        :height="height"
        :width="width"
        :style="{...styles}"
      />
    </template>
    <template v-if="icon === 'pan'">
      <PanIcon
        :fill="fill" 
        :class="`am2-icon-${icon}`"
        :height="height"
        :width="width"
        :style="{...styles}"
      />
    </template>
    <template v-if="icon === 'undo'">
      <UndoIcon
        :fill="fill" 
        :class="`am2-icon-${icon}`"
        :height="height"
        :width="width"
        :style="{...styles}"
      />
    </template>
  </div>
</template>
<script>
import ZoomIn from '~/assets/icons/zoom-in.svg?inline'
import ZoomOut from '~/assets/icons/zoom-out.svg?inline'
import SelectIcon from '~/assets/icons/select-icon.svg?inline'
import PanIcon from '~/assets/icons/grab.svg?inline'
import UndoIcon from '~/assets/icons/undo-arrow.svg?inline'
export default {
  name: 'ApexToolbarIcon',
  components: {
    ZoomIn,
    ZoomOut,
    SelectIcon,
    PanIcon,
    UndoIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: '#fff',
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    styles: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    handleClick(icon) {
      this.$emit('click', icon)
    }
  }
}
</script>
<style lang="scss" scoped>
.am2-icon-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>