import { Plugin } from 'prosemirror-state';

class SelectionSizeTooltip {
  constructor(editorView, {
    toggleMuneBubble,
    menuBubbleDoc,
  }) {
    this.tooltip = document.createElement('div');
    this.tooltip.style.position = 'absolute';
    this.tooltip.style.zIndex = '20';
    this.tooltip.appendChild(menuBubbleDoc);
    editorView.dom.parentNode.appendChild(this.tooltip);

    this.toggleMuneBubble = toggleMuneBubble;
  }

  async update(editorView, lastState) {
    let state = editorView.state;
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
        lastState.selection.eq(state.selection)) return;

    // Hide the tooltip if the selection is empty
    if (state.selection.empty) {
      // this.tooltip.style.display = 'none'
      this.toggleMuneBubble(false);
      return;
    }

    await this.toggleMuneBubble(true);

    // Otherwise, reposition it and update its content
    this.tooltip.style.display = '';
    let { from, to } = state.selection;
    // The padding
    const padding = 10;
    // These are in screen coordinates
    const startPos = editorView.coordsAtPos(from);
    const endPos = editorView.coordsAtPos(to);
    // The box in which the tooltip is positioned, to use as base
    const boxRect = this.tooltip.offsetParent.getBoundingClientRect();
    const tooltipRect = this.tooltip.getBoundingClientRect();

    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    const centerYAxisOfSelection = (startPos.left + endPos.left) / 2 - boxRect.left;
    const expectedLeft = centerYAxisOfSelection - (tooltipRect.width / 2);
    const expectedTop = startPos.top - boxRect.top - tooltipRect.height - 10;
    this.tooltip.style.left = (expectedLeft < padding ? padding : expectedLeft) + 'px';
    this.tooltip.style.top = expectedTop + 'px';
    this.tooltip.style.overflow = 'hidden';
    // this.tooltip.textContent = to - from
  }

  destroy() {
    this.tooltip.remove();
  }
}

export const createMenuBubblePlugin = function ({
  toggleMuneBubble,
  menuBubbleDoc,
}) {
  return new Plugin({
    view(editorView) {
      return new SelectionSizeTooltip(editorView, {
        toggleMuneBubble,
        menuBubbleDoc,
      });
    },
  });
}
