<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-dice-access-token-modal"
      header="Connect To DICE"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-dice-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          class="u-display-flex u-margin-bottom-3 dice-modal-wrapper"
        >
          <ar-text
            size="xs"
            text="Subscribe associated contacts to list (optional)"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="{
              content: `Automatically subscribe all attendees of events from this DICE account.`,
            }"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>

        <ar-field
          :error-messages="[
            veeErrors.first('messageList')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="messageList"
            :channels="['email']"
          />
        </ar-field>
        <ar-field
          label="Access Token"
          class="u-margin-top-5"
        >
          <ar-input
            v-model="accessToken"
            name="accessToken"
            placeholder="Please enter your DICE access token"
            id="dice-token-input"
            type="password"
            data-test-id="dice-token-input"
          />
        </ar-field>
      </div>
      <div
        slot="body"
        :class="[
          'ask-dice-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-text
            class="u-margin-top-2"
          size="xs"
          text="More settings"
          weight="bold"
          :style="{
            color: $arStyle.color.blueGrey800,
          }"
        />
        <am2-switch-field-card
          class="u-margin-top-4"
          title="Automatically apply tags to fans imported from DICE"
          subtitle="Tags will be customised per artist, genre & venue"
          :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
          :value="applyPrefix"
          :display-field="applyPrefix"
          @input="toggleApplyPrefix"
          data-test-id="dice-automatic-tag-switch"
        >
          <ar-field>
            <div
              v-if="applyPrefix"
              class="u-margin-top-5"
            >
              <ar-field class="u-margin-top-6">
                <ar-text
                  size="xs"
                  text="Enable customised tag names on"
                  weight="bold"
                  :style="{
                    color: $arStyle.color.blueGrey700,
                  }"
                />
                <div
                  class="u-display-flex u-justify-content-flex-start u-margin-top-3"
                >
                  <ar-checkbox
                    label="Artists"
                    key="artists"
                    class="u-margin-right-5"
                    v-model="tagNameType.artists"
                    data-test-id="dice-automatic-tag-artists"
                  />
                  <ar-checkbox
                    label="Genres"
                    key="genres"
                    class="u-margin-right-5"
                    v-model="tagNameType.genres"
                    data-test-id="dice-automatic-tag-genres"
                  />
                  <ar-checkbox
                    label="Venues"
                    key="venues"
                    v-model="tagNameType.venues"
                    data-test-id="dice-automatic-tag-venues"
                  />
                </div>
              </ar-field>

              <ar-field>
                <div class="u-display-flex u-margin-bottom-3 dice-modal-wrapper u-margin-top-5">
                  <ar-text
                    size="xs"
                    text="Tag prefix (optional)"
                    :style="{
                      color: $arStyle.color.blueGrey700,
                    }"
                    weight="bold"
                    multiple-lines
                  />
                  <ar-icon
                    v-tooltip.top="{
                      content: `We suggest to start with 'DICE-' as a prefix to be clear that the audience attended a DICE event.`,
                    }"
                    name="tooltip-question-mark"
                    class="u-margin-left-2"
                    height="15px"
                    width="15px"
                    :color="$arStyle.color.purple500"
                  />
                </div>
                <ar-input
                  placeholder="Please enter your tag prefix"
                  :value="tagName"
                  @input="handleTagNameInput"
                  autocomplete="organization"
                  data-vv-name="tagName"
                  data-vv-as="tag name"
                  type="text"
                  data-test-id="dice-automatic-tag-prefix"
                />
                <ar-text
                  size="xxs"
                  :text="`For example, if you set ‘DICE’ as the prefix, the tags imported into audience republic for the genre ‘Rock’ will appear as dice-rock against the fan record.`"
                  :style="{
                    color: $arStyle.color.blueGrey700,
                  }"
                  class="u-margin-top-3"
                  multiple-lines
                />
              </ar-field>
            </div>
          </ar-field>
        </am2-switch-field-card>
      </div>
      <div
        slot="footer"
        :class="[
          'ask-dice-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
          id="dice-modal-confirm-button"
          data-test-id="dice-modal-confirm-button"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessToken: null,
      messageList: null,
      applyPrefix: false,
      tagName: null,
      tagNameType: {
        artists: false,
        genres: false,
        venues: false,
      },
    };
  },

  computed: {
    confirmButtonDisabled() {
      if (this.applyPrefix) {
        // Artists, genres, and venues are 3 category checkboxes for the dice tagnames.
        // If none of these three are checked, then the confirm button should be disabled.
        // If at least one is checked, it should also confirm whether access token is present.
        return !(!!this.tagNameType.artists || !!this.tagNameType.genres || !!this.tagNameType.venues) || !this.accessToken;
      }

      return !this.accessToken;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.accessToken = null;
        this.messageList = null;
        this.applyPrefix = false;
        this.tagName = null;
        this.tagNameType = {
          artists: false,
          genres: false,
          venues: false,
        }
      }
    },
  },

  methods: {
    handleConfirmClick() {
      let params = {
        accessToken: this.accessToken,
        messageList: this.messageList,
      }

      if (this.applyPrefix) {
        params.tagFans = {
          ...this.tagNameType,
          prefix: this.tagName,
        }
      }

      this.$emit('confirm', params)
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    toggleApplyPrefix() {
      this.applyPrefix = !this.applyPrefix;
    },
    handleTagNameInput(name) {
      this.tagName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-dice-access-token-modal {
  .ask-dice-access-token-modal-body {
    padding: 20px 30px 30px;

    &:nth-child(2) {
      border-top: 1px solid $blueGrey500;
    }
  }
  .ask-dice-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
