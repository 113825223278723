<template>
  <section>
    <am2-unlock-prompt
      v-if="!isMessageFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Messaging` : `Messaging`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `All-in-one messaging platform across Email Marketing and SMS. <a href='https://audiencerepublic.com/messaging' target='_blank' style='color: #FFF;'>Learn more</a>` : `All-in-one messaging platform. <a href='https://audiencerepublic.com/messaging' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 2,
      }"
    />
    <am2-navbar
      :menu="menu"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />

    <nuxt-child
      class="content--centered"
    />

  </section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'MessageCenter',

  scrollToTop: true,

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    menu() {
      const res = {
        messages: {
          name: 'Messages',
          to: '/message-center/messages',
          featureKeys: null,
        },
        lists: {
          name: 'Lists',
          to: '/message-center/lists',
        },
        forms: {
          name: 'Signup Forms',
          to: '/message-center/forms',
        },
        templates: {
          name: 'Templates',
          to: '/message-center/templates',
        },
      };
      return res;
    },
    isMessageFeatureEnabled() {
      return this.isFeatureEnabled(['messages']);
    },
  },

  methods: {
    async goToPlans() {
      this.$router.push({
        path: `/settings/subscriptions`,
      });
    },
  },
};
</script>
