<template>
  <am2-dropdown-binder
    :align="align"
    :pixel-shift="5"
    button-name="am2-avatar-button"
    :button-props="buttonProps"
    :dropdown-props="{
      items,
      style: dropdownStyle,
      itemStyle: {
        padding: '10px 15px',
        height: '44px',
      },
      arrowDistance: '19px',
    }"
    @select="handleItemSelect"
  />
</template>
<script>
export default {
  name: 'AvatarButtonDropdown',
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].indexOf(val) > -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    buttonProps: {
      type: Object,
      default: () => ({
        avatarProps: {
          name: {
            firstName: null,
            lastName: null,
          },
        },
      }),
    },
    dropdownStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleItemSelect(item) {
      this.$emit('select', item);
    },
  },
}
</script>

