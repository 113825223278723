<template>
  <section
    class="event-attendees-container"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <div
      class="u-display-flex u-justify-content-space-between"
      :style="{
        padding: '22px 0 22px',
        backgroundColor: '#F7F9FC',
      }"
      v-ar-sticky-top="{
        priority: 1,
      }"
    >
      <am2-heading
        type="h1"
        size="md"
        :title="`Attendees`"
        :style="{
          marginRight: '12px',
        }"
      />
      <div class="attendees-integration-wrapper" v-if="currentIntegration">
        <div class="attendees-integration-logo u-margin-right-2">
          <ar-icon
            :name="'zoom'"
            :color="$arStyle.color[currentIntegration]"
            height="16px"
            width="16px"
          />
        </div>
        <ar-text
          text="Attendees have been synced."
          size="xs"
        />
      </div>
    </div>

    <am2-table-control-section
      v-ar-sticky-top="{
        priority: 1,
      }"
      :style="{
        marginTop: '8px',
        backgroundColor: '#f6f9fc',
      }"
      :fans-search="{
        action: serverSideSearch,
      }"
      :column-settings="{
        selectedColumnKeys: selectedColumnKeys,
        availableColumns: availableColumns,
        action: handleSelectedColumnKeysUpdate,
      }"
      :loading="(isFetchingAudience) && audience.length === 0"
      show-checked-rows-options-bar
      show-filter-and-search-bar
      :showFilterButton="false"
      :csvExport="{
        action: handleExportClick,
      }"
    />

    <am2-zoom-attendance-table
      v-if="currentIntegration == 'zoom'"
      :head="displayedTableHeadings"
      :body="tableBody"
      :loading="isFetchingAudience && tableBody.length === 0"
      has-index
      empty-text="No attendee data"
      @sortBy="handleSortChange"
      :enable-row-click="customerProfilesEnabled"
      @rowClick="handleRowClick"
      has-sticky-header
      has-control-section
    />
    <am2-fan-table
      v-else
      :head="displayedTableHeadings"
      :body="tableBody"
      :loading="isFetchingAudience && tableBody.length === 0"
      has-index
      empty-text="No attendee data"
      @sortBy="handleSortChange"
      :enable-row-click="customerProfilesEnabled"
      @rowClick="handleRowClick"
      has-sticky-header
      has-control-section
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { magicDownload } from '@/utils/html-element/';

const initialOrderBy = { key: 'sysMtime', order: 'desc' };

const tableHeadings = [
  {
    name: 'Name',
    sortKey: 'firstName',
    key: 'firstName',
    format: 'name',
    attributeKeys: ['firstName','lastName']
  },
  {
    name: 'Email',
    key: 'emailAddress',
    format: 'emailAddress',
    sortKey: 'emailAddress',
    attributeKeys: 'emailAddress',
  },
  {
    name: 'Mobile',
    key: 'mobileNumber',
    format: 'text',
    sortKey: 'mobileNumber',
    attributeKeys: 'mobileNumber',
  },
];

const zoomTableHeadings = [
  {
    name: 'Name',
    sortKey: 'firstName',
    key: 'firstName',
    format: 'name',
    attributeKeys: ['firstName','lastName']
  },
  {
    name: 'Email',
    key: 'emailAddress',
    format: 'emailAddress',
    attributeKeys: 'emailAddress',
  },
  {
    name: 'Mobile',
    key: 'mobileNumber',
    format: 'text',
    attributeKeys: 'mobileNumber',
  }
]

export default {
  name: 'EventAudience',

  data() {
    return {
      tableHeadings,
      zoomTableHeadings,
      orderBy: initialOrderBy,
      searchString: '',
      displayedColumnKeys: tableHeadings.map(heading => heading.key),
      filter: {
        logic: [],
        conditions: [
          {
            name: 'events',
            type: 'condition-search-picker',
            data: {
              condition: 'true_to_all',
              values: [ this.$route.params.oid ],
            },
          },
        ],
      },
      exportKeys: ['emailAddress', 'firstName', 'lastName', 'mobileNumber', 'dob', 'streetAddress', 'postcode', 'city', 'state', 'country', 'ticketSales', 'tickets'],
      currentIntegration: null,
      selectedColumnKeys: [],
      availableColumns: [
        {
          name: 'Name',
          value: 'firstName',
          disabled: true,
        },
        {
          name: 'Email',
          value: 'emailAddress',
        },
        {
          name: 'Mobile',
          value: 'mobileNumber',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      audience: state => state.audience.audience,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      isFetchingAudience: state => state.audience.isFetchingAudience,
      isNoMoreAudience: state => state.audience.isNoMoreAudience,
    }),
    infiniteScrollDisabled() {
      return this.isFetchingAudience || this.isNoMoreAudience;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    fanAttributeKeys() {
      let res = [];

      for (let i = 0; i < this.tableHeadings.length; i += 1) {
        const heading = this.tableHeadings[i];
        if (this.displayedColumnKeys.includes(heading.key) && heading.attributeKeys) {
          if (Array.isArray(heading.attributeKeys)) {
            res = res.concat(heading.attributeKeys);
          } else {
            res.push(heading.attributeKeys);
          }
        }
      }

      return res;
    },

    tableBody() {
      if (!this.audience) {
        return [];
      }

      return this.audience.map(attendee => {
        attendee.avatar = attendee.profileImageUrl;

        return attendee;
      });
    },

    displayedTableHeadings() {
      const headings = this.currentIntegration == 'zoom' ? this.zoomTableHeadings : this.tableHeadings

      return headings.filter(heading => {
        if (this.selectedColumnKeys && this.selectedColumnKeys.includes(heading.key)) {
          return heading;
        }
      });
    },
  },

  created() {
    if (this.currentEvent && this.currentEvent.provider) {
      this.currentIntegration = this.currentEvent.provider
    }

    this.$store.registerModule("event-view-child", {
      mutations: {
        FORCED_EVENT_UPDATE(state) {
          this.loadMoreData();
        }
      }
    });
    if (!!this.currentEvent) {
      this.reloadData();
    }

    this.selectedColumnKeys = this.availableColumns.reduce((keys, { value }) => {
      keys.push(value);
      return keys;
    }, [])
  },

  watch: {
    currentEvent(event, oldEvent) {
      if (event && event.provider) {
        this.currentIntegration = event.provider
      }
      if (!oldEvent && !!event) {
        this.reloadData();
      }
      const ticketStatsCount = this.currentEvent?.ticketStats[0]?.attendees;
      if (ticketStatsCount !== null) {
        this['audience/SET_AUDIENCE_COUNT'](ticketStatsCount);
      }
    }
  },

  beforeDestroy() {
    this['audience/RESET_AUDIENCE']();
  },

  methods: {
    ...mapActions([
      'audience/FETCH_MORE_AUDIENCE',
      'audience/FETCH_ZOOM_ATTENDANCE',
      'audience/EXPORT_AUDIENCE_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'audience/RESET_AUDIENCE',
      'audience/SET_AUDIENCE_COUNT'
    ]),

    async handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      this.orderBy = {
        key,
        order,
      };
      this.reloadData()
    },

    loadMoreData() {
      if (this.currentIntegration == null) {
        this['audience/FETCH_MORE_AUDIENCE']({
          searchString: this.searchString,
          selectKeys: this.fanAttributeKeys,
          filter: this.filter,
          orderBy: this.orderBy,
          selectKeys: this.fanAttributeKeys,
        });
      } else if (this.currentIntegration == 'zoom') {
        this['audience/FETCH_ZOOM_ATTENDANCE']({
          eventOid: this.$route.params.oid,
          searchString: this.searchString,
          selectKeys: this.fanAttributeKeys,
          filter: this.filter,
          orderBy: this.orderBy,
          selectKeys: this.fanAttributeKeys,
        });
      }
    },

    async serverSideSearch(searchString) {
      this.searchString = searchString;
      this.reloadData();
    },

    reloadData() {
      if (this.isFetchingAudience) return; // Don't reload if we're already fetching
      if (this.currentIntegration == null) {
        this['audience/FETCH_MORE_AUDIENCE']({
          searchString: this.searchString,
          selectKeys: this.fanAttributeKeys,
          filter: this.filter,
          orderBy: this.orderBy,
          reload: true,
          skipCount: true,
        });
      } else if (this.currentIntegration == 'zoom') {
        this['audience/FETCH_ZOOM_ATTENDANCE']({
          eventOid: this.$route.params.oid,
          searchString: this.searchString,
          selectKeys: this.fanAttributeKeys,
          filter: this.filter,
          orderBy: this.orderBy,
          reload: true,
        });
      }
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleExportClick() {
      this['audience/EXPORT_AUDIENCE_CSV'](
        {
          filter: this.filter,
          searchString: this.searchString,
          selectKeys: this.exportKeys,
          orderBy: this.orderBy || { key: 'ticketSales', order: 'desc' },
        }
      );
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 1250);
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;
      this.reloadData();
    },
  },
};
</script>

<style lang="scss">
.event-attendees-container {
  padding: 0 10px;
  margin-bottom: 30px !important;

  .registrants-top-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 27px;

    .registrants-top {
      width: calc(100% - 30px);
      margin: 0;
    }

    .registrants-menu-button {
      background: $skyBlueGrey400;
      top: 0;

      .registrants-menu-button-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 30px;
        height: 30px;
      }

      .registrants-menu-button-text {
        display: inline-block;
        vertical-align: middle;
        top: 2px;
      }
    }
  }

  .attendees-integration-wrapper {
    display: flex;
    align-items: center;

    .attendees-integration-logo {
      width: 26px;
      height: 26px;
      background-color: white;
      border: 1px solid $blueGrey500;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 10px 0 rgba(67,81,107,0.2);
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }
}
</style>
