<template>
  <am2-link-button-dropdown
    :items="timezoneItems"
    :item-key="realVal"
    placeholder="Select timezone"
    search-placeholder="Search timezone"
    has-search
    no-option-text="No timezones found"
    max-height="300px"
    :dropdown-style="{
      width: '280px',
    }"
    @select="handleTimezoneSelect"
    @searchStringChange="handleSearchStringChange"
  />
</template>

<script>
import timezones from '@/utils/timezones';
import supportedTimezones from './timezones';
export default {
  name: "AmTimezoneDropdown",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      realVal: null,
      searchString: '',
    };
  },
  computed: {
    // Temporary filter out timezones that are not supported
    // by the backend library joda.time to avoid exceptions and
    // failing actions.
    // When we'll switch to latest java.time, please remove this filter.
    validTimezones() {
      return timezones.filter(({ name }) => {
        return supportedTimezones.includes(name);
      });
    },
    timezoneItems() {
      return this.validTimezones
        .filter(({ name }) => {
          return name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
        })
        .map(timezone => {
          return {
            ...timezone,
            key: timezone.value,
          };
        });
    },
  },

  watch: {
    value: {
      handler(newVal) {
        this.realVal = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    handleSearchStringChange(newSearchString) {
      this.searchString = newSearchString;
    },
    handleTimezoneSelect(timezone) {
      this.realVal = timezone.value;
      this.$emit('input', timezone.value);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
