var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"edit-privacy-policy-modal",attrs:{"is-open":_vm.opened,"header":"Edit Privacy Policy","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'edit-privacy-policy-modal-body' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{ref:"fields"},[_c('ar-field',{attrs:{"label":"Company","error-messages":[
            _vm.veeErrors.first('companyName') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: !!_vm.url,
            }),expression:"{\n              required: !!url,\n            }"}],attrs:{"data-vv-name":"companyName","data-vv-as":"Company name","placeholder":"Company name"},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})],1),_vm._v(" "),_c('ar-field',{style:({
            marginTop: '25px',
          }),attrs:{"label":"Privacy Policy URL","error-messages":[
            _vm.veeErrors.first('url') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: !!_vm.companyName,
              url: { require_protocol: true },
            }),expression:"{\n              required: !!companyName,\n              url: { require_protocol: true },\n            }"}],attrs:{"data-vv-name":"url","data-vv-as":"Privacy Policy URL","placeholder":"Privacy Policy URL"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1)]),_vm._v(" "),_c('div',{class:[
        'edit-privacy-policy-modal-footer' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Save"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }