<template>
  <div class="email-preview">
    <img
      v-if="mobile"
      src="~/assets/images/message-center/email-preview-header.png"
      width="100%"
      height="auto"
    >
    <div
      v-if="!opened"
      class="unopened-email"
      :style="{
        borderBottom: !!senderName || !!subject || !!previewText ? null : 'none',
      }"
    >
      <div
        v-if="!!senderName"
        class="blue-ball"
      />
      <ar-text
        size="sm"
        :text="senderName"
        :style="{
          color: 'black',
        }"
      />
      <ar-text
        size="xs"
        :text="subject"
        :style="{
          color: 'black',
          marginTop: '8px',
        }"
      />
      <ar-text
        size="xs"
        :text="previewText"
        multiple-lines
        :max-lines="2"
        :style="{
          color: $arStyle.color.blueGrey700,
          marginTop: '6px',
        }"
      />
    </div>
    <div
      v-else
      class="opened-email"
    >
      <div class="header">
        <div class="from-sender" v-if="!!senderName">
          <ar-text
            size="xs"
            text="From:"
            :style="{
              color: 'black',
            }"
          />
          <ar-text
            size="xs"
            :text="senderName"
            :style="{
              color: '#007AFF',
              marginLeft: '2px',
            }"
          />
        </div>
        <ar-divider
          :style="{
            marginTop: mobile ? '18px' : '24px',
          }"
        />
        <ar-text
          size="xs"
          :text="subject"
          :style="{
            color: 'black',
            marginTop: '18px',
          }"
        />
        <ar-text
          size="xxxs"
          :text="scheduledAtCopy"
          :style="{
            color: '#ACACAC',
            marginTop: '12px',
          }"
        />
        <ar-divider
          :style="{
            marginTop: mobile ? '18px' : '24px',
          }"
        />
      </div>
      <div
        class="iframe-wrapper"
      >
        <iframe
          ref="email-iframe"
          width="100%"
          height="100%"
          frameborder="0"
          :style="{
            marginBottom: '-6px',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { iosMailDateFormat } from '@/utils/date/';
import { injectPreviewContact, beautifyDynamicTagsSyntax } from '../utils';

export default {
  name: 'EmailPreview',

  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
    senderName: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    previewText: {
      type: String,
      default: null,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    emailTemplate: {
      type: String,
      default: null,
    },
    disabledLinks: {
      type: Array,
      default: () => [
        '{{ar_unsubscribe_link}}',
      ],
    },
    scheduledAt: {
      type: String,
      default: null,
    },
    previewContact: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    scheduledAtCopy() {
      return iosMailDateFormat(this.scheduledAt);
    },
  },

  mounted() {
    this.updateIframe();
  },

  watch: {
    opened(val) {
      if (val) {
        this.$nextTick(this.updateIframe);
      }
    },
    emailTemplate: {
      handler(val) {
        if (val) {
          this.$nextTick(this.updateIframe);
        }
      },
      immediate: true,
    },
    previewContact() {
      this.$nextTick(this.updateIframe);
    },
  },

  methods: {
    injectPreviewContact(message) {
      if (!this.previewContact) return message;
      let finalMsg = message;
      Object.keys(this.previewContact).forEach(key => {
        finalMsg = finalMsg.replace(new RegExp(`{{${key}}}`, 'g'), this.previewContact[key]);
      });
      return finalMsg;
    },
    disableLinks(doc) {
      this.disabledLinks.forEach((link) => {
        const linkElem = doc.querySelectorAll(`a[href="${link}"]`)[0];
        if (linkElem) {
          linkElem.style['pointer-events'] = 'none';
        }
      });
    },
    updateIframe() {
      if (!this.emailTemplate || !this.opened) {
        return;
      }
      let modifiedEmailTemplate = null;
      if (this.previewContact) {
        modifiedEmailTemplate = injectPreviewContact(this.emailTemplate, this.previewContact);
      } else {
        modifiedEmailTemplate = beautifyDynamicTagsSyntax(this.emailTemplate);
      }
      const doc = this.$refs['email-iframe'].contentWindow.document;
      doc.open();
      doc.write(modifiedEmailTemplate);
      this.disableLinks(doc);
      doc.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.email-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .unopened-email {
    position: relative;
    padding: 20px 13px 23px 33px;
    height: 129px;
    border-bottom: 1px solid $skyBlueGrey500;

    .blue-ball {
      display: inline-block;
      position: absolute;
      top: 23px;
      left: 14px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #007AFF;
    }
  }

  .opened-email {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    .header {
      padding: 0 16px;
      margin-top: 16px;

      .from-sender {
        display: flex;
      }
    }
    .iframe-wrapper {
      padding: 0 16px;
      overflow: auto;
      flex: 1;
    }
  }
}
</style>

