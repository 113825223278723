<template>
  <div class="settings-subscriptions">
    <div>
      <div class="first-title-section">
        <ar-text
          size="md"
          text="Your Plan"
        />
      </div>
      <am2-card-skeleton
        v-if="displayPlanCardSkeletons"
        class="base-plan-card"
        :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
        :style="{
          height: '122px',
        }"
      />
      <div
        v-else-if="advancedPaymentPlanSubscriptionInformation.basePaymentSubscription"
        v-for="basePlan of advancedPaymentPlanSubscriptionInformation.basePaymentPlans"
        :key="basePlan.puid"
        class="base-plan-card"
      >
        <am2-base-plan-card
          v-if="basePlan.paymentSubscriptions[0]"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
          :payment-plan="basePlan"
          :payment-subscription="basePlan.paymentSubscriptions[0]"
          :add-on-subscriptions="advancedPaymentPlanSubscriptionInformation.addOnPaymentSubscriptions"
          :primary-payment-source="primaryPaymentSource"
          @subscriptionStateUpdate="handleSubscriptionStateUpdate"
        />
      </div>
      <div
        v-else
        class="base-plan-card"
      >
        <am2-base-plan-card
          :base-plan="null"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
        />
      </div>
      <!-- Waiting for confirmation of add-ons -->
      <div class="second-title-section">
        <ar-text
          size="md"
          text="Additional options"
        />
      </div>
      <div
        v-if="displayPlanCardSkeletons"
        class="add-on-cards-section"
      >
        <am2-card-skeleton
          v-for="n in 5"
          :key="n"
          :class="[
            'add-on-card',
            $arMediaQuery.pageContent.maxWidth('sm') && 'full-width',
          ]"
          show-as="tiles"
          :level="$arMediaQuery.pageContent.maxWidth('xs') ? 2 * n : 2 * Math.ceil(n / 2)"
          :style="{
            height: '309px',
          }"
        />
      </div>
      <div v-else class="add-on-cards-section">
        <am2-add-on-plan-card
          v-for="(addOnPlan, idx) of enabledPaymentPlans"
          :key="idx"
          :payment-plan="addOnPlan"
          :primary-payment-source="primaryPaymentSource"
          :base-subscription="advancedPaymentPlanSubscriptionInformation.basePaymentSubscription"
          :size="$arMediaQuery.pageContent.maxWidth('sm') ? 'small' : 'regular'"
          :class="[
            'add-on-card',
            $arMediaQuery.pageContent.maxWidth('sm') && 'full-width',
          ]"
          @subscriptionStateUpdate="handleSubscriptionStateUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  layout: 'default',
  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      paymentPlanList: state => state.payment.paymentPlanList,
      isFetchingPaymentPlans: state => state.payment.isFetchingPaymentPlans,
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      isFetchingPaymentSubscriptions: state => state.payment.isFetchingPaymentSubscriptions,
    }),
    ...mapGetters({
      primaryPaymentSource: 'payment/primaryPaymentSource',
      advancedPaymentPlanSubscriptionInformation: 'payment/advancedPaymentPlanSubscriptionInformation',
    }),
    displayPlanCardSkeletons() {
      return this.isFetchingPaymentPlans || this.isFetchingPaymentSubscriptions;
    },
    enabledPaymentPlans() {
      return this.advancedPaymentPlanSubscriptionInformation.addOnPaymentPlans.filter((paymentPlan) => {
        if (!paymentPlan.featureIds || paymentPlan.featureIds.length === 0) {
          return false;  
        }

        return !paymentPlan.featureIds.includes('facebook-messenger')
      })
    }
  },

  async created() {
    this.initializeDatas();

  },

  methods: {
    ...mapActions([
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'payment/FETCH_PAYMENT_PLANS',
      'payment/FETCH_PAYMENT_CUSTOMER',
    ]),
    ...mapMutations([
      'payment/SET_PAYMENT_CUSTOMER',
    ]),
    async initializeDatas() {
      try {
        await Promise.all([this['payment/FETCH_PAYMENT_PLANS']({}), this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']({}), this['payment/FETCH_PAYMENT_CUSTOMER']()]);
      } catch (e) {
        console.error(e);
      }
    },
    handleSubscriptionStateUpdate() {
      this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']();
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-subscriptions {
  max-width: 800px;
  margin: 59px auto 0;

  .loading-section {
    height: 122px;
  }

  .first-title-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .second-title-section {
    margin-top: 54px;
  }

  .base-plan-card {
    margin-top: 26px;
  }

  .add-on-cards-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 26px;

    .add-on-card {
      flex-basis: calc(50% - 20px);
      flex-shrink: 0;
      margin-bottom: 40px;

      &.full-width {
        flex-basis: unset;
        width: 100%;
      }
    }
  }
}
</style>
