var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-edit-details"},[_c('am2-import-dynamic-tags-modal',{attrs:{"is-show":_vm.showImportDynamicTagsModal,"message-list-oid":_vm.scratchSimpleMessage.meta.messageListOid,"channel":"sms"},on:{"import":_vm.handleDynamicTagsImport,"cancel":function($event){_vm.showImportDynamicTagsModal = false}}}),_vm._v(" "),_c('ar-field',{attrs:{"label":"Message name"}},[_c('ar-input',{attrs:{"value":_vm.scratchSimpleMessage.customerName,"autocomplete":"message-name","data-test-id":"message-name-input"},on:{"input":_vm.handleCustomerNameInput}})],1),_vm._v(" "),_c('ar-field',{style:({ marginTop: '40px' })},[_c('div',{staticClass:"u-display-flex u-justify-content-space-between u-align-items-center",style:({
        marginBottom: '8px',
      })},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"To","weight":"bold","multiple-lines":"","max-lines":1}})],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"u-display-flex u-align-items-center u-justify-content-space-between"},[_c('am2-message-list-select',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null
          }),expression:"{\n            content: dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null\n          }",modifiers:{"top":true}}],ref:"message-list-select",class:[
            'message-list-recipients-dropdown',
            'u-flex-grow-1',
            _vm.showFilterButton && 'filter-enabled'
          ],attrs:{"value":_vm.scratchSimpleMessage.meta.messageListOid,"campaign-oid":_vm.scratchSimpleMessageCampaignOid,"event-oid":_vm.scratchSimpleMessageEventOid,"channels":['sms'],"data-test-id":"message-list-select"},on:{"select":_vm.handleMessageListSelect}}),_vm._v(" "),(_vm.showFilterButton)?_c('div',{staticClass:"circle-hooker item"},[(_vm.filterIsNotEmpty && !!_vm.filterCount)?_c('div',{staticClass:"circle"},[_c('span',[_vm._v(_vm._s(_vm.filterCount))])]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"filter-btn u-margin-left-3",attrs:{"icon-name":"filter-alt","side-length":"40px","icon-props":{
              height: '16px',
            },"text":"Filter","type":_vm.filterIsNotEmpty ? 'purple' : 'grey',"outlined":"","data-test-id":"audience-table-filter-button","disabled":!this.scratchSimpleMessage.meta.messageListOid},on:{"click":_vm.switchFilterSidebar}})],1):_vm._e()],1),_vm._v(" "),(_vm.noContacts)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"There are no valid mobile phone numbers in this list"}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessage && _vm.currentMessageListIsForAllCampaignRegistrants)?_c('ar-snackbar',{staticClass:"u-margin-top-5",attrs:{"type":"warning","message":"Changing from All Recipients to a Tier will disable scheduling."}}):_vm._e(),_vm._v(" "),(_vm.currentCampaignIsActive && _vm.currentMessageListIsForCampaignTier)?_c('ar-state-message',{staticClass:"u-margin-top-5",attrs:{"text-props":{
          weight: 'bold',
        },"has-icon":"","disable-text-color":"","type":"information","text":"Note: It's recommended to only message individual tiers once the campaign is ended."}}):_vm._e()],1)]),_vm._v(" "),(_vm.showAdvancedTargetingModule)?_c('am2-message-list-advanced-targeting-section',{staticClass:"u-margin-padding-top-1",attrs:{"channel":"sms","show-toggle":""},on:{"updateAdvancedTargeting":_vm.handleAdvancedTargetingUpdate}}):_vm._e(),_vm._v(" "),(_vm.areFiltersNotEmpty)?_c('div',{staticClass:"recipients-count u-margin-top-4 u-padding-left-4"},[(_vm.isFetchingFilteredRecipientListCount && _vm.currentAdvancedTargetingIsValid)?_c('am2-loading-spinner',{style:({
        width: '1.5em',
        height: '1.5em'
      })}):_c('div',{staticClass:"recipients-count"},[_c('ar-icon',{staticClass:"sales-chart-settings-icon settings-button u-margin-right-3",attrs:{"name":"team","width":"16px","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{staticClass:"recipients-text",attrs:{"size":"xs","data-test-id":"message-targeting-recipient-count","text":_vm.recipientCountText}}),_vm._v(" "),_c('div',{staticClass:"u-margin-left-2"},[_vm._v("·")]),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-left-2",attrs:{"text":"Clear all","has-underline":"","data-test-id":"message-list-clear-all-filters"},on:{"click":_vm.handleClearAllFilters}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-position-relative",style:({
      marginTop: '40px',
    })},[_c('div',{staticClass:"u-position-absolute u-display-flex u-justify-content-flex-end",style:({ top: 0, right: 0 })},[_c('am2-link-button-dropdown',{attrs:{"items":_vm.dynamicTagSelectItems,"button-props":{
          hasArrow: false,
          hasUnderline: true,
        },"item-style":{
          height: '44px',
          padding: '0 20px',
        },"divider-style":{
          margin: '0',
        },"disabled":_vm.availableDynamicTagsInSimpleMessage.length === 0,"align":_vm.$arMediaQuery.window.maxWidth('xs') ? 'left' : 'right',"placeholder":"Insert dynamic tag","dropdown-style":{
          padding: '0',
        },"data-test-id":"insert-dynamic-tag-link"},on:{"select":_vm.handleInsertDynamicTagsSelect}})],1),_vm._v(" "),_c('ar-field',{style:({
        marginTop: '40px',
      }),attrs:{"name":"message-body","label":"Message","error-messages":[_vm.veeErrors.first('messageBody')]}},[_c('SimpleMessageEditor',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          max: _vm.scratchSimpleMessage.meta.messageBody.length + _vm.smsMessagePreview.charactersLeft,
        }),expression:"{\n          required: true,\n          max: scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft,\n        }"}],ref:"simple-message-editor",attrs:{"dynamic-tags":_vm.availableDynamicTagsInSimpleMessage,"value":_vm.scratchSimpleMessage.meta.messageBody,"data-vv-name":"messageBody"},on:{"input":_vm.handleMessageBodyInput}}),_vm._v(" "),(_vm.nonExistingTagsInSimpleMessageErrorCopy)?_c('ar-snackbar',{style:({
          marginTop: '10px',
        }),attrs:{"type":"error","message":_vm.nonExistingTagsInSimpleMessageErrorCopy}}):_vm._e(),_vm._v(" "),_c('am2-characters-left-copy-section',{style:({
          marginTop: '10px',
        }),attrs:{"current-length":_vm.scratchSimpleMessage.meta.messageBody.length,"tooltip":"The character count below excludes the opt out message, which gets automatically appended to your SMS.","max-length":_vm.scratchSimpleMessage.meta.messageBody.length + _vm.smsMessagePreview.charactersLeft,"template":(_vm.smsMessagePreviewLength + " characters / " + _vm.smsMessagePreviewSegmentsCount + " SMS")}})],1)],1),_vm._v(" "),(_vm.fallbackDynamicTagsForScratchSimpleMessage.length !== 0)?_c('ar-field',{staticClass:"u-margin-top-5",attrs:{"label":"Fallback dynamic tags"}},[(_vm.displayFallbackDynamicTagsField)?_c('div',_vm._l((_vm.fallbackDynamicTagsForScratchSimpleMessage),function(tag){return _c('ar-field',{key:tag,staticClass:"u-margin-bottom-3",attrs:{"label":tag,"error-messages":[_vm.veeErrors.first(tag)]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
          }),expression:"{\n            required: true,\n          }"}],attrs:{"placeholder":("Enter fallback value of " + tag),"value":_vm.scratchSimpleMessage.meta.fallbackDynamicTags[_vm.convertToCamelCase(tag)],"data-vv-name":tag,"data-vv-as":(tag + " fallback tag"),"data-test-id":("fallback-dynamic-tag-" + tag)},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
_vm.handleFallbackDynamicTagsInput.apply(void 0, [ _vm.convertToCamelCase(tag) ].concat( argsArray ))}}})],1)}),1):_vm._e(),_vm._v(" "),_c('ar-link-button',{attrs:{"text":_vm.displayFallbackDynamicTagsField ? 'Hide all fields' : 'Show all fields',"disabled":!_vm.isFallbackDynamicTagsCompleteInSimpleMessage,"data-test-id":_vm.displayFallbackDynamicTagsField ? 'hide-dynamic-fields-button' : 'display-dynamic-fields-button'},on:{"click":function($event){_vm.displayFallbackDynamicTagsField = !_vm.displayFallbackDynamicTagsField}}})],1):_vm._e(),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Schedule?"}},[_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: !_vm.currentMessageListCanBeSchedued && !_vm.enableSchedule ? 'You cannot schedule messages from campaigns' : null,
      }),expression:"{\n        content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,\n      }",modifiers:{"top":true}}],attrs:{"value":_vm.enableSchedule,"label":"Schedule a time to send this message","disabled":_vm.currentSelectedMessageUiStatus === 'scheduled' || !_vm.currentMessageListCanBeSchedued,"data-test-id":"toggle-enable-schedule-checkbox"},on:{"input":_vm.handleEnableScheduleToggle}}),_vm._v(" "),(_vm.enableSchedule)?_c('div',{class:[
        'schedule-input-group',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],style:({
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
      })},[_c('ar-field',{class:[
          'u-color-blueGrey700',
          'u-flex-shrink-0' ],style:({
          margin: '0',
        }),attrs:{"label":"Start time","error-messages":[_vm.veeErrors.first('scheduledAt')]}},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|tzFormat|afterNow|after5MinFromNow'),expression:"'required|tzFormat|afterNow|after5MinFromNow'"}],attrs:{"value":_vm.scratchSimpleMessage.scheduledAt,"time-zone":_vm.scratchSimpleMessage.meta.presentation.timeZone,"data-vv-name":"scheduledAt","data-vv-as":"scheduled time","data-test-id":"message-scheduled-at"},on:{"input":_vm.handleScheduledAtInput}})],1),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('md'))?_c('ar-divider',{style:({
          position: 'relative',
          top: '38px',
          width: '50px',
          margin: '0 10px',
        })}):_vm._e(),_vm._v(" "),_c('ar-field',{staticClass:"time-zone",class:[
          'u-flex-shrink-0' ],style:({
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"label":"Timezone","error-messages":[_vm.veeErrors.first('timeZone')]}},[_c('am2-timezone-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchSimpleMessage.meta.presentation.timeZone,"data-vv-name":"timeZone","data-test-id":"message-timezone-select"},on:{"input":_vm.handleTimezoneInput}})],1)],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
      'cost-container',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],style:({
      marginTop: '70px',
      marginLeft: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '0' : '-12px',
    })},[_c('ar-checkbox',{attrs:{"value":_vm.costAccepted,"label":"I agree to charge my credit card","disabled":_vm.smsCostFailedToFetch,"data-test-id":"accecpt-cost-checkbox"},on:{"change":_vm.emitCostDecisionChangeEvent}}),_vm._v(" "),_c('div',{staticClass:"cost"},[(_vm.smsCostFailedToFetch)?_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'There was a problem while calculating the cost of your SMS. Please try again.'
        }),expression:"{\n          content: 'There was a problem while calculating the cost of your SMS. Please try again.'\n        }",modifiers:{"top":true}}],staticClass:"value",attrs:{"size":"sm","weight":"bold","allow-html":"","text":"<a>Error - Try again</a>"},on:{"anchorClick":_vm.handleTryFetchingCostAgain}}):(!_vm.isFetchingSmsCost)?_c('ar-text',{staticClass:"value",attrs:{"size":"sm","weight":"bold","text":((_vm.smsMessagePreview.cost.currency) + " " + (_vm.formatCurrency(_vm.smsMessagePreview.cost.total)))}}):_vm._e(),_vm._v(" "),(_vm.isFetchingSmsCost)?_c('am2-loading-spinner',{style:({
          width: '1.5rem',
          height: '1.5rem',
          marginRight: '1.25rem'
        })}):_vm._e(),_vm._v(" "),(!_vm.smsCostFailedToFetch)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'This is the total cost for sending this message to all recipients'
        }),expression:"{\n          content: 'This is the total cost for sending this message to all recipients'\n        }",modifiers:{"top":true}}],staticClass:"tooltip",attrs:{"name":"tooltip-question-mark","height":"20px","width":"20px"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }