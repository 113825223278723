<template>
  <div class="slider-input">
    <div class="slider-zone">
      <div
        v-if="hasPanel"
        class="image-wrapper"
        :style="{
          marginRight: '10px',
        }"
        @click="minusValue"
        @mousedown="keepMinusingValue"
      >
        <ar-icon
          name="minus"
          width="16px"
          :color="$arStyle.color.skyBlueGrey700"
        />
      </div>
      <div class="slider">
        <Slider
          :value="realValue"
          :min="min"
          :max="max"
          :is-value-visible="false"
          :track-color="trackColor"
          @input="changeValue"
        />
      </div>
      <div
        v-if="hasPanel"
        class="image-wrapper"
        :style="{
          marginLeft: '10px',
        }"
        @click="addValue"
        @mousedown="keepAddingValue"
      >
        <ar-icon
          name="plus"
          width="16px"
          :color="$arStyle.color.skyBlueGrey700"
        />
      </div>
    </div>
    <div class="captionZone" align="center">
      <slot name="caption" />
    </div>
  </div>
</template>

<script>
import Slider from './slider';

export default {
  name: 'SliderPanel',
  components: {
    Slider,
  },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    hasPanel: {
      type: Boolean,
      default: false,
    },
    trackColor: {
      type: String,
      default: 'grey',
      validator: (val) => ['grey', 'green', 'purple'].indexOf(val) > -1,
    },
  },

  data() {
    return {
      realValue: null,
    };
  },

  watch: {
    value: {
      handler: function (val) {
        this.realValue = val;
      },
      immediate: true,
    },
  },

  mounted() {
    window.addEventListener('mouseup', this.removeMouseDownListener);
  },

  methods: {
    changeValue(value) {
      this.realValue = parseInt(value, 10);
      this.$emit('input', parseInt(value, 10));
    },
    removeMouseDownListener() {
      if (this.keepAddingInterval) {
        clearInterval(this.keepAddingInterval);
      }
      if (this.keepMinusingInterval) {
        clearInterval(this.keepMinusingInterval);
      }
    },
    keepAddingValue() {
      this.keepAddingInterval = setInterval(() => {
        this.addValue();
      }, 50);
    },
    keepMinusingValue() {
      this.keepMinusingInterval = setInterval(() => {
        this.minusValue();
      }, 50);
    },
    addValue() {
      let newValue = this.realValue + 1;
      if (newValue > this.max) {
        newValue = this.max;
      }
      this.realValue = newValue;
      this.$emit('input', newValue);
    },
    minusValue() {
      let newValue = this.realValue - 1;
      if (newValue < this.min) {
        newValue = this.min;
      }
      this.realValue = newValue;
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-input {
  .slider-zone {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider {
      width: 100%;
    }

    .image-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}
</style>
