<template>
  <div
    name="am2-prose-mirror-editor"
    class='am2-rich-text-editor'
  >
    <slot
      :commands="commands"
      :activities="activities"
    />
    <div
      ref='editor'
      :class="[
        'editor',
        isEditorActive && 'active',
      ]"
    >
      <div ref="select-bubble" v-show="displayMenuBubble">
        <slot
          v-if="editorViewIsReady"
          name='selection-bubble'
          :editor-view="editorView"
          :commands="commands"
          :activities="activities"
          :getAttrs="getAttrs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import { Schema, DOMParser, DOMSerializer } from 'prosemirror-model';
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import { history, undo, redo } from "prosemirror-history";
import { createMenuBubblePlugin } from './plugins/menu-bubble-plugin';
import { createInputRulesPlugin } from './plugins/input-rules-plugin';
import { clone } from '@/utils/helpers/'

export default {
  name: 'RichTextEditor',
  props: {
    content: {
      type: String,
      default: '',
    },
    schema: {
      type: Object,
      default: () => {},
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    keyMap: {
      type: Object,
      default: () => baseKeymap,
    },
    contentTransformer: { // You can transform your content before it's rendered
      type: Function,
      default: null,
    },
    contentRestorer: { // You can restore your content before it's emitted
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isEditorActive: false,
      isMenuBubbleActive: false,
      editorView: null,
      commands: this.generateCommands(),
      activities: this.generateActivities(),
      getAttrs: this.generateGetAttrs(),
    };
  },
  computed: {
    editorViewIsReady() {
      return !!this.editorView;
    },
    displayMenuBubble() {
      return this.isMenuBubbleActive;
    },
    finalSchema() {
      const result = clone(this.schema);
      this.extensions.forEach(extension => {
        if (extension.type === 'mark') {
          result.marks[extension.name] = extension.schema;
        }
        if (extension.type === 'node') {
          result.nodes[extension.name] = extension.schema;
        }
      });
      return new Schema(result);
    },
  },
  watch: {
    content(newContent) {
      if (newContent !== this.getContentHtml(this.editorView.state.doc.content)) {
        // If content and real content in ProseMirror doesn't match, we manually update it.
        this.updateEditorViewState();
      }
    },
    schema() {
      this.$nextTick(() => {
        this.updateEditorViewState();
      });
    },
    extensions() {
      this.$nextTick(() => {
        this.updateEditorViewState();
      });
    },
    keyMap() {
      this.$nextTick(() => {
        this.updateEditorViewState();
      });
    },
  },
  methods: {
    // This method is used by paraents
    runCommand(commandName, params) {
      this.commands[commandName](params);
    },
    generateCustomKeymap() {
      const finalKeyMap = clone(this.keyMap);
      this.extensions.forEach(extension => {
        if (!extension.keys) {
          return;
        }
        const keymap = extension.keys({
          schema: this.finalSchema,
        });
        Object.keys(keymap).forEach(key => {
          finalKeyMap[key] = keymap[key];
        });
      });
      return finalKeyMap
    },
    generateInputRules() {
      let rules = [];
      this.extensions.forEach(extension => {
        if (!extension.inputRules) return;
        const inputRules = extension.inputRules({ schema: this.finalSchema });
        if (inputRules.length === 0) return;
        rules = rules.concat(inputRules);
      });
      return rules;
    },
    generateNodeViews() {
      const newNodeViews = {};
      this.extensions.forEach(extension => {
        if (!extension.nodeView) {
          return;
        }
        newNodeViews[extension.name] = extension.nodeView;
      });
      return newNodeViews;
    },
    generateCommands() {
      const newCommands = {};
      this.extensions.forEach(extension => {
        if (!extension.commands) {
          return;
        }
        newCommands[extension.name] = (attrs) => {
          extension.commands({ schema: this.finalSchema, view: this.editorView })(attrs);
        };
      });
      return newCommands;
    },
    generateActivities() {
      const newActivities = {};
      this.extensions.forEach(extension => {
        if (!extension.activity) {
          return;
        }
        newActivities[extension.name] = (attrs) => {
          if (!this.editorView) { return false; }
          return extension.activity({ schema: this.finalSchema, view: this.editorView })(attrs);
        };
      });
      return newActivities;
    },
    generateGetAttrs() {
      const newGetAttrs = {};
      this.extensions.forEach(extension => {
        if (!extension.getAttrs) {
          return;
        }
        newGetAttrs[extension.name] = (attrName) => {
          return extension.getAttrs({ schema: this.finalSchema, view: this.editorView })(attrName);
        };
      });
      return newGetAttrs;
    },
    getContentDoc(content) {
      let transformedContent = content;
      if (this.contentTransformer) {
        transformedContent = this.contentTransformer(content);
      }
      const mockDiv = document.createElement('div');
      mockDiv.innerHTML = transformedContent;
      return DOMParser.fromSchema(this.finalSchema).parse(mockDiv);
    },
    getContentSlice(content) {
      let transformedContent = content;
      if (this.contentTransformer) {
        transformedContent = this.contentTransformer(content);
      }
      const mockDiv = document.createElement('div');
      mockDiv.innerHTML = transformedContent;
      return DOMParser.fromSchema(this.finalSchema).parseSlice(mockDiv);
    },
    getContentHtml(content) {
      const div = document.createElement('div');
      const fragment = DOMSerializer
        .fromSchema(this.finalSchema)
        .serializeFragment(content);

      div.appendChild(fragment);
      let html = div.innerHTML
      if (this.contentRestorer) {
        html = this.contentRestorer(html);
      }
      return html;
    },
    emitContent() {
      this.$emit('update', this.getContentHtml(this.editorView.state.doc.content));
    },
    updateEditorViewState() {
      const newState = this.createEditorState();
      this.editorView.updateState(newState);
      this.commands = this.generateCommands();
      this.activities = this.generateActivities();
      this.getAttrs = this.generateGetAttrs();
      this.emitContent();
    },
    createEditorState() {
      const histKeymap = keymap({ "Mod-z": undo, "Mod-y": redo });
      return EditorState.create({
        doc: this.getContentDoc(this.content),
        plugins: [
          histKeymap,
          keymap(this.generateCustomKeymap()),
          history(),
          createInputRulesPlugin({
            rules: this.generateInputRules(),
          }),
          createMenuBubblePlugin({
            toggleMuneBubble: this.toggleMuneBubble,
            menuBubbleDoc: this.$refs['select-bubble'],
          }),
        ],
      });
    },
    createEditorView() {
      return new EditorView(this.$refs.editor, {
        state: this.createEditorState(), // Initial state
        nodeViews: this.generateNodeViews(),
        // We have to update "commands", "activities" and "getAttrs" when state changes
        dispatchTransaction: (tr) => {
          const newState = this.editorView.state.apply(tr);
          this.editorView.updateState(newState);
          this.commands = this.generateCommands();
          this.activities = this.generateActivities();
          this.getAttrs = this.generateGetAttrs();
          this.emitContent();
        },
        handlePaste: (view, event) => {
          const slice = this.getContentSlice(event.clipboardData.getData('Text'));
          const tr = view.state.tr;
          tr.replaceSelection(slice);
          view.dispatch(tr);
          return true;
        },
        // We want promoters to get full html when copying to clipboard, instead of just text
        clipboardTextSerializer: (slice) => {
          return this.getContentHtml(slice.content);
        },
        handleDOMEvents: {
          focus: () => {
            this.isEditorActive = true;
            return false;
          },
          blur: () => {
            this.isEditorActive = false;
            return false;
          },
        },
      });
    },
    async toggleMuneBubble(opened) {
      this.isMenuBubbleActive = opened;
      await this.$nextTick();
      return true;
    },
    initProseMirrorEditor() {
      if (!this.$refs.editor) {
        return;
      }
      this.editorView = this.createEditorView();
    },
  },
  async mounted() {
    this.initProseMirrorEditor();
  },
};
</script>

<style lang='scss' scoped>
.am2-rich-text-editor {
  width: 100%;
  height: 100%;
  background: white;

  .editor {
    width: 100%;
    height: 100%;
    /* Important - This has to be pre-wrap, otherwise it breaks on Firefox */
    white-space: pre-wrap !important;
    ::v-deep {
      .ProseMirror {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid $skyBlueGrey500;
        border-radius: 0.25rem;
        padding: 10px 14px;
        outline: none;
        overflow: auto;

        * {
          &::selection{
            background: $purple100;
          }
        }
      }
    }

    &.active {
      ::v-deep {
        .ProseMirror {
          border: 1px solid $green500;
          box-shadow: 0 0 0 3px $green200;
        }
      }
    }
  }
}
</style>
