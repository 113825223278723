import { ActionTree } from 'vuex'
// @ts-ignore
import * as Papa from 'papaparse';
import { RootState } from '@/store/modules/types';
import { createTextFile } from '@/utils/html-element/';
import { isValidTzDate } from '@/utils/date/';
import { MessageState } from './types';
import {
  recursivelyPurifyUnlayerDesign,
  getDyanmicTagsFromContent,
} from '@/store/modules/message/utils';

export const messageActions: ActionTree<MessageState, RootState> = {
  async FETCH_ADDITIONAL_DYNAMIC_TAGS({ rootState, commit }) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;

    try {
      const additionalDynamicTags = await this.$api.messages.fetchAdditionalDynamicTags(promoterOid);
      commit('SET_ADDITIONAL_DYNAMIC_TAGS', additionalDynamicTags);
    } catch (err) {
      console.error(err);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch default dynamic tags.' });
      return false;
    } finally {
      // TODO ?
    }
  },
  async SEND_OUT_MESSAGE({ rootState, commit, dispatch }, scratchMessage: ScratchEmailMessage | ScratchSimpleMessage) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;
    const promoterAccountOid = rootState.auth.account.oid;

    const {
      oid,
      customerName,
      meta,
      scheduledAt,
      provider,
    } = scratchMessage;

    const body: any = {
      promoterOid,
      promoterAccountOid,
      status: 'in-progress',
      name: 'fan-message',
      customerName,
      provider,
      meta,
    };

    if (scheduledAt) {
      body.status = 'scheduled';
      body.scheduledAt = scheduledAt;
    }

    if (body.meta && body.meta.uiStatus) {
      delete body.meta.uiStatus;
    }

    try {
      commit('SET_IS_SENDING_OUT_MESSAGE', true);

      // Check if message list exist, if not, stop it
      let hasMessageList = true;
      try {
        const res = await this.$axios.get(`/promoter/${promoterOid}/message-list/${meta.messageListOid}`);
        if (!res.data) {
          hasMessageList = false;
        }
      } catch (e) {
        hasMessageList = false;
      }
      if (!hasMessageList) {
        const campaignOid = meta.initiator?.campaignOid;
        dispatch('SHOW_CONFIRM', {
          title: `Could not ${scheduledAt ? 'schedule' : 'send'} message`,
          messageHtml: campaignOid ? 'The selected recipients are unavailable. Please try selecting the recipients again.' : 'Your selected List is not available. Please select another List.',
          hideCancelButton: true,
          confirmButtonText: 'OK',
        }, { root: true });
        return;
      }

      if (oid) {
        await this.$axios.patch(`/promoter/${promoterOid}/task/${oid}`, body);
      } else {
        await this.$axios.post(`/promoter/${promoterOid}/task`, body);
      }
      return true;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to send message' });
      return false;
    } finally {
      commit('SET_IS_SENDING_OUT_MESSAGE', false);
    }
  },
  async SAVE_MESSAGE_DRAFT({ rootState, commit }, {
    provider,
    oid,
    customerName,
    meta,
    scheduledAt,
  }) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;
    const promoterAccountOid = rootState.auth.account.oid;

    const jsonBody: any = {
      promoterOid,
      promoterAccountOid,
      provider,
      meta,
      customerName,
      name: 'fan-message',
      status: 'draft',
    };

    if (isValidTzDate(scheduledAt)) {
      jsonBody.scheduledAt = scheduledAt;
    } else {
      delete jsonBody.scheduledAt;
    }

    let uri = `/promoter/${promoterOid}/task`;

    try {
      commit('SET_IS_SAVING_MESSAGE_AS_DRAFT', true);
      let response;
      if (oid) {
        response =  await this.$axios.patch(`${uri}/${oid}`, jsonBody);
      } else {
        response = await this.$axios.post(uri, jsonBody);
      }

      if (response.status === 201) {
        this.$arNotification.push({ type: 'success', message: 'Message saved as draft' });
      }
      return true;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to save message as draft' });
      return false;
    } finally {
      commit('SET_IS_SAVING_MESSAGE_AS_DRAFT', false);
    }
  },
  async SEND_TEST_EMAIL({ rootState, commit }, {
    recipients,
    type,
    meta,
  }) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = rootState.auth.account;
    try {
      commit('SET_IS_SENDING_TEST_EMAIL', true);
      const jsonBody = {
        disableLinkTracking: !!meta.disableLinkTracking,
        templateResourceOid: meta.templateResourceOid,
        messageBody: {
          subject: meta.messageBody.subject,
          previewText: meta.messageBody.previewText,
        },
        email: {
          promoterPropertyOid: meta.email ? meta.email.promoterPropertyOid : null,
        },
        messageListOid: meta.messageListOid,
        type,
        recipients
      };

      // @ts-ignore
      await this.$axios.sg.post(`/promoter/${promoterOid}/test-message`, jsonBody);
      return true;
    } catch (error) {
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to send test message',
      });
      return false;
    } finally {
      commit('SET_IS_SENDING_TEST_EMAIL', false);
    }
  },
  async FETCH_SMS_MESSAGE_PREVIEW(
    { rootState, commit, getters, state },
    { editSmsMessage, cost = false }: { editSmsMessage: ScratchSimpleMessage, cost: boolean },
  ) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;
    try {
      
      const advancedTargetingFilter = getters.getCurrentFilterExpression || null;

      if (cost) {
        commit('SET_SMS_COST_FAILED_TO_FETCH', false);
        commit('SET_IS_FETCHING_SMS_COST', true);
      }

      const params: any = {
        $provider: 'sms',
        $body: editSmsMessage.meta.messageBody || '',
        $messageListOid: null,
        $cost: cost || null,
        $audienceFilter: cost ? advancedTargetingFilter : null,
      };
      if (editSmsMessage.meta.messageListOid) {
        params.$messageListOid = editSmsMessage.meta.messageListOid;
      }
      if (editSmsMessage.meta.tagMaxWidthMap && Object.keys(editSmsMessage.meta.tagMaxWidthMap).length > 1) {
        try {
          const encodedString = JSON.stringify(editSmsMessage.meta.tagMaxWidthMap);
          params.$tagMaxWidthMap = encodedString;
        } catch(e) {
          console.log(e);
        }
      }

      // @ts-ignore
      const { data } = await this.$axios.cn.get(`/promoter/${promoterOid}/message-preview/`, {
        params,
      });

      commit('PUT_SMS_MESSAGE_PREVIEW', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch SMS message preview' });
      if (cost) {
        commit('SET_SMS_COST_FAILED_TO_FETCH', true);
      }
    } finally {
      if (cost) {
        commit('SET_IS_FETCHING_SMS_COST', false);
      }
    }
  },
  async FETCH_MORE_MESSAGES(
    { state, rootState, commit },
    {
      status = null,
      search = null,
      top = 20,
      select = null,
      reload = false,
    },
  ) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;

    if (state.isFetchingMessages) {
      console.error('FETCH_MORE_MESSAGES cancelled due to existing pending request');
      return;
    }

    // If it's not load more, pls reset messages
    if (reload) {
      commit('RESET_MESSAGES');
    }

    if (state.hasFetchMessagesFailed) {
      console.error(`FETCH_MORE_MESSAGES cancelled due to previous failed request.`);
      return;
    }


    try {
      commit('SET_IS_FETCHING_MESSAGES', true);
      const uri = `/promoter/${promoterOid}/fan-message-search`;
      const params = {
        $top: top,
        $skip: state.messages.length,
        $select: select || null,
        $search: search || null,
        $status: status || null,
      };

      const { data } = await this.$axios.get(uri, { params });

      commit('CONCAT_MESSAGES', data);
      if (data.length === 0) {
        commit('SET_IS_NO_MORE_MESSAGES', true);
      }
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch messages' });
      commit('SET_HAS_FETCH_MESSAGES_FAILED', true);
    } finally {
      commit('SET_IS_FETCHING_MESSAGES', false);
    }
  },
  async FETCH_MESSAGE({ rootState, commit }, oid) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;

    if (isNaN(oid)) {
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch message' });
      return null;
    }

    const uri = `/promoter/${promoterOid}/task/${oid}`;

    commit('SET_IS_FETCHING_MESSAGE', true);
    try {
      // @ts-ignore
      const { data } = await this.$axios.sg.get(uri);

      if (data?.meta?.presentation?.templateType === 'unlayer') {
        recursivelyPurifyUnlayerDesign(null, data.meta.presentation.template);
      }

      await commit('SET_CURRENT_SELECTED_MESSAGE', data);
      return true;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch message' });
      return false;
    } finally {
      commit('SET_IS_FETCHING_MESSAGE', false);
    }
  },
  async CLONE_MESSAGE({ rootState, commit }, taskOid) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/task/${taskOid}/clone`;

    try {
      await this.$axios.post(uri);

      this.$arNotification.push({
        type: 'success',
        message: 'Message successfully duplicated'
      });
    } catch (error: any) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: `Failed to duplicate message: ${error.message}`,
      });
    }
  },
  async ARCHIVE_MESSAGE({ rootState, commit }, taskOid) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/task/${taskOid}`;

    try {
      await this.$axios.patch(uri, {
        status: 'archived',
      });

      this.$arNotification.push({
        type: 'success',
        message: `Successfully archived message`,
      });
      commit('REMOVE_MESSAGE_FROM_MESSAGES', taskOid);
    } catch (error: any) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: `Failed to archive message: ${error.message}`,
      });
    }
  },
  async CANCEL_MESSAGE({ rootState }, taskOid) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/task/${taskOid}`;

    try {
      await this.$axios.patch(uri, {
        status: 'cancelled',
      });
      this.$arNotification.push({
        type: 'success',
        message: `Successfully cancelled message`,
      });
    } catch (error: any) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: `Failed to cancel message: ${error.message}`,
      });
    }
  },
  // Email Html
  async GENERATE_SCRATCH_EMAIL_TEMPLATE({ rootState, commit }, unlayerDesign: any) {
    if (!rootState.auth.account) { return; }
    const { promoterOid } = rootState.auth.account;
    try {
      const unlayerExportHtml = await this.$api.unlayer.exportHtml(unlayerDesign);
      commit('SET_SCRATCH_EMAIL_TEMPLATE', unlayerExportHtml?.html);
      return true;
    } catch (error) {
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to generate scratch email template',
      });
      return false;
    }
  },
  async FETCH_SAVED_EMAIL_TEMPLATE({ dispatch, commit }, oid) {
    try {
      commit('SET_IS_FETCHING_SAVED_EMAIL', true);
      const html = await dispatch('GET_ASSET', { oid }, { root: true });
      commit('SET_SAVED_EMAIL', html);
    } catch (error) {
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch email template',
      });
    } finally {
      commit('SET_IS_FETCHING_SAVED_EMAIL', false);
    }
  },
  async UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE({ dispatch, commit, state }, emailHtml: string) {
    // If you have a saved template, we have to delete it, since we will no longer need it
    if (state.scratchEmailMessage.meta.templateResourceOid) {
      // Messi 20210303: DO NOT DO THIS AT THE MOMENT! this would probably delete asset from other bucket.
      // dispatch('DELETE_ASSET', state.scratchEmailMessage.meta.templateResourceOid, { root: true });
    }

    // we need to create a new HTML asset at every save/send
    // because of how our S3 buckets work - we can only
    // PUT to the bucket resource for a limited period.
    const file = createTextFile({
      text: emailHtml,
      type: 'text/html',
      name: 'email_html', // Name doesn't matter here
    });
    try {
      commit('SET_IS_UPDATING_EMAIL', true);
      const resource = await dispatch('UPLOAD_ASSET', {
        assetType: 'email-template',
        contentType: 'text/html',
        file,
      }, { root: true });
      commit('PATCH_SCRATCH_EMAIL_MESSAGE', {
        meta: {
          templateResourceOid: resource.oid,
        },
      });
      return resource;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update email template',
      });
      return false;
    } finally {
      commit('SET_IS_UPDATING_EMAIL', false);
    }
  },
  async FETCH_CSV_PREVIEW_CONTACTS({ commit, rootState }, dynamicTagsCsvOid: number) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;
    try {
      const preSignedUrl = await this.$api.buckets.generatePreSignedUrl(promoterOid, dynamicTagsCsvOid, 'get');

      const csvString = await this.$api.csv.stream(preSignedUrl, 5);
      let csvData = Papa.parse<string[]>(csvString, {header: false}).data;

      const headers: string[] = csvData[0];
      const rows: string[][] = csvData.splice(1).filter((row: any[]) => {
        if (row.length === 0 || !row[0] || row[0] === 'undefined') {
          return false;
        }
        return true;
      });
      commit('SET_CSV_PREVIEW_CONTACTS', { headers, rows });
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch dynamic tags csv',
      });
    }
  },
  async RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE({ commit }, message: string) {
    const dynamicTags = getDyanmicTagsFromContent(message);
    const dynamicTagsWithoutEmailAddress = dynamicTags.filter(tag => tag !== 'email_address');
    commit('SET_FALLBACK_DYNAMIC_TAGS_FOR_SCRATCH_EMAIL_MESSAGE', dynamicTagsWithoutEmailAddress)
    commit('TIDY_UP_FALLBACK_DYNAMIC_TAGS_IN_EMAIL_MESSAGE', dynamicTagsWithoutEmailAddress);
  },
  async RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_SIMPLE_MESSAGE({ commit }, message: string) {
    const dynamicTags = getDyanmicTagsFromContent(message);
    const dynamicTagsWithoutMobileNumber = dynamicTags.filter(tag => tag !== 'mobile_number');
    commit('SET_FALLBACK_DYNAMIC_TAGS_FOR_SCRATCH_SIMPLE_MESSAGE', dynamicTagsWithoutMobileNumber)
    commit('TIDY_UP_FALLBACK_DYNAMIC_TAGS_IN_SIMPLE_MESSAGE', dynamicTagsWithoutMobileNumber);
  },
};
