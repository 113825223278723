<template>
  <section class="am2-message-setup-page">
    <div
      :class="[
        'header-section',
        displayMobilePreview && 'not-visible',
      ]"
    >
      <am2-unlock-prompt
        v-if="displayUnlockPrompt"
        :title="unlockPromptTitle"
        :subtitle="unlockPromptSubtitle"
        @click="handleGoToPlansClick"
        :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
      />
      <!-- TODO: update the props of this component when we support multiple message types -->
      <am2-top-create
        :title="headerText"
        :exit-path="exitPath"
        :title-icon="headerIconProps"
        :style="{
          position: 'relative',
        }"
      />
    </div>

    <div
      :class="[
        'body-section',
        $arMediaQuery.window.maxWidth('sm') && 'sm-max',
      ]"
    >
      <div
        :class="[
          'edit-message-section',
          hasNoSpaceForPreview && 'full-width',
        ]"
      >
        <div
          :class="[
            'edit-message-form-section',
            $arMediaQuery.window.maxWidth('sm') && 'sm-max',
          ]"
        >
          <div class="page-content">
            <nuxt-child
              ref="message-edit-page"
              :cost-accepted="costAccepted"
              @cost-decision-change="(val) => costAccepted = val"
              :no-contacts="noContactsInMessageList"
              :recipient-count-mismatch="recipientsDoesNotMatchListCount"
              :message-list="currentMessageList || null"
              :facebook-page-is-purchased="facebookPageIsPurchased"
              @facebookPageUpdate="handleFacebookPageUpdate"
              @forceCostRecount="forceCostRecount"
            />
          </div>
        </div>

        <div
          :class="[
            'edit-message-navigation-section',
            $arMediaQuery.window.maxWidth('sm') && 'sm-max',
          ]"
        >
          <div class="navigation-left-section">
            <ar-simple-button
              v-if="$arMediaQuery.window.minWidth('sm')"
              text="Cancel"
              outlined
              @click="cancelChanges"
              data-test-id="cancel-button"
              :style="{ width: '120px' }"
            />
          </div>

          <div class="navigation-right-section">
            <ar-icon
              v-if="currentSelectedMessageScheduleCountdownCopy"
              class="u-margin-right-2"
              name="notification-warning"
              :color="$arStyle.color.purple500"
              v-tooltip="{
                content: currentSelectedMessageScheduleCountdownCopy,
              }"
            />
            <ar-link-button
              v-if="currentSelectedMessageUiStatus !== 'scheduled'"
              :text="isNewMessage ? 'Save as draft' : 'Save draft'"
              :class="[
                'save-link',
                $arMediaQuery.window.maxWidth('sm') && 'sm-max',
              ]"
              :disabled="isSaveAsADraftButtonDisabled"
              data-test-id="save-draft-button"
              @click="saveAsDraft"
            />
            <ar-simple-button
              v-if="hasNoSpaceForPreview"
              :text="mobilePreviewIsToggledUp ? 'Edit' : 'Preview'"
              outlined
              :icon-name="previewButtonIconName"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              icon-distance="10px"
              @click="handlePreviewClick"
              data-test-id="view-preview-button"
              :style="{
                marginRight: '12px',
                width: previewButtonWidth,
              }"
            />

            <ar-simple-button
              :text="sendMessageButtonCopy"
              :disabled="!enableSendMessageButton"
              :loading="isSendingOutMessage"
              type="purple"
              v-if="isMessageFeatureEnabled"
              data-test-id="send-message-button"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: !enableSendMessageButton,
                action: sendMessage,
              }"
              @click="sendMessage"
            />
            <ar-simple-button
              v-if="!isMessageFeatureEnabled"
              :text="sendMessageButtonCopy"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '150px' : '100px' }"
              icon-name="lock"
              v-tooltip.top="{
                content: lockedTooltipMessage
              }"
              disabled
              data-test-id="send-message-button"
              @click="handleGoToPlansClick"
            />
          </div>
        </div>
      </div>

      <div
        :class="[
          'preview-section',
          hasNoSpaceForPreview && 'go-on-top-of-edit-section',
          $arMediaQuery.window.maxWidth('sm') && 'sm-max',
          displayMobilePreview && 'is-visible',
        ]"
      >
        <div class="preview-box">
          <div class="preview">
            <MessageMobileContainer
              :display-paginator="displayCsvPreviewPaginator"
              :contacts-count="csvPreviewContacts ? csvPreviewContacts.length : 0"
              :contact-index="selectedCsvPreviewContactIndex"
              @contactChange="handleCsvPreviewContactsIndexChange"
            >
              <am2-sms-preview
                v-if="messageType === 'sms'"
                :sender="smsMessagePreview.sender"
                :message-body="smsMessagePreview.body"
                :original-text="scratchMessageBody"
                :preview-contact="selectedCsvPreviewContact"
              />
              <am2-facebook-messenger-preview
                v-if="messageType === 'facebook'"
                :page-name="facebookPageObject && facebookPageObject.name"
                :page-image-src="facebookPageObject && facebookPageObject.imageSrc"
                :message-body="messagePreview"
                :original-text="scratchMessageBody"
                :preview-contact="selectedCsvPreviewContact"
              />
            </MessageMobileContainer>
          </div>
          <ar-link-button
            v-if="csvPreviewContacts"
            :text="displayCsvPreviewContacts ? 'Hide live dynamic tags' : 'Preview dynamic tags'"
            :style="{
              marginTop: '22px',
            }"
            @click="handleDisplayCsvPreviewContactsToggle"
            data-test-id="toogle-dynamic-tags-preview-link"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { debounce } from "debounce";
import accounting from 'accounting';
import { convertToSnakeCase, capitalizeFirstLetter, clone } from '@/utils/helpers';
import { MAX_CHARACTERS_FACEBOOK_MESSAGE } from '@/utils/constants';
import MessageMobileContainer from '@/components/containers/message-mobile-container';
import { recipientFilterToTargetingRules } from '@/store/modules/message/utils';

export default {
  name: 'MessageEdit',
  layout: 'edit',

  components: {
    MessageMobileContainer,
  },

  data: () => ({
    overrideWarning: false,
    costAccepted: false,
    facebookPageObject: null,
    facebookPageIsPurchased: null,
    mobilePreviewIsToggledUp: false, // Mobile-only - when true, shows the preview instead of the editor
    windowHeight: window.innerHeight,
  }),

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      currentCampaignFirstFbPageId: 'campaign/currentCampaignFirstFbPageId',
      scratchSimpleMessageCampaignOid: 'message/scratchSimpleMessageCampaignOid',
      scratchSimpleMessageEventOid: 'message/scratchSimpleMessageEventOid',
      hasNonExistingTagsInSimpleMessage: 'message/hasNonExistingTagsInSimpleMessage',
      getSmsMessagePreviewSegmentsCount: 'message/getSmsMessagePreviewSegmentsCount',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentSelectedMessageEditable: 'message/currentSelectedMessageEditable',
      currentSelectedMessageScheduleCountdownCopy: 'message/currentSelectedMessageScheduleCountdownCopy',
      selectedCsvPreviewContact: 'message/selectedCsvPreviewContact',
      integrationDisconnected: 'facebookMessengerIntegration/integrationDisconnected',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
    }),
    ...mapState({
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      smsMessagePreview: state => state.message.smsMessagePreview,
      scratchSimpleMessage: state => state.message.scratchSimpleMessage,
      isSendingOutMessage: state => state.message.isSendingOutMessage,
      isSavingMessageAsDraft: state => state.message.isSavingMessageAsDraft,
      csvPreviewContacts: state => state.message.csvPreviewContacts,
      selectedCsvPreviewContactIndex: state => state.message.selectedCsvPreviewContactIndex,
      displayCsvPreviewContacts: state => state.message.displayCsvPreviewContacts,
      oid: state => state.route.params.oid,
      path: state => state.route.path,
      query: state => state.route.query,
      integration: state => state.facebookMessengerIntegration.integration,
      isFetchingPaymentSubscriptions: state => state.payment.isFetchingPaymentSubscriptions,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      isFetchingSmsCost: state => state.message.isFetchingSmsCost,
      smsCostFailedToFetch: state => state.message.smsCostFailedToFetch,
    }),

    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/message-center/messages');
    },

    displayCsvPreviewPaginator() {
      return this.csvPreviewContacts && this.displayCsvPreviewContacts;
    },

    hasNoSpaceForPreview() {
      if (this.$arMediaQuery.window.maxWidth('md')) {
        return true;
      }
      if (this.displayUnlockPrompt) {
        return this.windowHeight < 700;
      } else {
        return this.windowHeight < 600;
      }
    },

    displayMobilePreview() {
      return this.mobilePreviewIsToggledUp && this.hasNoSpaceForPreview;
    },

    isNewMessage() {
      return !this.scratchSimpleMessage.oid;
    },
    previewButtonIconName() {
      if (this.mobilePreviewIsToggledUp) {
        return null;
      }
      return 'mobile-preview';
    },
    previewButtonWidth() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        return '120px';
      } else {
        return '135px';
      }
    },
    sendMessageButtonCopy() {
      if (this.isScheduledMessage) {
        if (this.currentSelectedMessageUiStatus === 'scheduled') {
          return 'Update';
        } else {
          return 'Schedule';
        }
      } else {
        if (this.$arMediaQuery.window.minWidth('sm')) {
          return 'Send Message';
        } else {
          return 'Send';
        }
      }
    },
    isScheduledMessage() {
      return !!this.scratchSimpleMessage.scheduledAt || !!this.scratchSimpleMessage.meta.presentation.timeZone;
    },
    scratchMessageBody() {
      return this.scratchSimpleMessage?.meta?.messageBody;
    },
    smsSubscriptionExists() {
      return this.paymentSubscriptionList.some(item => item.pplanId && item.pplanId.toLowerCase().indexOf('sms') > -1);
    },
    isSaveAsADraftButtonDisabled() {
      return this.isSavingMessageAsDraft ||
        this.isSendingOutMessage;
    },
    isMessageFeatureEnabled() {
      if (this.isFetchingPaymentSubscriptions) {
        return true;
      }
      if (this.messageType === 'sms') {
        return this.isFeatureEnabled(['messages', 'sms']) && !!this.smsSubscriptionExists;
      } else if (this.messageType === 'facebook') {
        return this.isFeatureEnabled(['messages', 'facebookMessenger']);
      } else {
        return false;
      }
    },
    displayUnlockPrompt() {
      return !this.isMessageFeatureEnabled;
    },
    lockedTooltipMessage() {
      if (this.messageType === 'facebook') {
        return 'A <strong>Basic</strong> or <strong>Pro</strong> plan (with the Facebook Messenger Add-On) or a <strong>Plus</strong> plan is required before you can send Facebook messages.';
      }
      return 'A <strong>Pro</strong> or <strong>Plus</strong> plan (with purchased SMS credit) is required before you can send SMS messages.';
    },
    messageType() {
      // only two possible message types 'sms' and 'facebook'
      if (this.path.indexOf('sms') > -1) {
        return 'sms';
      }
      return 'facebook';
    },
    headerText() {
      if (this.messageType === 'sms') {
        return 'Send an SMS';
      }
      return 'Send a Facebook Message';
    },
    headerIconProps() {
      if (this.messageType === 'sms') {
        return {
          name: 'sms',
          color: this.$arStyle.color.sms,
          height: '19px',
          width: '18px',
        };
      }

      return {
        name: 'messenger',
        color: this.$arStyle.color.messenger,
        height: '20px',
        width: '20px',
      };
    },
    noContactsInMessageList() {
      const isMessageFromCampaign = this.scratchSimpleMessageCampaignOid;
      const isMessageFromEvent = this.scratchSimpleMessageEventOid;

      if (!this.currentMessageList) {
        return false;
      }
      if (this.currentMessageList.filterGroupOid && !isMessageFromCampaign) { // It's hard to check real-time stats of auto list
        return false;
      }
      if (this.currentMessageList.filterGroupOid && !isMessageFromEvent) { // It's hard to check real-time stats of auto list
        return false;
      }
      if (!this.currentMessageList.statsSnapshot) {
        return false;
      }

      if (this.messageType === 'sms') {
        // If we're checking message-from-campaign, then we need to use mobileNumber rather than sms/validSms, because we don't follow regular opt-in rules
        if (isMessageFromCampaign || isMessageFromEvent) {
          const statSnapshotIsNew = typeof this.currentMessageList?.statsSnapshot?.sms?.validMobile !== 'undefined';
          return statSnapshotIsNew ? this.currentMessageList.statsSnapshot?.sms?.validMobile <= 0 : this.currentMessageList.statsSnapshot?.validSms <= 0;
        } else {
          const statSnapshotIsNew = typeof this.currentMessageList?.statsSnapshot?.sms?.optedIn !== 'undefined';
          return statSnapshotIsNew ? this.currentMessageList.statsSnapshot?.sms?.optedIn <= 0 : this.currentMessageList.statsSnapshot?.sms <= 0;
        }
      } else {
        if (!!this.currentMessageList.statsSnapshot.facebook) {
          const pageId = this.facebookPageObject?.id || this.currentMessageList?.meta?.facebookMessenger?.pageId;
          if (!pageId) return true;
          if (!this.currentMessageList.statsSnapshot.facebook[pageId]) return true;
          return this.currentMessageList.statsSnapshot.facebook[pageId] <= 0;
        } else if(!!this.currentMessageList.statsSnapshot.facebookMessenger) { // facebookMessenger is the old method of tracking FB stats. We'll remove this eventually
          return this.currentMessageList.statsSnapshot.facebookMessenger <= 0;
        }
        return true;
      }
    },

    recipientsDoesNotMatchListCount() {
      if (!this.currentMessageList || !this.currentMessageList.statsSnapshot) return;
      const statsSnapshot = this.currentMessageList.statsSnapshot;
      if (this.messageType === 'sms') {
        return (statsSnapshot.sms.optedIn || 0) < (this.currentMessageList.statsSnapshot.totalOptins || 0)
      }
      return false;
    },

    enableSendMessageButton() {
      if (this.messageType === 'facebook' && this.integrationDisconnected) {
        return false;
      }
      if (this.isSavingMessageAsDraft) {
        return false;
      }
      if (this.currentSelectedMessage && !this.currentSelectedMessageEditable) {
        return false;
      }
      if (this.advancedMessageListTargeting?.type &&
          this.advancedMessageListTargeting?.type !== 'all' &&
          !this.filteredRecipientListCount) {
        return false;
      }
      switch (this.messageType) {
        case 'sms':
          return !this.noContactsInMessageList &&
            this.costAccepted &&
            this.scratchSimpleMessage.meta.messageListOid &&
            this.scratchSimpleMessage.meta.messageBody.length > 0 &&
            this.smsMessagePreview.charactersLeft >= 0 &&
            !this.isFetchingSmsCost &&
            !this.smsCostFailedToFetch &&
            !this.hasNonExistingTagsInSimpleMessage;
        case 'facebook':
          return !this.noContactsInMessageList &&
            this.facebookPageIsPurchased &&
            this.scratchSimpleMessage.meta.messageListOid &&
            this.scratchSimpleMessage.meta.messageBody.length > 0 &&
            this.scratchSimpleMessage.meta.messageBody.length <= MAX_CHARACTERS_FACEBOOK_MESSAGE &&
            !this.hasNonExistingTagsInSimpleMessage;
        default:
          return false;
      }

      return true;
    },

    unlockPromptTitle() {
      if (this.messageType === 'sms') {
        return 'Unlock SMS Messaging';
      }
      return 'Unlock Facebook Messenger';
    },
    unlockPromptSubtitle() {
      if (this.messageType === 'sms') {
        return `Upgrade to a paid plan now to start sending messages today!`
      }
      return `Upgrade to a paid plan now to start sending Facebook messages today!`
    },

    messagePreview() {
      return this.scratchSimpleMessage.meta.messageBody;
    },

    hasPopulatedData() {
      return !!this.scratchSimpleMessage?.meta?.messageBody || !!this.scratchSimpleMessage?.meta?.messageListOid || !!this.scratchSimpleMessage?.meta?.facebookMessenger?.pageId;
    },

    recipientsCount() {
      if (!this.currentMessageList) {
        return 0;
      }
      if (!this.currentMessageList.statsSnapshot) {
        return 0;
      }
      if (this.messageType === 'sms' && this.scratchSimpleMessageCampaignOid) {
        const statSnapshotIsNew = typeof this.currentMessageList?.statsSnapshot?.sms?.validMobile !== 'undefined';
        return statSnapshotIsNew ? this.currentMessageList.statsSnapshot.sms.validMobile : (this.currentMessageList.statsSnapshot.validSms || 0);
      } else if (this.messageType === 'sms') {
        const statSnapshotIsNew = typeof this.currentMessageList?.statsSnapshot?.sms?.optedIn !== 'undefined';
        return statSnapshotIsNew ? this.currentMessageList.statsSnapshot.sms.optedIn : (this.currentMessageList.statsSnapshot.validSms || 0);
      } else if (this.messageType === 'facebook') {
        if (!!this.currentMessageList.statsSnapshot.facebook) {
          const pageId = this.facebookPageObject?.id || this.currentMessageList?.meta?.facebookMessenger?.pageId;
          if (!pageId) return 0;
          if (!this.currentMessageList.statsSnapshot.facebook[pageId]) return 0;
          return this.currentMessageList.statsSnapshot.facebook[pageId];
        } else if (!!this.currentMessageList.statsSnapshot.facebookMessenger) { // facebookMessenger is the old method of tracking FB stats. We'll remove this eventually
          return this.currentMessageList.statsSnapshot.facebookMessenger;
        }
      }
      return 0;
    },

    exitPath() {
      // If it's a new message for campaign
      if (!!this.scratchSimpleMessageCampaignOid
        && this.isNewMessage) {
        return `/campaigns/${this.scratchSimpleMessageCampaignOid}/view/dashboard`;
      } else if (!!this.scratchSimpleMessageEventOid
        && this.isNewMessage) {
        return `/events/${this.scratchSimpleMessageEventOid}/view/sales`;
      } else {
        return this.sanitizedReferringPage;
      }
    },
  },

  watch: {
    scratchSimpleMessage: {
      handler(val, oldVal) {
        if (this.messageType === 'sms') {
          const oldSmsMessagePreviewSegmentsCount =
            this.getSmsMessagePreviewSegmentsCount(oldVal);
          const expectedSmsMessagePreviewSegmentsCount =
            this.getSmsMessagePreviewSegmentsCount(val);

          // Skip debouncing if segments count changed
          // or if recipientFilter changes
          const oldMessageListOid = oldVal.meta.messageListOid;
          const newMessageListOid = val.meta.messageListOid;

          if (
            oldSmsMessagePreviewSegmentsCount !== expectedSmsMessagePreviewSegmentsCount
            || newMessageListOid !== oldMessageListOid
          ) {
            this.updateSmsPreview(true);
          } else {
            this.updateSmsPreviewDebounce(false);
          }
        }
        sessionStorage.setItem('_ar_promoter_new_message', JSON.stringify(val));
      },
      deep: true
    },
  },

  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (!this.overrideWarning && this.hasPopulatedData) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if (response) {
        sessionStorage.removeItem('_ar_promoter_new_message');
        next();
      } else {
        next(false)
      }
    } else {
      sessionStorage.removeItem('_ar_promoter_new_message');
      next();
    }
  },

  /**
   * If you need any data to be ready before we start rending the template,
   * please do it here.
   **/
  async asyncData({ store, route }) {
    store.commit('message/RESET_SCRATCH_SIMPLE_MESSAGE');
    store.commit('message/RESET_CURRENT_SELECTED_MESSAGE');
    store.commit('messageSender/RESET_CURRENT_MESSAGE_SENDER');
    store.commit('message/RESET_SMS_MESSAGE_PREVIEW');
    store.commit('message/RESET_CSV_PREVIEW_CONTACTS');
    if (route.params.oid) {
      await store.dispatch('message/FETCH_MESSAGE', route.params.oid);
    }
    return {};
  },

  async created() {
    this.updateSmsPreviewDebounce = debounce(this.updateSmsPreview, 250);
    try {
      if (this.oid) {
        const messageObject = clone(this.currentSelectedMessage);

        if (messageObject.meta.tagMaxWidthMap) {
          const convertedMaxLengthMap = {};
          Object.keys(messageObject.meta.tagMaxWidthMap).forEach(item => {
            const newKey = convertToSnakeCase(item);
            convertedMaxLengthMap[newKey] = messageObject.meta.tagMaxWidthMap[item];
          });
          messageObject.meta.tagMaxWidthMap = convertedMaxLengthMap;
        }

        this['message/SET_SCRATCH_SIMPLE_MESSAGE'](messageObject);
      } else if (sessionStorage.getItem('_ar_promoter_new_message')) {
        this['message/SET_SCRATCH_SIMPLE_MESSAGE'](JSON.parse(sessionStorage.getItem('_ar_promoter_new_message')));
      } else  {
        this['message/RESET_SCRATCH_SIMPLE_MESSAGE']();
      }

      const savedMessageListOid = this.query && this.query.messageList ? this.query.messageList : null;
      const savedCampaignOid = this.query && this.query.campaignOid ? this.query.campaignOid : null;
      const savedEventOid = this.query && this.query.eventOid ? this.query.eventOid : null;

      if (!this.scratchSimpleMessage.meta.messageListOid && savedMessageListOid && savedMessageListOid.length > 0) {
        this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
          meta: { messageListOid: parseInt(savedMessageListOid) },
        });
      } else if (savedCampaignOid) {
        this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
          meta: {
            initiator: {
              campaignOid: parseInt(savedCampaignOid),
            },
          }
        });
      } else if (savedEventOid) {
        this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
          meta: {
            initiator: {
              eventOid: parseInt(savedEventOid),
            },
          }
        });
      }

      if (this.scratchSimpleMessageCampaignOid) {
        await this['campaign/FETCH_CURRENT_CAMPAIGN'](this.scratchSimpleMessageCampaignOid);
        if (this.messageType === 'facebook' && this.currentCampaignFirstFbPageId) {
          this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
            meta: {
              facebookMessenger: {
                pageId: this.currentCampaignFirstFbPageId,
              }
            },
          });
        }
      }
      if (this.scratchSimpleMessage.meta.messageListOid) {
        await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid: this.scratchSimpleMessage.meta.messageListOid});
        let newAdvancedMessageListTargeting = null;
        if (this.currentSelectedMessage?.meta?.recipientFilter) {
          newAdvancedMessageListTargeting = recipientFilterToTargetingRules(this.currentSelectedMessage.meta.recipientFilter);
        } else {
          newAdvancedMessageListTargeting = recipientFilterToTargetingRules(this.scratchEmailMessage?.meta?.recipientFilter || null);
        }
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.updateSmsPreview(true);
      }
      // Download Dynamic Tags CSV and set contacts if exist
      if (this.scratchSimpleMessage.meta.tagsResourceOid) {
        this['message/FETCH_CSV_PREVIEW_CONTACTS'](this.scratchSimpleMessage.meta.tagsResourceOid);
      }
    } catch(e) {
      console.error(e);
    }

    this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']();
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['message/RESET_CURRENT_SELECTED_MESSAGE']();
    this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
    this['message/RESET_SCRATCH_SIMPLE_MESSAGE']();
    this['message/RESET_CSV_PREVIEW_CONTACTS']();
    this['messageList/RESET_CURRENT_MESSAGE_LIST']();
    this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();

    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    ...mapActions([
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'SHOW_CONFIRM',
      'message/FETCH_SMS_MESSAGE_PREVIEW',
      'message/SEND_OUT_MESSAGE',
      'message/SAVE_MESSAGE_DRAFT',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'message/FETCH_MESSAGE',
      'message/FETCH_CSV_PREVIEW_CONTACTS',
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),

    ...mapMutations([
      'campaign/RESET_CURRENT_CAMPAIGN',
      'message/RESET_SMS_MESSAGE_PREVIEW',
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'message/SET_SCRATCH_SIMPLE_MESSAGE',
      'message/PUT_SCRATCH_SIMPLE_MESSAGE',
      'message/RESET_SCRATCH_SIMPLE_MESSAGE',
      'message/RESET_CSV_PREVIEW_CONTACTS',
      'message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX',
      'message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'messageList/RESET_CURRENT_MESSAGE_LIST',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
    ]),

    async updateSmsPreview(cost) {
      this['message/FETCH_SMS_MESSAGE_PREVIEW']({
        editSmsMessage: this.scratchSimpleMessage,
        cost,
      });
      if (cost) {
        const options = {
          channel: this.messageType
        }
        if (this.messageType === 'facebook') {
          options.facebookPageId = this.scratchSimpleMessage?.meta?.facebookMessenger?.pageId;
        }
        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT'](options);
      }
    },

    forceCostRecount() {
      this.updateSmsPreview(true);
    },

    cancelChanges() {
      this.$router.push({path: this.exitPath});
    },

    async saveAsDraft() {
      const succeed = await this['message/SAVE_MESSAGE_DRAFT']({
        ...this.scratchSimpleMessage,
        provider: this.messageType,
      });

      if (succeed) {
        this.overrideWarning = true;
        this.$router.push({ path: '/message-center/messages' });
      }
    },

    async sendMessage() {
      const isValid = await this.$refs['message-edit-page'].$validator.validateAll();
      if (!isValid) {
        this.$arNotification.push({ type: 'error', message: 'Could not send message, please review error messages' });
        return;
      }

      const isCampaignSend = !!this.scratchSimpleMessageCampaignOid;

      let actionWord = this.isScheduledMessage ? 'schedule' : 'send';

      let showWarningPrompt = false;
      let promptMessage = '';

      if (this.messageType === 'facebook' && isCampaignSend) {
        const tierSize = this.currentMessageList?.statsSnapshot?.total;
        if (tierSize > this.recipientsCount) {
          showWarningPrompt = true;
          promptMessage = `Not everyone has subscribed to Facebook Messages from this page. <br><strong>Only ${accounting.formatNumber(this.recipientsCount)} of the ${accounting.formatNumber(tierSize)} people in this tier will receive the message.</strong>`;
        }

      } else if (this.messageType === 'facebook') {
        const messageListFacebookPageId = this.currentMessageList?.meta?.facebookMessenger?.pageId;
        const messageListRecipients = messageListFacebookPageId && this.currentMessageList?.statsSnapshot?.facebook ? (this.currentMessageList?.statsSnapshot?.facebook[messageListFacebookPageId] || 0) : 0;

        if (this.recipientsCount < messageListRecipients && !this.currentSelectedMessage?.meta?.recipientFilter && !this.scratchSimpleMessage?.meta?.recipientFilter) {
          showWarningPrompt = true;
          promptMessage = `Not everyone has subscribed to Facebook Messages from this page. <br><strong>Only ${accounting.formatNumber(this.recipientsCount)} of the ${accounting.formatNumber(messageListRecipients)} people in this list will receive the message.</strong>`;
        }

      } else if (this.messageType === 'sms' && isCampaignSend) {
        const tierSize = this.currentMessageList?.statsSnapshot?.total;
        if (tierSize > this.recipientsCount) {
          showWarningPrompt = true;
          promptMessage = `Not everyone has subscribed to SMS messages. <br><strong>Only ${accounting.formatNumber(this.recipientsCount)} of the ${accounting.formatNumber(tierSize)} people in this tier will receive the message.</strong>`;
        }
      }


      if (showWarningPrompt) {
          const response = await this.SHOW_CONFIRM({
            confirmButtonText: `Continue`,
            title: 'Are you sure you want to continue?',
            iconName: 'alert-question-mark',
            messageHtml: promptMessage,
          });
          if (!response) return;
      } else {
        let count = this.recipientsCount;
        if (!!this.currentSelectedMessage?.meta?.recipientFilter || !!this.scratchSimpleMessage?.meta?.recipientFilter) {
          count = this.filteredRecipientListCount;
        }

        const response = await this.SHOW_CONFIRM({
          title: 'Confirmation',
          confirmButtonText: `${capitalizeFirstLetter(actionWord)} Message`,
          messageHtml: `You are about to ${actionWord} a message to <strong>${accounting.formatNumber(count)}</strong> ${count === 1 ? 'person' : 'people'}. Do you want to continue?`
        });
        if (!response) return;
      }




      const succeed = await this['message/SEND_OUT_MESSAGE']({
        ...this.scratchSimpleMessage,
        provider: this.messageType,
      });

      if (succeed) {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        this.overrideWarning = true;
        this.$router.push({path: '/message-center/messages'});
      }
    },

    handlePreviewClick() {
      this.mobilePreviewIsToggledUp = !this.mobilePreviewIsToggledUp;
    },

    async handleFacebookPageUpdate(facebookPageObject) {
      this.facebookPageObject = facebookPageObject;
      this.facebookPageIsPurchased = !!facebookPageObject.paymentSubscriptionOid;
    },

    async handleGoToPlansClick() {
      if (this.scratchSimpleMessage.meta.messageListOid || this.scratchSimpleMessage.meta.messageBody.length > 0) {
        await this.saveAsDraft();
      }
      this.$router.push({
        path: `/settings/subscriptions`,
      });
    },

    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },

    handleCsvPreviewContactsIndexChange(idx) {
      this['message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX'](idx);
    },

    handleDisplayCsvPreviewContactsToggle() {
      this['message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS']();
    },
  }
};
</script>

<style lang="scss" scoped>
.am2-message-setup-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  .header-section {
    position: relative;
    z-index: $zIndexRegular;
    flex-shrink: 0;
    &.not-visible {
      display: none;
    }
  }

  .body-section {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    .edit-message-section {
      flex: 1;
      position: relative;
      z-index: $zIndexRegular;
      display: flex;
      flex-direction: column;
      min-width: 700px;
      max-width: 765px;
      height: 100%;
      background: #fff;
      &.full-width {
        min-width: unset;
        max-width: 100vw;
      }
      &.sm-max {
        min-width: unset;
      }
      .edit-message-form-section {
        flex: 1;
        width: 100%;
        padding: 0 80px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        .page-content {
          max-width: 760px;
          margin: 50px auto;
        }
        &.sm-max {
          padding: 0 12px;
          .page-content {
            margin: 30px auto;
          }
        }
      }

      .edit-message-navigation-section {
        flex-shrink: 0;
        position: relative;
        width: 100%;
        z-index: $zIndexHighest;
        height: 110px;
        padding: 0 80px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &.sm-max {
          height: 75px;
          padding: 0 12px;
        }

        .navigation-right-section {
          display: inline-flex;
        }

        .navigation-right-section {
          display: flex;
          align-items: center;
          .save-link {
            margin-right: 30px;

            &.sm-max {
              margin-right: 12px;
            }
          }
        }
      }
    }
    .preview-section {
      flex-shrink: 0;
      padding: 45px;
      z-index: $zIndexLow;
      overflow: hidden;

      &.is-visible {
        z-index: $zIndexHigh;
      }

      &.go-on-top-of-edit-section {
        padding: 12px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 110px); // minus edit-message-navigation-section height
        background-color: #FFF;

        &.sm-max {
          height: calc(100% - 75px);
        }
      }

      .preview-box {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 100%;
        max-height: 780px;

        .preview {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
