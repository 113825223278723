<template>
  <div class="screen-content">
    <div class="header-section">
      <img
        src="~/assets/images/message-center/messenger-preview-header.png"
        width="100%"
        height="auto"
      >
      <div class="page-info-section">
        <img v-if="pageImageSrc" :src="pageImageSrc" />
        <ar-text
          size="xxxs"
          :text="pageName"
          :style="{
            marginTop: '4px',
            color: 'black',
          }"
        />
      </div>
    </div>
    <section v-if="messageText" class="message-area">
      <SenderBubble
        :text="messageText"
        @anchorClick="handlePreviewAnchorClick"
      />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { urlsRegex } from '@/utils/regex';
import { AR_SHORT_URL_PATTERN } from '@/utils/constants';
import { generateRandomString } from '@/utils/helpers';
import { injectPreviewContact, beautifyDynamicTagsSyntax } from '../utils';
import SenderBubble from '../SenderBubble';

export default {
  name: 'FacebookMessengerPreview',

  components: {
    SenderBubble,
  },

  props: {
    pageName: {
      type: String,
      default: null,
    },
    pageImageSrc: {
      type: String,
      default: null,
    },
    messageBody: {
      type: String,
      default: null,
    },
    originalText: {
      type: String,
      default: null,
    },
    previewContact: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
    },
  },

  data() {
    return {
      storedDummyUrls: [],
    }
  },

  watch: {
    messageBody: {
      handler(val) {
        const urls = this.originalText.match(urlsRegex) || [];
        if (urls.length > this.storedDummyUrls.length) {
          // Generate a few more URLs for good measure
          for(let x; x < 5; x++) {
            this.storedDummyUrls.push(`${process.env.arShortUrlDomain}/${generateRandomString(5)}`);
          }
        }
      },
      immediate: true,
    }
  },

  computed: {
    messageText() {
      if (!this.messageBody) {
        return null;
      }
      const urifiedMessage = this.urifyMessage(this.messageBody);
      if (this.previewContact) {
        return injectPreviewContact(urifiedMessage, this.previewContact);
      } else {
        return beautifyDynamicTagsSyntax(urifiedMessage);
      }
    },
  },

  created() {
    for(let x = 0; x < 20; x++) {
      this.storedDummyUrls.push(`${process.env.arShortUrlDomain}/${generateRandomString(5)}`);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),
    // TODO - Modulize this function, so we don't duplicate this on "sms" and "facebook-messenger" preview
    urifyMessage(message) {
      const originalUrls = this.originalText.match(urlsRegex) || [];
      let index = -1;
      let storedDummyUrls = this.storedDummyUrls;
      let optOutLinkDummyUrl = this.optOutLinkDummyUrl;

      return message.replace(urlsRegex, function(url) {
        index++;
        let matchingDummyUrl = storedDummyUrls[index] || `${process.env.arShortUrlDomain}/${generateRandomString(5)}`;
        if (originalUrls && !!originalUrls[index] && originalUrls[index] !== AR_SHORT_URL_PATTERN && originalUrls[index] !== AR_DEV1_SHORT_URL_PATTERN && originalUrls[index] !== AR_STAGING_SHORT_URL_PATTERN && originalUrls[index] !== AR_PROD_SHORT_URL_PATTERN) {
          return `<a href="${originalUrls[index]}" target="_blank" onclick="return false;">${matchingDummyUrl}</a>`;
        } else if (url === AR_SHORT_URL_PATTERN || url === AR_DEV1_SHORT_URL_PATTERN || url === AR_STAGING_SHORT_URL_PATTERN || url === AR_PROD_SHORT_URL_PATTERN) {
          return `<a href="${url}" data-is-opt-out target="_blank" onclick="return false;">${optOutLinkDummyUrl}</a>`;
        }
        return `<a href="${url}" target="_blank" onclick="return false;">${matchingDummyUrl}</a>`
      });
    },
    handlePreviewAnchorClick(idx, anchor) {
      let urlLink = anchor?.attributes['href']?.textContent;
      const isOptOut = anchor?.attributes['data-is-opt-out'];
      const isValidUrl = new RegExp(urlsRegex).test(urlLink);
      let linkCopy = '';

      if (!!isOptOut) {
        linkCopy = "their personal opt-out page";
      } else if (isValidUrl) {
        if (!urlLink.startsWith('http')) urlLink = `https://${urlLink}`;
        linkCopy = `<a href="${urlLink}" target="_blank">${urlLink}</a>`
      } else {
        linkCopy = `the link you provided`;
      }

      this.SHOW_CONFIRM({
        title: "You've just clicked a shortlink",
        messageHtml: `We automatically shorten links to provide click tracking. When the recipient clicks this link, they'll be redirected to ${linkCopy}.` ,
        hideCancelButton: true,
        confirmButtonText: 'OK',
        hasCrossIcon: true,
      });
    },
  }

};
</script>

<style lang="scss" scoped>
  $default-screen-width: 375px;
  $default-screen-height: 660px;

  .screen-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-section {
      position: relative;
      .page-info-section {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 23%;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        img {
          height: 46%;
          width: auto;
          border: 1px solid $blueGrey500;
          border-radius: 50%;
        }
      }
    }

    section.message-area {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 41px 30px 12px 30px;

      .switch-preview-tag-section {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: green;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>

